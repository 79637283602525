import React from "react";
import PropTypes from "prop-types";
import "./Essential.css";
const EssentialBooks = props => {
  return (
    <div>
      <button type="button" onClick={props.onClick} className="btn btn-danger EssentialBtn">
        Essential Books <i className="fas fa-book-open book" style={styles.icon} />
      </button>
    </div>
  );
};

EssentialBooks.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default EssentialBooks;

const styles = {
  iconContainer: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    color: "#981A5F",
    fontSize: 20
  }
};
