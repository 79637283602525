import axios from "axios";
import { BASE_URL } from "../constants/global";

export const getMemesByMemeTag = condition => {
  return axios.post(`${BASE_URL}/memes/memetag/memes`, condition);
};

export const getMemeTypeCount = (thirdKey, keyword) => {
  return axios.get(`${BASE_URL}/memes/memeTypes?thirdKey=${thirdKey}&keyword=${keyword}`);
};
