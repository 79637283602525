import * as constants from "../constants/concepts";
import * as global from "../constants/global";
import { SET_MEMES_COUNT } from "../constants/memes";
import MemesUpload from "../components/Memes/memeUpload";
const initialState = {
  authors: [],
  keywords: [],
  perspectives: [],
  sortType: constants.SORT_BY_LIKE,
  finish: false,
  initialLoadingDone: false,
  listRelatedLoading: false,
  showMessage: false,
  relatedAuthor: null,
  relatedGroup: [],
  apiLoading: true,
  authorIndex: 5,
  authorTopIndex: 0,
  sorting: global.SORT_BY_LIKES,
  offset: 1,
  limit: 20,
  initialLoading: false,
  authorGroups: [],
  tempConcepts: [],
  concepts: [], // will act as keywrods on cluster page
  tempLoading: false,
  blockPagination: false,
  factsCount: 0,
  factKeywordFilter: -1,
  keywordsCountSort: true,
  conceptLoading: false,
  conceptError: "",
  perspectiveCount: 0,
  textishConcepts: [],
  textishLoading: false,
  textishDataLoading: false,
  textishSelected: "",
  loading: false,
  unverifiedConcepts: [],
  deleteUnverifiedConceptLoading: false,
  memesTagConcepts: [],
  characterRelatedConcepts:[]
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.PERSPECTIVE_COUNT: {
      return {
        ...state,
        perspectiveCount: action.payload
      };
    }
    case constants.GET_CHAR_RELATED_ALL_CONCEPTS:{
      return {
        ...state,
        characterRelatedConcepts:action.payload
      }
    }
    case constants.SHOW_MESSAGE: {
      return {
        ...state,
        showMessage: true,
        initialLoadingDone: true
      };
    }
    case constants.CONCEPT_RELATEDS: {
      return {
        ...state,
        relatedAuthor: action.payload.authorRelatedToConcept,
        relatedGroup: action.payload.groupRelatedToConcept
      };
    }
    case constants.SET_AUTHORGROUPS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case constants.LIST_RELATED_LOADING: {
      return {
        ...state,
        listRelatedLoading: true
      };
    }
    case constants.END_RELATED_LOADING: {
      return {
        ...state,
        listRelatedLoading: false
      };
    }
    case constants.SET_PERSPECTIVES: {
      return {
        ...state,
        ...action.payload
      };
    }
    case constants.KEYWORDS_AND_PERSPECTIVES: {
      return {
        ...state,
        perspectives: action.payload.perspectives,
        keywords: action.payload.keywords
      };
    }
    case constants.SET_PERSPECTIVES_DOWN: {
      let perspectives = state.perspectives;
      perspectives = perspectives.concat(action.payload.data);
      return {
        ...state,
        perspectives,
        finish: action.payload.finish
      };
    }
    case constants.SET_RELATEDS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case constants.SET_PERSPECTIVES_UP: {
      let perspectives = state.perspectives;
      perspectives = action.payload.data.concat(perspectives);
      return {
        ...state,
        perspectives,
        finish: action.payload.finish
      };
    }
    case constants.REQUEST_STARTED: {
      return {
        ...state,
        apiLoading: true,
        trySearch: false,
        Message: false
      };
    }
    case constants.PERSPECTIVE_LIKE: {
      return {
        ...state,
        perspectives: action.payload
      };
    }
    case constants.CONCEPT_SORT_TYPE: {
      return {
        ...state,
        sortType: action.payload
      };
    }
    case constants.CONCEPT_PAGE_MOUNTED: {
      return {
        ...state,
        initialLoadingDone: false
      };
    }

    case constants.CONCEPT_LOADING: {
      return {
        ...state,
        apiLoading: action.payload,
        initialLoading: action.initialLoading
      };
    }
    case constants.CONCEPT_OFFSET: {
      return {
        ...state,
        offset: action.payload
      };
    }
    case constants.CONCEPT_AUTHOR_INDEX: {
      return {
        ...state,
        authorIndex: action.index,
        authorTopIndex: action.topIndex
      };
    }

    case constants.SET_CONCEPT_SORTING:
      return {
        ...state,
        sorting: action.payload,
        offset: 1,
        authorIndex: 5,
        authorTopIndex: 0,
        blockPagination: false
      };
    case constants.TEMP_LOADING:
      return {
        ...state,
        tempLoading: action.payload,
        tempConcepts: []
      };

    case constants.TEMP_CONCEPTS:
      return {
        ...state,
        tempConcepts: action.payload,
        tempLoading: false
      };

    case constants.BLOCK_PERSPECTIVE_LOADING:
      return {
        ...state,
        blockPagination: action.payload
      };

    case SET_MEMES_COUNT: // just grabbing facts count  from this action
      return {
        ...state,
        factsCount: action.factsCount
      };

    case constants.FACT_KEYWORD_FILTER:
      return {
        ...state,
        factKeywordFilter: action.payload
      };

    case constants.SET_KEYWORDS_COUNT_SORT:
      return {
        ...state,
        keywordsCountSort: action.payload
      };

    case constants.CONCEPT_SUBMIT_LOADING:
      return {
        ...state,
        conceptLoading: action.payload,
        conceptError: action.error
      };

    case constants.CONCEPT_SUBMIT_ERROR:
      return {
        ...state,
        conceptError: action.payload
      };

    case constants.TEXTISH_CONCEPTS:
      return {
        ...state,
        textishConcepts: action.payload
      };

    case constants.TEXTISH_LOADING:
      return {
        ...state,
        textishLoading: action.payload
      };

    case constants.TEXTISH_DATA_LOADING:
      return {
        ...state,
        textishDataLoading: action.payload
      };

    case constants.TEXTISH_SELECTED:
      return {
        ...state,
        textishSelected: action.payload
      };

    case constants.CONCEPT_RELATED_CONCEPTS:
      return {
        ...state,
        concepts: action.payload,
        loading: action.loading
      };

    case constants.SAVE_MEMES_TAGS_COCNEPTS:
      return {
        ...state,
        memesTagConcepts: action.payload
      };
    case constants.GET_UNVERIFIED_CONCEPTS_SUCCESS:
      return {
        ...state,
        unverifiedConcepts: action.payload,
        apiLoading: false
      };
    case constants.ADD_UNVERIFIED_CONCEPT_SUCCESS:
      return {
        ...state,
        unverifiedConcepts: [...state.unverifiedConcepts, action.payload],
        apiLoading: false
      };

    case constants.UNVERIFIED_CONCEPT_DELETE_LOADING:
      return {
        ...state,
        deleteUnverifiedConceptLoading: true
      };
    case constants.UNVERIFIED_CONCEPT_DELETE_SUCCESS:
      return {
        ...state,
        unverifiedConcepts: state.unverifiedConcepts.filter(e => e.id !== action.payload),
        deleteUnverifiedConceptLoading: false
      };
    case constants.UNVERIFIED_CONCEPT_WITH_NAME:
      return {
        ...state,
        unverifiedConcepts: state.unverifiedConcepts.filter(e => e.name !== action.payload),
        deleteUnverifiedConceptLoading: false
      };

    default:
      return state;
  }
};
