import React from "react";
import history from "../../../router/history";
import "./firstCol.css";

const MoreBlock = props => {
  const { perspectiveCount, memeTypeCount, selectedOption, subFilter } = props;

  const pageName = window.location.href.split("/")[3];

  const onMoreClickHandler = () => {
    let concept = subFilter.value;
    concept = concept.split(" ").join("-");
    history.push(`/${pageName}/${concept}`);
    props.removeSubFilter();
  };

  return (
    <div>
      <div className="moreDiv" onClick={onMoreClickHandler}>
        <p className="moreContent">
          {pageName === "Concepts" || pageName === "concepts" ? (
            <span style={{ fontWeight: "bold" }}>
              {memeTypeCount && perspectiveCount && memeTypeCount + perspectiveCount}
            </span>
          ) : (
            <span style={{ fontWeight: "bold" }}>{perspectiveCount ? perspectiveCount : 0}</span>
          )}{" "}
          More <span className="moreThirdKey">{subFilter.value}</span> <span>items</span>
        </p>
      </div>
      {
        <div className="backToAll" onClick={props.onClick}>
          Back to <span style={{ fontWeight: "bold" }}>{selectedOption.value}</span>{" "}
        </div>
      }
    </div>
  );
};

export default MoreBlock;
