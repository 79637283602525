import React, { useState } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";
import history from "../../../router/history";
import { clusterCategory, conceptCategory, keywordCategory } from "../../../constants/global";
import ToolTip from "react-portal-tooltip";
import RelatedConcepts from "../../Concept/cols/relatedConcepts";
import "./switchConcept.css";
import ConceptsList from "../../utills/conceptsSideBar/conceptsList";
import * as actions from "../../../actions/conceptClustersActions";
import { bindActionCreators } from "redux";
import { reloadPage } from "../../../helpers/navbar";
const pageName = window.location.href.split("/")[3];

const SwitchConceptModes = props => {
  const { selectedOption, keywords, lock } = props;
  const [leftActive, setLeftActive] = useState(false);
  const [rightActive, setRightActive] = useState(false);
  let toggleTime = null;

  //const [lock, setLock] = useState(false);

  // const handleLockShow = () => {
  //   setLock(!lock);
  // };

  const leftToggle = () => {
    setRightActive(false);
    setLeftActive(!leftActive);
  };

  const rightEnter = () => {
    if (selectedOption.category === clusterCategory) {
      props.relatedConcepts(selectedOption.value);
      clearTimeout(toggleTime);
      setRightActive(true);
    }
  };

  const rightClick = () => {
    history.push(`/${conceptCategory}/${selectedOption.slug}`);
    if (selectedOption.category === clusterCategory) {
    } else {
      reloadPage();
    }
  };

  const rightLeave = () => {
    toggleTime = setTimeout(() => {
      setRightActive(false);
    }, 200);
  };

  const handleChange = () => {
    if (selectedOption.category === clusterCategory || selectedOption.category === "Memes") {
      history.push(
        `/${conceptCategory}/${
          props.selectedOption.slug ? props.selectedOption.slug : props.selectedOption.value
        }`
      );
    } else {
      history.push(`/${clusterCategory}/${props.selectedOption.slug}`);
    }
  };

  const handleKeywordsCallBack = (index, concept) => {
    history.push(`/${conceptCategory}/${selectedOption.slug}?${keywordCategory}=${concept.id}`);
  };

  return (
    <div className="row">
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <h5>View</h5>
        </div> */}
      {/* <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <RelatedConcepts drawerMode={false} />
          </div>
          <div
            className="cursor-pointer hover-red"
            onClick={rightClick}
            // onMouseEnter={rightEnter}
            // onMouseLeave={rightLeave}
          >
            <p id="rightList">Single</p>
          </div>
        </div> */}

      {/* //////////// Commented Lock due to Merging Concept & Concept-Cluster Pages //////////// */}

      {/* {pageName && pageName == "dictionary" && pageName !== "Concepts" && (
        <div style={{ marginLeft: "15px", cursor: "pointer" }}>
          {lock ? (
            <i className="fa fa-lock fa-3x" aria-hidden="true" onClick={() => setLock(false)}></i>
          ) : (
            <i className="fa fa-unlock fa-3x" aria-hidden="true" onClick={() => setLock(true)}></i>
          )}
        </div>
      )} */}
      {rightActive && (
        <ToolTip active={rightActive} position="bottom" arrow="center" parent="#rightList">
          <div id="cluster-keywords-list">
            <ConceptsList concepts={keywords} conceptCallBack={handleKeywordsCallBack} />
          </div>
        </ToolTip>
      )}
    </div>
  );
};

SwitchConceptModes.propTypes = {};

const styles = {
  text: {
    fontSize: 12
  }
};

const mapStateToProps = state => {
  return {
    selectedOption: state.navbar.selectedOption,
    keywords: state.concepts.concepts
  };
};
const mapDispatchToProps = dispatch => {
  return {
    relatedConcepts: bindActionCreators(actions.getClusterRelated, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SwitchConceptModes);
