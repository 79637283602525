import * as navbarConstants from "../constants/navbar";
import * as constants from "../constants/cluster";
import * as memeConstants from "../constants/memes";
import * as services from "../services/cluster";
import * as conceptServices from "../services/concept";
import { getClusterItem } from "../helpers/cluster";
import * as perspectiveServices from "../services/perspectiveServices";
import conceptsSorter, { moveOriginalConceptToTop } from "../helpers/conceptHelpers";
import { sortAuthors } from "../helpers/authorHelpers";
import {
  SORT_BY_CONCEPTS,
  SORT_BY_LIKES,
  conceptCategory,
  authorCategory,
  keywordCategory,
  listCategory,
  clusterCategory,
  factCategory,
  newClusterCategory
} from "../constants/global";
import { sortPerspectivesByConcepts, sortPerspectivesByLove } from "../helpers/perspectiveHelpers";
import { RANDOMIZE_MEMES, SET_MEMES_COUNT } from "../constants/memes";
import { CONCEPT_RELATED_CONCEPTS, SET_RELATEDS } from "../constants/concepts";
import { AUTHOR_RELATEDS } from "../constants/authors";

export function combineMemePerspective(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.COMBINE_PERSPECTIVE_MEME,
        payload: data
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function getClusterDetail(name, filters = [], type, showPerspectives) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: false
      });
      // dispatch({
      //   type: constants.CLUSTER_LOADING,
      //   payload: true
      // });
      dispatch({
        type: constants.SET_SELECTED_CONCEPT_ID,
        payload: -1
      });
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: []
      });
      dispatch({
        type: navbarConstants.SELECTED_AUTHOR,
        payload: null
      });
      const pageName = window.location.href.split("/")[3];
      const response = await services.clusterDetailByName(name, filters, type, pageName);
      const result = response.data.data;

      let { data, concepts, authors, count } = result;

      concepts = concepts.sort(conceptsSorter);
      let selfTerm = data;
      concepts = concepts.filter(x => x.id !== selfTerm.id);
      concepts = [selfTerm, ...concepts];

      authors = sortAuthors(authors);
      const state = getState();
      const { limit } = state.conceptClusters;
      const { user, isAuthenticated } = state.auth;
      const category = type === "list" ? listCategory : clusterCategory;
      const selectedOption = getClusterItem(data, category);

      if (category === clusterCategory) {
        conceptServices
          .fetchBooksAndAuthors(selectedOption.booksCollection, selectedOption.authorGroup)
          .then(response => {
            dispatch({
              type: AUTHOR_RELATEDS,
              relatedAuthors: response.data.author
            });
            dispatch({
              type: navbarConstants.BOOKS,
              payload: response.data.books
            });
          })
          .catch(e => {
            dispatch({
              type: AUTHOR_RELATEDS,
              relatedAuthors: []
            });
            dispatch({
              type: navbarConstants.BOOKS,
              payload: []
            });
          });
      }
      const { factsCount, memesCount } = data;

      dispatch({
        type: SET_MEMES_COUNT,
        payload: memesCount,
        factsCount: factsCount
      });
      dispatch({
        type: navbarConstants.SET_SELECTED_OPTION,
        payload: selectedOption
      });
      dispatch({
        type: navbarConstants.SET_INSIGHTS,
        payload: count
      });
      dispatch({
        type: navbarConstants.AUTHORS,
        payload: authors
      });
      dispatch({
        type: navbarConstants.CONCEPTS,
        payload: concepts
      });
      if (showPerspectives) {
        const perspectives = await getPerspectives(
          data,
          authors,
          //  concepts,
          0,
          0,
          limit,
          dispatch
        );

        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: sortPerspectivesByConcepts(concepts, perspectives)
        });
      }

      dispatch({
        type: constants.CLUSTER_LOADING,
        payload: false,
        initialLoading: true
      });
    } catch (e) {
      console.log("erros is ", e);
      const state = getState();
      const { lock } = state.navbar;

      lock !== false &&
        dispatch({
          type: navbarConstants.HIDE_PAGES,
          payload: true
        });
      // dispatch({
      //   type: constants.CLUSTER_LOADING,
      //   payload: false
      // });
    }
  };
}

export function getClusterRelated(name) {
  return async (dispatch, getState) => {
    try {
      // dispatch({
      //     type: CONCEPT_RELATED_CONCEPTS,
      //     payload: [],
      //     loading: true
      // })
      const response = await services.clusterRelatedConcepts(name);
      const result = response.data.data;
      const concepts = result.data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      dispatch({
        type: CONCEPT_RELATED_CONCEPTS,
        payload: concepts,
        loading: false
      });
    } catch (e) {
      console.log("erros is ", e);
    }
  };
}

export async function getPerspectives(
  concept,
  authors,
  // concepts,
  conceptIndex,
  offset,
  limit,
  dispatch
) {
  let temp = [];
  // let a = offset;
  let currentIndex = conceptIndex;
  const authorIds = authors.map(a => a.id);

  if (concept.relation === keywordCategory) {
    const response2 = await perspectiveServices.perspectivesByKeywords(
      // id,
      concept.id
    );
    const data = response2.data.data;
    dispatch({
      type: navbarConstants.KEYWORDS,
      payload: response2.data.keywords
    });
    if (data) {
      temp = [...temp, ...data.perspectiveKeywords];
    }
  } else {
    const response2 = await perspectiveServices.getPerspectivesByLikes(
      authorIds,
      concept.id,
      offset,
      limit,
      true
    );
    dispatch({
      type: navbarConstants.KEYWORDS,
      payload: response2.data.keywords
    });

    temp = [...temp, ...response2.data.data];
  }
  dispatch({
    type: constants.CLUSTER_OFFSET,
    payload: offset + 1
  });

  // while (currentIndex <= concepts.length - 1) {
  // dispatch({
  //   type: constants.CLUSTER_OFFSET,
  //   payload: a + 1
  // });
  //   if (temp.length >= limit) break;
  //   else {
  //     a = 0;
  //     currentIndex++;
  // dispatch({
  //   type: constants.CLUSTER_CONCEPT_INDEX,
  //   index: currentIndex
  // });
  //   }
  //   const concept = concepts[currentIndex];
  // }
  return temp;
}

export function handleDownPagination() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        conceptClusters: {
          sorting,
          limit,
          offset,
          conceptIndex,
          conceptTopIndex,
          selectedConceptId
        },
        navbar: { concepts, authors, selectedOption }
      } = state;
      let data = [];

      if (selectedConceptId >= 0) {
        const myIndex = concepts.findIndex(concept => concept.id === selectedConceptId);
        if (myIndex >= 0) {
          dispatch({
            type: navbarConstants.PERSPECTIVE_LOADING,
            payload: true
          });
          const concept = concepts[myIndex];
          const response = await getPerspectives(
            selectedOption,
            authors,
            // [concept],
            0,
            offset,
            limit,
            dispatch
          );
          data = sortPerspectivesByConcepts(concepts, response);
          if (data.length < limit) {
            dispatch({
              type: constants.CONCEPT_PERSPECTIVE_FINISH,
              payload: true
            });
          }
        }
      } else {
        dispatch({
          type: navbarConstants.PERSPECTIVE_LOADING,
          payload: true
        });

        if (sorting === SORT_BY_CONCEPTS && concepts.length - 1 > conceptIndex) {
          const response = await getPerspectives(
            selectedOption,
            authors,
            // concepts,
            conceptIndex,
            offset,
            limit,
            dispatch
          );

          data = sortPerspectivesByConcepts(concepts, response);
        } else if (sorting === SORT_BY_LIKES) {
          const conceptIds = concepts.map(x => x.id);
          const authorIds = authors.map(a => a.id);
          const response = await perspectiveServices.getPerspectivesByLikes(
            authorIds,
            conceptIds,
            offset,
            limit
          );
          if (response.status === 200) {
            data = response.data.data;
          }
          dispatch({
            type: constants.CLUSTER_OFFSET,
            payload: 1 + offset
          });
        }
      }
      let perspectives = state.navbar.perspectives;
      let newPerspectives = [...perspectives, ...data];
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: newPerspectives
      });

      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: false
      });
    } catch (e) {
      console.log("errro is ", e);
    }
  };
}

export function perspectivesByFilter(inputValue) {
  return async (dispatch, getState) => {
    dispatch({
      type: navbarConstants.LOADING_START
    });
    try {
      const state = getState();
      const {
        navbar: { concepts, authors },
        autherCluster: { authorGroups }
      } = state;
      let conceptIds = [];
      let authorIds = [];

      if (inputValue.type === factCategory) {
        conceptIds = concepts.map(x => x.id);
        const response = await perspectiveServices.getFactsByConcepts(conceptIds);
        if (response.status === 200) {
          const { data } = response.data;
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: data
          });
        }
      } else {
        if (inputValue.type === conceptCategory) {
          conceptIds = [inputValue.id];
          authorIds = authors.map(x => x.id);
        }
        if (inputValue.type === authorCategory) {
          conceptIds = concepts.map(x => x.id);
          authorIds = [inputValue.id];
        }
        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
          authorIds,
          conceptIds
        );
        const sorted = sortPerspectivesByLove(response.data.data);
        if (response.status === 200) {
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: sorted
          });
        }
        dispatch({
          type: constants.CLUSTER_LOADING,
          payload: false,
          initialLoading: true
        });
      }
    } catch (e) {
      console.log("error is ", e);
    }
    dispatch({
      type: navbarConstants.LOADING_END
    });
  };
}
export function setClusterSorting(sorting) {
  return dispatch => {
    dispatch({
      type: constants.SET_CLUSTER_SORTING,
      payload: sorting
    });
  };
}
export function toggleClusterPagination() {
  return async (dispatch, getState) => {
    dispatch({
      type: navbarConstants.LOADING_START
    });
    try {
      const state = getState();
      const { sorting, limit } = state.conceptClusters;
      const { concepts, authors, selectedOption } = state.navbar;
      let data = [];
      if (sorting === SORT_BY_CONCEPTS) {
        dispatch({
          type: constants.SET_CLUSTER_SORTING,
          payload: SORT_BY_LIKES
        });
        dispatch({
          type: constants.CLUSTER_OFFSET,
          payload: 1
        });
        const conceptIds = concepts.map(c => c.id);
        const authorIds = authors.map(a => a.id);
        const response = await perspectiveServices.getPerspectivesByLikes(
          authorIds,
          conceptIds,
          0,
          limit
        );
        if (response.status === 200) {
          data = response.data.data;
        }
      } else {
        const ids = concepts.slice(0, 2).map(x => x.id);
        const authorIds = authors.map(a => a.id);
        data = await getPerspectives(
          selectedOption,
          authors,
          //  concepts,
          0,
          0,
          limit,
          dispatch
        );

        dispatch({
          type: constants.SET_CLUSTER_SORTING,
          payload: SORT_BY_CONCEPTS
        });
        dispatch({
          type: constants.CLUSTER_CONCEPT_INDEX,
          index: 2
        });
      }
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: data
      });
      dispatch({
        type: constants.SET_SELECTED_CONCEPT_ID,
        payload: -1
      });
      dispatch({
        type: constants.BLOCK_CLUSTER_PAGINATION,
        payload: false
      });
    } catch (e) {
      console.log("error is ", e);
    }
    dispatch({
      type: navbarConstants.LOADING_END
    });
  };
}

export function authorCallBack(author) {
  return async (dispatch, getState) => {
    dispatch({
      type: navbarConstants.LOADING_START
    });
    try {
      const state = getState();
      const { concepts, selectedOption } = state.navbar;
      let data = [];
      const ids = concepts.map(x => x.id);
      const selectedConceptId = selectedOption.id;

      dispatch({
        type: navbarConstants.SELECTED_AUTHOR,
        payload: author
      });

      dispatch({
        type: memeConstants.MEME_PAGE_MEMES,
        payload: []
      });
      dispatch({
        type: memeConstants.SAVE_MEMES,
        payload: {
          memes: [],
          fetchLoading: false
        }
      });
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: []
      });

      const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
        author.id,
        selectedConceptId
      );
      if (response.status === 200) {
        data = sortPerspectivesByConcepts(concepts, response.data.data);

        dispatch({
          type: navbarConstants.SELECTED_AUTHOR,
          payload: author
        });

        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: data
        });
        dispatch({
          type: constants.SET_SELECTED_CONCEPT_ID,
          payload: selectedConceptId
        });

        dispatch({
          type: constants.BLOCK_CLUSTER_PAGINATION,
          payload: true
        });

        dispatch({
          type: constants.SET_CLUSTER_SELECTED_AUTHOR,
          payload: author.id
        });
      }
    } catch (e) {
      console.log("error is ", e);
    }
    dispatch({
      type: navbarConstants.LOADING_END
    });
  };
}

export function conceptCallBack(index, concept) {
  return async (dispatch, getState) => {
    dispatch({
      type: navbarConstants.LOADING_START
    });
    try {
      const state = getState();
      const { authors, selectedOption } = state.navbar;
      const ids = authors.map(x => x.id);
      let perspectives = [];
      const { limit, offset } = state.conceptClusters;
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: []
      });
      dispatch({
        type: navbarConstants.SELECTED_AUTHOR,
        payload: null
      });
      if (concept.relation === keywordCategory) {
        const response = await perspectiveServices.perspectivesByKeywords(
          selectedOption.id,
          concept.id
        );
        if (response.status === 200) {
          const perspectiveKeywords = response.data.data.perspectiveKeywords;
          if (perspectiveKeywords) {
            dispatch({
              type: navbarConstants.AUTHORS,
              payload: response.data.data.authors
            });
            perspectives = perspectiveKeywords.perspectiveKeywords;
          }
        }
      } else {
        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthorsPagination(
          ids,
          concept.id,
          0,
          limit
        );
        if (response.status === 200) {
          dispatch({
            type: constants.CLUSTER_OFFSET,
            payload: offset + 1
          });
          dispatch({
            type: navbarConstants.AUTHORS,
            payload: response.data.data.authors
          });
          perspectives = response.data.data.perspectives;
        }
      }
      const sorted = sortPerspectivesByLove(perspectives);
      dispatch({
        type: RANDOMIZE_MEMES
      });
      if (sorted.length < limit) {
        dispatch({
          type: constants.CONCEPT_PERSPECTIVE_FINISH,
          payload: true
        });
      }
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: sorted
      });
      dispatch({
        type: constants.SET_SELECTED_CONCEPT_ID,
        payload: concept.id
      });

      const clusters = await conceptServices.getClusterConcepts({
        id: concept.id,
        type: newClusterCategory
      });
      dispatch({
        type: SET_RELATEDS,
        payload: {
          concepts: clusters.data.data
        }
      });
    } catch (e) {
      console.log("error is ", e);
    }
    dispatch({
      type: navbarConstants.LOADING_END
    });
  };
}

export const setFactConceptFilterId = id => {
  return dispatch => {
    dispatch({
      type: constants.SET_CONCEPT_FILTER_ID,
      payload: id
    });
  };
};

export const getFactsByConceptId = id => {
  return async dispatch => {
    const response = await perspectiveServices.getFactsByConcepts(id);
    dispatch({
      type: navbarConstants.PERSPECTIVES,
      payload: response.data.data
    });
  };
};

export const blockClusterPagination = block => {
  return async dispatch => {
    dispatch({
      type: constants.BLOCK_CLUSTER_PAGINATION,
      payload: block
    });
  };
};

export const setClusterPagination = finish => {
  return async dispatch => {
    dispatch({
      type: constants.CONCEPT_PERSPECTIVE_FINISH,
      payload: finish
    });
  };
};
