import { combineReducers } from 'redux';
import auth from "./auth";
import navbar from "./navbar";
import concepts from "./concepts";
import conceptClusters from "./conceptClusters";
import author from "./author";
import keyword from './keyword';
import autherCluster from "./autherCluster";
import archives from "./archives";
import memes from './memes'
import books from './books'
import mobile from './mobile'
import pos from './pos';
import feedlikes from './feedlikes'
export default combineReducers({
    auth,
    concepts,
    navbar,
    keyword,
    author,
    conceptClusters,
    autherCluster,
    archives,
    memes,
    books,
    mobile,
    pos,
    feedlikes
})