export const removeDuplicate = (array) => {
  let ids = array.map(o => o.id)
  let filtered = array.filter(({ id }, index) => !ids.includes(id, index + 1))
  return filtered;
}


export const isConceptAuthorRouteOpen = () => {
  if (window.location.href) {
    return window.location.href.split("/")[5];
  }
}

export const getConceptAndAuthorName = () => {
  if (window.location.href) {
    const concept = window.location.href.split("/")[4];
    const route = window.location.href.split("/")[5];
    if (concept && route) {
      const conceptName = concept.split('-').join(' ');
      const authorName = route.split('-').join(' ');
      return { conceptName, authorName }
    }
  }
}