export const REQUEST_STARTED = "REQUEST_STARTED";
export const SET_PERSPECTIVES_UP = "SET_PERSPECTIVES_UP";
export const SET_PERSPECTIVES_DOWN = "SET_PERSPECTIVES_DOWN";
export const SET_PERSPECTIVES = "SET_PERSPECTIVES";
export const KEYWORDS_AND_PERSPECTIVES = "KEYWORDS_AND_PERSPECTIVES";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const CONCEPT_RELATEDS = "CONCEPT_RELATEDS";
export const LIST_RELATED_LOADING = "LIST_RELATED_LOADING";
export const SET_RELATEDS = "SET_RELATEDS";
export const END_RELATED_LOADING = "END_RELATED_LOADING";
export const SET_AUTHORGROUPS = "SET_AUTHORGROUPS";
export const PERSPECTIVE_LIKE = "PERSPECTIVE_LIKE";
export const CONCEPT_PAGE_MOUNTED = "CONCEPT_PAGE_MOUNTED";
export const CONCEPT_SORT_TYPE = "CONCEPT_SORT_TYPE";
export const SORT_BY_LIKE = "like";
export const SORT_BY_AUTHOR = "author";
export const CONCEPT_LOADING = "CONCEPT_LOADING";
export const CONCEPT_OFFSET = "CONCEPT_OFFSET";
export const SET_CONCEPT_SORTING = "SET_CONCEPT_SORTING";
export const FACT_KEYWORD_FILTER = "FACT_KEYWORD_FILTER";
export const SET_KEYWORDS_COUNT_SORT = "SET_KEYWORDS_COUNT_SORT";
export const CONCEPT_AUTHOR_INDEX = "CONCEPT_AUTHOR_INDEX";
export const TEMP_CONCEPTS = "TEMP_CONCEPTS";
export const TEMP_LOADING = "TEMP_LOADING";
export const BLOCK_PERSPECTIVE_LOADING = "BLOCK_PERSPECTIVE_LOADING";
export const CONCEPT_SUBMIT_LOADING = "CONCEPT_SUBMIT_LOADING";
export const CONCEPT_SUBMIT_ERROR = "CONCEPT_SUBMIT_ERROR";
export const TEXTISH_CONCEPTS = "TEXTISH_CONCEPTS";
export const TEXTISH_LOADING = "TEXTISH_LOADING";
export const TEXTISH_DATA_LOADING = "TEXTISH_DATA_LOADING";
export const TEXTISH_SELECTED = "TEXTISH_SELECTED";
export const CONCEPT_RELATED_CONCEPTS = "CONCEPT_RELATED_CONCEPTS";
export const SAVE_MEMES_TAGS_COCNEPTS = "SAVE_MEMES_TAGS_COCNEPTS";
export const GET_UNVERIFIED_CONCEPTS_SUCCESS = "GET_UNVERIFIED_CONCEPTS_SUCCESS";
export const UNVERIFIED_CONCEPT_DELETE_SUCCESS = "UNVERIFIED_CONCEPT_DELETE_SUCCESS";
export const UNVERIFIED_CONCEPT_WITH_NAME = "UNVERIFIED_CONCEPT_WITH_NAME";
export const ADD_UNVERIFIED_CONCEPT_SUCCESS = "ADD_UNVERIFIED_CONCEPT_SUCCESS";
export const PERSPECTIVE_COUNT = "PERSPECTIVE_COUNT";
export const UNVERIFIED_CONCEPT_DELETE_LOADING = "UNVERIFIED_CONCEPT_DELETE_LOADING";
export const GET_CHAR_RELATED_ALL_CONCEPTS = "GET_CHAR_RELATED_ALL_CONCEPTS";
