import React, { Fragment } from "react";
import PropTypes from "prop-types";
import KeywordButton from "./keywordButton";
import history from "../../../../router/history";
import { conceptCategory } from "../../../../constants/global";
import { compose } from "redux";

const KeywordList = props => {
  const { keywords, perspective, showKeywordButton, focus, setFocus, author } = props;
  console.log("keywords", keywords);
  const pageName = window.location.href.split("/")[3];
  const currentConcept = window.location.href.split("/")[4];
  if (pageName === "Concepts") {
    const isKeywordExist = keywords.some(e => e.name === "Definition");
    console.log(isKeywordExist, "valueeep");
    // !isKeywordExist && keywords.unshift({ name: "Definition" });
  }
  if (author.lastName === "Proverbs" || author.lastName === "proverbs") {
    const isProverbExist = keywords.some(e => e.name === "Proverb");
    !isProverbExist && keywords.unshift({ name: "Proverb" });
  }

  const getKeywordColor = k => {
    if (k.name === "Definition") {
      return "#6e77ff";
    } else if (k.name === "Proverb") {
      return "blue";
    } else {
      return "black";
    }
  };

  const getPath = data => {
    if (pageName === "Authors") {
      history.push(`/Concepts/${data.slug ? data.slug : data.name}`);
    } else {
      if (data.name === "Definition") {
        history.push(`/dictionary/${currentConcept}`);
      } else if (data.name === "Proverb") {
        history.push(`/proverbs/${currentConcept}`);
      } else {
        history.push(`/${pageName}/${data.slug ? data.slug : data.name}`);
      }
    }
  };

  const onClick = data => {
    data.label = data.value = data.name;
    data.category = conceptCategory;
    getPath(data);
    //history.push(`/${pageName}/${data.slug ? data.slug : data.name}`);
    //props.onKeywordClick(data);
  };

  return (
    <div>
      {showKeywordButton && (
        <Fragment>
          <KeywordButton focus={focus} setFocus={setFocus} perspective={perspective} />
          {" | "}
        </Fragment>
      )}
      {keywords &&
        keywords.map((k, index) => {
          console.log(k, "eeeeee");
          return (
            <div id={k.name} key={index} style={{ display: "inline" }} onClick={() => onClick(k)}>
              <span
                id={k.name}
                className="perspective-keywords"
                style={{ fontSize: 12, color: getKeywordColor(k) }}
              >
                {keywords.length > 1 && k.name + " · "}
                {keywords.length < 2 && k.name + " "}
              </span>
              {/* <span
                className="perspective-keywords"
                style={{ fontSize: 12, color: getKeywordColor(k) }}
              >
                Definition
              </span> */}
            </div>
          );
        })}
    </div>
  );
};

KeywordList.prototype = {
  keywords: PropTypes.array.isRequired,
  perspectiveId: PropTypes.number.isRequired,
  showKeywordButton: PropTypes.bool.isRequired,
  onKeywordClick: PropTypes.func.isRequired
};

export default React.memo(KeywordList);
const styles = {
  wrapper: {
    display: "inline"
  }
};
