import { BASE_URL } from "../constants/global"
import axios from 'axios'

export const increaseUpVoteBook = (bookId,collectionId, value,userId) => {
    return axios.put(`${BASE_URL}/books/collection/increaseUpVoteBook`,{bookId,collectionId,value,userId})
}

export const increaseAuthorUpVoteBook = (bookId,authorId, value,userId) => {
    return axios.put(`${BASE_URL}/books/authors/increaseUpVoteBook`,{bookId,authorId,value,userId})
}

export const getRelatedCollectionsById = collectionId => {
    return axios.get(`${BASE_URL}/books/collections/related/${collectionId}`)
}

export const getAllCollections = () => {
    return axios.get(`${process.env.REACT_APP_SERVER}/api/rest/v1/books/collections/all`);
}

export const addCollectionBook = (bookId, collectionId) => {
    return axios.post(`${BASE_URL}/books/add-collection`, {bookId, collectionId},)
}

export const removeCollectionBook = (bookId, collectionId) => {
    return axios.post(`${BASE_URL}/books/remove-collection`, {bookId, collectionId},)
}

export const getBooksByCollection = (id, limit = null, userId) => {
    return axios.get(`${BASE_URL}/books/collection/${id}?limit=${limit}&userId=${userId}`)
}

export const getBooksByCollectionByPagination = (id, limit,offset, userId) => {
    return axios.get(`${BASE_URL}/books/collection/pagination/${id}?limit=${limit}&userId=${userId}&offset=${offset}`)
}


export const getBooksByAuthorId = (authorId, userId) => {
    return axios.get(`${BASE_URL}/books/author/${authorId}?userId=${userId}`)
}

export const upVoteBook = (bookId, down = false, collectionId, userId) => {
    return axios.put(`${BASE_URL}/books/collection/upVote/${bookId}`, { down, collectionId, userId })
}

export const downVoteBook = (bookId, down = false, collectionId, userId) => {
    return axios.put(`${BASE_URL}/books/collection/downVote/${bookId}`, { down, collectionId, userId })
}

export const upVoteAuthorCollectionBook = (bookId, down = false, authorId, userId) => {
    return axios.put(`${BASE_URL}/books/author/upVote/${bookId}`, { down, authorId, userId })
}

export const downVoteAuthorCollectionBook = (bookId, down = false, authorId, userId) => {
    return axios.put(`${BASE_URL}/books/author/downVote/${bookId}`, { down, authorId, userId })
}

export const getGroupBooksByLimit = (groupId, limit) => {
    return axios.get(`${BASE_URL}/books/group/${groupId}?limit=${limit}`)
}

export const likeBookDescription = (bookDescriptionId, disLike = false) => {
    return axios.get(`${BASE_URL}/books/like/${bookDescriptionId}/?disLike=${disLike}`)
}

export const addRelatedCollection = (collectionId, relatedCollectionId) => {
    return axios.put(`${BASE_URL}/collection/related/${collectionId}`, { relatedCollectionId });
}

export const addRelatedCollectionFromSearch = (collectionId, relatedCollectionId) => {
    return axios.put(`${BASE_URL}/collection/search-related/${collectionId}`, { relatedCollectionId });
}

export const removeRelatedCollection = (collectionId, relatedCollectionId) => {
    return axios.delete(`${BASE_URL}/collection/related/${collectionId}`, { data: { relatedCollectionId } });
}