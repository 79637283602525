import * as colors from "./colors";
export const authorCategory = "Authors";
export const keywordCategory = "Keyword";
export const conceptCategory = "Concepts";
export const clusterCategory = "Concept-Clusters";
export const newClusterCategory = "dictionary of";
export const listCategory = "Concept-Lists";
export const groupsCategory = "AuthorGroups";
export const newsCategory = "News";
export const groupsServerCategory = "author-groups";
export const booksCategory = "books";
export const similarAuthorCategory = "Similar-Authors";
export const singleBook = "single-Book";
export const memesCategory = "memes";
export const QLCategory = "QL";
export const posCategory = "pos";
export const allCategory = "All";
export const factCategory = "trivia";
export const singleBookCategory = "Book";
export const NONE_PRONOUN = "0";
export const BACK_CODE = "POP";
export const backgroundColorsByCategory = {
  [authorCategory]: colors.LIGHT_RED,
  [conceptCategory]: colors.MAIN_WHITE,
  [clusterCategory]: colors.OPACITY_LIGHT_PINK,
  [groupsCategory]: colors.OPACITY_LIGHT_GREEN,
  [memesCategory]: colors.LIGHT_YELLOW,
  [similarAuthorCategory]: colors.BABY_BLUE,
  [singleBook]: colors.GOLD_BROWN
};
export const SORT_BY_LIKES = "SORT_BY_LIKES";
export const SORT_BY_CONCEPTS = "SORT_BY_CONCEPTS";
export const SORT_BY_AUTHORS = "SORT_BY_AUTHORS";
export const SORT_BY_RANKING = "SORT_BY_RANKING";
export const SORT_BY_DATE = "SORT_BY_DATE";

export const ASCEND_SORT = "ASCEND_SORT";
export const DESCEND_SORT = "DESCEND_SORT";
export const SET_VIEWPORT = "SET_VIEWPORT";
export const BOOK_ERROR = "BOOK_ERROR";

export const SITE_TITLE = "dictionaryish";
export const SITE_TITLE_CAPITAL = "v1.dictionaryish.com";
export const SITE_DISPLAY_TITLE = "www.v1.dictionaryish.com";

export const PERSPECTIVE_EDIT_TYPE = "PERSPECTIVE_EDIT_TYPE";
export const PERSPECTIVE_NEW_TYPE = "PERSPECTIVE_NEW_TYPE";

export const MEME_EDIT_TYPE = "MEME_EDIT_TYPE";
export const MEME_NEW_TYPE = "MEME_NEW_TYPE";

export const BASE_URL = `${process.env.REACT_APP_SERVER}/api/rest/v1`;
