import React, { Fragment, useEffect } from "react";
import "./firstCol.css";
import Chip from "./chip";
import Search from "../../TopNavBar/search/search";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
const ConceptsList = props => {
  let { isAdmin, concepts, selectedOption, selectedId, lock } = props;
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const pageName = window.location.href.split("/")[3];
  const currentConcept = window.location.href.split("/")[4];

  // concepts = concepts.sort((a, b) => a.name.localeCompare(b.name));

  // const preservedConcept = concepts.filter(elm => elm.slug === currentConcept.toLowerCase());
  // const remainingConcepts = concepts.filter(elm => elm.slug !== currentConcept.toLowerCase());

  // let sortedConcepts = [...preservedConcept, ...remainingConcepts];

  // sortedConcepts = _.uniqBy(sortedConcepts, "id");

  // sortedConcepts = [...new Set(sortedConcepts)];

  return (
    <div
      style={{
        width: "auto",
        marginLeft: "15px",
        marginTop: pageName === "dictionary" ? "0px" : "5px"
      }}
    >
      {isAdmin && (
        <div style={{ width: "90%", marginLeft: "5%" }}>
          <Search
            //className="ml-3"
            value=""
            category="Concept"
            onChange={props.addClusterItem}
            placeholder="Add Concept"
          />
        </div>
      )}
      <ul
        id="conceptsList"
        style={{
          width: "auto",
          marginTop: "12px",
          marginLeft: "-20px",
          paddingRight: "10px",
          paddingLeft: "10px"
        }}
      >
        {concepts &&
          concepts.length > 0 &&
          concepts.map(c => (
            <li
              className="hover-grey"
              onMouseEnter={() => props.handleListMouseEnter(c)}
              onMouseLeave={props.handleListMouseLeave}
              data-tip
              data-for={`concept${c.id}`}
              style={selectedOption.category == "Concept-Clusters" ? { ...styles.listItem } : {}}
              id={`concept${c.id}`}
              key={c.id}
            >
              <Chip
                concept={c}
                onNameClick={props.onNameClick}
                onDeleteClick={concept => props.removeClusterItem(concept.id)}
                onReciprocalClick={props.handleReciprocalClick}
                showControls={isAdmin}
                hideDelete={false}
                selectedId={selectedId}
                lock={lock}
                selectedOption={selectedOption}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};

const styles = {
  list: {
    lineHeight: "1.1em",
    listStyle: "none",
    padding: "0px 0px 0px 22px",
    width: "100%",
    fontWeight: 700
  },
  tooltipStyle: {
    backgroundColor: "white !important"
  },
  listItem: {
    padding: "0px",
    cursor: "pointer"
  }
};

export default ConceptsList;
