import * as perspectiveServices from "../services/perspectiveServices";
import * as services from "../services/navbarServices";
import * as clusterServices from "../services/cluster";
import * as archiveServices from "../services/archiveServices";
import * as constants from "../constants/navbar";
import * as archiveConstansts from "../constants/archives";
import * as global from "../constants/global";
import history from "../router/history";
import * as scroll from "../helpers/scrolling";
import { getAuthorGroups } from "../helpers/authorGroups";
import queryString from "query-string";
import axios from "axios";
import * as navbarConstants from "../constants/navbar";
import * as conceptConstants from "../constants/concepts";
import { getPerspectiveIdsFromArchives } from "./archives";
import conceptsSorter from "../helpers/conceptHelpers";
import { SET_SELECTED_CONCEPT_ID } from "../constants/cluster";
import { sortPerspectivesByConcepts, sortPerspectivesByLove } from "../helpers/perspectiveHelpers";
import { toast } from "react-toastify";
import { getClusterItem } from "../helpers/cluster";
import { pageReloadscroll } from "../helpers/navbar";
import { reloadPage } from "../helpers/navbar";
import _ from "lodash";

export function uploadUserImage(userId, image) {
  return async dispatch => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/api/rest/v1/upload/user/image`,
      {
        userId,
        image
      }
    );
    dispatch({
      type: constants.UPLOAD_IMAGE,
      payload: image
    });
  };
}

export function flushState() {
  return dispatch => {
    dispatch({
      type: "FLUSH_NAV_BAR"
    });
  };
}

export function getUserImage(id) {
  return async dispatch => {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/rest/v1/get/user/image?id=${id}`
    );
    const img = res.data.img;
    dispatch({
      type: constants.UPLOAD_IMAGE,
      payload: img
    });
  };
}

export function thirdKey(thirdKey) {
  return dispatch => {
    dispatch({
      type: "THIRD_KEY",
      payload: thirdKey
    });
  };
}

export function isAuthor(name) {
  try {
    return async dispatch => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/rest/v1/isAuthor?concept=${name}`
      );
      dispatch({
        type: navbarConstants.IS_AUTHOR,
        payload: response.data.data
      });
    };
  } catch (e) {
    console.log("ERROR -> ", e);
  }
}

export function checkProverb(concept) {
  try {
    return async dispatch => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/rest/v1/isProverb?concept=${concept}`
      );
      dispatch({
        type: navbarConstants.SET_PROVERB,
        payload: response.data.data
      });
    };
  } catch (e) {
    console.log("ERROR -> ", e);
  }
}

export function lockIcon(lock) {
  return dispatch => {
    dispatch({
      type: navbarConstants.LOCK,
      payload: lock
    });
  };
}

export function userDefaultOption(option) {
  return dispatch => {
    dispatch({
      type: navbarConstants.USER_DEFAULT_OPTION,
      payload: option
    });
  };
}

export function fixArchiveIcon() {
  return dispatch => {
    dispatch({
      type: constants.ARCHIVE_MODE
    });
  };
}

export function setSubFilter(filter, popupRoute = false) {
  return dispatch => {
    dispatch({
      type: "ADD_SUB_FILTER",
      payload: filter
    });
  };
}

export function setPrimaryMode(mode) {
  return dispatch => {
    dispatch({
      type: constants.SET_PRIMARY_MODE,
      payload: mode
    });
  };
}

export function setPerspectives(perspectives) {
  return dispatch => {
    dispatch({
      type: constants.PERSPECTIVES,
      payload: perspectives
    });
  };
}

export function removeSubFilter() {
  return (dispatch, getState) => {
    const state = getState();
    const tags = state.navbar.tags;
    let path = `${window.location.pathname}`;
    if (!path.startsWith("/memes")) {
      if (tags.length > 0) path += `?${getFiltersUrl(tags)}`;
    }
    dispatch({
      type: "REMOVE_SUB_FILTER"
    });
  };
}

const redirectUrl = (selectedOption, tags) => {
  const { value, slug } = selectedOption;
  switch (tags) {
    case "dictionary":
      return `/dictionary/${slug ? slug : value}`;
    case "concept":
      return `/Concepts/${slug ? slug : value}`;
    case "book":
      return `/books/${selectedOption.id}`;
    case "trivia":
    case "one-liner":
    case "dad-joke":
    case "meme":
      return `/memes/${slug ? slug : value}?keyword=${tags}`;
    case "viral":
      return `/memes/${slug ? slug : value}`;
    case "adjVerbs":
      return `/pos/${selectedOption.term}?${selectedOption.grammar}`;
    case "proverb":
      return `/proverbs/${slug ? slug : value}`;

    default:
      return `/Authors/${slug ? slug : value}`;
  }
};

const changeSelectedItem = (dispatch, selectedItem, tags) => {
  const path = `${redirectUrl(selectedItem, tags)}?${getFiltersUrl(tags)}`;
  if (window.location.pathname !== path) {
    history.push(path);
  }
  dispatch({
    type: "SET_SELECTED_OPTION",
    payload: selectedItem
  });
  dispatch({
    type: "IS_RANDOM",
    payload: true
  });
};

const surpriseSelectedItem = (dispatch, selectedItem, tags) => {
  const path = `${redirectUrl(selectedItem, tags)}`;
  history.push(path);
  dispatch({
    type: "SET_SELECTED_OPTION",
    payload: selectedItem
  });
  dispatch({
    type: "IS_RANDOM",
    payload: true
  });
};

export function setSelectedItem(selectedItem) {
  return (dispatch, getState) => {
    const state = getState();
    const tags = state.navbar.tags;
    const memesFilter = state.navbar.memesFilter;
    changeSelectedItem(dispatch, selectedItem, tags);
    reloadPage();
  };
}

export function setSelectedCategory(selectedCategory) {
  return dispatch => {
    dispatch({
      type: "SET_SEARCH_CATEGORY",
      payload: selectedCategory
    });
  };
}

export function surpriseConcept(tagSurprise) {
  pageReloadscroll();
  return (dispatch, getState) => {
    dispatch({
      type: "RANDOM_LOADING",
      payload: true
    });
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/rest/v1/surprise/${tagSurprise}`)
      .then(response => {
        if (response.status === 200) {
          const state = getState();
          const tags = state.navbar.tags;

          surpriseSelectedItem(dispatch, response.data.data, tagSurprise);
          dispatch({
            type: "IS_RANDOM",
            payload: true
          });
          dispatch({
            type: "RANDOM_LOADING",
            payload: false
          });
        } else {
        }
      });
  };
}

export function openModal() {
  return dispatch => {
    dispatch({
      type: "OPEN_LOGIN"
    });
  };
}
export function closeModal() {
  return dispatch => {
    dispatch({
      type: "CLOSE_LOGIN"
    });
  };
}
export function toggleSearch() {
  return dispatch => {
    dispatch({
      type: "TOGGLE_SEARCH"
    });
  };
}
export function hideSearch() {
  return dispatch => {
    dispatch({
      type: "HIDE_SEARCH"
    });
  };
}
export function handleTags(tag, oldTags = []) {
  return async dispatch => {
    let filters = [];
    let queryFilters = queryString.parse(window.location.search).filters;

    if (typeof queryFilters === "string") {
      filters.push(queryFilters);
    } else {
      filters = queryFilters;
    }
    if (filters && filters.length > 0) {
      let author_group = await getAuthorGroups(filters);
      const newTags = addTagsToRoute(author_group, []);
      dispatch({
        type: "SET_TAGS",
        payload: newTags
      });
    }
  };
}
function addTagsToRoute(tag, oldTags = []) {
  let newTags = [];
  if (Array.isArray(tag)) {
    newTags = tag;
  } else {
    newTags = [...oldTags, tag];
  }
  const testPath = `${window.location.pathname}?${getFiltersUrl(newTags)}`;
  if (testPath !== window.location.pathname) {
    history.push(testPath);
  }
  return newTags;
}
export function addTag(tag, oldTags = []) {
  return dispatch => {
    const newTags = addTagsToRoute(tag, oldTags);
    dispatch({
      type: "SET_TAGS",
      payload: newTags
    });
  };
}
const getFiltersUrl = tags => {
  let filter = "";
  const firstFilter = tags[0];
  const secondFilter = tags[1];
  if (firstFilter) filter += `filters=${firstFilter.value}`;
  if (secondFilter) filter += `&&filters=${secondFilter.value}`;
  return filter;
};

export function socialLogin(name, email, id) {
  return (dispatch, getState) => {
    let obj = { email, name, id };
    const state = getState();
    return axios
      .post(`${process.env.REACT_APP_SERVER}/api/rest/v1/socialLogin`, obj)
      .then(response => {
        if (response.data.success) {
          const user = response.data.user;
          const { archives } = user;
          dispatch(loginSuccess(user));
          const { favouriteConcepts, favouriteAuthors } = user;
          dispatch({
            type: constants.ADD_FAVOURITE_CONCEPT,
            payload: favouriteConcepts
          });

          dispatch({
            type: constants.ADD_FAVOURITE_AUTHOR,
            payload: favouriteAuthors
          });
          const { primaryMode } = state;
          dispatch({
            type: constants.MODAL_OPEN,
            payload: false
          });
          if (!primaryMode && (favouriteConcepts.length > 0 || favouriteAuthors.length > 0)) {
            handlePostLogin(dispatch, getState, archives);
          }
          if (primaryMode) {
            resetCounts(dispatch, user.id, getState);
          }
        } else {
          dispatch(loginFailed());
        }
      })
      .catch(e => {
        dispatch(loginFailed());
      });
  };
}
export function searchFocus(name, username, id) {
  return dispatch => {
    dispatch({ type: "SET_FOCUS" });
  };
}
export function removeFocus(name, username, id) {
  return dispatch => {
    dispatch({ type: "UNSET_FOCUS" });
  };
}
export  function likePerspective(like){
  try {
    return async (dispatch, getState) => {
      
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/rest/v1/perspective/like`,
        { like  }
      );
      
      if (response.status === 200) return true;
      else return false;
    }

    }
    catch (e)
      {
      return false;
    }
   
}
export function likeMeme(obj) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const { like, meme } = obj;
    return axios
      .post(`${process.env.REACT_APP_SERVER}/api/rest/v1/meme/like`, { like })
      .then(response => {
        if (response.status === 200) {
          let favoriteMemes = user.favoriteMemes ? user.favoriteMemes : [];
          if (like.type === "decrease") {
            if (user.favoriteMemes) {
              favoriteMemes = user.favoriteMemes.filter(e => e.id !== meme.id);
            }
          } else {
            favoriteMemes.push(meme);
          }
          dispatch({
            type: constants.UPDATE_FAVORITE_MEMES,
            payload: favoriteMemes
          });
        }
      })
      .catch(e => e);
  };
}
export function login(account) {
  return (dispatch, getState) => {
    let obj = {};
    obj.email = account.email;
    obj.password = account.password;
    dispatch(loginRequest());
    const state = getState();
    return axios
      .post(`${process.env.REACT_APP_SERVER}/api/rest/v1/login`, obj, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          accept: "application/json"
        }
      })
      .then(response => {
        if (response.data.success) {
          const user = response.data.user;
          dispatch({
            type: constants.FEED_MODE,
            payload: user.isFeedActivated
          });
          dispatch(loginSuccess(user));
          let { favouriteConcepts, favouriteAuthors, archives } = user;
          dispatch({
            type: constants.ADD_FAVOURITE_CONCEPT,
            payload: favouriteConcepts
          });
          dispatch({
            type: constants.ADD_FAVOURITE_AUTHOR,
            payload: favouriteAuthors
          });
          dispatch({
            type: constants.MODAL_OPEN,
            payload: false
          });
          const { primaryMode } = state.navbar;
          if (!primaryMode && (favouriteConcepts.length > 0 || favouriteAuthors.length > 0)) {
            handlePostLogin(dispatch, getState, archives);
          }
          if (primaryMode) {
            resetCounts(dispatch, user.id, getState);
          }
        } else {
          dispatch(loginFailed());
        }
      })
      .catch(e => {
        console.log("error is ", e);
        if (e.response && e.response.status === 405) {
          dispatch(emailNotVerified());
        } else {
          console.log("error is", e);
          dispatch(loginFailed());
        }
      });
  };
}

async function handlePostLogin(dispatch, getState, archives) {
  const perspectiveIds = getPerspectiveIdsFromArchives(archives);
  dispatch({
    type: archiveConstansts.SET_ARCHIVES,
    payload: [...archives],
    perspectiveIds
  });
  const state = getState();
  const { mode } = state.navbar;
  if (mode === constants.ARCHIVE_MODE) {
    let archive = archives.find(x => x.name === archiveConstansts.MY_ARCHIVE);
    if (!archive) {
      archive = archives[0];
    }
    handleFetchArchiveData(archive.id, dispatch, getState, false);
  } else if (mode === constants.FEED_MODE) {
    dispatch({
      type: constants.FEED_MODE,
      payload: true
    });
    reset(dispatch, getState);
  }
}

export function countTerms() {
  return async (dispatch, getState) => {
    const state = getState();
    const { id } = state.auth.user;
    resetCounts(dispatch, id, getState);
  };
}

async function resetCounts(dispatch, userId, getState) {
  try {
    // user is not required
    dispatch({
      type: constants.INITIAL_LOADING,
      payload: true
    });
    const response = await services.totalTermsWithTopTerms();
    const data = response.data.data;
    dispatch({
      type: constants.TOTAL_TERMS,
      payload: data
    });

    const response2 = await perspectiveServices.getInitialPerspectives(userId);
    const temp = response2.data.data;
    dispatch({
      type: archiveConstansts.ARCHIVE_PERSPECTIVES,
      payload: temp.tempPerspectives
    });
    const updatedState = getState();
    if (updatedState.navbar.mode !== constants.ARCHIVE_MODE) {
      dispatch({
        type: constants.PERSPECTIVES,
        payload: temp.likedPerspectives
      });
    }
    dispatch({
      type: constants.INITIAL_LOADING,
      payload: false
    });
  } catch (e) {
    dispatch({
      type: constants.INITIAL_LOADING,
      payload: false
    });
  }
}

export function loginRequest() {
  return {
    type: "LOGIN_REQUEST"
  };
}

export function loginSuccess(userInfo) {
  return {
    type: "LOGIN_SUCCESS",
    userInfo: userInfo
  };
}

export function loginFailed() {
  return {
    type: "LOGIN_FAILURE"
  };
}

export function emailNotVerified() {
  return {
    type: constants.EMAIL_NOT_VERIFIED_ERROR,
    payload: true
  };
}

export function logout() {
  return dispatch => {
    dispatch({
      type: constants.LOGOUT
    });
    dispatch({
      type: constants.EMPTY_FAVOURITES
    });
  };
}

export function signup(account) {
  return dispatch => {
    let obj = {};

    obj.name = account.name;
    obj.email = account.email;
    obj.password = account.password;
    obj.confirmPassword = account.rePassword;

    let temp = {};
    temp["User"] = obj;
    dispatch(signupRequest());
    return axios
      .post(`${process.env.REACT_APP_SERVER}/api/rest/v1/users`, temp, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          accept: "application/json"
        }
      })
      .then(response => {
        if (response.status === 200) {
          dispatch(signupSuccess(response.data.data));
        } else {
          if (response.status === 204) {
            dispatch(signupFailed("Email already Existed"));
          }
        }
      })
      .catch(e => {
        console.log("errro si ", e.response);
        if (e.response && e.response.status === 400) {
          dispatch(signupFailed(e.response.data.message));
        } else {
          dispatch(signupFailed("Network Error"));
        }
      });
  };
}

export function validateUsername(username) {
  return async dispatch => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/api/rest/v1/username/validate`,
      { username }
    );
    if (response.status === 200) {
      dispatch({
        type: "SET_USERNAME_MESSAGE",
        payload: response.data.message
      });
      dispatch({
        type: "SET_USERNAME_ERROR",
        payload: response.data.status
      });
    }
  };
}

export function signupRequest() {
  return {
    type: "SIGNUP_REQUEST"
  };
}

export function signupSuccess(userInfo) {
  return {
    type: "SIGNUP_SUCCESS",
    userInfo: userInfo
  };
}

export function signupFailed(message) {
  return {
    type: "SIGNUP_FAILED",
    payload: message
  };
}

export function setMode(mode) {
  return async (dispatch, getState) => {
    dispatch({
      type: constants.SET_MODE,
      payload: mode
    });
  };
}

export function setNewsMode(disableNewsSelect = false) {
  return async dispatch => {
    dispatch({
      type: constants.PERSPECTIVES,
      payload: []
    });
    const data = await services.getNews();
    const temp = data.data.data.sort((a, b) => a.priority - b.priority);
    let selectedId = -1;

    const params = queryString.parse(history.location.search);
    if (params.news) {
      dispatch({
        type: constants.SET_PRIMARY_MODE,
        payload: false
      });
      if (Array.isArray(params.news)) {
        selectedId = parseInt(params.news[0]);
      } else selectedId = parseInt(params.news);
      const index = temp.findIndex(news => news.id === selectedId);
      if (index < 0) {
        if (temp.length > 0) {
          selectedId = temp[0].id;
        }
      }
    } else {
      if (temp.length > 0) {
        selectedId = temp[0].id;
      }
    }
    if (selectedId >= 0) {
      dispatch({
        type: constants.SET_NEWS,
        payload: temp
      });
      dispatch({
        type: constants.SET_MODE,
        payload: constants.NEWS_MODE
      });
      let selected = temp.find(x => x.id === selectedId);
      selected.category = "concepts";
      dispatch({
        type: constants.SET_SELECTED_OPTION,
        payload: selected
      });
      if (!disableNewsSelect) {
        dispatch({
          type: constants.SET_SELECTED_NEWS,
          payload: selectedId
        });
        getNewsData(selected, dispatch);
      }
    }
  };
}

async function getNewsData(news, dispatch) {
  const pageName = window.location.href.split("/")[3];
  const response = await clusterServices.clusterDetailByName(news.name, [], "cluster", pageName);
  if (response.status === 200) {
    let { concepts } = response.data.data;
    concepts = concepts.filter(x => x.id !== news.id);
    const sortedConcepts = [news, ...concepts.sort(conceptsSorter)];
    dispatch({
      type: constants.CONCEPTS,
      payload: sortedConcepts
    });

    dispatch({
      type: SET_SELECTED_CONCEPT_ID,
      payload: news.id
    });
    const option = getClusterItem(news);
    option.category = global.newsCategory;
    dispatch({
      type: constants.SET_SELECTED_OPTION,
      payload: option
    });
    const perspectives = await getNewPerspectives(news.id, sortedConcepts[0]);
    const sorted = sortPerspectivesByLove(perspectives);
    dispatch({
      type: constants.PERSPECTIVES,
      payload: sorted
    });
  }
}

export function handleNewsClicked(news) {
  return async dispatch => {
    dispatch({
      type: constants.PERSPECTIVES,
      payload: []
    });
    dispatch({
      type: navbarConstants.LOADING_START
    });
    dispatch({
      type: constants.SET_PRIMARY_MODE,
      payload: false
    });
    getNewsData(news, dispatch);
    dispatch({
      type: navbarConstants.LOADING_END
    });
    dispatch({
      type: constants.SET_SELECTED_NEWS,
      payload: news.id
    });
  };
}

export function newsConceptCallBack(concept) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { selectedNewsId } = state.navbar;
      let selected = { ...concept, category: global.conceptCategory };
      dispatch({
        type: constants.SET_SELECTED_OPTION,
        payload: selected
      });
      dispatch({
        type: SET_SELECTED_CONCEPT_ID,
        payload: concept.id
      });
      const perspectives = await getNewPerspectives(selectedNewsId, concept);
      const sorted = sortPerspectivesByLove(perspectives);
      dispatch({
        type: constants.PERSPECTIVES,
        payload: sorted
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

async function getNewPerspectives(newsId, concept) {
  let perspectives = [];
  if (concept.relation === global.keywordCategory) {
    const response = await perspectiveServices.perspectivesByKeywords(newsId, concept.id);
    if (response.status === 200) {
      const data = response.data.data;
      if (data) {
        perspectives = data.perspectiveKeywords;
      }
    }
  } else {
    const response = await perspectiveServices.getPerspectivesByConcepts([concept.id]);
    if (response.status === 200) {
      perspectives = response.data.data;
    }
  }
  return perspectives;
}

export function resetModes() {
  return async (dispatch, getState) => {
    reset(dispatch, getState);
  };
}

export function reset(dispatch, getState) {
  scroll.resetScroll();
  // resetCounts(dispatch, -1, false)
  dispatch({
    type: constants.SET_MODE,
    payload: constants.LIKE_MODE
  });
  dispatch({
    type: constants.SET_PRIMARY_MODE,
    payload: false
  });
  fetchData(dispatch, getState);
}

export function resetFeeds() {
  return async dispatch => {
    dispatch({
      type: constants.SAVE_RECENT_PERSPECTIVES,
      payload: [],
      offset: 0,
      timeStamp: ""
    });
  };
}

export function fetchArchiveData(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: constants.SET_PRIMARY_MODE,
      payload: false
    });
    const selectedArchiveFromReducer = getState().auth.user;
    handleFetchArchiveData(id, dispatch, getState, false);
  };
}

export function setDefaultArchive(archiveId) {
  return dispatch => {
    dispatch({
      type: "SET_DEFAULT_ARCHIVE",
      payload: archiveId
    });
  };
}

export function fetchArchiveDataBySlug(slug) {
  return async (dispatch, getState) => {
    dispatch({
      type: constants.SET_PRIMARY_MODE,
      payload: false
    });
    handleFetchArchiveData(slug, dispatch, getState, true);
  };
}

export const emtptyData = () => {
  return dispatch => {
    dispatch({
      type: "EMPTY_DATA"
    });
    dispatch({
      type: constants.SET_MODE,
      payload: constants.ARCHIVE_MODE
    });
  };
};

const handleFetchArchiveData = async (id, dispatch, getState, slug = false) => {
  dispatch({
    type: constants.PERSPECTIVE_LOADING,
    payload: true
  });
  dispatch({
    type: constants.EMPTY_PAGE
  });
  scroll.resetScroll();
  dispatch({
    type: constants.SET_MODE,
    payload: constants.ARCHIVE_MODE
  });
  const state = getState();
  const {
    user: { token }
  } = state.auth;
  let response = null;
  if (slug) {
    response = await archiveServices.getArchiveDataBySlug(id);
  } else {
    response = await archiveServices.getArchiveDataById(id, token);
  }
  const archive = response.data.data;
  if (archive) {
    const { isPublic, user_id, slug } = archive;

    dispatch({
      type: archiveConstansts.SELECTED_ARCIVE,
      payload: archive.id
    });
    dispatch({
      type: archiveConstansts.SAVE_ARCHIVE_MEME,
      payload: archive.Memes
    });
    let perspectives = archive.Perspectives;
    let authors = perspectives.map(x => x.Author);
    let concepts = perspectives.map(x => x.Concept);
    authors = authors.filter(function(item, pos) {
      return authors.findIndex(i => i.id === item.id) === pos;
    });

    concepts = concepts.filter(function(item, pos) {
      return concepts.findIndex(i => i.id === item.id) === pos;
    });

    concepts = concepts.sort(conceptsSorter);

    perspectives = sortPerspectivesByConcepts(concepts, perspectives);

    dispatch({
      type: archiveConstansts.SET_ARCHIVE_META,
      payload: {
        isPublic,
        user_id,
        slug
      }
    });

    dispatch({
      type: constants.SET_ARCHIVE_DATA,
      perspectives,
      authors,
      concepts
    });
  }

  dispatch({
    type: constants.PERSPECTIVE_LOADING,
    payload: false
  });
};

export const setCurrentTimeStamp = () => {
  return dispatch => {
    dispatch({
      type: constants.SET_TIME_STAMP,
      payload: new Date()
    });
  };
};
export const refreshLikes = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { favouriteConcepts, favouriteAuthors } = state.auth;
    const lastUpdate = state.navbar.timeStamp;
    const conceptIds = favouriteConcepts.map(c => c.id);
    const authorIds = favouriteAuthors.map(a => a.id);
    let timeStamp = "";
    try {
      const response = await perspectiveServices.getNewLikes(lastUpdate, conceptIds, authorIds);
      const temp = response.data.data;
      const data = temp.perspectives,
        timeStamp = temp.maxTime;
      if (data.length > 0) {
        const old = state.navbar.newPerspectives;
        const newPerspectives = [...data, ...old];
        dispatch({
          type: constants.NEW_PERSPECTIVES,
          payload: newPerspectives
        });
      }
      dispatch({
        type: constants.SET_TIME_STAMP,
        payload: timeStamp
      });
    } catch (e) {
      console.log("error is ", e);
      dispatch({
        type: constants.SET_TIME_STAMP,
        payload: timeStamp
      });
    }
  };
};

export function fetchPerspectives() {
  return async (dispatch, getState) => {
    fetchData(dispatch, getState);
  };
}

export const fetchData = async (dispatch, getState) => {
  dispatch({
    type: constants.PERSPECTIVE_LOADING,
    payload: true
  });
  const state = getState();
  const { favouriteAuthors, favouriteConcepts } = state.auth;
  if (favouriteConcepts.length > 0 || favouriteAuthors.length > 0) {
    const { offset, limit, perspectives } = state.navbar;
    if (offset < 1) {
      dispatch({
        type: constants.LOADING_START
      });
    }
    const { favouriteConcepts, favouriteAuthors } = state.auth;
    const conceptIds = favouriteConcepts.map(x => x.id);
    const authorIds = favouriteAuthors.map(x => x.id);
    const response = await perspectiveServices.getRecentLikedPerspectives(
      offset,
      limit,
      conceptIds,
      authorIds
    );
    const temp = response.data.data;
    const data = offset < 1 ? [...temp.perspectives] : [...perspectives, ...temp.perspectives];
    dispatch({
      type: constants.SAVE_RECENT_PERSPECTIVES,
      payload: [...data],
      offset: offset + 1,
      timeStamp: temp.maxTime
    });
  } else {
    dispatch({
      type: constants.FEED_MODE,
      payload: false
    });
  }
  dispatch({
    type: constants.LOADING_END
  });
  dispatch({
    type: constants.PERSPECTIVE_LOADING,
    payload: false
  });
};

export const setFeedMode = mode => {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = state.auth.user.id;
    const response = await services.UpdateIsFeed(userId);
    if (response.status == 200) {
      dispatch({
        type: constants.FEED_MODE,
        payload: mode
      });
    }
  };
};

export const likeHomepagePerspective = perspective => {
  return (dispatch, getState) => {
    const id = perspective.id;
    const state = getState();
    const perspectives = state.navbar.perspectives;
    const index = perspectives.findIndex(x => x.id === id);
    if (index > -1) {
      perspectives[index] = perspective;
      dispatch({
        type: constants.SAVE_RECENT_PERSPECTIVES,
        payload: perspectives,
        offset: state.navbar.offset
      });
    }
  };
};

export const showNewLikes = perspective => {
  return (dispatch, getState) => {
    const state = getState();
    const newPerspectives = state.navbar.newPerspectives;
    const perspectives = state.navbar.perspectives;
    dispatch({
      type: constants.PERSPECTIVES,
      payload: [...newPerspectives, ...perspectives]
    });
    dispatch({
      type: constants.NEW_PERSPECTIVES,
      payload: []
    });
  };
};

export const emptyViewPort = () => {
  return dispatch => {
    dispatch({
      type: global.SET_VIEWPORT,
      payload: ""
    });
  };
};

export const addFavouriteConcept = concept => {
  return async (dispatch, getState) => {
    const state = getState();
    const { favouriteConcepts, isAuthenticated, user } = state.auth;

    const { favouriteLimit } = state.navbar;
    let already = favouriteConcepts.findIndex(x => x.id === concept.id);

    if (already < 0) {
      dispatch({
        type: constants.ADD_FAVOURITE_CONCEPT,
        payload: [...favouriteConcepts, concept]
      });
      if (isAuthenticated) {
        const response = await services.addFavouriteConcept(user.id, concept.id);
      }
    }
  };
};

export const addFavouriteAuthor = author => {
  return async (dispatch, getState) => {
    const state = getState();
    const { favouriteAuthors, isAuthenticated, user } = state.auth;
    const { favouriteLimit } = state.navbar;
    let already = favouriteAuthors.findIndex(x => x.id === author.id);
    if (already < 0) {
      dispatch({
        type: constants.ADD_FAVOURITE_AUTHOR,
        payload: [...favouriteAuthors, author]
      });
      if (isAuthenticated) {
        const response = await services.addFavouriteAuthor(user.id, author.id);
      }
    }
  };
};

export const removeFavouriteConcept = concept => {
  return async (dispatch, getState) => {
    const state = getState();
    const { favouriteConcepts, isAuthenticated, user } = state.auth;
    let concepts = favouriteConcepts.filter(x => x.id !== concept.id);
    if (concepts) {
      dispatch({
        type: constants.ADD_FAVOURITE_CONCEPT,
        payload: concepts
      });
      if (isAuthenticated) {
        await services.removeFavouriteConcept(user.id, concept.id);
      }
    }
  };
};

export const removeFavouriteAuthor = author => {
  return async (dispatch, getState) => {
    const state = getState();
    const { favouriteAuthors, isAuthenticated, user } = state.auth;
    let authors = favouriteAuthors.filter(x => x.id !== author.id);
    if (authors) {
      dispatch({
        type: constants.ADD_FAVOURITE_AUTHOR,
        payload: authors
      });
      if (isAuthenticated) {
        await services.removeFavouriteAuthor(user.id, author.id);
      }
    }
  };
};

export const emptyFavourites = author => {
  return async dispatch => {
    dispatch({
      type: constants.EMPTY_FAVOURITES
    });
  };
};

export const handleSearch = name => {
  return async dispatch => {
    dispatch({
      type: constants.SEARCH_LOADING,
      payload: true
    });
    dispatch({
      type: constants.HIDE_PAGES,
      payload: false
    });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/rest/v1/concepts/search/${name}`
      );
      if (response.status === 200) {
        const data = response.data.data;
        if (data.length > 0) {
          const temp = data[0];
          const testPath = `/${global.conceptCategory}/${name}`;
          if (testPath !== window.location.pathname) {
            history.push(testPath);
          }
          dispatch({
            type: constants.SEARCH_LOADING,
            payload: false
          });
        } else {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER}/api/rest/v1/authors/search/${name}`
          );
          if (response.status === 200) {
            const data = response.data.data;
            if (data.length > 0) {
              const temp = data[0];
              const testPath = `/${global.authorCategory}/${name}`;
              if (testPath !== window.location.pathname) {
                history.push(testPath);
              }
              dispatch({
                type: constants.SEARCH_LOADING,
                payload: false
              });
            } else {
              dispatch({
                type: constants.HIDE_PAGES,
                payload: true
              });
            }
          }
        }
      }
    } catch (e) {
      console.log("error is", e);
      dispatch({
        type: constants.SEARCH_LOADING,
        payload: false
      });
      dispatch({
        type: constants.HIDE_PAGES,
        payload: false
      });
    }
  };
};

export const setSelectedConcept = id => {
  return dispatch => {
    dispatch({
      type: constants.SELECTED_CONCEPT_ID,
      payload: id
    });
  };
};

export function setModal(modal) {
  return dispatch => {
    dispatch({
      type: "MODAL",
      modal: modal
    });
  };
}

export function setModelOpen(modalOpen) {
  return dispatch => {
    dispatch({
      type: "MODAL_OPEN",
      modalOpen: modalOpen
    });
  };
}

export function setSelectedOption(option) {
  return dispatch => {
    dispatch({
      type: constants.SET_SELECTED_OPTION,
      payload: option
    });
  };
}

export function handlePerspectiveSubmit(
  myAuthor,
  definition,
  pronoun,
  myOwn,
  authorLastName,
  original,
  citation,
  term,
  isFact
) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.auth;
      const authorId = myAuthor.id;
      const payload = {
        authorId,
        definition,
        myOwn,
        authorLastName,
        pronoun,
        term,
        original,
        citation,
        userId: user.id,
        isFact
      };
      dispatch({
        type: constants.ADD_PERSPECTIVES_LOADING,
        payload: true
      });
      const response = await services.handleNewPerspectiveSubmit(payload, user.token);
      if (response.status === 200) {
        dispatch({
          type: conceptConstants.UNVERIFIED_CONCEPT_WITH_NAME,
          payload: term
        });
        dispatch({
          type: constants.ADD_PERSPECTIVES_LOADING,
          payload: false
        });
        toast.success("Perspective Submitted");
      }
    } catch (e) {
      dispatch({
        type: constants.ADD_PERSPECTIVES_LOADING,
        payload: false
      });
      console.log("error is ", e);
    }
  };
}

export function handlePerspectiveFromBottomSubmit(
  definition,
  myOwn,
  pronoun,
  authorLastName,
  original,
  citation,
  term
) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.auth;
      const payload = {
        definition,
        myOwn,
        authorLastName,
        pronoun,
        term,
        original,
        citation,
        userId: user.id
      };
      const response = await services.handleNewPerspectiveSubmit(payload, user.token);
      if (response.status === 200) {
        toast.success("Perspective Submitted");
      }
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function handlePerspectiveUpdate(
  perspectiveId,
  pronoun,
  concept,
  author,
  description,
  longDescription,
  citation,
  isFact
) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const payload = {
        pronoun,
        concept_id: concept.id,
        author_id: author.id,
        description,
        longDescription,
        citation,
        isFact
      };
      const response = await services.updatePerspective(perspectiveId, payload);
      if (response.status === 200) {
        const { perspectives } = state.navbar;
        let perspectiveIndex = perspectives.findIndex(
          perspective => perspective.id === perspectiveId
        );
        let perspective = perspectives[perspectiveIndex];
        const newAuthor = { ...author, firstName: "", lastName: author.value };
        const newConcept = { ...concept, name: concept.value };
        perspectives[perspectiveIndex] = {
          ...perspective,
          pronoun,
          description,
          longDescription,
          citation,
          Concept: newConcept,
          Author: newAuthor,
          isFact: isFact
        };
        dispatch({
          type: constants.PERSPECTIVES,
          payload: [...perspectives]
        });
        toast.success("Perspective Updated Successfully");
      }
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function getConcept(id) {
  return async (dispatch, getState) => {
    try {
      const response = await services.getConcept(id);
      dispatch({
        type: navbarConstants.EDIT_CONCEPT,
        payload: response.data.data
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function handlePronounUpdate(data) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const response = await services.updatePronoun(data);
      const selectedOption = state.navbar.selectedOption;
      if (response.status === 200) {
        selectedOption.label = data.name;
        selectedOption.name = data.name;
        selectedOption.pronoun = data.pronoun;
        selectedOption.type = data.type;
        dispatch({
          type: constants.SET_SELECTED_OPTION,
          payload: { ...selectedOption }
        });
        toast.success("Concept Updated Successfully");
      }
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function handlePerspectiveDelete(perspectiveId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let { perspectives } = state.navbar;
      perspectives = perspectives.filter(perspective => perspective.id !== perspectiveId);
      const response = await services.deltePerspective(perspectiveId);
      if (response.status === 200) {
        toast.success("Perspective Deleted Successfully");
        dispatch({
          type: constants.PERSPECTIVES,
          payload: [...perspectives]
        });
      }
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function handleImageUpdate(formData) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { selectedOption } = state.navbar;
      const { authorDetail } = state.author;
      const { user } = state.auth;
      let response = null;
      dispatch({
        type: constants.IMAGE_UPLOADING,
        payload: true,
        status: false
      });
      if (selectedOption.category === global.conceptCategory) {
        response = await services.updateConceptImage(selectedOption.id, formData, user.token);
      }
      if (selectedOption.category === global.authorCategory) {
        formData.append("authorId", selectedOption.id);
        response = await services.updateAuthorImage(formData, user.token);
      }
      if (selectedOption.category === global.similarAuthorCategory) {
        formData.append("authorId", authorDetail.id);
        response = await services.updateAuthorImage(formData, user.token);
      }
      if (response && response.status === 200) {
        selectedOption.pictureLink = response.data.data;
        dispatch({
          type: constants.SET_SELECTED_OPTION,
          payload: { ...selectedOption }
        });

        dispatch({
          type: constants.IMAGE_UPLOADING,
          payload: false,
          status: true
        });
      }
    } catch (e) {
      dispatch({
        type: constants.IMAGE_UPLOADING,
        payload: false,
        status: false
      });
      console.log("error is ", e);
    }
  };
}
