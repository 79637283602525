import React from "react";
import PropTypes from "prop-types";
const EssentialBooks = props => {
  return (
    <div>
      <button
        type="button"
        onClick={props.onClick}
        style={{ marginLeft: "-10px" }}
        className="btn btn-danger btn-lg"
      >
        move to authors{" "}
      </button>
    </div>
  );
};
EssentialBooks.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default EssentialBooks;
