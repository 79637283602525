import React from 'react'
import PropTypes from 'prop-types'
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { FaFacebook } from 'react-icons/fa';
import {Button} from 'reactstrap'
import './SocialLogin.css'

const SocialLogin = (props) => {
    const googleId = `${process.env.REACT_APP_GOOGLE}`
    const facebookId = `${process.env.REACT_APP_FB}`
    const url = "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
    const responseFacebook = (response) => {
        console.log(response);
      }
    return (
        <div id="socialLoginWrapper" style={styles.socialLogin}>

            <FacebookLogin
                appId={facebookId}
                callback={responseFacebook}
                autoLoad={true}
                fields="name,email,picture"

                // disableMobileRedirect={true}
                // onClick={() => {console.log('clicked')}}
                // callback={(data) => {props.socialLogin(data.name,data.id,data.id)}} 
                // render={renderProps => (
                //     <Button  onClick={() => {
                //         console.log(renderProps);
                //         renderProps.onClick()
                //     }} id="socialButton" variant="contained" color="primary" style={styles.facebook}>
                //          <FaFacebook />
                //         Facebook Login
                //     </Button>
                // )}
            />
            <GoogleLogin
                clientId={googleId}
                render={renderProps => (
                    <Button onClick={renderProps.onClick} disabled={renderProps.disabled} id="socialButton"  style={styles.google} >
                        <img src={url} style={styles.img} alt="googleIcon"/>
                        <span>
                            Google Login
                        </span>
                    </Button>
                )}
                buttonText="Login"
                onSuccess={ data => {props.socialLogin(data.profileObj.name, data.profileObj.email, data.profileObj.googleId)}}
                onFailure={err => console.log('google error', err)}
                cookiePolicy={'single_host_origin'}
            />
        </div>
    )
}
SocialLogin.propTypes = {
    socialLogin: PropTypes.func.isRequired,
}
export default SocialLogin
const styles = {
    socialLogin: {
        // border: "1px solid #eee",
        padding: "20px 15px",
        // display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    google: {
        backgroundColor: 'white',
        color: 'green',
        marginTop: 20,
        width: 187,
        borderColor: '#dadada',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textTransform: 'none'
    },
    facebook: {
        borderRadius: 2,
        color: 'white',
        marginTop: 20,
        width: 187,
        textTransform: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    img: {
        width: 20
    }
}
