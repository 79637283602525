import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import history from "../../router/history";
import { bindActionCreators } from "redux";
import ArchiveButton from "../utills/PerspectiveBox/context/archiveButton";
import { connect, useSelector } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import ToolTip from "react-portal-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import SearchCategory from "./search/category";
import Search from "./search/search";
import FilterSearch from "./search/filterSearch";
import * as scroll from "../../helpers/scrolling";
// import SubPageMenuIcon from "../../images/subPageNavbarMenu.png";
import SubPageMenuIcon from "../../images/homeIcon.png";
import dice from "../../images/randomDice.ico";
import river from "../../images/iconRiver.jpg";
import logo from "../../images/dictionaryish.png";
import logoDefault from "../../images/icon.png";
import AddIconGreen from "../../images/imageIcons/addIconGreen.png";
import PageShare from "../utills/FacebookComment/PageShare";
import MemeUpload from "../Memes/memeUpload";
import PerspectiveForm from "../utills/form/perspectiveForm";
import AddAdvertise from "./navChild/AddAdvertise";
import MemeForm from "../utills/form/memeForm";
import MemeToolTipDiv from "./MemeToolTipDiv";

import * as navbarActions from "../../actions/navbaractions";
import * as conceptActions from "../../actions/conceptsActions";
import * as memesActions from "../../actions/memes";
import * as wackyActions from "../../actions/wackyWares";
import * as authorActions from "../../actions/authorActions";

import {
  setSelectedCategory,
  toggleSearch,
  surpriseConcept,
  logout
} from "../../actions/navbaractions";
import {
  getBgColor,
  placeHolder,
  getCategory,
  getSubHeading,
  getSurpriseCategory,
  getContent,
  isSearchCategory,
  subHeadingClick,
  categoryFilter,
  topFilter,
  getDiceBorder,
  getSearchbarColor
} from "./helpers";

import { PERSPECTIVE_NEW_TYPE, MEME_NEW_TYPE } from "../../constants/global";
import { normalizeAuthorCluster } from "../../schema/authorClusterSchema";

const NavSecondRow = props => {
  const {
    navBarData,
    selectedOption,
    isSearchEnable,
    setIsSearchEnable,
    user,
    loading,
    uploadSuccess,
    uploadFail,
    conceptError,
    conceptLoading,
    authorError,
    authorLoading,
    thirdKey,
    subFilter,
    clickSearch,
    setClickSearch = () => {},
    memeTags,
    surpriseClick
  } = props;

  const searchCategoryFilter = props.navbar.searchCategory;
  const [searchCategory, setSearchCategory] = useState(props.navbar.searchCategory);
  const [addAdvertise, setAddAdvertise] = useState(false);
  const [open, setOpen] = useState(false);
  const [viralToolTipShow, setViralToolTipShow] = useState(false);
  const [noOptionsMessage, setNoOptionsMessage] = useState("");
  const [filterSearch, setFilterSearch] = useState(false);
  const [subFilterState, setSubFilterState] = useState("");
  const [addOption, setAddOption] = useState("perspective");
  const [openAuthor, setOpenAuthor] = useState(false);
  const pageName = window.location.href.split("/")[3];
  const memeKey = window.location.href.split("=")[1];
  const conceptName = window.location.pathname.split("/")[2];

  useEffect(() => {
    setTimeout(() => {
      if (searchRef.current) {
        searchRef.current.focus();
      }
    }, 200);
  }, [navBarData, searchCategory, isSearchEnable, window.location.href]);

  const onSelectedCatagoryChange = e => {
    props.setSelectedCategory(e);
    setSearchCategory(e);
  };

  const showCount = () => {
    if (
      pageName === "Concepts" ||
      pageName === "concepts" ||
      pageName === "dictionary" ||
      pageName === "memes" ||
      pageName === "Authors" ||
      pageName === "author"
    ) {
      return true;
    }
  };

  const getCountOfConcept = () => {
    if (pageName === "Concepts" || pageName === "concepts") {
      if (selectedOption && selectedOption.count && selectedOption.memeCount) {
        let count = selectedOption.count + selectedOption.memeCount;
        return count;
      } else if (selectedOption && selectedOption.count && selectedOption.memeCount === 0) {
        return selectedOption.count;
      } else if (selectedOption && selectedOption.memeCount && selectedOption.count === 0) {
        return selectedOption.memeCount;
      }
    } else if (pageName === "dictionary") {
      return selectedOption.count;
    } else if (pageName === "memes") {
      return selectedOption.memeCount;
    } else if (pageName === "Authors" || pageName === "authors") {
      return selectedOption.count;
    }
  };

  const getPageTitle = () => {
    return (
      <>
        <p style={{ marginTop: "8px" }}>
          {selectedOption.value && `${selectedOption.value.toUpperCase()}`}
          {thirdKey && ` & ${thirdKey.toUpperCase()}`}
          {subFilter && ` & ${subFilter.value.toUpperCase()}`}
          {memeKey && `  ( ${memeKey.toUpperCase()} )`}
          {showCount() && selectedOption && selectedOption.value && (
            <>
              <sup>{`( ${getCountOfConcept()} )`}</sup>
            </>
          )}
        </p>
        <p
          style={{
            float: "left",
            fontSize: "8px",
            marginTop: "-24px",
            textTransform: "capitalize"
            // borderTop: "1px solid black"
          }}
        >
          {selectedOption &&
            conceptName &&
            selectedOption.value !== conceptName.split("-").join(" ") &&
            selectedOption.slug !== conceptName &&
            conceptName}
        </p>
      </>
    );
  };

  const HandleClickItem = item => {
    if (searchCategoryFilter !== "All") {
      categoryFilter(item, searchCategoryFilter);
    } else {
      topFilter(item, navBarData);
    }
    scroll.resetSecondCol();
  };

  const onTitleClick = item => {
    const route = `/book/${item.id}`;
    history.push(route);
  };

  const onCategoryClick = item => {
    const route = `/books/${item.id}`;
    history.push(route);
  };

  const onAuthorClick = item => {
    const route = `/books/author/${item.id}`;
    history.push(route);
  };

  const searchOnFocus = () => {
    setNoOptionsMessage("Start Typing");
  };

  const searchClickHandler = () => {
    setIsSearchEnable(!isSearchEnable);
    setClickSearch(!clickSearch);
  };

  const seArchBarClick = () => {
    setIsSearchEnable(true);
    setClickSearch(!clickSearch);
  };

  const handleMemeIconClick = () => {
    if (viralToolTipShow) {
      history.push("/");
      // window.location.reload();
    } else {
      setViralToolTipShow(true);
    }
  };

  const handleDoubleClick = () => {
    history.push("/");
    // window.location.reload();
  };

  const closeMemeToolTip = () => {
    setViralToolTipShow(false);
  };

  useEffect(() => {
    setViralToolTipShow(false);
  }, [window.location.href]);

  const searchRef = useRef();

  return (
    <div
      className="secondMainDiv"
      style={{
        backgroundColor:
          pageName === "authors" || pageName === "Authors"
            ? "#f8d3cf"
            : `${getBgColor(navBarData, thirdKey)}`
      }}
    >
      {/* DEFAULT LOGO */}

      <div className="defaultLogoDiv">
        <div>
          <img
            src={props.selectedOption.pictureLink ? props.selectedOption.pictureLink : logoDefault}
            className="logoImageDiv"
          />
        </div>
      </div>

      {/* DICTIONARYISH LOGO */}

      <div className="dictionaryLogoDiv">
        <img
          src={logo}
          className="dictionaryImageDiv"
          onClick={() => subHeadingClick(navBarData)}
        />
        <p className="subHeading" onClick={() => subHeadingClick(navBarData)}>
          {getSubHeading(navBarData)}
        </p>
      </div>
      <OutsideClickHandler onOutsideClick={closeMemeToolTip}>
        <div onMouseLeave={closeMemeToolTip} className="memeIconDiv">
          <div onDoubleClick={handleDoubleClick} id={`viralToolTip`} className="memeIcon">
            <img
              className="icon"
              onClick={handleMemeIconClick}
              src={SubPageMenuIcon}
              style={{ width: "42px", height: "40px", marginTop: "2px" }}
            />
          </div>
          <ToolTip
            active={viralToolTipShow}
            position="bottom"
            arrow="center"
            parent={`#viralToolTip`}
            group="navbar"
          >
            <MemeToolTipDiv setViralToolTipShow={setViralToolTipShow} memeTags={memeTags} />
          </ToolTip>
        </div>
      </OutsideClickHandler>
      <div className="randomDiceDiv">
        <div className="randomDiceHolder" style={{ borderColor: getDiceBorder(navBarData) }}>
          <img
            className="icon"
            onClick={() =>
              props.surpriseClick(getSurpriseCategory(navBarData)) ||
              surpriseClick(getSurpriseCategory(navBarData))
            }
            src={dice}
            style={{ width: "34px", height: "34px", marginTop: "2px" }}
          />
        </div>
      </div>

      {/* SEARCH DIV */}
      <div className="searchIconDiv">
        <i className="fas fa-search searchIcon" onClick={searchClickHandler}></i>
      </div>

      <div
        onMouseEnter={() => setIsSearchEnable(true)}
        onMouseLeave={() => {
          if (
            pageName !== "" &&
            pageName !== "trivia" &&
            pageName !== "one-liner" &&
            pageName !== "meme" &&
            pageName !== "dad-joke" &&
            pageName !== "virals" &&
            pageName !== "books"
          ) {
            setIsSearchEnable(false);
          }
        }}
      >
        {isSearchEnable ? (
          <div className="searchDiv">
            {navBarData == "books" ? (
              <div className="booksSearchDiv">
                <div onClick={seArchBarClick} style={{ width: "31%", float: "left" }}>
                  <Search
                    value={""}
                    navTab={navBarData}
                    noOptionsMessage={() => noOptionsMessage}
                    category="collections"
                    searchRef={searchRef}
                    onChange={onCategoryClick}
                    onFocus={searchOnFocus}
                    autoFocus={true}
                    placeholder="category"
                  />
                </div>
                <div onClick={seArchBarClick} style={{ width: "27%", float: "left" }}>
                  <Search
                    value={""}
                    navTab={navBarData}
                    noOptionsMessage={() => noOptionsMessage}
                    category="booksByTitle"
                    onChange={onTitleClick}
                    onFocus={searchOnFocus}
                    placeholder="title"
                  />
                </div>
                <div onClick={seArchBarClick} style={{ width: "27%", float: "left" }}>
                  <Search
                    value={""}
                    navTab={navBarData}
                    noOptionsMessage={() => noOptionsMessage}
                    category="AuthorHavingBook"
                    onChange={onAuthorClick}
                    onFocus={searchOnFocus}
                    placeholder="author"
                  />
                </div>
                {isSearchCategory(navBarData) && (
                  <div>
                    <SearchCategory
                      toggleSearch={toggleSearch}
                      changeCategory={onSelectedCatagoryChange}
                      category={searchCategory}
                      colorBorder={props.navbar.searchCategory}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  marginTop: "3px",
                  width: "94%",
                  marginLeft: "3%",
                  height: "49px"
                }}
              >
                <div
                  style={{
                    width: "80%",
                    float: "left",
                    border: "1 px solid transparent",
                    borderRadius: "22px",
                    backgroundColor:
                      //(subFilterState && subFilterState.length) > 0 ?
                      getSearchbarColor(navBarData),
                    //: "transparent",
                    paddingTop: "5px",
                    paddingBottom: "5px"
                  }}
                >
                  <div onClick={seArchBarClick} style={{ width: "96%", marginLeft: "2%" }}>
                    <Search
                      value={""}
                      navTab={navBarData}
                      noOptionsMessage={() => noOptionsMessage}
                      category={getCategory(navBarData, searchCategoryFilter)}
                      tags={getContent(navBarData)}
                      searchRef={searchRef}
                      onChange={HandleClickItem}
                      onFocus={searchOnFocus}
                      placeholder={placeHolder(navBarData)}
                    />
                  </div>
                </div>
                {isSearchCategory(navBarData) && (
                  <div
                    style={{
                      width: "18%",
                      float: "left",
                      marginTop: "2px",
                      marginLeft: "1%"
                    }}
                  >
                    <SearchCategory
                      toggleSearch={toggleSearch}
                      changeCategory={onSelectedCatagoryChange}
                      category={searchCategory}
                      colorBorder={props.navbar.searchCategory}
                    />
                  </div>
                )}
              </div>
            )}
            <div>
              <p style={{ float: "left", fontSize: "12px", marginLeft: "25px", marginTop: "-5px" }}>
                {subFilterState}{" "}
                {subFilterState && (
                  <i
                    className="fas fa-times"
                    style={{ cursor: "pointer" }}
                    onClick={() => setSubFilterState("")}
                  ></i>
                )}
              </p>
            </div>
          </div>
        ) : (
          <div className="pageTitle" onClick={() => setIsSearchEnable(true)}>
            {getPageTitle()}
          </div>
        )}
      </div>

      {/* APPLY FILTER */}

      {navBarData !== "books" && (
        <div
          style={{
            display: isSearchEnable ? "" : "none",
            float: "left",
            width: "15%",
            height: "52px",
            marginTop: "0px"
            //backgroundColor: "yellow",
            //marginLeft: isSearchEnable ? "" : "30px"
          }}
        >
          {isSearchCategory(navBarData) && (
            <div>
              {!filterSearch ? (
                <i
                  className="fas fa-filter iconFilter"
                  style={{
                    float: "left",
                    fontSize: "20px",
                    width: "28",
                    height: "28",
                    cursor: "pointer",
                    marginTop: "16px",
                    marginRight: "10px"
                  }}
                  onClick={() => setFilterSearch(!filterSearch)}
                ></i>
              ) : (
                <i
                  className="fas fa-times iconFilter"
                  style={{
                    float: "left",
                    fontSize: "28px",
                    width: "28",
                    height: "28",
                    cursor: "pointer",
                    marginTop: "10px",
                    marginRight: "10px"
                  }}
                  onClick={() => setFilterSearch(!filterSearch)}
                ></i>
              )}
            </div>
          )}
          <div
            style={{
              float: "left",
              width: "80%",
              marginTop: "7px",
              marginLeft: "2%"
            }}
          >
            {filterSearch && (
              <FilterSearch
                subFilterState={subFilterState}
                setSubFilterState={setSubFilterState}
                setFilterSearch={setFilterSearch}
                searchCategory={searchCategory}
              />
            )}
          </div>
        </div>
      )}

      {/* LOGOS DIV */}

      <div className="iconsDiv">
        {user.isAdmin && (
          <div className="iconHolder" style={{ border: "none" }}>
            <img
              src={AddIconGreen}
              alt="Add Icon"
              id="addAdvertise"
              className="icon"
              onClick={() => {
                setAddAdvertise(!addAdvertise);
                setAddOption("perspective");
              }}
            />
          </div>
        )}
        {addAdvertise && (
          <ToolTip
            style={style}
            active={addAdvertise}
            position="bottom"
            arrow="right"
            parent={`#addAdvertise`}
            group="navbar"
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                setTimeout(() => {
                  if (!open && !openAuthor) {
                    setAddAdvertise(false);
                  }
                }, 100);
              }}
            >
              <div style={{ float: "left", width: "100%", minHeight: "500px" }}>
                <div
                  style={{
                    marginLeft: "15%",
                    color: addOption === "perspective" ? "white" : "black",
                    backgroundColor: addOption === "perspective" ? "black" : "white"
                  }}
                  className="addOptionIcon"
                  onClick={() => setAddOption("perspective")}
                >
                  <i className="fas fa-quote-right"></i>
                </div>
                <div
                  style={{
                    color: addOption === "meme" ? "white" : "black",
                    backgroundColor: addOption === "meme" ? "black" : "white"
                  }}
                  className="addOptionIcon"
                  onClick={() => setAddOption("meme")}
                >
                  <i className="fab fa-meetup"></i>
                </div>
                <div
                  style={{
                    color: addOption === "textMeme" ? "white" : "black",
                    backgroundColor: addOption === "textMeme" ? "black" : "white"
                  }}
                  className="addOptionIcon"
                  onClick={() => setAddOption("textMeme")}
                >
                  <span style={{ fontSize: "12px" }}>Text</span>
                </div>
                <div
                  style={{
                    color: addOption === "ad" ? "white" : "black",
                    backgroundColor: addOption === "ad" ? "black" : "white"
                  }}
                  className="addOptionIcon"
                  onClick={() => setAddOption("ad")}
                >
                  <i className="fab fa-adversal"></i>
                </div>

                {addOption === "perspective" && (
                  <div style={{ marginTop: "50px" }}>
                    <PerspectiveForm
                      openAuthor={openAuthor}
                      setOpenAuthor={setOpenAuthor}
                      open={open}
                      setOpen={setOpen}
                      nature={PERSPECTIVE_NEW_TYPE}
                      handlePerspectiveSubmit={props.handlePerspectiveSubmit}
                      handleConceptSubmit={props.handleConceptSubmit}
                      handleAuthorSubmit={props.handleAuthorSubmit}
                      getRelatedConcepts={props.getRelatedConcepts}
                      conceptError={conceptError}
                      conceptLoading={conceptLoading}
                      authorLoading={authorLoading}
                      authorError={authorError}
                      user={user}
                      relatedConcepts={props.relatedConcepts}
                      setActive={setAddAdvertise}
                    />
                  </div>
                )}
                {addOption === "meme" && (
                  <div style={{ marginTop: "20px" }}>
                    <MemeUpload
                      nature="newMeme"
                      user={props.user}
                      loading={loading}
                      uploadSuccess={uploadSuccess}
                      uploadFail={uploadFail}
                      uploadMemes={props.uploadMemes}
                      selectedOption={selectedOption}
                      memesPage={true}
                      setAddAdvertise={setAddAdvertise}
                      //loadingPage={loadingPage}
                    />
                  </div>
                )}
                {addOption === "textMeme" && (
                  <div style={{ marginTop: "50px" }}>
                    <MemeForm
                      nature={MEME_NEW_TYPE}
                      handlePerspectiveSubmit={props.handlePerspectiveSubmit}
                      handleConceptSubmit={props.handleConceptSubmit}
                      handleAuthorSubmit={props.handleAuthorSubmit}
                      uploadMeme={props.uploadMeme}
                      updateMeme={props.updateMeme}
                      currentConcept={props.selectedOption}
                      conceptError={conceptError}
                      conceptLoading={conceptLoading}
                      authorLoading={authorLoading}
                      authorError={authorError}
                      textMemeColors={props.textMemeColors}
                      setAddAdvertise={setAddAdvertise}
                    />
                  </div>
                )}
                {addOption === "ad" && (
                  <div>
                    <AddAdvertise
                      setAddAdvertise={setAddAdvertise}
                      handleWackyWareSubmit={props.handleWackyWareSubmit}
                    />
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </ToolTip>
        )}
        {
          <div className="iconHolder responsiveIcon" style={{ border: "none" }}>
            <PageShare />
          </div>
        }

        {props.user && props.user.isAdmin && (
          <div className="iconHolder">
            <a href="/unverifiedconcepts">
              <i
                className="fa fa-question icon"
                style={{ fontSize: "25px", marginTop: "8px", color: "#929292" }}
              ></i>
            </a>
          </div>
        )}
        <div
          className="iconHolder"
          style={{
            border: "none",
            fontSize: "24px",
            paddingLeft: "1px",
            color: "grey",
            border: "1px solid gray",
            borderRadius: "50%"
          }}
          href="/user"
          onClick={e => {
            e.preventDefault();
            if (user.id) {
              history.push("/user");
            } else {
              history.push("/login");
            }
          }}
        >
          <i className="far fa-user" style={{ marginTop: "8px", marginLeft: "-5px" }}>
            <div
              className="userProfileDot"
              style={{
                backgroundColor: user.id ? "#1fb100" : "red"
              }}
            ></div>
          </i>
        </div>

        <div
          style={{
            float: "left",
            width: "5%",
            textAlign: "center",
            height: "42px",
            marginTop: "4px",
            borderRadius: "50%"
          }}
        >
          <i className="fa-solid fa-caret-down" style={{ fontSize: "3vh", marginTop: "12px" }}></i>
          <Dropdown>
            <Dropdown.Toggle
              style={{ opacity: 0, color: "grey", fontSize: "30px ", marginTop: "-80px" }}
              variant="transparent"
              id="dropdown-basic"
            ></Dropdown.Toggle>
            <Dropdown.Menu style={{ border: "1px solid grey", width: "170px" }}>
              <Dropdown.Item
                href="/login"
                onClick={user.id && props.logout}
                style={{
                  border: "1px solid grey",
                  width: "45%",
                  marginLeft: "15%",
                  borderRadius: "10px"
                }}
              >
                {user.id ? "Logout" : "Login"}
              </Dropdown.Item>
              <Dropdown.Item
                href="/user"
                onClick={e => {
                  e.preventDefault();
                  if (user.id) {
                    history.push("/user");
                  } else {
                    history.push("/login");
                  }
                }}
              >
                My Profile
              </Dropdown.Item>
              <Dropdown.Item
                href="/user"
                onClick={e => {
                  e.preventDefault();
                  props.userDefaultOption("archive");
                  history.push("/user");
                }}
              >
                {" "}
                <ArchiveButton />
              </Dropdown.Item>
              <Dropdown.Item
                href="/user"
                onClick={e => {
                  e.preventDefault();
                  props.userDefaultOption("feed");
                  history.push("/user");
                }}
              >
                <img
                  src={river}
                  style={{ width: "25px", height: "25px", margin: "0 5px 0 -5px" }}
                />{" "}
                My Feed
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          className="iconHolder"
          style={{
            border: "none",
            fontSize: "24px",
            paddingLeft: "1px",
            color: "grey",
            border: "1px solid gray",
            borderRadius: "50%"
          }}
        >
          <i className="fas fa-bars " style={{ marginTop: "10px" }}></i>
          <Dropdown>
            <Dropdown.Toggle
              style={{ opacity: 0, color: "grey", fontSize: "30px ", marginTop: "-80px" }}
              variant="transparent"
              id="dropdown-basic"
            ></Dropdown.Toggle>
            <Dropdown.Menu style={{ border: "1px solid grey" }}>
              <Dropdown.Item href="#/action-2">browser-bar add-on</Dropdown.Item>
              <Dropdown.Item href="#/action-3" style={{ color: "yellow" }}>
                <a href="http://m.me/conceptionary" target="_blank">
                  <p
                    style={{
                      color: "black",
                      textDecoration: "none",
                      margin: "0px"
                    }}
                  >
                    <i
                      class="fab fa-facebook-messenger"
                      style={{ color: "#039dfc", fontSize: "20px", marginRight: "7px" }}
                    ></i>
                    messenger app
                  </p>
                </a>
              </Dropdown.Item>
              <Dropdown.Item href="/about">about</Dropdown.Item>
              <Dropdown.Item href="/contact">contact</Dropdown.Item>
              <Dropdown.Item href="/TermsOfUse">terms of use</Dropdown.Item>
              <Dropdown.Item href="/PrivacyPolicy">privacy policy</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSearch: bindActionCreators(toggleSearch, dispatch),
    surpriseClick: bindActionCreators(surpriseConcept, dispatch),
    setSelectedCategory: bindActionCreators(setSelectedCategory, dispatch),
    setPrimaryMode: bindActionCreators(navbarActions.setPrimaryMode, dispatch),
    logout: bindActionCreators(logout, dispatch),
    handleWackyWareSubmit: bindActionCreators(wackyActions.handleWackyWareSubmit, dispatch),
    uploadMemes: bindActionCreators(memesActions.uploadMemes, dispatch),
    handleConceptSubmit: bindActionCreators(conceptActions.handleConceptSubmit, dispatch),
    handleAuthorSubmit: bindActionCreators(authorActions.handleAuthorSubmit, dispatch),
    handlePerspectiveSubmit: bindActionCreators(navbarActions.handlePerspectiveSubmit, dispatch),
    userDefaultOption: bindActionCreators(navbarActions.userDefaultOption, dispatch),
    getRelatedConcepts: bindActionCreators(conceptActions.getRelatedConcepts, dispatch),
    uploadMeme: bindActionCreators(memesActions.uploadTextMeme, dispatch),
    updateMeme: bindActionCreators(memesActions.updateTextMeme, dispatch)
  };
};
const mapStateToProps = state => {
  return {
    navbar: state.navbar,
    selectedOption: state.navbar.selectedOption,
    user: state.auth.user,
    loading: state.memes.loading,
    uploadSuccess: state.memes.uploadSuccess,
    uploadFail: state.memes.uploadFail,
    conceptError: state.concepts.conceptError,
    conceptLoading: state.concepts.conceptLoading,
    authorError: state.author.authorError,
    authorLoading: state.author.authorLoading,
    thirdKey: state.navbar.thirdKey,
    subFilter: state.navbar.subFilter,
    memeTags: state.memes.textMemeColors
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavSecondRow));

let style = {
  style: {
    width: "427px",
    border: "2px solid black",
    padding: 20,
    boxShadow: "5px 5px 3px rgba(0,0,0,.5)"
  },
  arrowStyle: {
    color: "rgba(0,0,0,.8)",
    borderColor: false
  }
};
