import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import requiredIf from 'react-required-if'
import { GREY, OPAQUE_BLACK, RED } from '../../../constants/colors'
import DrawerButton from '../drawer/drawerButton'
import ImageUpload from './imageUpload'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../actions/navbaractions'
import Loader from 'react-loader-spinner'
import {resizedImageUrl } from '../../utills/common/imageResize';

function ImageWrapper(props) {
    const { src, title, bottomTitle, showButton, imageUploading, imageUploadStatus, user, isSingleBook } = props
    const [loaded, setLoaded] = useState(false)
    const [showLoading, setShowLoading] = useState(true)

    useEffect(() => {
        if(!src)
        {
            setShowLoading(false)
        }
    }, [src])


    return (
        <div style={styles.imageWrapper}>
            {showLoading && 
                <div style={styles.loadingWrappper}>
                    <Loader
                        type="Oval"
                        color={GREY}
                        height="30"
                        width="30"
                    />
                </div>
            }
            {!showLoading && user.isAdmin && !isSingleBook &&
                <ImageUpload 
                    oldSrc={(src && loaded)? true: false} 
                    loading={imageUploading} 
                    uploadFail={imageUploadStatus} 
                    uploadSuccess={!imageUploadStatus} 
                    onSubmit={props.handleImageUpdate}
                />}
            {showButton &&
               <div style={styles.drawerBtnWrapper}>
                   <DrawerButton handleClick={props.handleDrawerClick}/>
               </div>
            }
            { title && loaded && 
                <div className="d-md-none" style={styles.titleWrapper(bottomTitle)}>
                    <p>{title}</p>
                </div>
            }
            <img 
                src={resizedImageUrl(src, 200, 250)}
                onError={() => {
                    setShowLoading(false)
                    setLoaded(false)
                }}
                onLoad={() => {
                    setShowLoading(false)
                    setLoaded(true)
                }} 
                alt={"image"}
                style={{ width: "100%", display: loaded? 'block': 'none' }} 
            />
        </div>
    )
}




ImageWrapper.propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string,
    bottomTitle: PropTypes.bool,
    showButton: PropTypes.bool,
    handleDrawerClick: requiredIf(PropTypes.func, props => props.showButton),
    showImageButton: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
    return {
        imageUploading: state.navbar.imageUploading,
        imageUploadStatus: state.navbar.imageUplaodStatus,
        user: state.auth.user
    }
}
 
const mapDispatchToProps = dispatch => ({
  handleImageUpdate: bindActionCreators(actions.handleImageUpdate, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(ImageWrapper)

const styles  = {
    imageWrapper: {
        maxHeight: 335,
        overflow: "hidden",
        position: 'relative',
        marginTop: "5%"
    },
    drawerBtnWrapper: {
        position: 'absolute',
        zIndex: 1
    },
    titleWrapper: (bottomTitle) => {    
        return {
            position: 'absolute',
            width: '100%',
            color: 'white',
            background: OPAQUE_BLACK,
            height: 33,
            display: 'flex',
            fontWeight: 600,
            paddingLeft: 20,
            top: bottomTitle? 'null': 0,
            bottom: bottomTitle? 0: 'null'
        }
    },
    loadingWrappper: {
        display: 'flex',
        justifyContent: 'center',
    }
}

