import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions/memes";
import ToolTip from "react-portal-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import MemeForm from "../utills/form/memeForm";
import { MEME_NEW_TYPE, MEME_EDIT_TYPE } from "../../constants/global";
import MemeTools from "./memesTools";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LIGHT_BLUE, MAIN_WHITE } from "../../constants/colors";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import history from "../../router/history";
import "./memes.css";
import MemeConcepts from "./MemeConcepts";
import { getMemeColor } from "./memeHelper";

const TextMemeCard = props => {
  const {
    meme,
    memesFilter,
    selectedOption,
    memesCount,
    user,
    conceptLoading,
    conceptError,
    authorLoading,
    authorError,
    memeTags,
    title,
    thirdKey
  } = props;

  const isAdmin = user.isAdmin || false;
  const [active, setActive] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [citation, setCitation] = useState(false);
  const [currentMeme, setCurrentMeme] = useState({});

  console.log("rendering text meme card", currentMeme);

  const currentKeyword = window.location.href.split("=")[1];

  const onLikeClick = id => {
    props.likeMeme(id);
  };

  const toggle = () => {
    setOpen(!open);
  };
  const handleConfirm = () => {
    setOpen(false);
    props.deleteMemes(currentMeme.id);
  };
  const memeToggle = () => {
    setActive(!active);
  };
  useEffect(() => {
    setCurrentMeme(meme);
  }, [meme]);
  const closeToolTip = () => (active ? setActive(false) : false);
  let memeConceptsIds = currentMeme.Concepts && currentMeme.Concepts.map(c => c.id);

  let memeColor =
    memeConceptsIds && memeTags.find(item => memeConceptsIds.includes(item.ConceptId));
  console.log("got meme color", memeColor);
  let defaultColor;
  if (!currentKeyword) {
    defaultColor = memeColor && memeColor.colorCode ? memeColor.colorCode : "#00008B";
  } else {
    defaultColor = getMemeColor(currentKeyword, memeColor);
  }
  
  const updateMemeHandle = parameters => {
    let newMeme = {};
    newMeme.id = currentMeme.id;
    newMeme.firstLine = parameters.firstLine;
    newMeme.secondLine = parameters.secondLine;
    newMeme.Concepts = parameters.tags;
    props.updateMeme(parameters);
    setCurrentMeme(newMeme);
    setActive(false);
  };
  return (
    <>
      <div id="memes-wrapper" style={memesFilter ? {} : styles.outerWrapper}>
        <div id="memes-inner-wrapper" style={styles.cardWrapper}>
          {memeColor && selectedOption.id !== memeColor.ConceptId && (
            <div style={{ textAlign: "right" }}>
              <span>
                <a
                  style={{ marginRight: 3, color: "#000000" }}
                  onClick={e => {
                    e.preventDefault();
                    props.setOffset(0);
                    history.push(`/Concepts/${memeColor.name.split(" - ")[0]}`);
                  }}
                  href={`/Concepts/${memeColor.name.split(" - ")[0]}`}
                >
                  {/* {memeColor.name.split(" - ")[0]} */}
                </a>
              </span>
            </div>
          )}
          <div
            id={`${currentMeme.id}`}
            className="textMemeView"
            style={{ background: `linear-gradient(to bottom, ${defaultColor} 50%, #000000)` }}
          >
            <h3 className="whiteText" style={{ whiteSpace: "pre-line" }}>
              {currentMeme.firstLine}
            </h3>
            <p className="whiteText" style={{ whiteSpace: "pre-line" }}>
              {currentMeme.secondLine}
            </p>
            {currentMeme.citation && citation && (
              <div style={{ marginBottom: "12px", padding: "8px" }}>
                <p style={{ fontSize: "0.8rem", fontFamily: "garamond", color: "white" }}>
                  {currentMeme.citation}
                </p>
              </div>
            )}
          </div>
          <div id="memes-tools">
            <MemeTools
              setCitation={setCitation}
              toggle={toggle}
              meme={currentMeme}
              onLikeClick={onLikeClick}
              textMeme={true}
              isAdmin={isAdmin}
              citation={citation}
              setActive={setActive}
              active={active}
              title={title}
            />
            {/* {isAdmin && <i className="fa fa-trash memes-trash" onClick={toggle} />}
            {currentMeme.citation && (
              <i
                class="fas fa-info-circle"
                style={{
                  fontSize: "27px",
                  marginLeft: "250px",
                  color: "#5bc0de",
                  cursor: "pointer"
                }}
                onClick={() => setCitation(!citation)}
              ></i>
            )} */}
          </div>
          <div>
            {currentMeme && currentMeme.id && (
              <MemeConcepts
                meme={currentMeme}
                memeColor={memeColor}
                textType={true}
                defaultColor={defaultColor}
                thirdKey={thirdKey}
              />
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm</ModalHeader>
        <ModalBody>Confirm want to delete this Memes????</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirm}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ToolTip
        active={active}
        position="left"
        arrow="top"
        parent={`#addMeme${title}${currentMeme.id}`}
        group="navbar"
      >
        <OutsideClickHandler onOutsideClick={closeToolTip}>
          <div id="addPerspectiveFormAdmin">
            <MemeForm
              nature={MEME_EDIT_TYPE}
              meme={currentMeme}
              handlePerspectiveSubmit={props.handlePerspectiveSubmit}
              handleConceptSubmit={props.handleConceptSubmit}
              handleAuthorSubmit={props.handleAuthorSubmit}
              uploadMeme={props.uploadMeme}
              updateMeme={updateMemeHandle}
              setActiveFalse={() => setActive(false)}
              currentConcept={props.selectedOption}
              conceptError={conceptError}
              conceptLoading={conceptLoading}
              authorLoading={authorLoading}
              authorError={authorError}
            />
          </div>
        </OutsideClickHandler>
      </ToolTip>
    </>
  );
};

TextMemeCard.propTypes = {
  // meme: PropTypes.object.isRequired // Meme object
};

const mapDispatchToProps = dispatch => {
  return {
    likeMeme: bindActionCreators(actions.likeMeme, dispatch),
    deleteMemes: bindActionCreators(actions.deleteMeme, dispatch),
    updateMeme: bindActionCreators(actions.updateTextMeme, dispatch),
    setOffset: bindActionCreators(actions.setOffset, dispatch)
  };
};
const mapStateToProps = state => ({
  memesFilter: state.navbar.memesFilter,
  memesCount: state.memes.count,
  selectedOption: state.navbar.selectedOption,
  user: state.auth.user,
  memeTags: state.memes.textMemeColors
});

export default connect(mapStateToProps, mapDispatchToProps)(TextMemeCard);

const styles = {
  cardWrapper: {
    border: "1px solid rgb(187, 187, 187)",
    // padding: 10,
    paddingBottom: 0,
    borderRadius: 4,
    boxShadow: "1px 1px 40px #c1c0c0",
    overflow: "hidden",
    marginTop: 10,
    background: MAIN_WHITE,
    width: "100%"
  },
  imageWrapper: {
    maxHeight: "90%",
    width: "100%",
    overflow: "hidden",
    position: "relative"
  },
  textWrapper: {
    fontSize: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  text: {
    alignSelf: "flex-end"
  },
  outerWrapper: {
    width: "100%",
    maxHeight: "auto",
    display: "flex",
    justifyContent: "center"
  },
  badge: {
    padding: "4px 3px",
    fontSize: 10,
    background: LIGHT_BLUE,
    borderRadius: "50%",
    alignSelf: "flex-end"
  }
};
