import * as constants from "../constants/pos";
import * as services from "../services/pos";
import * as navbarConstants from "../constants/navbar";
import { toast } from "react-toastify";

export function setPosOffSet(offsetValue) {
  return dispatch => {
    dispatch({
      type: constants.SET_POS_OFFSET,
      payload: offsetValue
    });
  };
}

export function saveNewPos(pos) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        user: { token }
      } = state.auth;

      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });

      const res = await services.addNewPos(pos, token);

      dispatch({
        type: constants.SAVE_POS_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      console.log("error is ", e);
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function getConceptPos(conceptId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.GET_POS_SUCCESS,
        payload: []
      });
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });
      const res = await services.getAllConceptsPos(conceptId);
      dispatch({
        type: constants.GET_POS_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function deletePosAction(id) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });
      const res = await services.deletePosApi(id);
      dispatch({
        type: constants.DELETE_POS_SUCCESS,
        payload: id
      });
    } catch (e) {
      console.log("error is ", e);
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function getPosCategories() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        user: { token }
      } = state.auth;
      dispatch({
        type: constants.POS_CATEGORIES_SUCCESS,
        payload: []
      });
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });
      const res = await services.getPosCategories(token);
      dispatch({
        type: constants.POS_CATEGORIES_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function getPosCategoriesValues(conceptId, categoryId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        user: { token }
      } = state.auth;
      dispatch({
        type: constants.GET_POS_SUCCESS,
        payload: []
      });
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });
      const res = await services.getPosCategoriesValues(conceptId, categoryId, token);
      dispatch({
        type: constants.GET_POS_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function saveNewCategory(category) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        user: { token }
      } = state.auth;

      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });
      const res = await services.addNewCategory(category, token);
      dispatch({
        type: constants.SAVE_POS_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      console.log("error is ", e);
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function getConceptSimiles(conceptId) {
  return async (dispatch, getState) => {
    try {
      const res = await services.getAllConceptSimiles(conceptId);
      dispatch({
        type: constants.GET_CONCEPT_POS_LIST_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function getPosSimiles(posId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });
      const res = await services.getAllPosSimiles(posId);
      dispatch({
        type: constants.POS_CONTENT_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      console.log("error is ", e);
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function getPosSynonymsContent(posName, contentType) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        auth: { user },
        pos: { offset, posContents }
      } = state;

      dispatch({
        type: offset === 0 ? constants.SET_POS_LOADING : constants.PAGINATION_LOADING,
        payload: true
      });
      const res = await services.getAllPosSynonymsContent(posName, contentType, user.token, offset);

      dispatch({
        type: constants.POS_CONTENT_SUCCESS,
        payload: offset === 0 ? res.data.data : [...posContents, ...res.data.data]
      });
      dispatch({
        type: constants.PAGINATION_LOADING,
        payload: false
      });
    } catch (e) {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function getPosSynonyms(posName) {
  return async (dispatch, getState) => {
    try {
      const res = await services.getPosSynonyms(posName);
      dispatch({
        type: constants.GET_POS_SYNONYMS_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function getSelectedPosSynonyms(posName, tooltip = false) {
  //console.log("action", tooltip);
  return async (dispatch, getState) => {
    try {
      tooltip
        ? dispatch({
            type: constants.SET_POS_LOADING_TOOLTIP,
            payload: true
          })
        : dispatch({
            type: constants.SET_POS_LOADING,
            payload: true
          });
      const res = await services.getPosSynonyms(posName);

      dispatch({
        type: constants.GET_POS_SELECTED_SYNONYMS_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
      dispatch({
        type: constants.SET_POS_LOADING_TOOLTIP,
        payload: false
      });
    }
  };
}
export function getPosConcepts(posName) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });
      const res = await services.getPosConcepts(posName);
      dispatch({
        type: constants.GET_POS_COLLOCATIONS_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function getPosSynonymsConcepts(posName) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });
      const res = await services.getPosSynonymsConcepts(posName);
      dispatch({
        type: constants.GET_POS_COLLOCATIONS_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function likeSimile(data) {
  return async (dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;

    try {
      const res = await services.likeSimile(data, user.token);
      if (data.action === "like") {
        const index = user.favoriteSimiles.findIndex(e => e.id === data.simileId);
        if (index === -1) {
          user.favoriteSimiles.push(res.data.data);
          user.unfavoriteSimiles = user.unfavoriteSimiles.filter(e => e.id !== data.simileId);
        } else {
          user.favoriteSimiles = user.favoriteSimiles.filter(e => e.id !== data.simileId);
        }
      } else {
        const index = user.unfavoriteSimiles.findIndex(e => e.id === data.simileId);
        if (index === -1) {
          user.unfavoriteSimiles.push(res.data.data);
          user.favoriteSimiles = user.favoriteSimiles.filter(e => e.id !== data.simileId);
        } else {
          user.unfavoriteSimiles = user.unfavoriteSimiles.filter(e => e.id !== data.simileId);
        }
      }

      dispatch({
        type: navbarConstants.UPDATE_USER,
        payload: user
      });
    } catch (e) {
      console.log(e);
    }
  };
}

function filterPosSynonymsData(data, synonyms, res, id) {
  let temp = [...synonyms];
  if (data.action === "remove") {
    temp = temp.filter(elm => elm.id !== id);
  } else {
    temp.push(res.data.data);
  }
  return temp;
}

function filterPosCollocationsData(data, collocations, res, id) {
  if (data.action === "remove") {
    collocations = collocations.filter(elm => elm.id !== id);
  } else {
    collocations.push(res.data.data);
  }
  return collocations;
}

export function changePosSynonyms(id, data, posSynonym = false) {
  return async (dispatch, getState) => {
    const state = getState();
    let { posSelectedSynonyms, posSynonyms } = state.pos;

    try {
      const res = await services.updatePosSynonyms(id, data);

      toast.success(
        `Synonym ${res.data.data.term} successfully ${data.action === "add" ? "added" : "deleted"}`
      );

      const resSynonyms = filterPosSynonymsData(
        data,
        !posSynonym ? posSelectedSynonyms : posSynonyms,
        res,
        res.data.data.id
      );

      dispatch({
        type: !posSynonym ? constants.UPDATE_SELECTED_POS_SYNONYMS : constants.UPDATE_POS_SYNONYMS,
        payload: resSynonyms
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function changePosCollocations(id, data) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const res = await services.addPosCollocations(id, data);
      toast.success(`Collocation ${res.data.data.concept.name} successfully added`);

      dispatch({
        type: constants.UPDATE_POS_COLLOCATIONS,
        payload: res.data.data
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function updatePos(id, data) {
  return async (dispatch, getState) => {
    try {
      const res = await services.updatePos(id, data);

      dispatch({
        type: constants.UPDATE_POS_SUCCESS,
        payload: { id, ...data }
      });
    } catch (e) {
      console.log(e);
    }
  };
}
export function getPosContent(pos, type) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });
      const res = await services.posContent(pos, type);

      dispatch({
        type: constants.POS_CONTENT_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function conceptPosContent(concept, type) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        auth: { user },
        pos: { offset, posContents }
      } = state;
      dispatch({
        type: offset === 0 ? constants.SET_POS_LOADING : constants.PAGINATION_LOADING,
        payload: true
      });
      const res = await services.conceptPosContent(concept, type, offset);

      dispatch({
        type: constants.POS_CONTENT_SUCCESS,
        payload: offset === 0 ? res.data.data : [...posContents, ...res.data.data]
      });
      dispatch({
        type: constants.PAGINATION_LOADING,
        payload: false
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function getRelatedConcepts(concept) {
  return async (dispatch, getState) => {
    try {
      const res = await services.getRelatedConcepts(concept);
      dispatch({
        type: constants.RELATED_CONCEPTS_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function deletePosContent(id) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });

      await services.deletePosContent(id);
      dispatch({
        type: constants.POS_CONTENT_DELETE_SUCCESS,
        payload: id
      });
    } catch (e) {
      toast.error(e.message);
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function updatePosContent(id, data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: true
      });

      await services.updatePosContent(id, data);
      dispatch({
        type: constants.POS_CONTENT_UPDATE_SUCCESS,
        payload: { id, data }
      });
    } catch (e) {
      toast.error(e.message);
      dispatch({
        type: constants.SET_POS_LOADING,
        payload: false
      });
    }
  };
}

export function createPosContent(data, Po) {
  return async (dispatch, getState) => {
    try {
      const res = await services.createPosContent(data);
      const content = res.data.data;

      dispatch({
        type: constants.CREATE_POS_CONTENT_SUCCESS,
        payload: { ...content, Po }
      });
    } catch (e) {
      toast.error(e.message);
    }
  };
}
