import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col } from "reactstrap";
import * as scroll from "../../../helpers/scrolling";
import * as actions from "../../../actions/conceptClustersActions";
import AuthorsSidebar from "../../utills/AuthorsSidebar/AuthorsSidebar";
import FacebookComment from "../../utills/FacebookComment/FacebookComment";
import FactsButton from "../../utills/Header/FactsButton";
import MemeButton from "../../utills/Header/memeButton";
import { removeSubFilter, setSubFilter } from "../../../actions/navbaractions";
import { refreshFacebook } from "../../../helpers/facebook";
import {
  authorCategory,
  conceptCategory,
  factCategory,
  groupsServerCategory,
  SORT_BY_LIKES
} from "../../../constants/global";
import PageShare from "../../utills/FacebookComment/PageShare";
import Drawer from "../../utills/drawer/drawer";
import { setAuthorDrawerOpen } from "../../../actions/mobile";
import MoveToAuthor from "./movetoauthors";
import MoveToConcept from "./movetoconcept";
import AuthorBooks from "../../Author/cols/authorBooks";
import AuthorCards from "../../Author/cols/authorCards";
import { listenToDocumentSizeChangeOnPercepectiveClick } from "../../../helpers/navbar";

import history from "../../../router/history";
const ThirdColCluster = props => {
  const {
    authors,
    factsCount,
    selectedOption,
    subFilter,
    authorDrawer,
    memesCount,
    user,
    relatedAuthors
  } = props;
  const authorCallBack = (author, index) => {
    props.combineMemePerspective([]);
    props.setAuthorDrawerOpen(false);
    props.authorCallBack(author);
    listenToDocumentSizeChangeOnPercepectiveClick();
  };

  // const handleFactsClick = () => {
  //     scroll.resetScroll()
  //     if (subFilter) {
  //         props.removeSubFilter()
  //         props.setClusterSorting(SORT_BY_LIKES)
  //         props.toggleClusterPagination()
  //         refreshFacebook()
  //     }
  //     else {
  //         const temp = { id: true, type: factCategory, value: factCategory, label: factCategory }
  //         props.setSubFilter(temp)
  //         props.perspectivesByFilter(temp)
  //     }
  // }
  const moveToConcept = () => {
    history.push(`/${conceptCategory}/${selectedOption.value}`);
  };

  const aboutThinker = () => {
    history.push(`/${authorCategory}/${selectedOption.slug}`);
  };

  const moveToGroup = () => {
    history.push(`/${groupsServerCategory}/${selectedOption.slug}`);
  };

  return (
    <>
      <Drawer
        direction="right"
        open={authorDrawer}
        handleClose={() => props.setAuthorDrawerOpen(false)}
      >
        <AuthorsSidebar authors={authors} onAuthorClick={authorCallBack} opacity={false} />
      </Drawer>
      <Col
        xs={10}
        sm={12}
        md={2}
        className="d-none d-lg-block"
        onScroll={scroll.handleThirdWheel}
        id={scroll.thirdColId}
        style={{ marginLeft: "10%", paddingTop: 40 }}
      >
        {selectedOption.author && (
          <div className="pt-2">{/* <MoveToAuthor onClick={aboutThinker} /> */}</div>
        )}
        <AuthorBooks conceptMode={true} />
        <AuthorCards
          authors={relatedAuthors}
          handleAuthorClick={author =>
            history.push(`/${groupsServerCategory}/${selectedOption.slug}`)
          }
          detailMode={true}
          currentAuthor={{}}
        />
        {/* {selectedOption.authorGroup && <button
                    type="button"
                    className="btn mt-2 mb-2 btn-outline-success"
                    style={{ width: "86%", borderRadius: 10 }}
                    onClick={moveToGroup}
                >
                    Author Group
                </button>} */}
        {/* <div className="pt-2">
                    <PageShare />
                </div>

                {(memesCount !== 0 || user.isAdmin) && <MemeButton />}

                {(factsCount !== 0 || user.isAdmin) &&
                    <FactsButton
                        count={factsCount}
                        onClick={handleFactsClick}
                    />
                } */}
        <div id="thirdInnerScr">
          <AuthorsSidebar
            authors={authors}
            onAuthorClick={authorCallBack}
            opacity={false}
            selectedAuthor={props.selectedAuthor}
          />
        </div>
      </Col>
    </>
  );
};

const mapStateToProps = state => ({
  selectedAuthor: state.navbar.selectedAuthor,
  authors: state.navbar.authors,
  factsCount: state.concepts.factsCount,
  memesCount: state.memes.count,
  subFilter: state.navbar.subFilter,
  authorDrawer: state.mobile.authorDrawer,
  selectedOption: state.navbar.selectedOption,
  user: state.auth.user,
  relatedAuthors: state.author.relatedAuthors
});

const mapDispatchToProps = dispatch => {
  return {
    authorCallBack: bindActionCreators(actions.authorCallBack, dispatch),
    removeSubFilter: bindActionCreators(removeSubFilter, dispatch),
    setSubFilter: bindActionCreators(setSubFilter, dispatch),
    toggleClusterPagination: bindActionCreators(actions.toggleClusterPagination, dispatch),
    perspectivesByFilter: bindActionCreators(actions.perspectivesByFilter, dispatch),
    setClusterSorting: bindActionCreators(actions.setClusterSorting, dispatch),
    setAuthorDrawerOpen: bindActionCreators(setAuthorDrawerOpen, dispatch),
    combineMemePerspective: bindActionCreators(actions.combineMemePerspective, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdColCluster);
