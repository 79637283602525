import axios from 'axios'
import { BASE_URL } from '../constants/global'

export const groupDetailByName = name => {
    return axios.get(`${BASE_URL}/authorGroups/detail/${name}`)
}

export const groupById = id => {
    return axios.get(`${BASE_URL}/authorGroups/name/${id}`)
}

export const addAuthorToGroup = (groupId, authorId, token) => {
    return axios.post(`${BASE_URL}/admin/authorGroup/related/author`, { groupId, authorId }, { headers: { Authorization: token}})
}

export const deleteAuthorFromGroup = (groupId, authorId, token) => {
    return axios.delete(`${BASE_URL}/admin/authorGroup/related/author`, { data: { groupId, authorId },  headers: { Authorization: token}})
}