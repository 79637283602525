import React from "react";
import ReactLoading from "react-loading";
import loader from "../../../images/dictionaryish-loading.gif";

import "./ReactLoading.css";

export default function ReactPageLoading() {
  return (
    <div id="pageLoading">
      <img src={loader} alt="loading" />
      {/* <ReactLoading type={"balls"} color={"red"} /> */}
    </div>
  );
}
