import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router";
import { MAIN_WHITE } from "../../../constants/colors";
import { bindActionCreators } from "redux";
import { getBooksByAuthors } from "../../../actions/books";
import { handlePronounUpdate } from "../../../actions/navbaractions";
import {
  authorCategory,
  booksCategory,
  clusterCategory,
  conceptCategory,
  groupsCategory,
  newsCategory,
  NONE_PRONOUN,
  similarAuthorCategory,
  singleBookCategory
} from "../../../constants/global";
import { colorBySelectedCategory } from "../../../helpers/conceptHelpers";
import history from "../../../router/history";
import styles from "../navbarStyles";
import Search from "../../TopNavBar/search/search";
import { pageReloadscroll, reloadPage } from "../../../helpers/navbar";
import OutsideClickHandler from "react-outside-click-handler";
import { Col, Row, Button, Input } from "reactstrap";

import ToolTip from "react-portal-tooltip";

export const navbarTitle = props => {
  const [color, setColor] = useState(colorBySelectedCategory());
  let checkPath = window.location.pathname.split("/")[1];
  let isBook = window.location.pathname.split("/")[2];

  const booksPath = (checkPath == "books" || checkPath == "book") && isBook;
  const [editPronounTooltipActive, setEditPronounTooltipActive] = useState(false);
  const [EditConcept, setEditConcept] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    let concept = props.selectedOption;
    setEditConcept({
      id: concept.id,
      value: concept.value,
      pronoun: concept.pronoun,
      type: concept.type
    });
  }, [props.selectedOption]);
  const handlePronounChange = e => {
    setEditConcept({
      ...EditConcept,
      [e.target.name]: e.target.value
    });
  };
  const handlePronounSubmit = () => {
    if (EditConcept.concept === "") {
      setError("Concept cannot be empty!");
    } else {
      setError(null);
      props.PronounUpdate(EditConcept);
      setEditPronounTooltipActive(false);
    }
    // reloadPage()
  };
  history.listen(() => {
    // if(!booksPath){
    // }
    // if(!singlebookPath){
    // }
    setColor(colorBySelectedCategory());
  });

  const checkUrlPath = () => {
    const path = window.location.href;
    const url = path.split("/")[3];
    if (url === "unverifiedConcepts") return false;
    return true;
  };
  const generateTitle = () => {
    if (!checkUrlPath()) return "Unverified Concepts";
    var option = props.selectedOption;
    let title;
    if (props.selectedOption.value != null) {
      title =
        props.selectedOption.value.charAt(0).toUpperCase() + props.selectedOption.value.slice(1);
    }
    const getPronoun = option => {
      return option.pronoun && option.pronoun != NONE_PRONOUN ? option.pronoun : "...";
    };

    const titles = {
      [conceptCategory]: (
        <>
          {option.type && option.value && <span> {option.type} is </span>}
          <span>
            {/* {getPronoun(option)} <span style={{textTransform: "capitalize"}}>{option.value}</span> */}
            {option.pronoun}{" "}
            {option.type && option.value && (
              <span style={{ textTransform: "capitalize" }}>{option.value}?</span>
            )}
            {props.auth.user.isAdmin && (
              <i
                className="fa fa-pencil cursor-pointer"
                id={`editPronoun`}
                onClick={() => setEditPronounTooltipActive(!editPronounTooltipActive)}
                style={{ marginLeft: 10, fontSize: 20 }}
              />
            )}
            {/* <OutsideClickHandler onOutsideClick={setEditPronounTooltipActive(false)}> */}
            <ToolTip
              active={editPronounTooltipActive}
              position="bottom"
              arrow="center"
              parent={`#editPronoun`}
            >
              <div>
                <Row className="pronoun-edit-row">
                  <Col xs={12}>
                    <p style={{ color: "red" }}>{error}</p>
                  </Col>
                </Row>
                <Row className="pronoun-edit-row" style={{ marginLeft: 20 }}>
                  <div>
                    <Col xs={3}>
                      <Input
                        type="radio"
                        name="type"
                        value="Who"
                        checked={EditConcept.type == "Who"}
                        onChange={handlePronounChange}
                      />
                    </Col>
                    <Col style={{ marginLeft: 5 }}>Who</Col>
                  </div>
                  <div>
                    <Col xs={3}>
                      <Input
                        type="radio"
                        name="type"
                        value="What"
                        checked={EditConcept.type == "What"}
                        onChange={handlePronounChange}
                      />
                    </Col>
                    <Col style={{ marginLeft: 5 }}>What</Col>
                  </div>
                </Row>
                <Row className="pronoun-edit-row">
                  <Col xs={12}>
                    <Input
                      name="pronoun"
                      onChange={handlePronounChange}
                      value={EditConcept.pronoun}
                      style={{ borderRadius: 20 }}
                      placeholder="Pronoun"
                    />
                  </Col>
                </Row>
                <Row className="pronoun-edit-row">
                  <Col xs={12}>
                    <Input
                      name="value"
                      onChange={handlePronounChange}
                      value={EditConcept.value}
                      style={{ borderRadius: 20 }}
                      placeholder="Concept"
                    />
                  </Col>
                </Row>
                <Row className="pronoun-edit-row">
                  <Col xs={12} className="d-flex flex-row-reverse">
                    <Button
                      type="button"
                      onClick={handlePronounSubmit}
                      color="primary"
                      style={{ width: "100%" }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </ToolTip>
            {/* </OutsideClickHandler> */}
          </span>
        </>
      ),
      [authorCategory]: (
        <>
          <span>The {option.value}</span>
          <span> Dictionary</span>
        </>
      ),
      [clusterCategory]: (
        <>
          <span>The </span>
          <span style={{ textTransform: "capitalize" }}>{option.value} </span>
          <span>Dictionary</span>
        </>
      ),
      [groupsCategory]: (
        <>
          <span>Dictionary of</span>
          <span> {option.value}</span>
          <span> Thinkers</span>
        </>
      ),
      [similarAuthorCategory]: (
        <>
          <span>Authors Similar to </span>
          <span> {option.value}</span>
        </>
      ),
      [booksCategory]: (
        <>
          <span> {option.value}:</span>
          <span>Essential Books</span>
        </>
      ),
      // [newsCategory]: (
      //   <>
      //     <span style={styles.newsTitle}> In the.. News</span>
      //   </>
      // ),
      [singleBookCategory]: (
        <>
          <h2> {option.value}</h2>
          <h6> {option.subtitle}</h6>
        </>
      )
    };
    return "";
    //titles[option.category] ? titles[option.category] : <span>{option.value}</span>;
  };
  const searchBookCollection = inputValue => {
    history.push(`/books/${inputValue.id}`);
    pageReloadscroll();
  };
  const searchAuthorCollection = inputValue => {
    const autherBooks = window.location.pathname;
    if (autherBooks == "/books/all") {
      props.getBooksByAuthor(inputValue.id);
      history.push(`/books/author/${inputValue.id}`);
    } else {
      props.getBooksByAuthor(inputValue.id);
      history.push(`/books/author/${inputValue.id}`);
    }
    pageReloadscroll();
  };
  const searchTitleCollection = inputValue => {
    history.push(`/book/${inputValue.id}`);
    pageReloadscroll();
  };
  return (
    <>
      {booksPath && (
        <div
          style={{
            ...styles.topSearch,
            background: color,
            fontWeight: "normal",
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            zIndex: 12,
            width: "100%"
          }}
          className="secondary-search-navbar"
        >
          <Search
            className="top-navbar-search"
            value=""
            category="collections"
            onChange={searchBookCollection}
            placeholder="Book collections"
          />
          <Search
            className="top-navbar-search"
            value=""
            category="AuthorHavingBook"
            onChange={searchAuthorCollection}
            placeholder="Author Books"
          />
          <Search
            className="top-navbar-search"
            value=""
            category="booksByTitle"
            onChange={searchTitleCollection}
            placeholder="Books By Title"
          />
        </div>
      )}
      {booksPath ? (
        <div
          style={{
            ...styles.wholeDiv,
            ...styles.topTitleDiv,
            ...styles.topNavtitle,
            background: color
          }}
          className="d-none d-md-block secondary-navbar"
        >
          <center>
            <div className="secondary-navbar-title">{generateTitle()}</div>
          </center>
        </div>
      ) : (
        <div
          style={{ backgroundColor: "red" }}
          // style={{
          //   ...styles.wholeDiv,
          //   ...styles.topNavtitle,
          //   background: color
          // }}
          // className="d-none d-md-block secondary-navbar"
        >
          <center>
            <div
              style={{ backgroundColor: "red" }}

              //className="secondary-navbar-title"
            >
              {generateTitle()}
            </div>
          </center>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  selectedOption: state.navbar.selectedOption,
  auth: state.auth
});

const mapDispatchToProps = dispatch => {
  return {
    getBooksByAuthor: bindActionCreators(getBooksByAuthors, dispatch),
    PronounUpdate: bindActionCreators(handlePronounUpdate, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(navbarTitle));
