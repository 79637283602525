import React, { Component } from "react";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";
import axios from "axios";
import wikipedia from "../../../../images/wikipedia.png";
import * as authorServices from "../../../../services/author";

export default class AuthorWikiInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      show: false,
      error: false
    };
    setTimeout(this.show, 800);
  }
  show = () => {
    this.setState({ show: true });
  };
  componentDidMount() {
    let { firstName, lastName } = this.props;
    authorServices.scrapAuthorFromWikiPedia(firstName, lastName).then(data => {
      if (data.status === 200) {
        let authorData = data.data.data;
        this.setState({
          loading: false,
          image: authorData.img,
          paragraph: authorData.data
        });
      } else {
        this.setState({
          loading: false,
          error: true
        });
      }
    });
  }

  render() {
    return (
      <div style={{ display: this.state.show ? "block" : "none", zIndex: "1 !important" }}>
        <div id="customToolTip" onMouseLeave={this.props.onMouseLeave}>
          {this.state.loading && <Loader type="Puff" color="red" height="30" width="30" />}

          {!this.state.loading && !this.state.error && (
            <AuthorWikiDetail
              image={this.state.image}
              firstName={this.props.firstName}
              lastName={this.props.lastName}
              paragraph={this.state.paragraph}
            />
          )}

          {!this.state.loading && this.state.error && <NoInfo />}
        </div>
      </div>
    );
  }
}

export const AuthorWikiDetail = props => (
  <div id="customToolTip-inner">
    <div id="customToolTip-text">
      <div id="text" dangerouslySetInnerHTML={{ __html: props.paragraph }}></div>
      <div id="readMore">
        <a
          href={`https://en.wikipedia.org/wiki/${props.firstName}_${props.lastName}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={wikipedia} alt="dictionaryish wikipedia" />
        </a>
      </div>
    </div>
    <div id="customToolTip-image">
      <img className="" src={props.image} alt="author" />
    </div>
  </div>
);

AuthorWikiDetail.propTypes = {
  paragraph: PropTypes.string.isRequired, // basically HTML recieved from wikipedia PI
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired // Image url
};

const NoInfo = () => (
  <div id="noInfo">
    <p>No Info Yet</p>
  </div>
);
