import React, { useState, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AdsComponent from "../Addsense/addsense";
import IntervalMemes from "../../Memes/intervalMemes";
import Context from "./context/context";
import { likeHomepagePerspective, openModal } from "../../../actions/navbaractions";
import PerspectiveLoading from "../ReactLoading/PerspectiveLoading";
import { ARCHIVE_MODE, NEWS_MODE } from "../../../constants/navbar";
import emptyArchive from "../../../images/emptyArchive.png";
import { conceptCategory, SITE_TITLE } from "../../../constants/global";
import { Button } from "reactstrap";
import { getPerspectives, showData } from "../../../selectors/index";
import { BLACK } from "../../../constants/colors";
import MemeCard from "../../Memes/memeCard";
import TextMemeCard from "../../Memes/textMemeCard";
import * as actions from "../../../actions/conceptClustersActions";
export const PerspectiveContext = createContext();

const Perspectives = props => {
  const {
    archivePerspectives,
    data,
    loading,
    authorPage,
    archiveMode,
    newsMode,
    archives,
    isAuthenticated,
    perspectivesLength,
    subFilter,
    selectedOption,
    showData,
    FooterForm,
    memes,
    selectedAuthor,
    combinedData
  } = props;

  const [selectedId, setSelected] = useState(-1);
  // const [combinedData, setCombinedData] = useState([]);

  let perspectives = data;
  console.log(data, "gggggggg");

  if (archives) {
    perspectives = archivePerspectives;
  }
  // if (!showData) {
  //   perspectives = [];
  // }

  // useEffect(() => {
  //   const selectedSubFilterId =
  //     selectedSubFilter && selectedSubFilter.id ? selectedSubFilter.id : null;
  //   const selectedConceptId = selectedConcept && selectedConcept.id ? selectedConcept.id : null;

  //   const selectedAuthorId =
  //     selectedSubAuthor && selectedSubAuthor.id ? selectedSubAuthor.id : null;

  //   if (
  //     (selectedOption && selectedOption.id && selectedOption.id !== selectedConceptId) ||
  //     (subFilter && subFilter.id && subFilter.id !== selectedSubFilterId) ||
  //     (selectedAuthor && selectedAuthor.id && selectedAuthor.id !== selectedAuthorId)
  //   ) {
  //     setSelectedConcept(selectedOption);
  //     setSelectedSubFilter(subFilter);
  //     // setSelectedSubAuthor(selectedAuthor);
  //     setCombinedData([]);

  //     setTimeout(() => {
  //       setCombinedData([]);
  //     }, 0);
  //   }
  // }, [selectedOption, subFilter]);

  useEffect(() => {
    const page = window.location.pathname.split("/")[1];

    if (page === "Concepts" || page === "concepts") {
      let temp = [];
      let tempPerspectives = perspectives && perspectives.length > 0 ? perspectives : [];
      let tempMemes = memes && memes.length > 0 ? memes : [];

      temp = _.shuffle([...tempPerspectives.slice(-10), ..._.uniqBy(tempMemes.slice(-10), "id")]);
      // setCombinedData([...combinedData, ..._.difference(temp, combinedData)]);

      let combineData = [...combinedData, ..._.differenceWith(temp, combinedData, _.isEqual)];
      props.combineMemePerspective(combineData);
    } else {
      // setCombinedData(perspectives);
      perspectives = _.uniqBy(perspectives, "id");
      props.combineMemePerspective(perspectives);
    }
  }, [memes, perspectives]);

  return (
    <PerspectiveContext.Provider value={{ selectedId, setSelected }}>
      {perspectives && perspectives.length > 0 && (
        <PerspectiveLoading perspectiveLoading={loading} />
      )}
      {(perspectives || memes) &&
        combinedData.map((perspective, index) => {
          const isPerspective = perspective.hasOwnProperty("pronoun");
          perspective.index = index;
          return isPerspective ? (
            <div key={index}>
              {index === perspectivesLength &&
                selectedOption.category === conceptCategory &&
                subFilter && (
                  <div
                    style={{
                      borderTop: `2px solid ${BLACK}`,
                      borderBottom: `2px solid ${BLACK}`,
                      paddingTop: "2%",
                      textAlign: "center"
                    }}
                  >
                    <h3>
                      {subFilter.label} and {selectedOption.label}
                    </h3>
                  </div>
                )}
              {/* {index % 10 === 0 && index !== 0 && <AdsComponent />}
              <IntervalMemes
                index={index}
                interval={window.location.pathname === "/" ? (newsMode ? 5 : 8) : 8}
              /> */}
              {console.log(perspective, "ll")}

              <Context
                perspective={perspective}
                label={perspective.Concept.name}
                slug={perspective.Concept.slug}
                author={perspective.Author}
                index={index}
                id={perspective.ConceptId}
                onLikeClick={props.onLikeClick}
                authorPage={authorPage}
                onConceptClick={props.onConceptClick}
                onCountClick={props.onCountClick}
                onFactClick={props.onFactClick}
                authorCallBack={props.authorCallBack}
              />

              {index % 15 === 0 && index !== 0 && FooterForm}
            </div>
          ) : (
            <>
              {perspective.isText ? (
                <TextMemeCard meme={perspective} />
              ) : (
                <MemeCard meme={perspective} />
              )}
            </>
          );
        })}

      {perspectives &&
        perspectives.length < 1 &&
        window.location.pathname === "/" &&
        archiveMode &&
        !loading && (
          <div>
            <img src={emptyArchive} alt={SITE_TITLE + "archive"} style={styles.image} />
            {!isAuthenticated && (
              <Button onClick={props.openModal} color="primary" size="sm">
                Login
              </Button>
            )}
          </div>
        )}

      {perspectives && perspectives.length > 0 && (
        <PerspectiveLoading perspectiveLoading={loading} />
      )}
    </PerspectiveContext.Provider>
  );
};

Perspectives.prototype = {
  onConceptClick: PropTypes.func, // Only send from concepts page
  onCountClick: PropTypes.func, // Only send from concept page
  onFactClick: PropTypes.func, // Only send from concept page
  authorCallBack: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    onLikeClick: bindActionCreators(likeHomepagePerspective, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
    combineMemePerspective: bindActionCreators(actions.combineMemePerspective, dispatch)
  };
};

const mapStateToProps = state => {
  return {
    data: getPerspectives(state),
    archivePerspectives: state.archives.perspectives,
    loading: state.navbar.loading,
    archiveMode: state.navbar.mode === ARCHIVE_MODE,
    newsMode: state.navbar.mode === NEWS_MODE,
    isAuthenticated: state.auth.isAuthenticated,
    isPublic: state.archives.isPublic,
    archiveOwner: state.archives.archiveOwner,
    user: state.auth.user,
    showData: showData(state),
    perspectivesLength: state.navbar.perspectivesLength,
    selectedOption: state.navbar.selectedOption,
    subFilter: state.navbar.subFilter,
    memes: state.memes.memes,
    selectedAuthor: state.navbar.selectedAuthor,
    combinedData: state.navbar.combinedData
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Perspectives));

const styles = {
  image: {
    width: "100%",
    paddingTop: 70
  }
};
