import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DrawerButton from "../drawer/drawerButton";
import queryString from "query-string";
import shareIcon from "../../../images/imageIcons/share2.png";
import relatedAuthorsIcon from "../../../images/imageIcons/relatedAuthors2.png";
import backIcon from "../../../images/imageIcons/main.png";
import memesIcon from "../../../images/imageIcons/memes2.png";
import bio from "../../../images/imageIcons/author.png";
import conceptIcon from "../../../images/imageIcons/concepts.png";
import keywordIcon from "../../../images/imageIcons/keywords.png";
import newsIcon from "../../../images/imageIcons/news.png";
import commentIcon from "../../../images/imageIcons/comment.png";
import history from "../../../router/history";
import Memes from "./memeButton";

import "./miniHeader.css";
import { groupsServerCategory } from "../../../constants/global";
import { NEWS_MODE } from "../../../constants/navbar";
import { bindActionCreators } from "redux";
import { setCommentsMode } from "../../../actions/mobile";

const MiniHeader = props => {
  const {
    selectedOption,
    hideConcepts,
    hideRelateds,
    hideAuthor,
    hideAll,
    hideTrivia,
    showMenu,
    showBio,
    showKeywords,
    isRelateds,
    subFilter,
    insights,
    parentGroup,
    hideMain,
    hideMemes,
    showNews,
    newsMode,
    selectedNewsId,
    memesCount,
    keywords,
    relatedAuthors,
    memesMode,
    commentsMode,
    showComments,
    isAdmin,
    memePage
  } = props;
  const [group, setGroup] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const value = queryString.parse(window.location.search);
    if (Object.keys(value).length > 0) {
      if (value.fromGroup) {
        setGroup(parseInt(value.fromGroup));
      }
    }
  }, []);

  const onCopyClick = () => {
    if (!copied) {
      const link = `${process.env.REACT_APP_SERVER}?news=${props.selectedNewsId}`;
      navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    }
  };

  const homepage = window.location.pathname === "/";
  const newsPage = window.location.pathname === "/news";
  return (
    <>
      {!memePage && (
        <div className="d-f d-md-none mini-header sticky-mini-header">
          <div className="menu">
            {/* {!hideMain && <p onClick={props.handleMainClick}>main |</p>} */}
            {!hideMemes && memesCount > 0 && (
              <div className="right-drawer-button right-bottom-button">
                <Memes mobileView={true} />
              </div>
            )}

            {!hideRelateds && relatedAuthors && relatedAuthors.length > 0 && (
              // <img src={relatedAuthorsIcon} onClick={props.handleRelatedClick} className="left-drawer-button left-bottom-button" />
              <></>
            )}

            {(memesMode || commentsMode || subFilter) && (
              <img
                src={backIcon}
                alt="back"
                onClick={props.handleMainClick}
                className="left-drawer-button "
                style={{ top: 115 }}
              />
            )}

            {/* {!hideTrivia && <p onClick={props.handleTriviaClick}>trivia </p>} */}

            {!hideConcepts && (
              <img
                src={conceptIcon}
                alt="concept icon"
                onClick={props.handleConceptClick}
                className="left-drawer-button"
              />
            )}

            {showKeywords && keywords && keywords.length > 0 && (
              <img
                src={keywordIcon}
                alt="keyword icon"
                onClick={props.handleKeywordClick}
                className="left-drawer-button left-bottom-button"
              />
            )}

            {!hideAuthor && (
              <img
                src={relatedAuthorsIcon}
                alt="related author icon"
                onClick={props.handleAuthorClick}
                className="right-drawer-button"
              />
            )}

            {showNews && (
              <img
                src={newsIcon}
                onClick={props.handleNewsClick}
                className="right-drawer-button"
                alt="news icon"
              />
            )}

            {showBio && (
              <img src={bio} onClick={props.handleBioClick} className="right-drawer-button" />
            )}

            {showComments && !commentsMode && (
              <img
                src={commentIcon}
                alt="comment icon"
                onClick={() => props.setCommentsMode(true)}
                className="right-drawer-button page-comments-icon"
              />
            )}
          </div>
          <div id="back-button">
            {subFilter && <DrawerButton handleClick={props.handleMainClick} />}
            {!newsPage && !homepage && <span>{insights}</span>}
            {/* {homepage && newsMode && !copied && (
              <img onClick={onCopyClick} src={shareIcon} style={{ width: 20 }} alt="share icon" />
            )} */}
            {/* {newsMode && copied && (
              <>
                <i className="fa fa-check" /> Copied
              </>
            )} */}
          </div>
          {!homepage && (
            <div className="title text-truncate">
              {parentGroup && (
                <DrawerButton
                  handleClick={() => history.push(`/${groupsServerCategory}/${parentGroup}`)}
                />
              )}
              {selectedOption.value}
              &nbsp;
              <span>{subFilter && ` and ${subFilter.value}`}</span>
            </div>
          )}
        </div>
      )}
      {memePage && (
        <div
          className="d-f d-md-none mini-header sticky-mini-header"
          style={{ backgroundColor: "#0000", borderBottom: 0 }}
        >
          <img
            src={conceptIcon}
            alt="concept icon"
            onClick={props.handleConceptClick}
            className="left-drawer-button"
          />
        </div>
      )}
    </>
  );
};

MiniHeader.propTypes = {
  handleMainClick: PropTypes.func.isRequired,
  handleKeywordClick: PropTypes.func,
  handleConceptClick: PropTypes.func,
  handleAuthorClick: PropTypes.func,
  handleMemesClick: PropTypes.func.isRequired,
  handleRelatedClick: PropTypes.func,
  // handleTriviaClick: PropTypes.func.isRequired,
  handleBioClick: PropTypes.func,
  hideTrivia: PropTypes.bool,
  hideConcepts: PropTypes.bool.isRequired,
  hideAuthor: PropTypes.bool,
  hideRelateds: PropTypes.bool,
  showMenu: PropTypes.bool,
  isRelateds: PropTypes.bool,
  showKeywords: PropTypes.bool,
  parentGroup: PropTypes.string,
  hideMain: PropTypes.bool,
  hideMemes: PropTypes.bool,
  showNews: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    selectedOption: state.navbar.selectedOption,
    subFilter: state.navbar.subFilter,
    memesMode: state.mobile.memesMode,
    insights: state.navbar.insights,
    newsMode: state.navbar.mode === NEWS_MODE,
    selectedNewsId: state.navbar.selectedNewsId,
    memesCount: state.memes.count,
    keywords: state.navbar.concepts,
    relatedAuthors: state.author.relatedAuthors,
    commentsMode: state.mobile.commentsModes,
    isAdmin: state.auth.user.isAdmin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCommentsMode: bindActionCreators(setCommentsMode, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniHeader);
