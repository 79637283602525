import React from "react";
import AsyncSelect from "react-select/lib/Async";
import { headerStyles } from "../../TopNavBar/search/searchStyles";

const HeaderSubFilter = props => {
  return (
    <div
      data-html={true}
      data-place="right"
      data-tip={`<p>Search with in page</p><p>1. keywords<p>2. authors</p><p>3. biographical info</p>`}
    >
      <AsyncSelect
        loadOptions={props.loadOptions}
        onChange={props.onChange}
        styles={headerStyles}
        value=""
        onFocus={props.onFocus}
        noOptionsMessage={props.noOptionsMessage}
        placeholder={`Search within page..`}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      />
    </div>
  );
};

export default HeaderSubFilter;
