export const AUTHOR_LOADING = 'AUTHOR_LOADING'
export const AUTHOR_LOADING_COMPLETE = 'AUTHOR_LOADING_COMPLETE'
export const AUTHOR_DETAIL = 'AUTHOR_DETAIL'
export const AUTHOR_RELATEDS_LOADING = 'AUTHOR_RELATEDS_LOADING'
export const AUTHOR_RELATEDS = 'AUTHOR_RELATEDS'
export const REFRESH_LOADING = 'REFRESH_LOADING'
export const TEMP_RELATED_AUTHORS = 'TEMP_RELATED_AUTHORS'
export const TEMP_AUTHOR_LOADING = 'TEMP_AUTHOR_LOADING'
export const AUTHOR_CONCEPT_INDEX = 'AUTHOR_CONCEPT_INDEX'
export const AUTHOR_SELECTED_ID = 'AUTHOR_SELECTED_ID'
export const CONCEPT_SELECTED_ID = 'CONCEPT_SELECTED_ID'
export const SET_AUTHOR_SORTING = 'SET_AUTHOR_SORTING'
export const AUTHOR_OFFSET = 'AUTHOR_OFFSET'
export const BLOCK_PERSPECTIVE_LOADING = 'BLOCK_PERSPECTIVE_LOADING'
export const SAVE_AUTHOR_WIKI = 'SAVE_AUTHOR_WIKI'
export const AUTHOR_WIKI_LOADING = 'AUTHOR_WIKI_LOADING'
export const AUTHOR_SUBMIT_LOADING = "AUTHOR_SUBMIT_LOADING"
export const AUTHOR_SUBMIT_ERROR = "AUTHOR_SUBMIT_ERROR"
export const SINGLE_BOOK_RELATED_ALL_PERSPECTIVE_CONCEPTS_SUCCESS = 'SINGLE_BOOK_RELATED_ALL_PERSPECTIVE_CONCEPTS_SUCCESS'
export const GET_ALL_AUTHORS_BY_CHAR="GET_ALL_AUTHORS_BY_CHAR"
export const GET_ALL_AUTHORS_BY_GROUP_NAME = "GET_ALL_AUTHORS_BY_GROUP_NAME"
