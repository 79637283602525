import keywordSorterFunction from "../helpers/keywordsSorterFunction";
import conceptsSorter from "../helpers/conceptHelpers";
import * as global from "../constants/global";
import { perspectivesByKeywords } from "../services/perspectiveServices";
import { ARCHIVE_MODE } from "../constants/navbar";

export function getPerspectivesByArchives(state) {
  let archives = state.archives;
  let perspective = [];
  if (
    archives.archiveData &&
    archives.archiveData.perspective &&
    Object.keys(archives.archiveData.perspective).length > 0
  ) {
    let archivePerspective = archives.archiveData.perspective;
    Object.keys(archivePerspective).forEach(key => {
      perspective.push(archivePerspective[key]);
    });
  }
  return perspective;
}

export function getTempConcepts(state) {
  let { concepts, tempConcepts } = state.concepts;
  const { selectedOption } = state.navbar;
  if (selectedOption.category !== global.conceptCategory) {
    concepts = state.navbar.concepts;
  }
  tempConcepts = tempConcepts.map(temp => {
    temp.reciprocalExist = concepts.findIndex(x => x.id === temp.id) > -1;
    return temp;
  });
  return tempConcepts;
}

export function getTempCollections(state) {
  let { alphabets, relatedGroups } = state.books;
  relatedGroups = relatedGroups.map(temp => {
    temp.reciprocalExist = alphabets.findIndex(x => x.id === temp.id) > -1;
    return temp;
  });
  return relatedGroups;
}

export function getTempAuthors(state) {
  let { relatedAuthors, tempAuthors } = state.author;
  if (relatedAuthors) {
    tempAuthors = tempAuthors.map(temp => {
      temp.reciprocalExist = relatedAuthors.findIndex(x => x.id === temp.id) > -1;
      return temp;
    });
  }
  return [...tempAuthors];
}

export function getRelatedConcepts(state) {
  let data = [];
  const { concepts } = state.concepts;
  if (concepts) {
    data = concepts.sort(conceptsSorter);
  }
  return data;
}

export const mapArrayToColumns = array => {
  let itemsPerColumn = Math.ceil(array.length / 3);
  let data = [];
  for (let i = 0; i < itemsPerColumn; i++) {
    for (let j = i; j < array.length; j = j + itemsPerColumn) {
      data.push(array[j]);
    }
  }
  return data;
};

export function getRelatedAuthors(state) {
  let authors = [];
  let author_author = state.author.author_authors;
  if (author_author) {
    authors = author_author.AuthorInfluenceAuthors;
  }
  return authors;
}

export function getAuthorImages(author) {
  let Images = [];
  if (author.authorsWithDetails && author.authorsWithDetails.Images)
    Images = author.authorsWithDetails.Images;
  return Images;
}

export function recentlyLikedPerspectives(navbar) {
  let perspectiveOrder = navbar.totalTerms.perspectiveOrder;
  let recentPerspectives = navbar.totalTerms.perspectives;
  let perspectives = [];
  if (perspectiveOrder) {
    perspectiveOrder.forEach(data => {
      perspectives.push(recentPerspectives.find(x => x.id === data.id));
    });
  }
  return perspectives;
}

export function getAllDataAutherCluster(concepts, authors, authorGroups) {
  let data = mapConcepts(concepts);
  if (authors) {
    let mappedAuthors = mapAuthors(authors);
    data = data.concat(mappedAuthors);
  }
  if (authorGroups) {
    let mappedAuthorGroups = mapAuthorGroups(authorGroups);
    data = data.concat(mappedAuthorGroups);
  }
  return data;
}

export function getAllDataAutherPage(state) {
  let concepts = getUniqueConcept(state);
  return mapConcepts(concepts);
}

const mapAuthorGroups = groups => {
  let Data = [];
  if (groups)
    groups.map((group, index) => {
      return Data.push({
        id: group.id,
        myIndex: index,
        value: group.name,
        label: group.name,
        color: "Green",
        type: global.groupsCategory
      });
    });
  return Data;
};

export const mapConcepts = concepts => {
  let Data = [];
  if (concepts)
    concepts.map((concept, index) => {
      return Data.push({
        id: concept.id,
        myIndex: index,
        value: concept.name,
        label: concept.name,
        color: "#00B8D9",
        type: global.conceptCategory
      });
    });
  return Data;
};

export function getConceptsByArchives(state) {
  let archives = state.archives;
  let concepts = [];
  if (
    archives.archiveData &&
    archives.archiveData.concept &&
    Object.keys(archives.archiveData.concept).length > 0
  ) {
    let archiveConcept = archives.archiveData.concept;
    Object.keys(archiveConcept).forEach(key => {
      concepts.push(archiveConcept[key]);
    });
  }
  return concepts;
}

export function getAuthorsByArchives(state) {
  let archives = state.archives;
  let authors = [];
  if (
    archives.archiveData &&
    archives.archiveData.author &&
    Object.keys(archives.archiveData.author).length > 0
  ) {
    let archiveAuthors = archives.archiveData.author;
    Object.keys(archiveAuthors).forEach(key => {
      authors.push(archiveAuthors[key]);
    });
  }
  return authors;
}

export function getUniqueKeywords(state) {
  let keywords = state.concepts.keywords;
  return keywords.sort(keywordSorterFunction);
}

export function getUniqueSortedAuthors(state) {
  let perspectiveids = state.concepts.perspectiveIds;
  let perspectivesRelations = state.concepts.perspectivesRelations;
  let authors = state.concepts.authors;
  if (perspectiveids && perspectiveids.length > 0 && authors && perspectivesRelations) {
    let uniqueAuthorWithDetails = [];
    let uniqueAuthorNames = [];
    perspectiveids.forEach(id => {
      if (
        !(
          uniqueAuthorNames.indexOf(
            authors[perspectivesRelations[id].Author].firstName +
              authors[perspectivesRelations[id].Author].lastName
          ) > -1
        )
      ) {
        uniqueAuthorNames.push(
          authors[perspectivesRelations[id].Author].firstName +
            authors[perspectivesRelations[id].Author].lastName
        );
        uniqueAuthorWithDetails.push(authors[perspectivesRelations[id].Author]);
      }
    });
    return uniqueAuthorWithDetails.sort(function(a, b) {
      let authorALastName = a.lastName.toUpperCase();
      let authorBLastName = b.lastName.toUpperCase();
      return authorALastName < authorBLastName ? -1 : authorALastName > authorBLastName ? 1 : 0;
    });
  }
}

export function getUniqueConcept(state) {
  let concepts = [];
  if (state.author.authorsWithDetails && state.author.authorsWithDetails.sortedKeywords) {
    if (state.author.authorsWithDetails.sortedKeywords.length > 0) {
      concepts = state.author.authorsWithDetails.sortedKeywords;
    }
    concepts.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }
  return concepts;
}

export function getSortedPerspectivesByConceptName(state) {
  let perspectives = state.author.authorsWithDetails.Perspectives;
  let newPerspectives = [];
  if (perspectives && state.author.sortByKeywords) {
    let concepts = getUniqueConcept(state);
    concepts.forEach(concept => {
      let data = perspectives.filter(p => p.concept_id === concept.id);
      data.forEach(pr => {
        newPerspectives.push(pr);
      });
    });
  } else {
    newPerspectives = perspectives;
  }
  return newPerspectives;
}

export function getSortedPerspectivesByLikes(perspectives) {
  if (!perspectives) perspectives = [];
  return perspectives.sort((a, b) => {
    if (!a.loves) a.loves = 0;
    if (!b.loves) b.loves = 0;
    return a.loves > b.loves ? -1 : 0;
  });
}

export function getUniqueAuthorBooks(state) {
  let author = state.author.author;
  let books = state.author.authorBooks;
  if (author && author.Books && author.Books.length > 0 && books) {
    let bookids = author.Books;
    let uniqueBookNameWithId = [];
    let uniqueBookIds = [];
    bookids.forEach(bookid => {
      if (!(uniqueBookIds.indexOf(books[bookid].id) > -1)) {
        uniqueBookIds.push(books[bookid].id);
        let discrete_book_id = books[bookid].id;
        let title = books[bookid].title;
        uniqueBookNameWithId.push({ discrete_book_id, title });
      }
    });
    return uniqueBookNameWithId;
  }
}

export function getAuthorBioData(state) {
  let authorGroups = state.author.authorGroups;
  let authorBioHeadings = state.author.authorBioHeadings;
  let author = state.author.author;
  if (author && author.AuthorGroups && author.AuthorGroups.length > 0) {
    let data = [];
    let uniqueBioHeadings = [];
    author.AuthorGroups.forEach(id => {
      if (authorGroups[id].AuthorBioHeading) {
        if (
          !uniqueBioHeadings.includes(authorBioHeadings[authorGroups[id].AuthorBioHeading].name)
        ) {
          uniqueBioHeadings.push(authorBioHeadings[authorGroups[id].AuthorBioHeading].name);
          data.push({
            heading: authorBioHeadings[authorGroups[id].AuthorBioHeading].name,
            values: [{ name: authorGroups[id].name, id: authorGroups[id].id }]
          });
        } else {
          data.forEach(element => {
            if (element.heading === authorBioHeadings[authorGroups[id].AuthorBioHeading].name) {
              element.values.push({ name: authorGroups[id].name, id: authorGroups[id].id });
            }
          });
        }
      }
    });
    return data;
  }
  return;
}

export function getRelatedGroups(state) {
  let authorGroups = state.author.authorGroups;
  let temp = [];
  let data = [];
  if (!Array.isArray(authorGroups)) authorGroups = [];
  authorGroups.forEach(group => {
    let bioHeading = group.AuthorBioHeading;
    if (bioHeading && !temp.includes(bioHeading.id)) {
      let groups = authorGroups.filter(relateds => relateds.AuthorBioHeadingId === bioHeading.id);
      data.push({
        heading: bioHeading.name,
        values: groups
      });
      temp.push(bioHeading.id);
    }
  });
  return data;
}

export function getAllData(state) {
  let Data = [];
  let { concepts, authors } = state.navbar;
  let { authorGroups } = state.concepts;
  Array.isArray(concepts) &&
    concepts.forEach((keyword, index) => {
      Data.push({
        id: keyword.id,
        myIndex: index,
        value: keyword.name,
        label: keyword.name,
        color: "#00B8D9",
        type: global.keywordCategory
      });
    });
  if (authors) {
    let mappedAuthors = mapAuthors(authors);
    Data = [...Data, ...mappedAuthors];
  }
  Data = Data.concat(authorGroups);
  return Data;
}
function mapAuthors(authors) {
  let data = [];
  authors.forEach((author, index) => {
    data.push({
      id: author.id,
      myIndex: index,
      value: author.firstName + " " + author.lastName,
      label: author.firstName + " " + author.lastName,
      color: "#A52A2A",
      type: global.authorCategory
    });
  });
  return data;
}

export function getUniqueSortedConceptsFromConceptCluster(state) {
  let conceptCluster = state.conceptClusters.conceptCluster;
  let concepts = state.conceptClusters.concepts;
  if (conceptCluster && conceptCluster.Concepts && conceptCluster.Concepts.length > 0 && concepts) {
    let uniqueConceptNames = [];
    let sortedUniqueConcepts = [];
    conceptCluster.Concepts.forEach(id => {
      if (!uniqueConceptNames.includes(concepts[id].name)) {
        uniqueConceptNames.push(concepts[id].name);
        sortedUniqueConcepts.push(concepts[id]);
      }
    });
    return sortedUniqueConcepts.sort((a, b) => {
      let conceptNameA = a.name.toLowerCase();
      let conceptNameB = b.name.toLowerCase();
      return conceptNameA < conceptNameB ? -1 : conceptNameA > conceptNameB ? 1 : 0;
    });
  }
}

export function getPerspectivesFromConceptCluster(state) {
  return state.conceptClusters.conceptClusterWithDetails;
}

export function getUniqueAuthorsFromConceptCluster(state) {
  let concepts = state.conceptClusters.concepts;
  let authors = state.conceptClusters.authors;
  let perspective = state.conceptClusters.perspective;
  let conceptCluster = state.conceptClusters.conceptCluster;
  if (conceptCluster && conceptCluster.Concepts && concepts && perspective && authors) {
    const conceptids = conceptCluster.Concepts;
    const uniqueAuthors = [];
    const uniqueAuthorNames = [];
    conceptids.forEach(id => {
      concepts[id].Perspectives.forEach(perspecid => {
        if (!uniqueAuthorNames.includes(perspective[perspecid].AuthorId)) {
          uniqueAuthorNames.push(perspective[perspecid].AuthorId);
          uniqueAuthors.push({
            id: perspective[perspecid].AuthorId,
            firstName: authors[perspective[perspecid].Author].firstName,
            lastName: authors[perspective[perspecid].Author].lastName
          });
        }
      });
    });
    uniqueAuthors.sort(function(a, b) {
      let authorALastName = a.lastName.toUpperCase();
      let authorBLastName = b.lastName.toUpperCase();
      return authorALastName < authorBLastName ? -1 : authorALastName > authorBLastName ? 1 : 0;
    });
    return uniqueAuthors;
  }
}

export function getAuthorsOfGroup(authorCluster) {
  let data = [];
  let authors = authorCluster.authors;
  if (authors) {
    Object.keys(authors).forEach(id => {
      data.push(authors[id]);
    });
  }
  return data;
}

function getAuthorGroupsOfGroup(groups) {
  let data = [];
  Object.keys(groups).forEach(id => {
    data.push(groups[id]);
  });
  return data;
}

export function getGroupsAuthors(authorCluster) {
  let data = [];
  let authorGroups = authorCluster.authorGroups;
  let authors = authorCluster.authors;

  let subFilter = parseInt(authorCluster.authorGroupSubFilter);
  if (subFilter && authorGroups) {
    let group = authorGroups[subFilter];
    let authorsOfGroup = group.Authors;
    authorsOfGroup.forEach(id => {
      data.push(authors[id]);
    });
  } else {
    data = getAuthorsOfGroup(authorCluster);
  }
  return data.sort(function(a, b) {
    return a.lastName.localeCompare(b.lastName);
  });
}

export function getUniqueSortedConceptNameFromAuthorCluster(state) {
  let perspectives = state.autherCluster.perspectives;
  let authorCluster = state.autherCluster.authorCluster;
  if (perspectives && perspectives.length > 0 && authorCluster) {
    let uniqueConceptNames = [];
    let uniqueConcepts = [];
    perspectives.forEach(conceptid => {
      if (!uniqueConceptNames.includes(authorCluster[conceptid].conceptName)) {
        uniqueConceptNames.push(authorCluster[conceptid].conceptName);
        uniqueConcepts.push({
          name: authorCluster[conceptid].conceptName,
          id: authorCluster[conceptid].conceptId
        });
      }
    });
    return uniqueConcepts.sort(conceptsSorter);
  }
}

export function getDynamicSortedAuthorClusterWithDetails(state) {
  let perspectives = state.autherCluster.perspectives;
  let authorCluster = state.autherCluster.authorCluster;
  let copyAuthorClusterWithDetails = [];
  if (authorCluster) {
    let authorClicked = authorCluster.authorClicked;
    let conceptClicked = authorCluster.conceptClicked;
    if (perspectives && perspectives.length > 0 && authorCluster) {
      perspectives.forEach(id => {
        copyAuthorClusterWithDetails.push(authorCluster[id]);
      });
      if (authorClicked) {
        copyAuthorClusterWithDetails.sort(function(a, b) {
          var textA = a.authorLastName.toUpperCase();
          var textB = b.authorLastName.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (conceptClicked) {
        copyAuthorClusterWithDetails.sort(function(a, b) {
          var authorA = a.authorLastName.toUpperCase();
          var authorB = b.authorLastName.toUpperCase();
          var textA = a.conceptName.toUpperCase();
          var textB = b.conceptName.toUpperCase();
          if (textA === textB) {
            return authorA < authorB ? -1 : authorA > authorB ? 1 : 0;
          } else {
            if (textA === "CULTURE") {
            } else {
              if (textA === "CULTURE") {
              }
              return textA < textB ? -1 : 1;
            }
          }
          return true;
        });
      }
    }
  }
  return copyAuthorClusterWithDetails;
}

export function getSortedAuthorClusterWithDetailsByAuthorLast(state) {
  let clustersWithDetails = state.autherCluster.authorClustersWithDetails;
  if (clustersWithDetails && clustersWithDetails.length > 0) {
    let copyAuthorClusterWithDetails = [...clustersWithDetails];
    copyAuthorClusterWithDetails.sort(function(a, b) {
      var textA = a.authorLastName.toUpperCase();
      var textB = b.authorLastName.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return copyAuthorClusterWithDetails;
  }
}

export function getFilteredPerspectives(state) {
  let perspectivesRelations = state.concepts.perspectivesRelations;
  let authors = state.concepts.authors;
  let perspectivesByAuthor = state.concepts.perspectivesByAuthor;
  let sortedAuthorIds = state.concepts.sortedAuthorIds;

  let normalizedPerspectives = state.concepts.normalizedPerspectives;
  if (perspectivesRelations && authors && sortedAuthorIds && sortedAuthorIds.length > 0) {
    let result = [];
    if (state.concepts.sortByLikes) {
      normalizedPerspectives.forEach(temp => {
        if (perspectivesRelations[temp.id]) {
          temp.Author = perspectivesRelations[temp.id].Author;
          temp.Keywords = perspectivesRelations[temp.id].Keywords;
          result.push(temp);
        }
      });
    } else {
      sortedAuthorIds.forEach(id => {
        if (perspectivesByAuthor[id]) {
          perspectivesByAuthor[id].forEach(p => {
            let temp = {};
            if (perspectivesRelations[p]) {
              temp = normalizedPerspectives[p];
              temp.Author = perspectivesRelations[p].Author;
              temp.Keywords = perspectivesRelations[p].Keywords;
              result.push(temp);
            }
          });
        }
      });
    }
    return result;
  }
}

export const mapConceptKeywords = state => {
  let { concepts, subFilter } = state.navbar;
  if (subFilter && subFilter.type === global.factCategory) {
    concepts = concepts.filter(x => x.isFact);
  }
  return concepts;
};

export const getPerspectives = state => {
  try {
    let {
      navbar: { perspectives, selectedOption, subFilter, mode },
      concepts: { factKeywordFilter }
    } = state;

    if (
      factKeywordFilter > -1 &&
      selectedOption.category === global.conceptCategory &&
      subFilter &&
      subFilter.type === global.factCategory
    ) {
      perspectives = perspectives.filter(perspective => {
        const keywords = perspective.perspectiveKeywords;
        let belongs = false;
        keywords.forEach(x => {
          if (x.id === factKeywordFilter) {
            belongs = true;
          }
        });
        return belongs;
      });
    }

    // Fitler Perpsecitves array if there a fitler on Archive apges
    const homepage = window.location.pathname === "/";
    if (homepage && mode === ARCHIVE_MODE) {
      const { authorFilterId, conceptFilterId } = state.archives;
      // If author Filter is exist on achive page
      if (authorFilterId) {
        perspectives = perspectives.filter(perspective => perspective.AuthorId === authorFilterId);
      }
      // if(conceptFilterId)
      // {
      //     perspectives = perspectives.filter(perspective => perspective.ConceptId === conceptFilterId)
      // }
    }

    return perspectives;
  } catch (e) {
    console.log("error is ", e);
  }
};

export const showData = state => {
  const { isAuthenticated, user } = state.auth;
  const { archiveOwner, isPublic } = state.archives;
  const { mode } = state.navbar;
  const archiveMode = mode === ARCHIVE_MODE;
  let show = true;

  if (!isAuthenticated) {
    if (archiveMode && !isPublic) {
      show = false;
    }
  } else {
    if (archiveMode && user.id !== archiveOwner && !isPublic) {
      show = false;
    }
  }
  return show;
};

export const haveNewBie = state => {
  const { books } = state.books;
  let haveNewBie = false;
  books.forEach(book => {
    if (book.pop == 1 || book.pop == 2) {
      haveNewBie = true;
    }
  });
  return haveNewBie;
};

export const getFilteredBooks = state => {
  let { newBie, books, sort } = state.books;
  if (newBie) {
    books = books.filter(book => {
      return book.pop == 1 || book.pop == 2;
    });
  } else if (sort === global.SORT_BY_DATE || sort === global.SORT_BY_RANKING) {
    books = books.filter(book => {
      if (book.pop == 1 || book.pop == 2) {
        return false;
      } else return true;
    });
  }
  return [...books];
};

export const getNextConcept = (concepts, selectedConceptId) => {
  let nextConcept;
  if (selectedConceptId > 0) {
    const index = concepts.findIndex(concept => concept.id === selectedConceptId);
    if (index > 0) {
      nextConcept = concepts[index + 1];
    }
  }
  return nextConcept;
};

export const getPrevConcept = (concepts, selectedConceptId) => {
  let prevConcept;
  if (selectedConceptId > 0) {
    const index = concepts.findIndex(concept => concept.id === selectedConceptId);
    if (index > 0) {
      prevConcept = concepts[index - 1];
    }
  }
  return prevConcept;
};

export const getNextAuthors = (authors, selectedAuthorId) => {
  if (selectedAuthorId > 0) {
    const index = authors.findIndex(author => author.id === selectedAuthorId);
    if (index > 0) {
      return authors[index + 1];
    }
  }
};

export const getPrevAuthors = (authors, selectedAuthorId) => {
  let prevAuthors;
  if (selectedAuthorId > 0) {
    const index = authors.findIndex(author => author.id === selectedAuthorId);
    if (index > 0) {
      prevAuthors = authors[index - 1];
    }
  }
  return prevAuthors;
};
