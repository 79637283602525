import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import KeywordList from "../context/keywordList";
import ToolButtons from "../ToolButtons/ToolButtons";
import * as global from "../../../../constants/global";
import * as constants from "../../../../constants/navbar";
import * as navbarActions from "../../../../actions/navbaractions";
import PerspectiveFooter from "../perspectiveFooter/footer";
import FactFooter from "../perspectiveFooter/fact";
import { NEWS_MODE } from "../../../../constants/navbar";
import history from "../../../../router/history";
import "./PerspectiveBody.css";
import { Row, Col, Container } from "reactstrap";
import { resetScroll } from "../../../../helpers/scrolling";
import { Link } from "react-router-dom";
import ShareButtons from "../../../SocialShareButtons/ShareButtons";
import PerspectiveToolTip from "../tooltip/perspectiveToolTip";
import { pageReloadscroll } from "../../../../helpers/navbar";
import PerspectiveHeader from "../PerspectiveHeader/PerspectiveHeader";

const PerspectiveBody = props => {
  const {
    perspective,
    label,
    borderTop,
    index,
    slug,
    id,
    author,
    selectedOption,
    user,
    newsMode,
    selectedId,
    isSinglePerspective,
    authorPage
  } = props;

  const [focus, setFocus] = useState(false);
  const { factCount } = perspective.Concept;
  const [description, setDescription] = useState(false);
  const [active, setActive] = useState(false);

  const handleTitleClick = concept => {
    history.push(`/dictionary/${concept}`);
  };
  const onConceptClick = () => {
    const iframe = window.location.pathname.includes("/archive/");
    if (iframe) {
      const win = window.open(
        `${process.env.REACT_APP_SERVER}/${global.conceptCategory}/${perspective.Concept.slug}`,
        "_blank"
      );
      win.focus();
    } else {
      const homePage = window.location.pathname === "/";
      if (selectedOption.category !== global.conceptCategory || (homePage && newsMode)) {
        const item = {
          id: perspective.ConceptId,
          value: label,
          label,
          category: global.conceptCategory
        };
        props.setSelectedItem(item);
      } else {
        props.onConceptClick();
      }
    }
  };

  const [mouseInDiv, setMouseInDiv] = useState(false);

  const handleHover = () => {
    if (active || focus) {
      setMouseInDiv(true);
    } else {
      setMouseInDiv(!mouseInDiv);
    }
  };

  const handleFactClick = () => {
    resetScroll();
    if (selectedOption.category === global.conceptCategory) {
      props.onFactClick();
    } else {
      // const { name } = perspective.Concept
      // // history.pushState(`/${global.conceptCategory}/${name}?facts=true`)
      // history.push({
      //     pathname: `/${global.conceptCategory}/${name}?facts=true`,
      //     search: '?facts=false',
      // });
      // props.setSubFilter({
      //     id: true,
      //     type: global.factCategory
      // })
    }
  };

  let selected = selectedId === perspective.ConceptId;

  return (
    <div
      id={id}
      onMouseEnter={!mouseInDiv ? handleHover : () => {}}
      onMouseLeave={handleHover}
      // id="perspectiveDev"
      className="clearfix shadow"
      style={{
        borderTop: borderTop || isSinglePerspective ? "1px solid #ececec" : "none",
        cursor: "default",
        paddingBottom: 5,
        //paddingTop: 25,
        backgroundColor:
          author.lastName === "Proverbs" || author.lastName === "proverbs" ? "#103d9e" : "black"
        // zIndex: 0
      }}
    >
      <div className={isSinglePerspective ? "singlePerspective-box" : ""} style={{ margin: 15 }}>
        <PerspectiveDetail
          data={perspective}
          label={label}
          slug={slug}
          onConceptClick={onConceptClick}
          handleTitleClick={handleTitleClick}
          selected={selected}
          isConcept={selectedOption.category === global.conceptCategory}
          isSinglePerspective={isSinglePerspective}
          authorPage={authorPage}
          author={author}
        />
        {isSinglePerspective && (
          <div style={{ marginTop: 10 }}>
            <b
              style={{ color: "white", display: "flex" }}
              className="hover-grey"
              id="author"
              onMouseEnter={props.mouseEnter}
            >
              {author.firstName} {author.lastName}
            </b>
          </div>
        )}
      </div>
      <div
        id={perspective.id}
        className="description"
        style={{
          display: description ? "block" : "none",
          fontFamily: "garamond",
          fontWeight: "1rem",
          color: "white",
          margin: 15
        }}
      >
        {perspective.longDescription}
        <div>{perspective.citation}</div>
      </div>

      {/* <ToolButtons
        perspective={perspective}
        author={author}
        label={label}
        onLikeClick={props.onLikeClick}
        index={index}
        isSinglePerspective={isSinglePerspective}
        authorCallBack={props.authorCallBack}
      /> */}

      <PerspectiveFooter
        perspective={perspective}
        label={label}
        onCountClick={props.onCountClick}
      />
      <div>
        <div>
          {
            <ShareButtons
              perspective={perspective}
              title={label}
              authorName={author.firstName + " " + author.lastName}
              setDescription={setDescription}
              description={description}
              author={author}
              isSinglePerspective={isSinglePerspective}
              user={user}
              index={index}
              active={active}
              setActive={setActive}
              onLikeClick={props.onLikeClick}
              authorCallBack={props.authorCallBack}
              archiveMode={props.archiveMode}
            />
          }
        </div>
        {perspective.User && (
          <div className="user-wrapper">Submitted by {perspective.User.name}</div>
        )}
      </div>

      {perspective.isFact && factCount > 0 && (
        <FactFooter data={factCount} onFactClick={handleFactClick} />
      )}
      <div style={{ marginTop: "3px", backgroundColor: "white" }}>
        <div
          className="col"
          // style={{ position: !user.isAdmin && "absolute", backgroundColor: "white" }}
        >
          {/* {console.log(perspective, "22")} */}
          {perspective.perspectiveKeywords && (
            <KeywordList
              focus={focus}
              setFocus={setFocus}
              keywords={perspective.perspectiveKeywords}
              perspective={perspective}
              author={author}
              showKeywordButton={
                user ? (user.isPremium ? true : user.isAdmin ? true : false) : false
              }
              onKeywordClick={props.setSelectedItem}
            />
          )}
        </div>
        {/* <div className="col-2"> */}
        {/* {user.isAdmin && (
            <PerspectiveToolTip
              perspective={perspective}
              user={user}
              relatedConcepts={props.relatedConcepts}
              nature={global.PERSPECTIVE_EDIT_TYPE}
            />
          )} */}
        {/* </div> */}
      </div>
    </div>
  );
};

PerspectiveBody.propTypes = {
  perspective: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  authorPage: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  borderTop: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onConceptClick: PropTypes.func,
  onCountClick: PropTypes.func,
  authorCallBack: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedItem: bindActionCreators(navbarActions.setSelectedItem, dispatch),
    setSubFilter: bindActionCreators(navbarActions.setSubFilter, dispatch)
  };
};

const mapStateToProps = state => {
  return {
    selectedOption: state.navbar.selectedOption,
    user: state.auth.user,
    newsMode: state.navbar.mode === NEWS_MODE,
    selectedId: state.navbar.selectedConceptId,
    relatedConcepts: state.concepts.tempConcepts,
    archiveMode: state.navbar.mode === constants.ARCHIVE_MODE
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PerspectiveBody));

const PerspectiveDetail = props => (
  <div>
    <Row>
      <Col>
        <a href={`/Concepts/${props.slug}`}>
          <span
            style={{
              textTransform: "capitalize",
              fontFamily: "garamond",
              justifyContent: "left",
              fontSize: "3rem",
              padding: "0",
              color: "white",
              cursor: "pointer"
            }}
          >
            {props.data.pronoun} {props.label}
          </span>
          <span style={{ fontSize: "1rem", color: "white" }}> (n.)</span>
        </a>
        <hr style={{ marginTop: "0px", color: "white" }} />
      </Col>
    </Row>
    <Row>
      <Col>
        {props.isConcept ? (
          <a
            href={`/Concepts/${props.slug}`}
            onClick={e => {
              e.preventDefault();
            }}
            style={{ color: "white", textDecoration: "none" }}
          >
            <span onClick={props.onConceptClick} style={styles.link}>
              <b
                id="pronoun"
                style={{
                  color: "white",
                  fontFamily: "garamond",
                  textTransform: "capitalize",
                  fontSize: "1.9rem"
                }}
              >
                {props.data.pronoun} {props.label}
              </b>
            </span>
            <span style={{ fontWeight: "1rem", color: "white" }}> (n.)</span>
          </a>
        ) : (
          <Link
            to={`/Concepts/${props.slug}`}
            style={styles.link}
            onClick={() => pageReloadscroll()}
          >
            <b
              id="pronoun"
              style={{
                color: "white",
                fontFamily: "garamond",
                textTransform: "capitalize",
                fontSize: "1.9rem"
              }}
            >
              {props.data.pronoun} {props.label}
            </b>
          </Link>
        )}
        {/* <span style={{ fontFamily: "garamond", fontSize: "1.9rem", color: "white" }}>{" is"}</span> */}
        {props.data.description.startsWith(",") ? "" : " "}
        <span
          style={{
            fontFamily: "garamond",
            fontSize: "1.9rem",
            textAlign: "left",
            color: "white",
            textJustify: "none"
          }}
        >
          {props.data.description}
        </span>

        {props.author.id !== 228 && !props.isSinglePerspective && (
          <PerspectiveHeader author={props.author} />
        )}
      </Col>
    </Row>
  </div>
);

const styles = {
  linkColor: {
    color: "white",
    textDecoration: "none"
  }
};
