import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import ReactLoading from 'react-loading';
import './loading.css'

export const Loading = props => {
    const {columnLoading} = props
    return (
        <Fragment>
            {columnLoading && 
                <ReactLoading 
                    type={"spokes"} 
                    color={"black"} 
                    width={35}
                    className={'loaderSpoke'}
                />
            }
        </Fragment>  
    )
}

const mapStateToProps = (state) => ({
    columnLoading: state.navbar.columnLoading
})

export default connect(mapStateToProps, null)(Loading)