import { BASE_URL } from "../constants/global";
import axios from "axios";
import { Types } from "aws-sdk/clients/acm";

export const addNewPos = (pos, token) => {
  return axios.post(`${BASE_URL}/pos`, pos, { headers: { Authorization: token } });
};
export const addNewCategory = (category, token) => {
  return axios.post(`${BASE_URL}/pos/posCategories/term`, category, {
    headers: { Authorization: token }
  });
};

export const getAllConceptsPos = (conceptName, token) => {
  return axios.get(`${BASE_URL}/pos/concept/${conceptName}`, { headers: { Authorization: token } });
};

export const deletePosApi = (id, token) => {
  return axios.delete(`${BASE_URL}/pos/${id}`, { headers: { Authorization: token } });
};

export const getPosCategories = token => {
  return axios.get(`${BASE_URL}/pos/posCategories`, { headers: { Authorization: token } });
};

export const getPosCategoriesValues = (conceptId, categoryId, token) => {
  return axios.get(`${BASE_URL}/pos/concept/posCategories/${conceptId}/${categoryId}`, {
    headers: { Authorization: token }
  });
};

export const getAllConceptSimiles = (conceptId, token) => {
  return axios.get(`${BASE_URL}/simile/concept/${conceptId}`, {
    headers: { Authorization: token }
  });
};

export const getAllPosSimiles = (posId, token) => {
  return axios.get(`${BASE_URL}/simile/pos/${posId}`, { headers: { Authorization: token } });
};
export const getAllPosSynonymsContent = (posName, type, token, offset) => {
  return axios.get(
    `${BASE_URL}/simile/posSynonyms?posName=${posName}&type=${type}&offset=${offset}`,
    {
      headers: { Authorization: token }
    }
  );
};

export const getPosSynonyms = (name, token) => {
  return axios.get(`${BASE_URL}/pos/synonym/${name}`, { headers: { Authorization: token } });
};
export const getPosConcepts = (name, token) => {
  return axios.get(`${BASE_URL}/pos/collocations/${name}`, { headers: { Authorization: token } });
};
export const getPosSynonymsConcepts = (name, token) => {
  return axios.get(`${BASE_URL}/pos/posSynonyms/collocations/${name}`, {
    headers: { Authorization: token }
  });
};

export const likeSimile = (data, token) => {
  return axios.post(`${BASE_URL}/simile/favorite`, data, { headers: { Authorization: token } });
};

export const deletePos = (data, token) => {
  return axios.delete(`${BASE_URL}`, data, { headers: { Authorization: token } });
};

export const updatePos = (id, data, token) => {
  return axios.patch(`${BASE_URL}/pos/${id}`, data, { headers: { Authorization: token } });
};

export const updatePosSynonyms = (id, data, token) => {
  return axios.patch(`${BASE_URL}/pos/synonym/${id}`, data, { headers: { Authorization: token } });
};

export const addPosCollocations = (id, data, token) => {
  return axios.patch(`${BASE_URL}/pos/collocation/${id}`, data, {
    headers: { Authorization: token }
  });
};

export const conceptPosContent = (pos, type, offset, token) => {
  return axios.get(
    `${BASE_URL}/posContent/concept?conceptName=${pos}&type=${type}&offset=${offset}`,
    {
      headers: { Authorization: token }
    }
  );
};

export const getRelatedConcepts = (concept, token) => {
  return axios.get(`${BASE_URL}/concepts/relatedConcept/${concept}`, {
    headers: { Authorization: token }
  });
};

export const posContent = (pos, type, token) => {
  return axios.get(`${BASE_URL}/posContent?posName=${pos}&type=${type}`, {
    headers: { Authorization: token }
  });
};

export const deletePosContent = (id, token) => {
  return axios.delete(`${BASE_URL}/posContent/${id}`, {
    headers: { Authorization: token }
  });
};

export const updatePosContent = (id, data, token) => {
  return axios.patch(`${BASE_URL}/posContent/${id}`, data, {
    headers: { Authorization: token }
  });
};

export const createPosContent = (data, token) => {
  return axios.post(`${BASE_URL}/posContent`, data, {
    headers: { Authorization: token }
  });
};
