import React from 'react'
import loading from '../../../images/loading.gif';

import './perspectiveLoading.css'

export default function PerspectiveLoading(props) {
    return (
        <div style={{ height: 20 }}>
            <div id = "perspective-loading-wrapper">
                <img 
                    src={loading} 
                    style={{ display: [props.perspectiveLoading ? 'block' : 'none'] }} 
                    alt="loading" 
                />
            </div>
        </div>
    )
}
