import React from 'react'
import { LIGHT_BLUE } from '../../../../constants/colors'

const Fact = props => {
    const { data } = props
    return (
        <div style={styles.wrapper}>
            <span style={styles.text} onClick={props.onFactClick}>
                { data } Facts
            </span>
        </div>
    )
}

export default Fact

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent:'flex-end'
    },
    text: {
        color: LIGHT_BLUE,
        fontWeight: 'bold',
        fontSize: 13
    }
}