import React from 'react'
import SocialLogin from './SocialLogin'
import archiveImage from '../../../../images/imageIcons/archive.png'
import messageImage from '../../../../images/imageIcons/comment.png'
import LoginForm from './LoginForm'
import { SITE_TITLE } from '../../../../constants/global'

const TooltipForm = props => {
    const {userExist_admin, loginRequestStatus_admin, isAuthenticated, invalidLogin, user, auth} = props
    
    return (
        <div onMouseLeave={props.onMouseLeave} >
            <h4 style={styles.text}>Log in to Continue</h4>
            <div>
            <div id ="login_body_archive">
                <LoginMessage />
                <SocialLogin 
                    socialLogin = {props.socialLogin}
                />
                <LoginForm 
                    switchModal={props.switchModal}
                    userExist_admin = {userExist_admin}
                    loginRequestStatus_admin = {loginRequestStatus_admin}
                    isAuthenticated = {isAuthenticated}
                    invalidLogin = {invalidLogin}
                    user = {user}
                    auth = {auth}
                    login = {props.login}
                />
            </div>
            </div>
        </div>
    )
}

const styles = {
    text: { textAlign: "center" }
}

export default TooltipForm



const LoginMessage = () => (
    <div id="loginMessage">
        <ul>
            <li>
                <h3>Sign-in</h3>
                <ul>
                    <li>Comment <img className="icon px-2" id={"text"} alt={`${SITE_TITLE} message`} src={messageImage}  /></li>
                    <li>Create Custom dictionary <img className="icon px-2" id={"text"} alt={`${SITE_TITLE} archive`} src={archiveImage}  /></li>
                </ul>
            </li>
        </ul>
    </div>
)