import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Col } from "reactstrap";
import * as scroll from "../../../helpers/scrolling";
import AuthorsSidebar from "../../utills/AuthorsSidebar/AuthorsSidebar";
import { getAuthorItem } from "../../../helpers/authorHelpers";
import {
  setConceptSorting,
  toggleConceptPagination,
  setFactsKeywrodFilterId
} from "../../../actions/conceptsActions";
import ByThisThinker from "../../Concept/cols/ByThisThinker";

import { setSubFilter, removeSubFilter } from "../../../actions/navbaractions";
import MemesButton from "../../utills/Header/memeButton";
import FactsButton from "../../utills/Header/FactsButton";
import {
  authorCategory,
  booksCategory,
  factCategory,
  groupsCategory,
  groupsServerCategory
} from "../../../constants/global";
import { refreshFacebook } from "../../../helpers/facebook";
import { SORT_BY_AUTHOR } from "../../../constants/concepts";
import PageShare from "../../utills/FacebookComment/PageShare";
import EssentialBooks from "../../utills/BookCards/EssentialBooks";
import history from "../../../router/history";
import Drawer from "../../utills/drawer/drawer";
import * as mobile from "../../../actions/mobile";
import { Link } from "react-router-dom";
import { BLACK, LIGHT_GREEN, LIGHT_PINK, LIST_PINK } from "../../../constants/colors";
// import BooksCards from "../../utills/BookCards/BooksCards";
import AuthorBooks from "../../Author/cols/authorBooks";
import AuthorCards from "../../Author/cols/authorCards";
import { listenToDocumentSizeChangeOnPercepectiveClick } from "../../../helpers/navbar";

const ThirdCol = props => {
  const {
    authors,
    subFilter,
    selectedOption,
    factsCount,
    memesCount,
    relatedGroup,
    authorDrawer,
    user,
    relatedAuthors
  } = props;
  //  const [pathname, setPathname] = useState("");

  const currentAuthor = id => {
    return authors.find(e => e.id === Number(id));
  };

  const authorCallBack = (author, index) => {
    history.push(`/dictionary/${selectedOption.slug}/${author.slug}`);
  };
  // window.onpopstate = e => {
  //   if (window.location && window.location.href.split("=")[1]) {
  //     const author = currentAuthor(window.location.href.split("=")[1]);
  //     if (author) authorCallBack(author, 1);
  //   }
  // };

  // const authorCallBack = (author, index) => {
  //   listenToDocumentSizeChangeOnPercepectiveClick();
  //   const inputValue = getAuthorItem(author);
  //   inputValue.type = inputValue.category;
  //   props.setSubFilter(inputValue);
  //   props.perspectivesByFilter(inputValue);
  // };

  const { authorGroup, booksCount, value } = selectedOption;

  const booksClick = () => {
    history.push(`/books/${authorGroup.id}`);
  };
  const onGroupClick = () => {
    history.push(`/Author-Groups/${relatedGroup.slug}`);
  };
  const aboutThinker = () => {
    history.push(`/Authors/${selectedOption.slug}`);
  };

  return (
    <>
      <Drawer
        open={authorDrawer}
        handleClose={() => props.setAuthorDrawerOpen(false)}
        direction="right"
      >
        <AuthorsSidebar authors={authors} onAuthorClick={authorCallBack} opacity={false} />
      </Drawer>
      <Col
        xs={10}
        sm={12}
        md={2}
        className="d-none d-lg-block pt-5"
        onScroll={scroll.handleThirdWheel}
        id={scroll.thirdColId}
        style={{ marginLeft: "10%" , boxShadow: subFilter ? "16px 2px 21px -14px rgba(0,0,0,0.75)" : "none"}}
      >
        <AuthorBooks conceptMode={true} />
        <AuthorCards
          authors={relatedAuthors}
          handleAuthorClick={author =>
            history.push(`/${groupsServerCategory}/${selectedOption.slug}`)
          }
          detailMode={true}
          currentAuthor={{}}
        />
        {/* {selectedOption.author &&
          // <div style={{ marginBottom: "5%" }}>
          //   <ByThisThinker onClick={aboutThinker} />
          // </div>
        } */}

        <div id="thirdInnerScr">
          {authorGroup && booksCount > 0 && <EssentialBooks onClick={booksClick} />}
          <AuthorsSidebar authors={authors} onAuthorClick={authorCallBack} opacity={false} />
        </div>
      </Col>
    </>
  );
};

const mapStateToProps = state => ({
  relatedGroup: state.concepts.relatedGroup,
  selectedOption: state.navbar.selectedOption,
  user: state.auth.user,
  detail: state.author.authorDetail,
  subFilter: state.navbar.subFilter,
  authors: state.navbar.authors,
  factsCount: state.concepts.factsCount,
  authorDrawer: state.mobile.authorDrawer,
  booksCollection: state.navbar.collection,
  authorDrawer: state.mobile.authorDrawer,
  relatedAuthors: state.author.relatedAuthors
});

const mapDispatchToProps = dispatch => {
  return {
    setSubFilter: bindActionCreators(setSubFilter, dispatch),
    removeSubFilter: bindActionCreators(removeSubFilter, dispatch),
    setConceptSorting: bindActionCreators(setConceptSorting, dispatch),
    removeSubFilter: bindActionCreators(removeSubFilter, dispatch),
    toggleConceptPagination: bindActionCreators(toggleConceptPagination, dispatch),
    setFactsKeywrodFilterId: bindActionCreators(setFactsKeywrodFilterId, dispatch),
    setAuthorDrawerOpen: bindActionCreators(mobile.setAuthorDrawerOpen, dispatch)
  };
};
const styles = {
  link: {
    display: "block",
    fontSize: 14,
    width: "86%",
    borderRadius: 10
  },
  concept: {
    border: `1px solid ${LIST_PINK}`,
    color: LIST_PINK
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(ThirdCol);
