import isEmpty from "lodash/isEmpty";

import * as navbarConstants from "../constants/navbar";

const initialState = {
  isAuthenticated: false,
  user: { favoriteMemes: [], favoriteSimiles: [], unfavoriteSimiles: [] },
  loginRequestStatus_admin: true,
  invalidLogin: false,
  userExist_admin: false,
  SIGNUP_SUCCESS: false,
  signUpError: null,
  favouriteConcepts: [],
  favouriteAuthors: [],
  feedMode: false,
  accountVerifiedMessage: "",
  accountVerificationFail: "",
  checkAccountVerification: false,
  defaultArchive: null,
  focusedCategory: "All"
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "SET_FAILED_MESSAGE":
      return {
        ...state,
        accountVerificationFail: action.payload,
        checkAccountVerification: false
      };

    case "SET_ACCOUNTVERIFIED_MESSAGE":
      return {
        ...state,
        accountVerifiedMessage: action.payload,
        checkAccountVerification: true
      };
    case "SET_CURRENT_USER":
      return {
        isAuthenticated: !isEmpty(action.user),
        user: action.user
      };
    case navbarConstants.UPDATE_USER:
      return {
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case "CLOSE_LOGIN":
      return {
        ...state,
        invalidLogin: false,
        SIGNUP_SUCCESS: false
      };
    case "LOG_OUT":
      return {
        isAuthenticated: false,
        user: {},
        SIGNUP_SUCCESS: false
      };

    case "LOGIN_REQUEST":
      return {
        ...state,
        loginRequestStatus_admin: false,
        invalidLogin: false,
        isAuthenticated: false
      };

    case "LOGIN_SUCCESS":
      return {
        ...state,
        userExist_admin: true,
        user: action.userInfo,
        loginRequestStatus_admin: true,
        isAuthenticated: true,
        invalidLogin: false
      };

    case "LOGIN_FAILURE":
      return {
        ...state,
        loginRequestStatus_admin: true,
        isAuthenticated: false,
        invalidLogin: true
      };

    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        loginRequestStatus_admin: true,
        userExist_admin: false,
        feedMode: false
      };
    case "SIGNUP_REQUEST":
      return {
        ...state,
        loginRequestStatus_admin: false,
        signUpError: null
      };

    case "SIGNUP_SUCCESS":
      return {
        ...state,
        SIGNUP_SUCCESS: true,
        loginRequestStatus_admin: true,
        signUpError: null
      };

    case "SIGNUP_FAILED":
      return {
        ...state,
        SIGNUP_SUCCESS: false,
        loginRequestStatus_admin: true,
        signUpError: action.payload
      };

    case navbarConstants.ADD_FAVOURITE_CONCEPT:
      return {
        ...state,
        favouriteConcepts: [...action.payload]
      };

    case navbarConstants.ADD_FAVOURITE_AUTHOR:
      return {
        ...state,
        favouriteAuthors: [...action.payload]
      };

    case navbarConstants.UPDATE_FAVORITE_MEMES:
      let { user } = state;
      user.favoriteMemes = action.payload;
      return {
        ...state,
        user
      };
    case navbarConstants.EMPTY_FAVOURITES:
      return {
        ...state,
        favouriteAuthors: [],
        favouriteConcepts: []
      };
    case navbarConstants.FEED_MODE:
      return {
        ...state,
        feedMode: action.payload
      };
    case navbarConstants.SET_DEFAULT_ARCHIVE:
      return {
        ...state,
        defaultArchive: action.payload
      };
    case navbarConstants.EMAIL_NOT_VERIFIED_ERROR: {
      return {
        ...state,
        loginRequestStatus_admin: true,
        isAuthenticated: false
      };
    }

    // toogle category reducer

    case "TOOGLE_CATEGORY": {
      return { ...state, focusedCategory: action.payload };
    }

    default:
      return state;
  }
};
