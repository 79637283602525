import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Input, Button } from "reactstrap";
import * as navbarActions from "../../../actions/navbaractions";
import { DARK_GREY, LIGHT_BLUE } from "../../../constants/colors";
import { getRelatedConcepts } from "../../../selectors";
import { mapArrayToColumns } from "../../../helpers/conceptHelpers";
import CitationForm from "./citationForm";
import { Alert } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";

const RelatedConceptsList = props => {
  const { relatedConcepts, selectedOption, isAuth } = props;
  const [open, setOpen] = useState(false);
  const [checkCitation, setCheckCitation] = useState(false);
  const [textButtonColSize, setTextButtonColSize] = useState(8);
  const [textFieldSize, setTextFieldSize] = useState(12);
  const [myOwn, setMyOwn] = useState(false);
  const [errorRecaptcha, setErrorRecaptcha] = useState("");
  const [checkRecaptcha, setCheckRecaptcha] = useState(false);
  const [data, setData] = useState({
    definition: "",
    authorLastName: "",
    term: "",
    original: "",
    citation: "",
    pronoun: ""
  });
  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const isKeyPressed = () => {
    setTextButtonColSize(12);
    setTextFieldSize(12);
    setOpen(true);
  };
  const callCheckCitation = () => {
    setCheckCitation(true);
  };
  const reset = () => {
    setOpen(false);
    setCheckCitation(false);
    setTextButtonColSize(2);
    setTextFieldSize(8);
    setErrorRecaptcha("");
  };
  const recaptchaOnChange = () => {
    setCheckRecaptcha(true);
  };
  const handleSubmit = () => {
    if (!isAuth) {
      props.setModelOpen(true);
    } else if (checkRecaptcha == false) {
      setErrorRecaptcha("Please Fill the Recaptcha");
    } else {
      const { pronoun, definition, authorLastName, original, citation } = data;
      if (definition) {
        props.handlePerspectiveSubmit(
          definition,
          myOwn,
          pronoun,
          authorLastName,
          original,
          citation,
          selectedOption.value
        );
        setErrorRecaptcha("");
        setData({});
        setOpen(false);
        setCheckCitation(false);
        setTextButtonColSize(2);
        setTextFieldSize(8);
      } else {
        setErrorRecaptcha("Definition is required");
      }
    }
  };

  const disabled = data.definition ? false : true;

  return (
    <>
      <div style={styles.wrapper}>
        <Row>
          <Col style={open ? styles.title : { paddingRight: 5 }}>
            {errorRecaptcha && <Alert color="danger">{errorRecaptcha}</Alert>}
            <div style={{ display: "flex", justifyContent: "center" }}>
              {open && (
                <Input
                  type="text"
                  placeholder="pronoun"
                  name="pronoun"
                  value={data.pronoun}
                  onChange={handleChange}
                  style={{ maxWidth: "20%" }}
                />
              )}
              <center>{selectedOption.value + " is..."}</center>
            </div>
          </Col>
          <Col xs={textFieldSize} style={{ paddingLeft: 5 }}>
            <Input
              type="textarea"
              placeholder={open ? "enter here ...." : "contribute here"}
              onKeyPress={() => isKeyPressed()}
              style={styles.getTextAreaStyles(open)}
              row="5"
              name="definition"
              onChange={handleChange}
              value={data.definition}
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <Row>
              {open && checkCitation === false ? (
                <>
                  <Col style={{ marginLeft: "4%" }}>
                    <Input type="checkbox" onChange={() => setMyOwn(!myOwn)} checked={myOwn} />
                    original/overheard
                  </Col>
                  <Col>
                    <Input type="checkbox" onChange={() => callCheckCitation()} />I have a citation
                  </Col>
                </>
              ) : checkCitation ? (
                <CitationForm handleChange={handleChange} />
              ) : (
                ""
              )}
            </Row>

            {open ? (
              <Col>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_V2_KEY}
                  onChange={recaptchaOnChange}
                />
                <Button
                  color="primary"
                  disabled={disabled}
                  onClick={handleSubmit}
                  style={
                    open
                      ? { marginTop: "4%", float: "right" }
                      : {
                          float: "left"
                        }
                  }
                >
                  submit
                </Button>
                <Button
                  color="primary"
                  onClick={() => reset()}
                  style={
                    open
                      ? { margin: "4%", float: "right" }
                      : {
                          float: "right"
                        }
                  }
                >
                  reset
                </Button>
              </Col>
            ) : (
              <>
                <Button
                  color="primary"
                  style={{
                    float: "right"
                  }}
                  onClick={handleSubmit}
                >
                  submit
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  relatedConcepts: mapArrayToColumns(getRelatedConcepts(state)),
  selectedOption: state.navbar.selectedOption,
  isAuth: state.auth.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
  setSelectedItem: bindActionCreators(navbarActions.setSelectedItem, dispatch),
  handlePerspectiveSubmit: bindActionCreators(
    navbarActions.handlePerspectiveFromBottomSubmit,
    dispatch
  ),
  setModelOpen: bindActionCreators(navbarActions.setModelOpen, dispatch)
});

const styles = {
  wrapper: {
    backgroundColor: DARK_GREY,
    padding: 15,
    marginTop: 40
  },
  getTextAreaStyles: open => {
    return {
      width: "100%",
      textAlign: "center",
      fontStyle: "italic",
      borderRadius: "0.5em",
      height: open ? "100%" : "60%",
      transition: "height 2s"
    };
  },

  title: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: "2%",
    paddingRight: 5
  },
  item: {
    fontWeight: 700,
    cursor: "pointer"
  },
  cluster: {
    fontSize: 18,
    color: LIGHT_BLUE,
    cursor: "pointer"
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(RelatedConceptsList));
