import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Button, Input } from "reactstrap";
import Search from "../../TopNavBar/search/search";
import { getAuthorItem } from "../../../helpers/authorHelpers";
import { getConceptItem } from "../../../helpers/conceptHelpers";
import { PERSPECTIVE_EDIT_TYPE, PERSPECTIVE_NEW_TYPE } from "../../../constants/global";
import Loader from "react-loader-spinner";

import ConceptModalForm from "./conceptModalForm";
import AuthorModalForm from "./authorModalForm";

const PerspectiveForm = props => {
  const [myPerspective, setMyPerspective] = useState({});
  const [myConcept, setMyConcept] = useState({});
  const [myAuthor, setMyAuthor] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState(null);
  const [isAnonymous, setAnonymous] = useState(false);
  const [authorOpen, setAuthorOpen] = useState(false);
  const [conceptOpen, setConceptOpen] = useState(false);
  // const [isFact , setFact] = useState(false);
  const citationRef = useRef();
  const longRef = useRef();
  const definitionRef = useRef();

  const {
    perspective,
    nature,
    conceptLoading,
    conceptError,
    authorError,
    authorLoading,
    user,
    perspectiveLoading = false,
    relatedConcepts = [],
    getRelatedConcepts = () => {},
    conceptType = "Concept",
    active,
    setActive = () => {},
    open,
    setOpen = () => {},
    openAuthor,
    setOpenAuthor = () => {}
  } = props;

  useEffect(() => {
    setIsEdit(nature === PERSPECTIVE_EDIT_TYPE);
  }, [nature]);

  useEffect(() => {
    if (nature === PERSPECTIVE_EDIT_TYPE) {
      setMyPerspective(perspective);
      setMyAuthor(getAuthorItem(perspective.Author));
      setMyConcept(getConceptItem(perspective.Concept));
    }
  }, [perspective, nature]);

  useEffect(() => {
    if (nature === PERSPECTIVE_NEW_TYPE) {
      setMyAuthor({});
      setMyConcept({});
      setMyPerspective({});
      if (citationRef.current) {
        citationRef.current.value = "";
      }
      if (longRef.current) {
        longRef.current.value = "";
      }
      if (definitionRef.current) {
        definitionRef.current.value = "";
      }
    }
  }, [nature]);

  const handleChange = e => {
    setMyPerspective({
      ...myPerspective,
      [e.target.name]: e.target.value
    });
  };

  const toggleAnony = () => {
    setAnonymous(!isAnonymous);
  };
  const toggleFact = () => {
    setMyPerspective({
      ...myPerspective,
      isFact: !myPerspective.isFact
    });
  };

  const handleUpdate = () => {
    props.handlePerspectiveUpdate(
      myPerspective.id,
      myPerspective.pronoun,
      myConcept,
      myAuthor,
      myPerspective.description,
      myPerspective.longDescription,
      myPerspective.citation,
      myPerspective.isFact
    );
  };

  const handleAuthorChange = inputValue => {
    setMyAuthor(inputValue);
  };

  const handleConceptChange = inputValue => {
    setMyConcept(inputValue);
  };

  const handleInsert = () => {
    if (!myAuthor.id && isAnonymous === false) {
      setError("Please select an Author");
    } else if (!myConcept.id) {
      setError("Please select a Concept");
    } else if (!myPerspective.description) {
      setError("Perspective description is required");
    } else {
      props.handlePerspectiveSubmit(
        myAuthor,
        myPerspective.description,
        myPerspective.pronoun,
        false,
        myAuthor.value,
        myPerspective.original,
        myPerspective.citation,
        myConcept.value,
        myPerspective.isFact
      );
      setMyAuthor({});
      setMyConcept({});
      setMyPerspective({ pronoun: "" });
      setError(null);

      if (citationRef.current) {
        citationRef.current.value = "";
      }
      if (longRef.current) {
        longRef.current.value = "";
      }
      if (definitionRef.current) {
        definitionRef.current.value = "";
      }
    }
    setActive(false);
  };

  const toggleAuthorModal = () => {
    setAuthorOpen(!authorOpen);
  };

  const toggleConceptModal = () => {
    setConceptOpen(!conceptOpen);
  };

  const handleConceptSubmit = (name, pronoun, type, relatedConcepts) => {
    props.handleConceptSubmit(name, pronoun, type, relatedConcepts);
  };

  const handleAuthorSubmit = (firstName, lastName) => {
    props.handleAuthorSubmit(firstName, lastName);
  };

  return (
    <div id="perspective-edit-wrapper" className="perspective-edit-wrapper container">
      <Row className="prospective-edit-row">
        <Col xs={3}>Pronoun</Col>
        <Col xs={9} style={styles.concepts}>
          <Input name="pronoun" onChange={handleChange} value={myPerspective.pronoun} />
        </Col>
      </Row>
      <Row className="prospective-edit-row">
        <Col xs={3}>Concepts</Col>
        <Col xs={isEdit ? 9 : 7} style={styles.concepts}>
          <Search
            category="Concept"
            perspectiveCount="0"
            value={myConcept}
            onChange={handleConceptChange}
            placeholder="Select Concept"
            perspectiveCount="0"
          />
        </Col>
        {conceptType !== "unverifiedConcepts" ? (
          !isEdit && (
            <Col xs={1} style={styles.concepts} onClick={toggleConceptModal}>
              <ConceptModalForm
                open={open}
                setOpen={setOpen}
                loading={conceptLoading}
                onConceptSubmit={handleConceptSubmit}
                getRelatedConcepts={getRelatedConcepts}
                error={conceptError}
                user={user}
                relatedConcepts={relatedConcepts}
              />
            </Col>
          )
        ) : (
          <Col xs={1} style={styles.concepts} />
        )}
      </Row>
      <Row className="prospective-edit-row">
        {!isAnonymous && (
          <>
            <Col xs={3}>Authors</Col>
            <Col xs={isEdit ? 9 : 7}>
              <Search category={"Author"} value={myAuthor} onChange={handleAuthorChange} />
            </Col>
            {!isEdit && (
              <Col xs={1} style={styles.concepts} onClick={toggleAuthorModal}>
                <AuthorModalForm
                  openAuthor={openAuthor}
                  setOpenAuthor={setOpenAuthor}
                  loading={authorLoading}
                  error={authorError}
                  onAuthorSubmit={handleAuthorSubmit}
                />
              </Col>
            )}
          </>
        )}
      </Row>
      <Row>
        <Col xs={3}>fact</Col>
        <Col xs={2}>
          <Input type="checkbox" checked={myPerspective.isFact} onChange={toggleFact} />
        </Col>
        <Col xs={4}>Anonymous</Col>
        <Col xs={2}>
          <Input
            type="checkbox"
            name="isAnonymous"
            checked={isAnonymous}
            onChange={toggleAnony}
            style={{ marginLeft: "30px" }}
          />
        </Col>
      </Row>
      <Row className="prospective-edit-row">
        <Col xs={3}>Definition</Col>
        <Col xs={9}>
          <textarea
            ref={definitionRef}
            rows={5}
            name="description"
            onChange={handleChange}
            value={myPerspective.description}
          ></textarea>
        </Col>
      </Row>
      <Row className="prospective-edit-row">
        <Col xs={3}>Context</Col>
        <Col xs={9}>
          <textarea
            ref={longRef}
            rows={5}
            name="longDescription"
            onChange={handleChange}
            value={myPerspective.longDescription}
          ></textarea>
        </Col>
      </Row>
      <Row className="prospective-edit-row">
        <Col xs={3}>Citation</Col>
        <Col xs={9}>
          <textarea
            ref={citationRef}
            rows={5}
            name="citation"
            onChange={handleChange}
            value={myPerspective.citation}
          ></textarea>
        </Col>
      </Row>
      <Row className="prospective-edit-row">
        {alert && (
          <Col xs={12} className="text-danger text-center">
            {error}
          </Col>
        )}
        <Col xs={12} className="d-flex flex-row-reverse">
          {isEdit ? (
            <Button type="button" onClick={handleUpdate} color="primary">
              update
            </Button>
          ) : (
            <Button type="button" onClick={handleInsert} color="primary">
              {perspectiveLoading ? (
                <Loader type="Oval" color={"white"} height="30" width="30" />
              ) : (
                "Add"
              )}
            </Button>
          )}
          <Button type="button" className="mr-2" onClick={() => setActive(false)} color="secondary">
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

PerspectiveForm.propTypes = {
  // perspective: PropTypes.object.isRequired,
  nature: PropTypes.oneOf([PERSPECTIVE_EDIT_TYPE, PERSPECTIVE_NEW_TYPE]).isRequired,
  handlePerspectiveUpdate: PropTypes.func,
  handlePerspectiveSubmit: PropTypes.func,
  handleConceptSubmit: PropTypes.func,
  handleAuthorSubmit: PropTypes.func,
  conceptLoading: PropTypes.bool,
  // conceptError: PropTypes.bool,
  authorLoading: PropTypes.bool
  // authorError: PropTypes.bool
};

export default PerspectiveForm;

const styles = {
  concepts: {
    zIndex: 6,
    padding: 0
  },
  addIcon: {
    width: 25
  }
};
