import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ShareButtons from "../../../SocialShareButtons/ShareButtons";
import shareIcon from "../../../../images/imageIcons/share2.png";
import options from "../../../../images/imageIcons/options.png";
import commentImage from "../../../../images/imageIcons/comment.png";
import Archive from "./ArchiveButton";
import Comment from "../PerspectiveComment/Comment";
import { connect } from "react-redux";
import { likePerspective } from "../../../../actions/navbaractions";
import history from "../../../../router/history";
import * as constants from "../../../../constants/navbar";
import "./ToolButtons.css";
import { authorPicLink } from "../../../../helpers/authorHelpers";
const ToolButtons = props => {
  const [share, setShare] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [description, setDescription] = useState(false);
  const [perspectiveId, setPerspectiveId] = useState(null);

  const { perspective, index, author, label, archiveMode, isSinglePerspective } = props;

  const onLikeClick = async () => {
    let like = {};
    if (!perspective.isLike) {
      like.type = "increase";
      like.id = perspective.id;
      perspective.isLike = true;
      perspective.loves += 1;
    } else {
      like = {};
      like.type = "decrease";
      like.id = perspective.id;
      perspective.isLike = false;
      perspective.loves -= 1;
    }
    props.onLikeClick(perspective, perspective.index);
    const response = likePerspective(like);
  };

  useEffect(() => {
    setDescription(false);
  }, [perspective]);

  const commentClick = () => {
    // old
    // setShowComment(!showComment)
    history.push(`/dictionary/${perspective.Concept.slug}?author=${author.id}`);
  };
  return (
    <div>
      <div onMouseLeave={() => setShare(false)}>
        <div id="toolButtons" style={{ marginRight: 15 }}>
          <Archive index={index} perspective={perspective} archivePage={archiveMode} />
          {/* {!isSinglePerspective && (
            <a
              href={`/authors/${props.author.slug}?Concept=${perspective.ConceptId}`}
              onClick={e => {
                e.preventDefault();
              }}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <img
                alt="fb"
                className="icon px-2 addedToArchiver"
                id={"text" + index}
                src={commentImage}
                onClick={commentClick}
              />
            </a>
          )} */}
          {/* <img
            alt="option"
            className="icon px-2"
            src={options}
            onClick={() => setDescription(!description)}
          /> */}
          <div id="share-wrapper" onMouseLeave={() => setShare(false)}>
            {/* <img
                                alt="share"
                                className="icon px-2"
                                src={shareIcon}
                                onClick={() => setShare(!share)}
                                style={{ marginTop: -8}}
                            /> */}
            {/* {share && <ShareButtons
                                perspective={perspective}
                                title={label}
                                authorName={author.firstName + " " + author.lastName}
                            />} */}
          </div>
          <i
            style={{
              color: perspective.isLike ? "red" : "rgb(192,192,192)",
              marginTop: 0,
              marginLeft: 5
            }}
            className="fa fa-heart-o heartIcon "
            aria-hidden="true"
            onClick={onLikeClick}
          />
        </div>
      </div>
      {/* <div
        id={perspective.id}
        className="description"
        style={{ display: description ? "block" : "none" }}
      >
        {perspective.longDescription}
        <div id="citation">{perspective.citation}</div>
      </div> */}
      {showComment && (
        <div id="comment-wrapper">
          <Comment id={perspective.id} />
        </div>
      )}
    </div>
  );
};
ToolButtons.prototype = {
  perspective: PropTypes.object.isRequired,
  author: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired, // Index of perspective in array to show tooltip at that index
  label: PropTypes.number.isRequired // Label title to show in the share Twitter/ Facebook
};
const mapStateToProps = state => {
  return {
    archives: state.archives,
    navbar: state.navbar,
    concepts: state.concepts,
    archiveMode: state.navbar.mode === constants.ARCHIVE_MODE
  };
};

export default connect(mapStateToProps, null)(React.memo(ToolButtons));
