import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  SITE_TITLE_CAPITAL,
  authorCategory,
  conceptCategory,
  clusterCategory,
  listCategory,
  groupsCategory,
  booksCategory,
  singleBookCategory
} from "../../constants/global";
import { isSimilarAuthorPage } from "../../helpers/authorGroups";
import { isConceptAuthorRouteOpen, getConceptAndAuthorName } from "../../helpers/common";
import SeoTag from "../utills/SeoTag";
class Title extends Component {
  crateMetaTags = (selectedValue, perspectives) => {
    let description = "";
    let desc = "";
    const length = perspectives.length;
    if (length > 1) {
      perspectives.slice(1, 10).forEach(content => {
        const length = content.description.length;
        if (length > desc.length) {
          desc = content.description;
        }
      });
      description = `${selectedValue} is ${desc} `;
      description = description + `as well as ${perspectives[0].description}`;
    } else {
      description = `${selectedValue} is ${perspectives[0].description}`;
    }
    return description;
  };

  getTitle() {
    let title = "";
    let description = "";
    let { selectedOption, concepts, perspectives, authors } = this.props.navbar;
    // if (window.location.pathname === "/") {
    //   title = SITE_TITLE_CAPITAL;
    // } else
    if (selectedOption && selectedOption.value) {
      if (isSimilarAuthorPage()) {
        title = `Authors Similar to ${selectedOption.value} | ${SITE_TITLE_CAPITAL}`;

        description = authors.map(x => `${x.firstName} ${x.lastName}`).join(" . ");
      } else {
        if (selectedOption.category === authorCategory) {
          title = `${selectedOption.value} iss Dictionary | ${SITE_TITLE_CAPITAL}`;
          description = concepts.map(x => x.name).join(" . ");
        }
        if (selectedOption.category === booksCategory) {
          let categoryTitle = selectedOption.value.replace(/\b\w/g, l => l.toUpperCase());
          title = `Essential ${categoryTitle} Books | Dictionary`;
        }
        if (selectedOption.category === singleBookCategory) {
          let categoryTitle = selectedOption.value.replace(/\b\w/g, l => l.toUpperCase());
          title = `${categoryTitle} Dictionary | ${SITE_TITLE_CAPITAL}`;
        }
        if (selectedOption.category === groupsCategory) {
          title = `Some Important ${selectedOption.value} Thinkers | ${SITE_TITLE_CAPITAL}`;
          description = authors.map(x => `${x.firstName} ${x.lastName}`).join(" . ");
        }
        if (
          selectedOption.category === clusterCategory ||
          selectedOption.category === listCategory
        ) {
          title = `${selectedOption.value} Dictionary | ${SITE_TITLE_CAPITAL}`;
          description = concepts.map(x => x.name).join(" . ");
        }
        if (selectedOption.category === conceptCategory) {
          const { relatedAuthor } = this.props;
          if (relatedAuthor) {
            title = `Who is ${selectedOption.value}? | ${SITE_TITLE_CAPITAL}`;
            let temp = perspectives[0];
            if (temp) description = `${selectedOption.value} is ${temp.description}`;
          } else if (isConceptAuthorRouteOpen()) {
            const { authorName } = getConceptAndAuthorName();
            let pronoun = "";
            let temp = perspectives[0];
            if (temp) {
              pronoun = temp.pronoun ? temp.pronoun : "";
              let categoryTitle = selectedOption.value.replace(/\b\w/g, l => l.toUpperCase());
              description = `${authorName} said that ${this.crateMetaTags(
                selectedOption.value,
                perspectives
              )}`;
            }
            let categoryTitle = selectedOption.value.replace(/\b\w/g, l => l.toUpperCase());
            title = `Perspectives of ${categoryTitle} by ${authorName}  |   ${SITE_TITLE_CAPITAL}`;
          } else {
            let pronoun = "";
            let temp = perspectives[0];
            if (temp) {
              pronoun = temp.pronoun ? temp.pronoun : "";
              description = this.crateMetaTags(selectedOption.value, perspectives);
            }
            let categoryTitle = selectedOption.value.replace(/\b\w/g, l => l.toUpperCase());
            title = `What is ${
              selectedOption.pronoun ? selectedOption.pronoun : ""
            } ${categoryTitle}?   |   ${SITE_TITLE_CAPITAL}`;
          }
        }
      }
    }
    return { title, description };
  }
  render() {
    const { title, description } = this.getTitle();
    return <SeoTag title={title} description={description} />;
  }
}
const mapStateToProps = state => {
  return {
    navbar: state.navbar,
    relatedAuthor: state.concepts.relatedAuthor
  };
};
export default connect(mapStateToProps, null)(Title);
