import * as services from "../services/books";
import * as constants from "../constants/navbar";
import * as global from "../constants/global";
import { getGroupItem } from "../helpers/authorGroups";
import axios from "axios";
import { sortAuthors, getAuthorItem } from "../helpers/authorHelpers";
import { AUTHOR_DETAIL } from "../constants/authors";
import { dataPagination } from "../helpers/navbar";

export function getAllCollections() {
  return async dispatch => {
    try {
      const response = await services.getAllCollections();
      if (response.status === 200) {
        dispatch({
          type: constants.SIMILAR_ALPHABETS,
          payload: response.data.data
        });
      }
    } catch (e) {
      console.log("error is", e);
    }
  };
}

export function getRelatedCollections(collectionId) {
  return async dispatch => {
    try {
      dispatch({
        type: constants.SIMILAR_BOOKS_COLLECTION,
        payload: []
      });
      dispatch({
        type: constants.SET_SELECTED_COLLECTION,
        payload: collectionId
      });
      const response = await services.getRelatedCollectionsById(collectionId);
      if (response.status === 200) {
        dispatch({
          type: constants.SIMILAR_BOOKS_COLLECTION,
          payload: response.data.data
        });
      }
    } catch (e) {
      console.log("error is", e);
    }
  };
}

export const getBooks = id => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.auth;
      dispatch({
        type: constants.BOOK_API,
        payload: true
      });
      let limit = 20;
      const response = await services.getBooksByCollection(id, limit, user.id);
      if (response.status === 200) {
        let { data, relatedGroups, relatedConcept, pronoun, books, authors } = response.data;
        authors = authors.filter((a, i) => authors.findIndex(t => t.id === a.id) === i);
        authors = sortAuthors(authors);
        dispatch({
          type: constants.AUTHORS,
          payload: authors
        });
        dispatch({
          type: constants.BOOKS,
          payload: sortBooksByRanking([...books])
        });
        dispatch({
          type: constants.SET_NEW_BIE,
          payload: false
        });

        dispatch({
          type: constants.SET_BOOKS_SORTING,
          payload: global.SORT_BY_RANKING
        });

        dispatch({
          type: constants.SIMILAR_ALPHABETS,
          payload: relatedGroups
        });

        if (relatedConcept) {
          dispatch({
            type: constants.SIMILAR_BOOKS_CONCPET,
            payload: relatedConcept
          });
        }

        const selectedOption = getGroupItem(data);
        selectedOption.category = global.booksCategory;
        selectedOption.pronoun = pronoun;
        dispatch({
          type: constants.SET_SELECTED_OPTION,
          payload: selectedOption
        });
        dispatch({
          type: constants.SET_BOOKS_AUTHOR_FILTER,
          payload: false
        });
        dispatch({
          type: constants.BOOK_API,
          payload: false
        });
      }
    } catch (e) {
      console.log("error is", e);
    }
  };
};

export const increaseUpVoteBook = (bookId, value) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { books } = state.books;
      const { selectedOption } = state.navbar;
      const { user } = state.auth;
      const index = books.findIndex(book => book.id === bookId);
      let book = books[index];
      let response = null;
      if (selectedOption.category === global.authorCategory) {
        response = await services.increaseAuthorUpVoteBook(
          bookId,
          selectedOption.id,
          value,
          user.id
        );
      } else {
        response = await services.increaseUpVoteBook(bookId, selectedOption.id, value, user.id);
      }
      if (response && response.status === 200) {
        book.upVotesCount = value;
        books[index] = { ...book };
        dispatch({
          type: constants.BOOKS,
          payload: [...books]
        });
      }
    } catch (error) {
      console.log("error ", error);
    }
  };
};

export const upVoteBook = (bookId, down = false) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { books } = state.books;
      const { selectedOption } = state.navbar;
      const { user } = state.auth;
      const index = books.findIndex(book => book.id === bookId);

      if (index > -1) {
        let book = books[index];
        if (down) {
          book.upVotesCount = book.upVotesCount - 1;
          book.upVoted = false;
        } else {
          book.upVotesCount = book.upVotesCount + 1;
          book.upVoted = true;
        }
        if (book.downVoted) {
          book.downVotesCount = book.downVotesCount - 1;
          book.downVoted = false;
        }
        books[index] = { ...book };

        let response;
        if (selectedOption.category === global.authorCategory) {
          response = await services.upVoteAuthorCollectionBook(
            bookId,
            down,
            selectedOption.id,
            user.id
          );
        } else {
          response = await services.upVoteBook(bookId, down, selectedOption.id, user.id);
        }
        if (response.status === 200) {
          dispatch({
            type: constants.BOOKS,
            payload: [...books]
          });
        }
      }
    } catch (e) {
      console.log("erro is ", e.message);
    }
  };
};
export const downVoteBook = (bookId, down = false) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { books } = state.books;
      const { selectedOption } = state.navbar;
      const { user } = state.auth;
      const index = books.findIndex(book => book.id === bookId);
      if (index > -1) {
        let book = books[index];
        if (down) {
          book.downVotesCount = book.downVotesCount - 1;
          book.downVoted = false;
        } else {
          book.downVotesCount = book.downVotesCount + 1;
          book.downVoted = true;
        }
        if (book.upVoted) {
          book.upVotesCount = book.upVotesCount - 1;
          book.upVoted = false;
        }
        books[index] = { ...book };
        let response;
        if (selectedOption.category === global.authorCategory) {
          response = await services.downVoteAuthorCollectionBook(
            bookId,
            down,
            selectedOption.id,
            user.id
          );
        } else {
          response = await services.downVoteBook(bookId, down, selectedOption.id, user.id);
        }
        if (response.status === 200) {
          dispatch({
            type: constants.BOOKS,
            payload: [...books]
          });
        }
      }
    } catch (e) {
      console.log("erro is ", e);
    }
  };
};

export const changeBooksSortToAuthor = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { authors } = state.navbar;
    let { books } = state.books;
    books = sortBooksByAuthors(books, authors);
    dispatch({
      type: constants.BOOKS,
      payload: [...books]
    });
    dispatch({
      type: constants.SET_BOOKS_SORTING,
      payload: global.SORT_BY_AUTHORS
    });
  };
};

export const changeBooksSortToRank = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { authors } = state.navbar;
    let { books } = state.books;
    books = sortBooksByRanking(books, authors);

    dispatch({
      type: constants.BOOKS,
      payload: [...books]
    });
    dispatch({
      type: constants.SET_BOOKS_SORTING,
      payload: global.SORT_BY_RANKING
    });
  };
};

export const changeBooksSortToDate = (descending = false) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedOption } = state.navbar;
    const { user } = state.auth;
    const response = await services.getBooksByCollection(selectedOption.id, null, user.id);
    if (response.status === 200) {
      let { books } = response.data;
      if (descending) {
        books = books.reverse();
        dispatch({
          type: constants.BOOKS_SORT_TYPE,
          payload: global.DESCEND_SORT
        });
      } else {
        dispatch({
          type: constants.BOOKS_SORT_TYPE,
          payload: global.ASCEND_SORT
        });
      }
      dispatch({
        type: constants.BOOKS,
        payload: response.data.books
      });
      dispatch({
        type: constants.SET_BOOKS_SORTING,
        payload: global.SORT_BY_DATE
      });
    }
  };
};

export const getBooksByAuthors = (authorId, page = false) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.auth;
      const response = await services.getBooksByAuthorId(authorId, user.id);
      if (response.status === 200) {
        const { books, author } = response.data;
        dispatch({
          type: constants.BOOKS,
          payload: sortBooksByRanking(books)
        });
        dispatch({
          type: constants.SET_BOOKS_AUTHOR_FILTER,
          payload: true
        });
        dispatch({
          type: constants.BOOK_API,
          payload: false
        });
        dispatch({
          type: AUTHOR_DETAIL,
          payload: author
        });

        if (page) {
          dispatch({
            type: constants.SET_SELECTED_OPTION,
            payload: getAuthorItem(author)
          });
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };
};

export const getBooksByAuthorsAndDate = (authorId, descending = false) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.auth;
      const response = await services.getBooksByAuthorId(authorId, user.id);
      if (response.status === 200) {
        let { books } = response.data;
        if (descending) {
          books = books.reverse();
          dispatch({
            type: constants.BOOKS_SORT_TYPE,
            payload: global.DESCEND_SORT
          });
        } else {
          dispatch({
            type: constants.BOOKS_SORT_TYPE,
            payload: global.ASCEND_SORT
          });
        }
        dispatch({
          type: constants.BOOKS,
          payload: books
        });
        dispatch({
          type: constants.SET_BOOKS_SORTING,
          payload: global.SORT_BY_DATE
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  };
};

export const getCollectionBooksByLimit = limit => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { selectedOption } = state.navbar;
      const { user } = state.auth;
      const response = await services.getBooksByCollection(selectedOption.id, limit, user.id);
      if (response.status === 200) {
        const { books } = response.data;
        dispatch({
          type: constants.BOOKS,
          payload: books
        });
        dispatch({
          type: constants.SET_BOOKS_SORTING,
          payload: global.SORT_BY_RANKING
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  };
};

export const getCollectionBooksByPagination = () => {
  return async (dispatch, getState) => {
    dataPagination();
    try {
      dispatch({
        type: constants.PERSPECTIVE_LOADING,
        payload: true
      });
      const state = getState();
      const { selectedOption } = state.navbar;
      const limit = state.books.limit;
      const offset = state.books.offset + limit;
      const oldBooks = state.books.books;
      const { user } = state.auth;
      const response = await services.getBooksByCollectionByPagination(
        selectedOption.id,
        limit,
        offset,
        user.id
      );
      const { books } = response.data;
      if (response.status === 200 && books.length !== 0) {
        dispatch({
          type: constants.BOOKS,
          payload: [...oldBooks, ...books]
        });
        dispatch({
          type: constants.SET_BOOKS_OFFSET,
          payload: offset
        });
      }
      dispatch({
        type: constants.PERSPECTIVE_LOADING,
        payload: false
      });
      dataPagination();
    } catch (e) {
      dispatch({
        type: constants.PERSPECTIVE_LOADING,
        payload: false
      });
      dataPagination();
      console.log("error", e);
    }
  };
};

export const likeBookDescription = (descriptionId, disLike) => {
  return async (dispatch, getState) => {
    try {
      const response = await services.likeBookDescription(descriptionId, disLike);
      if (response.status === 200) {
      }
    } catch (e) {
      console.log("error", e);
    }
  };
};

export const setBooksViewPort = bookId => {
  return dispatch => {
    dispatch({
      type: global.SET_VIEWPORT,
      payload: bookId
    });
  };
};

export const setNewBieMode = mode => {
  return dispatch => {
    dispatch({
      type: constants.SET_NEW_BIE,
      payload: mode
    });
  };
};

export const addRelatedCollection = relatedCollection => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let { alphabets } = state.books;
      const { selectedOption } = state.navbar;
      let exist = alphabets.find(collection => collection.id === relatedCollection.id);
      if (!exist) {
        const response = await services.addRelatedCollection(
          selectedOption.id,
          relatedCollection.id
        );
        if (response.status === 200) {
          const actual = { ...relatedCollection, name: relatedCollection.value };
          alphabets.push(actual);
          dispatch({
            type: constants.SIMILAR_ALPHABETS,
            payload: [...alphabets]
          });
        }
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };
};

export function addCollectionToBook(bookId, collection) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.auth;
      const { books } = state.books;
      const response = await services.addCollectionBook(bookId, collection.id);
      if (response.status === 200) {
        const index = books.findIndex(item => item.id === parseInt(bookId));
        if (index > -1) {
          let book = books[index];
          if (book) {
            const newcollection = {
              id: collection.id,
              name: collection.value
            };
            if (book.bookCollections) {
              book.bookCollections.push(newcollection);
            } else {
              book.bookCollections = [collection];
            }
            books[index] = book;
            dispatch({
              type: constants.BOOKS,
              payload: books
            });
            if (response.status === 204) {
              console.log("Book updated ");
            }
          }
        }
      }
    } catch (e) {
      console.log("errror is ", e);
    }
  };
}

export function removeCollectionFromBook(bookId, collection) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.auth;
      const { books } = state.books;
      const response = await services.removeCollectionBook(bookId, collection.id);
      if (response.status === 200) {
        const index = books.findIndex(item => item.id === parseInt(bookId));
        if (index > -1) {
          let book = books[index];
          if (book) {
            let bookCollection = book.bookCollections.filter(item => item.id != collection.id);
            book.bookCollections = bookCollection;
            books[index] = book;
            dispatch({
              type: constants.BOOKS,
              payload: books
            });
            if (response.status === 204) {
              console.log("Book updated ");
            }
          }
        }
      }
    } catch (e) {
      console.log("errror is ", e);
    }
  };
}

export const removeRelatedCollection = relatedCollection => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let { alphabets } = state.books;
      const { selectedOption } = state.navbar;
      let collections = alphabets.filter(collection => collection.id !== relatedCollection.id);
      const response = await services.removeRelatedCollection(
        selectedOption.id,
        relatedCollection.id
      );
      if (response.status === 200) {
        dispatch({
          type: constants.SIMILAR_ALPHABETS,
          payload: [...collections]
        });
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };
};

export const removeRelatedCollectionFromCollection = relatedCollection => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let { alphabets } = state.books;
      const { selectedOption } = state.navbar;
      let itemIndex = alphabets.findIndex(collection => collection.id === relatedCollection);
      alphabets[itemIndex].reciprocalExist = false;
      const response = await services.removeRelatedCollection(relatedCollection, selectedOption.id);
      if (response.status === 200) {
        dispatch({
          type: constants.SIMILAR_ALPHABETS,
          payload: alphabets
        });
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };
};
export const addRelatedCollectionToCollection = relatedCollection => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let { alphabets } = state.books;
      const { selectedOption } = state.navbar;
      let itemIndex = alphabets.findIndex(collection => collection.id === relatedCollection);
      alphabets[itemIndex].reciprocalExist = true;
      const response = await services.addRelatedCollection(relatedCollection, selectedOption.id);
      if (response.status == 200) {
        dispatch({
          type: constants.SIMILAR_ALPHABETS,
          payload: alphabets
        });
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };
};
export const addRelatedCollectionFromSearch = relatedCollection => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let { alphabets } = state.books;
      const { selectedOption } = state.navbar;
      let exist = alphabets.find(collection => collection.id === relatedCollection.id);
      if (!exist) {
        const response = await services.addRelatedCollectionFromSearch(
          relatedCollection.id,
          selectedOption.id
        );
        if (response.status === 200) {
          const actual = {
            ...relatedCollection,
            name: relatedCollection.value,
            reciprocalExist: true
          };
          alphabets.push(actual);
          dispatch({
            type: constants.SIMILAR_ALPHABETS,
            payload: [...alphabets]
          });
        }
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };
};
export const sortBooksByAuthors = (books, authors) => {
  let temp = [];
  authors.map(x => {
    books.forEach(book => {
      let index = book.Authors.findIndex(a => a.id === x.id);
      if (index > -1) {
        temp.push(book);
      }
    });
  });
  return temp;
};

export const sortBooksByRanking = books => {
  return books.sort((a, b) => b.upVotesCount - a.upVotesCount);
};
