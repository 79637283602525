import React from "react";
import PropTypes from "prop-types";
import { Button, Badge } from "reactstrap";
import { DARK_BLUE } from "../../../constants/colors";
import factsIcon from "../../../images/imageIcons/triviaIcon.png";

const FactsButton = ({ count, active, onClick }) => {
  // return (
  //   <button
  //     // style={{...styles.getBtnStyles(active)}}
  //     data-tip="Trivia"
  //     onClick={onClick}
  //     style={{
  //       backgroundColor: "white",
  //       color: "black",
  //       borderRadius: 10,
  //       display: "flex",
  //       alignItems: "center",
  //       fontSize: 11
  //     }}
  //     className="btn mb-2  btn-outline-primary"
  //   >
  //     <img
  //       className="icon"
  //       style={styles.icon}
  //       id="sharePage"
  //       src={factsIcon}
  //       alt="trivia button"
  //     />
  //     Trivia
  //     <Badge color="primary" className="ml-2" style={styles.getBadgeStyle(active)}>
  //       {count}
  //     </Badge>
  //   </button>
  // );
};

FactsButton.prototypes = {
  count: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default FactsButton;

const styles = {
  icon: {
    float: "left",
    height: 29,
    width: 33,
    height: "100%",
    borderRadius: 20,
    backgroundColor: "black"
  },
  getBtnStyles: active => {
    return {
      border: "1px solid black",
      background: "transparent",
      color: "black",
      padding: 3,
      margin: 5,
      backgroundColor: active ? DARK_BLUE : "inherit",
      color: active ? "white" : "inherit",
      fontSize: 12
    };
  },
  getBadgeStyle: active => {
    return {
      background: active ? "transparent" : DARK_BLUE
    };
  }
};
