import keywordsSorter from "../helpers/keywordsSorterFunction";
import getUniqueSortedAuthors from "../helpers/getSortedAuthor";
import conceptsSorter, { sortAndMap } from "../helpers/conceptHelpers";
import * as navbarConstants from "../constants/navbar";
import * as constants from "../constants/concepts";
import * as memesConstants from "../constants/memes";
import * as services from "../services/concept";
import * as authorConstant from "../constants/authors";
import { dataPagination } from "../helpers/navbar";
import * as perspectiveServices from "../services/perspectiveServices";
import {
  SORT_BY_LIKES,
  SORT_BY_AUTHORS,
  authorCategory,
  conceptCategory,
  keywordCategory,
  newClusterCategory,
  factCategory,
  groupsServerCategory
} from "../constants/global";
import { sortPerspectivesByLove } from "../helpers/perspectiveHelpers";
import { toast } from "react-toastify";
import { getMemesByMemeTag } from "../services/meme";
import { resetSecondCol } from "../helpers/scrolling";
import { CostExplorer } from "aws-sdk";

function startReactLoading(dispatch) {
  return dispatch({ type: constants.REQUEST_STARTED });
}

// export function loadPerspectivesBylikes(id, offset, merge, authorIds) {
//   return async dispatch => {
//     if (offset === 0) {
//       dispatch({
//         type: navbarConstants.LOADING_START
//       });
//     }
//     let perspective = {};
//     perspective["conceptId"] = id;
//     perspective["offset"] = offset;
//     perspective["authorIds"] = authorIds;
//     const data = await services.conceptPerspectivesByLikes(perspective);
//     let finish = false;
//     if (data.data.perspectives.length < 20) {
//       finish = true;
//     }
//     if (merge) {
//       dispatch({
//         type: constants.SET_PERSPECTIVES_DOWN,
//         payload: {
//           data: data.data.perspectives,
//           finish: finish
//         }
//       });
//     } else {
//       dispatch({
//         type: constants.SET_PERSPECTIVES,
//         payload: {
//           perspectives: data.data.perspectives,
//           finish: finish,
//           sortType: constants.SORT_BY_LIKE
//         }
//       });
//     }
//     dispatch({
//       type: navbarConstants.LOADING_END
//     });
//   };
// }

export function getPerspectivesCount(keyword) {
  return async dispatch => {
    let res = await perspectiveServices.getPerspectivesCount(keyword);
    dispatch({
      type: constants.PERSPECTIVE_COUNT,
      payload: res.data.perspectiveCount
    });
  };
}
export function getAllConcepts(character) {
  return async dispatch => {
    let res = await perspectiveServices.getAllConcepts(character);
    dispatch({
      type: constants.GET_CHAR_RELATED_ALL_CONCEPTS,
      payload: res.data
    });
  };
}

export function setSelectedOption(dispatch, selectedOption) {
  dispatch({
    type: navbarConstants.SET_SELECTED_OPTION,
    payload: selectedOption
  });
  dispatch({
    type: navbarConstants.SET_INSIGHTS,
    payload: selectedOption.count
  });
}
export function conceptDetailsByName(name, sortType, filters, allowLoadPerspectives) {
  return async (dispatch, getState) => {
    try {
      const pageName = window.location.href.split("/")[3];
      setSelectedOption(dispatch, {});
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: false
      });

      dispatch({
        type: constants.CONCEPT_LOADING,
        payload: true
      });
      dispatch({
        type: constants.BLOCK_PERSPECTIVE_LOADING,
        payload: false
      });

      const state = getState();
      const { limit } = state.concepts;
      const { selectedOption } = state.navbar;
      const random = state.navbar.isRandomActive;
      if (random) {
        dispatch({
          type: "IS_RANDOM",
          payload: true
        });
      }
      startReactLoading(dispatch);
      const data = await services.conceptByNameAndFilter(name, filters);
      // console.log("action", data.data.conceptDetail.relateds);
      if (data.status === 200) {
        let conceptDetail = data.data.conceptDetail;
        let selectedOption = conceptDetail.selectedOption;
        pageName != "memes" && setSelectedOption(dispatch, selectedOption);
        let authorRelatedToConcept = selectedOption.author;
        let groupRelatedToConcept = selectedOption.authorGroup;
        let keywords = conceptDetail.relateds.keywords.sort((a, b) => b.count - a.count);
        let authors = conceptDetail.relateds.authors.sort(getUniqueSortedAuthors);

        const authorGroupId = selectedOption.authorGroup ? selectedOption.authorGroup.id : null;
        const getBooksAndAuthors = await services.fetchBooksAndAuthors(
          selectedOption.booksCollection,
          authorGroupId
        );
        dispatch({
          type: authorConstant.AUTHOR_RELATEDS,
          relatedAuthors: getBooksAndAuthors.data.author
        });
        dispatch({
          type: navbarConstants.BOOKS,
          payload: getBooksAndAuthors.data.books
        });

        if (authors.length < 1 && pageName !== "Concepts" && pageName !== "concepts") {
          dispatch({
            type: navbarConstants.HIDE_PAGES,
            payload: true
          });
          return;
        }
        let authorIds = authors.map(author => author.id);
        const memesCount = conceptDetail.memesCount;
        const factsCount = conceptDetail.factsCount;
        dispatch({
          type: memesConstants.SET_MEMES_COUNT,
          payload: memesCount,
          factsCount: factsCount
        });

        if (authors.length < 1 && pageName !== "Concepts" && pageName !== "Concepts") {
          dispatch({
            type: constants.SHOW_MESSAGE
          });
        } else {
          dispatch({
            type: constants.CONCEPT_RELATEDS,
            payload: {
              authorRelatedToConcept,
              groupRelatedToConcept
            }
          });
          dispatch({
            type: navbarConstants.KEYWORDS,
            payload: keywords
          });
          dispatch({
            type: navbarConstants.AUTHORS,
            payload: authors
          });

          let perspectives = [];
          if (allowLoadPerspectives) {
            perspectives = await getInitialPerspectives(
              selectedOption.id,
              sortType,
              authorIds,
              limit
            );
            let authorPage = window.location.href.split("/")[5];
            {
              !authorPage &&
                dispatch({
                  type: navbarConstants.PERSPECTIVES,
                  payload: perspectives
                });
            }
            if (perspectives.length < limit) {
              dispatch({
                type: navbarConstants.BLOCK_PERSPECTIVE_LOADING,
                payload: true
              });
            }
          }
          dispatch({
            type: constants.CONCEPT_LOADING,
            payload: allowLoadPerspectives ? false : true,
            initialLoading: true
          });
          dispatch({
            type: constants.LIST_RELATED_LOADING
          });
        }
      } else {
        dispatch({
          type: navbarConstants.HIDE_PAGES,
          payload: true
        });
        dispatch({
          type: navbarConstants.SET_SELECTED_OPTION,
          payload: { id: -1, category: conceptCategory }
        });
      }
      const condition = {
        concept: name,
        offset: 0,
        limit: 10
      };

      getMemesByMemeTag(condition)
        .then(response => {
          const data = response.data;

          const state = getState();
          let memeTagIds =
            state.memes &&
            state.memes.textMemeColors &&
            state.memes.textMemeColors.map(item => item.ConceptId);
          let filteredTagConcepts =
            response.data &&
            response.data.concepts &&
            memeTagIds &&
            response.data.concepts.filter(item => memeTagIds.includes(item.id));
          dispatch({
            type: constants.SAVE_MEMES_TAGS_COCNEPTS,
            payload: filteredTagConcepts
          });
        })
        .catch(e => {
          console.log("error is ", e);
        });
    } catch (e) {
      console.log("error is", e);
      dispatch({
        type: constants.CONCEPT_LOADING,
        payload: true
      });
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: true
      });
    }
  };
}
export function getKeywordsData(name, filters) {
  return async (dispatch, getState) => {
    try {
      const data = await services.conceptByNameAndFilter(name, filters);
      if (data.status === 200) {
        let conceptDetail = data.data.conceptDetail;
        let keywords = conceptDetail.relateds.keywords.sort((a, b) => b.count - a.count);
        dispatch({
          type: navbarConstants.KEYWORDS,
          payload: keywords
        });
      }
    } catch (e) {
      console.log("error is", e);
    }
  };
}

export function updateThirdRow(selectedName) {
  return async (dispatch, getState) => {
    try {
      const condition = {
        concept: selectedName,
        offset: 0,
        limit: 10
      };

      getMemesByMemeTag(condition).then(response => {
        const data = response.data;

        const state = getState();
        let memeTagIds =
          state.memes &&
          state.memes.textMemeColors &&
          state.memes.textMemeColors.map(item => item.ConceptId);
        let filteredTagConcepts =
          response.data &&
          response.data.concepts &&
          memeTagIds &&
          response.data.concepts.filter(item => memeTagIds.includes(item.id));
        dispatch({
          type: constants.SAVE_MEMES_TAGS_COCNEPTS,
          payload: filteredTagConcepts
        });
      });
    } catch (e) {
      console.log("error is", e);
    }
  };
}

export function getRelatedConcepts(conceptId) {
  return async (dispatch, getState) => {
    try {
      const data = await services.getClusterConcepts({
        id: conceptId,
        type: newClusterCategory
      });

      if (data.status === 200) {
        const concepts = sortAndMap(data.data.data, null);
        dispatch({
          type: constants.SET_RELATEDS,
          payload: {
            concepts,
            listRelatedLoading: false
          }
        });
      }
    } catch (e) {
      console.log("error is", e);
      dispatch({
        type: constants.END_RELATED_LOADING
      });
    }
  };
}
async function getInitialPerspectives(id, sortType, authorIds, limit) {
  let response = [];
  if (sortType === SORT_BY_LIKES) {
    let temp = await perspectiveServices.getPerspectivesByLikes(authorIds, id, 0, limit, true);
    response = temp.data.data;
  } else {
    let perspective = {};
    let ids = authorIds.slice(0, 5);
    perspective["authorIds"] = ids;
    perspective["conceptId"] = id;
    let temp = await perspectivesByAuthors(ids, id);
    response = sortByAuthorIds(authorIds, temp.data.perspectives);
  }
  return response;
}
function perspectivesByAuthors(authorIds, conceptId) {
  return perspectiveServices.getPerspectivesByConceptsAndAuthors(authorIds, conceptId, true);
}

export function addKeyword(concept, perspectiveId) {
  return async (dispatch, getState) => {
    const response = await services.addKeywordToPerspective(concept.id, perspectiveId);

    if (response.status === 200) {
      const state = getState();
      const {
        navbar: { perspectives }
      } = state;
      const index = perspectives.findIndex(x => x.id === perspectiveId);
      if (index > -1) {
        const perspective = perspectives[index];
        if (perspective) {
          const oldKeywords = perspective.perspectiveKeywords;
          const alreadyExist = oldKeywords.findIndex(x => x.id === concept.id);
          if (alreadyExist < 0) {
            perspective.perspectiveKeywords = [
              ...oldKeywords,
              { ...concept, id: concept.id, name: concept.value }
            ];
            console.log("keywords action", perspective.perspectiveKeywords);
            perspectives[index] = { ...perspective };
            let keywords = state.navbar.keywords;

            const keywordIndex = keywords.findIndex(x => x.id === concept.id);
            if (keywordIndex > -1) {
              let keyword = keywords[keywordIndex];
              keyword.count = keyword.count + 1;
              keywords[keywordIndex] = keyword;
            } else {
              keywords.push({
                ...concept,
                name: concept.value,
                isFact: perspective.isFact,
                count: 1
              });
            }
            keywords.sort(keywordsSorter);

            dispatch({
              type: navbarConstants.KEYWORDS,
              payload: [...keywords]
            });
            dispatch({
              type: navbarConstants.PERSPECTIVES,
              payload: [...perspectives]
            });
          }
        }
      }
    }
  };
}
export function removeKeyword(concept, perspectiveId) {
  return async (dispatch, getState) => {
    const response = await services.removeKeywordFromPerspective(concept.id, perspectiveId);
    if (response.status === 200) {
      const state = getState();
      const {
        navbar: { perspectives }
      } = state;
      const index = perspectives.findIndex(x => x.id === perspectiveId);
      if (index > -1) {
        const perspective = perspectives[index];
        if (perspective) {
          let tempKeywords = [];
          let oldKeywords = perspective.perspectiveKeywords;
          oldKeywords = oldKeywords.filter(x => x.id !== concept.id);
          perspective.perspectiveKeywords = [...oldKeywords];
          perspectives[index] = { ...perspective };
          let keywords = state.concepts.keywords;
          const keywordIndex = keywords.findIndex(x => x.id === concept.id);
          if (keywordIndex > -1) {
            let keyword = keywords[keywordIndex];
            keyword.count = keyword.count - 1;
            if (keyword.count < 1) {
              keywords = keywords.filter(x => x.id !== concept.id);
            } else {
              keywords[keywordIndex] = keyword;
            }
            tempKeywords = keywords;
          } else {
            tempKeywords = keywords;
          }
          tempKeywords.sort(keywordsSorter);
          console.log("keywords remove", tempKeywords);
          dispatch({
            type: constants.KEYWORDS_AND_PERSPECTIVES,
            payload: {
              keywords: [...tempKeywords]
            }
          });
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: [...perspectives]
          });
        }
      }
    }
  };
}
function sortByAuthorIds(ids, perspectives) {
  let data = [];
  ids.forEach(authorId => {
    let temp = perspectives.filter(perspective => perspective.author_id === authorId);
    temp.forEach(perspect => {
      data.push(perspect);
    });
  });
  return data;
}

export function handleDownPagination() {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: true
      });
      const state = getState();
      const { sorting, offset, limit } = state.concepts;
      let data = [];
      const index = state.concepts.authorIndex;
      const topIndex = state.concepts.authorTopIndex;
      if (sorting === SORT_BY_AUTHORS) {
        const { authors, selectedOption } = state.navbar;
        const authorIds = authors.slice(index, index + 5).map(a => a.id);
        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
          authorIds,
          selectedOption.id,
          true
        );
        if (response.status === 200) {
          data = sortByAuthorIds(authorIds, response.data.data);
          dispatch({
            type: constants.CONCEPT_AUTHOR_INDEX,
            index: index + 5,
            topIndex: topIndex
          });
        }
      } else {
        const { authors, selectedOption } = state.navbar;
        const authorIds = authors.map(a => a.id);
        const response = await perspectiveServices.getPerspectivesByLikes(
          authorIds,
          [selectedOption.id],
          offset,
          limit,
          true
        );
        if (response.status === 200) {
          data = response.data.data;

          dispatch({
            type: constants.CONCEPT_OFFSET,
            payload: 1 + offset
          });
        }
      }
      let perspectives = state.navbar.perspectives;
      let newPerspectives = [...perspectives, ...data];
      let blockPagination = false;

      const pageName = window.location.href.split("/")[3];

      if (pageName === "Concepts" || pageName === "concepts") {
        blockPagination = false;
      } else {
        if (data.length < limit) {
          blockPagination = true;
        }
      }

      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: newPerspectives
      });
      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: false
      });
      dispatch({
        type: navbarConstants.BLOCK_PERSPECTIVE_LOADING,
        payload: blockPagination
      });
      dataPagination();
    } catch (e) {
      console.log("errro is ", e);
      dataPagination();
    }
  };
}

export function toggleConceptPagination() {
  return async (dispatch, getState) => {
    dispatch({
      type: navbarConstants.LOADING_START
    });
    try {
      const state = getState();
      const { sorting, limit } = state.concepts;
      const { authors, selectedOption } = state.navbar;
      let data = [];
      if (sorting === SORT_BY_AUTHORS) {
        dispatch({
          type: constants.SET_CONCEPT_SORTING,
          payload: SORT_BY_LIKES
        });
        const authorIds = authors.map(c => c.id);
        const response = await perspectiveServices.getPerspectivesByLikes(
          authorIds,
          selectedOption.id,
          0,
          limit,
          true
        );
        if (response.status === 200) {
          data = response.data.data;
        }
      } else {
        const array = authors.slice(0, 5);
        const authorIds = array.map(x => x.id);
        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
          authorIds,
          selectedOption.id,
          true
        );
        if (response.status === 200) {
          data = sortByAuthorIds(authorIds, response.data.data);
        }
        dispatch({
          type: constants.SET_CONCEPT_SORTING,
          payload: SORT_BY_AUTHORS
        });
        dispatch({
          type: constants.CONCEPT_AUTHOR_INDEX,
          index: 5,
          topIndex: 0
        });
      }
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: data
      });
    } catch (e) {
      console.log("error is ", e);
    }
    dispatch({
      type: navbarConstants.LOADING_END
    });
  };
}

export function perspectivesByFilter(inputValue) {
  return async (dispatch, getState) => {
    dispatch({
      type: navbarConstants.LOADING_START
    });
    try {
      const state = getState();
      let {
        navbar: { concepts, authors, selectedOption }
      } = state;
      if (inputValue.type === keywordCategory) {
        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: []
        });
        dispatch({
          type: navbarConstants.SELECTED_AUTHOR,
          payload: null
        });
        const data = await perspectiveServices.perspectivesByKeywords(
          inputValue.id,
          selectedOption.id
        );

        const count = data.data.data.perspectiveKeywords.perspectiveKeywords.length;

        if (count) {
          selectedOption.count = count;
          selectedOption.memeCount = data.data.data.memeCount;
          dispatch({
            type: navbarConstants.SET_SELECTED_OPTION,
            payload: selectedOption
          });
        }

        const { responseType } = data.data;
        const perspectiveLength = data.data.data.perspectiveKeywords
          ? data.data.data.perspectiveKeywords.length
          : 0;

        dispatch({
          type: navbarConstants.PERSPECTIVE_LENGTH,
          payload: perspectiveLength
        });
        // if (responseType === "success") {
        //   if (data.data.data) {
        //     let allPerspectives = data.data.data.perspectiveKeywords;

        //     const reverseData = await perspectiveServices.perspectivesByKeywords(
        //       selectedOption.id,
        //       inputValue.id
        //     );
        //     const { responseType } = reverseData.data;
        //     if (responseType === "success") {
        //       if (reverseData.data.data) {
        //         const sorted = sortPerspectivesByLove(reverseData.data.data.perspectiveKeywords);
        //         allPerspectives = [
        //           ...allPerspectives,
        //           ...reverseData.data.data.perspectiveKeywords
        //         ];
        //       }
        //     }

        //     dispatch({
        //       type: navbarConstants.PERSPECTIVES,
        //       payload: allPerspectives
        //     });

        //     dispatch({
        //       type: constants.CONCEPT_LOADING,
        //       payload: false,
        //       initialLoading: true
        //     });
        //   }
        // }

        // console.log("usama", data.data.data.perspectiveKeywords.perspectiveKeywords);

        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: data.data.data.perspectiveKeywords.perspectiveKeywords
        });
      }

      if (inputValue.type === authorCategory) {
        let conceptIds = selectedOption.id;
        let authorIds = inputValue.id;
        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
          authorIds,
          conceptIds,
          true
        );
        if (response.status === 200) {
          const sorted = response.data.data;

          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: sorted
          });
          dispatch({
            type: constants.CONCEPT_LOADING,
            payload: false,
            initialLoading: true
          });
        }
      }
      if (inputValue.type === factCategory) {
        let conceptId = selectedOption.id;
        const response = await perspectiveServices.getFactsByConcepts(conceptId);
        if (response.status === 200) {
          const sorted = response.data.data;
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: sorted
          });
          dispatch({
            type: constants.CONCEPT_LOADING,
            payload: false,
            initialLoading: true
          });
        }
      }
    } catch (e) {
      console.log("error is ", e);
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: []
      });
    }

    dispatch({
      type: navbarConstants.LOADING_END
    });

    setTimeout(() => {
      resetSecondCol();
    }, 2000);
  };
}

export function setConceptSorting(sorting) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.SET_CONCEPT_SORTING,
        payload: sorting
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function setFactsKeywrodFilterId(keywordId) {
  return async dispatch => {
    try {
      dispatch({
        type: constants.FACT_KEYWORD_FILTER,
        payload: keywordId
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function removeReciprocal(clusterId, conceptId) {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      user: { token }
    } = state.auth;
    let { concepts } = state.concepts;
    const { selectedOption } = state.navbar;
    if (selectedOption.category !== conceptCategory) {
      concepts = state.navbar.concepts;
    }
    const index = concepts.findIndex(x => x.id === conceptId);
    if (index > -1) {
      const payload = {
        conceptId,
        clusterId,
        token
      };
      const response = await services.removeReciprocal(payload);
      if (response.status === 200) {
        let concept = concepts[index];
        concept.reciprocalExist = false;
        concepts[index] = concept;
        if (selectedOption.category === conceptCategory) {
          dispatch({
            type: constants.SET_RELATEDS,
            payload: {
              concepts: [...concepts],
              listRelatedLoading: false
            }
          });
        } else {
          dispatch({
            type: navbarConstants.CONCEPTS,
            payload: [...concepts]
          });
        }
      }
    }
  };
}

export function addReciprocal(clusterId, conceptId) {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      user: { token }
    } = state.auth;
    let { concepts } = state.concepts;
    const { selectedOption } = state.navbar;
    if (selectedOption.category !== conceptCategory) {
      concepts = state.navbar.concepts;
    }

    const index = concepts.findIndex(x => x.id === conceptId);
    if (index > -1) {
      const payload = {
        conceptId,
        clusterId,
        token
      };
      const response = await services.addReciprocal(payload);
      if (response.status === 200) {
        let concept = concepts[index];
        concept.reciprocalExist = true;
        concepts[index] = concept;
        if (selectedOption.category === conceptCategory) {
          dispatch({
            type: constants.SET_RELATEDS,
            payload: {
              concepts: [...concepts],
              listRelatedLoading: false
            }
          });
        } else {
          dispatch({
            type: navbarConstants.CONCEPTS,
            payload: [...concepts]
          });
        }
      }
    }
  };
}

export function removeClusterItem(conceptId) {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      user: { token }
    } = state.auth;
    const { selectedOption } = state.navbar;
    let { concepts } = state.concepts;
    if (selectedOption.category !== conceptCategory) {
      concepts = state.navbar.concepts;
    }
    concepts = concepts.filter(x => x.id !== conceptId);
    const payload = {
      clusterId: selectedOption.id,
      conceptId,
      type: newClusterCategory,
      token
    };
    const response = await services.deleteConceptFromCluster(payload);
    if (response.status === 200) {
      if (selectedOption.category === conceptCategory) {
        dispatch({
          type: constants.SET_RELATEDS,
          payload: {
            concepts: [...concepts],
            listRelatedLoading: false
          }
        });
      } else {
        dispatch({
          type: navbarConstants.CONCEPTS,
          payload: [...concepts]
        });
      }
    }
  };
}

export function addClusterItem(inputValue) {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      user: { token }
    } = state.auth;
    const { selectedOption } = state.navbar;
    let concepts = state.navbar.concepts;
    if (selectedOption.category === conceptCategory) {
      concepts = state.concepts.concepts;
    } else {
      concepts = state.navbar.concepts;
    }

    const index = concepts.findIndex(x => x.id === inputValue.id);
    if (index < 0) {
      const payload = {
        clusterId: selectedOption.id,
        conceptId: inputValue.id,
        type: newClusterCategory,
        token
      };
      const response = await services.addConceptToCluster(payload);
      inputValue.name = inputValue.value;
      inputValue.reciprocalExist = true;
      concepts.unshift(inputValue);
      if (response.status === 200) {
        if (selectedOption.category === conceptCategory) {
          dispatch({
            type: constants.SET_RELATEDS,
            payload: {
              concepts: [...concepts],
              listRelatedLoading: false
            }
          });
        } else {
          dispatch({
            type: navbarConstants.CONCEPTS,
            payload: [...concepts]
          });
          dispatch({
            type: constants.SET_RELATEDS,
            payload: {
              concepts: [],
              listRelatedLoading: false
            }
          });
        }
      }
    }
  };
}

export function findTempConcepts(clusterId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        user: { token }
      } = state.auth;
      const { selectedOption } = state.navbar;
      const { concepts } = state.concepts;
      dispatch({
        type: constants.TEMP_LOADING,
        payload: true
      });
      const response = await services.getClusterConcepts({
        type: newClusterCategory,
        id: clusterId
      });
      const { data } = response.data;
      dispatch({
        type: constants.TEMP_CONCEPTS,
        payload: data.sort(conceptsSorter)
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function toggleKeywordsSort() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      console.log("🚀 ~ file: conceptsActions.js ~ line 1024 ~ return ~ state", state);

      let { concepts } = state.navbar;
      const { keywordsCountSort } = state.concepts;
      if (keywordsCountSort) {
        concepts = concepts.sort(keywordsSorter);
        dispatch({
          type: constants.SET_KEYWORDS_COUNT_SORT,
          payload: false
        });
      } else {
        concepts = concepts.sort((a, b) => b.count - a.count);
        dispatch({
          type: constants.SET_KEYWORDS_COUNT_SORT,
          payload: true
        });
      }
      dispatch({
        type: navbarConstants.CONCEPTS,
        payload: [...concepts]
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}
export function handleConceptSubmit(name, pronoun, type, relatedConcepts) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.CONCEPT_SUBMIT_LOADING,
        payload: true,
        error: ""
      });
      const state = getState();
      const token = state.auth.user.token;
      const response = await services.addNewConcept(token, name, pronoun, type, relatedConcepts);
      if (response.status === 201) {
        dispatch({
          type: constants.CONCEPT_SUBMIT_LOADING,
          payload: false,
          error: ""
        });
        dispatch({
          type: constants.ADD_UNVERIFIED_CONCEPT_SUCCESS,
          payload: response.data.data
        });
        toast.success("Concept added successfully");
      }
    } catch (e) {
      let error = "";
      if (e.response) {
        if (e.response.status == 409) {
          toast.error("Concept with this name already Exists");
          error = "Concpet with this name already Exists";
        }
      } else error = e.message;

      dispatch({
        type: constants.CONCEPT_SUBMIT_LOADING,
        payload: false,
        error: error
      });
    }
  };
}

export function handleTextishConceptClick(conceptName) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { textishConcepts, limit } = state.concepts;
      const { subFilter } = state.navbar;
      dispatch({
        type: constants.TEXTISH_SELECTED,
        payload: null
      });
      //console.log("subFilters are only", subFilter);
      dispatch({
        type: constants.TEXTISH_DATA_LOADING,
        payload: true
      });
      //console.log("searching for ", conceptName, "----> ", textishConcepts);
      const index = textishConcepts.findIndex((concept, index) => {
        return concept.original === conceptName;
      });
      //console.log("index", index);
      const textishConcept = textishConcepts[index];
      const newConcepts = [...textishConcepts];
      dispatch({
        type: constants.TEXTISH_CONCEPTS,
        payload: JSON.parse(JSON.stringify(newConcepts))
      });
      let filter = null;
      if (subFilter && subFilter.category === groupsServerCategory) {
        filter = subFilter.id;
      }
      const data = await services.getAuthorsByConceptId(textishConcept.founded.id, filter);

      dispatch({
        type: navbarConstants.SET_SELECTED_OPTION,
        payload: {
          id: textishConcept.founded.id,
          value: textishConcept.founded.name,
          label: textishConcept.founded.name
        }
      });

      dispatch({
        type: constants.TEXTISH_SELECTED,
        payload: textishConcept.founded.name
      });

      if (data.status === 200) {
        const authors = data.data.data;
        let authorIds = authors.map(author => author.id);
        const subFilter = state.navbar.subFilter;
        if (subFilter && subFilter.category === authorCategory) {
          dispatch({
            type: navbarConstants.AUTHORS,
            payload: [
              {
                id: subFilter.id,
                firstName: "",
                lastName: subFilter.value
              }
            ]
          });
          authorIds = [subFilter.id];
        } else {
          dispatch({
            type: navbarConstants.AUTHORS,
            payload: authors
          });
        }
        const perspectives = await getInitialPerspectives(
          textishConcept.founded.id,
          SORT_BY_LIKES,
          authorIds,
          10
        );

        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: perspectives
        });
        if (perspectives.length < 10) {
          dispatch({
            type: navbarConstants.BLOCK_PERSPECTIVE_LOADING,
            payload: true
          });
        }
      }
      dispatch({
        type: constants.TEXTISH_DATA_LOADING,
        payload: false
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function fetchUnverifiedConcepts() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        user: { token }
      } = state.auth;

      dispatch({
        type: constants.CONCEPT_LOADING,
        payload: true
      });
      const res = await services.unverifiedConceptsApi(token);
      dispatch({
        type: constants.GET_UNVERIFIED_CONCEPTS_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      dispatch({
        type: constants.CONCEPT_LOADING,
        payload: false
      });
      console.log("error is ", e);
    }
  };
}

export function delUnverifiedConcepts(id) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        user: { token }
      } = state.auth;

      dispatch({
        type: constants.UNVERIFIED_CONCEPT_DELETE_LOADING,
        payload: true
      });
      const data = await services.delUnverifiedConceptsApi(id, token);
      dispatch({
        type: constants.UNVERIFIED_CONCEPT_DELETE_SUCCESS,
        payload: id
      });
    } catch (e) {
      dispatch({
        type: constants.UNVERIFIED_CONCEPT_DELETE_LOADING,
        payload: false
      });
      console.log("error is ", e);
    }
  };
}
