import { perspectivesByKeywords } from "../services/perspectiveServices"

export const perspectiveLink = id => {
    return `${process.env.REACT_APP_SERVER}/perspective/${id}`
}

export const sortPerspectivesByConcepts = (concepts, perspectives) => {
    let temp = []
    concepts.forEach(concept => {
        let data = perspectives.filter(p => p.concept_id  === concept.id)
        data.forEach(pr => {
            temp.push(pr)
        })
    })
    return temp
}

export const sortBookPerspectivesByConcepts = (concepts, perspectives) => {
    // let temp = []
    // concepts.forEach(concept => {
    //     let data = perspectives.filter(p => p.concept_id  === concept.id)
    //     data.forEach(pr => {
    //         temp.push(pr)
    //     })
    // })
    return perspectives
}

export const sortPerspectivesByLove = perspectives => {
    return perspectives.sort((x, y) => {
        return y.loves - x.loves
    })
}
