import * as constants from "../constants/cluster";
import * as global from "../constants/global";
import { SET_MEMES_COUNT } from "../constants/memes";

const initialState = {
  initial: true,
  apiLoading: false,
  sorting: global.SORT_BY_CONCEPTS,
  conceptIndex: 0,
  conceptTopIndex: 0,
  offset: 1,
  limit: 20,
  initialLoading: false,
  selectedConceptIndex: -1,
  blockPagination: false,
  authorGroups: [],
  factsCount: 0,
  conceptFilterId: -1,
  finish: false,
  selectedAuthorId: -1,
  conceptPerspectiveFinish: false
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.CLUSTER_LOADING:
      return {
        ...state,
        apiLoading: action.payload,
        initialLoading: !action.payload
      };

    case constants.CLUSTER_OFFSET:
      return {
        ...state,
        offset: action.payload
      };

    case constants.CLUSTER_CONCEPT_INDEX:
      return {
        ...state,
        conceptIndex: action.index
      };

    case constants.SET_CLUSTER_SORTING:
      return {
        ...state,
        sorting: action.payload
      };

    case constants.SET_SELECTED_CONCEPT_ID:
      return {
        ...state,
        selectedConceptId: action.payload,
        selectedAuthorId: -1
      };

    case constants.BLOCK_CLUSTER_PAGINATION: {
      return {
        ...state,
        blockPagination: action.payload
      };
    }

    case constants.CLUSTER_GROUPS: {
      return {
        ...state,
        authorGroups: action.payload
      };
    }

    case SET_MEMES_COUNT: {
      // grabbing facts count
      return {
        ...state,
        factsCount: action.factsCounts
      };
    }
    case constants.SET_CONCEPT_FILTER_ID:
      return {
        ...state,
        conceptFilterId: action.payload
      };

    case constants.SET_CLUSTER_SELECTED_AUTHOR:
      return {
        ...state,
        selectedAuthorId: action.payload
      };

    case constants.CONCEPT_PERSPECTIVE_FINISH:
      return {
        ...state,
        conceptPerspectiveFinish: action.payload
      };

    default:
      return state;
  }
};
