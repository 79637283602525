import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Button,
  Input,
  Alert
} from "reactstrap";
import addIcon from "../../../images/imageIcons/addIcon.png";
import Loader from "react-loader-spinner";
import { MAIN_WHITE } from "../../../constants/colors";

const AuthorModalForm = props => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [myError, setMyError] = useState("");
  const [myLoading, setMyLoading] = useState(false);

  const { loading, onAuthorSubmit, error, openAuthor, setOpenAuthor = () => {} } = props;

  const toggle = () => {
    setOpenAuthor(!openAuthor);
  };

  useEffect(() => {
    // if previously loading then there could be an error
    if (!loading && myLoading) {
      setMyError(error);
      setFirstName("");
      setLastName("");
      setOpenAuthor("");
    }

    setMyLoading(loading);
  }, [loading, error]);

  const handleSubmit = () => {
    if (firstName && lastName) {
      if (!loading) {
        onAuthorSubmit(firstName, lastName);
        setFirstName("");
        setLastName("");
        toggle();
      }
    } else {
      setMyError("Please fill all fields correctly");
    }
  };

  return (
    <>
      <img src={addIcon} style={styles.addIcon} onClick={toggle} alt="aad Icon" />
      <Modal isOpen={openAuthor} toggle={toggle}>
        <ModalHeader toggle={toggle}>Author Modal</ModalHeader>
        <ModalBody>
          {myError && <Alert color="danger">{myError}</Alert>}
          <FormGroup>
            <Label>Author first name</Label>
            <Input
              type="text"
              name="name"
              autocomplete="off"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              placeholder="Enter concept name"
            />
          </FormGroup>
          <FormGroup>
            <Label>Author last name</Label>
            <Input
              type="text"
              name="name"
              autocomplete="off"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              placeholder="Enter concept name"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            {loading ? <Loader type="Oval" color={MAIN_WHITE} height="30" width="30" /> : "Add"}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

AuthorModalForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onAuthorSubmit: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired
};

export default AuthorModalForm;

const styles = {
  addIcon: {
    width: 25,
    cursor: "pointer"
  }
};
