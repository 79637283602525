
import { persistStore, persistReducer } from 'redux-persist'
import { createStore, applyMiddleware,compose } from 'redux';
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import rootReducer from "../reducers";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
        key: 'root',
        storage,
        whitelist: ['auth']
      }  
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer,composeEnhancers(applyMiddleware(thunk)));
const persistor = persistStore(store);
export default {store,persistor}


