import React from 'react'
import {connect} from 'react-redux'
import MemeCard from './memeCard'

const IntervalMemes = props =>  {
    const {index, memes, interval} = props
    // if we only do index/interval then first meme at index 0 will be missed because 8/8 = 1, but 8-8/8 = 0
    const position = index-interval
    let meme = null
    let show = false
    if(position >= 0 && position%interval === 0 )
    {
        const myIndex = (position/interval)
        meme = memes[myIndex]
        if(meme)
        {
            show = true
        }
    
    }
    return (
        <div className="d-block d-md-none">
            {show && <MemeCard meme={meme}/>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        memes: state.memes.memes
    }
}

export default connect(mapStateToProps, null) (React.memo(IntervalMemes))
