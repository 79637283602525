import React, { useState } from "react";
import { connect } from "react-redux";
import HeaderImage from "./headerImage";
import AuthorWikiInfo from "./authorWikiInfo";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as helpers from "../../../../helpers/authorHelpers";
import * as navbarAction from "../../../../actions/navbaractions";
import "./perspectiveHeader.css";
import { authorCategory } from "../../../../constants/global";
import { Link } from "react-router-dom";
import { pageReloadscroll } from "../../../../helpers/navbar";

const PerspectiveHeader = props => {
  const [wiki, setWiki] = useState(false);
  let { author } = props;

  const mouseEnter = () => {
    setWiki(true);
  };

  const mouseLeave = () => {
    setWiki(false);
  };

  const onAuthorClick = () => {
    const iframe = window.location.pathname.includes("/archive/");
    if (iframe) {
      const win = window.open(
        `${process.env.REACT_APP_SERVER}/${authorCategory}/${author.firstName} ${author.lastName}`,
        "_blank"
      );
      win.focus();
    } else {
      const item = helpers.getAuthorItem(author);
      props.setSelectedItem(item);
    }
  };
  return (
    <div className="author-box" onMouseLeave={mouseLeave}>
      <HeaderData
        link={helpers.authorPicLink(author)}
        mouseEnter={mouseEnter}
        mouseLeave={mouseLeave}
        onAuthorClick={onAuthorClick}
        author={author}
      />
      {wiki && (
        <AuthorWikiInfo
          onMouseLeave={mouseLeave}
          firstName={author.firstName}
          lastName={author.lastName}
        />
      )}
    </div>
  );
};

PerspectiveHeader.prototype = {
  author: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedItem: bindActionCreators(navbarAction.setSelectedItem, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(React.memo(PerspectiveHeader));

const HeaderData = props => (
  <>
    {/* <HeaderImage link={props.link} /> */}
    <Link to={`/Authors/${props.author.slug}`} onClick={() => pageReloadscroll()}>
      <b
        className="hover-grey"
        id="author"
        onMouseEnter={props.mouseEnter}
        style={{
          display: "flex",
          fontFamily: "Times New Roman",
          color: "white",
          flexWrap: "wrap"
        }}
      >
        <p className="authorname m-0">{props.author.firstName}</p> &nbsp;
        <p className="authorname m-0">{props.author.lastName}</p>
      </b>
    </Link>
  </>
);
