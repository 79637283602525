import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { headerStyles } from "../../TopNavBar/search/searchStyles";
import searches from "../../../helpers/search";
import * as navbarActions from "../../../actions/navbaractions";
import AsyncSelect from "react-select/lib/Async";

const FilterSearch = props => {
  const [message, setMessage] = useState("");
  const { tags, searchCategory, setFilterSearch, setSubFilterState } = props;
  const handleClickItem = item => {
    setSubFilterState(`${item.value}`);
    setFilterSearch(false);
  };

  const onTagsChanged = tag => {
    props.addTag(tag, tags);
    if (tags.length > 0) {
      props.setSelectedCategory("AuthorGroups");
    }
    if (tags.length <= 0) {
      props.setSelectedCategory("All");
    }
    props.closeSearch();
  };
  const loadFilterOptions = inputValue => {
    return new Promise(resolve => {
      searches.authorGroupsNav(inputValue, function(err, results) {
        resolve(results);
      });
    });
    // if (searchCategory == "Concept") {
    //   return new Promise(resolve => {
    //     searches.concepts(inputValue, function(err, results) {
    //       resolve(results);
    //     });
    //   });
    // } else if (searchCategory == "Author") {
    //   return new Promise(resolve => {
    //     searches.authors(inputValue, function(err, results) {
    //       resolve(results);
    //     });
    //   });
    // } else if (searchCategory == "Concept Clusters") {
    //   return new Promise(resolve => {
    //     searches.conceptClusters(inputValue, function(err, results) {
    //       resolve(results);
    //     });
    //   });
    // } else if (searchCategory == "Author Groups") {
    //   return new Promise(resolve => {
    //     searches.authorGroupsNav(inputValue, function(err, results) {
    //       resolve(results);
    //     });
    //   });
    // } else {
    //   return new Promise(resolve => {
    //     searches.all(inputValue, function(err, results) {
    //       resolve(results);
    //     });
    //   });
    // }
  };

  return (
    <AsyncSelect
      loadOptions={loadFilterOptions}
      onChange={handleClickItem}
      noOptionsMessage={() => message}
      //isDisabled={tags.length >= 2 ? true : false || searchCategory === "Author Groups"}
      placeholder="Apply Sub Filter..."
      onFocus={() => setMessage("Start Typing")}
      styles={headerStyles}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
    />
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedCategory: bindActionCreators(navbarActions.setSelectedCategory, dispatch),
    addTag: bindActionCreators(navbarActions.addTag, dispatch)
  };
};

const mapStateToProps = state => {
  return {
    tags: state.navbar.tags,
    searchCategory: state.navbar.searchCategory
  };
};

FilterSearch.propTypes = {
  closeSearch: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSearch);

const styles = {
  column: {
    padding: 4
  },
  responseive: {
    width: "100%",
    height: "100%"
  },
  relatedIcon: {
    width: 33,
    height: "100%",
    borderRadius: 20
  }
};
