import React, { useState, useEffect } from "react";
import ToolTip from "react-portal-tooltip";
import { connect } from "react-redux";
import { MEME_NEW_TYPE } from "../../../constants/global";
import MemeForm from "../../utills/form/memeForm";
import $ from "jquery";
import { bindActionCreators } from "redux";
import { handlePerspectiveSubmit } from "../../../actions/navbaractions";
import AddIcon from "../../../images/imageIcons/addIcon.png";
import { handleConceptSubmit } from "../../../actions/conceptsActions";
import { handleAuthorSubmit } from "../../../actions/authorActions";
import OutsideClickHandler from "react-outside-click-handler";
import { handleContextAndCopyPaste } from "../../../helpers/scrolling";
import * as actions from "../../../actions/memes";

const AddMeme = props => {
  const [active, setActive] = useState(false);
  const { conceptLoading, conceptError, authorLoading, authorError } = props;

  const toggle = () => {
    setActive(!active);
  };

  useEffect(() => {
    if (active) {
      handleContextAndCopyPaste(false);
    } else {
      handleContextAndCopyPaste(true);
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      $(document).on("click", e => {
        if (
          e.target.id == "addPerspectiveFormAdmin" ||
          $(e.target).parents("#addPerspectiveFormAdmin").length
        ) {
        } else {
          // setActive(false)
        }
      });
    } else {
      $(document).off("click");
    }
  }, [active]);

  // const closeToolTip = () => active ? setActive(false) : false

  return (
    <>
      <img
        src={AddIcon}
        alt="Add icon"
        style={styles.addPerspective}
        id="addMeme"
        className="icon"
        onClick={toggle}
      />
      <ToolTip active={active} position="left" arrow="bottom" parent={`#addMeme`} group="navbar">
        <OutsideClickHandler onOutsideClick={() => {}}>
          <div id="addPerspectiveFormAdmin">
            <MemeForm
              nature={MEME_NEW_TYPE}
              handlePerspectiveSubmit={props.handlePerspectiveSubmit}
              handleConceptSubmit={props.handleConceptSubmit}
              handleAuthorSubmit={props.handleAuthorSubmit}
              uploadMeme={props.uploadMeme}
              updateMeme={props.updateMeme}
              currentConcept={props.selectedOption}
              conceptError={conceptError}
              conceptLoading={conceptLoading}
              authorLoading={authorLoading}
              authorError={authorError}
              textMemeColors={props.textMemeColors}
            />
          </div>
        </OutsideClickHandler>
      </ToolTip>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    handlePerspectiveSubmit: bindActionCreators(handlePerspectiveSubmit, dispatch),
    handleConceptSubmit: bindActionCreators(handleConceptSubmit, dispatch),
    handleAuthorSubmit: bindActionCreators(handleAuthorSubmit, dispatch),
    uploadMeme: bindActionCreators(actions.uploadTextMeme, dispatch),
    updateMeme: bindActionCreators(actions.updateTextMeme, dispatch)
  };
};

const mapStateToProps = state => {
  return {
    conceptLoading: state.concepts.conceptLoading,
    conceptError: state.concepts.conceptError,
    authorError: state.author.authorError,
    authorLoading: state.author.authorLoading,
    selectedOption: state.navbar.selectedOption,
    textMemeColors: state.memes.textMemeColors
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMeme);

const styles = {
  addPerspective: {
    height: 25,
    marginTop: "60px"
  }
};
