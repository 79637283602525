import React from "react";
import filter from "../../../images/iconFilter.png";

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
let categories = [
  { name: "All", value: "All", icon: "fas fa-infinity", color: "grey" },
  { name: "Concepts", value: "Concept", icon: "fa fa-gear", color: "black" },
  { name: "Authors", value: "Author", icon: "fa fa-user", color: "red" },
  { name: "Dictionaries", value: "Concept Clusters", icon: "fa fa-gears", color: "#ff00ff" },
  { name: "Author Groups", value: "Author Groups", icon: "fa fa-users", color: "lime" }
];

function getCategory(selectedCategory) {
  let data = {};
  categories.forEach(category => {
    if (selectedCategory === category.value) {
      data = category;
    }
    if (selectedCategory === "AuthorGroups") {
      data = categories[0];
    }
  });
  return data;
}

class SearchCategory extends React.Component {
  state = {
    isOpen: false
  };
  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    let category = getCategory(this.props.category);
    let clr = this.props.colorBorder;
    let { isOpen } = this.state;
    return (
      <>
        <Dropdown
          data-tip="limit search to: concept, author, cluster and groups"
          isOpen={isOpen}
          toggle={this.toggle}
          id="searchBtn"
        >
          <DropdownToggle
            style={{
              border: "1px solid",
              backgroundColor:
                clr == "Concept"
                  ? "grey"
                  : clr == "Concept Clusters"
                  ? "#ff00ff"
                  : clr == "Author"
                  ? "red"
                  : clr == "Author Groups"
                  ? "lime"
                  : "transparent",
              borderColor:
                clr == "Concept"
                  ? "grey"
                  : clr == "Concept Clusters"
                  ? "#ff00ff"
                  : clr == "Author"
                  ? "red"
                  : clr == "Author Groups"
                  ? "lime"
                  : "transparent",
              paddingRight: "5px"
            }}
            id="toggleStyle"
          >
            <img
              src={filter}
              style={{
                float: "left",
                width: "28px",
                height: "28px",
                marginLeft: "5px",
                marginTop: "5px",
                cursor: "pointer"
              }}
            />
            {/* <i className="fa fa-search" style={{ borderColor: category.color }}></i> */}
            {/* <i
              id="selectedCategoryIcon"
              className={category.icon}
              style={{ borderColor: category.color }}
            ></i> */}
          </DropdownToggle>
          <DropdownMenu id="popupDropdownList">
            {categories.map((category, index) => {
              return (
                <DropdownItem key={index} onClick={() => this.props.changeCategory(category.value)}>
                  <i className={category.icon} style={{ borderColor: category.color }}></i>
                  <span>{category.name}</span>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </>
    );
  }
}
export default SearchCategory;
