import React from "react";
import { Helmet } from "react-helmet";

const Tags = ({ title = "", description = "" }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
    </>
  );
};

export default Tags;
