import React, { useState, Fragment, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ToolTip from "react-portal-tooltip";
import Search from "../../../TopNavBar/search/search";
import * as actions from "../../../../actions/conceptsActions";
import { PerspectiveContext } from "..";

const keywordButton = props => {
  const [leave, setLeave] = useState(false);
  const [delay, setDelay] = useState("");
  const { focus, setFocus, perspective } = props;
  const { perspectiveKeywords } = perspective;

  const [focusSearch, setFocusSearch] = useState(false);

  useEffect(() => {
    try {
      if (ref.current) {
        setFocusSearch(false);
        ref.current.focus();
      }
      if (setFocusSearch) setFocusSearch(false);
    } catch (e) {
      console.log("could not found ref");
    }
  }, [ref, focusSearch]);

  const ref = useRef();
  let updateId = () => {};
  let perspectiveId = -1;

  useEffect(() => {
    if (perspectiveId === perspective.id) {
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("click", handleOutsideClick);
    } else {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleOutsideClick);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [perspectiveId, leave]);

  const handleOutsideClick = () => {
    if (leave && perspectiveId === perspective.id) {
      // setActive(false)
      updateId(-1);
      setLeave(false);
    }
  };
  const handleScroll = () => {
    // if(perspectiveId === perspective.id)
    // {
    updateId(-1);
    // }
  };
  const handleMouseEnter = () => {
    if (perspectiveId === perspective.id) {
      setLeave(false);
      clearTimeout(delay);
    }
  };
  const handleMouseLeave = () => {
    setLeave(true);
    hideWithDelay();
    // setActive(false)
  };

  const hideWithDelay = () => {
    if (perspectiveId === perspective.id) {
      setDelay(
        setTimeout(() => {
          if (!focus) {
            updateId(-1);
          }
        }, 10000)
      );
    }
  };

  const toggle = () => {
    if (perspectiveId === perspective.id) {
      updateId(-1);
    } else {
      updateId(perspective.id);
    }
    setFocusSearch(true);
  };
  const onSearch = inputValue => {
    props.addKeyword(inputValue, perspective.id);
  };

  const keywordList = (
    <Fragment>
      {perspectiveKeywords &&
        perspectiveKeywords.map(keyword => {
          return (
            <div
              key={keyword.id}
              style={styles.chip}
              id="chip"
              onClick={() => props.removeKeyword(keyword, perspective.id)}
            >
              {`${keyword.name}  x`}
            </div>
          );
        })}
    </Fragment>
  );

  return (
    <PerspectiveContext.Consumer>
      {data => {
        const { selectedId, setSelected } = data;
        perspectiveId = selectedId;
        updateId = setSelected;

        return (
          <>
            <div
              style={styles.wrapper}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <span
                className="perspective-keywords"
                onClick={toggle}
                id={`perspectives${perspective.id}`}
              >
                <i className="fa fa-plus-circle"></i>
              </span>
            </div>
            {perspective.id === selectedId && (
              <ToolTip
                active={perspective.id === selectedId}
                position="bottom"
                arrow="center"
                parent={`#perspectives${perspective.id}`}
              >
                <div
                  style={styles.tipWrapper}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div style={styles.secondaryWrapper}>
                    <b style={styles.tags}>Add Tag</b>
                    <div style={styles.searchWrapper}>
                      <Search
                        value={""}
                        onChange={inputValue => onSearch(inputValue)}
                        placeholder={`keyword search`}
                        category={"Keywords"}
                        searchRef={ref}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                      />
                    </div>
                  </div>
                  <div style={styles.chipWrapper}>{keywordList}</div>
                </div>
              </ToolTip>
            )}
          </>
        );
      }}
    </PerspectiveContext.Consumer>
  );
};

const mapDispatchToProps = dispatch => ({
  addKeyword: bindActionCreators(actions.addKeyword, dispatch),
  removeKeyword: bindActionCreators(actions.removeKeyword, dispatch)
});

export default connect(null, mapDispatchToProps)(keywordButton);

const styles = {
  wrapper: {
    display: "inline"
    // padding: "10px 20px"
  },
  secondaryWrapper: {
    display: "flex",
    alignItems: "center"
  },
  searchWrapper: {
    marginTop: 10,
    marginBottom: 10,
    flex: 1
  },
  tags: {
    paddingRight: 5
  },
  tipWrapper: {
    width: 300,
    padding: 7
  },
  chipWrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    padding: "0px 7px",
    fontSize: 14,
    backgorund: "red",
    border: "1px solid #bab8b8",
    borderRadius: "5rem",
    margin: 3
  }
};
