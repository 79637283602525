import React from 'react'
import PropTypes from 'prop-types'
import { Col, Input, FormGroup, Label } from 'reactstrap'
import { DARK_GREY, LIGHT_BLUE } from '../../../constants/colors'

const CitationForm = props => {
    return (
        <div style={styles.wrapper}>
            <FormGroup row>
                <Label for="examplePassword" sm={2}>Author</Label>
                <Col sm={6}>
                    <Input type="text" name="authorLastName" onChange={props.handleChange} id="examplePassword" placeholder="enter your own here" required/>
                </Col>
                <Col>
                    <Input type="checkbox" />my own
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="examplePassword" sm={2}>Citation</Label>
                <Col sm={6}>
                    <Input
                        placeholder="enter your own here"
                        style={{ width:"100%",textAlign: "center", fontStyle: "italic"}}
                        name="citation" 
                        onChange={props.handleChange}
                    />
                </Col>
            </FormGroup>
            <FormGroup row style ={{marginLeft:"1%"}}>
                <Label for="examplePassword" sm={2} style={{display: "contents"}}>original context</Label>
                <Col sm={6}>
                    <Input type="text" name="original" onChange={props.handleChange}  id="examplePassword" placeholder="enter your own here" required/>
                </Col>
            </FormGroup>
        </div>
    )
}

CitationForm.propTypes = {
    handleChange: PropTypes.func.isRequired
}

export default React.memo(CitationForm)
const styles = {
    wrapper: {
        backgroundColor: DARK_GREY,
        paddingTop: "3%",
        paddingLeft: "3%",
    },

    title: {
        fontSize: 20,
        fontWeight: 700,
        marginBottom: "2%",
        paddingRight: 5
    },
    item: {
        fontWeight: 700,
        cursor: 'pointer'
    },
    cluster: {
        fontSize: 18,
        color: LIGHT_BLUE,
        cursor: 'pointer'
    }
}