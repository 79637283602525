export const SAVE_POS = "SAVE_POS";
export const SAVE_POS_SUCCESS = "SAVE_POS_SUCCESS";
export const SAVE_POS_FAIL = "SAVE_POS_FAIL";
export const GET_POS = "GET_POS";
export const GET_POS_SUCCESS = "GET_POS_SUCCESS";
export const GET_POS_FAIL = "GET_POS_FAIL";
export const SET_POS_LOADING = "SET_POS_LOADING";
export const DELETE_POS_SUCCESS = "DELETE_POS_SUCCESS";
export const POS_CATEGORIES_SUCCESS = "POS_CATEGORIES_SUCCESS";
export const POS_CATEGORIES_VALUES_SUCCESS = "POS_CATEGORIES_VALUES_SUCCESS";
export const GET_SIMILES_SUCCESS = "GET_SIMILES_SUCCESS";
export const GET_CONCEPT_POS_LIST_SUCCESS = "GET_CONCEPT_POS_LIST_SUCCESS";
export const GET_POS_SYNONYMS_SUCCESS = "GET_POS_SYNONYMS_SUCCESS";
export const GET_POS_SELECTED_SYNONYMS_SUCCESS = "GET_POS_SELECTED_SYNONYMS_SUCCESS";
export const GET_POS_COLLOCATIONS_SUCCESS = "GET_POS_COLLOCATIONS_SUCCESS";
export const LIKE_SIMILE_SUCCESS = "LIKE_SIMILE_SUCCESS";
export const UPDATE_POS_SYNONYMS = "UPDATE_POS_SYNONYMS";
export const UPDATE_POS_COLLOCATIONS = "UPDATE_POS_COLLOCATIONS";
export const UPDATE_SELECTED_POS_SYNONYMS = "UPDATE_SELECTED_POS_SYNONYMS";
export const UPDATE_SELECTED_POS_COLLOCATIONS = "UPDATE_SELECTED_POS_COLLOCATIONS";
export const UPDATE_POS = "UPDATE_POS";
export const UPDATE_POS_SUCCESS = "UPDATE_POS_SUCCESS";
export const SET_SELECTED_POS_LOADING = "SET_SELECTED_POS_LOADING";
export const POS_CONTENT_SUCCESS = "POS_CONTENT_SUCCESS";
export const POS_CONTENT_DELETE_SUCCESS = "POS_CONTENT_DELETE_SUCCESS";
export const POS_CONTENT_UPDATE_SUCCESS = "POS_CONTENT_UPDATE_SUCCESS";
export const CREATE_POS_CONTENT_SUCCESS = "CREATE_POS_CONTENT_SUCCESS";
export const SET_POS_OFFSET = "SET_POS_OFFSET";
export const PAGINATION_LOADING = "PAGINATION_LOADING";
export const SET_POS_LOADING_TOOLTIP = "SET_POS_LOADING_TOOLTIP";
export const RELATED_CONCEPTS_SUCCESS = "RELATED_CONCEPTS_SUCCESS";
