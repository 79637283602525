import validator from "validator";
import isEmpty from "lodash/isEmpty";
export default function (loginData) {
    let errors = {};
    if (validator.isEmpty(loginData.email)) {
        errors.email = "*Email is required!";
    }
    if (validator.isEmpty(loginData.password)) {
        errors.password = "*Password is required!"
    }

    return {
        errors,
        isValid: isEmpty(errors),
    }
}