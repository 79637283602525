import React from "react";
import PropTypes from "prop-types";
import ConceptsList from "./conceptsList";

const ConceptSidebar = props => {
  const { concepts, opacity, selectedId, showCount, isKeywords, isSingleBook, user } = props;
  const pageName = window.location.href.split("/")[3];

  return (
    <div
      id="firstColInner"
      style={{
        opacity: opacity ? 0.3 : 1,
        textAlign:
          (pageName === "Authors" ||
            pageName === "authors" ||
            pageName === "author-groups" ||
            pageName === "Author-Groups") &&
          "right",
        marginTop: pageName === "Authors" && "20px"
      }}
    >
      <ConceptsList
        concepts={concepts}
        selectedId={selectedId}
        isSingleBook={isSingleBook}
        showCount={showCount}
        isKeywords={isKeywords}
        user={user}
        conceptAddCallBack={props.conceptAddCallBack}
        conceptRemoveCallBack={props.conceptRemoveCallBack}
        conceptCallBack={props.conceptCallBack}
        isMemePage={props.isMemePage}
        enterMouse={props.enterMouse}
        activeConcepts={props.activeConcepts}
        // leaveMouse={props.leaveMouse}
      />
    </div>
  );
};

ConceptSidebar.propTypes = {
  concepts: PropTypes.array.isRequired,
  showCount: PropTypes.bool,
  selectedId: PropTypes.number,
  opacity: PropTypes.bool,
  isKeywords: PropTypes.bool,
  conceptCallBack: PropTypes.func.isRequired
};

export default React.memo(ConceptSidebar);
