import React, { useState, useEffect } from "react";
import { FacebookProvider, Share, EmbeddedPost } from "react-facebook";
import PropTypes from "prop-types";
import { likePerspective } from "../../actions/navbaractions";
import { useDispatch, useSelector } from "react-redux";

import ShareLink from "react-twitter-share-link";
import * as helpers from "../../helpers/perspectiveHelpers";
import commentImage from "../../images/imageIcons/comment.png";
import context from "../../images/imageIcons/context.svg";
import history from "../../router/history";
import { SITE_DISPLAY_TITLE } from "../../constants/global";
import copy from "../../images/imageIcons/copy.png";
import PerspectiveToolTip from "../utills/PerspectiveBox/tooltip/perspectiveToolTip";
import ReactTooltip from "react-tooltip";
import { BLACK, LIGHT_GREEN, MAIN_WHITE } from "../../constants/colors";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import {
  FacebookShareCount,
  HatenaShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount
} from "react-share";
import Archive from "../utills/PerspectiveBox/ToolButtons/ArchiveButton";
import "./shareButtons.css";
import { Row, Col } from "reactstrap";






const detail = (perspective, title, authorName) => {
  title = title.charAt(0).toUpperCase() + title.slice(1);
  let detail =
    title + " is " + perspective.description + "\n~ " + authorName + "\n" + SITE_DISPLAY_TITLE;
  if (perspective.pronoun) {
    detail =
      perspective.pronoun.charAt(0).toUpperCase() + perspective.pronoun.slice(1) + " " + detail;
  }
  return detail;
};
function clipboardDetail(perspective, title, authorName) {
  title = title.charAt(0).toUpperCase() + title.slice(1);
  let detail =
    title + " is " + perspective.description + " \n~ " + authorName + " \n\n " + SITE_DISPLAY_TITLE;
  if (perspective.pronoun) {
    detail =
      perspective.pronoun.charAt(0).toUpperCase() + perspective.pronoun.slice(1) + " " + detail;
  }
  return detail;
}
const ShareButtons = props => {
  const {
    perspective,
    title,
    authorName,
    setDescription,
    description,
    isSinglePerspective,
    author,
    user,
    active,
    setActive,
    index,
    archiveMode
  } = props;



  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const [check , setCheck]= useState(false)
  const link = helpers.perspectiveLink(perspective.id);
  const tweetLink = "";
  let text = detail(perspective, title, authorName);
  let clipboardText = clipboardDetail(perspective, title, authorName);
  useEffect(() => {
    setDescription(false);
    setCheck(perspective.isLike ? true: false)
  }, [perspective]);
  const handleCopyClick = () => {
    if (!copied) {
      navigator.clipboard.writeText(clipboardText);
      setCopied(true);
      ReactTooltip.rebuild();
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  };
  const isMobile = window.screen.width <= 768;
  const size = isMobile ? 45 : 25;
  const commentClick = () => {
    // old
    // setShowComment(!showComment)
    history.push(`/dictionary/${perspective.Concept.slug}?author=${author.id}`);
  };
  const onLikeClick = async () => {
    // console.log("fffffffff", perspective.isLike)
    let like = {};
    if (!perspective.isLike || undefined) {
      setCheck(true)
      like.type = "increase";
      like.id = perspective.id;
      perspective.isLike = true;
      perspective.loves += 1;
    } else {
      like = {};
      setCheck(false)
      like.type = "decrease";
      like.id = perspective.id;
      perspective.isLike = false;
      perspective.loves -= 1;
    }
    props.onLikeClick(perspective, perspective.index);
    dispatch(likePerspective({like, user}))
     
    
  };

  return (
    <div>
      <div
        style={{
          background: "#ffffff",
          display: "flex",
          height: "54px"
          // justifyContent: "space-between"
          // width: "200%"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              border: "1px solid #e4d3d3",
              borderRadius: "50%",
              height: 35,
              width: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 2
            }}
          >
            <i
              style={{
                color: check ? "#6A1312" : "#E4D3D3",
                fontSize: "20px"
              }}
              className="fas fa-heart heartIcon"
              aria-hidden="true"
              onClick={onLikeClick}
            />
          </div>
          <div
            style={{
              border: "1px solid #e4d3d3",
              borderRadius: "50%",
              height: 35,
              width: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Archive index={index} perspective={perspective} archivePage={archiveMode} />
          </div>
          <div
            style={{
              border: "1px solid #e4d3d3",
              borderRadius: "50%",
              height: 35,
              width: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 2
            }}
          >
            <FacebookShareButton
              url={`https://v1.dictionaryish.com/perspective/${perspective.id}`}
              quote={text}
            >
              <FacebookIcon size={size} round={true} />
            </FacebookShareButton>
          </div>
          <div
            style={{
              border: "1px solid #e4d3d3",
              borderRadius: "50%",
              height: 35,
              width: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 2
            }}
          >
            <ShareLink link={tweetLink} text={text}>
              {link => (
                <a href={link} rel="noopener noreferrer" style={styles.twitter} target="_blank">
                  <TwitterIcon size={size} round={true} />
                </a>
              )}
            </ShareLink>
          </div>

          <div style={{ padding: 0 }}>
            <div className="btn" onClick={handleCopyClick} style={styles.copy}>
              {copied ? (
                <i style={{ color: LIGHT_GREEN }} className="fa fa-check" />
              ) : (
                <i
                  style={{ color: "black", fontSize: "20px", cursor: "pointer" }}
                  class="fas fa-camera"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
          {perspective.longDescription && (
            <div style={{ padding: 0 }}>
              <div style={styles.copy}>
                <i
                  class="fas fa-info"
                  style={{
                    fontSize: "20px",
                    // color: "#5bc0de",
                    cursor: "pointer"
                  }}
                  onClick={() => setDescription(!description)}
                />
              </div>
            </div>
          )}
          <div style={{ padding: 0 }}>
            <div className="btn" style={styles.copy}>
              {!isSinglePerspective && (
                <a
                  href={`/authors/${author.slug}?Concept=${perspective.ConceptId}`}
                  onClick={e => {
                    e.preventDefault();
                  }}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <img
                    alt="fb"
                    className="icon px-2 addedToArchiver"
                    src={commentImage}
                    onClick={commentClick}
                  />
                </a>
              )}
            </div>
          </div>
          <div style={{ padding: 0 }}>
            {user.isAdmin && (
              <PerspectiveToolTip
                perspective={perspective}
                user={user}
                relatedConcepts={props.relatedConcepts}
                nature={global.PERSPECTIVE_EDIT_TYPE}
                active={active}
                setActive={setActive}
              />
            )}
          </div>
        </div>
      </div>
      {/* <div
          className="col-9"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "auto"
            // paddingLeft: "35px"
          }}
        >
          <Row>
            <Col >
              <FacebookShareButton
                url={`https://v1.dictionaryish.com/perspective/${perspective.id}`}
                quote={text}
              >
                <FacebookIcon size={size} round={true} />
              </FacebookShareButton>
            </Col>
            <Col >
              <ShareLink link={tweetLink} text={text}>
                {link => (
                  <a href={link} rel="noopener noreferrer" style={styles.twitter} target="_blank">
                    <TwitterIcon size={size} round={true} />
                  </a>
                )}
              </ShareLink>
            </Col>
            <Col >
              <div className="btn" onClick={handleCopyClick} style={styles.copy}>
                {copied ? <i style={{ color: LIGHT_GREEN }} className="fa fa-check" /> : "Copy"}
              </div>
            </Col>
            {perspective.longDescription && (
              <Col >
                <div className="btn" style={styles.copy}>
                  <img
                    alt="option"
                    className="icon"
                    src={context}
                    onClick={() => setDescription(!description)}
                  />
                </div>
              </Col>
            )}
            <Col >
              <div className="btn" style={styles.copy}>
                {!isSinglePerspective && (
                  <a
                    href={`/authors/${author.slug}?Concept=${perspective.ConceptId}`}
                    onClick={e => {
                      e.preventDefault();
                    }}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <img
                      alt="fb"
                      className="icon px-2 addedToArchiver"
                      src={commentImage}
                      onClick={commentClick}
                    />
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="col-3">
          {user.isAdmin && (
           author <PerspectiveToolTip
              perspective={perspective}
              user={user}
              relatedConcepts={props.relatedConcepts}
              nature={global.PERSPECTIVE_EDIT_TYPE}
            />
          )}
        </div> */}

      {/* <FacebookProvider appId={`${process.env.REACT_APP_FB}`}>
          <Share href={link}>
            {({ handleClick, loading }) => (
              <a href={link} onClick={handleClick} rel="noopener noreferrer" style={styles.twitter} target='_blank'>
              <FacebookIcon size={size} round={true} />
            </a>
            )}
          </Share>
        </FacebookProvider> */}
    </div>
  );
};
ShareButtons.prototype = {
  perspective: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired
};
export default React.memo(ShareButtons);
const styles = {
  facebook: {
    backgroundColor: "transparent",
    border: "none",
    padding: 0
  },
  twitter: {
    display: "inline-block",
    background: "transparent"
  },
  copy: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: MAIN_WHITE,
    border: "1px solid #e4d3d3",
    borderRadius: "100px",
    color: BLACK,
    fontSize: "10px",
    fontWeight: 200,
    width: "35px",
    height: "35px",
    // padding: "6px 0px 0px 0px",
    margin: 2
    // paddingTop: "6px"
  }
  // copy: {
  //   cursor: "pointer",
  //   backgroundColor: MAIN_WHITE,
  //   border: `1px solid ${BLACK}`,
  //   borderRadius: 100,
  //   color: BLACK,
  //   fontSize: 15,
  //   fontWeight: 200,
  //   width: 35
  // }
};
