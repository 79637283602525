import React, { useEffect } from "react";
import MemesPerspectives from "./MemesPerspectives";
import Perspectives from "../../utills/PerspectiveBox";

import * as scroll from "../../../helpers/scrolling";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions/conceptsActions";
import * as memeActions from "../../../actions/memes";
import * as navbarActions from "../../../actions/navbaractions";
import Header from "../../utills/Header/Header";
import { getAllData } from "../../../selectors/index";
import { resizedImageUrl } from "../../utills/common/imageResize";
import MoreBlock from "./MoreBlock";
import {
  SORT_BY_LIKES,
  groupsServerCategory,
  SORT_BY_AUTHORS,
  factCategory
} from "../../../constants/global";
import * as facebook from "../../../helpers/facebook";
import Return from "./return";
import ImageWrapper from "../../utills/Header/imageWrapper";
import $ from "jquery";
import ClustersList from "./list";
import FooterForm from "./addPerspectiveForm";
import MiniHeader from "../../utills/Header/miniHeader";
import * as mobile from "../../../actions/mobile";
import MemeCards from "../../Memes/memeCards";
import { refreshFacebook } from "../../../helpers/facebook";
import { SORT_BY_AUTHOR } from "../../../constants/concepts";
import { getSecondColStyles } from "../../TopNavBar/navbarStyles";
import history from "../../../router/history";

const SecondCol = props => {
  const {
    allData,
    showSearch,
    sortByLikes,
    viewport,
    loading,
    subFilter,
    selectedOption,
    sorting,
    blockPagination,
    factKeywordFilter,
    concepts,
    conceptDrawer,
    relatedConceptDrawer,
    authorDrawer,
    memesMode,
    parentGroup,
    relatedConcepts,
    commentModes,
    columnLoading,
    memes,
    selectedAuthor
  } = props;

  const pageName = window.location.href.split("/")[3];
  const currentConcept = window.location.href.split("/")[4];

  useEffect(() => {
    selectedAuthor === null && window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, subFilter, blockPagination, selectedOption, selectedAuthor]);
  useEffect(() => {
    const element = document.getElementById(viewport);
    if (element) {
      scroll.setIgnoreWindowScroll();
      element.scrollIntoView({ behavior: "auto" });
      scroll.setScroll($(window).scrollTop() - 120);
      props.emptyViewPort();
      scroll.updateScroll();
    }
  }, [viewport]);

  const handleScroll = () => {
    props.setDrawer(false);
    const oldScroll = scroll.handleScrollCallBack();
    if ($(window).scrollTop() >= oldScroll) {
      handleDown();
    }
  };

  const handleDown = () => {
    props.setDrawer(false);

    if (scroll.hasReachedToBottom() && !loading && !subFilter && !blockPagination && !memesMode) {
      props.handleDownPagination();
      // props.fetchAllMemes();
      props.fetchMemes({
        concept: selectedOption.label,
        keyword: "undefined",
        imageMeme: false,
        lock: true,
        toggle: false,
        thirdKey: null,
        urlChange: false,
        pageName: "All"
      });
    }

    if (scroll.hasReachedToBottom() && !loading && subFilter) {
      props.fetchMemes({
        concept: selectedOption.label,
        keyword: subFilter.label,
        imageMeme: false,
        lock: true,
        toggle: false,
        thirdKey: null,
        urlChange: false,
        pageName: "All"
      });
      // const inputValue = {

      // }
      // props.perspectivesByFilter(inputValue);
    }
  };

  const toggleSort = () => {
    scroll.resetScroll();
    props.toggleConceptPagination();
  };

  const filterTitle = () => {
    if (subFilter) {
      let temp = {
        name: subFilter.label,
        type: subFilter.type,
        parentName: selectedOption.value
      };
      if (factKeywordFilter > -1) {
        let factKeyword = concepts.find(x => x.id === factKeywordFilter);
        if (factKeyword) {
          temp.keywordName = factKeyword.name;
        }
      }
      return temp;
    } else return null;
  };

  const filterCallBack = inputValue => {
    props.setSubFilter(inputValue);
    if (inputValue.type === groupsServerCategory) {
      props.conceptDetailsByName(selectedOption.value, sorting, [inputValue.value], true);
    } else {
      //props.perspectivesByFilter(inputValue);
      facebook.refreshFacebook();
    }
  };

  const factCallBack = () => {
    const inputValue = { id: true, type: factCategory, value: factCategory, label: factCategory };
    filterCallBack(inputValue);
  };

  const cancelClick = () => {
    props.removeSubFilter();
    let concept = selectedOption.value;
    concept = concept.split(" ").join("-");
    let pageName = window.location.href.split("/")[3];
    const backPage = `/${pageName}/${concept}`;
    history.push("");
    if (subFilter && subFilter.type === groupsServerCategory) {
      props.conceptDetailsByName(selectedOption.value, sorting, [], true);
    } else {
      props.setConceptSorting(SORT_BY_AUTHORS);
      props.toggleConceptPagination();
      facebook.refreshFacebook();
    }
    props.setMobileMemesMode(false);
    history.push(backPage);
    props.searchConcept();
  };

  const handleConceptClick = () => {
    props.removeSubFilter();

    props.conceptDetailsByName(selectedOption.value, sorting, [], true);
  };

  const handleTriviaClick = () => {
    scroll.resetScroll();
    props.setFactsKeywrodFilterId(-1);
    const temp = { id: true, type: factCategory, value: factCategory, label: factCategory };
    props.setSubFilter(temp);
    //props.perspectivesByFilter(temp);
  };

  const onFactClick = () => {
    scroll.resetScroll();
    if (subFilter) {
      props.removeSubFilter();
      props.setConceptSorting(SORT_BY_AUTHOR);
      props.toggleConceptPagination();
      refreshFacebook();
    } else {
      props.setFactsKeywrodFilterId(-1);
      const temp = { id: true, type: factCategory, value: factCategory, label: factCategory };
      props.setSubFilter(temp);
      //props.perspectivesByFilter(temp);
    }
  };

  const filter = subFilter ? (subFilter.type !== groupsServerCategory ? true : false) : false;
  const showRelatedConcepts = relatedConcepts ? (relatedConcepts.length > 0 ? true : false) : false;
  useEffect(() => {
    props.getRelatedConcepts(selectedOption.id);
  }, []);
  return (
    <div
      id="secondCol"
      className="col-12 col-sm-12 col-md-3 "
      style={getSecondColStyles(columnLoading, subFilter, pageName)}
    >
      {/* {!showSearch && (
        <Header
          sortByLikes={sortByLikes}
          toggleSortRight={toggleSort}
          data={filterTitle()}
          showAll={cancelClick}
          allData={allData}
          filterCallBack={filterCallBack}
          showFilterSearch={true}
          isConceptPage={true}
          setFactKeyword={props.setFactsKeywrodFilterId}
          handleFactsClick={onFactClick}
          active={subFilter ? subFilter.type === factCategory : false}
          hideMemes={true}
        />
      )} */}
      <MiniHeader
        handleKeywordClick={() => props.setDrawer(!conceptDrawer)}
        handleConceptClick={() => props.setRelatedConceptDrawer(!relatedConceptDrawer)}
        handleTriviaClick={handleTriviaClick}
        handleMainClick={cancelClick}
        handleAuthorClick={() => props.setAuthorDrawerOpen(!authorDrawer)}
        handleMemesClick={() => props.setMobileMemesMode(true)}
        title={selectedOption.value}
        showKeywords={true}
        isRelateds={true}
        hideRelateds={true}
        parentGroup={parentGroup}
        hideConcepts={!showRelatedConcepts}
        showComments={true}
        onClick={() => props.setCommentsMode(true)}
      />

      <div id="favouriteSecond" style={{ marginTop: "-10px" }}>
        {/* {!memesMode ? ( */}
        <>
          {!subFilter && (
            <ImageWrapper
              src={resizedImageUrl(selectedOption.pictureLink, 400, 500)}
              showImageButton={true}
            />
          )}
          <Perspectives
            onCountClick={filterCallBack}
            onConceptClick={handleConceptClick}
            onFactClick={() => factCallBack()}
            authorCallBack={props.authorCallBack}
            FooterForm={<FooterForm />}
          />
          {subFilter !== null && (
            <div style={{ marginTop: "15px", marginBottom: "5px" }}>
              <MoreBlock
                memeTypeCount={props.memeTypeCount}
                selectedOption={props.selectedOption}
                subFilter={props.subFilter}
                perspectiveCount={props.perspectiveCount}
                onClick={cancelClick}
                removeSubFilter={props.removeSubFilter}
              />
            </div>
          )}
          {/* {filter && <Return onClick={cancelClick} />} */}
          {(blockPagination || filter) && (
            <>
              <FooterForm />
              <ClustersList />
            </>
          )}
        </>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  showSearch: state.navbar.showSearch,
  allData: getAllData(state),
  sortByLikes: state.concepts.sorting === SORT_BY_LIKES,
  sorting: state.concepts.sorting,
  blockPagination: state.concepts.blockPagination,
  viewport: state.navbar.viewport,
  loading: state.autherCluster.loading,
  subFilter: state.navbar.subFilter,
  selectedOption: state.navbar.selectedOption,
  factKeywordFilter: state.concepts.factKeywordFilter,
  concepts: state.navbar.concepts,
  conceptDrawer: state.navbar.conceptDrawer,
  authorDrawer: state.mobile.authorDrawer,
  memesMode: state.mobile.memesMode,
  commentModes: state.mobile.commentModes,
  relatedConcepts: state.concepts.concepts,
  columnLoading: state.navbar.columnLoading,
  selectedAuthor: state.navbar.selectedAuthor,
  perspectiveCount: state.concepts.perspectiveCount,
  memeTypeCount: state.memes.memeTypeCount
});

const mapDispatchToProps = dispatch => ({
  fetchAllMemes: bindActionCreators(memeActions.fetchAllMemePagination, dispatch),
  fetchMemes: bindActionCreators(memeActions.fetchMemetagMemes, dispatch),
  conceptDetailsByName: bindActionCreators(actions.conceptDetailsByName, dispatch),
  handleDownPagination: bindActionCreators(actions.handleDownPagination, dispatch),
  toggleConceptPagination: bindActionCreators(actions.toggleConceptPagination, dispatch),
  perspectivesByFilter: bindActionCreators(actions.perspectivesByFilter, dispatch),
  setConceptSorting: bindActionCreators(actions.setConceptSorting, dispatch),
  emptyViewPort: bindActionCreators(navbarActions.emptyViewPort, dispatch),
  setSubFilter: bindActionCreators(navbarActions.setSubFilter, dispatch),
  removeSubFilter: bindActionCreators(navbarActions.removeSubFilter, dispatch),
  setFactsKeywrodFilterId: bindActionCreators(actions.setFactsKeywrodFilterId, dispatch),
  setDrawer: bindActionCreators(mobile.setDrawerOpen, dispatch),
  setRelatedConceptDrawer: bindActionCreators(mobile.setRelatedConceptDrawerOpen, dispatch),
  setAuthorDrawerOpen: bindActionCreators(mobile.setAuthorDrawerOpen, dispatch),
  setMobileMemesMode: bindActionCreators(mobile.setMobileMemesMode, dispatch),
  getRelatedConcepts: bindActionCreators(actions.getRelatedConcepts, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SecondCol));
