import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions/memes";
import PropTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import ToolTip from "react-portal-tooltip";
import MemeTools from "./memesTools";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { SITE_TITLE, conceptCategory } from "../../constants/global";
import { LIGHT_BLUE, MAIN_WHITE } from "../../constants/colors";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import history from "../../router/history";
import "./memes.css";
import MemeConcepts from "./MemeConcepts";
import MemeUpload from "./memeUpload";
import { Helmet } from "react-helmet";
import { isArray } from "jquery";

const MemeCard = props => {
  const {
    meme,
    memesFilter,
    selectedOption,
    memesCount,
    user,
    uploadSuccess,
    uploadFail,
    loading
  } = props;  console.log("rendering  meme card");

  const isAdmin = user.isAdmin || false;
  const [currentMeme, setCurrentMeme] = useState({});
  const [citation, setCitation] = useState(false);
  const [active, setActive] = useState(false);
  useEffect(() => {
    setCurrentMeme(meme);
    console.log("meme==========", meme)
  }, [meme]);

  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const onLikeClick = meme => {};

  const toggle = () => {
    setOpen(!open);
  };

  const handleConfirm = () => {
    setOpen(false);
    props.deleteMemes(currentMeme.id);
  };

  return (
    <>
      <div id="memes-wrapper" style={memesFilter ? {} : styles.outerWrapper}>
        <>
          <Helmet>
            <meta property="og:updated_time" content={Date.now()} />
            <meta property="og:image" content={`${currentMeme.url}`} />
            <meta property="og:url" content={`${currentMeme.url}`} />
            <meta property="fb:app_id" content="299894923804469" />
          </Helmet>
        </>

        <div id="memes-inner-wrapper" style={styles.cardWrapper}>
          <div id="memes-image-wrapper" style={styles.imageWrapper}>
            {/* BUY BUTTON */}
            <div>
              {currentMeme.buy && (
                <a href={currentMeme.advertUrl && `${currentMeme.advertUrl}`} target="_blank">
                  <Button color="success" style={{ float: "left", margin: "7px 0 0 10px " }}>
                    Buy
                  </Button>
                </a>
              )}
            </div>
            {/* PRODUCT TITLE */}
            <div>
              {currentMeme.productTitle && (
                <h5 style={{ textAlign: "center", whiteSpace: "pre" }}>
                  {currentMeme.productTitle}
                  {currentMeme.year && `  |  ${currentMeme.year}`}
                  {currentMeme.isAdvert && (
                    <span style={{ float: "right", fontSize: "12px" }}>
                      <a
                        style={{ marginRight: 3, color: "#000000" }}
                        onClick={e => {
                          //e.preventDefault();
                          props.setOffset(0);
                          //history.push(`/wackywares`);
                        }}
                        href="/wackywares"
                      >
                        wacky wares
                      </a>
                    </span>
                  )}
                </h5>
              )}
              {currentMeme.artist && (
                <div style={{ width: "50%", marginLeft: "25%" }}>
                  <h6
                    style={{
                      textAlign: "center",
                      marginTop: "-5px",
                      marginLeft: "-10px",
                      fontWeight: "400"
                    }}
                  >
                    {currentMeme.artist}
                  </h6>
                </div>
              )}
            </div>

            <a href={currentMeme.advertUrl && `${currentMeme.advertUrl}`} target="_blank">
              <LazyLoadImage
                alt={`${SITE_TITLE} meme`}
                effect="blur"
                width="100%"
                height="100%"
                src={currentMeme && currentMeme.url}
                threshold="300"
                wrapperClassName="lazy-memes"
                afterLoad={() => {
                  setLoaded(true);
                }}
              />
            </a>
          </div>
          {currentMeme.description && (
            <div
              style={{
                marginBottom: currentMeme.citation && citation ? "-20px" : "12px",
                padding: "8px"
              }}
            >
              <p style={{ fontSize: "1.8rem", fontFamily: "garamond" }}>
                {currentMeme.description}
              </p>
            </div>
          )}
          {currentMeme.citation && citation && (
            <div style={{ marginBottom: "12px", padding: "8px" }}>
              <p style={{ fontSize: "0.8rem", fontFamily: "garamond" }}>{currentMeme.citation}</p>
            </div>
          )}
          <MemeTools
            meme={currentMeme}
            onLikeClick={onLikeClick}
            setCitation={setCitation}
            citation={citation}
            isAdmin={isAdmin}
            toggle={toggle}
          />
          {/* {isAdmin && <i className="fa fa-trash memes-trash" onClick={toggle} />}
          {currentMeme.citation && (
            <i
              class="fas fa-info-circle"
              style={{ fontSize: "27px", marginLeft: "250px", color: "#5bc0de", cursor: "pointer" }}
              onClick={() => setCitation(!citation)}
            ></i>
          )} */}

          <div style={styles.textWrapper}>
            <span style={styles.text}>
              <b>Submitted by</b>: {currentMeme && currentMeme.User ? currentMeme.User.name : ""}
            </span>
            {/* {selectedOption.category === conceptCategory && (
              <Button
                onClick={() => history.push(history.push(`/Concepts/${selectedOption.name}`))}
                className="badgeTitle"
                style={styles.badge}
              >
                {memesCount}
              </Button>
            )} */}
          </div>
          <MemeConcepts meme={currentMeme} />
          {isAdmin && (
            <i
              className="fa fa-pencil memes-edit"
              id={`addMeme${currentMeme.id}`}
              onClick={() => setActive(true)}
            />
          )}
        </div>
      </div>
      <ToolTip
        active={active}
        position="left"
        arrow="top"
        parent={`#addMeme${currentMeme.id}`}
        group="navbar"
      >
        <OutsideClickHandler onOutsideClick={() => setActive(false)}>
          <div id="addPerspectiveFormAdmin">
            <MemeUpload
              nature="updateMeme"
              user={props.user}
              loading={loading}
              uploadSuccess={uploadSuccess}
              uploadFail={uploadFail}
              updateImageMeme={props.updateImageMeme}
              selectedOption={selectedOption}
              memesPage={true}
              currentMeme={currentMeme}
              setActive={setActive}
              //setAddAdvertise={setAddAdvertise}
              //loadingPage={loadingPage}
            />
          </div>
        </OutsideClickHandler>
      </ToolTip>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm</ModalHeader>
        <ModalBody>Confirm want to delete this Memes????</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirm}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

MemeCard.propTypes = {
  meme: PropTypes.object.isRequired // Meme object
};

const mapDispatchToProps = dispatch => {
  return {
    likeMeme: bindActionCreators(actions.likeMeme, dispatch),
    deleteMemes: bindActionCreators(actions.deleteMeme, dispatch),
    setOffset: bindActionCreators(actions.setOffset, dispatch)
  };
};
const mapStateToProps = state => ({
  memesFilter: state.navbar.memesFilter,
  memesCount: state.memes.count,
  selectedOption: state.navbar.selectedOption,
  user: state.auth.user,
  uploadSuccess: state.memes.uploadSuccess,
  uploadFail: state.memes.uploadFail,
  loading: state.memes.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(MemeCard);

const styles = {
  cardWrapper: {
    border: "1px solid rgb(187, 187, 187)",
    // padding: 10,
    paddingBottom: 0,
    borderRadius: 4,
    boxShadow: "1px 1px 40px #c1c0c0",
    overflow: "hidden",
    marginTop: 10,  
    background: MAIN_WHITE,
    width: "100%"
  },
  imageWrapper: {
    maxHeight: "90%",
    width: "100%",
    overflow: "hidden",
    position: "relative"
  },
  textWrapper: {
    marginTop: "10px",
    marginRight: "40px",
    fontSize: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  text: {
    alignSelf: "flex-end"
  },
  outerWrapper: {
    width: "100%",
    maxHeight: "auto",
    display: "flex",
    justifyContent: "center"
  },
  badge: {
    padding: "4px 3px",
    fontSize: 10,
    background: LIGHT_BLUE,
    borderRadius: "50%",
    alignSelf: "flex-end"
  }
};
