import React, { useEffect, useRef, useState } from "react";
import Search from "../search/search";
import { Button } from "reactstrap";
import "./AddAdvertise.css";

const AddAdvertise = props => {
  const [buy, setBuy] = useState(false);
  const [adUrl, setAdUrl] = useState(false);
  const [error, setError] = useState(null);
  const [tags, setTags] = useState([]);
  const [productTitle, setProductTitle] = useState(null);
  const [year, setYear] = useState(null);
  const [artist, setArtist] = useState(null);
  const [advertUrl, setAdvertUrl] = useState(null);
  const [description, setDescription] = useState(null);
  const [imageLink, setImageLink] = useState(null);
  const [image, setImage] = useState(null);
  const [buyButton, setBuyButton] = useState(false);

  const isAdvert = true;

  const { setAddAdvertise, handleWackyWareSubmit } = props;

  const removeTag = id => {
    tags.filter(tag => tag.id !== id);
  };

  const handleChangeFile = type => event => {
    const nameFile = type === "imageLink" ? event.target.files[0] : null;

    if (type === "imageLink" && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      uploadImage(nameFile);
    }
  };

  const uploadImage = image => {
    var S3 = require("aws-sdk/clients/s3");
    const s3bucket = new S3({
      accessKeyId: `${process.env.REACT_APP_accessKeyId}`,
      secretAccessKey: `${process.env.REACT_APP_secretAccessKey}`,
      Bucket: `${process.env.REACT_APP_AUTHOR_BUCKET}`,
      signatureVersion: "v4",
      ACL: `${process.env.REACT_APP_ACL}`,
      region: `${process.env.REACT_APP_region}`,
      successActionStatus: 201,
      keyPrefix: "images/"
    });

    s3bucket.createBucket(() => {
      const params = {
        Bucket: `${process.env.REACT_APP_AUTHOR_BUCKET}`,
        Key: image.name,
        Body: image,
        ContentType: image.type,
        ACL: `${process.env.REACT_APP_ACL}`,
        region: `${process.env.REACT_APP_region}`,
        successActionStatus: 201,
        keyPrefix: "images/"
      };

      const api = s3bucket.upload(params, (err, data) => {
        if (err) {
          return;
        }
        if (data) {
          setTimeout(() => {
            setImageLink(data.Location);
            if (params.successActionStatus == 201) {
            }
          }, 3000);
        }
      });
    });
  };

  const onSubmitAdvertise = () => {
    if (!imageLink) {
      setError("Select image first!");
    } else {
      const newWackyPage = {
        productTitle,
        year,
        artist,
        buy: buyButton,
        advertUrl,
        tags,
        description,
        isAdvert,
        url: imageLink
      };
      setAddAdvertise(false);
      handleWackyWareSubmit(newWackyPage);
      setProductTitle(null);
      setYear(null);
      setArtist(null);
      setBuy(false);
      setAdvertUrl(null);
      setTags([]);
      setDescription(null);
      setAdUrl(false);
      setImageLink(null);
    }
  };

  return (
    <div>
      {error && (
        <div style={{ margin: "45px 0px 0px 75px", color: "red", fontWeight: "bold" }}>
          <span className="alert alert-danger">
            <i class="fas fa-exclamation-circle"></i> {error}
          </span>
        </div>
      )}
      <h4 className="addNewTitle">Add new Wacky Ware</h4>
      <input
        style={{
          float: "left",
          width: "60%",
          border: "1px solid #b8afae",
          borderRadius: "8px",
          marginTop: "10px",
          height: "30px"
        }}
        placeholder="Product Title"
        onChange={e => setProductTitle(e.target.value)}
      />

      <input
        style={{
          float: "left",
          width: "30%",
          border: "1px solid #b8afae",
          borderRadius: "8px",
          marginLeft: "10%",
          marginTop: "10px",
          height: "30px"
        }}
        placeholder="Year"
        onChange={e => setYear(e.target.value)}
      />
      <input
        style={{
          float: "left",
          clear: "both",
          width: "60%",
          border: "1px solid #b8afae",
          borderRadius: "8px",
          height: "30px",
          marginTop: "10px"
        }}
        placeholder="Artist"
        onChange={e => setArtist(e.target.value)}
      />
      <input
        type="checkbox"
        onChange={() => setAdUrl(!adUrl)}
        style={{
          float: "left",
          clear: "left",
          marginTop: "12px",
          width: "20px",
          height: "20px",
          cursor: "pointer"
        }}
      />
      <p style={{ float: "left", marginTop: "12px", marginLeft: "10px" }}>Link/URL</p>
      {adUrl && (
        <input
          style={{
            float: "left",
            width: "170px",
            marginTop: "10px",
            marginLeft: "10px",
            border: "1px solid #b8afae",
            borderRadius: "5px"
          }}
          placeholder="Add URL"
          onChange={e => setAdvertUrl(e.target.value)}
        />
      )}
      <input
        type="checkbox"
        onChange={() => setBuyButton(!buyButton)}
        style={{
          float: "left",
          marginTop: "12px",
          marginLeft: adUrl ? "20px" : "200px",
          width: "20px",
          height: "20px",
          cursor: "pointer"
        }}
      />
      <p
        style={{
          float: "left",
          marginTop: "8px",
          marginLeft: "10px",
          padding: "1px 5px 4px 5px",
          backgroundColor: buyButton ? "green" : "#333",
          border: buyButton ? "1px solid green" : "1px solid #333",
          borderRadius: "5px",
          color: buyButton ? "white" : "#bbc4c3"
        }}
      >
        Buy
      </p>
      <input
        type="file"
        name="image"
        id="image"
        accept="image/*"
        placeholder="Add Image"
        onChange={handleChangeFile("imageLink")}
        style={{ float: "left", clear: "both", cursor: "pointer" }}
      />
      <p
        style={{
          float: "left",
          clear: "left",
          marginTop: "16px",
          marginLeft: "6px"
        }}
      >
        Tags:
      </p>
      <div
        style={{
          float: "left",
          clear: "right",
          width: "150px",
          marginTop: "12px",
          marginLeft: "10px"
        }}
      >
        <Search
          value={""}
          category="Concept"
          onChange={item => {
            setTags([...tags, item]);
          }}
          placeholder="Add Tag"
        />
      </div>
      <div style={{ float: "left", clear: "both" }}>
        {tags.map(tag => (
          <p style={{ float: "left", marginLeft: "8px" }}>
            {tag.slug}
            <span style={{ color: "red", fontWeight: "bold" }} onClick={() => removeTag(tag.id)}>
              {" "}
              X
            </span>
          </p>
        ))}
      </div>
      <textarea
        style={{
          float: "left",
          width: "90%",
          marginLeft: "5%",
          marginTop: "15px",
          height: "150px"
        }}
        placeholder="Description"
        onChange={e => setDescription(e.target.value)}
      />
      <Button
        type="button"
        color="primary"
        style={{
          float: "right",
          marginTop: "10px"
        }}
        onClick={() => onSubmitAdvertise()}
      >
        Add
      </Button>
      <Button
        type="button"
        color="secondary"
        style={{
          float: "right",
          marginTop: "10px",
          marginRight: "10px"
        }}
        onClick={() => {
          setAddAdvertise(false);
          setTags([]);
        }}
      >
        Cancel
      </Button>
    </div>
  );
};

export default AddAdvertise;
