import axios from "axios";
import { BASE_URL } from "../constants/global";

export const authorDetailByName = name => {
  return axios.post(`${BASE_URL}/author/detail/${name}`, { keywords: true });
};
export const authorRelatedsByName = name => {
  return axios.get(`${BASE_URL}/author/related/detail/${name}`);
};
export const singleBook = id => {
  return axios.get(`${BASE_URL}/books/${id}`);
};
export const authorRelatedsById = id => {
  return axios.get(`${BASE_URL}/author/related/${id}`);
};
export const authorPerspectivesByConceptIds = (authorId, conceptIds) => {
  return axios.post(`${BASE_URL}/author/perspectives/concepts`, { authorId, conceptIds });
};
export const authorPerspectivesByLikes = (authorId, offset, limit) => {
  return axios.post(`${BASE_URL}/author/perspectives/likes`, { authorId, offset, limit });
};
export const addConceptBook = (bookId, conceptId) => {
  return axios.post(`${BASE_URL}/books/add-concept`, { bookId, conceptId });
};

export const removeConceptBook = (bookId, conceptId) => {
  return axios.post(`${BASE_URL}/books/remove-concept`, { bookId, conceptId });
};

export const removeAuthorReciprocal = (author1, author2, token) => {
  return axios.delete(`${BASE_URL}/admin/author/relateds`, {
    data: { author1, author2 },
    headers: { Authorization: token }
  });
};
export const addAuthorReciprocal = (author1, author2, token) => {
  return axios.post(
    `${BASE_URL}/admin/author/relateds`,
    { author1, author2 },
    { headers: { Authorization: token } }
  );
};
export const getRelatedAuthorById = (authorId, token) => {
  return axios.get(`${BASE_URL}/admin/authors/${authorId}`, { headers: { Authorization: token } });
};
export const updateAuthorsPriority = (influencerId, relatedAuthors, token) => {
  return axios.post(
    `${BASE_URL}/admin/priority/${influencerId}`,
    { relatedAuthors },
    { headers: { Authorization: token } }
  );
};
export const resetAuthorsPriority = (authorId, token) => {
  return axios.get(`${BASE_URL}/admin/reset/priority/${authorId}`, {
    headers: { Authorization: token }
  });
};
export const scrapAuthorFromWikiPedia = (firstName, lastName) => {
  return axios.post(`${BASE_URL}/scrap/author`, { firstName, lastName });
};
export function addNewAuthor(token, firstName, lastName) {
  return axios.post(
    `${BASE_URL}/authors`,
    { firstName, lastName },
    { headers: { Authorization: token } }
  );
}

export function getPerspectiveConceptsOfBooks(bookId, authorId, newpageNumber) {
  return axios.post(`${BASE_URL}/books/author/perspective/${bookId}/${authorId}/${newpageNumber}`);
}

export function getPerspectiveConceptsOfRelatedAuthors(bookId, authorId, newpageNumber) {
  return axios.post(
    `${BASE_URL}/books/relatedauthor/perspective/${bookId}/${authorId}/${newpageNumber}`
  );
}
export function getAllAuthorsByAllCharacter(character) {
  return axios.get(`${BASE_URL}/author/all?character=${character}`);
}
// here is the function of urlHit to get the Authors by group name.

export function getAuthorsByGroupNameHandler(groupId, otherGroupId) {
  return axios.post(`${BASE_URL}/author/group-name`, { firstId: groupId, secondId: otherGroupId });
}
// in the next api we have to parameters which have previous an next group name.
