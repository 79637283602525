import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import archiveImage from "../../../../images/imageIcons/whiteArchieve.png";
import alreadyArchiveImage from "../../../../images/imageIcons/alreadArchive.png";
import add from "../../../../images/imageIcons/add.png";
import ToolTip from "react-portal-tooltip";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { emailNotVerified, openModal } from "../../../../actions/navbaractions";
import { ARCHIVE_MODE } from "../../../../constants/navbar";
import * as actions from "../../../../actions/archives";
import { getArchiveName } from "../../../../helpers/archives";
import ReactTooltip from "react-tooltip";
import "./ToolButtons.css";
import $ from "jquery";
import { toast } from 'react-toastify';



const Archive = props => {
  const notify = () => toast("Successfully removed from Archive!");
  const [active, setActive] = useState(false);
  const [showList, setShowList] = useState(true);
  const [name, setName] = useState("");
  const [delay, setDelay] = useState("");
  const [listen, setListen] = useState("");
  const [down, setDown] = useState(false);
  const [longPress, setLongPress] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [opened, setOpened] = useState(true);
  const [archives, setArchives] = useState(true);
  const [filteredArchives, setFilteredArchives] = useState([]);
  const [selectedArchive, setSelectedArchive] = useState([]);

  const ref = useRef();

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const sub = window.location.href.split("/")[3];
  const tooltipPosition = () => {
    const key = window.location.href.split("/")[3].toLowerCase();
    switch (key) {
      case "memes":
        return "40%";
      case "dictionary":
        return "60%";
      case "authors":
        return "60%";
      default:
        return "60%";
    }
  };

  const {
    meme,
    lastSelectedArchive,
    index,
    memeIds,
    archivePage,
    archiveList,
    auth,
    user,
    primaryMode
  } = props;

  let alreadyArchived = false;
  let archive = {};
  if (memeIds)
    if (memeIds) {
      let temp = memeIds.find(x => x.memeId === meme.id);
      if (temp) {
        alreadyArchived = true;
        archive = archiveList.find(x => x.id === temp.archiveId);
      } else {
        alreadyArchived = false;
        archive = {};
      }
    }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Hiding ToolTip on mobile
    // if (active) {
    //     $(".ToolTipPortal")
    //         .children()
    //         .css({  });
    // }
    // else{

    //     $(".ToolTipPortal")
    //         .children()
    //         .css({});
    // }
    if (window.screen.width <= 768 && showList) {
      let opacity = 0;
      if (active) {
        opacity = 1;
      }
      $(".ToolTipPortal").css({
        opacity
      });
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [active]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setArchives(archiveList);
      setFilteredArchives(archiveList);
      if (auth && auth.defaultArchive) {
        setSelectedArchive(auth.defaultArchive);
      } else {
        if (archiveList && archiveList.length > 0) setSelectedArchive(archiveList[0].id);
      }
    }
  }, [archiveList, auth]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [alreadyArchived]);

  const handleScroll = () => {
    if (active) {
      setActive(false);
      setLongPress(false);
      setShowList(true);
    }
  };
  const handleMouseEnter = () => {
    if (active) {
      clearTimeout(delay);
    }
  };
  const handleMouseLeave = () => {
    if (active) {
      setDelay(
        setTimeout(() => {
          setLongPress(false);
          setActive(false);
          setShowList(true);
        }, 200)
      );
    }
  };
  const onChange = e => {
    setName(e.target.value);
  };

  const archiveMouseDown = () => {
    if (auth.isAuthenticated) {
      if (!active) {
        setListen(
          setTimeout(() => {
            handleLongMouseClick();
          }, 200)
        );
        setDown(true);
      } else {
        setLongPress(false);
        setActive(false);
        setShowList(true);
      }
    } else {
      props.openModal();
    }
  };
  const archiveMouseUp = () => {
    if (down) {
      handleShortMouseClick();
    }
  };
  const handleShortMouseClick = () => {
    handleMouseUp();
    let id = selectedArchive ? selectedArchive : archiveList[0].id;
    props.addMemeToArchive(meme.id, id);
  };
  const handleLongMouseClick = () => {
    setActive(true);
    setShowList(true);
    setLongPress(true);
    setDown(false);
  };
  const handleMouseUp = () => {
    clearTimeout(listen);
    setDown(false);
  };
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      if (ref.current) {
        setShowList(true);
        setActive(true);
        props.createArchive(ref.current.value, props.auth.user.id);
        ref.current.blur();
      }
    }
  };
  const onCustomArchiveClick = (perspectiveId, archiveId) => {
    setLongPress(false);
    setShowList(true);
    setSelectedArchive(archiveId);
    props.addMemeToArchive(perspectiveId, archiveId);
    setActive(false);
    setOpened(false);
  };

  const onArchiveClick = () => {
    if (!archivePage) {
      if (auth && auth.isAuthenticated) {
        if (!auth.user.isPremium) {
          let id = selectedArchive ? selectedArchive : archiveList[0].id;
          props.addMemeToArchive(meme.id, id);
        } else {
          setActive(!active);
          setShowList(true);
          if (!showList) {
            setShowList(true);
          }
        }
      } else {
        props.openModal();
      }
    } else {
      const { archives_memes } = meme;
      if (archives_memes) {
        const archiveId = archives_memes.ArchiveId;
        const res= props.deleteMemeFromArchive(meme.id, archiveId, auth.user.token, true);
        toast("Succesfully removed from Archived!");
        console.log("res=>>>", res)
      }
    }
  };
  const removeFromArchive = (perspectiveId, archiveId) => {
    props.deleteMemeFromArchive(perspectiveId, archiveId, auth.user.token, false);
  };
  const handleNewClick = () => {
    setName("");
    setShowList(false);
    setActive(true);
  };

  const handleCancelClick = e => {
    setShowList(true);
    setActive(true);
  };

  const handleOkClick = e => {
    if (ref.current && ref.current.value) {
      setActive(false);
      setActive(true);
      const valueIsExist = archiveList.includes(name);
      props.createArchive(ref.current.value, props.auth.user.id);
    }
  };

  const filterArchives = e => {
    const keyword = e.target.value;
    const filteredArchives = archiveList
      .filter(o => o.name.toLowerCase().includes(keyword.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort(
        (a, b) =>
          a.name.toLowerCase().indexOf(keyword.toLowerCase()) -
          b.name.toLowerCase().indexOf(keyword.toLowerCase())
      );
    setFilteredArchives(filteredArchives);
  };

  let style = {
    style: {
      padding: 0
    },
    arrowStyle: {}
  };

  return (
    <div
      data-tip={
        alreadyArchived
          ? `<div style='text-align: center'><p style='margin: 0'>Archived in:</p> <div style='text-align: center;width:100%;'><h6>${getArchiveName(
              archive.name,
              user
            )}</h6></div>`
          : null
      }
      data-html={true}
      style={{ background: longPress ? "orange" : "" }}
    >
      {!archivePage && !alreadyArchived && (
        <i
          className="far fa-bookmark"
          id={"text" + index}
          style={{ cursor: "pointer" }}
          // src={archiveImage}
          // alt="Archive Image"
          onTouchStart={archiveMouseDown}
          onTouchEnd={archiveMouseUp}
          onMouseUp={archiveMouseUp}
          onMouseDown={archiveMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        />
      )}
      {!archivePage && alreadyArchived && (
        <i
          perspective-toggle="tooltip"
          data-html={true}
          onClick={() => removeFromArchive(meme.id, archiveList[0].id)}
          className="far fa-bookmark"
          id={"text" + index}
          // src={alreadyArchiveImage}
          // alt="Already archive image"
          style={{ opacity: 1, cursor: "pointer" }}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        />
      )}
      {archivePage && (
        <i
          data-toggle="tooltip"
          title="Delete from Archive"
          className="far fa-bookmark pangay"
          id={"text" + index}
          style={{ cursor: "pointer" }}
          onClick={onArchiveClick}
          // src={archiveImage}
          // alt="Archive image"
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        />
      )}
      <ToolTip
        active={active}
        position="right"
        arrow="center"
        align="center"
        parent={"#text" + index}
        style={style}
      >
        {
          <div
            className="toolTipBorder"
            style={{ position: "fixed", overflowY: "scroll", left: tooltipPosition() }}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
          >
            {showList && (
              <ul className="list-group">
                <input
                  placeholder="filter archives"
                  style={styles.filterInput}
                  onChange={filterArchives}
                />
                {filteredArchives.map(archive => {
                  return (
                    <li
                      className="list-group-item"
                      style={styles.listItem}
                      id="archives"
                      key={archive.id}
                      onClick={() => {
                        onCustomArchiveClick(meme.id, archive.id);
                      }}
                    >
                      <div>
                        <i
                          className="icon px-2"
                          id={"text" + index}
                          // src={archiveImage}
                          // alt="Archive image"
                        />
                        <span style={{ color: selectedArchive == archive.id ? "green" : "black" }}>
                          {getArchiveName(archive.name, user)}
                        </span>
                      </div>
                    </li>
                  );
                })}
                {auth && auth.user.isPremium && (
                  <li id="archives" className="list-group-item" onClick={handleNewClick}>
                    <img className="icon px-2 " style={{ height: 19 }} src={add} alt="meme icon" />
                    new archive
                  </li>
                )}
              </ul>
            )}
            {!showList && (
              <div style={styles.inputWrapper}>
                <input
                  type="text"
                  onKeyDown={handleKeyDown}
                  onChange={onChange}
                  placeholder="Enter here"
                  style={styles.input}
                  ref={ref}
                />
                <i className="fa fa-check" style={styles.inputTools} onClick={handleOkClick} />
                <i className="fa fa-remove" style={styles.inputTools} onClick={handleCancelClick} />
              </div>
            )}
          </div>
        }
      </ToolTip>
    </div>
  );
};

Archive.prototype = {
  perspective: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    createArchive: bindActionCreators(actions.createArchive, dispatch),
    addMemeToArchive: bindActionCreators(actions.addMemeToArchive, dispatch),
    deleteMemeFromArchive: bindActionCreators(actions.deleteMemeFromArchive, dispatch),
    openModal: bindActionCreators(openModal, dispatch)
  };
};
const mapStateToProps = state => {
  return {
    archives: state.archives,
    archiveList: state.archives.archiveList,
    lastSelectedArchive: state.archives.lastSelectedArchive,
    memeIds: state.archives.memeIds,
    navbar: state.navbar,
    auth: state.auth,
    user: state.auth.user,
    archivePage: state.navbar.mode === ARCHIVE_MODE,
    primaryMode: state.navbar.primaryMode
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Archive);

const styles = {
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  inputWrapper: {
    width: 213,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 3
  },
  input: {
    width: "80%"
  },
  filterInput: {
    height: 30,
    borderRadius: 8,
    margin: 4,
    borderWidth: 1
  },
  inputTools: {
    fontSize: 22,
    cursor: "pointer"
  }
};
