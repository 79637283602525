import React, { useEffect } from "react";
import history from "../../router/history";

const MemeToolTipDiv = props => {
  let { memeTags } = props;

  const arrOfRemovingMemeTags = [62, 68, 77, 88, 129, 130, 131];
  memeTags = memeTags && memeTags.sort((a, b) => a.name.localeCompare(b.name));
  let nonLexicons = memeTags && memeTags.filter(elm => elm.Lexicon == false);
  nonLexicons = nonLexicons && nonLexicons.filter(elm => !arrOfRemovingMemeTags.includes(elm.id));
  nonLexicons = nonLexicons && nonLexicons.sort((a, b) => a.name.localeCompare(b.name));
  let lexicons = memeTags && memeTags.filter(elm => elm.Lexicon == true);
  lexicons = lexicons && lexicons.sort((a, b) => a.name.localeCompare(b.name));
  const pageName = window.location.href.split("/")[3];

  const handleLexiconClick = lexicon => {
    lexicon = lexicon.split(" ").join("-");
    history.push(`/QL/${lexicon}`);
  };

  const getMiniGroupsOptions = () => {
    return (
      nonLexicons &&
      nonLexicons.map(concept => (
        <div className="miniSetToolTip">
          <p
            style={{ cursor: "pointer" }}
            className="miniGroupP hover-grey"
            onClick={() =>
              history.push(
                `/QL/${concept.name
                  .split(" - ")[0]
                  .split(" ")
                  .join("-")}`
              )
            }
          >
            {concept.name.split(" - ")[0]}
          </p>
        </div>
      ))
    );
  };
  return (
    <div
      style={{
        maxHeight: "500px",
        overflow: "scroll",
        border: "1px solid black"
      }}
    >
      <div
        style={{
          textAlign: "center",
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
          paddingTop: 10,
          paddingBottom: 10
        }}
      >
        <a href="/" style={{ display: "none", textDecoration: "none" }}>
          <span
            //onClick={() => history.push(`/`)}
            className="toolTipSecondDiv"
          >
            MAIN
          </span>
        </a>
      </div>
      <div
        style={{
          width: "100%",
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
          paddingTop: 10,
          paddingBottom: 10,
          display: "flex",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        {/* {pageName === "proverbs" ? (
          <a href="/virals" style={{ textDecoration: "none" }}>
            <p id="viralToolTip" style={{ color: "#6666ff" }} className="toolTipSecondDiv">
              VIRALS
            </p>
          </a>
        ) : (
          <p
            onClick={() => history.push(`/virals`)}
            id="viralToolTip"
            style={{ color: "#6666ff" }}
            className="toolTipSecondDiv"
          >
            VIRALS
          </p>
        )} */}
        <p
          onClick={() => history.push(`/concepts`)}
          style={{
            margin: "2px",
            color: "#000000",
            fontWeight: "bolder",
            fontSize: 22,
            border: "3px solid black",
            borderRadius: "8px",
            padding: "0 8px"
          }}
          className="toolTipSecondDiv"
        >
          ALL
        </p>
        <p
          onClick={() => history.push(`/definitions`)}
          style={{ margin: "2px", color: "#5e5f5f" }}
          className="toolTipSecondDiv"
        >
          DEFINITIONS
        </p>

        <p
          onClick={() => history.push(`/authors`)}
          style={{ margin: "2px", color: "#ed260d" }}
          className="toolTipSecondDiv"
        >
          THINKERS
        </p>

        {pageName === "proverbs" ? (
          <a href="/meme" style={{ textDecoration: "none" }}>
            <p style={{ margin: "2px", color: "#c7c313" }} className="toolTipSecondDiv">
              MEME
            </p>
          </a>
        ) : (
          <p
            onClick={() => history.push(`/meme`)}
            style={{ margin: "2px", color: "#c7c313" }}
            className="toolTipSecondDiv"
          >
            MEME
          </p>
        )}

        {pageName === "proverbs" ? (
          <a href="/trivia" style={{ textDecoration: "none" }}>
            <p style={{ margin: "2px", color: "#288133" }} className="toolTipSecondDiv">
              TRIVIA
            </p>
          </a>
        ) : (
          <p
            onClick={() => history.push(`/trivia`)}
            style={{ margin: "2px", color: "#288133" }}
            className="toolTipSecondDiv"
          >
            TRIVIA
          </p>
        )}

        {pageName === "proverbs" ? (
          <a href="/one-liner" style={{ textDecoration: "none" }}>
            <p style={{ margin: "2px", color: "#C78210" }} className="toolTipSecondDiv">
              ONE LINERS
            </p>
          </a>
        ) : (
          <p
            onClick={() => history.push(`/one-liner`)}
            style={{ margin: "2px", color: "#C78210" }}
            className="toolTipSecondDiv"
          >
            ONE LINERS
          </p>
        )}

        {pageName === "proverbs" ? (
          <a href="/dad-joke" style={{ textDecoration: "none" }}>
            <p
              //onClick={() => history.push(`/dad-joke`)}
              style={{ margin: "2px", color: "#D3665B" }}
              className="toolTipSecondDiv"
            >
              DAD JOKES
            </p>
          </a>
        ) : (
          <p
            onClick={() => history.push(`/dad-joke`)}
            style={{ margin: "2px", color: "#D3665B" }}
            className="toolTipSecondDiv"
          >
            DAD JOKES
          </p>
        )}

        <a href="/proverbs" style={{ textDecoration: "none" }}>
          <p
            //onClick={() => history.push(`/proverbs`)}
            style={{ margin: "2px", color: "#486bb5" }}
            className="toolTipSecondDiv"
          >
            PROVERBS
          </p>
        </a>
        <p
          onClick={() => history.push(`/books`)}
          style={{ margin: "2px", color: "#054f81" }}
          className="toolTipSecondDiv"
        >
          BOOKS
        </p>
      </div>
      {/* <div
        style={{
          width: "100%",
          height: "150px",
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
          paddingTop: 10,
          paddingBottom: 10
        }}
      >
        <div style={{ width: "50%", textAlign: "center", float: "left" }}>
          {pageName === "proverbs" ? (
            <a href="/virals" style={{ textDecoration: "none" }}>
              <p id="viralToolTip" style={{ color: "#6666ff" }} className="toolTipSecondDiv">
                VIRALS
              </p>
            </a>
          ) : (
            <p
              onClick={() => history.push(`/virals`)}
              id="viralToolTip"
              style={{ color: "#6666ff" }}
              className="toolTipSecondDiv"
            >
              VIRALS
            </p>
          )}

          <p
            onClick={() => history.push(`/definitions`)}
            style={{ color: "#000000" }}
            className="toolTipSecondDiv"
          >
            DEFINITIONS
          </p>
          <p
            onClick={() => history.push(`/books`)}
            style={{ color: "#000000" }}
            className="toolTipSecondDiv"
          >
            BOOKS
          </p>
        </div>
        <div style={{ width: "50%", textAlign: "center", float: "right" }}>
          {pageName === "proverbs" ? (
            <a href="/trivia" style={{ textDecoration: "none" }}>
              <p style={{ margin: "2px", color: "#288133" }} className="toolTipSecondDiv">
                TRIVIA
              </p>
            </a>
          ) : (
            <p
              onClick={() => history.push(`/trivia`)}
              style={{ margin: "2px", color: "#288133" }}
              className="toolTipSecondDiv"
            >
              TRIVIA
            </p>
          )}

          {pageName === "proverbs" ? (
            <a href="/dad-joke" style={{ textDecoration: "none" }}>
              <p
                //onClick={() => history.push(`/dad-joke`)}
                style={{ margin: "2px", color: "#D3665B" }}
                className="toolTipSecondDiv"
              >
                DAD JOKES
              </p>
            </a>
          ) : (
            <p
              onClick={() => history.push(`/dad-joke`)}
              style={{ margin: "2px", color: "#D3665B" }}
              className="toolTipSecondDiv"
            >
              DAD JOKES
            </p>
          )}

          {pageName === "proverbs" ? (
            <a href="/one-liner" style={{ textDecoration: "none" }}>
              <p style={{ margin: "2px", color: "#C78210" }} className="toolTipSecondDiv">
                ONE LINERS
              </p>
            </a>
          ) : (
            <p
              onClick={() => history.push(`/one-liner`)}
              style={{ margin: "2px", color: "#C78210" }}
              className="toolTipSecondDiv"
            >
              ONE LINERS
            </p>
          )}
          {pageName === "proverbs" ? (
            <a href="/meme" style={{ textDecoration: "none" }}>
              <p style={{ margin: "2px", color: "#c7c313" }} className="toolTipSecondDiv">
                MEME
              </p>
            </a>
          ) : (
            <p
              onClick={() => history.push(`/meme`)}
              style={{ margin: "2px", color: "#c7c313" }}
              className="toolTipSecondDiv"
            >
              MEME
            </p>
          )}

          <a href="/proverbs" style={{ textDecoration: "none" }}>
            <p
              //onClick={() => history.push(`/proverbs`)}
              style={{ margin: "2px", color: "#486bb5" }}
              className="toolTipSecondDiv"
            >
              PROVERBS
            </p>
          </a>
        </div>
      </div> */}
      <div
        style={{ textAlign: "center", width: "100%", borderTop: "1px solid black", paddingTop: 10 }}
      >
        <span style={{ fontWeight: "600" }}>news</span>
        <div
          style={{
            width: 42,
            height: 42,
            border: "1px solid #929292",
            borderRadius: 46,
            cursor: "pointer",
            marginLeft: "43%",
            marginBottom: "15px"
          }}
        >
          <i
            className="fa fa-newspaper icon "
            style={{ fontSize: "25px", marginTop: "8px", color: "#929292" }}
            onClick={() => history.push(`/news`)}
          ></i>
        </div>
        <div
          style={{
            textAlign: "center",
            width: "100%",
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            paddingTop: 10
          }}
        >
          <p style={{ fontWeight: "600" }}>Lexicons</p>
          {lexicons &&
            lexicons.map(elm => {
              return (
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLexiconClick(elm.name.split(" - ")[0])}
                >
                  {elm.name.split(" - ")[0]}
                </p>
              );
            })}
          {/* <p style={{ cursor: "pointer" }} onClick={() => history.push(`/QL/acronym`)}>
            Acronym
          </p>
          <p style={{ cursor: "pointer" }} onClick={() => history.push(`/QL/comeback`)}>
            Comeback
          </p>
          <p style={{ cursor: "pointer" }} onClick={() => history.push(`/QL/etymology`)}>
            Etymology
          </p>
          <p style={{ cursor: "pointer" }} onClick={() => history.push(`/QL/ology-(the-study-of)`)}>
            Ology (the study of)
          </p>
          <p style={{ cursor: "pointer" }} onClick={() => history.push(`/QL/phobia`)}>
            Phobia
          </p>
          <p style={{ cursor: "pointer" }} onClick={() => history.push(`/QL/untranslatable-word`)}>
            Untranslatable word
          </p> */}
        </div>

        <span style={{ fontWeight: "600" }}>MINI SETS</span>

        <div style={{ width: "300px" }}>{getMiniGroupsOptions()}</div>
      </div>
    </div>
  );
};

export default MemeToolTipDiv;
