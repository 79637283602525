import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import queryString from "query-string";
import Loader from "react-loader-spinner";
// import * as global from '../../constants/global'
import ToolTip from "react-portal-tooltip";
import { Button } from "reactstrap";
import $ from "jquery";
import "./imageUpload.css";

const getColor = props => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  margin-top: 10px;
  transition: border 0.24s ease-in-out;
  min-height: 300px;
`;

const MemesUpload = props => {
  const { uploadSuccess, uploadFail, oldSrc } = props;
  const [image, setImage] = useState();
  const [path, setPath] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [active, setActive] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    const image = acceptedFiles[0];
    setImage(image);
    setPath(URL.createObjectURL(image));
    setSuccessMessage(false);
    setErrorMessage(false);
  }, []);

  useEffect(() => {
    if (active) {
      $(document).on("scroll", () => {
        setActive(false);
      });
    } else {
      $(document).off("click");
      $(document).off("scroll");
    }
    return () => {
      $(document).off("click");
      $(document).off("scroll");
    };
  }, [active]);

  useEffect(() => {
    if (loading && !props.loading) {
      if (uploadSuccess) {
        setImage("");
        setPath("");
        setSuccessMessage(true);
        setTimeout(() => {
          setActive(false);
        }, 1000);
      } else {
        setErrorMessage(true);
      }
    }
    setLoading(props.loading);
  }, [props.loading]);

  const onClick = async () => {
    const formData = new FormData();
    formData.append("file", image);
    props.onSubmit(formData);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: "image/*",
    onDrop,
    noDrag: true,
    preventDropOnDocument: false,
    noClick: loading
  });

  return (
    <div id="image-upload-wrapper" style={styles.getStyles(oldSrc)}>
      {oldSrc ? (
        <i
          className="fa fa-pencil image-edit mobile-edit"
          id="adminImageUpdate"
          onClick={() => setActive(!active)}
        />
      ) : (
        <Button onClick={() => setActive(!active)} color="primary" id="adminImageUpdate">
          Update
        </Button>
      )}
      <ToolTip active={active} position="bottom" arrow="center" parent="#adminImageUpdate">
        <div id="image-update" className="container pt-1">
          <div style={styles.messageWrappper}>
            {successMessage && (
              <span className="alert alert-success">Meme submitted for approval</span>
            )}
            {errorMessage && <span className="alert alert-danger">Error uploading Meme</span>}
          </div>
          <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            <p className="mb-0">Click to select you meme</p>
            <img src={path} style={styles.image} alt="admin image" />
            <Button color="primary" className="mt-1" disabled={loading}>
              select
            </Button>
          </Container>
          <button
            type="button"
            disabled={!image || loading}
            style={styles.btn}
            onClick={onClick}
            className="btn btn-success"
          >
            {loading ? (
              <Loader type="Puff" color="#FFF" height="20" width="20" />
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </ToolTip>
    </div>
  );
};

MemesUpload.propTypes = {
  loading: PropTypes.bool.isRequired,
  uploadSuccess: PropTypes.bool.isRequired,
  // uploadFail: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
  // oldSrc: PropTypes.string.isRequired
};

export default MemesUpload;

const styles = {
  submit: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  messageWrappper: {
    margin: "15px 0px"
  },
  image: {
    width: 250
  },
  btn: {
    width: "100%",
    margin: "20px 0px"
  },
  getStyles: oldSrc => {
    let values = oldSrc
      ? {}
      : {
          position: "inherit",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          height: 50
        };
    return values;
  }
};
