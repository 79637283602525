import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import RelatedTabConcepts from "./relatedTabConcepts";
import { bindActionCreators } from "redux";
import ConceptSidebar from "../../utills/conceptsSideBar";
import _ from "lodash";

import * as scroll from "../../../helpers/scrolling";
import {
  SORT_BY_LIKES,
  keywordCategory,
  factCategory,
  conceptCategory
} from "../../../constants/global";
import * as actions from "../../../actions/conceptsActions";
import * as navbarActions from "../../../actions/navbaractions";
import * as memeActions from "../../../actions/memes";
import * as conceptClusterActions from "../../../actions/conceptClustersActions";
import RelatedConcepts from "./relatedConcepts";
import { mapConceptKeywords } from "../../../selectors";
import "./firstCol.css";
import Drawer from "../../utills/drawer/drawer";
import { setDrawerOpen, setRelatedConceptDrawerOpen } from "../../../actions/mobile";
import SwitchConceptModes from "../../utills/Buttons/switchConceptModes";
import $ from "jquery";
import { listenToDocumentSizeChangeOnPercepectiveClick } from "../../../helpers/navbar";

const DefinitionPage = props => {
  const {
    keywords,
    concepts,
    viralKeywords,
    sorting,
    subFilter,
    selectedOption,
    loading,
    lock
  } = props;

  const [pathname, setPathname] = useState("");

  const isAuthorPage = window.location.href.split("/")[5];

  const currentConcept = id => {
    return concepts.find(e => e.id === Number(id));
  };
  //   window.onpopstate = e => {
  //     if (window.location && window.location.href.split("=")[1]) {
  //       const concept = currentConcept(window.location.href.split("=")[1]);
  //       if (concept) conceptCallBack(-1, concept, true);
  //     } else if (window.location.pathname === pathname && !window.location.href.split("=")[1]) {
  //       history.goBack();
  //     }
  //   };
  useEffect(() => {
    if (window.location.pathname) {
      setPathname(window.location.pathname);
    }
  }, [window.location.pathname]);
  const pageName = window.location.href.split("/")[3];

  const getKeywords = () => {
    if (pageName === "Concepts") {
      let concept = [];

      keywords &&
        keywords.forEach(e => {
          viralKeywords.forEach(elm => {
            if (e.slug === elm.slug) {
              concept.push({ id: e.id, slug: e.slug, name: e.name, count: e.count + elm.count });
            }
          });
        });

      keywords &&
        keywords.forEach(e => {
          if (concept.some(elm => e.id === elm.id)) {
            return;
          } else {
            concept.push({ id: e.id, slug: e.slug, name: e.name, count: e.count });
          }
        });

      viralKeywords &&
        viralKeywords.forEach(elm => {
          if (concept.some(e => e.id === elm.id)) {
            return;
          } else {
            concept.push({ id: elm.id, slug: elm.slug, name: elm.name, count: elm.count });
          }
        });
      return concept.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return keywords;
    }
  };

  const conceptCallBack = (index, concept, popupRoute = false) => {
    if (subFilter && subFilter.type === factCategory) {
      props.setFactsKeywrodFilterId(concept.id);
    } else {
      scroll.resetSecondCol();
      const inputValue = {
        id: concept.id,
        value: concept.name,
        label: concept.name,
        slug: concept.name,
        type: keywordCategory
      };

      props.setSubFilter(inputValue, popupRoute);
      props.perspectivesByFilter(inputValue);
      props.setOffset(0);
      pageName === "Concepts" &&
        props.fetchMemes({
          concept: selectedOption.label,
          keyword: concept.slug,
          imageMeme: false,
          lock: true,
          toggle: false,
          thirdKey: null,
          urlChange: false
        });
      listenToDocumentSizeChangeOnPercepectiveClick();
    }
  };

  let selectedId = subFilter ? (subFilter.type === keywordCategory ? subFilter.id : -1) : -1;
  // const [activeTabe, setActiveTabe] = useState("concepts");

  // const handleTabSelect = key => {
  //   if (activeTabe == "concepts") {
  //     props.relatedConcepts(selectedOption.id);
  //     setActiveTabe("tags");
  //   } else {
  //     setActiveTabe("concepts");
  //   }
  // };
  return (
    <div className="row" style={{}}>
      {loading ? (
        <div style={{ color: "red", opacity: 0.7 }}>
          <Loader type="Oval" color="#4267B2" height="30" width="30" />
        </div>
      ) : (
        <div className="col-12" style={{ marginTop: pageName === "dictionary" ? "35px" : "15px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            {!isAuthorPage && (
              <div
                style={{
                  cursor: "pointer",
                  position: "fixed",
                  zIndex: 8
                }}
              >
                {lock ? (
                  <i
                    className="fa fa-lock fa-3x"
                    aria-hidden="true"
                    onClick={() => props.lockIcon(false)}
                  ></i>
                ) : (
                  <i
                    className="fa fa-unlock fa-3x"
                    aria-hidden="true"
                    onClick={() => props.lockIcon(true)}
                  ></i>
                )}
              </div>
            )}
          </div>
          <div style={{ width: "100%" }}>
            <div className="row" style={{ marginTop: "35px" }}>
              <div style={{ width: "95%" }}>
                <RelatedTabConcepts concepts={concepts} />
              </div>
              {/* <div
                style={{
                  width: "40%",
                  marginTop: "60px",
                  border: "2px solid black",
                  borderRadius: "5px",
                  backgroundColor: "white"
                }}
              >
                <ConceptSidebar
                  concepts={getKeywords()}
                  conceptCallBack={conceptCallBack}
                  opacity={sorting === SORT_BY_LIKES}
                  showCount={true}
                  selectedId={selectedId}
                  isKeywords={true}
                />
              </div> */}
            </div>
          </div>
        </div>
      )}

      {/* <div class="btn-group d-flex" onClick={handleTabSelect}>
        <button
          type="button"
          className={
            activeTabe === "concepts"
              ? `btn bg-primary border text-white btn-sm rounded mr-2`
              : `btn border text-dark btn-sm rounded mr-2`
          }
        >
          concepts
        </button>
        <button
          type="button"
          className={
            activeTabe === "tags"
              ? `btn bg-primary border text-white btn-sm rounded`
              : `btn border text-dark btn-sm rounded`
          }
        >
          tags
        </button>
      </div> */}
      {/* {activeTabe === "tags" ? (
        <i
          className="fa fa-sort cursor-pointer"
          style={styles.icon}
          onClick={() => {
            _.shuffle(keywords);
            props.toggleKeywordsSort();
          }}
        />
      ) : (
        ""
      )} */}
      {/* {!selectedOption.author && <SwitchConceptModes lock={lock} />} */}

      <div className="row concept-tabs">
        {/* {
          <>
            <br />
            <ConceptSidebar
              concepts={getKeywords()}
              conceptCallBack={conceptCallBack}
              opacity={sorting === SORT_BY_LIKES}
              showCount={true}
              selectedId={selectedId}
              isKeywords={true}
            />
          </>
        } */}
        {/* {loading ? (
          <div style={{ color: "red", opacity: 0.7 }}>
            <Loader type="Oval" color="#4267B2" height="30" width="30" />
          </div>
        ) : (
          <RelatedTabConcepts concepts={concepts} />
        )} */}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    //concepts: mapConceptKeywords(state),
    concepts: state.navbar.concepts,
    keywords: state.navbar.keywords,
    viralKeywords: state.navbar.viralKeywords,
    memesID: state.navbar.selectedOption.id,
    sorting: state.author.sorting,
    subFilter: state.navbar.subFilter,
    conceptDrawer: state.mobile.conceptDrawer,
    relatedConceptDrawer: state.mobile.relatedConceptDrawer,
    selectedOption: state.navbar.selectedOption,
    loading: state.concepts.loading,
    isAdmin: state.auth.user.isAdmin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedItem: bindActionCreators(navbarActions.setSelectedItem, dispatch),
    lockIcon: bindActionCreators(navbarActions.lockIcon, dispatch),
    perspectivesByFilter: bindActionCreators(actions.perspectivesByFilter, dispatch),
    setFactsKeywrodFilterId: bindActionCreators(actions.setFactsKeywrodFilterId, dispatch),
    setSubFilter: bindActionCreators(navbarActions.setSubFilter, dispatch),
    toggleKeywordsSort: bindActionCreators(actions.toggleKeywordsSort, dispatch),
    setDrawerOpen: bindActionCreators(setDrawerOpen, dispatch),
    setRelatedConceptDrawerOpen: bindActionCreators(setRelatedConceptDrawerOpen, dispatch),
    relatedConcepts: bindActionCreators(actions.getRelatedConcepts, dispatch),
    fetchMemes: bindActionCreators(memeActions.fetchMemetagMemes, dispatch),
    setOffset: bindActionCreators(memeActions.setOffset, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DefinitionPage));

const styles = {
  icon: {
    marginLeft: 50,
    padding: 10
  }
};
