import $ from "jquery";
import jQuery from "jquery";
import { getWindowScroll } from "./scrolling";
const url = window.location.pathname.split("/")[1];
// For hide and show navbars
let upScrolling = false;
let scrollChangePoint = 0;

// Timeout to hide and show navbarTitle with delay
let title = null;

const booksPath = window.location.pathname.split("/")[1] == "books";

const upScroll = {
  mainNavbarTop: 0,
  mainHeader: 48,
  mainNavbarTitleDisplay: "flex",
  mobileMainNavbar: -200,
  homePageHeader: 135,
  secondaryNavbarDisplay: "none",
  secondarySearchbarDisplay: "none",
  secondaryNavbarHeight: 10,
  secondaryNavbarTitleDisplay: "none",
  miniHeaderTop: -200,
  mainHeader: 10,
  pagePaddingTop: 100
};

const downScroll = {
  mainNavbarTop: booksPath ? 135 : 65,
  booksMainNavbarTop: 70,
  mainHeader: 48,
  mainNavbarTitleDisplay: "none",
  secondarySearchbarDisplay: "flex",
  mobileMainNavbar: 0,
  homePageHeader: 135,
  secondaryNavbarDisplay: "flex",
  secondaryNavbarHeight: 70,
  secondaryNavbarTitleDisplay: "block",
  miniHeaderTop: 46,
  homepageHeader: 135,
  mainHeader: 80,
  mainHeaderAfter: 80,
  pagePaddingTop: 80,
  favouriteSecondMarginTop: 0,
  mobileScreenMargin: 100
};

// Navbar hide and show script on scrolling

export const handleNavbarScroll = oldScroll => {
  try {
    const newScroll = getWindowScroll();
    if (newScroll > oldScroll) {
      if (!upScrolling) {
        upScrolling = true;
        scrollChangePoint = newScroll;
      } else {
        // if upScrolling
        if (newScroll - scrollChangePoint >= 10) {
          //  alert(newScroll - scrollChangePoint)
          // alert("up")
          if (newScroll - scrollChangePoint >= 80) {
            $(".sticky-mini-header").css({ top: upScroll.miniHeaderTop });
          }
          $(".main-navbar").css({ top: upScroll.mainNavbarTop });
          $(".mobile-main-navbar").css({ top: upScroll.mobileMainNavbar });
          $(".secondary-navbar").css({ display: upScroll.secondaryNavbarDisplay });
          $(".main-navbar-title").css({ display: upScroll.mainNavbarTitleDisplay });
          // $('.sticky-mobile-header').css({ top: -200})
          $(".secondary-search-navbar").css({ display: upScroll.secondarySearchbarDisplay });
          $(".main-header").css({ top: upScroll.mainHeader });
          $(".page").css({ paddingTop: upScroll.pagePaddingTop });
          title = setTimeout(() => {
            // Hiding after some delay due to animation
            $(".secondary-navbar-title").css({ display: upScroll.secondaryNavbarTitleDisplay });
          }, 200);
          if (url == "book") {
            $("#secondCol").css({ paddingTop: 55 });
          }
          if (window.location.pathname.split("/")[1] == "books") {
            $(".secondary-navbar").css({ height: 0 });
          }
          if ($(".yellow-page").length > 0) {
            $("#share2").css({ marginLeft: "-110%" });
          }
          $(".meme-concept-button").css({ display: "none" })
        }
      }
    } else {
      if (upScrolling) {
        upScrolling = false;
        scrollChangePoint = newScroll;
      } else {
        if (scrollChangePoint - newScroll >= 10 || newScroll <= 5) {
          // alert("donwn")
          //commented to solve the scrolling issues
            $(".sticky-mini-header").css({ top: downScroll.miniHeaderTop });
          if (url == "book") {
            $(".main-header").css({ top: 95 });
          } else {
            $(".main-header").css({ top: downScroll.mainHeader });
          }
          if (window.location.pathname.split("/")[1] === "book") {
            $("#favouriteSecond").css({ marginTop: 0 });
          } else {
            $("#favouriteSecond").css({ marginTop: downScroll.favouriteSecondMarginTop });
          }
          if (url == "books" || url == "book") {
            $(".secondary-search-navbar").css({ display: downScroll.secondarySearchbarDisplay });
            $(".main-navbar").css({ top: downScroll.booksMainNavbarTop });
          }

          var allBooksUrl = window.location.pathname;
          if (allBooksUrl === "/books/all") {
            $(".page").css({ paddingTop: "15%" });
            $(".secondary-navbar").css({ height: 0 });
          } else if ($(".yellow-page").length > 0) {
            $(".container-meme-page").css({ marginTop: 70 });
            $("#share2").css({ marginLeft: "-110%", marginTop: 120 });
          } else {
            $(".page").css({ paddingTop: downScroll.pagePaddingTop });
          }
          $(".meme-concept-button").css({ display: "flex" })
        }
      }
    }
  } catch (e) {
    console.log("scroling error", e);
  }
};

export function listenToDocumentSizeChange() {
  let oldDocumentSize = 0;
  function listenDocumentSize() {
    const documentSize = $(document).height();
    if (oldDocumentSize !== documentSize) {
      // $(".sticky-mini-header").css({ top: downScroll.miniHeaderTop });
      if ($(".yellow-page").length > 0) {
        $("#share2").css({ marginLeft: "-110%", marginTop: 120 });
      }
    }
    oldDocumentSize = documentSize;
  }

  $(document).ready(() => $(document).bind("DOMSubtreeModified", listenDocumentSize));
}

export function listenToDocumentSizeChangeOnPercepectiveClick() {
  let oldDocumentSize = 0;

  function listen() {
    const documentSize = $(document).height();
    if (oldDocumentSize !== documentSize) {
      if (url == "book") {
        $(".main-header").css({ top: 135 });
        $("#secondCol").css({ paddingTop: 135 });
        $("#favouriteSecond").css({ marginTop: 0 });
      } else {
        $(".main-header").css({ top: downScroll.mainHeader });
      }
    }
    oldDocumentSize = documentSize;
  }

  $(document).ready(() => $(document).bind("DOMSubtreeModified", listen));
}

export function pageReloadscroll() {
  clearTimeout(title);
  $(".secondary-navbar").css({
    height: downScroll.secondaryNavbarHeight,
    display: downScroll.secondaryNavbarTitleDisplay
  });
  $(".secondary-navbar-title").css({ display: downScroll.secondaryNavbarTitleDisplay });
  $(".sticky-mini-header").css({ top: downScroll.miniHeaderTop });
  $(".homepage-header").css({ top: downScroll.homePageHeader });
  $(".main-navbar").css({ top: downScroll.mainNavbarTop });
  $(".secondary-search-navbar").css({ display: downScroll.secondarySearchbarDisplay });
  $(".mobile-main-navbar").css({ top: downScroll.mobileMainNavbar });
  $(".main-navbar-title").css({ display: downScroll.mainNavbarTitleDisplay });
  if (window.location.pathname.split("/")[1] === "book") {
    $("#favouriteSecond").css({ marginTop: downScroll.favouriteSecondMarginTop });
    $(".main-navbar").css({ top: 80 });
  } else {
    $(".main-navbar").css({ top: downScroll.mainNavbarTop });
  }
}

export function dataPagination() {
  $(".secondary-navbar-title").css({ display: "none" });
  $(".main-navbar").css({ top: upScroll.mainNavbarTop });
  //$(".secondColumn").css({ marginTop: 60 });
  $(".mobile-main-navbar").css({ top: upScroll.mobileMainNavbar });
  $(".main-navbar-title").css({ display: upScroll.mainNavbarTitleDisplay });
  // $('.sticky-mobile-header').css({ top: -200})
  $(".secondary-search-navbar").css({ display: upScroll.secondarySearchbarDisplay });
  // $(".sticky-mini-header").css({ top: 46 });
  $(".main-header").css({ top: upScroll.mainHeader });
  $(".page").css({ paddingTop: upScroll.pagePaddingTop });
  if (url == "book") {
    $("#secondCol").css({ paddingTop: 55 });
  }
  if (window.location.pathname.split("/")[1] == "books") {
    $(".secondary-navbar").css({ height: 0 });
  } else {
    $(".secondary-navbar").css({ display: upScroll.secondaryNavbarDisplay });
  }
  if ($(".yellow-page").length > 0) {
    $("#share2").css({ marginLeft: "-110%", marginTop: 120 });
  }
}

export function reloadPage() {
  window.location.reload()
}