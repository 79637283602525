import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import ToolTip from "react-portal-tooltip";
import embedIcon from "../../../images/imageIcons/embed.png";
import shareIcon from "../../../images/iconShare.png";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import { SITE_DISPLAY_TITLE } from "../../../constants/global";
import $ from "jquery";

const PageShare = ({ archiveMode, slug }) => {
  const [open, setOpen] = useState(false);
  const [thread, setThread] = useState("");
  const [done, setDone] = useState(false);
  const [copyDone, setCopyDone] = useState(false);
  const [copySiteDone, setCopySiteDone] = useState(false);
  const [first, setFirst] = useState(false);

  const listner = e => {
    if (e.target.parentElement.id !== "share-wrapper") {
      if (open) {
        setOpen(false);
      }
    }
  };
  useEffect(() => {
    if (open) {
      $(".ToolTipPortal").css({
        opacity: 1
      });
      if (!first) {
        document.getElementById("root").addEventListener("click", e => {
          setOpen(false);
        });
      } else {
        setFirst(true);
      }
    } else {
      setFirst(false);
      $(".ToolTipPortal").css({
        opacity: 0
      });
    }
  }, [open]);
  useEffect(() => {
    window.addEventListener("scroll", () => setOpen(false));
  }, [open]);
  // useEffect(() => {
  //   window.addEventListener('click', list)
  // }, [open])

  const toggle = () => setOpen(!open);

  const url = `${process.env.REACT_APP_SERVER}/archive/${slug}`;

  const copyLink = () => {
    if (!done) {
      const embed = archiveMode ? url : window.location.href;

      navigator.clipboard.writeText(embed);

      setDone(true);
      setTimeout(() => {
        setDone(false);
      }, 2000);
    }
  };

  const copySiteLink = () => {
    if (!done) {
      const embed = SITE_DISPLAY_TITLE;

      navigator.clipboard.writeText(embed);

      setCopySiteDone(true);
      setTimeout(() => {
        setCopySiteDone(false);
      }, 2000);
    }
  };

  const embedLink = () => {
    if (!done) {
      const html = `<iframe src='${url}' height='400' width='400'></iframe>`;
      navigator.clipboard.writeText(html);

      setCopyDone(true);
      setTimeout(() => {
        setCopyDone(false);
      }, 2000);
    }
  };

  const toolTipEnter = () => {
    clearTimeout(thread);
    setOpen(true);
  };

  const toolTipLeave = () => {
    setOpen(false);
  };
  const ref = useRef();
  const handleClick = () => {
    setOpen(false);
  };
  const share = archiveMode
    ? `${process.env.REACT_APP_SERVER}?archive=${slug}`
    : window.location.href;

  const size = 50;

  return (
    <>
      {
        <img
          className="icon"
          id="share"
          className="icon"
          src={shareIcon}
          data-tip="Share"
          onClick={toggle}
          alt="Share icon"
          style={{ width: "22px", height: "22px", marginTop: "10px", marginRight: "-15px" }}
        />
      }
      {/* <div className="iconHolder" style={{ border: "none" }}>
        <img
          className="icon"
          src={share}
          data-tip="Share"
          onClick={
            toggle
            // () => navigator.clipboard.writeText(window.location.href)
          }
          style={{ width: "22px", height: "22px", marginTop: "10px", marginRight: "-15px" }}
        />
      </div> */}
      <ToolTip active={open} position="bottom" arrow="center" parent="#share">
        <div
          id="share-wrappers"
          onClick={handleClick}
          style={{ ...styles.shareWrapper }}
          onMouseEnter={toolTipEnter}
          onMouseLeave={toolTipLeave}
        >
          <FacebookShareButton hashtag={share} url={share} quote={share}>
            <FacebookIcon size={size} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={share}>
            <TwitterIcon size={size} round={true} />
          </TwitterShareButton>
          <EmailShareButton url={share}>
            <EmailIcon size={size} round={true} />
          </EmailShareButton>
          <WhatsappShareButton url={share}>
            <WhatsappIcon size={size} round={true} />
          </WhatsappShareButton>
          <Button onClick={copyLink} style={{ ...styles.copy, width: size }}>
            {done ? <i className="fa fa-check" /> : <i className="fa fa-clone" />}
          </Button>
          {archiveMode && (
            <Button onClick={embedLink} style={styles.embed}>
              {copyDone ? (
                <i className="fa fa-check" />
              ) : (
                <img src={embedIcon} style={styles.embedIcon} alt="embed icon" />
              )}
            </Button>
          )}
        </div>
        {/* {
          <>
            <h6>Share Site Link</h6>
            <div
              id="share-wrappers"
              onClick={handleClick}
              style={{ ...styles.shareWrapper }}
              onMouseEnter={toolTipEnter}
              onMouseLeave={toolTipLeave}
            >
              <FacebookShareButton url={SITE_DISPLAY_TITLE}>
                <FacebookIcon size={size} round={true} />
              </FacebookShareButton>
              <TwitterShareButton url={SITE_DISPLAY_TITLE}>
                <TwitterIcon size={size} round={true} />
              </TwitterShareButton>
              <EmailShareButton url={SITE_DISPLAY_TITLE}>
                <EmailIcon size={size} round={true} />
              </EmailShareButton>
              <WhatsappShareButton url={SITE_DISPLAY_TITLE}>
                <WhatsappIcon size={size} round={true} />
              </WhatsappShareButton>
              <Button onClick={copySiteLink} style={{ ...styles.copy, width: 50 }}>
                {copySiteDone ? <i className="fa fa-check" /> : <i className="fa fa-clone" />}
              </Button>
            </div>
          </>
        } */}
      </ToolTip>
    </>
  );
};
PageShare.propTypes = {
  archiveMode: PropTypes.string,
  slug: PropTypes.string
};
export default PageShare;
const styles = {
  button: {
    display: "block",
    width: 135,
    fontSize: 12
  },
  shareWrapper: {
    display: "flex",
    justifyContent: "space-around",
    padding: 20
  },
  icon: {
    height: 35
  },
  copy: {
    borderRadius: "50%",
    fontSize: 12,
    width: 36
  },
  embed: {
    borderRadius: "50%",
    fontSize: 12,
    width: 36,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  embedIcon: {
    width: 15
  }
};
