import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Container } from "reactstrap";
import ConceptsSideBar from "../utills/conceptsSideBar";
import Perspective from "../utills/PerspectiveBox";
import logo from "../../images/dictionaryish.png";
import { fetchArchiveDataBySlug } from "../../actions/navbaractions";
import { conceptCategory } from "../../constants/global";

const ArchiveShare = props => {
  const { concepts } = props;

  useEffect(() => {
    const archive = window.location.pathname.substring(9, window.location.pathname.length);
    if (archive) {
      props.fetchArchiveDataBySlug(archive);
    }
  }, []);

  const conceptCallBack = (index, concept) => {
    const win = window.open(
      `${process.env.REACT_APP_SERVER}/${conceptCategory}/${concept.name}`,
      "_blank"
    );
    win.focus();
  };

  const logoClick = () => {
    const win = window.open(`${process.env.REACT_APP_SERVER}`, "_blank");
    win.focus();
  };

  return (
    <Container>
      <Row style={{ position: "relative" }}>
        <div style={styles.header}>
          <div style={styles.title}>
            <h1>Glossary</h1>
          </div>
          <h6 style={styles.by}>by</h6>
          <img src={logo} style={styles.logo} onClick={logoClick} alt="Archive logo" />
        </div>
      </Row>
      <Row style={styles.data}>
        <Col xs={4} md={3}>
          <ConceptsSideBar concepts={concepts} conceptCallBack={conceptCallBack} />
        </Col>
        <Col xs={8} md={6}>
          <Perspective />
        </Col>
      </Row>
    </Container>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchArchiveDataBySlug: bindActionCreators(fetchArchiveDataBySlug, dispatch)
  };
};
const mapStateToProps = state => {
  return {
    concepts: state.navbar.concepts
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveShare);

const styles = {
  header: {
    width: "100vw",
    display: "flex",
    alignItems: "flex-end",
    position: "fixed",
    background: "white",
    zIndex: 1
  },
  title: {
    width: "45%",
    justifyContent: "flex-end",
    display: "flex"
  },
  by: {
    marginLeft: 24,
    alignSelf: "flex-end"
  },
  logo: {
    width: 85,
    height: 20,
    marginLeft: 5,
    cursor: "pointer"
  },
  data: {
    paddingTop: 50
  }
};
