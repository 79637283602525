import axios from "axios";

const server = `${process.env.REACT_APP_SERVER}/api/rest/v1`;

const all = (inputValue, callback) => {
  axios
    .get(`${server}/home/all/` + inputValue)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching author groups for navbar", error);
    });
};

const authors = (inputValue, callback) => {
  axios
    .get(`${server}/authors/search/` + inputValue)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching authors for navbar", error);
    });
};

const authorsHavingBooks = (inputValue, callback) => {
  axios
    .get(`${server}/authors/books/search/` + inputValue)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching authors for navbar", error);
    });
};
const concepts = (inputValue, perspectiveCount, callback) => {
  axios
    .get(`${server}/concepts/search/${inputValue}/${perspectiveCount}`)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching concepts for navbar", error);
    });
};

const allConcepts = (inputValue, callback) => {
  axios
    .get(`${server}/concepts/unverified/` + inputValue)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching concepts for navbar", error);
    });
};

const favouriteConcepts = (inputValue, callback) => {
  axios
    .post(`${server}/concepts/search/` + inputValue)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching concepts for navbar", error);
    });
};

const keywords = (inputValue, callback) => {
  axios
    .get(`${server}/concepts/keywords/search/` + inputValue)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching concepts for navbar", error);
    });
};

const memeTags = (inputValue, tags, callback) => {
  axios
    .get(`${server}/memes/keywords/search?label=${inputValue}${tags ? `&isMemeTag=${tags}` : ""}`)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching concepts for navbar", error);
    });
};

const conceptClusters = (inputValue, callback) => {
  axios
    .get(`${server}/conceptClusters/search/` + inputValue)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching concept clusters for navbar", error);
    });
};

const authorClusters = (inputValue, callback) => {
  axios
    .get(`${server}/authorClusters/search/` + inputValue)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching author clusters for navbar", error);
    });
};

const authorGroupsNav = (inputValue, tags, callback) => {
  console.log("tags", inputValue, tags);
  axios
    .get(`${server}/authorGroups/search/${inputValue}?tag=${tags}`)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching author groups for navbar", error);
    });
};

const authorGroups = (labels, callback) => {
  axios
    .post(`${server}/authors/search`, labels)
    .then(data => {
      const { responseType } = data.data;
      if (responseType === "success") {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching author groups for navbar", error);
    });
};

const booksCollection = (labels, callback) => {
  axios
    .get(`${server}/collection/search/${labels}`)
    .then(data => {
      if (data.status === 200) {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching author groups for navbar", error);
    });
};

const booksByTitle = (labels, callback) => {
  axios
    .get(`${server}/booksbytitle/search/${labels}`)
    .then(data => {
      if (data.status === 200) {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching author groups for navbar", error);
    });
};

const booksByAuthor = (labels, callback) => {
  axios
    .get(`${server}/booksbyauthor/search/${labels}`)
    .then(data => {
      if (data.status === 200) {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching author groups for navbar", error);
    });
};

const conceptsByCheckGroupId = (label, callback) => {
  axios
    .post(`${server}/concepts/searchConcepts`, { label })
    .then(data => {
      if (data.data.status === true) {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching authors for navbar", error);
    });
};

const authorsAndGroups = (label, callback) => {
  axios
    .get(`${server}/home/authorGroups/authors/${label}`)
    .then(data => {
      if (data.status === 200) {
        const selectedOption = data.data.data;
        callback(null, selectedOption);
      }
    })
    .catch(error => {
      console.log("Error while prefetching authors for navbar", error);
    });
};

const setLabelAndColorOfPos = (grammar, term) => {
  switch (grammar) {
    case "adj":
      return { label: `${term} (adjective)`, color: "#f4873b" };
    case "v":
      return { label: `${term} (verb)`, color: "#af4979" };
    case "n":
      return { label: `${term} (noun)`, color: "#454B1B" };
    case "adv":
      return { label: `${term} (noun)`, color: "#00752d" };
    default:
      return { label: `${term}`, color: "#000000" };
  }
};

const allPos = (label, tags, stateTags, callback) => {
  axios
    .get(`${server}/pos/search?label=${label}${tags ? `&posKind=` + tags : ""}`)
    .then(data => {
      if (data.status === 200) {
        const selectedOption = data.data.data;
        const poss = selectedOption.map(e => ({
          ...e,
          ...setLabelAndColorOfPos(e.grammar, e.term || e.name)
        }));
        callback(null, poss);
      }
    })
    .catch(error => {
      console.log("Error while prefetching pos list", error);
    });
};

const orderSearchByAlphabets = (array, keyword) => {
  return array;
  //   .filter(o => o.label.toLowerCase()
  //   .includes(keyword.toLowerCase()))
  //   .sort((a,b)=>a.label.localeCompare(b.label))
  //   .sort((a, b) => a.label.toLowerCase().indexOf(keyword.toLowerCase()) - b.label.toLowerCase().indexOf(keyword.toLowerCase()))
  //   .sort((a,b)=> a.label.length - b.label.length)
};

export default {
  authorGroups,
  authorGroupsNav,
  authorClusters,
  authors,
  concepts,
  favouriteConcepts,
  keywords,
  conceptClusters,
  booksCollection,
  conceptsByCheckGroupId,
  all,
  authorsAndGroups,
  booksByTitle,
  booksByAuthor,
  authorsHavingBooks,
  memeTags,
  allPos,
  orderSearchByAlphabets,
  allConcepts
};
