import { MAIN_WHITE } from "../../constants/colors";

const navbarStyles = {
  navbar: {
    zIndex: 5,
    position: "fixed",
    widht: "99vw",
    height: "45px",
    backgroundColor: "#fff",
    padding: "0px",
    paddingLeft: "20px",
    paddingRight: "20px",
    width: "100vw",
    boxShadow: "1px 2px #d8cfcf"
  },
  navbarBrand: {
    color: "rgba(0,0,0,0.9",
    cursor: "pointer"
  },
  filterItem: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    cursor: "pointer"
  },
  tagsWrapper: {
    padding: 0,
    display: "inline"
  },
  tag: {
    display: "inline-block",
    background: "purple",
    color: "#fff",
    borderRadius: "8px",
    padding: "5px",
    fontSize: "13px",
    marginLeft: "2px"
  },
  tagsInner: {
    marginLeft: "5px"
  },
  menues: {
    listStyleType: "none",
    padding: 0,
    textAlign: "center",
    display: "flex",
    alignItems: "baseline"
  },
  surprise: {
    height: 25,
    cursor: "pointer"
  },
  menue19: {
    height: 25,
    cursor: "pointer"
  },
  collections: {
    alignItems: "center !important"
  },
  titleDiv: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    zIndex: 1
  },
  filterSearch: {
    display: "inline-block",
    minWidth: "25%",
    maxWidth: "25%"
  },
  title: {
    marginLeft: "10%",
    fontSize: 18
  },
  logoColumn: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  wholeDiv: {
    position: "fixed",
    zIndex: 10,
    backgroundColor: "white",
    width: "100%",
    top: 0
  },
  topTitleDiv: {
    top: 70
  },
  logo: {
    color: "rgba(0,0,0,0.9",
    textDecoration: "none"
  },
  category: {
    paddingLeft: 0,
    paddingRight: 0
  },
  newsTitle: {
    marginTop: 30
  },
  topNavtitle: {
    fontSize: 46,
    fontWeight: "bold",
    // display: 'flex',
    // justifyContent: 'center',
    height: 70,
    background: MAIN_WHITE
  },
  topSearch: {
    fontSize: 46,
    fontWeight: "bold",
    // display: 'flex',
    // justifyContent: 'center',
    height: 70,
    background: MAIN_WHITE
  },
  responseive: {
    // width: '100%',
    height: "100%"
  }
};
export default navbarStyles;

export const getSecondColStyles = (columnLoading, subFilter, pageName) => {
  return {
    opacity: columnLoading ? 0.5 : 1,
    pointerEvent: columnLoading ? "none" : "auto",
    borderLeft: pageName === "Concepts" || pageName === "concepts" ? "1px solid black" : "",
    backgroundColor: "white",
    boxShadow: subFilter ? "-21px 2px 21px -14px rgba(0,0,0,0.75)" : "none"
  };
};
