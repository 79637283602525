import React, { Fragment } from 'react'
import Chip from './chip'

const TempConcepts = props => {
    const { concepts, isAdmin } = props
    return (
            <div 
                style={styles.tooltipWrapper}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
            >
                {concepts &&
                    concepts.length > 0 &&
                    concepts.map(c => (
                                    <div style={styles.internalWrapper}>
                                        <Chip 
                                            concept={c}
                                            onNameClick={props.handleTempReciprocalClick}
                                            onDeleteClick={() => {}}
                                            onReciprocalClick={props.handleTempReciprocalClick}
                                            showControls = {isAdmin}
                                            hideDelete = {true}
                                        />
                                    </div>
                        ))
                }
            </div>    
    )
}

export default TempConcepts




const styles = {
    tooltipWrapper: {
        width: 400, 
        display: 'flex',
        flexWrap: 'wrap', 
        // minHeight: 300,
         height: 300,
        maxHeight: 300,
        overflow: 'scroll',
        padding: 20
    },
    internalWrapper: {
        minWidth: '30%',
        padding: 5
    }
}