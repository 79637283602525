import React, { useEffect } from "react";
import Perspectives from "../../utills/PerspectiveBox";
import * as scroll from "../../../helpers/scrolling";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { authorPicLink } from "../../../helpers/authorHelpers";
import * as actions from "../../../actions/authorActions";
import * as navbarActions from "../../../actions/navbaractions";
import * as mobile from "../../../actions/mobile";
import Header from "../../utills/Header/Header";
import { mapConcepts } from "../../../selectors/index";
import { authorCategory, SORT_BY_LIKES } from "../../../constants/global";
import * as facebook from "../../../helpers/facebook";
import ImageWrapper from "../../utills/Header/imageWrapper";
import $ from "jquery";
import Footer from "./footer";
import MiniHeader from "../../utills/Header/miniHeader";
import MemeCards from "../../Memes/memeCard";
import Return from "../../Concept/cols/return";
import history from "../../../router/history";
import { isMobile } from "react-device-detect";
import { getSecondColStyles } from "../../TopNavBar/navbarStyles";

const SecondCol = props => {
  const {
    detail,
    allData,
    showSearch,
    sortByLikes,
    viewport,
    showImage,
    loading,
    subFilter,
    columnLoading,
    selectedOption,
    blockPagination,
    conceptDrawer,
    memesMode,
    bioDrawer,
    relatedAuthorDrawer,
    parentGroup,
    commentsMode
  } = props;
  let keywords = true;
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showImage, loading, subFilter, blockPagination, memesMode]);

  useEffect(() => {
    const element = document.getElementById(viewport);
    if (element) {
      scroll.setIgnoreWindowScroll();
      element.scrollIntoView({ behavior: "auto" });
      scroll.setScroll($(window).scrollTop() - 160);
      props.emptyViewPort();
      scroll.updateScroll();
    }
  }, [viewport]);

  const handleScroll = () => {
    const oldScroll = scroll.handleScrollCallBack();
    if ($(window).scrollTop() >= oldScroll) {
      handleDown();
    } else {
      handleUp();
    }
  };

  const handleUp = () => {
    if ($(window).scrollTop() === 0 && !sortByLikes && !showImage && !loading && !subFilter) {
      props.handleTopPagination(keywords);
    }
  };

  const handleDown = () => {
    if (
      scroll.hasReachedToBottom() &&
      !loading &&
      !subFilter &&
      !blockPagination &&
      !memesMode &&
      !commentsMode
    ) {
      props.handleDownPagination(keywords);
    }
  };

  const toggleSort = () => {
    scroll.resetScroll();
    props.toggleAuthorPagination();
  };

  const filterTitle = () => {
    if (subFilter)
      return { name: subFilter.label, type: subFilter.type, parentName: selectedOption.value };
    else return null;
  };

  const filterCallBack = inputValue => {
    props.setSubFilter(inputValue);
    props.perspectivesByFilter(inputValue);
    facebook.refreshFacebook();
  };

  const cancelClick = () => {
    props.removeSubFilter();
    props.cancelClick();
    facebook.refreshFacebook();
    props.setMobileMemesMode(false);
  };
  const goBack = () => {
    if (selectedOption.category === authorCategory) {
      // history.push(`/${authorCategory}/${selectedOption.value}`)
      props.removeSubFilter();
      props.cancelClick();
      facebook.refreshFacebook();
      props.setMobileMemesMode(false);
      //  history.goBack()
    }
    // else
    // {
    //   props.getBooks(selectedOption.id)
    // }
  };

  return (
    <div
      id="secondCol"
      className="col-12 col-sm-12 col-md-3 left offset-md-2"
      style={getSecondColStyles(columnLoading)}
    >
      {/* {!showSearch && (
        <Header
          sortByLikes={sortByLikes}
          toggleSortRight={toggleSort}
          data={filterTitle()}
          showAll={cancelClick}
          allData={allData}
          filterCallBack={filterCallBack}
          showFilterSearch={true}
          isAuthorPage={true}
          block={true}
        />
      )} */}
      <MiniHeader
        handleConceptClick={() => props.setConceptDrawerOpen(!conceptDrawer)}
        handleBioClick={() => props.setBioDrawerOpen(!bioDrawer)}
        handleRelatedClick={() => props.setRelatedAuthorDrawerOpen(!relatedAuthorDrawer)}
        handleMainClick={cancelClick}
        handleMemesClick={() => props.setMobileMemesMode(true)}
        hideAuthor={true}
        showBio={true}
        parentGroup={parentGroup}
        showComments={true}
      />

      <div id="favouriteSecond">
        {!memesMode ? (
          <>
            {isMobile && !subFilter && (
              <ImageWrapper title={selectedOption.value} src={authorPicLink(detail)} />
            )}

            <Perspectives authorPage={true} />
            {subFilter && <Return onClick={goBack} />}
            {blockPagination && <Footer />}
          </>
        ) : (
          <MemeCards />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  detail: state.author.authorDetail,
  showSearch: state.navbar.showSearch,
  allData: mapConcepts(state.navbar.concepts),
  sortByLikes: state.author.sorting === SORT_BY_LIKES,
  viewport: state.navbar.viewport,
  showImage: state.author.conceptTopIndex <= 0,
  loading: state.author.loading,
  subFilter: state.navbar.subFilter,
  blockPagination: state.author.blockPagination,
  columnLoading: state.navbar.columnLoading,
  selectedOption: state.navbar.selectedOption,
  conceptDrawer: state.navbar.conceptDrawer,
  memesMode: state.mobile.memesMode,
  commentsMode: state.mobile.commentsMode,
  bioDrawer: state.mobile.bioDrawer,
  relatedAuthorDrawer: state.mobile.relatedAuthorDrawer
});

const mapDispatchToProps = dispatch => ({
  allowBlockPagination: bindActionCreators(actions.allowBlockPagination, dispatch),
  handleDownPagination: bindActionCreators(actions.handleDownPagination, dispatch),
  toggleAuthorPagination: bindActionCreators(actions.toggleAuthorPagination, dispatch),
  handleTopPagination: bindActionCreators(actions.handleTopPagination, dispatch),
  perspectivesByFilter: bindActionCreators(actions.perspectivesByFilter, dispatch),
  cancelClick: bindActionCreators(actions.cancelClick, dispatch),
  emptyViewPort: bindActionCreators(navbarActions.emptyViewPort, dispatch),
  setSubFilter: bindActionCreators(navbarActions.setSubFilter, dispatch),
  removeSubFilter: bindActionCreators(navbarActions.removeSubFilter, dispatch),
  setConceptDrawerOpen: bindActionCreators(mobile.setDrawerOpen, dispatch),
  setBioDrawerOpen: bindActionCreators(mobile.setBioDrawerOpen, dispatch),
  setRelatedAuthorDrawerOpen: bindActionCreators(mobile.setRelatedAuthorDrawerOpen, dispatch),
  setMobileMemesMode: bindActionCreators(mobile.setMobileMemesMode, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(React.memo(SecondCol)));
