import * as constants from '../constants/authorGroup'
import { SORT_BY_CONCEPTS } from '../constants/global'

const initialState = {
    authorGroups: {},
    apiLoading: false,
    showPage: false,
    conceptIndex: 5,
    conceptTopIndex: 0,
    sorting: SORT_BY_CONCEPTS,
    offset: 1,
    limit: 30,
    initialLoading: false,
    hideAuthorPage: false,
    relatedConceptExist: false
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case constants.GROUP_LOADING: 
            return {
                ...state,
                apiLoading: action.payload,
                initialLoading: action.initialLoading
            }
        
        case constants.HIDE_GROUP_PAGE: 
            return {
                ...state,
                showPage: action.payload
            }
        case constants.GROUP_CONCEPT_INDEX:
            {
                return {
                    ...state,
                    conceptIndex: action.index,
                    conceptTopIndex: action.topIndex
                }
            }
        
        case constants.SET_GROUP_SORTING:
            {
                return {
                    ...state,
                    sorting: action.payload,
                    offset: 1,
                    conceptIndex: 5,
                    conceptTopIndex: 0
                }
            }
        
        case constants.GROUP_OFFSET:
            {
                return {
                    ...state,
                    offset: action.payload
                }
            }
        
        case constants.GROUP_RELATED_CONCEPT:
            {
                return {
                    ...state,
                    relatedConceptExist: action.payload
                }
            }
        


        default:
            return state;
    }
}