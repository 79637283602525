import { constant } from 'lodash'
import * as constants from '../constants/archives'
import {LOGOUT} from '../constants/navbar'

const initialState = {
    archiveList: [],
    selectedArchive: -1 ,
    perspectiveIds:[],
    lastSelectedArchive: -1,
    perspectives: [],
    isPublic: false,
    archiveOwner: -1,
    slug: null,
    authorFilterId: null,
    conceptFilterId: null,
    memeIds:[],
    memes:[]
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case constants.SET_ARCHIVES:{
            return {
                ...state,
                archiveList:action.payload,
                perspectiveIds:action.perspectiveIds,
                memeIds:action.memeIds
            }
        }
        case 'ADD_NEW_ARCHIVE':
        {
            let newArchive=action.payload
            let archiveList=state.archiveList
            archiveList.push(newArchive)
            return {
                ...state,
                archiveList
            }
        }
        case constants.SELECTED_ARCIVE:
        {
            return {
                ...state,
                selectedArchive: action.payload
            }
        }
        case constants.LAST_SELECTED_ARCHIVE:
        {
            return {
                ...state,
                lastSelectedArchive: action.payload
            }
        }
        case 'ARCHIVE_DATA':{
            return {
                ...state,
                archiveData:action.payload
            }
        }
        case 'ADD_TO_ARCHIVE': {
            let ids=state.perspectiveIds;
            ids.push(action.payload)
            return {
                ...state,
                perspectiveIds: [...ids]
            }
        }
        case 'ADD_MEME_TO_ARCHIVE': {
            let ids=state.memeIds;
            ids.push(action.payload)
            return {
                ...state,
                memeIds: [...ids]
            }
        }
        case 'REMOVE_PERSPECTIVE_ARCHIVE':{
            let perspectiveIds= state.perspectiveIds
            perspectiveIds = perspectiveIds.filter(i => i.perspectiveId !== action.payload)
            return {
                ...state,
                perspectiveIds
            }
        }
        case 'REMOVE_MEME_ARCHIVE':{
            let memeIds= state.memeIds
            memeIds = memeIds.filter(i => i.memeId !== action.payload)
            return {
                ...state,
                memeIds
            }
        }
        case 'SET_PERSPECTIVE_LIKE':
            {
                let archiveData=state.archiveData
                archiveData.perspective=action.payload
                return {
                    ...state,
                    archiveData:archiveData
                }
            }
        case constants.ARCHIVE_PERSPECTIVES: 
            return {
                ...state,
                perspectives: action.payload
            }
        case constants.SAVE_ARCHIVE_MEME: 
        return {
            ...state,
            memes: action.payload
        }
        case LOGOUT: 
            return {
                ...initialState,
                perspectives: state.perspectives,
                isPublic: state.isPublic,
                archiveOwner: state.archiveOwner
            }

        case constants.SET_ARCHIVE_META: 
            return {
                ...state,
                isPublic: action.payload.isPublic,
                archiveOwner: action.payload.user_id,
                slug: action.payload.slug
            }

        case constants.AUTHOR_ARCHIVE_FILTER: 
            return {
                ...state,
                authorFilterId: action.payload,
                conceptFilterId: null
            }

        case constants.CONCEPT_ARCHIVE_FILTER: 
            return {
                ...state,
                conceptFilterId: action.payload,
                authorFilterId: null
            }

        default:
            return state;
    }
   
}