import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ToolTip from "react-portal-tooltip";
import Search from "../TopNavBar/search/search";
import { bindActionCreators } from "redux";
import { addConceptToMeme, removeConceptFromMeme } from "../../actions/memes";
import OutsideClickHandler from "react-outside-click-handler";
import history from "../../router/history";
import * as actions from "../../actions/memes";
import * as navbarActions from "../../actions/navbaractions";
import * as clusterActions from "../../actions/conceptClustersActions";

const MemeConcepts = props => {
  const { meme, user, selectedOption, memeColor, textType, thirdKey } = props;
  const [concepts, setConcepts] = useState([]);
  const [tooltipConcepts, setTooltipConcepts] = useState([]);
  const [active, setActive] = useState(false);

  const pageName = window.location.href.split("/")[3];
  let currentConcept = window.location.href.split("/")[4];
  currentConcept = currentConcept && currentConcept.split("?")[0];
  const currentKeyword = window.location.href.split("=")[1];

  const getPath = concept => {
    props.removeSubFilter();
    if (pageName === "wackywares") {
      return;
    } else if (currentKeyword === undefined) {
      if (concept.name === "meme") {
        let bottomConcept = selectedOption.value;
        bottomConcept = bottomConcept.split(" ").join("-");
        history.push(`/memes/${bottomConcept}?keyword=meme`);
      } else {
        if (pageName === "Concepts" || pageName === "memes") {
          history.push(`/memes/${concept.slug ? concept.slug : concept.name}`);
        } else {
          history.push(`/memes/${concept.slug ? concept.slug : concept.name}?keyword=${pageName}`);
        }
      }
    } else {
      history.push(
        `/memes/${concept.slug ? concept.slug : concept.name}?keyword=${currentKeyword}`
      );
    }
  };

  const getMemeTagPath = concept => {
    if (pageName === "wackywares") {
      return;
    } else {
      props.removeSubFilter();
      history.push(
        `/memes/${currentConcept}?keyword=${concept.slug ? concept.slug : concept.name}`
      );
    }
  };

  const memeConceptClick = concept => {
    if (pageName === "Concepts" || pageName === "concepts" || pageName === "QL") {
      props.combineMemePerspective([]);
      setTimeout(() => {
        history.push(`/Concepts/${concept.slug}`);
      }, 0);
    } else if (concept.slug === currentConcept) {
      history.push(`/Concepts/${concept.slug}`);
    } else if (memeColor && memeColor.ConceptId === concept.id) {
      getMemeTagPath(concept);
    } else {
      getPath(concept);
    }
  };

  const toggle = () => {
    setActive(!active);
    if (!active) {
      setTimeout(() => {
        if (searchRef.current) {
          searchRef.current.focus();
        }
      }, 300);
    }
  };

  const getTagsColor = concept => {
    const { name } = concept;
    if (
      name === "trivia" ||
      name === "one liner" ||
      name === "dad joke" ||
      name === "meme" ||
      name === "memes"
    ) {
      switch (name) {
        case "trivia":
          return "#288133";
        case "one liner":
          return "#C78210";
        case "dad joke":
          return "#D3665B";
        case "meme":
          return "#807500";
        case "memes":
          return "#1565c0";
      }
    } else {
      switch (currentKeyword ? currentKeyword : pageName) {
        case "trivia":
          return "#288133";
        case "one-liner":
          return "#C78210";
        case "dad-joke":
          return "#D3665B";
        case "meme":
          return "#807500";
        case "memes":
          return "#1565c0";

        default:
          return "black";
      }
    }
  };

  const handleMouseEnter = () => {
    setActive(true);
  };

  const handleMouseLeave = () => {
    setActive(false);
  };

  const handleRemoveclick = concept => {
    props.removeConceptFromMeme(meme && meme.id, concept);
    let oldConcepts = concepts;
    let newConcepts = oldConcepts.filter(item => item.id !== concept.id);
    setTooltipConcepts(tooltipConcepts.filter(item => item.id !== concept.id));
    setConcepts(newConcepts);
    setActive(true);
    if (active) {
      setTimeout(() => {
        if (searchRef.current) {
          searchRef.current.focus();
        }
      }, 300);
    }
  };

  const populateConcepts = () => {
    let allConcepts = meme && meme.Concepts;
    let toolConcepts = meme && meme.Concepts;
    if (textType && memeColor) {
      const preservedObject =
        allConcepts && memeColor && allConcepts.filter(item => item.id === memeColor.ConceptId);
      allConcepts =
        allConcepts && memeColor && allConcepts.filter(item => item.id !== memeColor.ConceptId);
      {
        !currentKeyword &&
          pageName !== "trivia" &&
          pageName !== "one-liner" &&
          pageName !== "meme" &&
          pageName !== "dad-joke" &&
          allConcepts.unshift(preservedObject[0]);
      }
    }
    setTooltipConcepts(toolConcepts);
    if (!currentKeyword && meme.url && pageName !== "wackywares") {
      const isMemeExist = allConcepts && allConcepts.some(e => e.name === "meme");

      !isMemeExist && pageName !== "meme" && allConcepts && allConcepts.unshift({ name: "meme" });
    }
    setConcepts(allConcepts);
  };

  useEffect(() => {
    populateConcepts();
  }, [meme.id, window.location.href]);

  const onSearch = inputValue => {
    props.addConceptToMeme(meme.id, inputValue);
    let oldConcepts = concepts && concepts.length > 0 ? concepts : [];
    let toolConcepts = tooltipConcepts && tooltipConcepts.length > 0 ? tooltipConcepts : [];
    let checkConcept = oldConcepts.find(item => item.id == inputValue.id);
    if (!checkConcept) {
      let newConcept = {
        id: inputValue.id,
        name: inputValue.label
      };
      toolConcepts.push(newConcept);
      oldConcepts.push(newConcept);
    }
    setTooltipConcepts(toolConcepts);
    setConcepts(oldConcepts);
    setActive(false);
    setTimeout(() => {
      setActive(true);
      if (active) {
        setTimeout(() => {
          if (searchRef.current) {
            searchRef.current.focus();
          }
        }, 300);
      }
    }, 1);
  };

  const searchRef = useRef();

  const keywordList = (
    <>
      {tooltipConcepts &&
        tooltipConcepts.map(concept => {
          return (
            <div
              key={concept.id}
              style={styles.chip}
              id="chip"
              onClick={() => handleRemoveclick(concept)}
            >
              {`${concept.name}  x`}
            </div>
          );
        })}
    </>
  );

  return (
    <div id="mem.id" style={{ borderTop: "2px solid red" }}>
      {user.isAdmin && (
        <span className="perspective-keywords" onClick={toggle} id={`meme${meme && meme.id}`}>
          <i className="fa fa-plus-circle"></i>
        </span>
      )}
      {active && (
        <ToolTip
          active={active}
          position="bottom"
          arrow="center"
          parent={`#meme${meme && meme.id}`}
        >
          <OutsideClickHandler onOutsideClick={() => setActive(false)}>
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={styles.tipWrapper}
            >
              <div style={styles.secondaryWrapper}>
                <b style={styles.tags}>Add Tag</b>
                <div style={styles.searchWrapper}>
                  <Search
                    value={""}
                    onChange={onSearch}
                    placeholder={`keyword search`}
                    category={"Keywords"}
                    searchRef={searchRef}
                    // onFocus={() => setFocus(true)}
                    // onBlur = {() => setFocus(false)}
                  />
                </div>
              </div>
              <div style={styles.chipWrapper}>{keywordList}</div>
            </div>
          </OutsideClickHandler>
        </ToolTip>
      )}

      {concepts &&
        concepts.map(concept => {
          let lastElem = concepts[concepts.length - 1] == concept;
          return (
            <span key={concept.id}>
              <a
                style={{
                  marginRight: 3,
                  // color: concept.slug === currentConcept ? "black" : getTagsColor(concept.name),
                  color: concept.slug === currentConcept ? "black" : getTagsColor(concept),
                  cursor: "pointer",
                  fontFamily: "garamond"
                }}
                onClick={e => {
                  e.preventDefault();
                  props.setOffset(0);
                  memeConceptClick(concept);
                }}
                href={`/Concepts/${concept.name}`}
              >
                {concepts.length > 1 && concept.name}
                {!lastElem && " ·"}
                {concepts.length < 2 && concept.name + " "}
                {/* {concept.name} */}
              </a>
            </span>
          );
        })}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    memePageMemes: state.memes.memePageMemes,
    selectedOption: state.navbar.selectedOption
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeConceptFromMeme: bindActionCreators(removeConceptFromMeme, dispatch),
    addConceptToMeme: bindActionCreators(addConceptToMeme, dispatch),
    setOffset: bindActionCreators(actions.setOffset, dispatch),
    combineMemePerspective: bindActionCreators(clusterActions.combineMemePerspective, dispatch),
    removeSubFilter: bindActionCreators(navbarActions.removeSubFilter, dispatch)
  };
};

MemeConcepts.propTypes = {
  meme: PropTypes.object.isRequired,
  memeColor: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MemeConcepts);

const styles = {
  wrapper: {
    display: "inline"
    // padding: "10px 20px"
  },
  secondaryWrapper: {
    display: "flex",
    alignItems: "center"
  },
  searchWrapper: {
    marginTop: 10,
    marginBottom: 10,
    flex: 1
  },
  tags: {
    paddingRight: 5
  },
  tipWrapper: {
    width: 300,
    padding: 7
  },
  chipWrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    padding: "0px 7px",
    fontSize: 14,
    backgorund: "red",
    border: "1px solid #bab8b8",
    borderRadius: "5rem",
    margin: 3
  }
};
