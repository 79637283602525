import React, { useState, useRef, useEffect } from "react";
import { bindActionCreators } from "redux";
import logo from "../../images/dictionaryish.png";
import BurgerMenu from "./navChild/burgerMenu";
import Search from "./search/search";
import searchIcon from "../../images/imageIcons/search.png";
import history from "../../router/history";

import { connect } from "react-redux";
import {
  toggleSearch,
  hideSearch,
  searchFocus,
  socialLogin,
  removeSubFilter,
  removeFocus,
  surpriseConcept
} from "../../actions/navbaractions";
import PageShare from "../utills/FacebookComment/PageShare";
import { ARCHIVE_MODE } from "../../constants/navbar";
import randomIcon from "../../images/surprise.png";

const MobileNavbar = props => {
  const ref = useRef();
  const [active, setActive] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterSearch, setFilterSearch] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const { archiveMode } = props;

  useEffect(() => {
    if (active && ref.current) {
      ref.current.focus();
    }
  }, [active]);

  const getLoadingState = () => {
    const key = window.location.href.split("/")[3].toLowerCase();
    switch (key) {
      case "dictionary":
        return props.concepts.apiLoading;
      default:
        return false;
    }
  };
  const onClickHandle = item => {
    history.push(`/${item.category}/${item.slug ? item.slug : item.value}`);
  };

  const disableHandler = () => {
    const loading = getLoadingState();
    if (!loading) {
      props.surpriseClick();
    }
  };

  const handleSearchClick = () => {
    setActive(true);
    if (ref.current) {
      setActive(false);
      ref.current.focus();
    }
  };

  // const handleSearch = () => {
  //   props.handle()
  //   setActive(false);

  // }
  const homepage = window.location.pathname === "/";

  return (
    <div id="mobile-navbar" className="mobile-main-navbar " style={styles.wrapper}>
      <div style={styles.innerWrapper}>
        <div
          style={styles.imageWrapper}
          id="image-wrapper"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <img src={logo} style={styles.image} alt="logo" />
        </div>

        <div className="d-flex d-md-none " style={styles.iconsWrapper}>
          <div id="page-share-wrapper" style={styles.pageShareWrapper}>
            <PageShare isNavbar={true} />
          </div>
          <div id="search-wrapper" style={styles.searchWrapper}>
            {/* do not show if homepage */}
            {!homepage && !active && (
              <>
                <img
                  className="icon"
                  onClick={disableHandler}
                  style={getLoadingState() == true ? styles.loadingIcon : styles.icon}
                  src={randomIcon}
                  alt="Icon"
                />
                <img
                  className="icon"
                  onClick={handleSearchClick}
                  style={styles.icon}
                  src={searchIcon}
                  alt="Icon"
                />
              </>
            )}
            {/* But show if archive mode which is also on hoempage */}

            {archiveMode && !active && (
              <img
                className="icon"
                onClick={handleSearchClick}
                style={styles.icon}
                src={searchIcon}
                alt="Icon"
              />
            )}

            {active && (
              <div style={styles.searchRow}>
                <i className="fa fa-remove ml-2" onClick={() => setActive(false)} />
                <div style={styles.searchWrapper}>
                  <Search
                    className="ml-2"
                    value={""}
                    category={"All"}
                    onChange={onClickHandle}
                    placeholder={`new search`}
                    searchRef={ref}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={styles.menue}>
          <BurgerMenu />
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    removeSubFilter: bindActionCreators(removeSubFilter, dispatch),
    socialLogin: bindActionCreators(socialLogin, dispatch),
    removeFocus: bindActionCreators(removeFocus, dispatch),
    toggleSearch: bindActionCreators(toggleSearch, dispatch),
    hideSearch: bindActionCreators(hideSearch, dispatch),
    searchFocus: bindActionCreators(searchFocus, dispatch),
    surpriseClick: bindActionCreators(surpriseConcept, dispatch)
  };
};
const mapStateToProps = state => {
  return {
    navbar: state.navbar,
    showSearch: state.navbar.showSearch,
    memesFilter: state.navbar.memesFilter,
    concepts: state.concepts,
    authors: state.author,
    conceptClusters: state.conceptClusters,
    authorGroups: state.autherCluster,
    isAuthenticated: state.auth.isAuthenticated,
    invalidLogin: state.auth.invalidLogin,
    user: state.auth.user,
    insights: state.navbar.insights,
    auth: state.auth,
    archives: state.archives,
    primaryMode: state.navbar.primaryMode,
    archiveMode: state.navbar.mode === ARCHIVE_MODE
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileNavbar);

const styles = {
  wrapper: {
    position: "fixed",
    height: 45,
    width: "100vw",
    zIndex: 10,
    background: "white"
    // top : '7px !important'
  },
  innerWrapper: {
    width: "100%",
    height: 45,
    padding: 3,
    boxShadow: "1px 2px #d8cfcf",
    display: "flex",
    alignItems: "center"
  },
  imageWrapper: {
    height: "100%",
    maxWidth: "30vw"
  },
  image: {
    height: 40,
    width: "100%"
  },
  searchWrapper: {
    flex: 5
  },
  iconsWrapper: {
    width: "55vw",
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 6
  },
  pageShareWrapper: {
    flex: 1
  },

  icon: {
    height: 35,
    paddingLeft: 20
  },
  loadingIcon: {
    color: "gray",
    height: 35,
    paddingLeft: 20,
    pointerEvents: "none"
  },
  menue: {
    listStyleType: "none"
  },
  searchRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse"
  }
};
