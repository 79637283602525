import React from 'react';
import { MAIN_WHITE } from '../../../constants/colors';

const DrawerButton = props => {
    return (
        <div className="d-md-none" style={styles.btnWrapper} onClick={props.handleClick}>
            <i className="fas fa-chevron-circle-left"/>
        </div>
    );
};

export default DrawerButton;

const styles = {
    btnWrapper: {
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: MAIN_WHITE,
        cursor: 'pointer'
    }
}