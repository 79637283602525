import * as constants from '../constants/mobile'
const initialState = {
    conceptDrawer: false,
    relatedConceptDrawer: false,
    authorDrawer: false,
    bioDrawer: false,
    newsDrawer: false,
    relatedAuthorDrawer: false,
    commentsMode: false,
    memesMode: false,     // for mobile miniheader memes mode
}
export default (state = initialState, action = {}) => {
    
    switch (action.type) {
        
        case constants.SET_DRAWER: {
            return {
                ...initialState,         // Sending initial state because all other drawers needs to be closed
                conceptDrawer: action.payload
            }
        }

        case constants.SET_RELATED_CONCEPT_DRAWER: {
            return {
                ...initialState,
                relatedConceptDrawer: action.payload
            }
        }

        case constants.SET_AUTHOR_DRAWER: {
            return {
                ...initialState,
                authorDrawer: action.payload
            }
        }

        case constants.SET_MOBILE_MEMES_MODE: {
            return {
                ...initialState,
                memesMode: action.payload
            }
        }

        case constants.SET_BIO_DRAWER: {
            return {
                ...initialState,
                bioDrawer: action.payload
            }
        }

        case constants.SET_RELATED_AUTHOR_DRAWER: {
            return {
                ...initialState,
                relatedAuthorDrawer: action.payload
            }
        }

        case constants.SET_NEWS_DRAWER: {
            return {
                ...initialState,
                newsDrawer: action.payload
            }
        }

        case constants.SET_COMMENTS_MODE: {
            return {
                ...initialState,
                commentsMode: action.payload
            }
        }
        
        default:
            return {
                ...initialState,
                memesMode: state.memesMode
            };
    }
}