import React from 'react'

export default function Title(props) {
    return (
        <span id= "message">
            <p>Contribute to the</p>
            {props.filter  && <span>
                                <b>{`- ${props.filter.label} -`}</b> 
                                and 
                               </span>}
            <b>{`- ${props.title.value} -`}</b>
            <p>Conversation</p>
        </span>
    )
}
