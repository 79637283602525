import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ConceptSidebar from "../../utills/conceptsSideBar";
import ConceptList from "../../utills/conceptsSideBar/conceptsList";
import * as scroll from "../../../helpers/scrolling";
import { SORT_BY_LIKES } from "../../../constants/global";
import * as actions from "../../../actions/authorActions";
import RelatedsButton from "./relatedsButton";
import "./firstCol.css";
import Drawer from "../../utills/drawer/drawer";
import { setDrawerOpen } from "../../../actions/mobile";
import SwitchAuthorThinker from "../../utills/Buttons/switchAuthorModes";
import history from "../../../router/history";

function FirstCol(props) {
  const {
    concepts,
    sorting,
    subFilter,
    selectedId,
    active,
    setActive,
    conceptDrawer,
    detail,
    user,
    relatedAuthors
  } = props;
  let keywords = true;
  const conceptCallBack = (index, concept) => {
    history.push(`${detail.slug}`);
    props.conceptCallBack(index, concept, keywords);
  };
  return (
    <Fragment>
      {/* Related Concepts Drawer */}
      <Drawer open={conceptDrawer} handleClose={() => props.setDrawerOpen(false)} direction="left">
        <ConceptList
          concepts={concepts}
          conceptCallBack={conceptCallBack}
          selectedId={selectedId}
        />
      </Drawer>

      <div
        id={scroll.firstColId}
        onScroll={scroll.handleFirstWheel}
        className="col-6 col-sm-6 col-md-2 right d-none d-md-block"
      >
        {/* {detail.Concept && <SwitchAuthorThinker checked={true} />} */}
        {user.isAdmin && (
          <RelatedsButton
            active={active}
            setActive={setActive}
            handleMouseEnter={props.handleMouseEnter}
            handleMouseLeave={props.handleMouseLeave}
          />
        )}
        {!subFilter && (
          <ConceptSidebar
            concepts={concepts}
            conceptCallBack={conceptCallBack}
            opacity={sorting === SORT_BY_LIKES}
            selectedId={selectedId}
          />
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = state => {
  return {
    concepts: state.navbar.concepts,
    subFilter: state.navbar.subFilter,
    sorting: state.author.sorting,
    detail: state.author.authorDetail,
    selectedId: state.navbar.selectedConceptId,
    conceptDrawer: state.mobile.conceptDrawer,
    selectedOption: state.navbar.selectedOption,
    user: state.auth.user,
    relatedAuthors: state.author.relatedAuthors || []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    conceptCallBack: bindActionCreators(actions.conceptCallBack, dispatch),
    setDrawerOpen: bindActionCreators(setDrawerOpen, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(FirstCol));

const styles = {
  reciprocal: {
    cursor: "pointer"
  }
};
