import React, { useState, useEffect } from "react";
import ToolTip from "react-portal-tooltip";
import Virals from "./Virals";
import { connect, useDispatch, useSelector } from "react-redux";
import { conceptCategory, memesCategory } from "../../../constants/global";
import history from "../../../router/history";
import { getMemerelateds } from "../../../actions/authorActions";
import { bindActionCreators } from "redux";
import OutsideClickHandler from "react-outside-click-handler";
import $ from "jquery";
import _, { includes } from "lodash";

const ViralsPage = props => {
  const [open, setOpen] = useState(false);
  const [top, setTop] = useState(0);
  const [active, setActive] = useState(false);
  const [selectedId, setSelectedConceptId] = useState(null);
  const [selectedSubButton, setSelectedSubButton] = useState("all");
  const subButton = ["all", "memes", "trivia", "jokes", "one-liner", "more"];
  const conceptReducer = useSelector(state => state.concepts);
  const concept = window.location.pathname.split("/")[2];

  const navbarReducer = useSelector(state => state.navbar);

  //   useEffect(() => {
  //     setTop($("#main-viral-div").offset().top);
  //   });

  const closeAll = () => {
    setTimeout(() => {
      setOpen(false);
      setActive(false);
    }, 300);
  };

  const onMouseEnter = () => {
    setOpen(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => setOpen(false));
    return () => {
      window.removeEventListener("scroll", setOpen(false));
    };
  }, []);
  const handleMemeTagMouseEnter = id => {
    props.getMemerelateds(id);
    setActive(true);
    setSelectedConceptId(id);
  };

  //   const clickMemeQuickLink = () => {
  //     history.push(`/${memesCategory}/${navbarReducer.selectedOption.value}?keyword=meme`);
  //   };

  return (
    <div>
      {subButton.map(elm => (
        <button
          type="button"
          onMouseEnter={() => {
            if (elm === "more") {
              onMouseEnter();
            }
          }}
          onClick={() => {
            if (elm === "memes") {
              history.push(`/memes/${concept}?keyword=meme`);
              setSelectedSubButton("memes");
              //   history.push(`/${memesCategory}/${navbarReducer.selectedOption.value}?keyword=meme`);
            } else if (elm === "trivia") {
              history.push(`/memes/${concept}?keyword=trivia`);
              setSelectedSubButton("trivia");
            } else if (elm === "jokes") {
              history.push(`/memes/${concept}?keyword=dad joke`);
              setSelectedSubButton("jokes");
            } else if (elm === "one-liner") {
              history.push(`/memes/${concept}?keyword=one-liner`);
              setSelectedSubButton("one-liner");
            } else if (elm === "all") {
              history.push(`/memes/${concept}`);
              setSelectedSubButton("all");
            }
          }}
          class={
            selectedSubButton === elm
              ? `btn bg-primary border text-white btn-sm rounded mr-1 mt-1`
              : `btn border text-dark btn-sm rounded mr-1 mt-1`
          }
        >
          {elm}
        </button>
      ))}
      {open && (
        <div
          className="viral-div cursor-pointer"
          style={{
            position: "fixed",
            // top: top,
            left: "200px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            zIndex: 2
          }}
        >
          {/* <div onClick={() => clickMemeQuickLink()} style={{ cursor: "pointer", padding: 10 }}>
            Meme
          </div> */}

          {conceptReducer &&
            conceptReducer.memesTagConcepts &&
            conceptReducer.memesTagConcepts.map(tag => {
              if (subButton.includes(tag.name)) return;
              return (
                <OutsideClickHandler onOutsideClick={closeAll}>
                  <div
                    id={`concept-${tag.id}`}
                    onClick={() =>
                      history.push(
                        `/${memesCategory}/${navbarReducer.selectedOption.value}?keyword=${tag.name}`
                      )
                    }
                    onMouseEnter={() => handleMemeTagMouseEnter(tag.id)}
                    style={{ cursor: "pointer", padding: 10 }}
                  >
                    {tag.name} {active}
                  </div>
                </OutsideClickHandler>
              );
            })}
          <div>
            {navbarReducer && navbarReducer.memeConceptRelateds && active && (
              <ToolTip
                active={active}
                parent={`#concept-${selectedId}`}
                position="right"
                arrow="top"
                tooltipTimeout={0}
              >
                <ul>
                  {navbarReducer.memeConceptRelateds.map(e => {
                    return (
                      <li
                        onClick={() => {
                          history.push(
                            `/${memesCategory}/${navbarReducer.selectedOption.value}?keyword=${e.name}`
                          );
                        }}
                        className="cursor-pointer"
                      >
                        {e.name}
                      </li>
                    );
                  })}
                </ul>
                {navbarReducer.memeConceptRelateds.length < 1 && (
                  <ul>
                    <li></li>
                  </ul>
                )}
              </ToolTip>
            )}
          </div>
        </div>
      )}
      {/* <Virals /> */}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getMemerelateds: bindActionCreators(getMemerelateds, dispatch)
});

export default connect(null, mapDispatchToProps)(ViralsPage);
