import React from "react";
import AsyncSelect from "react-select/lib/Async";
import { searchStyles } from "./searchStyles";
import search from "../../../helpers/search";

const searchPages = (inputValue, category, tags, perspectiveCount, stateTags) => {
  if (category === "Concept") {
    return new Promise(resolve => {
      search.concepts(inputValue, perspectiveCount, function(err, results) {
        resolve(results);
      });
    });
  }
  if (category === "unverifiedConcepts") {
    return new Promise(resolve => {
      search.allConcepts(inputValue, function(err, results) {
        resolve(results);
      });
    });
  }
  if (category === "Favourite Concepts") {
    return new Promise(resolve => {
      search.favouriteConcepts(inputValue, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "Keywords") {
    return new Promise(resolve => {
      search.keywords(inputValue, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "Concept Clusters") {
    return new Promise(resolve => {
      search.conceptClusters(inputValue, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "AuthorGroups") {
    let obj = {};
    obj.labels = tags;
    obj.category = category;
    obj.label = inputValue;
    return new Promise(resolve => {
      search.authorGroups(obj, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "Author") {
    return new Promise(resolve => {
      search.authors(inputValue, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "AuthorHavingBook") {
    return new Promise(resolve => {
      search.authorsHavingBooks(inputValue, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "Author Clusters") {
    return new Promise(resolve => {
      search.authorClusters(inputValue, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "Author Groups") {
    return new Promise(resolve => {
      search.authorGroupsNav(inputValue, tags, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "collections") {
    return new Promise(resolve => {
      search.booksCollection(inputValue, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "booksByTitle") {
    return new Promise(resolve => {
      search.booksByTitle(inputValue, function(err, results) {
        var booksArray = [];
        results.forEach(element => {
          if (element.author) {
            let booksObj = {
              category: element.category,
              color: element.color,
              id: element.id,
              label: (
                <div>
                  {element.label} <span style={{ color: "black" }}>|</span>{" "}
                  <span style={{ color: "red" }}>
                    {element.author.lastName || element.author.firstName}
                  </span>
                </div>
              ),
              value: element.value
            };
            booksArray.push(booksObj);
          }
        });
        resolve(booksArray);
      });
    });
  } else if (category === "booksByAuthor") {
    return new Promise(resolve => {
      search.booksByAuthor(inputValue, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "All") {
    const path = window.location.pathname.split("/")[1];
    return new Promise(resolve => {
      {
        path == "dictionary"
          ? search.all(inputValue, function(err, results) {
              resolve(results);
            })
          : path == "Concept-Clusters"
          ? search.conceptClusters(inputValue, function(err, results) {
              resolve(results);
            })
          : search.all(inputValue, function(err, results) {
              resolve(results);
            });
      }
    });
  } else if (category === "conceptsByCheckGroupId") {
    return new Promise(resolve => {
      search.conceptsByCheckGroupId(inputValue, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "authorsAndGroups") {
    return new Promise(resolve => {
      search.authorsAndGroups(inputValue, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "Memetags") {
    return new Promise(resolve => {
      search.memeTags(inputValue, tags, function(err, results) {
        resolve(results);
      });
    });
  } else if (category === "pos") {
    return new Promise(resolve => {
      search.allPos(inputValue, tags, stateTags, function(err, results) {
        resolve(results);
      });
    });
  }
};

const loadOptions = (inputValue, category, tags, perspectiveCount) => {
  const data = searchPages(inputValue, category, tags, perspectiveCount);
  return data.then(res => search.orderSearchByAlphabets(res, inputValue));
};

const Search = props => {
  const {
    value,
    noOptionsMessage,
    placeholder,
    category,
    tags,
    perspectiveCount,
    stateTags,
    onInputChange,
    autoFocus,
    navTab = ""
  } = props;

  return (
    <AsyncSelect
      className={props.className}
      value={props.value}
      loadOptions={inputValue =>
        loadOptions(inputValue, category, tags, perspectiveCount, stateTags)
      }
      onChange={props.onChange}
      onOpen={props.onOpen}
      onFocus={props.onFocus}
      noOptionsMessage={noOptionsMessage}
      autoFocus={autoFocus}
      placeholder={placeholder}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      styles={searchStyles}
      onInputChange={onInputChange}
      ref={props.searchRef}
      onBlur={props.onBlur}
    />
  );
};

export default Search;
