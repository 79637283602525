import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import App from "./App";
import Archives from "./components/Archive/ArchiveShare";
import { BLACK, DARK_RED, LIME, LIST_PINK } from "./constants/colors";
import {
  authorCategory,
  clusterCategory,
  conceptCategory,
  groupsCategory
} from "./constants/global";
import { Swipeable } from "react-swipeable";
import { bindActionCreators } from "redux";
import * as mobile from "./actions/mobile";
import * as navbarActions from "./actions/navbaractions";

import CommentsOverLay from "./components/utills/FacebookComment/overLay";
import $ from "jquery";
import { handleContextAndCopyPaste } from "./helpers/scrolling";
import { listenToDocumentSizeChange } from "./helpers/navbar";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import { ReactReduxContext } from "react-redux";
export function handelRightClick(event) {
  event.preventDefault();
}

const Main = props => {
  // <<<<<<< HEAD
  // window.oncontextmenu = function() {
  //   return false;
  // };
  // document.addEventListener("contextmenu", handelRightClick);
  // document.addEventListener("keydown", function(event) {
  //   if (event.ctrlKey) {
  //     event.preventDefault();
  //   }
  //   if (event.metaKey) {
  //     event.preventDefault();
  //   }
  // });

  // =======
  const { store } = useContext(ReactReduxContext);

  // if (!store.getState().auth.user.isAdmin) {
  //   window.oncontextmenu = function() {
  //     return false;
  //   };
  //   document.addEventListener("contextmenu", handelRightClick);
  //   document.addEventListener("keydown", function(event) {
  //     if (event.ctrlKey) {
  //       event.preventDefault();
  //     }
  //     if (event.metaKey) {
  //       event.preventDefault();
  //     }
  //   });
  // }

  // >>>>>>> 8ab7881502963db44794c622fd94ad49ff886949
  const [primary, setPrimary] = useState(true);
  const { selectedOption, isRandomActive } = props;
  // console.log("🚀 ~ file: Main.js ~ line 22 ~ Main ~ isRandomActive", isRandomActive)
  // console.log("🚀 ~ file: Concept.js ~ line 55 ~ params", window.location.search)
  useEffect(() => {
    handleContextAndCopyPaste(true);
    listenToDocumentSizeChange();
    var perfEntries = performance.getEntriesByType("navigation");
    for (var i = 0; i < perfEntries.length; i++) {
      console.log(perfEntries[i].type);
      if (window.location.search == "?random?" && perfEntries[i].type) {
        console.log("yes we can call the fun here");
      }
    }
  }, []);

  // useEffect(() => {
  //   const url = window.location.href;
  //   if (url.includes("/archive")) {
  //     setPrimary(false);
  //   }
  // });

  return (
    <Swipeable
      onSwipedLeft={() => props.handleLeftSwipe(false)}
      onSwipedRight={() => props.handleRightSwipe()}
    >
      <div style={styles.getFramStyles(selectedOption)}>
        <CommentsOverLay />
        {primary ? <App /> : <Archives />}
      </div>
      <ToastContainer />
    </Swipeable>
  );
};

const mapStateToProps = state => {
  return {
    selectedOption: state.navbar.selectedOption,
    isRandomActive: state.navbar.isRandomActive
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleRightSwipe: bindActionCreators(mobile.handleRightSwipe, dispatch),
    handleLeftSwipe: bindActionCreators(mobile.handleLeftSwipe, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));

const styles = {
  getFramStyles: selectedOption => {
    const category = selectedOption.category;
    const color =
      category === conceptCategory
        ? BLACK
        : category === authorCategory
        ? DARK_RED
        : category === clusterCategory
        ? LIST_PINK
        : category === groupsCategory
        ? LIME
        : "none";
    const style = {
      border: `1px solid ${color}`,
      minHeight: "100vh",
      overflow: "hidden"
    };
    return style;
  }
};
