import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import history from '../../../../router/history'
import {bindActionCreators} from 'redux'
import {setSubFilter} from '../../../../actions/navbaractions'
import { conceptCategory, authorCategory } from '../../../../constants/global'
import {resetScroll} from '../../../../helpers/scrolling'
import { LIGHT_BLUE } from '../../../../constants/colors'


const Footer = (props) => {
    const {perspective, label, selectedOption} = props
    const badgeClick = () => {
        resetScroll()
        if(selectedOption.category === conceptCategory)
        {
            const name = `${perspective.Author.firstName} ${perspective.Author.lastName}`
            props.onCountClick({id: perspective.AuthorId, type: authorCategory, value: name, label: name})
        }
        else
        {
            history.push(`/${conceptCategory}/${label}?${authorCategory}=${perspective.AuthorId}`)
        }
    }
    return (
        <div style={styles.badgeWrapper}>
                {perspective.similarAuthorCount > 1 && 
                    <div onClick={badgeClick} className="badgeTitle" style={styles.badge}>
                        {perspective.similarAuthorCount? "of ": ""}
                        {perspective.similarAuthorCount}
                    </div>
                }
        </div>
    )
}

Footer.propTypes = {
    perspective: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        selectedOption: state.navbar.selectedOption
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSubFilter: bindActionCreators(setSubFilter, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Footer))

const styles = {
    badgeWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    badge: {
        padding: "4px 3px",
        fontSize: 10,
        background: LIGHT_BLUE
    }
}