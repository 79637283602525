import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { bindActionCreators } from "redux";
import * as navbarActions from "../../actions/navbaractions";
import allEngaged from "../../images/allEngaged.png";
import Follow from "../../images/follow.svg";
import Following from "../../images/following.svg";
import brain from "../../images/brain.png";
import { withRouter } from "react-router-dom";
import history from "../../router/history";

import { isTagExist, getBgColor } from "./helpers";

const NavThirdRow = props => {
  const {
    isToolTip,
    setIsToolTip,
    navBarData,
    setNavBarData,
    onlyImageMemes,
    pos,
    memes,
    subNavTabs,
    selectedOption,
    isProverb,
    isAuthor,
    favouriteConcepts,
    favouriteAuthors,
    user,
    thirdKey,
    subFilter
  } = props;
      
  const [subNavMore, setSubNavMore] = useState(false);
  const [favouriteConcept, setFavouriteConcept] = useState(-1);
  const [favouriteAuthor, setFavouriteAuthor] = useState(-1);

  const pageName = window.location.pathname.split("/")[1];
  const conceptName = window.location.pathname.split("/")[2];
  const currentKeyword = window.location.href.split("=")[1];

  const imageMemes = memes && memes.filter(e => e.url !== null);

  useEffect(() => {
    if (favouriteConcepts && selectedOption.id) {
      const index = favouriteConcepts.findIndex(elm => elm.id === selectedOption.id);
      setFavouriteConcept(index);
    }
  }, [selectedOption, favouriteConcepts]);

  useEffect(() => {
    if (favouriteAuthors && selectedOption.id) {
      const index = favouriteAuthors.findIndex(elm => elm.id === selectedOption.id);
      setFavouriteAuthor(index);
    }
  }, [selectedOption, favouriteAuthors]);

  useEffect(() => {
    if (selectedOption) {
      props.checkProverbExist(selectedOption.value);
    }
    
  }, []);

  useEffect(() => {
    if (selectedOption) {
      props.checkProverbExist(selectedOption.value);
    }
  }, [selectedOption, window.location.href]);

  const getThirdNavIconOpacity = name => {
    switch (name) {
      case "memes":
        return onlyImageMemes || (imageMemes && imageMemes.length > 0) ? "1" : "0.1";
      case "trivia":
        return isTagExist(subNavTabs, "trivia", memes) ? "1" : "0.1";
      case "dadJokes":
        return isTagExist(subNavTabs, pageName === "pos" ? "dad joke" : "dad-joke", memes)
          ? "1"
          : "0.1";
      case "oneLiners":
        return isTagExist(subNavTabs, "one-liner", memes) ? "1" : "0.1";
      case "more":
        return isTagExist(subNavTabs, "more", memes) ? "1" : "0.1";
      case "definitions":
        return selectedOption.hasPerspective || selectedOption.count > 0 ? true : false;
      case "about":
      return  selectedOption.author > 0 || selectedOption.hasAbout == true ? "1" : "0.1"

      default:
        break;
    }
  };

  const getThirdNavIconCursor = name => {
    switch (name) {
      case "memes":
        return onlyImageMemes || (imageMemes && imageMemes.length > 0) ? "pointer" : "default";
      case "trivia":
        return isTagExist(subNavTabs, "trivia", memes) ? "pointer" : "default";
      case "dadJokes":
        return isTagExist(subNavTabs, pageName === "pos" ? "dad joke" : "dad-joke", memes)
          ? "pointer"
          : "default";
      case "oneLiners":
        return isTagExist(subNavTabs, "one-liner", memes) ? "pointer" : "default";
      case "more":
        return isTagExist(subNavTabs, "more", memes) ? "pointer" : "default";

      default:
        break;
    }
  };

  const getThirdNavIconClick = name => {
    switch (name) {
      case "memes":
        onlyImageMemes || (imageMemes && imageMemes.length > 0)
          ? history.push(`/memes/${selectedOption.value.split(" ").join("-")}?keyword=meme`)
          : history.push(`/meme`);
        break;
      case "trivia":
        isTagExist(subNavTabs, "trivia", memes)
          ? history.push(`/memes/${selectedOption.value.split(" ").join("-")}?keyword=trivia`)
          : history.push(`/trivia`);
        break;
      case "dadJokes":
        isTagExist(subNavTabs, pageName === "pos" ? "dad joke" : "dad-joke", memes)
          ? history.push(`/memes/${selectedOption.value.split(" ").join("-")}?keyword=dad-joke`)
          : history.push(`/dad-joke`);
        break;
      case "oneLiners":
        isTagExist(subNavTabs, "one-liner", memes)
          ? history.push(`/memes/${selectedOption.value.split(" ").join("-")}?keyword=one-liner`)
          : history.push(`/one-liner`);
        break;
      case "more":

      default:
        break;
    }
  };

  const getThirdNavIconBgColor = name => {
    switch (name) {
      case "all":
        return pageName === "Concepts" ? "black" : "white";
      case "dictionary":
        return pageName === "dictionary" ? "red" : "white";
      case "memes":
        return currentKeyword === "meme" ? "#f0da49" : "white";
      case "trivia":
        return currentKeyword === "trivia" ? "#78e855" : "white";
      case "dadJokes":
        return currentKeyword === "dad-joke" ? "#ffa195" : "white";
      case "oneLiners":
        return currentKeyword === "one-liner" ? "#ff9400" : "white";
      case "by":
        return pageName === "Authors" ? "red" : "white";
      case "about":
        return pageName === "dictionary" ? "red" : "white";

      default:
        return "black";
    }
  };

  const getThirdNavIconColor = name => {
    switch (name) {
      case "all":
        return pageName === "Concepts" ? "white" : "black";
      case "dictionary":
        return pageName === "dictionary" ? "white" : "red";
      case "trivia":
        return currentKeyword === "trivia" ? "black" : "green";
      case "dadJokes":
        return currentKeyword === "dad-joke" ? "black" : "#b41600";
      case "oneLiners":
        return currentKeyword === "one-liner" ? "black" : "orange";
      case "by":
        return pageName === "Authors" ? "white" : "red";
      case "about":
        return pageName === "dictionary" ? "white" : "red";

      default:
        return "black";
    }
  };

  const thirdNavBarBorder = name => {
    switch (name) {
      case "all":
        return "1px solid black";
      case "dictionary":
        return "1px solid transparent";
      case "memes":
        return currentKeyword === "meme" ? "1px solid #f0da49" : "1px solid transparent";
      case "trivia":
        return currentKeyword === "trivia" ? "1px solid #78e855" : "1px solid transparent";
      case "dad-joke":
        return currentKeyword === "dad-joke" ? "1px solid #ffa195" : "1px solid transparent";
      case "one-liner":
        return currentKeyword === "one-liner" ? "1px solid #ff9400" : "1px solid transparent";

      default:
        return "1px solid transparent";
    }
  };

  const thirdNavBarMargin = name => {
    console.log("name", name);
    switch (name) {
      case "all":
        return "26.5%";
      case "dictionary":
        return "26.5%";
      case "memes":
        return currentKeyword === "meme" ? "25%" : "29%";
      case "trivia":
        return currentKeyword === "trivia" ? "25%" : "29%";
      case "dad-joke":
        return currentKeyword === "dad-joke" ? "25%" : "29%";
      case "one-liner":
        return currentKeyword === "one-liner" ? "25%" : "29%";

      default:
        return "29%";
    }
  };

  const getThirdNavIconBorderColor = name => {
    switch (name) {
      case "all":
        return pageName === "Concepts" ? "#a2a1a1" : "#616161";
      case "dictionary":
        return pageName === "dictionary" ? "black" : "#97175e";
      case "memes":
        return currentKeyword === "meme" ? "black" : "#dcc62f";
      case "trivia":
        return currentKeyword === "trivia" ? "black" : "#007000";
      case "dadJokes":
        return currentKeyword === "dad-joke" ? "black" : "#b41500";
      case "oneLiners":
        return currentKeyword === "one-liner" ? "black" : "orange";

      default:
        return "black";
    }
  };

  const getSubNavMore = () => {
    let array = [];
    if (subNavTabs.length === 0) {
      return "No more Tags!";
    }
    subNavTabs.forEach(e => {
      if (e.slug != "meme" && e.slug != "trivia" && e.slug != "one-liner" && e.slug != "dad-joke")
        array.push(e.slug);
    });
    if (isTagExist(subNavTabs, "virals", memes)) {
      array.unshift("Virals");
    }
    if (isProverb) {
      array.unshift("Proverbs");
    }

    return array.map(e => (
      <Dropdown.Item
        href="#"
        className="moreThirdRow"
        style={{ cursor: "pointer", fontSize: "15px", padding: "5px 5px 5px 10px" }}
        onClick={() => {
          if (e === "Virals") {
            history.push(`/memes/${selectedOption.value.split(" ").join("-")}`);
          } else if (e === "Proverbs") {
            history.push(`/proverbs/${selectedOption.value.split(" ").join("-")}`);
          } else {
            history.push(`/QL/${selectedOption.value.split(" ").join("-")}?keyword=${e}`);
          }
        }}
      >
        {e}
      </Dropdown.Item>
    ));
  };

  const handleFollowConcept = () => {
    props.addFavouriteConcept(selectedOption);
  };

  const handleFollowingConcept = () => {
    props.removeFavouriteConcept(selectedOption);
  };

  const handleFollowAuthor = () => {
    props.addFavouriteAuthor(selectedOption);
  };

  const handleFollowingAuthor = () => {
    props.removeFavouriteAuthor(selectedOption);
  };

  return (
    <div className="thirdMainDiv" style={{ backgroundColor: getBgColor(navBarData, thirdKey) }}>
      {/* NEW THIRD NAVBAR START */}

      <div
        style={{
          width: isAuthor ? "550px" : "50.8%",
          marginLeft: thirdNavBarMargin(navBarData),
          marginTop: "6px",
          height: "54px",
          backgroundColor: "white",
          borderTop: thirdNavBarBorder(navBarData),
          borderLeft: thirdNavBarBorder(navBarData),
          borderRight: thirdNavBarBorder(navBarData),
          boxShadow: thirdKey || subFilter ? "rgb(137 129 129) 1px -10px 20px 3px" : "none"
        }}
      >
        {/* ALL */}
        <span
          className="thirdNavIcon"
          style={{
            textAlign: "center",
            height: "48px",
            marginTop: "2px",
            width: "48px",
            fontFamily: "garamond",
            cursor: "pointer",
            backgroundColor: getThirdNavIconBgColor("all"),
            color: getThirdNavIconColor("all"),
            borderColor: getThirdNavIconBorderColor("all")
          }}
          onClick={() => history.push(`/Concepts/${selectedOption.value.split(" ").join("-")}`)}
        >
          <div
            style={{
              fontWeight: "bold",
              marginTop: "6px"
            }}
          >
            ALL
            <br />
            <i className="fas fa-infinity"></i>
          </div>
          <span className="toolTip">Concepts</span>
        </span>
        {/* DICTIONARY */}
        {!isAuthor && (
          <span
            className="thirdNavIcon"
            style={{
              fontSize: "16px",
              cursor: "pointer",
              backgroundColor: getThirdNavIconBgColor("dictionary"),
              color: getThirdNavIconColor("dictionary"),
              opacity: getThirdNavIconOpacity("definitions") ? "1" : "0.1",
              borderColor: getThirdNavIconBorderColor("dictionary")
            }}
            onClick={
              getThirdNavIconOpacity("definitions") &&
              (() => history.push(`/dictionary/${selectedOption.value.split(" ").join("-")}`))
            }
          >
            (n.)
            <span className="toolTip">Definitions</span>
          </span>
        )}
        {/* BY */}
        {isAuthor && (
          <span
            className="thirdNavIcon"
            style={{
              fontSize: "16px",
              fontFamily: "garamond",
              cursor: "pointer",
              backgroundColor: getThirdNavIconBgColor("by"),
              color: getThirdNavIconColor("by")
            }}
            onClick={() => history.push(`/Authors/${selectedOption.value.toString().split(" ").join("-")}`)}
          >
            BY
            <span className="toolTip">Author</span>
          </span>
        )}
        {/* ABOUT */}
        {isAuthor && (
          <span
            className="thirdNavIcon"
            style={{
              fontSize: "11px",
              fontFamily: "garamond",
              width: "46px",
              cursor: "pointer",
              backgroundColor: getThirdNavIconBgColor("about"),
              color: getThirdNavIconColor("about"),
              opacity: getThirdNavIconOpacity("about") 
            }}
            onClick={
              getThirdNavIconOpacity("about") &&
              (() => history.push(`/dictionary/${selectedOption.value.toString().split(" ").join("-")}`))
            }
          >
            ABOUT
            <span className="toolTip">Definitions</span>
          </span>
        )}
        {/* MEMES */}
        <span
          className="thirdNavIcon"
          style={{
            fontSize: "16px",
            cursor: getThirdNavIconCursor("memes"),
            opacity: getThirdNavIconOpacity("memes"),
            backgroundColor: getThirdNavIconBgColor("memes"),
            borderColor: getThirdNavIconBorderColor("memes")
          }}
          onClick={() => getThirdNavIconClick("memes")}
        >
          me-
          <br />
          mes
          <span className="toolTip">Memes</span>
        </span>
        {/* TRIVIA */}
        <span
          className="thirdNavIcon"
          style={{
            cursor: getThirdNavIconCursor("trivia"),
            opacity: getThirdNavIconOpacity("trivia"),
            color: getThirdNavIconColor("trivia"),
            backgroundColor: getThirdNavIconBgColor("trivia"),
            borderColor: getThirdNavIconBorderColor("trivia")
          }}
          onClick={() => getThirdNavIconClick("trivia")}
        >
          <img src={brain} width="24px" height="24px" />
          <span className="toolTip">Trivia</span>
        </span>
        {/* DAD-JOKES */}
        <span
          className="thirdNavIcon"
          style={{
            cursor: getThirdNavIconCursor("dadJokes"),
            opacity: getThirdNavIconOpacity("dadJokes"),
            color: getThirdNavIconColor("dadJokes"),
            backgroundColor: getThirdNavIconBgColor("dadJokes"),
            borderColor: getThirdNavIconBorderColor("dadJokes")
          }}
          onClick={() => getThirdNavIconClick("dadJokes")}
        >
          <i class="far fa-meh-rolling-eyes" style={{ fontSize: "24px" }}></i>
          <span className="toolTip">Dad Jokes</span>
        </span>
        {/* ONE-LINERS */}
        <span
          className="thirdNavIcon"
          style={{
            cursor: getThirdNavIconCursor("oneLiners"),
            opacity: getThirdNavIconOpacity("oneLiners"),
            color: getThirdNavIconColor("oneLiners"),
            backgroundColor: getThirdNavIconBgColor("oneLiners"),
            borderColor: getThirdNavIconBorderColor("oneLiners")
          }}
          onClick={() => getThirdNavIconClick("oneLiners")}
        >
          <i class="far fa-grin-squint-tears" style={{ fontSize: "24px" }}></i>
          <span className="toolTip">One Liners</span>
        </span>
        {/* MORE */}
        <span
          className="thirdNavIcon"
          style={{
            borderRadius: "50%",
            cursor: getThirdNavIconCursor("more"),
            opacity: getThirdNavIconOpacity("more")
          }}
        >
          <i class="fas fa-ellipsis-h" style={{ fontSize: "24px" }}></i>
          <Dropdown style={{ marginLeft: "-45px", marginTop: "-20px", width: "auto" }}>
            <Dropdown.Toggle
              style={{ opacity: 0, color: "grey", fontSize: "30px " }}
              variant="transparent"
              id="dropdown-basic"
            ></Dropdown.Toggle>
            <Dropdown.Menu style={{ border: "1px solid grey" }}>{getSubNavMore()}</Dropdown.Menu>
          </Dropdown>
        </span>
        <span
          style={{
            marginLeft: 5,
            borderLeft: "2px solid",
            paddingLeft: 5
          }}
        >
          {pageName === "Authors" || pageName === "authors" ? (
            favouriteAuthor > -1 ? (
              <img
                src={Following}
                alt="following"
                style={{ cursor: "pointer" }}
                onClick={handleFollowingAuthor}
              />
            ) : user && user.id ? (
              <>
                <span className="followBtn">
                  <img
                    src={Follow}
                    alt="follow"
                    onClick={handleFollowAuthor}
                    style={{ cursor: "pointer" }}
                  />
                  <span className="followToolTip">
                    Follow this page in your ‘feed’ (in user profile)
                  </span>
                </span>
              </>
            ) : (
              <>
                <span>
                  <img
                    src={Follow}
                    alt="follow"
                    // onClick={handleFollowAuthor}
                    style={{ opacity: 0.1 }}
                  />
                  <span className="followToolTip">Log in to follow this page in your ‘feed’</span>
                  <div
                    style={{
                      backgroundColor: user.id ? "#1fb100" : "red",
                      width: 16,
                      height: 16,
                      borderRadius: "50%",
                      marginTop: "-20px",
                      marginLeft: "515px"
                    }}
                  ></div>
                </span>
              </>
            )
          ) : favouriteConcept > -1 ? (
            <img
              src={Following}
              alt="following"
              style={{ cursor: "pointer" }}
              onClick={handleFollowingConcept}
            />
          ) : user && user.id ? (
            <>
              <span className="followBtn">
                <img
                  src={Follow}
                  alt="follow"
                  onClick={handleFollowConcept}
                  style={{ cursor: "pointer" }}
                />
                <span className="followToolTip">
                  Follow this page in your ‘feed’ (in user profile)
                </span>
              </span>
            </>
          ) : (
            <>
              <span className="followBtn">
                <img
                  src={Follow}
                  alt="follow"
                  // onClick={handleFollowConcept}
                  style={{ opacity: 0.1 }}
                />
                <span className="followToolTip">Log in to follow this page in your ‘feed’</span>
                <div
                  style={{
                    backgroundColor: user.id ? "#1fb100" : "red",
                    width: 16,
                    height: 16,
                    borderRadius: "50%",
                    marginTop: "-20px",
                    marginLeft: "455px"
                  }}
                ></div>
              </span>
            </>
          )}
        </span>
      </div>

      {/* NEW THIRD NAVBAR END */}

      {/* OLD NAVBAR START */}

      {/* <span
        className="navBarTabs"
        style={{
          marginTop: "-2px",
          fontWeight: pageName == "Concepts" ? "bold" : ""
        }}
        onClick={() => {
          history.push(`/Concepts/${selectedOption.value.split(" ").join("-")}`);
          setNavBarData("default");
        }}
      >
        <img src={allEngaged} width="26px" height="26px" />
      </span>
      <span
        className="navBarTabs"
        style={{ marginTop: "-2px", fontWeight: pageName == "dictionary" ? "bold" : "" }}
        onClick={() => {
          history.push(`/dictionary/${selectedOption.value.split(" ").join("-")}`);
          setNavBarData("dictionary");
        }}
      >
        <img src={allEngaged} width="26px" height="26px" />
      </span>
      <span
        className="navBarTabs"
        style={{
          marginTop: "-2px",
          backgroundColor: "#77e754",
          fontWeight: pageName == "dictionary" ? "bold" : "",
          border: "2px solid black",
          borderRadius: "6px"
        }}
        onClick={() => {
          history.push(`/dictionary/${selectedOption.value.split(" ").join("-")}`);
          setNavBarData("dictionary");
        }}
      >
        <img src={brain} width="22px" height="22px" style={{ padding: "4px" }} />
      </span>
      {isTagExist(subNavTabs, "virals", memes) && (
        <div
          className="navBarTabs1 dropdownVirals"
          style={{
            marginTop: "-2px",
            color: pageName === "memes" ? "#067e75" : "",
            fontWeight: pageName == "memes" ? "bold" : ""
          }}
        >
          <span
            onClick={() => {
              history.push(`/memes/${selectedOption.value.split(" ").join("-")}`);
              setIsToolTip(true);
              setTimeout(() => {
                setIsToolTip(false);
              }, 100);

              setNavBarData("virals");
            }}
          >
            {"  ·  "}VIRALS
          </span>
          <div className="dropdown-contentVirals" style={{ marginLeft: isToolTip ? "100px" : "" }}>
            {(onlyImageMemes || (imageMemes && imageMemes.length > 1)) && (
              <div
                className="dropdown-option"
                onClick={() => {
                  history.push(`/memes/${selectedOption.value.split(" ").join("-")}?keyword=meme`);
                  setIsToolTip(true);
                  setTimeout(() => {
                    setIsToolTip(false);
                  }, 100);

                  setNavBarData("memes");
                }}
              >
                MEMES
              </div>
            )}
            {isTagExist(subNavTabs, "trivia", memes) && (
              <div
                className="dropdown-option"
                onClick={() => {
                  history.push(
                    `/memes/${selectedOption.value.split(" ").join("-")}?keyword=trivia`
                  );
                  setIsToolTip(true);
                  setTimeout(() => {
                    setIsToolTip(false);
                  }, 100);

                  setNavBarData("trivia");
                }}
              >
                TRIVIA
              </div>
            )}
            {isTagExist(subNavTabs, "one-liner", memes) && (
              <div
                className="dropdown-option"
                onClick={() => {
                  history.push(
                    `/memes/${selectedOption.value.split(" ").join("-")}?keyword=one-liner`
                  );
                  setIsToolTip(true);
                  setTimeout(() => {
                    setIsToolTip(false);
                  }, 100);

                  setNavBarData("one-liner");
                }}
              >
                ONE-LINERS
              </div>
            )}

            {isTagExist(subNavTabs, pageName === "pos" ? "dad joke" : "dad-joke", memes) && (
              <div
                className="dropdown-option"
                onClick={() => {
                  history.push(
                    `/memes/${selectedOption.value.split(" ").join("-")}?keyword=dad-joke`
                  );
                  setIsToolTip(true);
                  setTimeout(() => {
                    setIsToolTip(false);
                  }, 100);

                  setNavBarData("dad-joke");
                }}
              >
                DAD JOKES
              </div>
            )}
          </div>
        </div>
      )}

       {pos.length > 0 && (
        <span
          className="navBarTabs"
          style={{
            fontFamily: "garamond",
            fontWeight: pageName == "pos" ? "bold" : "",
            whiteSpace: "pre"
          }}
          onClick={() => history.push(`/pos/${selectedOption.value.split(" ").join("-")}?n`)}
        >
          Adj/Verbs{"  ·  "}
        </span>
      )} 
      {isTagExist(subNavTabs, "more", memes) && (
        <div
          className="navBarTabs1 dropdownMore"
          style={{ marginTop: "1px", fontSize: "10px" }}
          onClick={() => setSubNavMore(!subNavMore)}
        >
          {"  ·  "}MORE
          <div className="dropdown-contentMore" style={{ marginLeft: isToolTip ? "100px" : "" }}>
            {getSubNavMore()}
          </div>
        </div>
      )}{" "}
      */}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    checkProverbExist: bindActionCreators(navbarActions.checkProverb, dispatch),
    addFavouriteConcept: bindActionCreators(navbarActions.addFavouriteConcept, dispatch),
    removeFavouriteConcept: bindActionCreators(navbarActions.removeFavouriteConcept, dispatch),
    addFavouriteAuthor: bindActionCreators(navbarActions.addFavouriteAuthor, dispatch),
    removeFavouriteAuthor: bindActionCreators(navbarActions.removeFavouriteAuthor, dispatch)
  };
};
const mapStateToProps = state => {
  return {
    onlyImageMemes: state.navbar.selectedOption.onlyImageMemes,
    subNavTabs: state.concepts.memesTagConcepts,
    pos: state.pos.posContents,
    memes: state.memes.memes,
    selectedOption: state.navbar.selectedOption,
    isProverb: state.navbar.isProverb,
    isAuthor: state.navbar.isAuthor,
    favouriteConcepts: state.auth.favouriteConcepts,
    favouriteAuthors: state.auth.favouriteAuthors,
    thirdKey: state.navbar.thirdKey,
    user: state.auth.user,
    subFilter: state.navbar.subFilter
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavThirdRow));
