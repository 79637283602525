import { toLower } from "lodash";
import { MAIN_WHITE } from "../constants/colors";
import * as global from "../constants/global";
import history from "../router/history";

const conceptsSorter = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const sortConcepts = (a, b) => {
  let f = a.name.charAt(0).toUpperCase();
  let s = b.name.charAt(0).toUpperCase();
  let fl = b.name.length;
  let sl = a.name.length;
  return s < f ? 1 : s > f ? -1 : sl < fl ? -1 : sl > fl ? 1 : 0;
};

export const mapArrayToColumns = (array, skip) => {
  try {
    let itemsPerColumn = Math.ceil(array.length / 3);
    let data = [];
    for (let i = 0; i < itemsPerColumn; i++) {
      for (let j = i; j < array.length; j = j + itemsPerColumn) {
        data.push(array[j]);
      }
    }
    return data;
  } catch (e) {
    console.log("error is ", e);
  }
};

export const sortAndMap = (conceptsArray, skip) => {
  let concepts = conceptsArray.sort(sortConcepts);
  concepts = concepts.filter(x => x.name !== skip);
  return mapArrayToColumns(concepts, skip);
};

export const getConceptItem = concept => {
  return {
    id: concept.id,
    value: concept.name,
    label: concept.name,
    slug: concept.slug,
    category: global.conceptCategory,
    autor: concept.author,
    pictureLink: concept.pictureLink
  };
};

export const moveOriginalConceptToTop = (concepts, originalConceptId) => {
  let data = [];
  const index = concepts.findIndex(concept => concept.id === originalConceptId);
  if (index > -1) {
    const originalConcept = concepts[index];
    concepts = concepts.filter(concept => concept.id === originalConceptId);
    data = [...originalConceptId, ...concepts];
  }
  return data;
};

export const removePunctuation = paragraph => {
  const newLineExp = /\n/g;
  paragraph = paragraph.replace(newLineExp, " ");
  const exp = /[^a-zA-Z0-9\s-]/g;
  return paragraph.replace(exp, "");
};

export const reOrderArrayIndex = (originalArray, from, to) => {
  originalArray.splice(to, 0, originalArray.splice(from, 1)[0]);
  return originalArray;
};

export const colorBySelectedCategory = () => {
  // handling with route not with navbar.selectedOption becuase if we use redux variable then first we will have white color by default and navbar color will change after First APi called out
  const colors = global.backgroundColorsByCategory;
  const path = history.location.pathname;
  let color = MAIN_WHITE;

  if (path) {
    if (toLower(path).includes(`/${toLower(global.authorCategory)}/`)) {
      return colors[global.authorCategory];
    } else if (toLower(path).includes(`/${toLower(global.conceptCategory)}/`)) {
      return colors[global.conceptCategory];
    } else if (toLower(path).includes(`/${toLower(global.clusterCategory)}/`)) {
      return colors[global.clusterCategory];
    } else if (toLower(path).includes(`/${toLower(global.groupsCategory)}/`)) {
      return colors[global.groupsCategory];
    } else if (toLower(path).includes(`/${toLower(global.groupsServerCategory)}/`)) {
      return colors[global.groupsCategory];
    } else if (toLower(path).includes(`/${toLower(global.similarAuthorCategory)}/`)) {
      return colors[global.similarAuthorCategory];
    } else if (toLower(path).includes(`/${toLower(global.memesCategory)}/`)) {
      return colors[global.memesCategory];
    }
    if (toLower(path).includes(`/${toLower(global.QLCategory)}/`)) {
      return colors[global.memesCategory];
    }
    if (path.split("/")[1] === "book") {
      return colors[global.singleBook];
    } else {
      return MAIN_WHITE;
    }
  }

  if (color) {
    return color;
  } else {
    return MAIN_WHITE;
  }
};

export default conceptsSorter;
