import axios from "axios";
import { BASE_URL } from "../constants/global";

export const totalTermsWithTopTerms = () => {
  return axios.get(`${BASE_URL}/count`);
};

export const UpdateIsFeed = id => {
  return axios.post(`${BASE_URL}/user/updateFeedMode`, { id });
};

export const addFavouriteAuthor = (userId, authorId) => {
  return axios.post(`${BASE_URL}/user/favourite/author`, { userId, authorId });
};

export const removeFavouriteAuthor = (userId, authorId) => {
  return axios.delete(`${BASE_URL}/user/favourite/author`, { data: { userId, authorId } });
};

export const addFavouriteConcept = (userId, conceptId) => {
  return axios.post(`${BASE_URL}/user/favourite/concept`, { userId, conceptId });
};

export const removeFavouriteConcept = (userId, conceptId) => {
  return axios.delete(`${BASE_URL}/user/favourite/concept`, { data: { userId, conceptId } });
};

export const getNews = () => {
  return axios.get(`${BASE_URL}/news`);
};

export const getNewsConcept = newsId => {
  return axios.get(`${BASE_URL}/news/concept/${newsId}`);
};

export const handleNewPerspectiveSubmit = (data, token) => {
  return axios.post(`${BASE_URL}/newPerspective`, data, { headers: { Authorization: token } });
};

export const updatePerspective = (perspectiveId, perspective) => {
  return axios.put(`${BASE_URL}/perspectives/${perspectiveId}`, perspective);
};
export const updatePronoun = data => {
  return axios.post(`${BASE_URL}/concepts/edit`, data);
};

export const getConcept = id => {
  return axios.get(`${BASE_URL}/concepts/get?id=${id}`);
};

export const deltePerspective = perspectiveId => {
  return axios.delete(`${BASE_URL}/perspectives/${perspectiveId}`);
};

export const updateConceptImage = (id, formData, token) => {
  return axios.put(`${BASE_URL}/admin/concept/image/${id}`, formData, {
    headers: { Authorization: token }
  });
};

export function updateAuthorImage(formData, token) {
  return axios.put(`${BASE_URL}/admin/author/image`, formData, {
    headers: { Authorization: token }
  });
}
