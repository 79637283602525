import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as navbarActions from "../../../actions/navbaractions";
import { Container, Row, Button } from "reactstrap";
import ToolTip from "react-portal-tooltip";
import AuthorCard, { SortableAuthorCard } from "./authorCard";
import { getAuthorItem } from "../../../helpers/authorHelpers";
import "./firstCol.css";
import {
  refreshPriority,
  removeAuthorReciprocal,
  addAuthorReciprocal,
  fetchTempAuthors,
  handleTempReciprocalClick,
  deleteRelatedAuthor,
  handleSortEnd
} from "../../../actions/authorActions";
import { getTempAuthors } from "../../../selectors";
import Search from "../../TopNavBar/search/search";
import { SortableContainer } from "react-sortable-hoc";
import history from "../../../router/history";

const RelatedConcepts = props => {
  const {
    detail,
    selectedOption,
    relatedAuthors,
    active,
    setActive,
    tempLoading,
    tempAuthors,
    refreshLoading,
    user: { isAdmin },
    relatedAuthorsLoading
  } = props;
  const [show, setShow] = useState(false);
  const [top, setTop] = useState(0);
  const [id, setId] = useState(-1);
  const [time, setTime] = useState("");
  const [visible, setVisible] = useState(false);
  let enter = false;

  useEffect(() => {
    if (active) {
      const scroll = window.pageYOffset;
      if (detail.Concept) {
        setTop(scroll + 100);
      } else {
        setTop(scroll + 50);
      }

      setShow(true);
    } else {
      setShow(false);
    }
  }, [active]);

  const onAuthorClick = author => {
    setActive(false);
    const item = getAuthorItem(author);
    props.setSelectedItem(item);
  };

  const handleReciprocalClick = author => {
    if (author.reciprocalExist) {
      props.removeAuthorReciprocal(author.id);
    } else {
      props.addAuthorReciprocal(author.id);
    }
  };

  const handleCardMouseEnter = author => {
    setTime(
      setTimeout(() => {
        setId(author.id);
        setVisible(true);
        props.fetchTempAuthors(author.id);
      }, 500)
    );
  };

  const handleCardMouseLeave = () => {
    clearTimeout(time);
    setVisible(false);
  };

  const handleToolTipEnter = () => {
    enter = true;
    props.handleTooltipMouseEntered();
    setVisible(true);
  };

  const handleToolTipLeave = () => {
    enter = false;
    props.handleTooltipMouseLeave();
    setVisible(false);
  };

  const handleSearch = inputValue => {
    inputValue.reciprocalExist = false;
    // just re-using the logic
    props.handleTempReciprocalClick(inputValue);
  };

  const handleParentMouseEnter = () => {
    props.handleTooltipMouseEntered();
  };

  const handleParentMouseLeave = () => {
    props.handleTooltipMouseLeave();
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      props.handleSortEnd(oldIndex, newIndex);
    }
  };

  const goToSimilarAuthor = () => {
    history.push(`/similar-authors/${selectedOption.slug}`);
  };

  return (
    show && (
      <Container
        id="listWrapper"
        onMouseEnter={handleParentMouseEnter}
        onMouseLeave={handleParentMouseLeave}
        style={{
          height: show ? "73vh" : "0px",
          width: "100%",
          paddingTop: 10,
          zIndex: 10,
          width: 400
        }}
        className="background"
      >
        {isAdmin && (
          <Row style={styles.searchWrapper}>
            {/* <i className="fa fa-refresh" style={styles.refresh} onClick={props.refreshPriority}>
                        refresh
                    </i> */}
            <div className="width-200" style={styles.innerWrapper}>
              <Search
                className="ml-2"
                value=""
                category="Author"
                onChange={handleSearch}
                placeholder="Search Author"
              />
            </div>
          </Row>
        )}
        <Button style={styles.btn} onClick={goToSimilarAuthor}>
          View a collection of these thinker's concepts
        </Button>
        {relatedAuthorsLoading ? (
          <span style={styles.loading}>Loading, Please Wait...</span>
        ) : (
          <SortableList
            items={relatedAuthors}
            onSortEnd={handleSortEnd}
            axis="xy"
            pressDelay={100}
            onAuthorClick={onAuthorClick}
            handleReciprocalClick={handleReciprocalClick}
            handleMouseEnter={handleCardMouseEnter}
            handleMouseLeave={handleCardMouseLeave}
            deleteRelatedAuthor={props.deleteRelatedAuthor}
            isAdmin={isAdmin}
            tools={true}
          />
        )}

        <ToolTip
          active={visible}
          parent={`#author${id}`}
          position="right"
          arrow="center"
          id="author-tooltip"
        >
          <div
            onMouseEnter={handleToolTipEnter}
            onMouseLeave={handleToolTipLeave}
            style={styles.outerContainer}
          >
            {tempLoading ? (
              <span>Loading...</span>
            ) : (
              <Row>
                {tempAuthors &&
                  tempAuthors.map(author => (
                    <AuthorCard
                      author={author}
                      onAuthorClick={() => {}}
                      handleReciprocalClick={props.handleTempReciprocalClick}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      tools={false}
                      isAdmin={isAdmin}
                      detailMode={false}
                    />
                  ))}
              </Row>
            )}
          </div>
        </ToolTip>
      </Container>
    )
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedItem: bindActionCreators(navbarActions.setSelectedItem, dispatch),
    removeAuthorReciprocal: bindActionCreators(removeAuthorReciprocal, dispatch),
    addAuthorReciprocal: bindActionCreators(addAuthorReciprocal, dispatch),
    fetchTempAuthors: bindActionCreators(fetchTempAuthors, dispatch),
    handleTempReciprocalClick: bindActionCreators(handleTempReciprocalClick, dispatch),
    deleteRelatedAuthor: bindActionCreators(deleteRelatedAuthor, dispatch),
    handleSortEnd: bindActionCreators(handleSortEnd, dispatch),
    refreshPriority: bindActionCreators(refreshPriority, dispatch)
  };
};

const mapStateToProps = state => {
  return {
    relatedAuthors: state.author.relatedAuthors,
    relatedAuthorsLoading: state.author.relatedAuthorsLoading,
    selectedOption: state.navbar.selectedOption,
    tempAuthors: getTempAuthors(state),
    user: state.auth.user,
    tempLoading: state.author.tempLoading,
    detail: state.author.authorDetail,
    refreshLoading: state.author.refreshLoading
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedConcepts);

const styles = {
  outerContainer: {
    width: 300,
    height: 300,
    overflowY: "scroll",
    overflowX: "hidden"
  },
  searchWrapper: {
    display: "flex",
    justifyContent: "center",
    height: 50,
    backgroundColor: "white"
  },
  innerWrapper: {
    position: "absolute",
    zIndex: 14
  },
  refresh: {
    marginLeft: "auto",
    cursor: "pointer",
    marginRight: 5
  },
  btn: {
    border: "1px solid black",
    background: "white",
    color: "black",
    width: "100%",
    marginBottom: 5,
    borderRadius: 9
  },
  loading: {
    fontWeight: 700,
    fontSize: 20,
    color: "gray"
  }
};

const SortableList = SortableContainer(props => {
  const { items } = props;
  return (
    <Row style={{ zIndex: 3, backgroundColor: "#fff" }}>
      {items &&
        items.map((author, index) => (
          <SortableAuthorCard
            key={`item-${author.id}`}
            index={index}
            value={author}
            author={author}
            {...props}
          />
        ))}
    </Row>
  );
});
