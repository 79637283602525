import * as global from "../constants/global";
import UserAvatar from "../images/imageIcons/users.png";
export function authorPicLink(author) {
  let authorName = author.lastName + "+" + author.firstName + ".jpg";
  let picLink = author.pictureLink;
  if (author.pictureLink === "") {
    picLink = oldPicLinkProcedure(author);
  }
  return picLink;
}
const oldPicLinkProcedure = author => {
  let author_lastName = author.lastName
    ? author.lastName
        .split(" ")
        .join("+")
        .toLowerCase()
    : "";
  let author_firstName = author.firstName
    ? author.firstName
        .split(" ")
        .join("+")
        .toLowerCase()
    : "";
  author_firstName = author.firstName ? author_firstName.split(".").join("") : "";
  let author_image = author_lastName.concat("+" + author_firstName + ".jpg");
  let imageSrc = "";
  try {
    imageSrc = require(`${process.env.REACT_APP_IMAGE}/${author_image}`);
  } catch (err) {
    imageSrc = UserAvatar;
  }

  return imageSrc;
};
export function getAuthorItem(author, count = null, hasAbout) {
  const name = `${author.firstName} ${author.lastName}`;
  return {
    id: author.id,
    value: name,
    label: name,
    slug: author.slug,
    onlyImageMemes: author.Concept ? author.Concept.onlyImageMemes : false,
    category: global.authorCategory,
    pictureLink: author.pictureLink,
    count: count ? count : null,
    hasAbout: hasAbout
  };
}
export function sortAuthors(authors) {
  return authors.sort(function(a, b) {
    let authorALastName = a.lastName.toUpperCase();
    let authorBLastName = b.lastName.toUpperCase();
    return authorALastName < authorBLastName ? -1 : authorALastName > authorBLastName ? 1 : 0;
  });
}

export function sortAuthorByPriority(authors) {
  return authors.sort((a, b) => a.priority - b.priority);
}

export function arrangeArray(oldIndex, newIndex, authors) {
  authors = authors.map(obj => {
    let priority = obj.priority;
    // package use return old and new index therefre i arrange mange priority according to index. for example index =  0 will have priority 0 and index = 1 will have priority 1
    // this logic is to move old index to new index and update priorities accordingly
    if (priority === oldIndex) {
      // if chip is not moved to any new index
      // loop found chip which is moved
      priority = newIndex;
    } else if (oldIndex < newIndex) {
      // if chip is moved to downward
      // decrease priority of index which are less than to new Index only, not which are greater than new index

      if (newIndex >= priority && !newIndex < priority && priority >= oldIndex) {
        priority -= 1;
      }
    } else if (oldIndex > newIndex) {
      // if chip is moved to upward
      // increase priority of chips greater than new index only, not which are less than new index
      if (newIndex <= priority && oldIndex >= priority) {
        priority += 1;
      }
    }
    obj.priority = priority;
    return obj;
  });
  return authors;
}

export function getSeoTitle(selectedOption = {}) {
  const path = window.location.href.split("/")[4];
  const newPath = path.split("-").join(" ");
  return `${
    selectedOption.label ? selectedOption.label : newPath
  } inline Dictionary | Dictionaryish`;
}

export function getSeoDescription(perspectives, concepts, selectedOption = {}) {
  const path = window.location.href.split("/")[4];
  const description = concepts.slice(0, 20).map(con => `${con.Concept.name}`);
  return description.join(" ● ");
}
