import { shuffle } from "lodash";
import * as constants from "../constants/memes";
const initialState = {
  memes: [],
  fetchLoading: false,
  loading: false,
  uploadSuccess: false,
  uploadFail: false,
  conceptMemeLoading: false,
  keywordMemeLoading: false,
  offset: 0,
  limit: 10,
  count: 0,
  memeTypeCount: 0,
  textMemeColors: [],
  memeConcepts: [],
  memeBottomConcepts: [],
  memeWithTags: [],
  randomConcepts: [],
  paginationBlock: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "PAGINATION_BLOCK": {
      return {
        ...state,
        paginationBlock: action.payload
      };
    }
    case "randomConcepts": {
      return {
        ...state,
        randomConcepts: action.payload
      };
    }
    case constants.SAVE_MEMES: {
      return {
        ...state,
        ...action.payload
      };
    }
    case constants.MEME_PAGE_MEMES: {
      return {
        ...state,
        ...action.payload
      };
    }
    case constants.MEMES_REQUEST: {
      return {
        ...state,
        fetchLoading: action.payload
      };
    }
    case constants.CONCEPT_MEMES_REQUEST: {
      return {
        ...state,
        conceptMemeLoading: action.payload
      };
    }
    case constants.KEYWORD_MEMES_REQUEST: {
      return {
        ...state,
        keywordMemeLoading: action.payload
      };
    }
    case constants.MEMES_CONCEPTS: {
      return {
        ...state,
        memeConcepts: action.payload
      };
    }
    case constants.MEMES_BOTTOM_CONCEPTS: {
      return {
        ...state,
        memeBottomConcepts: action.payload
      };
    }
    case constants.SAVE_MEMES_WITH_TAGS: {
      return {
        ...state,
        memeWithTags: action.payload
      };
    }

    case constants.MEMES_UPLOADING: {
      return {
        ...state,
        loading: true,
        uploadSuccess: false,
        uploadFail: false
      };
    }

    case constants.MEMES_SUCCESS: {
      return {
        ...state,
        loading: false,
        uploadSuccess: true,
        uploadFail: false
      };
    }

    case constants.MEMES_FAILED: {
      return {
        ...state,
        loading: false,
        uploadSuccess: false,
        uploadFail: true
      };
    }

    case constants.SET_OFFSET: {
      return {
        ...state,
        offset: action.payload
      };
    }

    case constants.SET_MEMES_COUNT: {
      return {
        ...state,
        count: action.payload
      };
    }

    case constants.MEMES_TYPE_COUNT: {
      return {
        ...state,
        memeTypeCount: action.payload
      };
    }

    case constants.RANDOMIZE_MEMES: {
      const { memes } = state;

      return {
        ...state,
        memes: [...shuffle(memes)]
      };
    }
    case "GET_MEMES_COLORS": {
      return {
        ...state,
        textMemeColors: action.payload
      };
    }

    default:
      return state;
  }
};
