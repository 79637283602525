import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import Archive from "../utills/PerspectiveBox/ToolButtons/MemeArchiveButton";
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from "react-share";
import { likeMeme, openModal } from "../../actions/navbaractions";
import axios from 'axios'
import { connect } from "react-redux";
import * as constants from "../../constants/navbar";

const MemeTools = props => {
  const {
    setCitation,
    citation,
    toggle,
    isAdmin,
    meme,
    archiveMode,
    auth,
    openModal,
    likeMeme,
    textMeme = false,
    setActive,
    active,
    title
  } = props;
  const [color, setColor] = useState(meme.likes_at ? "#6a1312" : "#e4d3d3");
  const [copied, setCopied] = useState(false);


  useEffect(() => {

    if(meme.loves === 1){
      setColor("#6a1312")
    }
    
    else{
      setColor("#e4d3d3")
    }
         
    // if (auth && auth.isAuthenticated) {
    //   if (auth.user && auth.user.favoriteMemes) {
    //     if (auth.user.favoriteMemes.some(e => e.id === meme.id)) {
    //       setColor("#e4d3d3");
    //     }
    //   }
    // }
  }, [meme]);

  const onCopyClick = () => {
    if (!copied) {
      navigator.clipboard.writeText(`${meme.firstLine} ~ www.v1.dictionaryish.com`);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  };





  const createImage = (options) => {
    options = options || {};
    const img = document.createElement("img");
    if (options.src) {
      img.src = options.src;
    }
    return img;
  };

  const copyToClipboard = async (pngBlob) => {
    try {
     
      await navigator.clipboard.write([
       
        new window.ClipboardItem({
          [pngBlob.type]: pngBlob
        })
      ]);
      console.log("Image copied");
    } catch (error) {
      console.error(error);
    }
  };

  const convertToPng = (imgBlob) => {
    try{
    
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
   
    const imageEl = createImage({ src: window.URL.createObjectURL(imgBlob) });
    imageEl.onload = (e) => {
      canvas.width = e.target.width;
      canvas.height = e.target.height;
      ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
      canvas.toBlob(copyToClipboard, "image/png", 1);
    };}
    catch(err){
      console.log(err);
    }
  };

  const copyImg = async (src) => {
    const img = await fetch(src,{
        method: 'GET',
        headers: { "Cache-Control": 'no-cache' },
    })
    // const img = await fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(src)}`)
    // const img = await axios.get(`https://api.allorigins.win/get?url=${encodeURIComponent(src)}`)
   

    const imgBlob = await img.blob();
    const extension = src.split(".").pop();
    const supportedToBeConverted = ["jpeg", "jpg", "gif"];
    if (supportedToBeConverted.indexOf(extension.toLowerCase())) {
      return convertToPng(imgBlob);
    } else if (extension.toLowerCase() === "png") {
      return copyToClipboard(imgBlob);
    }
    console.error("Format unsupported");
    return;
  };






 
  const onLikeClick = async () => {
    if (auth && auth.isAuthenticated) {
      let like = {};
      if (color === "#e4d3d3") {
        like.type = "increase";
        like.id = meme.id;
        like.userId = auth.user.id;
        meme.isLike = true;
        meme.loves += 1;
      } else {
        like = {};
        like.type = "decrease";
        like.id = meme.id;
        like.userId = auth.user.id;
        meme.isLike = false;
        meme.loves -= 1;
      }
      color == "#e4d3d3" ? setColor("#6a1312") : setColor("#e4d3d3");
      likeMeme({ like, meme });
    } else {
      openModal();
    }
  };
  return (
    <div style={styles.tools}>
      <div
        style={{
          border: "1px solid #6a1312",
          display: "flex",
          alignItems: "center",
          borderRadius: "50%",
          height: 35,
          width: 35,
          marginRight: 2
        }}
      >
        <i
          style={{ color: color, fontSize: 25 }}
          className="fas fa-heart heartIcon ml-1"
          aria-hidden="true"
          onClick={e => onLikeClick()}
        />
      </div>
      <div
        style={{
          border: "1px solid #6a1312",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          height: 35,
          width: 35,
          marginLeft: 2
        }}
      >
        <Archive
          // index={index}

          meme={meme}
          archivePage={archiveMode}
          size={25}
        />
      </div>
      <div
        style={{
          border: "1px solid #e4d3d3",
          borderRadius: "50%",
          height: 35,
          width: 35,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 2
        }}
      >
        <FacebookShareButton quote="" hashtag="dictionaryish" url={process.env.REACT_APP_SERVER}>
          <FacebookIcon size={25} round={true} />
        </FacebookShareButton>
      </div>
      <div
        style={{
          border: "1px solid #e4d3d3",
          borderRadius: "50%",
          height: 35,
          width: 35,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 2
        }}
      >
        <TwitterShareButton url={process.env.REACT_APP_SERVER}>
          <TwitterIcon size={25} round={true} />
        </TwitterShareButton>
      </div>
      {auth && auth.isAuthenticated && (
        <>
          {!copied ? (
            <div
              style={{
                border: "1px solid #6a1312",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                height: 35,
                width: 35,
                marginLeft: 2
              }}
            >
              <i
                style={{ color: "black", fontSize: "20px", cursor: "pointer" }}
                class="fas fa-camera"
                aria-hidden="true"
                onClick={textMeme ? () => onCopyClick() : () => copyImg(meme.url)}
              />
            </div>
          ) : (

            <div>
              <i className="fas fa-check ml-2" style={{ color: "green", fontSize: "25px" }} />
            </div>

          )}

          {isAdmin && (
            <div
              style={{
                border: "1px solid #6a1312",
                borderRadius: "50%",
                marginLeft: 2
              }}
            >
              <i className="fa fa-trash memes-trash" onClick={toggle} />
            </div>
          )}
          {meme.citation && (
            <div
              style={{
                border: "1px solid #6a1312",
                borderRadius: "50%",
                height: 35,
                width: 35,
                marginLeft: 2,
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}
            >
              <i
                class="fas fa-info"
                style={{
                  fontSize: "25px",
                  // color: "#5bc0de",
                  cursor: "pointer"
                }}
                onClick={() => setCitation(!citation)}
              ></i>
            </div>
          )}

          {isAdmin && (
            <div
              style={{
                border: "1px solid #6a1312",
                borderRadius: "50%",
                height: 35,
                width: 35,
                marginLeft: 2,
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}
            >
              <i
                className="fa fa-pencil"
                id={`addMeme${title}${meme.id}`}
                style={{ cursor: "pointer" }}
                onClick={() => setActive(!active)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
const mapStateToProps = state => {
  return {
    archives: state.archives,
    navbar: state.navbar,
    auth: state.auth,
    archiveMode: state.navbar.mode === constants.ARCHIVE_MODE
  };
};
const mapDispatchToProps = dispatch => {
  return {
    likeMeme: bindActionCreators(likeMeme, dispatch),
    openModal: bindActionCreators(openModal, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemeTools);

const styles = {
  tools: {
    display: "flex",
    alignItems: "center"
  }
};
