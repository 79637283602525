import React, { Component } from "react";
import history from "../../../router/history";
import { connect } from "react-redux";
import AuthorCard from "./authorCard";
import * as navbarActions from "../../../actions/navbaractions";
import { bindActionCreators } from "redux";
import { getAuthorItem } from "../../../helpers/authorHelpers";
import * as scroll from "../../../helpers/scrolling";
const Footer = props => {
  const { relatedAuthor } = props;

  var style = {
    fontWeight: "600",
    border: "1px solid",
    borderRadius: "4mm",
    backgroundColor: "white",
    fontSize: "31px",
    height: "55px !important",
    width: "78%",
    marginLeft: "9%",
    marginBottom: "10%"
  };
  var separator = {
    width: "81%",
    marginLeft: "9%"
  };
  const backToHome = () => {
    window.location.replace("/");
  };
  const onAuthorClick = author => {
    //scroll.resetScroll();
    //const item = getAuthorItem(author);
    // props.setSelectedItem(item);
    history.push(`/Authors/${author.slug ? author.slug : author.value}`);
  };
  return (
    <div>
      <div style={separator}>
        <center>
          <hr />
        </center>
      </div>
      <button
        type="button"
        className="btn btn-light btn-sm btn-block"
        style={style}
        onClick={backToHome}
      >
        Back to Homepage
      </button>
      <div className="row">
        {relatedAuthor &&
          relatedAuthor.map(author => (
            <AuthorCard
              author={author}
              onAuthorClick={() => onAuthorClick(author)}
              handleReciprocalClick={props.handleTempReciprocalClick}
              handleMouseEnter={() => {}}
              handleMouseLeave={() => {}}
              tools={false}
              isPage={true}
            />
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  relatedAuthor: state.author.relatedAuthors
});
const mapDispatchToProps = dispatch => ({
  setSelectedItem: bindActionCreators(navbarActions.setSelectedItem, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
