import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import { BLACK, SKY_BLUE } from "../../../constants/colors";
import history from "../../../router/history";
import { connect } from "react-redux";

const AuthorBooks = props => {
  const { books, selectedOption, conceptMode } = props;

  const goToAuthorBooksPage = () => {
    if (conceptMode) {
      history.push(`/books/${selectedOption.booksCollection}`);
    } else {
      history.push(`/books/author/${selectedOption.id}`);
    }
  };

  return (
    <Container>
      {books && books.length > 0 && (
        <>
          <div style={styles.header}>
            <b className="cursor-pointer" onClick={goToAuthorBooksPage}>
              Books({books.length})
            </b>
          </div>
          <Row style={styles.container}>
            {books.map(book => {
              return (
                <Col
                  key={book.id}
                  md={3}
                  className="p-1 cursor-pointer"
                  onClick={goToAuthorBooksPage}
                >
                  <a
                    href={`/book/${book.id}`}
                    onClick={e => {
                      e.preventDefault();
                    }}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <img src={book.pictureLink} style={styles.image} alt="Author book" />
                  </a>
                </Col>
              );
            })}
          </Row>
          <span className="cursor-pointer" onClick={goToAuthorBooksPage} style={styles.see}>
            more . . .
          </span>
        </>
      )}
    </Container>
  );
};

AuthorBooks.propTypes = {
  conceptMode: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    books: state.books.books || [],
    selectedOption: state.navbar.selectedOption
  };
};

export default connect(mapStateToProps, null)(AuthorBooks);

const styles = {
  container: {
    borderTop: `1px solid ${BLACK}`,
    paddingTop: 10,
    height: 110,
    overflow: "hidden"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 12
  },
  see: {
    fontSize: 15,
    color: SKY_BLUE,
    fontWeight: 500
  },
  image: {
    width: "100%",
    height: 95,
    border: `1px solid ${BLACK}`
  }
};
