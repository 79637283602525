import React from 'react'
import PropTypes from 'prop-types'
import { DARK_GREY } from '../../../constants/colors'

const Return = props => {
    return (
        <div className="clearfix" style={styles.wrapper} onClick={props.onClick}>
            <h5>return to feed</h5>
        </div>
    )
}

Return.prototype = {
    onClick: PropTypes.func.isRequired,
}


export default Return


const styles = {
    wrapper: {
        minHeight: 130,
        background: DARK_GREY,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }
}