import { contains } from "jquery";
import { allowBlockPagination } from "../actions/authorActions";
import * as constants from "../constants/pos";

const initialState = {
  posList: [],
  conceptPosList: [],
  posContents: [],
  posCategories: [],
  posCategoriesValues: [],
  posSynonyms: [],
  posSelectedSynonyms: [],
  loading: false,
  tooltip_loading: false,
  offset: 0,
  posPgLoading: false,
  similes: [],
  posCollocations: [],
  relatedConcepts: []
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.SET_POS_OFFSET: {
      return {
        ...state,
        offset: action.payload
      };
    }
    case constants.PAGINATION_LOADING: {
      return {
        ...state,
        posPgLoading: action.payload
      };
    }
    case constants.POS_CONTENT_SUCCESS: {
      return {
        ...state,
        posContents: action.payload,
        loading: false
      };
    }
    case constants.CREATE_POS_CONTENT_SUCCESS: {
      return {
        ...state,
        posContents: [...state.posContents, action.payload],
        loading: false
      };
    }
    case constants.UPDATE_SELECTED_POS_SYNONYMS: {
      return {
        ...state,
        posSelectedSynonyms: action.payload,
        loading: false
      };
    }
    case constants.UPDATE_SELECTED_POS_COLLOCATIONS: {
      return {
        ...state,
        posSelectedCollocations: action.payload,
        loading: false
      };
    }
    case constants.UPDATE_POS_SYNONYMS: {
      return {
        ...state,
        posSynonyms: action.payload,
        loading: false
      };
    }
    case constants.UPDATE_POS_COLLOCATIONS: {
      let temp = [...state.posCollocations];
      const index = temp.findIndex(e => e.id === action.payload.id);
      if (index !== -1) temp[index].Concepts.push(action.payload.concept);
      return {
        ...state,
        posCollocations: temp,
        loading: false
      };
    }
    case constants.POS_CONTENT_DELETE_SUCCESS: {
      return {
        ...state,
        posContents: state.posContents.filter(e => e.id !== action.payload),
        loading: false
      };
    }

    case constants.POS_CONTENT_UPDATE_SUCCESS: {
      const { id, data } = action.payload;

      return {
        ...state,
        posContents: state.posContents.map(elm =>
          elm.id === id ? { ...elm, content: data.content } : elm
        ),
        loading: false
      };
    }

    case constants.UPDATE_POS_SUCCESS: {
      const { id, term } = action.payload;
      return {
        ...state,
        posSynonyms: state.posSynonyms.map(elm => (elm.id === id ? { ...elm, term } : elm)),
        loading: false
      };
    }

    case constants.GET_POS_SELECTED_SYNONYMS_SUCCESS: {
      return {
        ...state,
        posSelectedSynonyms: action.payload,
        loading: false,
        tooltip_loading: false
      };
    }

    case constants.GET_POS_SUCCESS: {
      return {
        ...state,
        posList: action.payload,
        loading: false
      };
    }
    case constants.SAVE_POS_SUCCESS: {
      return {
        ...state,
        posList: [...state.posList, action.payload],
        loading: false
      };
    }

    case constants.DELETE_POS_SUCCESS: {
      return {
        ...state,
        posList: state.posList.filter(e => e.id !== action.payload),
        loading: false
      };
    }
    case constants.POS_CATEGORIES_SUCCESS:
      return {
        ...state,
        posCategories: action.payload,
        loading: false
      };
    case constants.POS_CATEGORIES_VALUES_SUCCESS:
      return {
        ...state,
        posCategoriesValues: action.payload,
        loading: false
      };

    case constants.SET_POS_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case constants.SET_POS_LOADING_TOOLTIP: {
      return {
        ...state,
        tooltip_loading: action.payload
      };
    }

    case constants.GET_SIMILES_SUCCESS:
      return {
        ...state,
        similes: action.payload,
        loading: false
      };

    case constants.GET_CONCEPT_POS_LIST_SUCCESS:
      return {
        ...state,
        posList: action.payload
      };
    case constants.GET_POS_SYNONYMS_SUCCESS:
      return {
        ...state,
        posSynonyms: action.payload
        // loading: false
      };

    case constants.RELATED_CONCEPTS_SUCCESS:
      return {
        ...state,
        relatedConcepts: action.payload
      };

    case constants.GET_POS_COLLOCATIONS_SUCCESS:
      return {
        ...state,
        posCollocations: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
