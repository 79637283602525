import React, { useState } from "react";
import PropTypes from "prop-types";
import history from "../../../router/history";
import { resetSecondCol } from "../../../helpers/scrolling";
import ConceptList from "../../utills/conceptsSideBar/conceptsList";
import { getStyleOfContentType } from "../../Pos/PosCategory/PosHelper";
import { set } from "lodash";
const Chip = props => {
  const { concept, showControls, hideDelete, selectedId, lock } = props;
  console.log(concept.slug, "valueeeeeeee");
  const pageName = window.location.href.split("/")[3];
  let currentConcept = window.location.href.split("/")[4];
  currentConcept = currentConcept.toLowerCase();
  const openConcept = () => {
    history.push(`/${pageName}/${concept.slug}`);
    resetSecondCol();
  };

  const getStyle = (value, concept) => {
    switch (value) {
      case "color":
        if (selectedId == -1 && concept.slug === currentConcept) {
          return "red";
        } else if (concept.id === selectedId) {
          return "red";
        } else if (!concept.reciprocalExist && concept.slug !== currentConcept) {
          return "gray";
        } else {
          return "black";
        }

      case "weight":
        if (lock === true) {
          // if (selectedId >= 0) {
          return concept.id === selectedId || concept.slug === currentConcept ? "bolder" : "normal";
          // }
        } else {
          return concept.slug === currentConcept ? "bolder" : "normal";
        }

      case "size":
        if (lock === true) {
          return concept.slug === currentConcept ? "32px" : "20px";
        } else {
          return concept.slug === currentConcept ? "26px" : "";
        }
      case "border":
        if (selectedId == -1 && concept.slug === currentConcept) {
          return "1px solid black";
        } else if (concept.id === selectedId) {
          return "1px solid black";
        } else if (!concept.reciprocalExist && concept.slug !== currentConcept) {
          return "none";
        } else {
          return "none";
        }
      case "borderRadius":
        if (selectedId == -1 && concept.slug === currentConcept) {
          return "50%";
        } else if (concept.id === selectedId) {
          return "50%";
        } else if (!concept.reciprocalExist && concept.slug !== currentConcept) {
          return "none";
        } else {
          return "none";
        }
      case "width":
        if (selectedId == -1 && concept.slug === currentConcept) {
          return "8vw";
        } else if (concept.id === selectedId) {
          return "6vw";
        } else if (!concept.reciprocalExist && concept.slug !== currentConcept) {
          return "none";
        } else {
          return "none";
        }
      case "boxShadow":
        if (selectedId == -1 && concept.slug === currentConcept) {
          return "0px 0px 11px 5px rgba(0,0,0,0.75)";
        } else if (concept.id === selectedId) {
          return "0px 0px 11px 5px rgba(0,0,0,0.75)";
        } else if (!concept.reciprocalExist && concept.slug !== currentConcept) {
          return "none";
        } else {
          return "none";
        }
      case "padding":
        if (selectedId == -1 && concept.slug === currentConcept) {
          return "5px";
        } else if (concept.id === selectedId) {
          return "5px";
        } else if (!concept.reciprocalExist && concept.slug !== currentConcept) {
          return "none";
        } else {
          return "none";
        }
      default:
        break;
    }
  };
  return (
    <div
      style={styles.chip}
      //className={lock && "lockStyle"}
    >
      <a
        // href={`/dictionary/${concept.slug}`}
        onClick={e => {
          e.preventDefault();
          // setShowborder(true);
        }}
        style={{
          color: "inherit",
          textDecoration: "none"
        }}
      >
        <span
          style={{
            ...styles.text,
            color: getStyle("color", concept),
            fontWeight: "bold",
            fontSize: getStyle("size", concept),
            border: getStyle("border", concept),
            borderRadius: getStyle("borderRadius", concept),
            width: getStyle("width", concept),
            boxShadow: getStyle("boxShadow", concept),
            padding: getStyle("padding", concept)
          }}
          onClick={lock ? () => props.onNameClick(concept) : () => openConcept()}
          onTouchEnd={() => props.onNameClick(concept)}
        >
          {concept.name}
        </span>

        {showControls && (
          <input
            type="radio"
            checked={concept.reciprocalExist}
            onClick={() => props.onReciprocalClick(concept)}
            style={styles.input}
          />
        )}
        {showControls && !hideDelete && (
          <i
            className="fa fa-trash hover-grey"
            style={styles.icon}
            onClick={() => props.onDeleteClick(concept)}
          />
        )}
        {/* {lock && <span style={{ fontWeight: "1000" }}>&#x2015;</span>} */}
      </a>
    </div>
  );
};

Chip.propTypes = {
  concept: PropTypes.object.isRequired,
  onReciprocalClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onNameClick: PropTypes.func.isRequired,
  showControls: PropTypes.bool,
  hideDelete: PropTypes.bool
};

export default Chip;

const styles = {
  chip: {
    textAlign: "right",
    // border: "1px solid black",
    color: "red"
    // display: "flex",
    // alignItems: "center"
  },
  icon: {
    padding: "0px 0px 0px 5px"
  },
  input: {
    cursor: "pointer"
  },
  text: {
    fontSize: 14,
    textTransform: "capitalize",
    paddingLeft: 5,
    marginRight: 5,
    cursor: "pointer"
  },
  boldClass: {
    fontWeight: "bolder",
    fontSize: "800",
    color: "red"
  }
};
