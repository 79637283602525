import React, { useState, useEffect } from "react";
import { FacebookProvider, Comments } from "react-facebook";
import PropTypes from "prop-types";
import Title from "./commentTitle";
import * as facebook from "../../../helpers/facebook";
import Loader from "react-loader-spinner";

import $ from "jquery";
import "./comment.css";
import { GREY } from "../../../constants/colors";

const FacebookComment = props => {
  const [expand, setExpand] = useState(false);
  const [myHeight, setMyHeight] = useState(200);
  const [loading, setMyLoading] = useState(false);
  let loaderPromise = null;

  const { fullHeight, filter, url, showComment, title } = props;
  const refreshFacebook = () => facebook.refreshFacebook();

  useEffect(() => {
    refreshFacebook();
    calculateHeight();
  }, [url]);

  const calculateHeight = () => {
    setMyLoading(true);
    loaderPromise = setInterval(() => {
      const height = $(".fb-comments").height();
      if (height) {
        if (height > 100) {
          // plugin loaded
          if (height >= 700) {
            setMyHeight(700);
          } else {
            setMyHeight(height + 140);
          }
          setMyLoading(false);
          clearInterval(loaderPromise);
        }
      }
    }, 500);
  };

  useEffect(() => {
    if (fullHeight || filter) {
      setExpand(true);
    } else {
      setExpand(false);
    }
  }, [fullHeight, filter]);

  const getStyle = () => {
    return {
      opacity: showComment ? 1 : 0,
      zIndex: showComment ? 2 : 0,
      height: expand ? "auto" : myHeight,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center"
    };
  };
  const commentBoxStyle = () => {
    return {
      height: expand ? "auto" : 500,
      overflow: expand ? "scroll" : "hidden"
    };
  };
  const wrapperStyles = getStyle();
  const boxStyle = commentBoxStyle();

  return (
    <div id="filterComments" style={wrapperStyles}>
      <>
        <Title title={title} filter={filter} />
        {loading && <Loader type="Oval" color={GREY} height="30" width="30" />}
        <div id="commentsInner" style={boxStyle}>
          <FacebookProvider appId={`${process.env.REACT_APP_FB}`}>
            <Comments href={url} width="auto" data-order-by="social" />
          </FacebookProvider>
        </div>

        {!filter && (
          <span
            id="more_comments"
            onClick={() => {
              setExpand(!expand);
            }}
          >
            {expand ? "" : "More"}
          </span>
        )}
      </>
    </div>
  );
};

FacebookComment.prototype = {
  fullHeight: PropTypes.bool.isRequired,
  filter: PropTypes.object,
  url: PropTypes.string.isRequired,
  showComment: PropTypes.bool.isRequired,
  title: PropTypes.object.isRequired
};

export default FacebookComment;
