import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tabs, Tab } from "react-bootstrap";
import ConceptSidebar from "../../utills/conceptsSideBar";
import ConceptList from "../../utills/conceptsSideBar/conceptsList";
import * as scroll from "../../../helpers/scrolling";
import FirstColBtn from "../../Concept/cols/FirstColBtn";
import {
  SORT_BY_LIKES,
  factCategory,
  conceptCategory,
  keywordCategory
} from "../../../constants/global";
import * as conceptActions from "../../../actions/conceptsActions";
import * as actions from "../../../actions/conceptClustersActions";
import * as navbarAction from "../../../actions/navbaractions";
import * as memeActions from "../../../actions/memes";
import { getTempConcepts, mapConceptKeywords } from "../../../selectors";
import {
  addClusterItem,
  addReciprocal,
  findTempConcepts,
  removeClusterItem,
  removeReciprocal,
  setFactsKeywrodFilterId
} from "../../../actions/conceptsActions";
import "./firstCol.css";
import { setDrawerOpen } from "../../../actions/mobile";
import Drawer from "../../utills/drawer/drawer";
import RelatedConcepts from "../../Concept/cols/relatedConcepts";
import * as conceptClusterActions from "../../../actions/conceptClustersActions";
import history from "../../../router/history";
import ToolTip from "react-portal-tooltip";
import TempConcepts from "../../Concept/cols/tempConcepts";
import ConceptsList from "../../Concept/cols/conceptsList";
import { removeSubFilter } from "../../../actions/navbaractions";
import OutsideClickHandler from "react-outside-click-handler";
import SwitchConceptModes from "../../utills/Buttons/switchConceptModes";
import { listenToDocumentSizeChangeOnPercepectiveClick } from "../../../helpers/navbar";

function FirstColCluster(props) {
  const {
    concepts,
    sortByLikes,
    selectedConceptId,
    subFilter,
    conceptDrawer,
    user,
    selectedOption,
    tempConcepts,
    lock,
    sorting,
    keywords,
    viralKeywords
  } = props;

  const pageName = window.location.href.split("/")[3];
  const conceptName = window.location.href.split("/")[4];

  const isAdmin = user.isAdmin || false;
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(false);
  const [time, setTime] = useState(null);

  let tooltipEnter = false;
  const conceptCallBack = (concept, index) => {
    if (selectedOption.value === concept.name) {
      history.push(`/${pageName}/${selectedOption.value.split(" ").join("-")}`);
    }
    props.setConceptDrawerOpen(false);
    scroll.resetSecondCol();
    lock === true && props.removeSubFilter();
    if (subFilter && subFilter.type === factCategory) {
      props.setFactConceptFilterId(concept.id);
      props.getFactsByConceptId(concept.id);
    } else {
      (pageName === "dictionary" || pageName === "Concepts") &&
        lock === true &&
        props.getKeywordsData(concept.name, []);
      props.combineMemePerspective([]);

      props.conceptCallBack(index, concept);
    }
    props.setOffset(0);
    if (concept.reciprocalExist) {
      props.fetchMemes({
        concept: concept.slug ? concept.slug : concept.name,
        keyword: "undefined",
        imageMeme: false,
        lock: true,
        toggle: false,
        pageName: "All"
      });
    } else {
      props.fetchMemes({
        concept: concept.slug ? concept.slug : concept.name,
        keyword: conceptName,
        imageMeme: false,
        lock: true,
        toggle: false,
        pageName: "All"
      });
    }

    listenToDocumentSizeChangeOnPercepectiveClick();
  };
  let selectedId = subFilter ? (subFilter.type === keywordCategory ? subFilter.id : -1) : -1;
  const handleTabSelect = key => {
    if (key == "related-concept") {
      props.getClusterRelated(selectedOption.value);
    }
  };
  const handleReciprocalClick = concept => {
    concept.reciprocalExist
      ? props.removeReciprocal(selectedOption.id, concept.id)
      : props.addReciprocal(selectedOption.id, concept.id);
  };

  const handleTempReciprocalClick = concept => {
    concept.value = concept.name;
    concept.label = concept.name;
    concept.reciprocalExist ? props.removeClusterItem(concept.id) : props.addClusterItem(concept);
    setShow(true);
  };

  const handleListMouseEnter = concept => {
    if (isAdmin) {
      props.findTempConcepts(concept.id);
      setId(concept.id);
      setShow(true);
    }
  };

  const handleListMouseLeave = () => {
    if (isAdmin) {
      setShow(false);
    }
  };

  const toolTipMouseEnter = () => {
    setShow(true);
    tooltipEnter = true;
    handleMouseEnter();
  };

  const handleMouseEnter = () => {
    clearTimeout(time);
    setActive(true);
  };

  const handleTooltipMouseLeave = () => {
    tooltipEnter = false;
    setShow(false);
    handleMouseLeave();
  };

  const handleMouseLeave = () => {
    setTime(
      setTimeout(() => {
        if (!tooltipEnter) {
          setActive(false);
        }
      }, 500)
    );
  };

  const removeCluster = (clusterId, conceptId) => {
    setShow(false);
    props.removeClusterItem(clusterId, conceptId);
  };

  const onScolling = () => {
    handleTooltipMouseLeave();
    scroll.handleFirstWheel();
  };

  const handleKeywordsCallBack = (index, concept) => {
    history.push(`/${conceptCategory}/${selectedOption.slug}?${keywordCategory}=${concept.id}`);
  };

  const getKeywords = () => {
    if (pageName === "Concepts") {
      let concept = [];

      keywords &&
        keywords.forEach(e => {
          viralKeywords &&
            viralKeywords.forEach(elm => {
              if (e.slug === elm.slug) {
                concept.push({ id: e.id, slug: e.slug, name: e.name, count: e.count + elm.count });
              }
            });
        });

      keywords &&
        keywords.forEach(e => {
          if (concept.some(elm => e.id === elm.id)) {
            return;
          } else {
            concept.push({ id: e.id, slug: e.slug, name: e.name, count: e.count });
          }
        });

      viralKeywords &&
        viralKeywords.forEach(elm => {
          if (concept.some(e => e.id === elm.id)) {
            return;
          } else {
            concept.push({ id: elm.id, slug: elm.slug, name: elm.name, count: elm.count });
          }
        });
      return concept.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return keywords;
    }
  };

  const conceptTagCallBack = (index, concept, popupRoute = false) => {
    if (subFilter && subFilter.type === factCategory) {
      props.setFactsKeywrodFilterId(concept.id);
    } else {
      scroll.resetSecondCol();
      const inputValue = {
        id: concept.id,
        value: concept.name,
        label: concept.name,
        slug: concept.name,
        type: keywordCategory
      };
      props.setSubFilter(inputValue, popupRoute);
      props.perspectivesByFilter(inputValue);
      props.setOffset(0);
      pageName === "Concepts" &&
        props.fetchMemes({
          concept: selectedOption.label,
          keyword: concept.slug,
          imageMeme: false,
          lock: true,
          toggle: false,
          thirdKey: null,
          urlChange: false,
          pageName: "All"
        });
    }
    listenToDocumentSizeChangeOnPercepectiveClick();
  };

  // useEffect(() => {
  //   if (pageName === "Concepts" || pageName === "concepts") {
  //     keywords.forEach(elm => {
  //       if (!concepts.includes(elm)) {
  //         concepts.push(elm);
  //       }
  //     });
  //   }
  // }, [concepts, keywords]);

  return (
    <div>
      <Drawer
        open={conceptDrawer}
        handleClose={() => props.setConceptDrawerOpen(false)}
        direction="left"
      >
        <ConceptList
          concepts={concepts}
          conceptCallBack={conceptCallBack}
          opacity={sortByLikes}
          selectedId={selectedConceptId}
        />
      </Drawer>
      <OutsideClickHandler onOutsideClick={handleTooltipMouseLeave}>
        <div
          id={scroll.firstColId}
          onScroll={onScolling}
          style={{ paddingBottom: 150, top: "45px", width: "26.5%" }}
          // className="col-6 col-sm-6 col-md-2 right d-none d-md-block "
        >
          {/* <FirstColBtn /> */}
          {/* <SwitchConceptModes /> */}

          <div className="row concept-tabs">
            <div className="col-12">
              <div
                style={{
                  cursor: "pointer",
                  position: "fixed",
                  zIndex: 7,
                  paddingLeft: "13%",
                  width: "26.5%"
                }}
              >
                {/* {lock ? (
                  <i
                    className="fa fa-lock fa-3x"
                    aria-hidden="true"
                    onClick={() => props.lockIcon(false)}
                  ></i>
                ) : (
                  <i
                    className="fa fa-unlock fa-3x"
                    aria-hidden="true"
                    onClick={() => props.lockIcon(true)}
                  ></i>
                )} */}
              </div>

              <div className="row" style={{ marginTop: "50px" }}>
                <div style={{ width: "95%" }}>
                  <ConceptsList
                    isAdmin={isAdmin}
                    addClusterItem={props.addClusterItem}
                    concepts={concepts}
                    handleListMouseEnter={handleListMouseEnter}
                    handlFeListMouseLeave={handleListMouseLeave}
                    handleReciprocalClick={handleReciprocalClick}
                    removeClusterItem={removeCluster}
                    onNameClick={conceptCallBack}
                    selectedOption={selectedOption}
                    selectedId={selectedConceptId}
                    lock={lock}
                  />
                </div>
                {/* <div
                  style={{
                    width: "40%",
                    marginTop: "60px",
                    border: "2px solid black",
                    borderRadius: "5px",
                    backgroundColor: "white"
                  }}
                >
                  <ConceptSidebar
                    concepts={getKeywords()}
                    conceptCallBack={conceptTagCallBack}
                    opacity={sorting === SORT_BY_LIKES}
                    showCount={true}
                    isKeywords={true}
                    selectedId={selectedId}
                  />
                </div> */}
              </div>
            </div>

            {/* <Tabs defaultActiveKey="keywords" transition={false} id="concept-tabs" onSelect={handleTabSelect}>
                    <Tab className="concept-tabs-second" eventKey="related-concept" title="related concepts">
                      <ConceptList concepts={concepts} conceptCallBack={handleKeywordsCallBack} />
                    </Tab>
                    <Tab className="concept-tabs-second" eventKey="keywords" title="keywords">
                        <ConceptsList
                            isAdmin={isAdmin}
                            addClusterItem={props.addClusterItem}
                            concepts={concepts}
                            handleListMouseEnter={handleListMouseEnter}
                            handlFeListMouseLeave={handleListMouseLeave}
                            handleReciprocalClick={handleReciprocalClick}
                            removeClusterItem={removeCluster}
                            onNameClick={conceptCallBack}
                            selectedOption={selectedOption.category}
                        />
                    </Tab>
                </Tabs> */}
          </div>

          <ToolTip active={show} parent={`#concept${id}`} position="right" arrow="center">
            <TempConcepts
              concepts={tempConcepts}
              isAdmin={isAdmin}
              onMouseEnter={toolTipMouseEnter}
              onMouseLeave={handleTooltipMouseLeave}
              handleTempReciprocalClick={handleTempReciprocalClick}
            />
          </ToolTip>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    //concepts: mapConceptKeywords(state),
    concepts: state.navbar.concepts,
    keywords: state.navbar.keywords,
    viralKeywords: state.navbar.viralKeywords,
    subFilter: state.navbar.subFilter,
    sortByLikes: state.conceptClusters.sorting === SORT_BY_LIKES,
    sorting: state.author.sorting,
    selectedConceptId: state.conceptClusters.selectedConceptId,
    conceptDrawer: state.mobile.conceptDrawer,
    user: state.auth.user,
    selectedOption: state.navbar.selectedOption,
    tempConcepts: getTempConcepts(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    combineMemePerspective: bindActionCreators(actions.combineMemePerspective, dispatch),
    getKeywordsData: bindActionCreators(conceptActions.getKeywordsData, dispatch),
    conceptCallBack: bindActionCreators(actions.conceptCallBack, dispatch),
    setFactsKeywrodFilterId: bindActionCreators(conceptActions.setFactsKeywrodFilterId, dispatch),
    perspectivesByFilter: bindActionCreators(conceptActions.perspectivesByFilter, dispatch),
    setFactConceptFilterId: bindActionCreators(actions.setFactConceptFilterId, dispatch),
    getFactsByConceptId: bindActionCreators(actions.getFactsByConceptId, dispatch),
    setConceptDrawerOpen: bindActionCreators(setDrawerOpen, dispatch),
    findTempConcepts: bindActionCreators(findTempConcepts, dispatch),
    removeSubFilter: bindActionCreators(removeSubFilter, dispatch),
    removeClusterItem: bindActionCreators(removeClusterItem, dispatch),
    addClusterItem: bindActionCreators(addClusterItem, dispatch),
    removeReciprocal: bindActionCreators(removeReciprocal, dispatch),
    addReciprocal: bindActionCreators(addReciprocal, dispatch),
    getClusterRelated: bindActionCreators(conceptClusterActions.getClusterRelated, dispatch),
    fetchMemes: bindActionCreators(memeActions.fetchMemetagMemes, dispatch),
    setOffset: bindActionCreators(memeActions.setOffset, dispatch),
    lockIcon: bindActionCreators(navbarAction.lockIcon, dispatch),
    setSubFilter: bindActionCreators(navbarAction.setSubFilter, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(FirstColCluster));

const styles = {
  reciprocal: {
    cursor: "pointer"
  }
};
