import axios from "axios";
import { BASE_URL } from "../constants/global";

export const getRecentLikedPerspectives = (offset, limit, favouriteConcepts, favouriteAuthors) => {
  // Get recent liked perspectives by offset and limit
  return axios.post(`${BASE_URL}/perspective/likes`, {
    offset,
    limit,
    favouriteConcepts,
    favouriteAuthors
  });
};

export const getNewLikes = (timeStamp, favouriteConcepts, favouriteAuthors) => {
  return axios.post(`${BASE_URL}/perspective/likes/recent`, {
    timeStamp,
    favouriteConcepts,
    favouriteAuthors
  });
};

export const getPerspectivesByConceptsAndAuthors = (authorIds, conceptIds, keywords) => {
  return axios.post(`${BASE_URL}/perspectives/all/concepts/authors`, {
    authorIds,
    conceptIds,
    keywords
  });
};

export const getPerspectivesByConceptId = conceptId => {
  return axios.post(`${BASE_URL}/concept/perspective`, { conceptId });
};

export const getPerspectivesByConceptIdAndAuthorId = (conceptId, authorId) => {
  return axios.post(`${BASE_URL}/concept/single-author/perspective`, { conceptId, authorId });
};

export const getPerspectivesByConcepts = conceptIds =>
  axios.post(`${BASE_URL}/perspectives/concepts`, { conceptIds });

export const getPerspectivesByConceptsAndAuthorsPagination = (
  authorIds,
  conceptIds,
  offset,
  limit
) => {
  return axios.post(`${BASE_URL}/perspectives/concepts/authors`, {
    authorIds,
    conceptIds,
    offset,
    limit
  });
};

export const getPerspectivesByLikes = (authorIds, conceptIds, offset, limit, keywords) => {
  return axios.post(`${BASE_URL}/perspectives/all/likes`, {
    authorIds,
    conceptIds,
    offset,
    limit,
    keywords
  });
};

export const perspectivesByKeywords = (keywordId, conceptId) => {
  return axios.post(`${process.env.REACT_APP_SERVER}/api/rest/v1/keywords/perspective`, {
    id: keywordId,
    conceptId
  });
};

export const getInitialPerspectives = userId => {
  return axios.post(`${process.env.REACT_APP_SERVER}/api/rest/v1/perspective/initial`, { userId });
};

export const getFactsByConcepts = conceptId => {
  return axios.post(`${process.env.REACT_APP_SERVER}/api/rest/v1/perspective/facts/concept/`, {
    conceptId
  });
};

export const getFactsByAuthors = authorId => {
  return axios.post(`${process.env.REACT_APP_SERVER}/api/rest/v1/perspective/facts/author`, {
    authorId
  });
};

export const getPerspectivesCount = keyword => {
  return axios.get(`${BASE_URL}/perspective/count?keyword=${keyword}`);
};
export const getAllConcepts = (character)=>{
  return axios.get(`${BASE_URL}/concept/all?character=${character}`)
}

