import React, { useState, useRef } from "react";
import ToolTip from "react-portal-tooltip";
import ToolTipForm from "../Modals/LoginModal/toolTipForm";
import * as navbarActions from "../../../actions/navbaractions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import archiveImage from "../../../images/imageIcons/archive.png";
import add from "../../../images/imageIcons/add.png";
import { getArchiveName } from "../../../helpers/archives";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { createArchive, updateArchivesPriority } from "../../../actions/archives";

const SortableItem = SortableElement(({ value: { archive, archiveClick, user } }) => (
  <li
    id="archives"
    style={{ zIndex: 51 }}
    onClick={() => archiveClick(archive.name, archive.id)}
    className="list-group-item"
  >
    <img className="icon px-2" id={"text"} src={archiveImage} alt="Archive image" />
    {getArchiveName(archive.name, user)}
  </li>
));

const SortableList = SortableContainer(({ items, archiveClick, user }) => {
  return (
    <>
      {items.map((archive, index) => (
        <SortableItem
          key={`item-${archive.id}`}
          index={index}
          value={{ archive, archiveClick, user }}
        />
      ))}
    </>
  );
});

const ArchiveToolTip = props => {
  const {
    isTooltipActive,
    archiveList,
    userExist_admin,
    loginRequestStatus_admin,
    isAuthenticated,
    invalidLogin,
    user,
    auth,
    id,
    setActive
  } = props;
  const [showList, setShowList] = useState(true);
  const ref = useRef();

  const setToolTipActive = () => {};

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      if (ref.current) {
        setShowList(true);
        setToolTipActive(true);
        props.createArchive(ref.current.value, user.id);
        ref.current.blur();
      }
    }
  };
  const handleNewClick = () => {
    setShowList(false);
    setToolTipActive(true);
  };

  const handleCancelClick = e => {
    setShowList(true);
    setToolTipActive(true);
  };

  const handleOkClick = e => {
    if (ref.current && ref.current.value) {
      setShowList(true);
      setToolTipActive(true);
      let isFind = archiveList.find(arc => arc.name == ref.current.value);
      if (isFind) {
        return;
      }
      props.createArchive(ref.current.value, user.id);
    }
  };
  return (
    <ToolTip active={isTooltipActive} position="bottom" arrow="center" parent={`#${id}`}>
      <div onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
        {showList && (
          <ul style={{ padding: 0 }}>
            {isAuthenticated && (
              <SortableList
                items={archiveList}
                user={user}
                archiveClick={props.archiveClick}
                onSortEnd={({ oldIndex, newIndex }) => {
                  props.updateArchivePriority(oldIndex, newIndex);
                  setActive(true);
                }}
                pressDelay={200}
              />
            )}
            {auth && user.isPremium && (
              <li id="archives" className="list-group-item" onClick={handleNewClick}>
                <img
                  className="icon px-2 "
                  style={{ height: 19 }}
                  src={add}
                  alt="add payment icon"
                />
                new archive
              </li>
            )}
          </ul>
        )}
        {!showList && (
          <div style={styles.inputWrapper}>
            <input
              type="text"
              onKeyDown={handleKeyDown}
              placeholder="Enter here"
              style={styles.input}
              ref={ref}
            />
            <i className="fa fa-check" style={styles.inputTools} onClick={handleOkClick} />
            <i className="fa fa-remove" style={styles.inputTools} onClick={handleCancelClick} />
          </div>
        )}
      </div>
      {!isAuthenticated && (
        <ToolTipForm
          switchModal={props.switchModal}
          userExist_admin={userExist_admin}
          loginRequestStatus_admin={loginRequestStatus_admin}
          isAuthenticated={isAuthenticated}
          invalidLogin={invalidLogin}
          user={user}
          auth={auth}
          login={props.login}
          socialLogin={props.socialLogin}
        />
      )}
    </ToolTip>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    socialLogin: bindActionCreators(navbarActions.socialLogin, dispatch),
    login: bindActionCreators(navbarActions.login, dispatch),
    updateArchivePriority: bindActionCreators(updateArchivesPriority, dispatch),
    createArchive: bindActionCreators(createArchive, dispatch)
  };
};
const mapStateToProps = state => {
  return {
    userExist_admin: state.auth.userExist_admin,
    loginRequestStatus_admin: state.auth.loginRequestStatus_admin,
    isAuthenticated: state.auth.isAuthenticated,
    invalidLogin: state.auth.invalidLogin,
    user: state.auth.user,
    auth: state.auth,
    archiveList: state.archives.archiveList.sort((a, b) => a.priority - b.priority)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveToolTip);

const styles = {
  inputWrapper: {
    width: 213,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 3
  },
  input: {
    width: "80%"
  },
  inputTools: {
    fontSize: 22,
    cursor: "pointer"
  }
};
