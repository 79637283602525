import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/authorActions";
import * as navbarActions from "../../actions/navbaractions";
import ReactPageLoading from "../utills/ReactLoading";
import SeoTags from "../utills/SeoTag";
import FirstCol from "./cols/firstCol";
import SecondCol from "./cols/secondCol";
import ThirdCol from "./cols/thirdCol";
import { authorCategory, BACK_CODE } from "../../constants/global";
import Message from "../Messages/Message";
import FacebookCol from "../utills/FacebookComment/facebookCol";
import RelatedAuthors from "./cols/relatedAuthors";
import Drawer from "../utills/drawer/drawer";
import history from "../../router/history";
import AuthorCards from "./cols/authorCards";
import { setBioDrawerOpen, setRelatedAuthorDrawerOpen } from "../../actions/mobile";
import { getAuthorItem, getSeoTitle, getSeoDescription } from "../../helpers/authorHelpers";

let mounted = false;

const NewAuthor = props => {
  const {
    selectedOption,
    tags,
    hidePages,
    initialLoading,
    concepts,
    perspectives,
    apiLoading,
    match: {
      params: { name }
    },
    relatedAuthors,
    relatedAuthorDrawer,
    subFilter
  } = props;

  const [author, setAuthor] = useState({});
  const [filter, setFilter] = useState(null);
  const [active, setActive] = useState(false);
  const [time, setTime] = useState(null);
  const [parentGroup, setParentGroup] = useState(null);
  const [pathname, setPathname] = useState(""); // This hook will be used to rerender component and automatically running listening top route change

  let entered = false;
  let keywords = true;

  const path = window.location.href.split("/")[4];

  const handleMouseEnter = () => {
    if (!active) {
      props.getReciprocalAuthors(selectedOption.value);
      clearTimeout(time);
      setActive(true);
    }
  };

  const handleMouseLeave = () => {
    setTime(
      setTimeout(() => {
        if (!entered) {
          setActive(false);
        }
      }, 200)
    );
  };

  const handleTooltipMouseEntered = () => {
    entered = true;
    handleMouseEnter();
  };

  const handleTooltipMouseLeave = () => {
    entered = false;
    handleMouseLeave();
  };

  useEffect(() => {
    if (window.location.pathname) {
      searchAuthor();
      setPathname(window.location.pathname);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (initialLoading) {
      searchAuthor();
    }
  }, [tags]);

  useEffect(() => {
    if (!initialLoading) {
      props.handleTags();
      searchAuthor();
    }
    mounted = true;
    history.listen((location, action) => {
      if (mounted && action === BACK_CODE) {
        setPathname(null);
        setPathname(location.pathname); // Update the state and when component re render and ultimately we will have updated props.match.params
      }
    });
    return () => {
      mounted = false;
      props.removeSubFilter();
    };
  }, []);

  useEffect(() => {
    searchAuthor();
  }, [pathname]);

  useEffect(() => {
    if (
      selectedOption.category === authorCategory &&
      author.value !== selectedOption.value &&
      author !== {} &&
      initialLoading
    ) {
      searchAuthor();
      props.removeSubFilter();
    }
    setAuthor(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (filter && concepts.length > 0 && initialLoading) {
      const concept = concepts.find(x => x.id === filter.id);
      const temp = { ...filter, value: concept.name, label: concept.name };
      // props.setSubFilter(temp);
      setFilter(null);
      props.perspectivesByFilter(temp, keywords);
    }
  });

  const searchAuthor = () => {
    const value = queryString.parse(window.location.search);
    let showPerspectives = true;
    if (Object.keys(value).length > 0) {
      let obj = {};
      if (value.Concept) {
        obj.id = parseInt(value.Concept);
        obj.type = "Concept";
        setFilter(obj);
        showPerspectives = false;
      }
      if (value.parentGroup) {
        setParentGroup(value.parentGroup);
      }
    }

    props.searchAuthor(name, showPerspectives);
  };

  const goToSimilarAuthor = author => {
    props.setRelatedAuthorDrawerOpen(false);
    props.setSelectedItem(getAuthorItem(author));
  };

  const page = hidePages ? (
    <Message />
  ) : (
    <div className="container-fluid">
      <Drawer
        direction="left"
        open={relatedAuthorDrawer}
        handleClose={() => props.setRelatedAuthorDrawerOpen(false)}
      >
        <AuthorCards authors={relatedAuthors} handleAuthorClick={goToSimilarAuthor} />
      </Drawer>
      {apiLoading && <ReactPageLoading />}
      {!apiLoading && (
        <div className="row">
          <div style={{ position: "relative" }}>
            <RelatedAuthors
              active={active}
              setActive={setActive}
              handleTooltipMouseEntered={handleTooltipMouseEntered}
              handleTooltipMouseLeave={handleTooltipMouseLeave}
              getReciprocalAuthors={handleMouseEnter}
            />
            <FirstCol
              active={active}
              setActive={setActive}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          </div>
          <SecondCol parentGroup={parentGroup} />
          <ThirdCol />
          <FacebookCol authorPage={true} />
        </div>
      )}
    </div>
  );

  return (
    <div
      className={subFilter ? "red-striped-page page red-page" : "page"}
      style={{ backgroundColor: "#f8d3cf" }}
    >
      <SeoTags
        title={getSeoTitle(selectedOption)}
        description={getSeoDescription(concepts, perspectives)}
      />
      {page}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  searchAuthor: bindActionCreators(actions.searchAuthor, dispatch),
  getReciprocalAuthors: bindActionCreators(actions.relatedReciprocalAuthors, dispatch),
  perspectivesByFilter: bindActionCreators(actions.perspectivesByFilter, dispatch),
  setSubFilter: bindActionCreators(navbarActions.setSubFilter, dispatch),
  removeSubFilter: bindActionCreators(navbarActions.removeSubFilter, dispatch),
  handleTags: bindActionCreators(navbarActions.handleTags, dispatch),
  setBioDrawerOpen: bindActionCreators(setBioDrawerOpen, dispatch),
  setRelatedAuthorDrawerOpen: bindActionCreators(setRelatedAuthorDrawerOpen, dispatch),
  setSelectedItem: bindActionCreators(navbarActions.setSelectedItem, dispatch)
});

const mapStateToProps = state => ({
  apiLoading: state.author.apiLoading,
  selectedOption: state.navbar.selectedOption,
  concepts: state.navbar.concepts,
  initialLoading: state.author.initialLoading,
  hidePages: state.navbar.hidePages,
  tags: state.navbar.tags,
  perspectives: state.navbar.perspectives,
  relatedAuthors: state.author.relatedAuthors,
  bioDrawer: state.mobile.bioDrawer,
  relatedAuthorDrawer: state.mobile.relatedAuthorDrawer,
  subFilter: state.navbar.subFilter
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NewAuthor));
