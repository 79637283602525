import React, { useState, Fragment } from "react";
import { Col } from "reactstrap";
import { authorPicLink } from "../../../helpers/authorHelpers";
import users from "../../../images/imageIcons/users.png";
import { SortableElement } from "react-sortable-hoc";
import { resizedImageUrl } from "../../utills/common/imageResize";

const AuthorCard = props => {
  const [active, setActive] = useState(false);
  const { author, tools, isAdmin, isPage, detailMode } = props;

  return (
    <Col
      style={{
        ...styles.container,
        zIndex: isPage ? 3 : 12,
        padding: detailMode ? 0 : 15,
        marginBottom: "16px"
      }}
      xs={6}
      md={detailMode ? 3 : 4}
    >
      <div id="related-author-wrapper" style={{ height: detailMode ? 100 : 180 }}>
        <div id="related-image-wrapper" onClick={() => props.onAuthorClick(author)}>
          <img
            alt="author"
            src={resizedImageUrl(authorPicLink(author), 100, 150)}
            onError={() => setActive(false)}
            onLoad={() => setActive(true)}
            style={{ display: active ? "block" : "none", width: "100%" }}
          />
          <img src={users} style={{ display: active ? "none" : "inline-block" }} alt="author" />
        </div>
        {isAdmin && (
          <div id="toolButton" style={styles.btnWrapper}>
            <input
              checked={author.reciprocalExist}
              type="radio"
              onClick={() => props.handleReciprocalClick(author)}
              className="cursor-pointer"
            />
            {tools && (
              <Fragment>
                <button
                  style={styles.trash}
                  className="fa fa-trash hover-red"
                  onClick={() => props.deleteRelatedAuthor(author.id, author.priority)}
                ></button>
                <h6
                  onMouseEnter={() => props.handleMouseEnter(author)}
                  onMouseLeave={props.handleMouseLeave}
                  className="relateds"
                  id={`author${author.id}`}
                >
                  R
                </h6>
              </Fragment>
            )}
          </div>
        )}
        <div style={styles.tools}>
          <a
            href={`/Authors/${author.slug}`}
            onClick={e => {
              e.preventDefault();
            }}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <h6
              onClick={() => props.onAuthorClick(author)}
            >{`${author.firstName} ${author.lastName}`}</h6>
          </a>
        </div>
      </div>
    </Col>
  );
};

const styles = {
  container: {
    // zIndex: 7
  },
  btnWrapper: {
    zIndex: 1
  },
  trash: {
    background: "transparent",
    border: "none"
  },
  tools: {
    display: "flex",
    justifyContent: "center",
    height: "20%"
  }
};

// exporting cards with sortabe

export const SortableAuthorCard = SortableElement(props => <AuthorCard {...props} />);

// exporting default card
export default AuthorCard;
