import axios from "axios";
import { BASE_URL } from "../constants/global";

export const clusterDetailByName = (name, filters, type, pageName) => {
  return axios.post(`${BASE_URL}/cluster/detail/${name}`, { filters, type, pageName });
};

export const clusterRelatedConcepts = name => {
  return axios.post(`${BASE_URL}/cluster/related/detail/${name}`);
};
