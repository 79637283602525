import React from "react";
import PropTypes from "prop-types";
import PerspectiveHeader from "../PerspectiveHeader/PerspectiveHeader";
import PerspectiveBody from "../PerspectiveBody/PerspectiveBody";
import "./context.css";

const Context = props => {
  const { perspective, author, label, index, id, authorPage, isSinglePerspective, slug } = props;
  return (
    <div id={id} className="perspective-box" style={styles.getBoxStyles(perspective.isFact)}>
      <h6 className="ago text-white">{perspective.ago}</h6>
      {/* {!authorPage && author.id !== 228 && !isSinglePerspective && <PerspectiveHeader author={author} />} */}
      {/* {console.log(perspective, "ll")} */}
      <PerspectiveBody
        perspective={perspective}
        id={id}
        author={author}
        label={label}
        slug={slug}
        index={index}
        isSinglePerspective={isSinglePerspective}
        borderTop={author ? (author.id === 228 ? true : false) : true}
        onLikeClick={props.onLikeClick}
        onConceptClick={props.onConceptClick}
        onCountClick={props.onCountClick}
        onFactClick={props.onFactClick}
        authorPage={authorPage}
        authorCallBack={props.authorCallBack}
      />
    </div>
  );
};

Context.prototype = {
  perspective: PropTypes.object.isRequired,
  author: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  onConceptClick: PropTypes.func, // Only send from concepts page
  onCountClick: PropTypes.func // Only send from concept page
};

export default React.memo(Context);

const styles = {
  getBoxStyles: isFact => {
    return isFact
      ? {
          background: "#FCEFEE",
          border: "1px solid red"
        }
      : {};
  }
};
