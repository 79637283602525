import React from "react";
import PropTypes from "prop-types";
import { resetSecondCol } from "../../../helpers/scrolling";
import history from "../../../router/history";

const AuthorsList = props => {
  const { authors, trash, selectedAuthor } = props;

  const callBack = (author, index) => {
    resetSecondCol();
    props.onAuthorClick(author, index);
  };

  const authorPage = window.location.href.split("/")[5];
  const currentConcept = window.location.href.split("/")[4];

  return (
    <ul style={styles.list}>
      {authorPage && (
        <p
          style={{ cursor: "pointer", fontWeight: "bold", marginBottom: "5px", marginLeft: "5px" }}
          onClick={() => history.push(`/dictionary/${currentConcept}`)}
        >
          SHOW ALL
        </p>
      )}
      {authors &&
        authors.map((author, index) => {
          return (
            <li key={author.id} style={styles.listItem}>
              <a
                href={`/Authors/${author.slug}`}
                onClick={e => {
                  e.preventDefault();
                }}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                {trash && (
                  <i className="fa fa-trash" onClick={() => props.onTrashClick(author)}></i>
                )}
                <span
                  style={
                    selectedAuthor && selectedAuthor.id === author.id
                      ? styles.selectedName
                      : styles.name
                  }
                  onClick={() => callBack(author, index)}
                >
                  <b className="hover-grey">
                    {author.lastName && author.firstName
                      ? `${author.lastName}, ${author.firstName}`
                      : author.lastName
                      ? author.lastName
                      : author.firstName
                      ? author.firstName
                      : ""}
                  </b>
                </span>
              </a>
            </li>
          );
        })}
    </ul>
  );
};

AuthorsList.propTypes = {
  authors: PropTypes.array.isRequired,
  onAuthorClick: PropTypes.func.isRequired,
  trash: PropTypes.bool,
  onTrashClick: PropTypes.func
};

export default AuthorsList;

const styles = {
  list: {
    lineHeight: "1.1em",
    height: "auto",
    listStyle: "none",
    padding: 0
  },
  listItem: {
    width: 150,
    padding: "8px"
  },
  name: {
    color: "black",
    textDecoration: "none",
    cursor: "pointer",
    fontSize: 14,
    height: "auto"
  },
  selectedName: {
    color: "red",
    textDecoration: "none",
    cursor: "pointer",
    fontSize: 14,
    height: "auto"
  }
};
