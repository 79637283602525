import React from "react";
import PropTypes from "prop-types";
import AuthorsList from "./authorsList";

const AuthorsSidebar = props => {
  const { authors, opacity, trash, selectedAuthor } = props;
  return (
    <div
      style={{
        opacity: opacity ? 0.3 : 1
      }}
    >
      <AuthorsList
        authors={authors}
        onAuthorClick={props.onAuthorClick}
        trash={trash}
        onTrashClick={props.onTrashClick}
        selectedAuthor={props.selectedAuthor}
      />
    </div>
  );
};

AuthorsSidebar.propTypes = {
  authors: PropTypes.array.isRequired,
  opacity: PropTypes.bool.isRequired,
  onAuthorClick: PropTypes.func.isRequired,
  trash: PropTypes.bool,
  onTrashClick: PropTypes.func
};

export default AuthorsSidebar;

const styles = {
  list: {
    lineHeight: "1.1em",
    height: "auto",
    listStyle: "none",
    padding: 0
  },
  listItem: {
    width: 150,
    padding: "8px"
  },
  name: {
    color: "black",
    textDecoration: "none",
    cursor: "pointer",
    fontSize: 14,
    height: "auto"
  }
};
