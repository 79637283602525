import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRelatedGroups } from "../../../selectors";
import { flushAuthorClusterState } from "../../../actions/autherCluster";
import { setSelectedItem } from "../../../actions/navbaractions";
import history from "../../../router/history";
import wikipedia from "../../../images/wikipedia.png";
import Loader from "react-loader-spinner";
import { AuthorWikiDetail } from "../../utills/PerspectiveBox/PerspectiveHeader/authorWikiInfo";
import AuthorCards from "./authorCards";
import ImageWrapper from "../../utills/Header/imageWrapper";
import { getAuthorItem } from "../../../helpers/authorHelpers";
import AuthorBooks from "./authorBooks";
import AboutThisThinker from "./aboutThisThinker";
import Iframe from "react-iframe";
import ToolTip from "react-portal-tooltip";
import AuthorImageCard from "./authorImageCard";

export const AuthorDetail = props => {
  const {
    selectedOption,
    authorGroups,
    detail: { dob, dod, Books, pictureLink },
    authorWikiInfo,
    authorWikiLoading,
    relatedAuthors,
    detail
  } = props;
  const [open, setOpen] = useState(false);

  const relatedConcept = detail.Concept;

  const onBioClick = bio => {
    let label = bio.distinct_grouping;
    label = label.split(" ").join("-");
    history.push(`/Author-Groups/${label}`);
    // let value = label;
    // let id = bio.distinct_grouping_id;
    // let category = "Author-Groups";
    // props.flushAuthorClusterState();
    // props.setSelectedItem({ label, value, id, category, via: "group" });
  };

  const replaced = selectedOption.value ? selectedOption.value.split(" ").join("_") : "";

  const aboutThinker = () => {
    history.push(`/dictionary/${props.selectedOption.slug}`);
  };

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div style={styles.wrapper}>
      <AuthorImageCard />
      {/* <button className="btn btn-link" style={{ color: 'black' }} onClick={() => history.push(`/books/author/${selectedOption.id}`)}>
                <i className="fas fa-book-open hover-grey"></i> 
                <span>
                    <b className="hover-grey">
                        ({Books && Books.length})
                    </b>
                </span>
            </button> */}

      <br />
      <br />
      <AuthorBooks />
      <AuthorCards
        authors={relatedAuthors}
        handleAuthorClick={author => props.setSelectedItem(getAuthorItem(author))}
        detailMode={true}
        currentAuthor={selectedOption.value}
      />
      {authorGroups &&
        authorGroups.length > 0 &&
        authorGroups.map((item, index) => {
          return (
            <div key={index} style={{ width: "100%" }}>
              <b style={{ fontSize: 15, display: "block" }}>
                {item.heading.charAt(0).toUpperCase() + item.heading.slice(1)}:
              </b>
              {item.values.map(v => {
                return (
                  <p key={v.id} style={{ marginTop: "-10px" }}>
                    <span style={{ display: "inline-block", padding: 0.1 }}>
                      <span
                        style={{ cursor: "pointer", color: "black", fontSize: 13 }}
                        className="hover-grey"
                        onClick={() =>
                          onBioClick({ distinct_grouping: v.name, distinct_grouping_id: v.id })
                        }
                      >
                        {v.name}
                      </span>
                      &nbsp;&nbsp;
                    </span>
                  </p>
                );
              })}
            </div>
          );
        })}
      {!authorWikiLoading && (
        <div id="readMore" style={styles.infoWrapper}>
          <div id="text" dangerouslySetInnerHTML={{ __html: authorWikiInfo.data }}></div>

          <span onClick={toggle}>
            <img src={wikipedia} alt="dictionaryish wikipedia" />
          </span>
        </div>
      )}
      {authorWikiLoading && (
        <div className="d-flex justify-content-center p-5">
          <Loader type="Puff" color="red" height="30" width="30" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  selectedOption: state.navbar.selectedOption,
  detail: state.author.authorDetail,
  authorGroups: getRelatedGroups(state),
  authorWikiInfo: state.author.authorWikiInfo || {},
  authorWikiLoading: state.author.authorWikiLoading,
  relatedAuthors: state.author.relatedAuthors
});

const mapDispatchToProps = dispatch => {
  return {
    flushAuthorClusterState: bindActionCreators(flushAuthorClusterState, dispatch),
    setSelectedItem: bindActionCreators(setSelectedItem, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorDetail);

const styles = {
  wrapper: {
    background: "white",
    padding: 10,
    paddingBottom: 80,
    minWidth: "100%"
  },
  outerBorderBtn: {
    margin: "2%",
    border: "4px solid white",
    borderRadius: 6
  },
  detailNameWrapper: {
    backgroundColor: "black",
    color: "white",
    height: "10%",
    paddingLeft: 2,
    fontSize: 23
  },
  btnWrapper: {
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "flex-end"
  },
  infoWrapper: {
    marginTop: 20,
    flexDirection: "column",
    alignItems: "flex-end"
  }
};
