import React, { Component } from "react";
import PropTypes from "prop-types";
import authorSortIcon from "../../../images/imageIcons/sortIcon.png";
import likeSortIcon from "../../../images/imageIcons/likeSortIcon.png";
import relatedIcon from "../../../images/imageIcons/related.png";
import relatedAuthors from "../../../images/imageIcons/relatedAuthors.png";
import surpriseIcon from "../../../images/surprise.png";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import ClusterToolTip from "./ClusterToolTip";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as navbarActions from "../../../actions/navbaractions";
import Filters from "./headerFilter";
import MemeButton from "./memeButton";
import HeaderSubFilter from "./headerSubFilter";
import PageShare from "../../utills/FacebookComment/PageShare";
import Memes from "./memeButton";
import ArchiveButton from "../PerspectiveBox/context/archiveButton";
import "./Header.css";
import { clusterCategory } from "../../../constants/global";
import { resetScroll } from "../../../helpers/scrolling";
import FactsButton from "../../utills/Header/FactsButton";
import { factCategory } from "../../../constants/global";
import search from "../../../helpers/search";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: [],
      allow: true,
      filterValue: "",
      noOptionsMessage: "",
      filterSearch: false,
      isTooltipActive: false,
      relatedToolTip: false,
      isArchiveToolTip: false,
      listenMouse: "",
      mouseDown: false
    };
    this.loadOptions = this.loadOptions.bind(this);
  }
  loadOptions = inputValue => {
    return new Promise(resolve => {
      this.setState({
        noOptionsMessage: "Start typing"
      });
      let allData = this.props.allData;
      let results = [];
      results = allData.filter((i, index) => {
        return i.label.toLowerCase().includes(inputValue.toLowerCase());
      });
      if (results.length < 1) {
        this.setState({
          noOptionsMessage: "No Options"
        });
      }
      resolve(search.orderSearchByAlphabets(results, inputValue).slice(0, 10));
    });
  };
  handleInputChange = newValue => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    resetScroll();
    return inputValue;
  };
  handleChange = input => {
    this.setState({
      filterValue: input.value,
      allow: true
    });
    this.props.filterCallBack(input);
  };
  viewCluster = () => {
    const { selectedOption } = this.props;
    this.props.setSelectedItem({
      id: selectedOption.id,
      label: selectedOption.value,
      value: selectedOption.value,
      category: clusterCategory
    });
  };

  closeRelatedAuthors = () => {
    this.setState({
      relatedToolTip: false
    });
  };

  isFilterOpen = open => {
    this.setState({
      filterSearch: open
    });
  };

  render() {
    const { filterSearch } = this.state;
    const { block, hideMemes } = this.props;
    let bg = "";
    if (!this.props.sortByLikes) {
      bg = "orange";
    }
    if (block) {
      bg = "";
    }
    return (
      <div
        id="header"
        className="d-none d-md-block main-header"
        onMouseLeave={() =>
          this.setState({ isTooltipActive: false, isArchiveToolTip: false, relatedToolTip: false })
        }
      >
        <div className="row" id="header-row">
          {/* <div style={{ display: "flex", flex: 1, paddingLeft: 0, paddingTop: 5, alignItems: "center" }}> */}
          {this.props.showFilterSearch && !this.props.data && !filterSearch && (
            <div style={{ width: "100%", maxWidth: "80%", marginRight: 2 }}>
              <HeaderSubFilter
                loadOptions={this.loadOptions}
                onChange={this.handleChange}
                onFocus={() => {
                  this.setState({ noOptionsMessage: "Start Typing" });
                }}
                noOptionsMessage={() => this.state.noOptionsMessage}
              />
            </div>
          )}
          {!hideMemes &&
            (this.props.memesCount > 0 || this.props.user.isAdmin) &&
            !this.props.isSingleBook && (
              <div style={{ marginRight: 2 }}>
                <Memes />
              </div>
            )}
          {
            // <div style={{ marginRight: 2 }}>
            //   <PageShare />
            // </div>
          }
          {/* {
                               this.props.isMemePage && selectedConcept && 
                               <>
                                     <h1 style={{textTransform: "capitalize"}}>{selectedConcept.name}</h1>      
                               </>
                           } */}
          {/* {this.props.factsCount > 0 && <div style={{ marginRight: 2 }}>
                            <FactsButton
                                count={this.props.factsCount}
                                onClick={this.props.handleFactsClick}
                                active={this.props.subFilter ? this.props.subFilter.type === factCategory : false}
                            />
                        </div>
                        } */}
        </div>

        {/* </div> */}
        {this.props.data && (
          <SecondaryNavbar
            data={this.props.data}
            handleBackClick={() => this.props.setFactKeyword(-1)}
            showAll={this.props.showAll}
          />
        )}
      </div>
    );
  }
}

const styles = {
  column: {
    padding: 4
  },
  responseive: {
    width: "100%",
    height: "100%"
  },
  relatedIcon: {
    width: 33,
    height: "100%",
    borderRadius: 20
  }
};

const mapDispatchToProps = dispatch => {
  return {
    surpriseConcept: bindActionCreators(navbarActions.surpriseConcept, dispatch),
    setSelectedItem: bindActionCreators(navbarActions.setSelectedItem, dispatch),
    searchFocus: bindActionCreators(navbarActions.searchFocus, dispatch)
  };
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    factsCount: state.concepts.factsCount,
    isAuthenticated: state.auth.isAuthenticated,
    selectedOption: state.navbar.selectedOption,
    relatedAuthors: state.author.relatedAuthors,
    concept: state.concepts,
    memesCount: state.memes.count,
    loading: state.concepts.listRelatedLoading,
    subFilter: state.navbar.subFilter,
    selectedConceptId: state.navbar.selectedConceptId,
    memeConcepts: state.navbar.concepts
  };
};

Header.propTypes = {
  sortByLikes: PropTypes.bool,
  showFilterSearch: PropTypes.bool,
  isConceptPage: PropTypes.bool,
  isAuthorPage: PropTypes.bool,
  hideMemes: PropTypes.bool,
  allData: PropTypes.array,
  data: PropTypes.object,
  showAll: PropTypes.func,
  filterCallBack: PropTypes.func,
  toggleSortRight: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
