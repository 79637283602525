export const getArchiveName = (name, user) => {
    if(name === "My Archive")
    {
        let temp = ''
        if(user.name)
        {
            temp =  `${user.name.charAt(0).toUpperCase() + user.name.slice(1)}'s Archive`
        }
        return temp
    }
    else
    {
        return name
    }
}