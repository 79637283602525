import React, { Component, useEffect } from "react";
import { Button, Form, FormGroup, Input } from "reactstrap";
import Loader from "react-loader-spinner";
import "./SocialLogin.css";
import EmailModal from "./emailModal";
import { Alert } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
class LoginForm extends Component {
  state = {
    email: "",
    password: "",
    emailError: false,
    passwordError: false,
    openModal: false,
    errorRecaptcha: "",
    checkRecaptcha: false
  };

  onResolved() {
    this.setState({ messageSent: true });
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = (e, isSignup) => {
    e.preventDefault();
    if (this.state.email === "") {
      this.setState({
        emailError: true,
        passwordError: false
      });
    } else if (this.state.password === "") {
      this.setState({
        passwordError: true,
        emailError: false
      });
    } else if (false) {
      this.setState({ errorRecaptcha: "Please Fill the Recaptcha" });
    } else {
      this.setState({
        emailError: false,
        passwordError: false
      });
      e.preventDefault();
      let account = {
        email: this.state.email,
        password: this.state.password
      };
      this.props.login(account);
      if (!this.props.user.isVerified) {
        this.setState({
          openModal: true
        });
      }
    }
  };
  recaptchaOnChange = () => {
    this.setState({
      checkRecaptcha: true
    });
  };
  resendEmail = () => {
    this.props.resendEmail(this.props.user.id);
  };

  render() {
    let {
      invalidLogin,
      loginRequestStatus_admin,
      user,
      emailNotVerified,
      verificationEmailSent
    } = this.props;
    return (
      <div>
        <Form style={styles.form} onSubmit={this.handleSubmit}>
          {this.state.errorRecaptcha && <Alert color="danger">{this.state.errorRecaptcha}</Alert>}
          <FormGroup>
            <Input
              type="email"
              name="email"
              id="email"
              onChange={this.handleInputChange}
              placeholder="email"
            />
            {this.state.emailError && (
              <p style={styles.error} className="alert alert-danger">
                Email cannot be null
              </p>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              name="password"
              id="password"
              onChange={this.handleInputChange}
              placeholder="Password"
            />
            {this.state.passwordError && (
              <p style={styles.error} className="alert alert-danger">
                Password cannot be null
              </p>
            )}
          </FormGroup>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_V2_KEY}
            onChange={this.recaptchaOnChange}
          />

          {invalidLogin ? <p className="alert alert-info">Invalid username/password</p> : null}
          {/* {this.state.openModal ? <p className="alert alert-info">Your Account Is Not Verified</p> : null} */}
          <p onClick={this.props.switchModal} style={styles.dontHaveAccount}>
            Don't have an account? signup
          </p>
          <EmailModal />
          {emailNotVerified && (
            <Button
              className="mb-2"
              outline
              color="primary"
              onClick={() => this.props.resendConfirmationEmail(this.state.email)}
              block
            >
              Resend Email
            </Button>
          )}
          {emailNotVerified && verificationEmailSent && (
            <p style={styles.error} className="alert alert-info">
              Check your email
            </p>
          )}

          <Button type="submit" className="btn form-control" style={styles.submitButton}>
            {loginRequestStatus_admin ? (
              "Login"
            ) : (
              <Loader type="Puff" color="#FFF" height="30" width="30" />
            )}
          </Button>
        </Form>
      </div>
    );
  }
}
const styles = {
  form: {
    margin: "auto",
    padding: "20px 30px",
    background: "#fff",
    boxShadow: "0 0 20px #ccc"
  },
  dontHaveAccount: {
    color: "#641E89",
    textDecoration: "underline",
    cursor: "pointer"
  },
  submitButton: {
    width: "100%",
    height: "40px",
    backgroundColor: "#641E89",
    color: "#fff",
    border: "1px solid white"
  },
  resendEmailButton: {
    width: "100%",
    color: "#fff",
    border: "1px solid white"
  },
  error: {
    padding: "3px",
    fontSize: "12px"
  }
};
export default LoginForm;
