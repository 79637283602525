import store from "../../../store/configureStore";

export const crateMetaTags = data => {
  let pos = getCurrentPos();
  const contentType = getCurrentContentType();
  let description;
  switch (contentType) {
    case "similes":
      description =
        data.similesContent.length > 0
          ? generateMetaTagContent(data.similesContent)
          : `${pos} | similes for ${pos} at Dictionaryish.com`;

      break;
    case "idioms":
      description =
        data.idiomsContent.length > 0
          ? generateMetaTagContent(data.idiomsContent)
          : `${pos} | idioms for ${pos} at Dictionaryish.com`;

      break;
    case "superlatives":
      description =
        data.superlativeContent.length > 0
          ? generateMetaTagContent(data.superlativeContent)
          : `${pos} | superlative for ${pos} at Dictionaryish.com`;

      break;
    case "collocations":
      description = description = "posCollocations";

      break;

    default:
      description = data
        ? generateMetaTagContentDefault(data)
        : `${pos} | similes, idioms, superlatives, collocations, for ${pos} at Dictionaryish.com`;
      break;
  }
  description = contentType ? `${contentType} for ${pos} : ${description}` : description;
  const title = contentType
    ? `${contentType}` + " " + "for" + " " + `${pos} | Dictionaryish.com`
    : `${pos} at Dictionaryish.com`;

  return { title, description };
};

const generateMetaTagContent = contents => {
  let description = ``;
  contents.length > 0 &&
    contents.slice(0, 10).forEach((content, index) => {
      description = description + " " + `${content.content}` + " " + "○" + " ";
    });
  return description;
};

const generateMetaTagContentDefault = ({ similesContent, idiomsContent, superlativeContent }) => {
  let description = ``;
  similesContent.length > 0 &&
    similesContent.slice(0, 10).forEach((content, index) => {
      description = description + " " + `${content.content}` + " " + "○" + " ";
    });
  return description;
};

export const getCurrentPosData = () => {
  const {
    pos: { posSynonyms }
  } = store.store.getState();
  const currentPosName = getCurrentPos();

  return posSynonyms.find(pos => pos.term === currentPosName);
};
export const getCurrentConceptData = () => {
  const {
    pos: { relatedConcepts }
  } = store.store.getState();
  const currentPosName = getCurrentPos();
  const concept = relatedConcepts.find(concept => concept.name === currentPosName);
  return concept;
};

export const getCurrentPos = () => {
  const path = window.location.pathname.split("/")[2];
  return path ? path.split("=")[0].replace(/%20/g, " ") : path;
};

export const getCurrentPosGrammarType = () => {
  const path = window.location.href.split("?")[1];
  return path ? path.split("=")[0].replace(/%20/g, " ") : path;
};

export const getCurrentContentType = () => {
  return window.location.href.split("=")[1] || null;
};

export const getColorOfPos = selectedPos => {
  const grammar = getCurrentPosGrammarType();
  switch (grammar) {
    case "adj":
      return "containerHeadingAdj";
    case "v":
      return "containerHeadingV";
    case "n":
      return selectedPos === "adj" ? "containerHeadingAdj" : "containerHeadingV";
    default:
      return "containerHeading";
  }
};

export const getCurrentConcept = () => {
  const path = window.location.pathname.split("/")[2];
  return path ? path.split("=")[0].replace(/%20/g, " ") : path;
};
export const getFullForm = selectedPos => {
  switch (selectedPos) {
    case "adj":
      return "adjective";
    case "v":
      return "verb";
    case "n":
      return "noun";
    default:
      return "noun";
  }
};

export const getPosContentHelper = contentType => {
  switch (contentType) {
    case "adj":
      return "as";
    case "v":
      return "like";
    case "superlatives":
      return "";
    default:
      return "";
  }
};

export const getWidthOfContainers = () => {
  const grammar = getCurrentPosGrammarType();
  switch (grammar) {
    case "n":
      return {
        search: "nounSearchStyle",
        heading: "nounPosHeading",
        navbar: "nounPosNavContainer",
        containerWrapper: "nounContainerWrapper",
        contentData: "nounContentData",
        simileTitle: "nounContentHeadingSimile",
        contentNotFound: "nounContentNotFound",
        navHeading: "nounHeading"
      };
    case "adj":
    case "v":
      return {
        search: "searchStyle",
        heading: "posHeading",
        navbar: "posNavContainer",
        containerWrapper: "containerWrapper",
        contentData: "contentData",
        simileTitle: "contentHeadingSimile",
        contentNotFound: "contentNotFound",
        navHeading: "heading"
      };
    default:
      return {
        search: "searchStyle",
        heading: "posHeading",
        navbar: "posNavContainer",
        containerWrapper: "containerWrapper",
        contentData: "ContentData",
        simileTitle: "contentHeadingSimile",
        contentNotFound: "ContentNotFound",
        navHeading: "heading"
      };
  }
};

export const getColorOfSynonyms = grammar => {
  switch (grammar) {
    case "adj":
      return "orange";
    case "v":
      return "purple";
    case "n":
      return "#454B1B";
    case "adv":
      return "#00752d";
    default:
      return "#000000";
  }
};

export const getStyleOfContentType = type => {
  switch (type) {
    case "similes":
      return "similesColor";
    case "idioms":
      return "idiomsColor";
    case "superlatives":
      return "superlativeColor";
    case "ljs":
      return "ljsColor";
    case "exact":
      return "exactColor";
    default:
      return "#000000";
  }
};

export const getColorOfContentType = type => {
  switch (type) {
    case "similes":
      return "#357A76";
    case "idioms":
      return "#B51800";
    case "superlatives":
      return "#81790B";
    case "ljss":
      return "#A446FF";
    case "exact":
      return "205688";
    default:
      return "#357A76";
  }
};
