const initialState = {
    keywordsForPerspectiveId: [],
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case "GET_SINGLE_CONCEPT_KEYWORDS":
            return {
                ...state,
                keywordsForPerspectiveId: [...action.payload]
            }
        default:
            return state;
    }
}