import * as constants from "../constants/mobile"
import history from "../router/history"
export const setDrawerOpen = open => {
    return dispatch => {
        dispatch({
            type: constants.SET_DRAWER,
            payload: open
        })
    }
}

export const setRelatedConceptDrawerOpen = open => {
    return dispatch => {
        dispatch({
            type: constants.SET_RELATED_CONCEPT_DRAWER,
            payload: open
        })
    }
}

export const setAuthorDrawerOpen = open => {
    return dispatch => {
        dispatch({
            type: constants.SET_AUTHOR_DRAWER,
            payload: open
        })
    }
}

export const setMobileMemesMode = (open) => {
    return (dispatch, getState) => {
        const state = getState()
        let { id, category } = state.navbar.selectedOption
        dispatch({
            type: constants.SET_MOBILE_MEMES_MODE,
            payload: open
        })
        history.push(`/memes?${category.toLowerCase()}=${id}`)
    }
}

export const setBioDrawerOpen = open => {
    return dispatch => {
        dispatch({
            type: constants.SET_BIO_DRAWER,
            payload: open
        })
    }
}

export const setNewsDrawerOpen = open => {
    return dispatch => {
        dispatch({
            type: constants.SET_NEWS_DRAWER,
            payload: open
        })
    }
}

export const setRelatedAuthorDrawerOpen = open => {
    return dispatch => {
        dispatch({
            type: constants.SET_RELATED_AUTHOR_DRAWER,
            payload: open
        })
    }
}

export const handleRightSwipe = () => {
    return (dispatch, getState) => {
        const state = getState()
        const { authorDrawer, newsDrawer, bioDrawer } = state.mobile
        if (authorDrawer || bioDrawer || newsDrawer) {
            dispatch({
                type: constants.SWIPED,
            })
        }
    }
}

export const handleLeftSwipe = () => {
    return (dispatch, getState) => {
        const state = getState()
        const { relatedConceptDrawer, conceptDrawer, relatedAuthorDrawer } = state.mobile
        if (conceptDrawer || relatedAuthorDrawer || relatedConceptDrawer) {
            dispatch({
                type: constants.SWIPED,
            })
        }
    }
}

export const closeAllDrawer = () => {
    return (dispatch) => {
        dispatch({
            type: constants.SWIPED,
        })
    }
}

export const setCommentsMode = (active) => {
    return (dispatch) => {
        dispatch({
            type: constants.SET_COMMENTS_MODE,
            payload: active
        })
    }
}
