import axios from "axios";
import * as navbarConstants from "../constants/navbar";
export function accountConfirmation(token, userId) {
  return async dispatch => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/rest/v1/confirmationAccount`,
        { token, userId }
      );
      dispatch({
        type: "SET_ACCOUNTVERIFIED_MESSAGE",
        payload: response.data.message
      });
    } catch (e) {
      if (e.response && e.response.status === 400)
        dispatch({
          type: "SET_FAILED_MESSAGE",
          payload: e.response.data.message
        });
      console.log("Response ", e.response);
      return e.response;
    }
  };
}
export function resendEmail(userId) {
  return async dispatch => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER}/api/rest/v1/resendEmail`, {
        userId
      });
      dispatch({
        type: "SET_ACCOUNTVERIFIED_MESSAGE",
        payload: response.data.message
      });
    } catch (e) {
      if (e.response && e.response.status === 400)
        dispatch({
          type: "SET_FAILED_MESSAGE",
          payload: e.response.data.message
        });
      console.log("Response ", e.response);
      return e.response;
    }
  };
}

export function resendConfirmationEmail(email) {
  return async dispatch => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/rest/v1/email/confirmation`,
        { email }
      );
      dispatch({
        type: navbarConstants.VERIFICATION_EMAIL_SENT,
        payload: true
      });
    } catch (e) {
      console.log("Response ", e.response);
    }
  };
}

export const toogleCategory = data => dispatch => {
  dispatch({
    type: "TOOGLE_CATEGORY",
    payload: data
  });
};
