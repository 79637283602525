import React, { useEffect, useState, createContext } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MemeCard from "../../Memes/memeCards";
import TextMemeCard from "../../Memes/textMemeCard";
import * as actions from "../../../actions/memes";
import Perspectives from "../../utills/PerspectiveBox";
import { conceptCategory, SITE_TITLE } from "../../../constants/global";
import _ from "lodash";
import PropTypes from "prop-types";
import AdsComponent from "../../../components/utills/Addsense/addsense";
import IntervalMemes from "../../Memes/intervalMemes";
import Context from "../../utills/PerspectiveBox/context/context";
import { likeHomepagePerspective, openModal } from "../../../actions/navbaractions";
import PerspectiveLoading from "../../utills/ReactLoading/PerspectiveLoading";
import { ARCHIVE_MODE, NEWS_MODE } from "../../../constants/navbar";
import emptyArchive from "../../../images/emptyArchive.png";
import { Button } from "reactstrap";
import { getPerspectives, showData } from "../../../selectors/index";
import { BLACK } from "../../../constants/colors";

export const PerspectiveContext = createContext();

const MemesPerspectives = props => {
  const [concepts, setConcepts] = useState([]);
  const {
    memes,
    fetchLoading,
    memePageMemes,
    memeLoading,
    memeTags,
    memeConcepts,
    toggle,
    handleToggle,
    archivePerspectives,
    data,
    navLoading,
    authorPage,
    archiveMode,
    newsMode,
    archives,
    isAuthenticated,
    isPublic,
    archiveOwner,
    user,
    perspectivesLength,
    subFilter,
    selectedOption,
    showData,
    FooterForm
  } = props;

  const [selectedId, setSelected] = useState(-1);

  let perspectives = data;
  if (archives) {
    perspectives = archivePerspectives;
  }
  if (!showData) {
    perspectives = [];
  }

  let uniqMemes = memePageMemes && _.uniqBy(memePageMemes, "id");

  useEffect(() => {
    let memeTagIds = memeTags && memeTags.map(item => item.ConceptId);
    let filteredMemeConcepts =
      memeConcepts && memeTagIds && memeConcepts.filter(item => !memeTagIds.includes(item.id));
    setConcepts(filteredMemeConcepts);
  }, [memeConcepts]);

  const memeRoute = window.location.pathname.split("/")[1] == "memes";
  return (
    <React.Fragment>
      <Perspectives />
      {/* <PerspectiveContext.Provider value={{ selectedId, setSelected }}>
        {perspectives.length > 0 && <PerspectiveLoading perspectiveLoading={navLoading} />}
        {perspectives &&
          perspectives.map((perspective, index) => {
            perspective.index = index;
            return (
              <div key={index}>
                {index === perspectivesLength &&
                  selectedOption.category === conceptCategory &&
                  subFilter && (
                    <div
                      style={{
                        borderTop: `2px solid ${BLACK}`,
                        borderBottom: `2px solid ${BLACK}`,
                        paddingTop: "2%",
                        textAlign: "center"
                      }}
                    >
                      <h3>
                        {subFilter.label} and {selectedOption.label}
                      </h3>
                    </div>
                  )}
                {index % 10 === 0 && index !== 0 && <AdsComponent />}
                <IntervalMemes
                  index={index}
                  interval={window.location.pathname === "/" ? (newsMode ? 5 : 8) : 8}
                />
                <Context
                  perspective={perspective}
                  label={perspective.Concept.name}
                  slug={perspective.Concept.slug}
                  author={perspective.Author}
                  index={index}
                  id={perspective.ConceptId}
                  onLikeClick={props.onLikeClick}
                  authorPage={authorPage}
                  onConceptClick={props.onConceptClick}
                  onCountClick={props.onCountClick}
                  onFactClick={props.onFactClick}
                  authorCallBack={props.authorCallBack}
                />

                {index % 15 === 0 && index !== 0 && FooterForm}
              </div>
            );
          })}

        {perspectives.length < 1 && window.location.pathname === "/" && archiveMode && !navLoading && (
          <div>
            <img src={emptyArchive} alt={SITE_TITLE + "archive"} style={styles.image} />
            {!isAuthenticated && (
              <Button onClick={props.openModal} color="primary" size="sm">
                Login
              </Button>
            )}
          </div>
        )}

        {perspectives.length > 0 && <PerspectiveLoading perspectiveLoading={navLoading} />}
      </PerspectiveContext.Provider> */}

      {/* {!memeRoute ? (
        <>
          {memes &&
            memes.map(meme =>
              meme.isText ? <TextMemeCard meme={meme} /> : <MemeCard meme={meme} />
            )}
          <div style={styles.loading}>
            <PerspectiveLoading perspectiveLoading={fetchLoading} />
          </div>
        </>
      ) : (
        <>
          {
            <p>
              <i
                style={{
                  marginTop: "30px",
                  cursor: "pointer",
                  color: toggle ? "black" : "#adb8b0"
                }}
                onClick={handleToggle}
                class="fas fa-random"
              >
                Shuffle
              </i>
            </p>
          }
          {uniqMemes &&
            uniqMemes.map(meme =>
              meme.isText ? <TextMemeCard meme={meme} /> : <MemeCard meme={meme} />
            )}

          <div style={styles.loading}>
            <PerspectiveLoading perspectiveLoading={fetchLoading} />
          </div>
        </>
      )}

      {!memeLoading && !fetchLoading && memes.length == 0 && (
        <h3 style={{ textAlign: "center" }}>Oops!!!!!! No memes found :(</h3>
      )} */}
    </React.Fragment>
  );
};
MemesPerspectives.prototype = {
  onConceptClick: PropTypes.func, // Only send from concepts page
  onCountClick: PropTypes.func, // Only send from concept page
  onFactClick: PropTypes.func, // Only send from concept page
  authorCallBack: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    likeMeme: bindActionCreators(actions.likeMeme, dispatch),
    onLikeClick: bindActionCreators(likeHomepagePerspective, dispatch),
    openModal: bindActionCreators(openModal, dispatch)
  };
};

const mapStateToProps = state => {
  return {
    memes: state.memes.memes,
    memePageMemes: state.memes.memePageMemes,
    memeTags: state.memes.textMemeColors,
    fetchLoading: state.memes.fetchLoading,
    memeConcepts: state.navbar.concepts,
    memeLoading: state.memes.loading,
    data: state.navbar.perspectives,
    archivePerspectives: state.archives.perspectives,
    navLoading: state.navbar.loading,
    archiveMode: state.navbar.mode === ARCHIVE_MODE,
    newsMode: state.navbar.mode === NEWS_MODE,
    isAuthenticated: state.auth.isAuthenticated,
    isPublic: state.archives.isPublic,
    archiveOwner: state.archives.archiveOwner,
    user: state.auth.user,
    showData: showData(state),
    perspectivesLength: state.navbar.perspectivesLength,
    selectedOption: state.navbar.selectedOption,
    subFilter: state.navbar.subFilter
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemesPerspectives);

const styles = {
  loading: {
    paddingTop: 30,
    paddingBottom: 30
  },
  memeWrapper: {
    border: "4px solid rgb(187, 187, 187)",
    borderRadius: 4,
    padding: 8,
    marginBottom: 20,
    marginTop: 20
  },
  image: {
    width: "100%",
    paddingTop: 70
  }
};
