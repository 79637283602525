import React from 'react';
import AdSense from 'react-adsense';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

function AdsComponent(props) {
        const client = process.env.REACT_APP_ADSENSE_CLIENT
        const slot = process.env.REACT_APP_ADSENSE_SLOT

        return (
                <>
                        <ErrorBoundary>
                                <AdSense.Google
                                        client={client}
                                        slot={slot}
                                        style={{ display: 'block', height: 'auto' }}
                                        format='auto'
                                        responsive='true'
                                        layoutKey='-gw-1+2a-9x+5c'
                                />
                        </ErrorBoundary>
                </>
        )
}

export default AdsComponent;