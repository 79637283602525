import axios from "axios";
import * as constants from "../constants/memes";
import * as navbarConstants from "../constants/navbar";
import { getPageMemesCondition } from "../helpers/memes";
import * as memeServices from "../services/meme";
import { removeDuplicate } from "../helpers/common";
import { dataPagination } from "../helpers/navbar";
import { resetSecondCol } from "../helpers/scrolling";
import { toast } from "react-toastify";
const BASE_URL = `${process.env.REACT_APP_SERVER}/api/rest/v1`;

export function getMemesWithNoTags() {
  return async dispatch => {
    let res = await axios.get(`${BASE_URL}/memes/noTag`);
    dispatch({
      type: constants.SAVE_MEMES,
      payload: {
        memes: res.data.data,
        fetchLoading: false
      }
    });
    dispatch({
      type: constants.MEME_PAGE_MEMES,
      payload: {
        memePageMemes: res.data.data
      }
    });
  };
}

export function setOffset(offset) {
  return dispatch => {
    dispatch({
      type: constants.SET_OFFSET,
      payload: offset
    });
  };
}

export function getMemeTypeCount(thirdKey, keyword) {
  return async dispatch => {
    let res = await memeServices.getMemeTypeCount(thirdKey, keyword);
    dispatch({
      type: constants.MEMES_TYPE_COUNT,
      payload: res.data.memeTypeCount
    });
  };
}

export function setMemesFilter(filter) {
  return async dispatch => {
    dispatch({
      type: navbarConstants.SET_MEMES_FILTER,
      payload: filter
    });
  };
}

export function getAllTextMemesColors(filter) {
  return async dispatch => {
    let res = await axios.get(`${BASE_URL}/memes/getcolors`);
    dispatch({ type: "GET_MEMES_COLORS", payload: res.data.colors });
  };
}

export function fetchMemes(condition) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const offset = state.memes.offset;
      const limit = state.memes.limit;
      let memeColors = state.memes.textMemeColors;
      condition.limit = limit;
      condition.offset = offset;
      if (condition) {
        dispatch({
          type: constants.MEMES_REQUEST,
          payload: true
        });
        const response = await axios.post(`${BASE_URL}/memes`, condition);
        if (response.status === 200) {
          let data = [];
          if (offset === 0) {
            resetSecondCol();
            data = response.data.data;
            dispatch({
              type: "SET_SELECTED_OPTION",
              payload: response.data.selectedOption
            });
          } else {
            data = [...state.memes.memes, ...response.data.data];
          }

          let filteredMemes = [];

          dispatch({
            type: constants.SAVE_MEMES,
            payload: {
              memes: data,
              fetchLoading: false
            }
          });

          dispatch({
            type: constants.MEME_PAGE_MEMES,
            payload: {
              memePageMemes: data
            }
          });
          dispatch({
            type: navbarConstants.CONCEPTS,
            payload: response.data.concepts
          });
          dispatch({
            type: constants.SET_MEMES_COUNT,
            payload: response.data.memesCount
          });
          dispatch({
            type: navbarConstants.SELECTED_CONCEPT_ID,
            payload: -1
          });
        }
      }
    } catch (e) {
      dispatch({
        type: constants.MEMES_REQUEST,
        payload: false
      });
    }
  };
}

export function fetchAdvertMemes() {
  return async (dispatch, getState) => {
    try {
      let condition = {};
      const state = getState();
      const offset = state.memes.offset;
      const limit = state.memes.limit;
      //let memeColors = state.memes.textMemeColors;
      condition.limit = limit;
      condition.offset = offset;
      if (condition) {
        dispatch({
          type: constants.MEMES_REQUEST,
          payload: true
        });
        const response = await axios.get(`${BASE_URL}/wackyWares?${limit}&${offset}`);
        if (response.status === 200) {
          let data = [];
          if (offset === 0) {
            resetSecondCol();
            data = response.data.data;
            // dispatch({
            //   type: "SET_SELECTED_OPTION",
            //   payload: response.data.selectedOption
            // });
          } else {
            data = [...state.memes.memes, ...response.data.data];
          }

          dispatch({
            type: constants.SAVE_MEMES,
            payload: {
              memes: data,
              fetchLoading: false
            }
          });

          //   dispatch({
          //     type: constants.MEME_PAGE_MEMES,
          //     payload: {
          //       memePageMemes: data
          //     }
          //   });
          dispatch({
            type: navbarConstants.VIRAL_KEYWORDS,
            payload: response.data.concepts
          });
          //   dispatch({
          //     type: constants.SET_MEMES_COUNT,
          //     payload: response.data.memesCount
          //   });
          //   dispatch({
          //     type: navbarConstants.SELECTED_CONCEPT_ID,
          //     payload: -1
          //   });
        }
      }
    } catch (e) {
      dispatch({
        type: constants.MEMES_REQUEST,
        payload: false
      });
    }
  };
}

export function fetchUploadMemes(condition) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const offset = state.memes.offset;
      const limit = state.memes.limit;
      const oldMeme = state.memes.memePageMemes;
      condition.limit = limit;
      condition.offset = offset;
      if (condition) {
        dispatch({
          type: constants.MEMES_REQUEST,
          payload: true
        });
        const response = await axios.post(`${BASE_URL}/memes/unpublished`, condition);
        if (response.status === 200) {
          let data = [];
          if (offset === 0) {
            data = response.data.data;
            dispatch({
              type: "SET_SELECTED_OPTION",
              payload: response.data.selectedOption
            });
          } else {
            data = [...state.memes.memes, ...response.data.data];
          }

          dispatch({
            type: constants.SAVE_MEMES,
            payload: {
              memes: data,
              fetchLoading: false
            }
          });
          dispatch({
            type: constants.MEME_PAGE_MEMES,
            payload: {
              memePageMemes: data
            }
          });
          dispatch({
            type: navbarConstants.CONCEPTS,
            payload: response.data.concepts
          });
          dispatch({
            type: constants.SET_MEMES_COUNT,
            payload: response.data.memesCount
          });
          dispatch({
            type: navbarConstants.SELECTED_CONCEPT_ID,
            payload: -1
          });
        }
      }
    } catch (e) {
      dispatch({
        type: constants.MEMES_REQUEST,
        payload: false
      });
    }
  };
}

export function publishMemes(condition) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const offset = state.memes.offset;
      const limit = state.memes.limit;
      const oldMeme = state.memes.memePageMemes;
      condition.limit = limit;
      condition.offset = offset;
      if (condition) {
        dispatch({
          type: constants.MEMES_REQUEST,
          payload: true
        });
        const response = await axios.post(`${BASE_URL}/memes/publish`, condition);
        if (response.status === 200) {
          let data = [];
          if (offset === 0) {
            data = response.data.data;
            dispatch({
              type: "SET_SELECTED_OPTION",
              payload: response.data.selectedOption
            });
          } else {
            data = [...state.memes.memes, ...response.data.data];
          }

          dispatch({
            type: constants.SAVE_MEMES,
            payload: {
              memes: data,
              fetchLoading: false
            }
          });
          dispatch({
            type: constants.MEME_PAGE_MEMES,
            payload: {
              memePageMemes: data
            }
          });
          dispatch({
            type: navbarConstants.CONCEPTS,
            payload: response.data.concepts
          });
          dispatch({
            type: constants.SET_MEMES_COUNT,
            payload: response.data.memesCount
          });
          dispatch({
            type: navbarConstants.SELECTED_CONCEPT_ID,
            payload: -1
          });
        }
      }
    } catch (e) {
      dispatch({
        type: constants.MEMES_REQUEST,
        payload: false
      });
    }
  };
}

export function getRandomConcepts(pageName) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`${BASE_URL}/randomConcepts?pageName=${pageName}`);
      let randomConcepts = response && response.data;

      randomConcepts = randomConcepts.filter(elm => elm.slug !== pageName);

      dispatch({
        type: "randomConcepts",
        payload: randomConcepts
      });
    } catch (e) {
      console.log("e", e);
    }
  };
}

export function getRandomMemes(memeType) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const offset = state.memes.offset;

      const limit = state.memes.limit;

      dispatch({
        type: constants.MEMES_REQUEST,
        payload: true
      });

      const response = await axios.get(`${BASE_URL}/randomMemes?memeType=${memeType}`);
      if (response.status === 200) {
        let data = [];

        if (offset === 0) {
          data = [...response.data.data];
        } else {
          data = [...state.memes.memes, ...response.data.data];
        }

        dispatch({
          type: constants.SAVE_MEMES,
          payload: {
            memes: data,
            fetchLoading: false
          }
        });
        dispatch({
          type: constants.SET_OFFSET,
          payload: offset + 10
        });
        dispatch({
          type: constants.MEME_PAGE_MEMES,
          payload: {
            memePageMemes: data
          }
        });
        dispatch({
          type: constants.SAVE_MEMES_WITH_TAGS,
          payload: data
        });

        dispatch({
          type: navbarConstants.VIRAL_KEYWORDS,
          payload: response.data.concepts
        });

        dispatch({
          type: constants.SET_MEMES_COUNT,
          payload: response.data.memesCount
        });
        dispatch({
          type: navbarConstants.SELECTED_CONCEPT_ID,
          payload: -1
        });
      }
    } catch (e) {
      dispatch({
        type: constants.MEMES_REQUEST,
        payload: false
      });
    }
  };
}

//////////////////

export function getProverbs(concept) {
  return async (dispatch, getState) => {
    try {
     
      const state = getState();
      const offset = state.memes.offset;

      const response = await axios.post(`${BASE_URL}/proverbs?concept=${concept}`, {
        
        offset: offset
      });
     
console.log(response.data.tagsAndAuthors,'response');
      if (response.status === 200) {
        let data = [];
        let relatedAuthor = response.data.data.map((data)=>{
        return data.Author.firstName
        })


        const RelatedTags=response.data.tagsAndAuthors.map((tag)=>{
          return tag.name
        })
        // console.log(RelatedTags,'RelatedTagssssssss');
        // console.log(relatedAuthor,'relatedAuthor');
        let relatedConcept = response.data.relatedConcepts.dictionaryConcepts;

        if (offset === 0) {
          data = [...response.data.data];
        } else {
          data = [...state.navbar.perspectives, ...response.data.data];
        }
       
        dispatch({
          type: navbarConstants.CONCEPTS,
          payload: relatedConcept

        })
        dispatch({
          type: navbarConstants.VIRAL_KEYWORDS,
          payload: RelatedTags
        });
        dispatch({
          type: navbarConstants.RELATED_AUTHOR,
          payload:relatedAuthor

        })
        dispatch({
          type: constants.SET_OFFSET,
          payload: offset + 10
        });
        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: data
        });
        dispatch({
          type: navbarConstants.KEYWORDS,
          payload: data.perspectiveKeywords
        });
      }
    } catch (e) {}
  };
}

export function getRandomProverbs() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const offset = state.memes.offset;

      const response = await axios.get(`${BASE_URL}/randomProverbs`);
      if (response.status === 200) {
        let data = [];

        if (offset === 0) {
          data = [...response.data.data];
        } else {
          data = [...state.navbar.perspectives, ...response.data.data];
        }
        dispatch({
          type: constants.SET_OFFSET,
          payload: offset + 10
        });
        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: data
        });
        dispatch({
          type: navbarConstants.KEYWORDS,
          payload: data.perspectiveKeywords
        });
      }
    } catch (e) {}
  };
}

export function fetchAllMemes(memePage = true) {
  return async (dispatch, getState) => {
    const state = getState();
    let condition = getPageMemesCondition(state.navbar.selectedOption, state.navbar.authors);
    if (condition && Object.entries(condition).length !== 0) {
      dispatch({
        type: constants.MEMES_REQUEST,
        payload: true
      });
      const offset = state.memes.offset;
      const limit = state.memes.limit;
      const oldMemes = state.memes.memes;
      condition.limit = limit;
      condition.offset = offset;
      const response = await axios.post(`${BASE_URL}/memes/all`, condition);

      if (response.status === 200) {
        let data = [];
        data = response.data.data;

        dispatch({
          type: constants.SAVE_MEMES,
          payload: {
            memes: memePage ? [...oldMemes, ...data] : data,
            fetchLoading: false
          }
        });
        dispatch({
          type: constants.SET_OFFSET,
          payload: offset + 10
        });
        dispatch({
          type: constants.MEME_PAGE_MEMES,
          payload: {
            memePageMemes: [...oldMemes, ...data]
          }
        });
      }
    }
  };
}
export function fetchAllMemePagination() {
  return async (dispatch, getState) => {
    const state = getState();
    let condition = getPageMemesCondition(state.navbar.selectedOption, state.navbar.authors);
    if (condition && Object.entries(condition).length !== 0) {
      dispatch({
        type: constants.MEMES_REQUEST,
        payload: true
      });
      const offset = state.memes.offset;
      const limit = state.memes.limit;
      const oldMemes = state.memes.memes ? state.memes.memes : [];
      condition.limit = limit;
      condition.offset = offset;
      const response = await axios.post(`${BASE_URL}/memes/pagination`, condition);

      if (response.status === 200) {
        let data = [];
        data = response.data.data;
        dispatch({
          type: constants.SAVE_MEMES,
          payload: {
            memes: [...oldMemes, ...data],
            fetchLoading: false
          }
        });
        dispatch({
          type: constants.SET_OFFSET,
          payload: offset + 10
        });
        dispatch({
          type: constants.MEME_PAGE_MEMES,
          payload: {
            memePageMemes: [...oldMemes, ...data]
          }
        });
      }
    }
  };
}

export function uploadMemes(formData) {
  //console.log("formData ------------- ", formData);
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.MEMES_UPLOADING
      });
      //const image = images[i];
      const state = getState();
      const user = state.auth.user;
      let memes = state.memes.memes;
      //const concept = state.navbar.selectedOption;
      //formData.delete("file");
      //formData.append("file", images[i]);
      const response = await axios.post(`${BASE_URL}/memes/upload`, formData, {
        headers: { Authorization: user.token }
      });
      let newMeme = response.data.memes;
      // newMeme.Concepts = [concept];
      // dispatch({
      //   type: constants.SAVE_MEMES,
      //   payload: {
      //     memes: [newMeme, ...memes]
      //   }
      // });
      dispatch({
        type: constants.MEME_PAGE_MEMES,
        payload: {
          memePageMemes: [newMeme, ...memes]
        }
      });

      dispatch({
        type: constants.MEMES_SUCCESS
      });
    } catch (e) {
      console.log("error is", e);
      dispatch({
        type: constants.MEMES_FAILED
      });
    }
  };
}

export function uploadMemesBulk(formData, images) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.MEMES_UPLOADING
      });
      let state = getState();
      for (let i = 0; i < images.length; i++) {
        state = getState();
        let memes = state.memes.memes;
        const image = images[i];
        const state = getState();
        const user = state.auth.user;
        const concept = state.navbar.selectedOption;
        formData.delete("file");
        formData.append("file", images[i]);
        const response = await axios.post(`${BASE_URL}/memes/uploadMemeBulk`, formData, {
          headers: { Authorization: user.token }
        });
        console.log("error i s");
        let newMeme = response.data.memes;
        newMeme.Concepts = [concept];
        dispatch({
          type: constants.SAVE_MEMES,
          payload: {
            memes: [newMeme, ...memes]
          }
        });
        dispatch({
          type: constants.MEME_PAGE_MEMES,
          payload: {
            memePageMemes: [newMeme, ...memes]
          }
        });
      }

      // images.map(async (image, indx) => {

      // })
      dispatch({
        type: constants.MEMES_SUCCESS
      });
    } catch (e) {
      console.log("error i s", e);
      dispatch({
        type: constants.MEMES_FAILED
      });
    }
  };
}

export function uploadTextMeme(formData) {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const user = state.auth.user;
      formData.userId = user.id;
      const response = await axios.post(`${BASE_URL}/memes/textupload`, formData, {
        headers: { Authorization: user.token }
      });

      if (response.status === "201") {
        toast.success(response.data.msg);
      } else {
        toast.error(response.data.msg);
      }

      dispatch({
        type: constants.MEMES_SUCCESS
      });
    } catch (e) {
      console.log("error i s", e);
      dispatch({
        type: constants.MEMES_FAILED
      });
    }
  };
}
export function getMemeRelatedConcepts(concept, keyword) {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const user = state.auth.user;
      // const concept = state.navbar.selectedOption;
      const response = await axios.post(`${BASE_URL}/concepts/with/memes/${concept}`, { keyword });
      dispatch({
        type: constants.MEMES_CONCEPTS,
        payload: response.data.data
      });
    } catch (e) {
      console.log("error i s", e);
    }
  };
}

export function getMemeRelatedBottomConcepts(concept, keyword) {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const user = state.auth.user;
      const response = await axios.post(`${BASE_URL}/concepts/with/memes/${concept}`, { keyword });

      dispatch({
        type: constants.MEMES_BOTTOM_CONCEPTS,
        payload: response.data.data
      });
    } catch (e) {
      console.log("error i s", e);
    }
  };
}

const blockPaginationOnMeme = (dispatch, value) => {
  dispatch({
    type: "PAGINATION_BLOCK",
    payload: value
  });
};

export function fetchMemetagMemes(condition) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const offset = state.memes.offset;
      const limit = state.memes.limit;

      condition.limit = limit;
      condition.offset = offset;
      blockPaginationOnMeme(dispatch, false);
      if (condition) {
        dispatch({
          type: constants.MEMES_REQUEST,
          payload: true
        });
        if (offset === 0 && condition.pageName === "All") {
          dispatch({
            type: constants.MEME_PAGE_MEMES,
            payload: {
              memePageMemes: []
            }
          });
          dispatch({
            type: constants.SAVE_MEMES,
            payload: {
              memes: [],
              fetchLoading: false
            }
          });
          dispatch({
            type: constants.CONCEPT_MEMES_REQUEST,
            payload: true
          });
        }
        const response = await axios.post(
          `${BASE_URL}/concepts/withKeyword/memes/${condition.concept}/${condition.keyword}`,
          condition
        );
        if (response.status === 200) {
          let data = [];
          if (offset === 0) {
            resetSecondCol();
            data = response.data.data;
            if (response.data.data.length < 10) {
              blockPaginationOnMeme(dispatch, true);
            }

            // if (data.length < limit) {
            //   console.log("response.data.data", response.data.data.length);
            //   blockPaginationOnMeme(dispatch, true);
            // }
            dispatch({
              type: "SET_SELECTED_OPTION",
              payload: response.data.selectedOption
            });
            dispatch({
              type: constants.CONCEPT_MEMES_REQUEST,
              payload: false
            });
          } else {
            if (response.data.data.length === 0) {
              blockPaginationOnMeme(dispatch, true);
            }
            data = [...state.memes.memes, ...response.data.data];
          }

          dispatch({
            type: constants.SAVE_MEMES,
            payload: {
              memes: data,
              fetchLoading: false
            }
          });
          dispatch({
            type: constants.SET_OFFSET,
            payload: offset + 10
          });
          dispatch({
            type: constants.MEME_PAGE_MEMES,
            payload: {
              memePageMemes: data
            }
          });
          dispatch({
            type: constants.SAVE_MEMES_WITH_TAGS,
            payload: data
          });
          {
            if (condition.pageName !== "All") {
              condition.urlChange &&
                dispatch({
                  type: navbarConstants.VIRAL_KEYWORDS,
                  payload: response.data.concepts
                });
            } else {
              condition.keyword === "undefined" &&
                dispatch({
                  type: navbarConstants.VIRAL_KEYWORDS,
                  payload: response.data.concepts
                });
            }
          }
          if (condition.pageName === "wackywares") {
            dispatch({
              type: navbarConstants.VIRAL_KEYWORDS,
              payload: response.data.concepts
            });
          }
          dispatch({
            type: constants.SET_MEMES_COUNT,
            payload: response.data.memesCount
          });
          dispatch({
            type: navbarConstants.SELECTED_CONCEPT_ID,
            payload: -1
          });
        }
      }
    } catch (e) {
      dispatch({
        type: constants.MEMES_REQUEST,
        payload: false
      });
    }
  };
}

// export function getMemeKeywordConcepts(condition) {
//   return async (dispatch, getState) => {
//     try {
//       const state = getState();
//       const offset = state.memes.offset;
//       const limit = state.memes.limit;
//       condition.limit = limit;
//       condition.offset = offset;
//       if (offset === 0) {
//         dispatch({
//           type: constants.KEYWORD_MEMES_REQUEST,
//           payload: true
//         });
//       }
//       if (condition) {
//         dispatch({
//           type: constants.MEMES_REQUEST,
//           payload: true
//         });
//         const response = await axios.post(
//           `${BASE_URL}/concepts/withKeyword/memes/${condition.concept}/${condition.keyword}`,
//           condition
//         );
//         if (response.status === 200) {
//           let data = [];
//           if (offset === 0) {
//             resetSecondCol();
//             data = response.data.data;

//             dispatch({
//               type: "SET_SELECTED_OPTION",
//               payload: response.data.selectedOption
//             });
//             dispatch({
//               type: constants.KEYWORD_MEMES_REQUEST,
//               payload: false
//             });
//           } else {
//             data = [...state.memes.memes, ...response.data.data];
//           }

//           dispatch({
//             type: constants.SAVE_MEMES,
//             payload: {
//               memes: data,
//               fetchLoading: false
//             }
//           });
//           dispatch({
//             type: constants.SET_OFFSET,
//             payload: offset + 10
//           });

//           dispatch({
//             type: constants.MEME_PAGE_MEMES,
//             payload: {
//               memePageMemes: data
//             }
//           });

//           dispatch({
//             type: navbarConstants.CONCEPTS,
//             payload: removeDuplicate(response.data.concepts)
//           });
//           dispatch({
//             type: constants.SET_MEMES_COUNT,
//             payload: response.data.memesCount
//           });
//         }
//       }
//     } catch (e) {
//       dispatch({
//         type: constants.MEMES_REQUEST,
//         payload: false
//       });
//     }
//   };
// }

export function updateImageMeme(updateData) {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const user = state.auth.user;

      const response = await axios.post(`${BASE_URL}/memes/imageMemeUpdate`, updateData, {
        headers: { Authorization: user.token }
      });

      if (response.status === 200) {
        let { memePageMemes } = state.memes;
        let memeId = updateData.memeId;
        let currentMeme = memePageMemes.find(item => item.id === memeId);
        currentMeme.description = updateData.description;
        currentMeme.citation = updateData.citation;
        currentMeme.url = updateData.url;
        let memeIndex = memePageMemes.findIndex(meme => meme.id === memeId);
        memePageMemes[memeIndex] = currentMeme;

        dispatch({
          type: constants.SAVE_MEMES,
          payload: {
            memes: memePageMemes
          }
        });
        dispatch({
          type: constants.MEME_PAGE_MEMES,
          payload: {
            memePageMemes: memePageMemes
          }
        });
      }
      dispatch({
        type: constants.MEMES_SUCCESS
      });
    } catch (e) {
      console.log("error i s", e);
      dispatch({
        type: constants.MEMES_FAILED
      });
    }
  };
}

export function updateTextMeme(formData) {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      const user = state.auth.user;
      formData.userId = user.id;
      const response = await axios.post(`${BASE_URL}/memes/textupdate`, formData, {
        headers: { Authorization: user.token }
      });

      if (response.status === 200) {
        let { memePageMemes } = state.memes;
        let memeId = formData.memeId;
        let currentMeme = memePageMemes.find(item => item.id === memeId);
        currentMeme.firstLine = formData.firstLine;
        currentMeme.secondLine = formData.secondLine;
        currentMeme.Concepts = formData.tags;
        let memeIndex = memePageMemes.findIndex(meme => meme.id === memeId);
        memePageMemes[memeIndex] = currentMeme;

        dispatch({
          type: constants.SAVE_MEMES,
          payload: {
            memes: memePageMemes
          }
        });
        dispatch({
          type: constants.MEME_PAGE_MEMES,
          payload: {
            memePageMemes: memePageMemes
          }
        });
      }
      dispatch({
        type: constants.MEMES_SUCCESS
      });
    } catch (e) {
      console.log("error i s", e);
      dispatch({
        type: constants.MEMES_FAILED
      });
    }
  };
}

export function likeMeme(id) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let memes = state.memes.memePageMemes;
      const user = state.auth.loggedInUserAccount_admin;
      const index = memes.findIndex(meme => meme.id === parseInt(id));
      if (index > -1) {
        let meme = memes[index];
        if (meme) {
          if (meme.isLike) {
            meme.isLike = false;
            meme.loves -= 1;
            memes[index] = meme;
          } else {
            meme.isLike = true;
            meme.loves += 1;
            memes[index] = meme;
          }
          const payload = {
            id: meme.id,
            data: {
              loves: meme.loves
            }
          };
          dispatch({
            type: constants.SAVE_MEMES,
            payload: {
              memes: JSON.parse(JSON.stringify(memes))
            }
          });
          dispatch({
            type: constants.MEME_PAGE_MEMES,
            payload: {
              memePageMemes: JSON.parse(JSON.stringify(memes))
            }
          });
          const response = await axios.put(`${BASE_URL}/memes`, payload);
          if (response.status === 204) {
          }
        }
      }
    } catch (e) {
      console.log("errror is ", e);
    }
  };
}

export function deleteMeme(memesId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.auth;
      const response = await axios.delete(`${BASE_URL}/admin/memes/${memesId}`, {
        headers: { Authorization: user.token }
      });
      if (response.status === 200) {
        let { memes } = state.memes;
        memes = memes.filter(meme => meme.id !== memesId);

        dispatch({
          type: constants.MEME_PAGE_MEMES,
          payload: {
            memePageMemes: [...memes]
          }
        });
      }
    } catch (e) {
      console.log("errror is ", e);
    }
  };
}

export function removeConceptFromMeme(memesId, concept) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.auth;
      const { memes } = state.memes;
      const response = await axios.delete(
        `${BASE_URL}/admin/memes/related/concept/${memesId}/${concept.id}`,
        { headers: { Authorization: user.token } }
      );
      if (response.status === 200) {
        const index = memes.findIndex(meme => meme.id === parseInt(memesId));
        if (index > -1) {
          let meme = memes[index];
          if (meme) {
            if (meme.Concepts) {
              meme.Concepts = meme.Concepts.filter(myConcept => myConcept.id !== concept.id);
            }

            memes[index] = meme;
          }
        }
      }
    } catch (e) {
      console.log("errror is ", e);
    }
  };
}

export function addConceptToMeme(memesId, concept) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { user } = state.auth;
      const { memes } = state.memes;
      const response = await axios.post(
        `${BASE_URL}/admin/memes/related/concept/${memesId}/${concept.id}`,
        {},
        { headers: { authorization: user.token } }
      );
      if (response.status === 200) {
        const index = memes.findIndex(meme => meme.id === parseInt(memesId));
        if (index > -1) {
          let meme = memes[index];
          if (meme) {
            meme.isLike = true;
            concept.name = concept.value;
            if (meme.Concepts) {
              meme.Concepts.push(concept);
            } else {
              meme.Concepts = [concept];
            }
            memes[index] = meme;

            if (response.status === 204) {
              //console.log("memes updated ");
            }
          }
        }
      }
    } catch (e) {
      console.log("errror is ", e);
    }
  };
}
