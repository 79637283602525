import React, { useState, useEffect } from "react";
import history from "../../../router/history";
import DefinitionPage from "./DefinitionPage";
import ViralsPage from "./ViralsPage";

const FirstColBtn = props => {
  const concept = window.location.pathname.split("/")[2];
  const page = window.location.pathname.split("/")[1].toLowerCase();
  const { lock } = props;
  const [selectedBtn, setSelectedBtn] = useState("");

  const handleClickDefinition = () => {
    history.push(`/dictionary/${concept}`);
  };

  const handleClickVirals = () => {
    history.push(`/memes/${concept}`);
  };

  const handleClickPos = () => {
    history.push(`/pos/${concept}?n`);
  };

  return (
    <>
      {/* <div class="btn-group bg-white">
        <button
          type="button"
          class={`btn btn-outline text-dark btn-sm`}
          onClick={handleClickDefinition}
        >
          <span className={selectedBtn === "definition" ? "font-weight-bold" : " "}>
            definition
          </span>
        </button>
        <button type="button" class="btn btn-outline text-dark btn-sm" onClick={handleClickVirals}>
          <span className={selectedBtn === "virals" ? "font-weight-bold" : " "}>virals</span>
        </button>
        <button type="button" class="btn btn-outline text-dark btn-sm" onClick={handleClickPos}>
          <span className={selectedBtn === "pos" ? "font-weight-bold" : " "}>
            adj/
            <br />
            verbs
          </span>
        </button>
      </div> */}
      <div>{<DefinitionPage lock={lock} />}</div>
    </>
  );
};

export default FirstColBtn;
