import history from "../../router/history";

const pageName = window.location.href.split("/")[3];
const item = window.location.href.split("/")[4];
const itemName = item ? item.split("?")[0] : itemName;
const keywordName = window.location.href.split("=")[1];

export const getBgColor = ( tab , thirdKey) => {
  switch (tab) {
    case "default":
      return "white";
    case "dictionary":
      return "#d1cbcb";
    case "virals":
      return "#c3e8e2";
    case "books":
      return "#b5c4e6";
    case "adjVerbs":
      return "#ffe3f1";
    case "one-liner":
      return thirdKey ? "#ffc78f" : "#ffe3c8";
    case "trivia":
      return thirdKey ? "#a6df9b" : "#e2f8db";
    case "memes":
      return thirdKey ?  "#ffffad" : "#fffee4";
    case "dad-joke":
      return  thirdKey ? "#fcacd1"  : "#fdcee4";
    case "authors":
      return "#f8d3cf";
    default:
      if (!pageName || pageName === "Concepts") {
        return "white";
      } else if (pageName === "pos") {
        return "#ffe3f1";
      } else if (pageName === "definitions") {
        return "#d1cbcb";
      } else if (pageName === "proverbs") {
        return "#a2b5de";
      } else {
        return "white";
      }
  }
};

export const getPlaceHolder = () => {
  if (this.state.searchCategory === "Concepts") return "according to";
  else if (this.state.searchCategory == "Concept Clusters") {
    return "according to";
  } else if (this.state.searchCategory == "Author Clusters") {
    return "on the subject of";
  } else {
    return "";
  }
};

export const getContent = navBarData => {
  if (navBarData !== "adjVerbs") {
    switch (navBarData) {
      case "trivia":
      case "one-liner":
      case "dad-joke":
      case "virals":
        return navBarData;
      case "memes":
        return "meme";
      default:
        if (pageName === "memes" && !keywordName) {
          return "virals";
        } else if (pageName === "memes" && itemName === "meme" && keywordName) {
          return "meme";
        } else if (pageName === "memes" && itemName !== "meme" && keywordName) {
          return keywordName;
        }
        break;
    }
  }
  return null;
};

export const getSurpriseCategory = filter => {
  switch (filter) {
    case "default":
      return "concept";
    case "dictionary":
      return "dictionary";
    case "trivia":
      return "trivia";
    case "dad-joke":
      return "dad-joke";
    case "one-liner":
      return "one-liner";
    case "virals":
      return "viral";
    case "books":
      return "book";
    case "adjVerbs":
      return "adjVerbs";
    case "memes":
      return "meme";
    default:
      if (!pageName || pageName === "Concepts") {
        return "concept";
      } else if (pageName === "dictionary") {
        return "dictionary";
      } else if (pageName === "pos") {
        return "adjVerbs";
      } else if (pageName === "memes" && !keywordName) {
        return "viral";
      } else if (pageName === "memes" && keywordName === "meme") {
        return "meme";
      } else if (pageName === "memes" && keywordName === "trivia") {
        return "trivia";
      } else if (pageName === "memes" && keywordName === "one-liner") {
        return "one-liner";
      } else if (pageName === "memes" && keywordName === "dad-joke") {
        return "dad-joke";
      } else if (pageName === "proverbs") {
        return "proverb";
      } else {
        return "concept";
      }
      break;
  }
};

export const placeHolder = navBarData => {
  switch (navBarData) {
    case "default":
      return "search...";
    case "dictionary":
      return "search Definitions...";
    case "virals":
      return "search virals...";
    case "books":
      return "search books...";
    case "authors":
      return "search thinkers...";
    case "adjVerbs":
      return "search adj/verbs/nouns";
    case "one-liner":
      return "search one-liners...";
    case "trivia":
      return "search trivia...";
    case "memes":
      return "search memes...";
    case "dad-joke":
      return "search Dad Jokes...";
    default:
      if (!pageName) {
        return "search...";
      } else if (pageName === "dictionary") {
        return "search Definitions... ";
      } else if (pageName === "pos") {
        return "search adj/verbs/nouns";
      } else if (pageName === "memes" && !keywordName) {
        return "search virals...";
      } else if (pageName === "memes" && keywordName === "meme") {
        return "search memes...";
      } else if (pageName === "memes" && keywordName === "trivia") {
        return "search trivia...";
      } else if (pageName === "memes" && keywordName === "one-liner") {
        return "search one-liners...";
      } else if (pageName === "memes" && keywordName === "dad-joke") {
        return "search Dad Jokes...";
      }
      break;
  }
};

export const getAllUnderline = navBarData => {
  if (navBarData === "default") {
    return "underline";
  } else if (navBarData === "all" && (pageName === "Concepts" || !pageName)) {
    return "underline";
  }
};

export const getDictionaryUnderline = navBarData => {
  if (navBarData === "dictionary") {
    return "underline";
  } else if (navBarData === "all" && pageName === "dictionary") {
    return "underline";
  }
};

export const getDictionaryColor = navBarData => {
  if (navBarData === "dictionary") {
    return "#5f5f5e";
  } else if (navBarData === "all" && pageName === "dictionary") {
    return "#5f5f5e";
  }
};

export function isTagExist(subNavTabs, name, memes) {
  if (pageName === "pos") {
    let res = [];
    const memesConcepts = memes && memes.map(e => e.Concepts);
    memesConcepts && memesConcepts.forEach(e => e.forEach(e => res.push(e)));
    const result = res.map(e => e.name);

    if (name === "virals") {
      return memes.length > 0 ? true : false;
    } else return result.includes(name);
  } else {
    if (name === "virals") {
      return (subNavTabs &&
        subNavTabs.some(
          e => e.slug === "one-liner" || e.slug === "trivia" || e.slug === "dad-joke"
        )) ||
        (memes && memes.some(e => e.url !== null))
        ? true
        : false;
    } else if (name === "more") {
      if (subNavTabs.length > 0) {
        return true;
      } else {
        return false;
      }
    } else return subNavTabs.some(e => e.slug == name);
  }
}

export const getBoldHeading = (name, state) => {
  switch (name) {
    case "all":
      if (state === "default") {
        return "bold";
      } else if (
        state === "all" &&
        (!pageName || pageName === "Concepts" || pageName === "unverifiedconcepts")
      ) {
        return "bold";
      }
      break;
    case "dictionary":
      if (state === "dictionary") {
        return "bold";
      } else if (state === "all" && pageName === "dictionary") {
        return "bold";
      }
      break;
    case "virals":
      if (state === "virals") {
        return "bold";
      } else if (state === "all" && pageName === "memes" && itemName && !keywordName) {
        return "bold";
      }
      break;
    case "books":
      if (state === "books") {
        return "bold";
      } else if (state === "all" && (pageName === "books" || pageName === "book")) {
        return "bold";
      }
      break;
    case "adjVerbs":
      if (state === "adjVerbs") {
        return "bold";
      } else if (state === "all" && pageName === "pos") {
        return "bold";
      }
      break;
    case "one-liner":
      if (state === "one-liner") {
        return "bold";
      } else if (state === "all" && pageName === "memes" && keywordName === "one-liner") {
        return "bold";
      }
      break;
    case "trivia":
      if (state === "trivia") {
        return "bold";
      } else if (state === "all" && pageName === "memes" && keywordName === "trivia") {
        return "bold";
      }
      break;
    case "memes":
      if (state === "memes") {
        return "bold";
      } else if (state === "all" && pageName === "memes" && keywordName === "meme") {
        return "bold";
      }
      break;
    case "dad-joke":
      if (state === "dad-joke") {
        return "bold";
      } else if (state === "all" && pageName === "memes" && keywordName === "dad-joke") {
        return "bold";
      }
      break;
    default:
      break;
  }
};

export const getCategory = (navBarData, searchCategoryFilter) => {
  if (searchCategoryFilter == "All") {
    switch (navBarData) {
      case "dictionary":
        return "All";
      case "adjVerbs":
        return "pos";
      case "virals":
      case "one-liner":
      case "trivia":
      case "memes":
      case "dad-joke":
        return "Memetags";
      case "all":
        if (!pageName || pageName === "Concepts" || pageName === "dictionary") {
          return "All";
        } else if (pageName === "pos") {
          return "pos";
        } else if (pageName === "memes") {
          return "Memetags";
        }
      default:
        return "All";
    }
  } else {
    switch (searchCategoryFilter) {
      case "Concept":
        return "Concept";
      case "Concept Clusters":
        return "Concept Clusters";
      case "Author":
        return "Author";
      case "Author Groups":
        return "Author Groups";
      default:
        return "All";
    }
  }
};

export const getSubHeading = navBarData => {
  switch (navBarData) {
    case "default":
      return "";
    case "dictionary":
      return "Definitions";
    case "virals":
      return "Virals";
    case "books":
      return "Books";
    case "adjVerbs":
      return "Adj/Verbs";
    case "one-liner":
      return "One-Liners";
    case "trivia":
      return "Trivia";
    case "memes":
      return "Memes";
    case "dad-joke":
      return "Dad-Jokes";

    default:
      if (!pageName || pageName === "Concepts") {
        return "";
      } else if (pageName === "dictionary") {
        return "Definitions";
      } else if (pageName === "pos") {
        return "Adj/Verbs";
      } else if (pageName === "memes" && !keywordName) {
        return "Virals";
      } else if (pageName === "memes" && keywordName === "meme") {
        return "Memes";
      } else if (pageName === "memes" && keywordName === "trivia") {
        return "Trivia";
      } else if (pageName === "memes" && keywordName === "one-liner") {
        return "One-Liner";
      } else if (pageName === "memes" && keywordName === "dad-joke") {
        return "Dad-Joke";
      }
      break;
  }
};

export const categoryFilter = (item, filter) => {
  if (filter == "Concept") {
    history.push(`/dictionary/${item.slug}`);
  } else if (filter == "Concept Clusters") {
    history.push(`/dictionary/${item.slug}`);
  } else if (filter == "Author") {
    history.push(`/Authors/${item.slug}`);
  } else if (filter == "Author Groups") {
    history.push(`/Author-Groups/${item.slug}`);
  }
};

export const getViralColor = navBarData => {
  if (navBarData !== "all") {
    switch (navBarData) {
      case "virals":
        return "#067e75";
      case "memes":
        return "#d2bf30";
      case "trivia":
        return "#037101";
      case "one-liner":
        return "#ff9301";
      case "dad-joke":
        return "#ff60e1";
      default:
        return "black";
    }
  } else if (pageName === "memes" && !keywordName) {
    return "#067e75";
  } else if (pageName === "memes" && keywordName === "meme") {
    return "#d2bf30";
  } else if (pageName === "memes" && keywordName === "trivia") {
    return "#037101";
  } else if (pageName === "memes" && keywordName === "one-liner") {
    return "#ff9301";
  } else if (pageName === "memes" && keywordName === "dad-joke") {
    return "#ff60e1";
  }
};

export const getBooksUnderline = navBarData => {
  if (navBarData === "books") {
    return "underline";
  } else if (navBarData === "all") {
    if (pageName === "books" || pageName === "book") {
      return "underline";
    }
  }
};

export const getBooksColor = navBarData => {
  if (navBarData === "books") {
    return "#0076b9";
  } else if (navBarData === "all") {
    if (pageName === "books" || pageName === "book") {
      return "#0076b9";
    }
  }
};

export const getViralTab = (viralState, navBarData) => {
  if (navBarData !== "all") {
    return viralState === "VIRALS" ? viralState : `[${viralState}]`;
  } else if (pageName === "memes" && !keywordName) {
    return "VIRALS";
  } else if (pageName === "memes" && keywordName === "meme") {
    return "[MEMES]";
  } else if (pageName === "memes" && keywordName === "trivia") {
    return "[TRIVIA]";
  } else if (pageName === "memes" && keywordName === "one-liner") {
    return "[ONE-LINERS]";
  } else if (pageName === "memes" && keywordName === "dad-joke") {
    return "[DAD JOKES]";
  } else {
    return "VIRALS";
  }
};

export const getSearchbarColor = filter => {
  switch (filter) {
    case "default":
      return "transparent";
    case "dictionary":
      return "transparent";
    case "trivia":
      return "green";
    case "dad-joke":
      return "#ff6680";
    case "one-liner":
      return "orange";
    case "virals":
      return "blue";
    case "books":
      return "transparent";
    case "adjVerbs":
      return "transparent";
    case "memes":
      return "#807500";
    default:
      if (!pageName || pageName === "Concepts") {
        return "transparent";
      } else if (pageName === "dictionary") {
        return "transparent";
      } else if (pageName === "pos") {
        return "transparent";
      } else if (pageName === "memes" && !keywordName) {
        return "transparent";
      } else if (pageName === "memes" && keywordName === "meme") {
        return "#807500";
      } else if (pageName === "memes" && keywordName === "trivia") {
        return "green";
      } else if (pageName === "memes" && keywordName === "one-liner") {
        return "orange";
      } else if (pageName === "memes" && keywordName === "dad-joke") {
        return "#ff6680";
      } else {
        return "transparent";
      }
      break;
  }
};

export const getDiceBorder = filter => {
  switch (filter) {
    case "default":
      return "black";
    case "dictionary":
      return "black";
    case "trivia":
      return "green";
    case "dad-joke":
      return "#ff6680";
    case "one-liner":
      return "orange";
    case "virals":
      return "blue";
    case "books":
      return "black";
    case "adjVerbs":
      return "black";
    case "memes":
      return "#807500";
    default:
      if (!pageName || pageName === "Concepts") {
        return "black";
      } else if (pageName === "dictionary") {
        return "black";
      } else if (pageName === "pos") {
        return "black";
      } else if (pageName === "memes" && !keywordName) {
        return "black";
      } else if (pageName === "memes" && keywordName === "meme") {
        return "#807500";
      } else if (pageName === "memes" && keywordName === "trivia") {
        return "green";
      } else if (pageName === "memes" && keywordName === "one-liner") {
        return "orange";
      } else if (pageName === "memes" && keywordName === "dad-joke") {
        return "#ff6680";
      } else if (pageName === "proverbs") {
        return "#486bb5";
      } else {
        return "black";
      }
      break;
  }
};

export const getViralUnderline = navBarData => {
  switch (navBarData) {
    case "virals":
    case "memes":
    case "trivia":
    case "one-liner":
    case "dad-joke":
      return "underline";
    default:
      if (navBarData === "all" && pageName === "memes") {
        return "underline";
      }
  }
};

export const isSearchCategory = state => {
  if (state === "default" || state === "dictionary") {
    return true;
  } else if (state === "all") {
    if (
      !pageName ||
      pageName === "Concepts" ||
      pageName === "dictionary" ||
      pageName === "Author-Groups" ||
      pageName === "Authors"
    )
      return true;
  } else {
    return false;
  }
};

const mainSubPage = item => {
  if (
    item.value === "trivia" ||
    item.value === "dad joke" ||
    item.value === "one-liner" ||
    item.value === "meme" ||
    item.value === "definition" ||
    item.value === "books" ||
    item.value === "Proverbs"
  ) {
    return true;
  } else {
    return false;
  }
};

export const topFilter = (item, filter) => {
  if (item.category == "Memetag") {
    if (mainSubPage(item)) {
      switch (item.value) {
        case "books":
          history.push("/books");
          break;
        case "Proverbs":
          history.push("/proverbs");
          break;
        case "definition":
          history.push("/definitions");
          break;
        case "trivia":
          history.push("/trivia");
          break;
        case "dad joke":
          history.push("/dad-joke");
          break;
        case "one-liner":
          history.push("/one-liner");
          break;
        case "meme":
          history.push("/meme");
          break;

        default:
          break;
      }
      return;
    }
    history.push(`/QL/${item.value.split(" ").join("-")}`);
    return;
  }
  switch (filter) {
    case "default":
      history.push(`/${item.category}/${item.slug ? item.slug : item.value}`);
      break;
    case "dictionary":
      history.push(
        `/${item.category === "Concepts" ? "dictionary" : "Authors"}/${
          item.slug ? item.slug : item.value
        }`
      );
      break;
    case "virals":
      history.push(`/memes/${item.slug}`);
      break;
    case "memes":
      history.push(`/memes/${item.slug ? item.slug : item.value}?keyword=meme`);
      break;
    case "trivia":
    case "one-liner":
    case "dad-joke":
      history.push(`/memes/${item.slug}?keyword=${filter}`);
      break;
    case "adjVerbs":
      posUrl(item);
      break;
    case "all":
      if (!pageName || pageName === "Concepts") {
        history.push(`/${item.category}/${item.slug ? item.slug : item.value}`);
      } else if (pageName === "dictionary") {
        history.push(
          `/${item.category === "Concepts" ? "dictionary" : "Authors"}/${
            item.slug ? item.slug : item.value
          }`
        );
      } else if (pageName === "memes" && !keywordName) {
        history.push(`/memes/${item.slug ? item.slug : item.name}`);
      } else if (pageName === "memes" && keywordName && itemName !== "meme") {
        history.push(`/memes/${item.slug ? item.slug : item.name}?keyword=${keywordName}`);
      } else if (pageName === "memes" && itemName === "meme") {
        history.push(`/memes/meme?keyword=${item.slug ? item.slug : item.name}`);
      } else if (pageName === "wackywares") {
        history.push(`/${item.category}/${item.slug ? item.slug : item.value}`);
      } else if (pageName === "pos") {
        posUrl(item);
      } else if (pageName === "Authors") {
        history.push(`/${item.category}/${item.slug ? item.slug : item.value}`);
      } else {
        history.push(`/${item.category}/${item.slug ? item.slug : item.value}`);
      }
      break;
    default:
      history.push(`/Concepts/${item.slug ? item.slug : item.value}`);
      break;
  }
};

export const subHeadingClick = navBarData => {
  switch (navBarData) {
    case "default":
    case "dictionary":
      history.push(`/concepts`);
      break;
    case "one-liner":
      history.push(`/one-liner`);
      break;
    case "adjVerbs":
      history.push(`/descriptions`);
      break;
    case "dad-joke":
      history.push(`/dad-joke`);
      break;
    case "trivia":
    case "virals":
    case "books":
    case "memes":
      history.push(`/${navBarData}`);
      break;

    default:
      if (!pageName || pageName === "Concepts") {
        history.push(`/concepts`);
      } else if (pageName === "dictionary") {
        history.push(`/concepts`);
      } else if (pageName === "pos") {
        history.push(`/descriptions`);
      } else if (pageName === "memes" && !keywordName) {
        history.push(`/virals`);
      } else if (pageName === "memes" && keywordName === "meme") {
        history.push(`/memes`);
      } else if (pageName === "memes" && keywordName === "trivia") {
        history.push(`/trivia`);
      } else if (pageName === "memes" && keywordName === "one-liner") {
        history.push(`/one-liner`);
      } else if (pageName === "memes" && keywordName === "dad-joke") {
        history.push(`/dad-joke`);
      }
      break;
  }
};

export const posUrl = item => {
  let contentType = window.location.href.split("=")[1];

  switch (contentType) {
    case "collocations":
    case "slangs":
    case "ljss":
    case "similes":
    case "all":
      break;
    default:
      contentType = null;
      break;
  }

  const route =
    contentType &&
    contentType !== "collocations" &&
    contentType !== "slangs" &&
    contentType !== "ljss"
      ? `/pos/${item.term}?${item.grammar}=${contentType}`
      : contentType && contentType == "ljss" && item.grammar !== "n"
      ? `/pos/${item.term}?${item.grammar}=ljss`
      : contentType && contentType == "ljss" && item.grammar == "n"
      ? `/pos/${item.term}?${item.grammar}=similes`
      : contentType && contentType == "collocations" && item.grammar == "n"
      ? `/pos/${item.term}?${item.grammar}=slangs`
      : contentType && contentType == "collocations" && item.grammar !== "n"
      ? `/pos/${item.term}?${item.grammar}=collocations`
      : contentType && contentType == "slangs" && item.grammar !== "n"
      ? `/pos/${item.term}?${item.grammar}=collocations`
      : contentType && contentType == "slangs" && item.grammar == "n"
      ? `/pos/${item.term}?${item.grammar}=slangs`
      : contentType && contentType == "all"
      ? `/pos/${item.term}?${item.grammar}=all`
      : `/pos/${item.term}?${item.grammar}`;
  history.push(route);
};
