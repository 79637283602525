import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getAllArchives } from "../../actions/archives";
import history from "../../router/history";
import NavFirstRow from "./NavFirstRow";
import NavSecondRow from "./NavSecondRow";
import NavThirdRow from "./NavThirdRow";

import * as navbarActions from "../../actions/navbaractions";
import * as memesActions from "../../actions/memes";
import * as conceptsActions from "../../actions/conceptsActions";
import * as scroll from "../../helpers/scrolling";

import $ from "jquery";

import "./TopNavBar.css";
import "./ReactSelect.css";
import "bootstrap";

import { setSelectedCategory } from "../../actions/navbaractions";

const TopNavBar = props => {
  const { memeAllTags, selectedOption } = props;
  const selectedName = selectedOption.value;

  const [homepage, setHomepage] = useState(false);
  const [searchCategory, setSearchCategory] = useState(props.navbar.searchCategory);
  const [navBarData, setNavBarData] = useState("all");
  const [isSearchEnable, setIsSearchEnable] = useState(false);
  const [isToolTip, setIsToolTip] = useState(false);
  const [clickSearch, setClickSearch] = useState(false);

  let conceptName = window.location.pathname.split("/")[2];
  conceptName = conceptName && conceptName.split("-").join(" ");
  const pageName = window.location.pathname.split("/")[1];
  let grammar = window.location.href.split("?")[1];
  grammar = grammar ? grammar.split("=")[0] : grammar;
  const memeKey = window.location.href.split("=")[1];

  useEffect(() => {
    if (pageName === "" || pageName === "Concepts" || pageName === "concepts") {
      setNavBarData("all");
    } else if (pageName === "dictionary" || pageName === "definitions") {
      setNavBarData("dictionary");
    } else if (pageName === "memes" && !memeKey) {
      setNavBarData("virals");
    } else if (pageName === "memes" && memeKey === "meme") {
      setNavBarData("memes");
    } else if (pageName === "memes" && memeKey === "trivia") {
      setNavBarData("trivia");
    } else if (pageName === "memes" && memeKey === "one-liner") {
      setNavBarData("one-liner");
    } else if (pageName === "memes" && memeKey === "dad-joke") {
      setNavBarData("dad-joke");
    } else if (pageName === "meme") {
      setNavBarData("memes");
    } else if (pageName === "trivia") {
      setNavBarData("trivia");
    } else if (pageName === "one-liner") {
      setNavBarData("one-liner");
    } else if (pageName === "dad-joke") {
      setNavBarData("dad-joke");
    } else if (pageName === "virals") {
      setNavBarData("virals");
    } else if (pageName === "books" || pageName === "book") {
      setNavBarData("books");
    } else if (pageName === "Authors" || pageName === "authors") {
      setNavBarData("authors");
    } else {
      setNavBarData("all");
    }
    props.isAuthor(conceptName);
  }, [window.location.href]);

  const isThirdNavRow = () => {
    return pageName === "dictionary" ||
      pageName === "pos" ||
      pageName === "Concept-Clusters" ||
      pageName === "memes" ||
      pageName === "Concepts" ||
      pageName === "Authors"
      ? true
      : false;
  };

  const getTopNavHeight = () => {
    return isThirdNavRow() ? "80px" : "64px";
  };

  useEffect(() => {
    if (pageName !== "") {
      props.updateThirdRow(selectedName);
    }
  }, [selectedOption]);

  useEffect(() => {
    props.getTextMemeColors();
    setIsSearchEnable(false);
    if (
      pageName === "" ||
      pageName === "trivia" ||
      pageName === "one-liner" ||
      pageName === "meme" ||
      pageName === "dad-joke" ||
      pageName === "virals" ||
      pageName === "books"
    ) {
      setIsSearchEnable(true);
    }
  }, [window.location.href]);

  useEffect(() => {
    props.getTextMemeColors();
    if (
      pageName === "" ||
      pageName === "trivia" ||
      pageName === "one-liner" ||
      pageName === "meme" ||
      pageName === "dad-joke" ||
      pageName === "books"
    ) {
      setIsSearchEnable(true);
    }
    props.isAuthor(conceptName);
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/") {
      setHomepage(true);
    } else {
      props.setPrimaryMode(false);
    }
    if (props.auth.isAuthenticated) {
      props.getAllArchives(props.auth.user.id);
    }
    window.addEventListener("resize", handleResize);
  }, [props.auth.isAuthenticated]);

  history.listen((location, action) => {
    if (location.pathname !== "/" && homepage) {
      setHomepage(false);
    }
  });

  const handleResize = () => {
    if (window.innerWidth <= 970) {
      $("#thirdCol").css("opacity", "0");
      $("#firstCol").css("opacity", "0");
    } else {
      $("#thirdCol").css("opacity", "1");
      $("#firstCol").css("opacity", "1");
    }
  };

  return (
    /* MAIN TOPNAV DIV */

    <div
      className="mainTopNavbar"
      style={{
        height: getTopNavHeight()
      }}
    >
      {/* 2nd MAIN DIV */}

      <NavSecondRow
        navBarData={navBarData}
        isSearchEnable={isSearchEnable}
        setIsSearchEnable={setIsSearchEnable}
        searchCategory={searchCategory}
        setSearchCategory={setSearchCategory}
        clickSearch={clickSearch}
        setClickSearch={setClickSearch}
        relatedConcepts={props.relatedConcepts}
      />

      {/* THIRD NAV-BAR */}

      {(pageName === "dictionary" ||
        pageName === "Concepts" ||
        pageName === "memes" ||
        pageName === "Concept-Clusters" ||
        pageName === "Authors" ||
        (pageName === "pos" && grammar == "n")) && (
        <NavThirdRow
          navBarData={navBarData}
          setNavBarData={setNavBarData}
          isToolTip={isToolTip}
          setIsToolTip={setIsToolTip}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedCategory: bindActionCreators(setSelectedCategory, dispatch),
    setPrimaryMode: bindActionCreators(navbarActions.setPrimaryMode, dispatch),
    setMode: bindActionCreators(navbarActions.setMode, dispatch),
    getAllArchives: bindActionCreators(getAllArchives, dispatch),
    setOffset: bindActionCreators(memesActions.setOffset, dispatch),
    getTextMemeColors: bindActionCreators(memesActions.getAllTextMemesColors, dispatch),
    updateThirdRow: bindActionCreators(conceptsActions.updateThirdRow, dispatch),
    isAuthor: bindActionCreators(navbarActions.isAuthor, dispatch)
  };
};
const mapStateToProps = state => {
  return {
    navbar: state.navbar,
    memeAllTags: state.memes.textMemeColors,
    user: state.auth.user,
    auth: state.auth,
    selectedOption: state.navbar.selectedOption,
    relatedConcepts: state.concepts.concepts
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNavBar));

let style = {
  style: {
    width: "427px",
    border: "2px solid black",
    padding: 20,
    boxShadow: "5px 5px 3px rgba(0,0,0,.5)"
  },
  arrowStyle: {
    color: "rgba(0,0,0,.8)",
    borderColor: false
  }
};
