export const LIGHT_BLUE = '#5fb3e2'
export const DARK_BLUE = '#4287f5'
export const BABY_BLUE = '#CCE7F4'

export const DARK_GREY ='#ececec'

export const GOLD_BROWN = '#fcd785'

export const LIGHT_GREY = '#D5D5D5'
export const GREY_WHITE = '#EAEAEA'
export const MIDIUM_GREY ='#adadad'
export const GREY = 'grey'
export const HEADER_GREY = '#BABABA'
export const BLACK = 'black'
export const OPAQUE_BLACK = '#00000085'

export const MAIN_WHITE = 'white'

export const DARK_RED = '#CB2A7B'
export const RED = 'red'
export const LIGHT_RED = '#FFF2F0'

export const SKY_BLUE = '#45A1F8'
export const LIGHT_PINK = '#FEF6F5'
export const LIST_PINK = '#ff00ff'
export const OPACITY_LIGHT_PINK = '#FEE9FE'

export const LIGHT_GREEN = 'green'
export const OPACITY_LIGHT_GREEN = '#EFFCF0'
export const LIME = 'lime'

export const LIGHT_YELLOW = '#FDF6C6'

export const BOOK_TITLE = "#931920"
