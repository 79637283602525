import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col } from "reactstrap";
import * as navbarActions from "../../../actions/navbaractions";
import { DARK_GREY, LIGHT_BLUE } from "../../../constants/colors";
import { getRelatedConcepts } from "../../../selectors";
import { mapArrayToColumns, getConceptItem } from "../../../helpers/conceptHelpers";
import { getClusterItem } from "../../../helpers/cluster";
import { clusterCategory } from "../../../constants/global";
import history from "../../../router/history";

const RelatedConceptsList = props => {
  const { relatedConcepts, selectedOption, hideClusters, isQuickLinks } = props;
  const rows = Math.ceil(relatedConcepts.length / 3);
  const mustBe = rows * 3;
  const found = relatedConcepts.length;
  const diff = rows - (mustBe - found);
  const after = diff * 3;

  const conceptCallBack = concept => {
    // const item = getConceptItem(concept)
    // props.setSelectedItem(item)
    const pageName = window.location.href.split("/")[3];
    if (pageName === "Author-Groups") {
      history.push(`/dictionary/${concept.slug ? concept.slug : concept.value}`);
    } else {
      history.push(`/${pageName}/${concept.slug}`);
    }
  };

  const clusterCallBack = () => {
    let temp = { ...selectedOption, name: selectedOption.value };
    const item = getClusterItem(temp, clusterCategory);
    props.setSelectedItem(item);
  };

  return (
    <div style={styles.wrapper}>
      {!hideClusters && (
        <>
          <div className="row" style={styles.title}>
            <h4>Links</h4>
          </div>
          <div
            className="row"
            style={{ ...styles.title, ...styles.cluster }}
            onClick={clusterCallBack}
          >
            <h4>see all in one page</h4>
          </div>
        </>
      )}
      {isQuickLinks && (
        <>
          <div className="row" style={styles.title}>
            <h4>Quick Links</h4>
          </div>
        </>
      )}
      <Row className="row" style={styles.list}>
        {relatedConcepts.map((c, index) => {
          return (
            <Fragment>
              {(index + 1) % 3 === 0 && index >= after && <Col item xs={4} />}
              <Col
                style={styles.item}
                className="col-4 hover-grey"
                xs={4}
                onClick={() => conceptCallBack(c)}
              >
                {c.name}
              </Col>
            </Fragment>
          );
        })}
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  relatedConcepts: mapArrayToColumns(getRelatedConcepts(state)),
  selectedOption: state.navbar.selectedOption
});

const mapDispatchToProps = dispatch => ({
  setSelectedItem: bindActionCreators(navbarActions.setSelectedItem, dispatch)
});

RelatedConceptsList.propTypes = {
  hideClusters: PropTypes.bool,
  isQuickLinks: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(RelatedConceptsList));

const styles = {
  wrapper: {
    backgroundColor: DARK_GREY,
    padding: 15,
    marginTop: 40
  },
  title: {
    display: "flex",
    justifyContent: "center"
  },
  item: {
    fontWeight: 700,
    cursor: "pointer"
  },
  cluster: {
    fontSize: 18,
    color: LIGHT_BLUE,
    cursor: "pointer"
  }
};
