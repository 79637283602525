import * as constants from '../constants/navbar'
import { SORT_BY_RANKING, DESCEND_SORT } from '../constants/global'

const initialState = {
    books: [],
    relatedGroups: [],    //Groups realted to books
    relatedConcept: null,
    sort: SORT_BY_RANKING,
    apiLoading: false,
    alphabets:[],
    error:false,
    sortType: DESCEND_SORT,
    newBie: false,
    authorFilter: false,
    selectedCollection: null,
    singleBookRelatedAllData : {},
    singleBookAllPerspectiveConceptsAndAuthors : {},
    offset: 0,
    limit: 20,
}
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case constants.BOOKS:
        return {
            ...state,
            books: action.payload,
        }
        case constants.SET_BOOKS_OFFSET:
        return {
            ...state,
            offset: action.payload,
        }
        case constants.SIMILAR_ALPHABETS:
            return{
                ...state,
                alphabets:action.payload
            }
        case constants.SIMILAR_BOOKS_COLLECTION: 
            return {
                ...state,
                relatedGroups: action.payload
            }
        case constants.SIMILAR_BOOKS_CONCPET: 
            return {
                ...state,
                relatedConcept: action.payload
            }
        
        case constants.SET_BOOKS_SORTING: 
            return {
                ...state,
                sort: action.payload
            }
            
        case constants.BOOK_API: 
            return {
                ...state,
                apiLoading: action.payload
            }

        case constants.BOOKS_SORT_TYPE: {
            return {
                ...state,
                sortType: action.payload
            }
        }

        case constants.SET_NEW_BIE: {
            return {
                ...state,
                newBie: action.payload
            }
        }

        case constants.SET_BOOKS_AUTHOR_FILTER: {
            return {
                ...state,
                authorFilter: action.payload
            }
        }

        case constants.SET_SELECTED_COLLECTION: {
            return {
                ...state,
                selectedCollection: action.payload
            }
        }
        case constants.SINGLE_BOOK_RELATED_ALL_DATA_SUCCESS:{
            return{
                ...state,
                singleBookRelatedAllData : action.payload
            }
        }

        case constants.SINGLE_BOOK_RELATED_ALL_PERSPECTIVE_CONCEPTS_SUCCESS : {
            return {
                ...state,
                singleBookAllPerspectiveConceptsAndAuthors : action.payload
            }
        }
        default:
            return state;
    }
}