import * as constants from "../constants/feedLike";

const initialState = {
  feedlikes: [],
  prevLikeCount: 0,
  differenceCount: 0,
  curratedlikes: [],
  CurretedprevLikeCount: 0,
  CurreteddifferenceCount: 0
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.FEED_LIKES: {
      return {
        ...state,
        feedlikes: action.payload
      };
    }
    case constants.CURRATED_LIKES: {
      return {
        ...state,
        curratedlikes: action.payload
      };
    }
    case constants.PREV_LIKES_COUNT: {
      return {
        ...state,
        prevLikeCount: action.payload
      };
    }
    case constants.DIFFERENCE_LIKE_COUNT: {
      return {
        ...state,
        differenceCount: action.payload
      };
    }
    case constants.CURRETED_PREV_LIKES_COUNT: {
      return {
        ...state,
        CurretedprevLikeCount: action.payload
      };
    }
    case constants.CURRETED_DIFFERENCE_LIKE_COUNT: {
      return {
        ...state,
        CurreteddifferenceCount: action.payload
      };
    }
    default:
      return state;
  }
};
