import React from 'react';
const Message = () => {
    return(
        <div style={{paddingTop:"100px",textAlign:"center", minHeight: '89vh'}}>
            <h1>
                Try a search...
            </h1>
        </div>
    )
}

export default Message;