import React, { useState, useEffect } from "react";
import { Badge } from "reactstrap";
import ToolTip from "react-portal-tooltip";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { getMemerelateds } from "../../../actions/authorActions";
import { bindActionCreators } from "redux";
import history from "../../../router/history";
import { conceptCategory, memesCategory } from "../../../constants/global";
import OutsideClickHandler from "react-outside-click-handler";

const Virals = props => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [top, setTop] = useState(0);
  const [active, setActive] = useState(false);
  const [selectedId, setSelectedConceptId] = useState(null);

  useEffect(() => {
    window.addEventListener("scroll", () => setOpen(false));
    return () => {
      window.removeEventListener("scroll", setOpen(false));
    };
  }, []);

  const conceptReducer = useSelector(state => state.concepts);
  const navbarReducer = useSelector(state => state.navbar);
  const dispatch = useDispatch();

  const onMouseEnter = () => {
    setOpen(true);
  };

  const onMouseLeave = () => {
    // setShow(false);
    // setOpen(false);
  };

  const onToolTipLeave = () => {
    // setShow(false);
    // setOpen(false);
  };

  const callMemsApi = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/rest/v1/memes?conceptID=${props.memesID}`
      );

      setData(response && response.data && response.data.concepts);
      setShow(true);
    } catch {
      console.log("error");
    }
  };

  useEffect(() => {
    setTop($("#main-viral-div").offset().top);
  });

  const handleMemeTagMouseEnter = id => {
    props.getMemerelateds(id);
    setActive(true);
    setSelectedConceptId(id);
  };

  const closeAll = () => {
    setTimeout(() => {
      setOpen(false);
      setActive(false);
    }, 300);
  };

  const clickMemeQuickLink = () => {
    history.push(`/${memesCategory}/${navbarReducer.selectedOption.value}?keyword=meme`);
  };

  return (
    <OutsideClickHandler onOutsideClick={closeAll}>
      <div id="main-viral-div">
        <div
          onMouseEnter={onMouseEnter}
          onClick={() => history.push(`/${memesCategory}/${navbarReducer.selectedOption.value}`)}
          className="viral-div cursor-pointer"
        >
          <h3>Virals</h3>
          <Badge color="primary" className="ml-2">
            {conceptReducer.memesTagConcepts.length}
          </Badge>
        </div>
        {open && (
          <div
            className="viral-div cursor-pointer"
            style={{
              position: "fixed",
              top: top,
              left: "200px",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              zIndex: 2
            }}
          >
            <div onClick={() => clickMemeQuickLink()} style={{ cursor: "pointer", padding: 10 }}>
              Meme
            </div>

            {conceptReducer &&
              conceptReducer.memesTagConcepts &&
              conceptReducer.memesTagConcepts.map(tag => {
                return (
                  <div
                    id={`concept-${tag.id}`}
                    onClick={() =>
                      history.push(
                        `/${memesCategory}/${navbarReducer.selectedOption.value}?keyword=${tag.name}`
                      )
                    }
                    onMouseEnter={() => handleMemeTagMouseEnter(tag.id)}
                    style={{ cursor: "pointer", padding: 10 }}
                  >
                    {tag.name} {active}
                  </div>
                );
              })}
          </div>
        )}
        <div>
          {navbarReducer && navbarReducer.memeConceptRelateds && active && (
            <ToolTip
              active={active}
              parent={`#concept-${selectedId}`}
              position="right"
              arrow="top"
              tooltipTimeout={0}
            >
              <ul>
                {navbarReducer.memeConceptRelateds.map(e => {
                  return (
                    <li
                      onClick={() => {
                        history.push(
                          `/${memesCategory}/${navbarReducer.selectedOption.value}?keyword=${e.name}`
                        );
                      }}
                      className="cursor-pointer"
                    >
                      {e.name}
                    </li>
                  );
                })}
              </ul>
              {navbarReducer.memeConceptRelateds.length < 1 && (
                <ul>
                  <li></li>
                </ul>
              )}
            </ToolTip>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

const mapDispatchToProps = dispatch => ({
  getMemerelateds: bindActionCreators(getMemerelateds, dispatch)
});

export default connect(null, mapDispatchToProps)(Virals);
