import axios from "axios";
import { schema, normalize } from "normalizr";
import * as navbarConstants from "../constants/navbar";
import * as constants from "../constants/archives";
import { updateArchiveSecurity, updateArchivePriority } from "../services/archiveServices";
import arrayMove from "array-move";
import { SET_VIEWPORT } from "../constants/global";
import { reloadPage } from "../helpers/navbar";
export function createArchive(name, userId) {
  return dispatch => {
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/rest/v1/archive/create`, { name, userId })
      .then(data => {
        if (data.data.code === 201) {
          dispatch({
            type: "ADD_NEW_ARCHIVE",
            payload: data.data.response
          });
        }
      });
  };
}

export function setDefaultArchive(id) {
  return async dispatch => {};
}

export function updateArchive(name, archive) {
  return async (dispatch, getState) => {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER}/api/rest/v1/archive/update/${archive.id}`,
      { name }
    );
    if (response.status === 200) {
      const state = getState();
      const archives = state.archives.archiveList;
      const ids = state.archives.perspectiveIds;
      const index = archives.findIndex(x => x.id === archive.id);
      if (index > -1) {
        archive.name = name;
        archives[index] = archive;
        dispatch({
          type: constants.SET_ARCHIVES,
          payload: archives,
          perspectiveIds: ids
        });
      }
    }
  };
}
export function getAllArchives(userId) {
  return dispatch => {
    dispatch({
      type: navbarConstants.HIDE_PAGES,
      payload: false
    });
    if (userId) {
      axios
        .post(`${process.env.REACT_APP_SERVER}/api/rest/v1/archive/get`, { userId })
        .then(data => {
          if (data.data.code === 200) {
            let archives = data.data.data;
            let perspectiveIds = getPerspectiveIdsFromArchives(archives);
            let memeIds = getMemeIdsFromArchives(archives);
            dispatch({
              type: constants.SET_ARCHIVES,
              payload: archives,
              perspectiveIds,
              memeIds
            });
          }
        });
    }
  };
}
export function getPerspectiveIdsFromArchives(archives) {
  let perspectiveIds = [];
  archives.forEach(archive => {
    archive.Perspectives.forEach(data => {
      perspectiveIds.push({ perspectiveId: data.id, archiveId: archive.id });
    });
  });
  return perspectiveIds;
}
export function getMemeIdsFromArchives(archives) {
  let memeIds = [];
  archives.forEach(archive => {
    archive.Memes.forEach(data => {
      memeIds.push({ memeId: data.id, archiveId: archive.id });
    });
  });
  return memeIds;
}
export function addPerspectiveToArchive(perspectiveId, archiveId) {
  return dispatch => {
    dispatch({
      type: "SET_DEFAULT_ARCHIVE",
      payload: archiveId
    });

    axios
      .post(`${process.env.REACT_APP_SERVER}/api/rest/v1/archive/perspectiveArchive/add`, {
        perspectiveId,
        archiveId
      })
      .then(data => {
        if (data.data.code === 201) {
          dispatch({
            type: "ADD_TO_ARCHIVE",
            payload: { perspectiveId, archiveId }
          });

          dispatch({
            type: constants.LAST_SELECTED_ARCHIVE,
            payload: archiveId
          });
        }
      });
  };
}

export function addMemeToArchive(memeId, archiveId) {
  return dispatch => {
    dispatch({
      type: "SET_DEFAULT_ARCHIVE",
      payload: archiveId
    });

    axios
      .post(`${process.env.REACT_APP_SERVER}/api/rest/v1/archive/memeArchive/add`, {
        memeId,
        archiveId
      })
      .then(data => {
        if (data.data.code === 201) {
          dispatch({
            type: "ADD_MEME_TO_ARCHIVE",
            payload: { memeId, archiveId }
          });
        }
      });
  };
}

export function deletePerspectiveFromArchive(perspectiveId, archiveId, token, page) {
  return (dispatch, getState) => {
    const state = getState();
    let perspectives = state.navbar.perspectives;
    const mode = state.navbar.mode;
    axios
      .post(
        `${process.env.REACT_APP_SERVER}/api/rest/v1/archive/perspectiveArchive/delete`,
        { perspectiveId, archiveId },
        { headers: { Authorization: "Bearer " + token } }
      )
      .then(data => {
        if (data.data.code === 200) {
          dispatch({
            type: "REMOVE_PERSPECTIVE_ARCHIVE",
            payload: perspectiveId
          });
          if (mode === navbarConstants.ARCHIVE_MODE) {
            perspectives = perspectives.filter(p => p.id !== perspectiveId);
            dispatch({
              type: "PERSPECTVES",
              payload: [...perspectives]
            });
          }
        }
      });
  };
}

export function deleteMemeFromArchive(memeId, archiveId, token, page) {
  return (dispatch, getState) => {
    const state = getState();
    let memes = state.archives.memes;
    const mode = state.navbar.mode;
    axios
      .post(
        `${process.env.REACT_APP_SERVER}/api/rest/v1/archive/memeArchive/delete`,
        { memeId, archiveId },
        { headers: { Authorization: "Bearer " + token } }
      )
      .then(data => {
        if (data.data.code === 200) {
          dispatch({
            type: "REMOVE_MEME_ARCHIVE",
            payload: memeId
          });
          if (mode === navbarConstants.ARCHIVE_MODE) {
            memes = memes.filter(p => p.id !== memeId);
            dispatch({
              type: "SAVE_ARCHIVE_MEME",
              payload: memes
            });
          }
        }
      });
  };
}

export function deleteArchive(archiveId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const token = state.auth.token;
      let archives = state.archives.archiveList;
      const perspectiveIds = state.archives.perspectiveIds;

      let data = await axios.delete(
        `${process.env.REACT_APP_SERVER}/api/rest/v1/archive/update/${archiveId}`,
        { headers: { Authorization: "Bearer " + token } }
      );
      if (data.status === 200) {
        archives = archives.filter(ar => ar.id === archiveId);
        dispatch({
          type: constants.SET_ARCHIVES,
          payload: archives,
          perspectiveIds
        });
        reloadPage();
      }
    } catch (e) {
      console.log("erro is ", e);
    }
  };
}
export function emptyArchive(archiveId) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    let archives = state.archives.archiveList;
    let perspectiveIds = state.archives.perspectiveIds;
    const perspectives = state.navbar.perspectives;
    perspectiveIds = perspectiveIds.filter(obj => {
      const index = perspectives.findIndex(x => x.id === obj.perspective_id);
      return index < 0;
    });

    let data = await axios.post(
      `${process.env.REACT_APP_SERVER}/api/rest/v1/archive/update/${archiveId}`,
      { headers: { Authorization: "Bearer " + token } }
    );
    if (data.status === 200) {
      archives = archives.filter(ar => ar.id === archiveId);
      dispatch({
        type: constants.SET_ARCHIVES,
        payload: archives,
        perspectiveIds
      });
      dispatch({
        type: navbarConstants.SET_ARCHIVE_DATA,
        perspectives: [],
        authors: [],
        concepts: []
      });
    }
  };
}
export function setSelectedArchive(id) {
  return dispatch => {
    dispatch({
      type: constants.SELECTED_ARCIVE,
      payload: id
    });
  };
}
export function getPerspectivesByArchive(id) {
  return dispatch => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/rest/v1/archive/perspective/${id}`)
      .then(data => {
        if (data.data.code === 200) {
          const concept = new schema.Entity("concept");
          const author = new schema.Entity("author");
          const perspective = new schema.Entity("perspective", {
            Concept: concept,
            Author: author
          });
          const archiver = new schema.Entity("archiver", {
            Perspectives: [perspective]
          });
          let normalizedData = normalize(data.data.data, archiver);
          dispatch({
            type: "ARCHIVE_DATA",
            payload: normalizedData.entities
          });
        }
      });
  };
}

export function likePerspective(id, perspectives) {
  let like = {};
  like.type = "increase";
  like.id = id;
  return dispatch => {
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/rest/v1/perspective/like`, { like })
      .then(res => {
        perspectives[id].isLike = true;
        dispatch({
          type: "SET_PERSPECTIVE_LIKE",
          payload: perspectives
        });
      });
  };
}
export function dislikePerspective(id, perspectives) {
  let like = {};
  like.type = "decrease";
  like.id = id;
  return dispatch => {
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/rest/v1/perspective/like`, { like })
      .then(res => {
        perspectives[id].isLike = false;
        dispatch({
          type: "SET_PERSPECTIVE_LIKE",
          payload: perspectives
        });
      });
  };
}

export function likeMeme(id, memes) {
  let like = {};
  like.type = "increase";
  like.id = id;
  return dispatch => {
    axios.post(`${process.env.REACT_APP_SERVER}/api/rest/v1/meme/like`, { like }).then(res => {
      memes[id].isLike = true;
      dispatch({
        type: "SET_MEME_LIKE",
        payload: memes
      });
    });
  };
}
export function dislikeMeme(id, memes) {
  let like = {};
  like.type = "decrease";
  like.id = id;
  return dispatch => {
    axios.post(`${process.env.REACT_APP_SERVER}/api/rest/v1/meme/like`, { like }).then(res => {
      memes[id].isLike = false;
      dispatch({
        type: "SET_MEME_LIKE",
        payload: memes
      });
    });
  };
}

export const toggleArchiveSecurity = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { isPublic, selectedArchive, archiveOwner } = state.archives;
      const {
        user: { token }
      } = state.auth;
      const response = await updateArchiveSecurity(selectedArchive, !isPublic, token);
      if (response.status === 200) {
        const { data } = response.data;
        dispatch({
          type: constants.SET_ARCHIVE_META,
          payload: {
            isPublic: !isPublic,
            user_id: archiveOwner,
            slug: data
          }
        });
      }
    } catch (e) {
      console.log("error is ", e);
    }
  };
};

export const updateArchivesPriority = (oldIndex, newIndex) => {
  return async (dispatch, getState) => {
    const state = getState();
    let { archiveList, perspectiveIds } = state.archives;
    const {
      user: { token }
    } = state.auth;
    archiveList = arrayMove(archiveList, oldIndex, newIndex);
    archiveList = archiveList.map((x, index) => {
      x.priority = index;
      return x;
    });
    const payload = archiveList.map(x => ({ id: x.id, priority: x.priority }));
    const response = await updateArchivePriority(payload, token);
    dispatch({
      type: constants.SET_ARCHIVES,
      payload: [...archiveList],
      perspectiveIds
    });
  };
};

export const setAuthorArchiveFilter = author => {
  return dispatch => {
    dispatch({
      type: constants.AUTHOR_ARCHIVE_FILTER,
      payload: author.id
    });
  };
};

export const setConceptArchiveFilter = concept => {
  return dispatch => {
    dispatch({
      type: navbarConstants.SELECTED_CONCEPT_ID,
      payload: concept.id
    });
    dispatch({
      type: SET_VIEWPORT,
      payload: concept.id
    });
  };
};
