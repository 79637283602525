import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import * as constants from "../../../constants/memes";
import { DARK_BLUE } from "../../../constants/colors";
import { useSelector } from "react-redux";
import { Link } from "react-scroll";
import ToolTip from "react-portal-tooltip";
import history from "../../../router/history";

const ConceptList = props => {
  const [memeTooltipActive, setMemeTooltipActive] = useState(false);
  const closeMemeToolTip = () => (memeTooltipActive ? setMemeTooltipActive(false) : false);
  const {
    selectedOption,
    selectedId,
    showCount,
    isKeywords,
    isSingleBook,
    user,
    concepts,
    isMemePage,
    isMobile,
    RelatedAuthor,
    conceptName,
    setThirdKey = () => {}
  } = props;

  const dispatch = useDispatch();
  const callBack = (index, concept) => {
    setThirdKey(null);
    dispatch({
      type: constants.MEMES_TYPE_COUNT,
      payload: 0
    });
    props.conceptCallBack(index, concept);
  };
  let currentKeyword = window.location.href.split("=")[1];
  let currentConcept = window.location.href.split("/")[4];
  currentConcept = currentConcept && currentConcept.split("-").join(" ");
  currentConcept = currentConcept && currentConcept.toLowerCase();
  let pageName = window.location.href.split("/")[3];
  pageName = currentKeyword ? pageName.split("?")[0] : pageName;
  const color = isKeywords ? DARK_BLUE : "red";
  const bookId = window.location.pathname.split("/")[2];
  if (currentKeyword) {
    currentConcept = currentConcept.split("?")[0];
  }

 

  let currentObject = concepts && concepts.filter(elm => elm.name.toLowerCase() === currentConcept);

  let remaining = concepts && concepts.filter(elm => elm.name.toLowerCase() !== currentConcept);

  if (pageName === "Concepts" || pageName === "dictionary") {
    remaining = remaining.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }
  const gettingConceptValueHandler = data => {
  return history.push(`/proverbs/${data}`);
  };

  remaining && remaining.unshift(...currentObject);

  const getColorRelatedConcept = () => {
    switch (currentKeyword) {
      case "trivia":
        return "#288133";
      case "one-liner":
        return "#C78210";
      case "dad-joke":
        return "#D3665B";
 case "dad-joke":
        return "#D3665B";
      case "meme":
        return "#807500";

      default:
        return "#1565c0";
    }
  };

  return (
   
    
    <div style={{ marginRight: "-4px" }}>
  {
         pageName === "proverbs"?(
           <>
           <p  style={{  marginTop:'10vh',color:"red",fontWeight:"bold",border:"5px solid black",borderRadius:"50%",backgroundColor:"white" }} >{currentConcept}</p>
           </>
         ):(
           <>
           
           </>
         )
      }
      <ul style={!props.lock ? { ...styles.lockOnList } : { ...styles.list }} id="conceptsList">
        {remaining &&
          remaining.map((concept, index) => (
            <>
              <span
                key={concept.id}
                style={{
                  textAlign: isMemePage ? "right" : ""
                }}
                id={`memeConcept${concept.id}`}
                onMouseEnter={() => (props.enterMouse ? props.enterMouse(concept, true) : "")}
              >
                {isSingleBook && user.isAdmin && (
                  <span
                    className="fa fa-times cursor-pointer"
                    onClick={() => props.conceptRemoveCallBack(bookId, concept.id)}
                    style={{ color: "red", alignSelf: "center" }}
                  />
                )}

                <a
                  href={`/dictionary/${concept.slug}`}
                  onClick={e => {
                    e.preventDefault();
                  }}
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <li
                    key={concept.id}
                    onClick={() => {
                      pageName === "Concepts"
                        ? concept.count
                          ? callBack(index, concept)
                          : history.push(`/memes/${concept.slug ? concept.slug : concept.name}`)
                        : callBack(index, concept);
                    }}
                    style={{ ...styles.listItem }}
                    className="hover-grey" 
                  >
                    <span
                      className={
                        selectedOption &&
                        (selectedOption.id === concept.id || selectedId === concept.id) &&
                        "divPointer"
                      }
                      style={{
                        // fontFamily: "garamond",
                        fontWeight:
                          pageName === "memes"
                            ? selectedOption.id === concept.id || currentConcept === concept.name
                              ? "bolder"
                              : "bolder"
                            : selectedId === concept.id
                            ? "bolder"
                            : concept.count
                            ? "bolder"
                            : "bolder",
                        border:
                          pageName === "Authors" ||
                          pageName === "authors" ||
                          pageName === "author-groups" ||
                          pageName === "Author-Groups"
                            ? "none"
                            : pageName === "memes"
                            ? selectedOption.id === concept.id
                              ? "1px solid black"
                              : "none"
                            : selectedId === concept.id
                            ? "1px solid black "
                            : concept.count
                            ? "none"
                            : "none",
                        // backgroundColor:
                        //   pageName === "Authors" ||
                        //   pageName === "authors" ||
                        //   pageName === "author-groups" ||
                        //   pageName === "Author-Groups"
                        //     ? "none"
                        //     : pageName === "memes"
                        //     ? selectedOption.id === concept.id
                        //       ? "white"
                        //       : "none"
                        //     : selectedId === concept.id
                        //     ? "white "
                        //     : concept.count
                        //     ? "none"
                        //     : "none",
                        boxShadow:
                          pageName === "Authors" ||
                          pageName === "authors" ||
                          pageName === "author-groups" ||
                          pageName === "Author-Groups"
                            ? "none"
                            : pageName === "memes"
                            ? selectedOption.id === concept.id
                              ? "0px 0px 11px 5px rgba(0,0,0,0.75)"
                              : "none"
                            : selectedId === concept.id
                            ? "0px 0px 11px 5px rgba(0,0,0,0.75) "
                            : concept.count
                            ? "none"
                            : "none",
                        borderRadius:
                          pageName === "Authors" ||
                          pageName === "authors" ||
                          pageName === "author-groups" ||
                          pageName === "Author-Groups"
                            ? "none"
                            : pageName === "memes"
                            ? selectedOption.id === concept.id
                              ? "50%"
                              : "none"
                            : selectedId === concept.id
                            ? "50% "
                            : concept.count
                            ? "none"
                            : "none",
                        padding:
                          pageName === "Authors" ||
                          pageName === "authors" ||
                          pageName === "author-groups" ||
                          pageName === "Author-Groups"
                            ? "none"
                            : pageName === "memes"
                            ? selectedOption.id === concept.id
                              ? "5px"
                              : "none"
                            : selectedId === concept.id
                            ? "5px"
                            : concept.count
                            ? "none"
                            : "none",
                        fontSize:
                          pageName === "Authors" ||
                          pageName === "authors" ||
                          pageName === "author-groups" ||
                          pageName === "Author-Groups"
                            ? "24px"
                            : pageName === "memes"
                            ? selectedOption.id === concept.id && currentConcept === concept.name
                              ? 32
                              : selectedOption.id === concept.id
                              ? 24
                              : currentConcept === concept.name
                              ? 22
                              : 24
                            : selectedId === concept.id
                            ? 16
                            : concept.count
                            ? 14
                            : 16,
                        ...styles.name,
                        color:
                          pageName === "Authors" ||
                          pageName === "authors" ||
                          pageName === "author-groups" ||
                          pageName === "Author-Groups" 
                          // pageName === "proverbs"
                            ? "black"
                            : pageName === "memes"
                            ? selectedOption.id === concept.id
                              ? color
                              : getColorRelatedConcept()
                            : selectedId === concept.id
                            ? "red"
                            : concept.count
                            ? "#1565E6"
                            : "#5378e6"
                      }}

                      onClick={() => {
                        gettingConceptValueHandler(concept.name);
                      }}
                    >

                      {concept.name}
                    </span>
                    {/* {props.lock && props.lock !== undefined && (
                      <span
                        style={{
                          fontWeight: 1000
                          // marginLeft: -1,
                          // display: "flex",
                          // alignItems: "center"
                        }}
                      >
                        &#8213;
                      </span>
                    )} */}
                    {concept.count && (
                      <span
                        style={(styles.count, { color: "#1565E6" })}
                      >{` (${concept.count})`}</span>
                    )}
                  </li>
                </a>
              </span>
            </>
          ))}
      </ul>
    </div>
  );
};

ConceptList.propTypes = {
  concepts: PropTypes.array.isRequired,
  showCount: PropTypes.bool,
  selectedId: PropTypes.number,
  isKeywords: PropTypes.bool,
  conceptCallBack: PropTypes.func.isRequired
};

export default ConceptList;
const styles = {
  list: {
    lineHeight: "1.1em",
    listStyle: "none",
    width: "100%",
    // borderStyle: "solid",
    // borderLeftWidth: 0,
    // borderBottomWidth: 0,
    // borderTopWidth: 0,
    // borderRightWidth: 4,
    paddingLeft: 0
  },
  lockOnList: {
    lineHeight: "1.1em",
    listStyle: "none",
    width: "100%",
    paddingLeft: 0,
    paddingLeft: 15
  },
  listItem: {
    // padding: "8px",
    cursor: "pointer",
    paddingRight: 0,
    paddingTop: 8,
    paddingBottom: 8
  },
  name: {
    textTransform: "capitalize",
    marginRight: 3
  },
  count: {
    color: "gray",
    fontSize: 11
  },
  getKeywordStyles: (isKeywords, count) => {
    return isKeywords
      ? {
          fontSize: count ? (count >= 5 ? 19 : count >= 2 ? 14 : 12) : 12,
          fontWeight: count ? (count >= 5 ? "bolder" : count >= 2 ? 500 : 300) : 300
        }
      : {
          fontWeight: "bold"
        };
  }
};
