import React, { useState } from 'react';
import PropTypes from 'prop-types'
import AuthorCard from './authorCard'
import { Container, Row } from 'reactstrap'
import { BLACK, SKY_BLUE } from '../../../constants/colors';
import history from '../../../router/history';
import { similarAuthorRoute } from '../../../router/router';
import relatedAuthors from './relatedAuthors';

const AuthorCards = (props) => {

    const [full, setFull] = useState(false)
    const { authors, detailMode, currentAuthor } = props;

    const handleDictionaryClick = () => {
        history.push(`${similarAuthorRoute}/${currentAuthor}`)
    }


    return (
        <>
            {authors && authors.length > 0 &&
                <Container>
                    <div style={styles.header}>
                        <b>Similar Authors</b>
                        <b className="cursor-pointer hover-red" onClick={handleDictionaryClick}>View all in dictionary</b>
                    </div>
                    <Row style={styles.getRowstyles(detailMode, full)}>
                        {authors &&
                            authors.map(author => (
                                <AuthorCard
                                    key={author.id}
                                    author={author}
                                    onAuthorClick={props.handleAuthorClick}
                                    handleReciprocalClick={props.handleTempReciprocalClick}
                                    handleMouseEnter={() => { }}
                                    handleMouseLeave={() => { }}
                                    tools={false}
                                    detailMode={detailMode}
                                />
                            ))
                        }
                    </Row>
                    {!full && <span className="cursor-pointer" onClick={() => setFull(!full)} style={styles.see}>See More . . .</span>}
                </Container>
            }
        </>
    );
};

AuthorCards.propTypes = {
    authors: PropTypes.array.isRequired,
    handleAuthorClick: PropTypes.func.isRequired,
    detailMode: PropTypes.bool.isRequired,
    // currentAuthor: PropTypes.string
}

export default AuthorCards;

const styles = {
    getRowstyles(detailMode, fullHeight) {
        return detailMode ? {
            borderTop: `1px solid ${BLACK}`,
            paddingTop: 10,
            height: fullHeight ? "inherit" : 125,
            overflow: 'hidden'
        } : {}
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 10
    },
    see: {
        fontSize: 15,
        color: SKY_BLUE,
        fontWeight: 500
    }
}