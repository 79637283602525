import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.css';

import { PersistGate } from 'redux-persist/integration/react'
import store from './store/configureStore'
import Main from './Main';
import { render } from 'react-snapshot';

const rootElement = document.getElementById("root");


const app = <Provider store={store.store}>
                        <PersistGate loading={null} persistor={store.persistor}>
                                <Router>
                                        <Main/>
                                </Router>
                        </PersistGate>
                </Provider>

// ReactDOM.render(render, rootElement)
// ReactDOM.render(
        render(
            app,
            document.getElementById('root')
        )
// )



serviceWorker.unregister();
