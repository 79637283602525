export const CLUSTER_LOADING = "CLUSTER_LOADING";
export const CLUSTER_OFFSET = "CLUSTER_OFFSET";
export const CLUSTER_GROUPS = "CLUSTER_GROUPS";
export const CLUSTER_CONCEPT_INDEX = "CLUSTER_CONCEPT_INDEX";
export const SET_CLUSTER_SORTING = "SET_CLUSTER_SORTING";
export const SET_SELECTED_CONCEPT_ID = "SET_SELECTED_CONCEPT_ID";
export const SET_CLUSTER_SELECTED_AUTHOR = "SET_CLUSTER_SELECTED_AUTHOR";
export const BLOCK_CLUSTER_PAGINATION = "BLOCK_CLUSTER_PAGINATION";
export const SET_CONCEPT_FILTER_ID = "SET_CONCEPT_FILTER_ID";
export const CONCEPT_PERSPECTIVE_FINISH = "CONCEPT_PERSPECTIVE_FINISH";
