import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Button,
  Input,
  Alert,
  Row
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import addIcon from "../../../images/imageIcons/addIcon.png";
import Loader from "react-loader-spinner";
import { MAIN_WHITE } from "../../../constants/colors";
import Search from "../../TopNavBar/search/search";
import TempConcepts from "../../../components/Concept/cols/tempConcepts";
import { removeDuplicate } from "../../../helpers/common";

const ConceptModalForm = props => {
  const [name, setName] = useState("");
  const [pronoun, setPronoun] = useState("");
  const [myError, setMyError] = useState("");
  const [myLoading, setMyLoading] = useState(false);
  const [relatedConcepts, setRelatedConcepts] = useState([]);
  const [type, setType] = useState("what");
  const [id, setId] = useState(-1);
  const [showTooltip, setShowTooltip] = useState(false);

  const { loading, onConceptSubmit, error, user, open, setOpen = () => {} } = props;

  const toggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    // if previously loading then there could be an error
    if (!loading && myLoading) {
      setMyError(error);
      setName("");
      setOpen(false);
    }

    setMyLoading(loading);
  }, [loading, error]);

  const handleSubmit = () => {
    if (name) {
      if (!loading) {
        onConceptSubmit(name, pronoun, type, relatedConcepts);
        setName("");
        setPronoun("");
        toggle();
        setRelatedConcepts([]);
      }
    } else {
      setMyError("Please fill name correctly");
    }
  };
  const conceptAddCallBack = concept => {
    concept.reciprocalExist = true;
    setRelatedConcepts([...relatedConcepts, concept]);
  };

  const changeReciprocalExist = concept => {
    if (concept.reciprocalExist && concept.reciprocalExist === true) {
      concept.reciprocalExist = false;
    } else {
      concept.reciprocalExist = true;
    }
    let temp = [...relatedConcepts];
    const index = temp.findIndex(e => e.id === concept.id);
    temp[index] = concept;
    setRelatedConcepts(temp);
  };

  const showToolTipOfRelated = concept => {
    setId(concept.id);
    props.getRelatedConcepts(concept.id);
    // setShowTooltip(true);
  };
  return (
    <>
      {/* <ToolTip active={showTooltip} parent={`#addConcept${id}`} position="right" arrow="center" style={toolTipStyle}>
                <TempConcepts
                    concepts={props.relatedConcepts}
                    isAdmin={user.isAdmin}
                //   onMouseEnter={showToolTipOfRelated}
                // onMouseLeave={handleTooltipMouseLeave}
                // handleTempReciprocalClick={handleTempReciprocalClick}
                />
            </ToolTip> */}

      <img src={addIcon} style={styles.addIcon} onClick={toggle} alt="add icon" />
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Concept Modal</ModalHeader>
        <ModalBody>
          {myError && <Alert color="danger">{myError}</Alert>}
          <ReactTooltip
            id={`addConcept${id}`}
            place="right"
            effect="solid"
            delayShow={500}
            className="extraClass"
            getContent={dataTip => {
              return (
                <TempConcepts
                  concepts={props.relatedConcepts}
                  isAdmin={user.isAdmin}
                  // onMouseEnter={showToolTipOfRelated}
                  // onMouseLeave={handleTooltipMouseLeave}
                  handleTempReciprocalClick={conceptAddCallBack}
                />
              );
            }}
          />

          <FormGroup tag="fieldset">
            <Row className="d-flex justify-content-center">
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    onClick={() => setType("who")}
                    checked={type === "who"}
                    name="type"
                  />{" "}
                  Who
                </Label>
              </FormGroup>
              <FormGroup check className="ml-2">
                <Label check>
                  <Input
                    type="radio"
                    onClick={() => setType("what")}
                    checked={type === "what"}
                    name="type"
                  />{" "}
                  What
                </Label>
              </FormGroup>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label>Pronoun</Label>
            <Input
              type="text"
              name="pronoun"
              value={pronoun}
              onChange={e => setPronoun(e.target.value)}
              placeholder="Enter Pronoun"
            />
          </FormGroup>
          <FormGroup>
            <Label>Concept Name</Label>
            <Input
              type="text"
              name="name"
              autocomplete="off"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Enter concept name"
            />
          </FormGroup>

          {user.isAdmin && (
            <div style={styles.searchWrapper}>
              <Search
                className="ml-2"
                value=""
                category="Concept"
                onChange={item => conceptAddCallBack(item)}
                placeholder="Related Concepts"
              />
            </div>
          )}
          <Row className="mx-4 mt-2 justify-content-start">
            {relatedConcepts.length > 0 &&
              removeDuplicate(relatedConcepts).map(c => (
                <div
                  style={styles.internalWrapper}
                  key={c.id}
                  onMouseEnter={() => showToolTipOfRelated(c)}
                >
                  <div style={styles.chip}>
                    <input
                      type="radio"
                      checked={c.reciprocalExist}
                      onClick={() => changeReciprocalExist(c)}
                      style={styles.input}
                    />
                    <span
                      className="ml-2 hover-grey"
                      onClick={() => changeReciprocalExist(c)}
                      onTouchEnd={() => changeReciprocalExist(c)}
                      id={`addConcept${c.id}`}
                      data-tip
                      data-for={`addConcept${c.id}`}
                    >
                      {c.label || c.name}
                    </span>
                  </div>
                </div>
              ))}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            {loading ? <Loader type="Oval" color={MAIN_WHITE} height="30" width="30" /> : "Add"}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ConceptModalForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onConceptSubmit: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired
};

export default ConceptModalForm;

const styles = {
  addIcon: {
    width: 25,
    height: 25,
    cursor: "pointer"
  },
  internalWrapper: {
    minWidth: "15%",
    padding: 5
  },
  searchWrapper: {
    maxWidth: 200
  }
};
