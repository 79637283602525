import React from "react";
import PropTypes from "prop-types";
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import info from "../../../images/imageIcons/info.png";
import login from "../../../images/imageIcons/login.png";
import archive from "../../../images/imageIcons/archive2.png";
import feed from "../../../images/imageIcons/likeFeed.png";
import news from "../../../images/imageIcons/newsFeed.png";
import books from "../../../images/imageIcons/archive2.png";
import add from "../../../images/imageIcons/add.png";
import random from "../../../images/surprise.png";
import contact from "../../../images/imageIcons/contact.png";
import termsOfUse from "../../../images/imageIcons/termsOfUse.png";
import privacy from "../../../images/imageIcons/privacy.png";
import messengerIcon from "../../../images/imageIcons/messenger.png";
import history from "../../../router/history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions/navbaractions";
import { MY_ARCHIVE } from "../../../constants/archives";

import "./burgerMenu.css";

const BurgerMenu = props => {
  const menues = [
    {
      id: 1,
      icon: archive,
      title: "My Archive",
      onClick: () => handleArchiveClick()
    },
    {
      id: 2,
      icon: feed,
      title: "My Feed",
      onClick: () => navigate("/feed")
    },
    {
      id: 3,
      icon: books,
      title: "Books",
      onClick: () => navigate("/books/all")
    },
    {
      id: 4,
      icon: news,
      title: "In the News",
      onClick: () => navigate("/news")
    },
    // {
    //   id: 5,
    //   icon: random,
    //   title: "Random",
    //   onClick: () => props.surpriseClick()
    // },
    {
      id: 6,
      icon: messengerIcon,
      title: "Messenger",
      onClick: () => handleMessengerClick()
    },
    {
      id: 7,
      icon: info,
      title: "About",
      onClick: () => navigate("/about")
    },
    {
      id: 8,
      icon: add,
      title: "Submit a text",
      onClick: () => props.openDefinitionModal(true)
    },
    {
      id: 9,
      icon: termsOfUse,
      title: "Terms of Use",
      onClick: () => navigate("/TermsOfUse")
    },
    {
      id: 10,
      icon: contact,
      title: "Contact us",
      onClick: () => navigate("/contact")
    },
    {
      id: 11,
      icon: privacy,
      title: "Privacy Policy",
      onClick: () => navigate("/PrivacyPolicy")
    }
  ];

  const { archives, loading, isAuthenticated } = props;

  const navigate = route => history.push(route);

  const handleArchiveClick = () => {
    const archive = archives.find(archive => archive.name === MY_ARCHIVE);
    if (archive) {
      history.push("/");
      props.fetchArchiveData(archive.id);
      props.setPrimaryMode(false);
    } else {
      history.push("/");
      props.emptyData();
      props.setPrimaryMode(false);
    }
  };
  const handelFeedClick = () => {
    if (!loading) {
      history.push("/");
      props.resetModes();
      props.removeSubFilter();
    }
  };

  const handleNewsClick = () => {
    if (!loading) {
      history.push("/");
      props.removeSubFilter();
      props.setPrimaryMode(false);
      props.setNewsMode(true);
    }
  };

  const handleAuthClick = () => {
    if (isAuthenticated) {
      props.logout();
    } else {
      navigate("/login");
    }
  };

  const handleMessengerClick = () => {
    window.open("http://m.me/conceptionary", "_blank");
  };

  return (
    <UncontrolledDropdown nav inNavbar data-tip="info">
      <DropdownToggle nav>
        <div className="burger">
          <div className="bar1" />
          <div className="bar2" />
          <div className="bar3" />
        </div>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem className="burger-list" onClick={handleAuthClick}>
          <img src={login} className="icon burger-list-icon" alt="login icon" />
          <span className="burger-list-title">{isAuthenticated ? "Logout" : "Login"}</span>
        </DropdownItem>
        {menues &&
          menues.map(menu => (
            <DropdownItem className="burger-list" onClick={menu.onClick} key={menu.id}>
              <img src={menu.icon} className="icon burger-list-icon" alt="menu Icon" />
              <span className="burger-list-title">{menu.title}</span>
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const mapStateToProps = state => ({
  archives: state.archives.archiveList,
  loading: state.navbar.loading,
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = dispatch => {
  return {
    fetchArchiveData: bindActionCreators(actions.fetchArchiveData, dispatch),
    setMode: bindActionCreators(actions.setMode, dispatch),
    setPrimaryMode: bindActionCreators(actions.setPrimaryMode, dispatch),
    removeSubFilter: bindActionCreators(actions.removeSubFilter, dispatch),
    setNewsMode: bindActionCreators(actions.setNewsMode, dispatch),
    resetModes: bindActionCreators(actions.resetModes, dispatch),
    surpriseClick: bindActionCreators(actions.surpriseConcept, dispatch),
    logout: bindActionCreators(actions.logout, dispatch),
    emptyData: bindActionCreators(actions.emtptyData, dispatch),
    openDefinitionModal: bindActionCreators(actions.setModal, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenu);
