import $ from "jquery";
import { handleNavbarScroll } from "./navbar";

let prevScroll = 0;
let prevFirstScroll = 0;
let prevThirdScroll = 0;
let prevFourthScroll = 0;

let ignoreWindowScrollEvents = false;
let ignoreFirstScrollEvents = false;
let ignoreThirdScrollEvents = false;
let ignoreFourthScrollEvents = false;

export const firstColId = "firstCol";
export const thirdColId = "thirdCol";
export const fourthColId = "fourthCol";
const firstCol = `#${firstColId}`;
const thirdCol = `#${thirdColId}`;
const fourthCol = `#${fourthColId}`;

export const handleFirstWheel = x => {
  if (!ignoreFirstScrollEvents) {
    let firstScroll = $(firstCol).scrollTop();
    let diff = firstScroll - prevFirstScroll;

    ignoreWindowScrollEvents = true;
    ignoreThirdScrollEvents = true;
    ignoreFourthScrollEvents = true;
    ignoreFourthScrollEvents = true;

    setDifference(window, thirdCol, fourthCol, diff);
    updateScroll();
  }
  ignoreFirstScrollEvents = false;
};

export const handleScrollCallBack = () => {
  let oldScroll = prevScroll;
  if (!ignoreWindowScrollEvents) {
    let secondScroll = $(window).scrollTop();
    let diff = secondScroll - prevScroll;

    ignoreFirstScrollEvents = true;
    ignoreThirdScrollEvents = true;
    ignoreFourthScrollEvents = true;

    setDifference(firstCol, thirdCol, fourthCol, diff);
    updateScroll();
  }
  ignoreWindowScrollEvents = false;
  handleNavbarScroll(oldScroll);
  return oldScroll;
};

export const scrollPosition = () => {
  const newPosition = handleScrollCallBack();
  if (newPosition <= prevScroll) {
    return false;
  } else {
    return true;
  }
};

export const handleThirdWheel = x => {
  if (!ignoreThirdScrollEvents) {
    let thirdScroll = $(thirdCol).scrollTop();
    let diff = thirdScroll - prevThirdScroll;
    ignoreWindowScrollEvents = true;
    ignoreFirstScrollEvents = true;
    ignoreFourthScrollEvents = true;
    setDifference(firstCol, window, fourthCol, diff);
    updateScroll();
  }
  ignoreThirdScrollEvents = false;
};

export const handleFourthWheel = x => {
  if (!ignoreFourthScrollEvents) {
    let fourthScroll = $(fourthCol).scrollTop();
    let diff = fourthScroll - prevFourthScroll;
    ignoreWindowScrollEvents = true;
    ignoreFirstScrollEvents = true;
    ignoreThirdScrollEvents = true;
    setDifference(firstCol, window, thirdCol, diff);
    updateScroll();
  }
  ignoreFourthScrollEvents = false;
};

export const setDifference = (first, second, third, diff) => {
  let firstMove = $(first).scrollTop();
  let secondMove = $(second).scrollTop();
  let thirdMove = $(third).scrollTop();

  $(first).scrollTop(firstMove + diff);
  $(second).scrollTop(secondMove + diff);
  $(third).scrollTop(thirdMove + diff);
};

export const setIgnoreWindowScroll = () => {
  ignoreWindowScrollEvents = true;
};

export const setPrevScroll = scroll => {
  prevScroll = $(window).scrollTop();
};

export const setScroll = scroll => {
  ignoreWindowScrollEvents = true;
  $(window).scrollTop(scroll);
};
export const resetSecondColScroll = () => {};

export const setFirstScroll = scroll => {
  ignoreFirstScrollEvents = true;
  $(firstCol).scrollTop(scroll);
};

export const setThirdScroll = scroll => {
  ignoreThirdScrollEvents = true;
  $(thirdCol).scrollTop(scroll);
};

export const setPrevFirstScroll = scroll => {
  prevFirstScroll = scroll;
};

export const setPrevThirsScroll = scroll => {
  prevThirdScroll = scroll;
};

export const resetSecondCol = () => {
  ignoreWindowScrollEvents = true;
  $(window).scrollTop(0);
  updateScroll();
};

export const resetScroll = () => {
  $(firstCol).scrollTop(0);
  $(window).scrollTop(0);
  $(thirdCol).scrollTop(0);
  prevThirdScroll = 0;
  prevScroll = 0;
  prevFirstScroll = 0;
};

export const updateScroll = () => {
  prevScroll = $(window).scrollTop();
  prevFirstScroll = $(firstCol).scrollTop();
  prevThirdScroll = $(thirdCol).scrollTop();
  prevFourthScroll = $(fourthCol).scrollTop();
};

export const hasReachedToBottom = () => {
  const windowHeight = $(window).height();
  const scrollHeight = $(window).scrollTop();
  const secondColumnHeight = $("#secondCol").innerHeight();
  const secondColumnHeight2 = $("#secondCol2").innerHeight();
  const normalPageHeight = $("#normalPage").innerHeight();
  // +50 is due to 50 padding top of component
  if (parseInt(windowHeight + scrollHeight) >= parseInt(secondColumnHeight + 50)) {
    return true;
  } else if (parseInt(windowHeight + scrollHeight) >= parseInt(secondColumnHeight2 + 50)) {
    return true;
  } else if (parseInt(windowHeight + scrollHeight) >= parseInt(normalPageHeight)) {
    return true;
  } else return false;
};

export const hasReachedFourthColumnToBottom = () => {
  $("#fourthCol").on("scroll", function() {
    if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
      return true;
    } else {
      return false;
    }
  });
};

export const hasWindowReachedBottom = () => {
  return $(window).scrollTop() + $(window).height() >= $(document).height() - 100;
};

export const getWindowScroll = () => {
  const scrollHeight = $(window).scrollTop();
  return scrollHeight;
};

export const listenDocumentHeightChange = () => {};

export const handleBodyWheel = x => {
  // x will be the mouse wheel event
  let firstScroll = $(firstCol).scrollTop();
  let secondScroll = $(window).scrollTop();
  let thirdScroll = $(thirdCol).scrollTop();
  if (secondScroll == prevScroll) {
    ignoreFirstScrollEvents = true;
    ignoreThirdScrollEvents = true;
    $(firstCol).scrollTop(firstScroll + x.originalEvent.deltaY);
    $(thirdCol).scrollTop(thirdScroll + x.originalEvent.deltaY);
    prevScroll = secondScroll;
    prevFirstScroll = firstScroll;
    prevThirdScroll = thirdScroll;
  }
};

export function handleContextAndCopyPaste(enable) {
  if (enable) {
    // $(document).ready(() => {
    //     $('body').bind('cut copy paste', function (e) {
    //         e.preventDefault();
    //     });
    //     $("body").on("contextmenu",function(e){
    //         return false;
    //     });
    //   })
  } else {
    $("body").unbind("cut copy paste");
    $("body").off("contextmenu");
  }
}
