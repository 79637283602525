import Axios from "axios";
import { groupsCategory } from "../constants/global";
import { similarAuthorRoute } from "../router/router";
export function getAuthorGroups(filters){
    let filterobj = {};
    filterobj.filters = filters;
    return new Promise((resolve, reject) => {
        Axios.post(`${process.env.REACT_APP_SERVER}/api/rest/v1/authorGroups`, filterobj)
            .then(data => {
                resolve(data.data);
            })
            .catch(err => {
                console.log(err);
            })
    });
}
export function getGroupItem(group)
{
    return  {
        id: group.id,
        value: group.name,
        label: group.name,
        category: groupsCategory,
        slug: group.slug,
        booksCount: group.booksCount,
        booksCollection: group.booksCollection
    }
}

export const isSimilarAuthorPage = () => window.location.pathname.includes(similarAuthorRoute)