import axios from 'axios'
import { BASE_URL } from '../constants/global'


export const conceptPerspectivesByLikes = (perspective) => {
    // return axios.post(`${BASE_URL}/perspectives/likes`,{perspective})
}
export const fetchBooksAndAuthors = (bookCollectionId, authorGroupId) => {
    return axios.get(`${BASE_URL}/concepts/authors/books?authorID=${authorGroupId}&bookId=${bookCollectionId}`)
}
export const unverifiedConceptsApi = (token) => {
    return axios.get(`${BASE_URL}/unverifiedconcepts`, { headers: { Authorization: token } })
}

export const delUnverifiedConceptsApi = (id, token) => {
    return axios.delete(`${BASE_URL}/unverifiedconcepts/${id}`, { headers: { Authorization: token } })
}


export const conceptPerspectivesByAuthors = perspective => {
    // return axios.post(`${BASE_URL}/perspectives/authors`, { perspective })
}

export const addKeywordToPerspective = (conceptId, perspectiveId) => {
    return axios.post(`${BASE_URL}/keyword/submit`, { conceptId, perspectiveId })
}

export const removeKeywordFromPerspective = (conceptId, perspectiveId) => {
    return axios.delete(`${BASE_URL}/keyword/submit`, { data: { conceptId, perspectiveId } })
}

export const conceptByNameAndFilter = (name, filters) => {
    return axios.post(`${BASE_URL}/concepts/details/name`, { name, filters })
}

export const conceptRelatedClusters = (conceptId) => {
    return axios.get(`${BASE_URL}/concepts/relateds/conceptClusters/${conceptId}`)
}

export const conceptRelatedGroups = (authorIds) => {
    return axios.post(`${BASE_URL}/concepts/relateds/authorGroups`, { authorIds })
}

export function getClusterConcepts({ type, id }) {
    return axios.get(`${BASE_URL}/conceptCluster/${type}/${id}`)
}

export function addReciprocal({ token, clusterId, conceptId }) {
    return axios.post(`${BASE_URL}/admin/conceptClusters/reciprocal`, { conceptId, clusterId }, { headers: { Authorization: token } })
}

export function removeReciprocal({ token, clusterId, conceptId }) {
    return axios.delete(`${BASE_URL}/admin/conceptClusters/reciprocal`, { data: { clusterId, conceptId }, headers: { Authorization: token } })
}
export function deleteConceptFromCluster({ token, clusterId, conceptId, type }) {
    return axios.delete(`${BASE_URL}/admin/conceptClusters/concept`, { data: { clusterId, conceptId, type }, headers: { Authorization: token } })
}
export function addConceptToCluster({ token, clusterId, conceptId, type }) {
    return axios.post(`${BASE_URL}/admin/conceptClusters/concept`, { clusterId, conceptId, type }, { headers: { Authorization: token } })
}
export function addNewConcept(token, name, pronoun, type, relatedConcepts) {
    return axios.post(`${BASE_URL}/concepts`, { name, pronoun, type, relatedConcepts }, { headers: { Authorization: token } })
}
export function getAuthorsByConceptId(conceptId, filter) {
    return axios.get(`${BASE_URL}/concept/authors/${conceptId}?groupId=${filter}`)
}