import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import archiveIcon from "../../../../images/imageIcons/archive2.png";
import * as actions from "../../../../actions/navbaractions";
import { getAllArchives } from "../../../../actions/archives";
import * as constants from "../../../../constants/archives";
import * as navbarConstants from "../../../../constants/navbar";
import ArchiveToolTip from "../../../TopNavBar/navChild/archiveToolTip";
import history from "../../../../router/history";
import { pageReloadscroll } from "../../../../helpers/navbar";
const ArchiveButton = props => {
  const [listen, setListen] = useState("");
  const [down, setDown] = useState(false);
  const [toolTip, setToolTip] = useState(false);
  const [delay, setDelay] = useState(false);
  const dispatch = useDispatch();
  const {
    archives,
    selectedArchive,
    isAuthenticated,
    archiveMode,
    isNavbar,
    hideTitle,
    user
  } = props;

  const archiveClick = (name, id) => {
    props.fetchArchiveData(id);
    props.setPrimaryMode(false);
    setToolTip(false);
    pageReloadscroll();
    //history.push("/myarchive");
  };

  const archiveMouseDown = () => {
    if (!toolTip) {
      setListen(
        setTimeout(() => {
          handleLongMouseClick();
        }, 300)
      );
      setDown(true);
    } else {
      setToolTip(false);
    }
  };
  const archiveMouseUp = () => {
    if (isAuthenticated) {
      if (down) {
        handleShortMouseClick();
      }
    } else {
      props.setMode(navbarConstants.ARCHIVE_MODE);
      props.setPrimaryMode(false);
      props.setPerspectives([]);
    }
  };
  const handleShortMouseClick = () => {
    if (isAuthenticated) {
      handleMouseUp();
      if (selectedArchive > -1) {
        archiveClick("", selectedArchive);
      } else {
        const archive = archives.find(x => x.name === constants.MY_ARCHIVE);
        if (archive) {
          archiveClick(constants.MY_ARCHIVE, archive.id);
        }
      }
    } else {
      showMessage();
    }
  };
  const showMessage = () => {
    props.setMode(navbarConstants.ARCHIVE_MODE);
    history.push("/");
  };
  const handleLongMouseClick = () => {
    if (isAuthenticated) {
      setToolTip(true);
      setDown(false);
    } else {
      showMessage();
    }
  };
  const handleMouseUp = () => {
    clearTimeout(listen);
    setDown(false);
  };
  const handleMouseEnter = () => {
    if (toolTip) {
      clearTimeout(delay);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toolTip]);

  const handleScroll = () => {
    if (toolTip) {
      setToolTip(false);
    }
  };

  const handleMouseLeave = () => {
    if (toolTip) {
      setDelay(
        setTimeout(() => {
          setToolTip(false);
        }, 200)
      );
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      props.getAllArchives();
    }
  }, [archiveMode]);
  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ justifyContent: isNavbar ? "flex-start !important" : "center !important" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a
        //href={`/myarchive`}
        onClick={e => {
          e.preventDefault();
        }}
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <i
          className="far fa-bookmark"
          //src={archiveIcon}
          id="listArchive"
          alt="filterIcon"
          data-tip="Archive"
          onMouseDown={archiveMouseDown}
          onMouseUp={archiveMouseUp}
          style={{ fontSize: "19px", marginTop: "8px" }}
        >
          {" "}
          My Archive
        </i>
      </a>
      {/* {!hideTitle && <b>archive</b>} */}
      {/* <ArchiveToolTip
        isTooltipActive={toolTip}
        setActive={setToolTip}
        archiveClick={archiveClick}
        id="listArchive"
      /> */}
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  archives: state.archives.archiveList,
  selectedArchive: state.archives.selectedArchive,
  archiveMode: state.navbar.mode === navbarConstants.ARCHIVE_MODE
});

const mapDispatchToProps = dispatch => {
  return {
    fetchArchiveData: bindActionCreators(actions.fetchArchiveData, dispatch),
    setMode: bindActionCreators(actions.setMode, dispatch),
    setPrimaryMode: bindActionCreators(actions.setPrimaryMode, dispatch),
    setPerspectives: bindActionCreators(actions.setPerspectives, dispatch),
    getAllArchives: bindActionCreators(getAllArchives, dispatch)
  };
};

ArchiveButton.propTypes = {
  isNavbar: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveButton);

const styles = {
  responseive: {
    width: "100%",
    height: "100%"
  }
};
