import React from "react";
import { connect } from "react-redux";
import FacebookComment from "./FacebookComment";
import backIcon from "../../../images/imageIcons/main.png";
import "./overlay.css";
import { bindActionCreators } from "redux";
import { setCommentsMode } from "../../../actions/mobile";

const OverLay = props => {
  const { commentsMode, selectedOption, subFilter } = props;

  return (
    <>
      {commentsMode && (
        <div id="comments-overlay">
          <img
            src={backIcon}
            alt="book image"
            id="back-image"
            onClick={() => props.setCommentsMode(false)}
          />
          <FacebookComment
            url={window.location.href}
            showComment={true}
            filter={subFilter}
            title={selectedOption}
            fullHeight={true}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    commentsMode: state.mobile.commentsMode,
    subFilter: state.navbar.subFilter,
    selectedOption: state.navbar.selectedOption
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCommentsMode: bindActionCreators(setCommentsMode, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverLay);
