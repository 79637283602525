import queryString from "query-string";
import { similarAuthorCategory } from "../constants/global";

export const getMemesCondition = () => {
  const params = queryString.parse(window.location.search);
  let condition = {};
  Object.keys(params).forEach(key => {
    const value = params[key];

    key = key.toLowerCase();

    if (key === "concepts") {
      condition = {
        conceptId: value
      };
    }
    if (key === "authors") {
      condition = {
        authorId: value
      };
    }
    if (key === "concept-clusters") {
      condition = {
        clusterId: value
      };
    }
    if (key === "author-groups") {
      condition = {
        groupId: value
      };
    }
  });
  return condition;
};

export const getPageMemesCondition = (selectedOption, authors = []) => {
  let condition = {};
  let category = selectedOption.category;
  if (category) {
    const category = selectedOption.category.toLowerCase();
    if (category === "concepts") {
      condition = {
        conceptId: selectedOption.id
      };
    }
    if (category === "authors") {
      condition = {
        authorId: selectedOption.id
      };
    }
    if (category === "concept-clusters") {
      condition = {
        clusterId: selectedOption.id
      };
    }
    if (category === "author-groups") {
      condition = {
        groupId: selectedOption.id
      };
    }
    if (category === similarAuthorCategory.toLowerCase()) {
      condition = {
        authorId: authors.map(author => author.id)
      };
    }
    return condition;
  }
};

export const shuffle = arr => {
  var i, j, temp;
  for (i = arr.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
};
