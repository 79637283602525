import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import queryString from "query-string";
import Search from "../TopNavBar/search/search";
import Loader from "react-loader-spinner";
import * as global from "../../constants/global";
import { Button } from "reactstrap";

const getColor = props => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  margin-top: 10px;
  transition: border 0.24s ease-in-out;
  min-height: 100px;
`;

const MemesUpload = props => {
  const {
    user,
    uploadSuccess,
    memesPage,
    selectedOption,
    loadingPage,
    notfinished,
    setAddAdvertise,
    nature,
    currentMeme,
    setActive
  } = props;

  // console.log("current meme", currentMeme);

  const [images, setImages] = useState([]);
  const [paths, setPaths] = useState([]); // For uploading multiple
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [description, setDescription] = useState(null);
  const [citation, setCitation] = useState(null);
  const [tags, setTags] = useState([]);
  const [imageLink, setImageLink] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (nature === "updateMeme") {
      setDescription(currentMeme.description);
      setCitation(currentMeme.citation);
    }
  }, []);

  const handleAddTag = input => {
    const isTag = tags.some(item => item.id === input.id);
    if (!isTag) {
      const newTags = [...tags, input];
      setTags(newTags);
    }
  };

  const removeTag = item => {
    const newTags = tags.filter(e => e.id !== item.id);
    setTags(newTags);
  };

  //////////// UPLOAD IMAGE S3 BUCKET

  const handleChangeFile = type => event => {
    const nameFile = type === "imageLink" ? event.target.files[0] : null;

    if (type === "imageLink" && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      uploadImage(nameFile);
    }
  };

  const uploadImage = image => {
    // setFileUplodLoading(true)
    var S3 = require("aws-sdk/clients/s3");
    const s3bucket = new S3({
      accessKeyId: `${process.env.REACT_APP_accessKeyId}`,
      secretAccessKey: `${process.env.REACT_APP_secretAccessKey}`,
      Bucket: `${process.env.REACT_APP_AUTHOR_BUCKET}`,
      signatureVersion: "v4",
      ACL: `${process.env.REACT_APP_ACL}`,
      region: `${process.env.REACT_APP_region}`,
      successActionStatus: 201,
      keyPrefix: "images/"
    });

    s3bucket.createBucket(() => {
      const params = {
        Bucket: `${process.env.REACT_APP_AUTHOR_BUCKET}`,
        Key: image.name,
        Body: image,
        ContentType: image.type,
        ACL: `${process.env.REACT_APP_ACL}`,
        region: `${process.env.REACT_APP_region}`,
        successActionStatus: 201,
        keyPrefix: "images/"
      };
      // setFileUplodLoading(true)
      //changeButton = true;
      //console.log(`1`, changeButton);
      const api = s3bucket.upload(params, (err, data) => {
        //console.log(`api`, params);
        if (err) {
          //  setFileUplodLoading(false)
          return;
        }
        if (data) {
          setTimeout(() => {
            setImageLink(data.Location);

            // console.log(`1`,fileUploadLoading);
            // if(fileUploadLoading==true)
            // {
            //   setFileUplodLoading(false);
            //   console.log(`2`,fileUploadLoading);
            // }
            if (params.successActionStatus == 201) {
              //changeButton = false;
              // setFileUplodLoading(!fileUploadLoading);
              //console.log(`2`, changeButton);
            }
          }, 3000);
        }
      });
    });
  };

  const onClick = () => {
    if (nature !== "updateMeme" && !imageLink) {
      setErrorMessage("No meme selected!");
    } else if (nature !== "updateMeme" && tags.length === 0) {
      setErrorMessage("Select concept of meme!");
    } else {
      let formData;
      let updateData;
      nature !== "updateMeme"
        ? (formData = {
            userId: user.id,
            finishStatus: false,
            description: description,
            tags: tags,
            url: imageLink,
            citation
          })
        : (updateData = {
            memeId: currentMeme.id,
            description: description ? description : null,
            citation: citation ? citation : null,
            url: imageLink ? imageLink : currentMeme.url
          });

      nature === "updateMeme" ? props.updateImageMeme(updateData) : props.uploadMemes(formData);
      // setAddAdvertise(false);

      nature === "updateMeme" && setActive(false);
    }
  };

  return (
    <div className="container pt-1">
      {errorMessage && (
        <div style={styles.messageWrappper}>
          <span className="alert alert-danger">
            <i class="fas fa-exclamation-circle"></i> {errorMessage}
          </span>
        </div>
      )}
      <div style={{ textAlign: "center", marginTop: "10px", marginBottom: "22px" }}>
        <div>Click to select your meme</div>
        <label
          for="files"
          style={{
            backgroundColor: "#0275d8",
            color: "white",
            border: "1px solid transparent",
            borderRadius: "5px",
            padding: "2px 6px",
            marginTop: "10px",
            cursor: "pointer"
          }}
        >
          Select Image
        </label>
        <input
          id="files"
          style={{ visibility: "hidden" }}
          type="file"
          accept=""
          onChange={handleChangeFile("imageLink")}
        />
      </div>

      <textarea
        style={{
          float: "left",
          width: "90%",
          marginLeft: "5%",
          marginTop: "-25px",
          height: "100px"
        }}
        value={description}
        placeholder="Description"
        onChange={e => setDescription(e.target.value)}
      />
      <textarea
        style={{
          float: "left",
          width: "90%",
          marginLeft: "5%",
          marginTop: "10px",
          height: "100px"
        }}
        value={citation}
        placeholder="Citation"
        onChange={e => setCitation(e.target.value)}
      />
      {nature === "newMeme" && (
        <div style={{ float: "left", clear: "both", width: "60%", marginTop: "20px" }}>
          <Search
            category={"Concept"}
            value=""
            onChange={input => handleAddTag(input)}
            placeholder="Add Tags"
          />
        </div>
      )}
      <div style={{ float: "left", clear: "both", marginTop: "10px" }}>
        {tags.map(e => {
          return (
            <div
              style={{
                float: "left",
                padding: "0px 7px",
                fontSize: "14",
                border: "1px solid #bab8b8",
                borderRadius: "5rem",
                marginLeft: "10px",
                cursor: "pointer"
              }}
              onClick={() => {
                removeTag(e);
              }}
            >
              {e.slug}
              <i className="fas fa-times" style={{ marginLeft: "5px", color: "red" }}></i>
            </div>
          );
        })}
      </div>
      <button
        type="button"
        //disabled={images.length < 1 || loading}
        style={styles.btn}
        onClick={onClick}
        className="btn btn-success"
      >
        {loading ? <Loader type="Puff" color="#FFF" height="20" width="20" /> : <span>Submit</span>}
      </button>
      <div>
        <span
          style={{
            backgroundColor: "#0275d8",
            color: "white",
            border: "1px solid transparent",
            borderRadius: "5px",
            padding: "6px"
          }}
        >
          Preview:
        </span>
        <div>{image && <img src={image} style={styles.image} alt="meme upload icon " />}</div>
        <div style={{ marginLeft: "90px", maxWidth: "120px" }}>{description}</div>
      </div>
    </div>
  );
};

MemesUpload.propTypes = {
  loading: PropTypes.bool.isRequired,
  uploadSuccess: PropTypes.bool.isRequired,
  uploadFail: PropTypes.bool.isRequired,
  memesPage: PropTypes.bool,
  selectedOption: PropTypes.object,
  user: PropTypes.object.isRequired,
  uploadMemes: PropTypes.func.isRequired,
  updateImageMeme: PropTypes.func.isRequired

  //setSelected: PropTypes.func.isRequired
};

export default MemesUpload;

const styles = {
  submit: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  messageWrappper: {
    margin: "25px 0px 25px 75px",
    color: "red",
    fontWeight: "bold"
  },
  imageWrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  image: {
    maxWidth: 100,
    marginLeft: 100
  },
  btn: {
    width: "100%",
    margin: "20px 0px"
  }
};
