export const SAVE_MEMES = "SAVE_MEMES";
export const SAVE_MEMES_WITH_TAGS = "SAVE_MEMES_WITH_TAGS";
export const MEME_PAGE_MEMES = "MEME_PAGE_MEMES";
export const MEMES_UPLOADING = "MEMES_UPLOADING";
export const MEMES_SUCCESS = "MEMES_SUCCESS";
export const MEMES_FAILED = "MEMES_FAILED";
export const SET_OFFSET = "SET_OFFSET";
export const MEMES_REQUEST = "MEMES_REQUEST";
export const CONCEPT_MEMES_REQUEST = "CONCEPT_MEMES_REQUEST";
export const KEYWORD_MEMES_REQUEST = "KEYWORD_MEMES_REQUEST";
export const SET_MEMES_COUNT = "SET_MEMES_COUNT";
export const RANDOMIZE_MEMES = "RANDOMIZE_MEMES";
export const MEMES_CONCEPTS = "MEMES_CONCEPTS";
export const MEMES_BOTTOM_CONCEPTS = "MEMES_BOTTOM_CONCEPTS";
export const MEMES_TYPE_COUNT = "MEMES_TYPE_COUNT";
