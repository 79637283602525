import React, { useState, useEffect } from "react";
import Related from "../../../images/imageIcons/related.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as navbarActions from "../../../actions/navbaractions";
import * as actions from "../../../actions/conceptsActions";
import { getConceptItem } from "../../../helpers/conceptHelpers";
import { getRelatedConcepts, getTempConcepts } from "../../../selectors";
import { getClusterItem } from "../../../helpers/cluster";
import { clusterCategory, conceptCategory } from "../../../constants/global";
import ToolTip from "react-portal-tooltip";
import TempConcepts from "./tempConcepts";
import ConceptsList from "./conceptsList";
import $ from "jquery";
import "./firstCol.css";
import { DARK_RED } from "../../../constants/colors";
import history from "../../../router/history";
import { reloadPage } from "../../../helpers/navbar";
import ReactTooltip from "react-tooltip";

const RelatedConcepts = props => {
  const { relatedConcepts, selectedOption, tempConcepts, isAdmin, drawerMode } = props;

  const currentConcept = window.location.href.split("/")[4];

  const preservedConcept = relatedConcepts.filter(elm => elm.slug === currentConcept.toLowerCase());
  const remainingConcepts = relatedConcepts.filter(
    elm => elm.slug !== currentConcept.toLowerCase()
  );

  const sortedConcepts = [...preservedConcept, ...remainingConcepts];

  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(false);
  const [time, setTime] = useState(null);
  let tooltipEnter = false;

  const onListClick = concept => {
    const item = getConceptItem(concept);
    props.setSelectedItem(item);
  };

  const clusterClick = () => {
    if (selectedOption.category === clusterCategory) {
      reloadPage();
    } else {
      selectedOption.name = selectedOption.value;
      const item = getClusterItem(selectedOption, clusterCategory);
      props.setSelectedItem(item);
    }
  };

  const conceptClick = () => {
    if (selectedOption.category === conceptCategory) {
      props.removeSubFilter();
      reloadPage();
    } else {
      selectedOption.name = selectedOption.value;
      const item = getConceptItem(selectedOption);
      props.setSelectedItem(item);
    }
  };

  const handleReciprocalClick = concept => {
    concept.reciprocalExist
      ? props.removeReciprocal(selectedOption.id, concept.id)
      : props.addReciprocal(selectedOption.id, concept.id);
  };

  const handleTempReciprocalClick = concept => {
    concept.value = concept.name;
    concept.label = concept.name;
    concept.reciprocalExist ? props.removeClusterItem(concept.id) : props.addClusterItem(concept);
  };

  const handleListMouseEnter = concept => {
    if (isAdmin) {
      setId(concept.id);
      props.findTempConcepts(concept.id);
      setShow(true);
    }
  };

  const handleListMouseLeave = () => {
    if (isAdmin) {
      setShow(false);
    }
  };

  const handleMouseLeave = () => {
    setTime(
      setTimeout(() => {
        if (!tooltipEnter) {
          setActive(false);
        }
      }, 500)
    );
  };

  const handleMouseEnter = () => {
    // props.getRelatedConcepts(selectedOption.id);
  };

  // useEffect(()=>{
  //     props.getRelatedConcepts(selectedOption.id)
  // }, [])

  const toolTipMouseEnter = () => {
    setShow(true);
    tooltipEnter = true;
    handleMouseEnter();
  };

  const handleTooltipMouseLeave = () => {
    tooltipEnter = false;
    setShow(false);
    handleMouseLeave();
  };

  const handleRelatedClick = () => {
    if (selectedOption.category !== clusterCategory) {
      history.push(`/${clusterCategory}/${selectedOption.slug}`);
    } else {
      reloadPage();
    }
  };
  const margintop = () => {
    const scroll = window.pageYOffset;
    return scroll - window.innerHeight / 3;
  };

  // $(".ToolTipPortal")
  //       .children()
  //       .css({  left:'15%' });

  let title = selectedOption.value ? selectedOption.value.toUpperCase() : "";

  let isCluster = selectedOption.category === clusterCategory;

  return (
    <div>
      <ReactTooltip
        id={`concept${id}`}
        place="right"
        effect="solid"
        className="extraClass"
        getContent={dataTip => {
          return (
            <TempConcepts
              concepts={tempConcepts}
              isAdmin={isAdmin}
              onMouseEnter={toolTipMouseEnter}
              onMouseLeave={handleTooltipMouseLeave}
              handleTempReciprocalClick={handleTempReciprocalClick}
            />
          );
        }}
      />
      {drawerMode && (
        <>
          {/* <ConceptsList
            isAdmin={isAdmin}
            addClusterItem={props.addClusterItem}
            concepts={relatedConcepts}
            handleListMouseEnter={handleListMouseEnter}
            handleListMouseLeave={handleListMouseLeave}
            handleReciprocalClick={handleReciprocalClick}
            removeClusterItem={props.removeClusterItem}
            onNameClick={onListClick}
          /> */}

          {/* <ToolTip active={show} parent={`#concept${id}`} position="right" arrow="center">
            <div style={{top: 200}}>
            <TempConcepts
              concepts={tempConcepts}
              isAdmin={isAdmin}
              onMouseEnter={toolTipMouseEnter}
              onMouseLeave={handleTooltipMouseLeave}
              handleTempReciprocalClick={handleTempReciprocalClick}
            />
            </div>
          </ToolTip> */}
        </>
      )}
      <div className="tabWrapper">
        {/* <center>
                    <p>
                        <b>{selectedOption.value}</b>
                    </p>
                </center>
                <center>
                    <p>Related Terms</p>
                </center> */}
        <br />
        <ConceptsList
          isAdmin={isAdmin}
          addClusterItem={props.addClusterItem}
          concepts={sortedConcepts}
          handleListMouseEnter={handleListMouseEnter}
          handleListMouseLeave={handleListMouseLeave}
          handleReciprocalClick={handleReciprocalClick}
          removeClusterItem={props.removeClusterItem}
          onNameClick={onListClick}
        />

        {/* <ToolTip active={show} parent={`#concept${id}`} position="right" arrow="center">
          <TempConcepts
            concepts={tempConcepts}
            isAdmin={isAdmin}
            onMouseEnter={toolTipMouseEnter}
            onMouseLeave={handleTooltipMouseLeave}
            handleTempReciprocalClick={handleTempReciprocalClick}
          />
        </ToolTip> */}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedItem: bindActionCreators(navbarActions.setSelectedItem, dispatch),
    removeReciprocal: bindActionCreators(actions.removeReciprocal, dispatch),
    addReciprocal: bindActionCreators(actions.addReciprocal, dispatch),
    removeClusterItem: bindActionCreators(actions.removeClusterItem, dispatch),
    addClusterItem: bindActionCreators(actions.addClusterItem, dispatch),
    findTempConcepts: bindActionCreators(actions.findTempConcepts, dispatch),
    removeSubFilter: bindActionCreators(navbarActions.removeSubFilter, dispatch),
    getRelatedConcepts: bindActionCreators(actions.getRelatedConcepts, dispatch)
  };
};

const mapStateToProps = state => {
  return {
    relatedConcepts: getRelatedConcepts(state),
    tempConcepts: getTempConcepts(state),
    selectedOption: state.navbar.selectedOption,
    isAdmin: state.auth.user.isAdmin
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedConcepts);

const styles = {
  btnWrapper: {
    display: "flex"
  }
};
