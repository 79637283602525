import validator from "validator";
import isEmpty from "lodash/isEmpty";
export default function (signupData) {
    let errors = {};
    if (validator.isEmpty(signupData.firstname)) {
        errors.firstname = "*First Name is required!";
    }
    if (validator.isEmpty(signupData.lastname)) {
        errors.lastname = "*Last Name is required!";
    }
    if (validator.isEmpty(signupData.email)) {
        errors.email = "*Email is required!";
    }
    if (!validator.isEmail(signupData.email)) {
        errors.email = "*Email is invalid!"
    }
    return {
        errors,
        isValid: isEmpty(errors),
    }
}