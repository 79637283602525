import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import $ from 'jquery'
import './drawer.css'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeAllDrawer } from '../../../actions/mobile'

const Drawer = (props) => {

    const { open, direction } = props



    useEffect(() => {
        if(open)
        {
            $(document).on('click', (e) => {
                if (e.target.id == "my-drawer" || $(e.target).parents("#my-drawer").length) {
                  } else {
                    props.closeAllDrawer()
                  }
            })
            $(document).on('scroll',  () => {
                props.closeAllDrawer()
            })
            
        }
        else{
            $(document).off('click')
            $(document).off('scroll') 
        }
        return () => {
            $(document).off('click')
            $(document).off('scroll') 
        }
    }, [open])

    return (
        <div id="my-drawer" style={styles.drawerStyles(open, direction)}>
            <div id="drawer-header">
                <i
                    className="fa fa-remove" 
                    onClick={props.handleClose}
                />
            </div>
            <div id="drawer-content">
                {props.children}
            </div>
        </div>
    );
};

const styles = {
    drawerStyles: (open, direction) => {
        let style = direction === 'right'? { 
            transform: open? 'translateX(20vw)': 'translateX(100vw)'
        }: {
            transform: open? 'translateX(-13px)': 'translateX(-100vw)'
        }
        return style
    }
}

Drawer.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    direction: PropTypes.oneOf(['left', 'right'])
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeAllDrawer: bindActionCreators(closeAllDrawer, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(React.memo(Drawer))