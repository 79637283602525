import React, { useState } from 'react'
import {resizedImageUrl} from '../../../../../components/utills/common/imageResize';
const Image = props => {
    const [image, setImage] = useState(false)
    const { link } = props
    return (
        <div>
            <img
                onError={() => { setImage(false) }}
                onLoad={() => { setImage(true) }}
                src={resizedImageUrl(link, 20, 20)}
                style={{ display: image ? 'inline-block' : 'none' }}
                className="rounded-circle"
                alt='author'
            />
            {image &&
                <i className="fa fa-user-circle-o user-circle" ></i>
            }
        </div>
    )
}

export default Image
