import axios from "axios";
import * as constants from "../constants/memes";
import * as navbarConstants from "../constants/navbar";
import { getPageMemesCondition } from "../helpers/memes";
import { removeDuplicate } from "../helpers/common";
import { dataPagination } from "../helpers/navbar";
import { resetSecondCol } from "../helpers/scrolling";
import { toast } from "react-toastify";
import memes from "../reducers/memes";
const BASE_URL = `${process.env.REACT_APP_SERVER}/api/rest/v1`;

export function handleWackyWareSubmit(newWackyPage) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const memes = state.memes.memes;
      const user = state.auth.user;

      const response = await axios.post(`${BASE_URL}/wackyWares`, newWackyPage);
      if (response.status === 201) {
        toast.success(response.data.msg);
      }
    } catch (e) {}
  };
}
