import React, { Component, Suspense, useEffect } from 'react';
import './App.css';
import { Router, Route, Switch } from 'react-router-dom';
import TopNavBar from "./components/TopNavBar/TopNavBar";
import NavbarTitle from "./components/TopNavBar/navChild/navbarTitle";
import MobileNavBar from "./components/TopNavBar/MobileNavbar";
import { connect } from 'react-redux';
import Title from './components/Title/Title';
import history from './router/history'
import AppRoutes from './router/router'
import Loading from './components/utills/ReactLoading/Loading'
import { bindActionCreators } from 'redux';
import { setSelectedItem } from './actions/navbaractions';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from "react-helmet";


class App extends Component {
  state =  {
    open: false
  }
  componentDidMount(){
    const advancedMatching = { em: 'admin@v1.dictionaryish.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init('490145554880598', advancedMatching, options);

    ReactPixel.pageView(); // For tracking page view
    // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
    // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
  }
  render() {
    const { open } = this.state
    return (
      <Router history={history}>
        <>
        <Helmet>
          <meta property="og:updated_time" content={Date.now()} />
          <meta property="og:image" content="https://v1.dictionaryish.com/images/dictionary-share.png" />
          <meta property="og:url" content="https://v1.dictionaryish.com/images/dictionary-share.png" />
          <meta property="fb:app_id" content="299894923804469" />
        </Helmet>
          <Loading />
          <Title />
          <NavbarTitle />
          <TopNavBar />
          <MobileNavBar
            handle ={this.props.setSelectedItem}
          />
          <Suspense fallback={<div></div>}>
            <Switch>
              {AppRoutes && AppRoutes.map((route, index) => {
                return <Route key={index} path={route.path} exact component={route.component} />
              })}
            </Switch>
          </Suspense>
        </>
        </Router>
    );
  }
}
function mapStatToProps(state) {
  return {
    auth: state.auth,
    concepts: state.navbar.concepts
  }
}

const mapDispatchTopProps = dispatch => {
  return {
    setSelectedItem: bindActionCreators(setSelectedItem, dispatch)
  }
}

export default connect(mapStatToProps, mapDispatchTopProps)(App);
