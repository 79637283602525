import React from "react";
import PropTypes from "prop-types";
const EssentialBooks = props => {
  return (
    <div style={{    border: "4px solid black",borderRadius: 6}}>
      {/* <button type="button" onClick={props.onClick} className="btn btn-dark">About This Thinker </button> */}
      <button type="button" onClick={props.onClick} className="btn btn-outline-light" style={{    backgroundColor: "white",color: "black"}}>On This Thinker</button>
    </div>
  );
};

EssentialBooks.propTypes = {
  onClick: PropTypes.func.isRequired
};


export default EssentialBooks;

