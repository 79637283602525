export const TOTAL_TERMS = "TOTAL_TERMS";
export const SET_MEMES_FILTER = "SET_MEMES_FILTER";
export const SET_INSIGHTS = "SET_INSIGHTS";
export const SET_SELECTED_OPTION = "SET_SELECTED_OPTION";
export const PERSPECTIVE_LOADING = "PERSPECTIVE_LOADING";
export const BLOCK_PERSPECTIVE_LOADING = "BLOCK_PERSPECTIVE_LOADING";
export const SAVE_RECENT_PERSPECTIVES = "SAVE_RECENT_PERSPECTIVES";
export const PERSPECTIVES = "PERSPECTVES";
export const EMPTY_PAGE = "EMPTY_PAGE";
export const CONCEPTS = "CONCEPTS";
export const PERSPECTIVE_CONCEPTS = "PERSPECTIVE_CONCEPTS";
export const AUTHOR_CONCEPTS = "AUTHOR_CONCEPTS";
export const ALL_CONCEPTS_SINGLE_BOOK = "ALL_CONCEPTS_SINGLE_BOOK";
export const AUTHORS = "AUTHORS";
export const BOOKS = "BOOKS";
export const SINGLE_BOOK = "SINGLE_BOOK";
export const SINGLE_BOOK_AUTHOR = "SINGLE_BOOK_AUTHOR";
export const SINGLE_BOOK_ACTIVE_STATE = "SINGLE_BOOK_ACTIVE_STATE";
export const SINGLE_BOOK_PAGE_NUMBER = "SINGLE_BOOK_PAGE_NUMBER";
export const SIMILAR_BOOKS_COLLECTION = "SIMILAR_BOOKS_COLLECTION";
export const SIMILAR_BOOKS_CONCPET = "SIMILAR_BOOKS_CONCPET";
export const SET_BOOKS_SORTING = "SET_BOOKS_SORTING";
export const BOOK_API = "BOOK_API";
export const LOADING_START = "LOADING_START";
export const CONCEPT_LOADING_START = "CONCEPT_LOADING_START";
export const CONCEPT_LOADING_END = "CONCEPT_LOADING_END";
export const LOADING_END = "LOADING_END";
export const INITIAL_LOADING = "INITIAL_LOADING";
export const NEW_PERSPECTIVES = "NEW_PERSPECTIVES";
export const SET_TIME_STAMP = "SET_TIME_STAMP";
export const SET_SORTING = "SET_SORTING";
export const SET_ARCHIVE_DATA = "SET_ARCHIVE_DATA";
export const SET_MODE = "SET_MODE";
export const LIKE_MODE = "LIKE_MODE";
export const NEWS_MODE = "NEWS_MODE";
export const SET_NEWS = "SET_NEWS";
export const ARCHIVE_MODE = "ARCHIVE_MODE";
export const HIDE_PAGES = "HIDE_PAGES";
export const ADD_FAVOURITE_CONCEPT = "ADD_FAVOURITE_CONCEPT";
export const ADD_FAVOURITE_AUTHOR = "ADD_FAVOURITE_AUTHOR";
export const EMPTY_FAVOURITES = "EMPTY_FAVOURITES";
export const FEED_MODE = "FEED_MODE";
export const SET_SELECTED_NEWS = "SET_SELECTED_NEWS";
export const SEARCH_LOADING = "SEARCH_LOADING";
export const SET_PRIMARY_MODE = "SET_PRIMARY_MODE";
export const SELECTED_CONCEPT_ID = "SELECTED_CONCEPT_ID";
export const LOGOUT = "LOGOUT";
export const EMAIL_NOT_VERIFIED_ERROR = "EMAIL_NOT_VERIFIED_ERROR";
export const VERIFICATION_EMAIL_SENT = "VERIFICATION_EMAIL_SENT";
export const SIMILAR_ALPHABETS = "SIMILAR_ALPHABETS";
export const SET_SELECTED_COLLECTION = "SET_SELECTED_COLLECTION";
export const BOOKS_SORT_TYPE = "BOOK_SORT_TYPE";
export const SET_NEW_BIE = "SET_NEW_BIE";
export const SET_BOOKS_AUTHOR_FILTER = "SET_BOOKS_AUTHOR_FILTER";
export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_SIGNUP_OPEN = "MODAL_SIGNUP_OPEN";
export const MODAL = "MODAL";
export const THANKS_MODAL = "THANKS_MODAL";
export const UPDATE_FAVORITE_MEMES = "UPDATE_FAVORITE_MEMES";
export const IMAGE_UPLOADING = "IMAGE_UPLOADING";
export const PERSPECTIVE_LENGTH = "PERSPECTIVE_LENGTH";
export const SET_BOOKS_OFFSET = "SET_BOOKS_OFFSET";
export const SET_DEFAULT_ARCHIVE = "SET_DEFAULT_ARCHIVE";
export const SINGLE_BOOK_RELATED_ALL_DATA_SUCCESS = "SINGLE_BOOK_RELATED_ALL_DATA_SUCCESS";
export const SINGLE_BOOK_RELATED_ALL_PERSPECTIVE_CONCEPTS_SUCCESS =
  "SINGLE_BOOK_RELATED_ALL_PERSPECTIVE_CONCEPTS_SUCCESS";
export const ADD_PERSPECTIVES_LOADING = "ADD_PERSPECTIVES_LOADING";
export const UPDATE_USER = "UPDATE_USER";
export const VIRAL_KEYWORDS = "VIRAL_KEYWORDS";
export const LOCK = "LOCK";
export const KEYWORDS = "KEYWORDS";
export const THIRD_KEY = "THIRD_KEY";
export const IS_AUTHOR = "IS_AUTHOR";
export const SELECTED_AUTHOR = "SELECTED_AUTHOR";
export const COMBINE_PERSPECTIVE_MEME = "COMBINE_PERSPECTIVE_MEME";
export const EDIT_CONCEPT = "EDIT_CONCEPT";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const SET_PROVERB = "SET_PROVERB";
export const USER_DEFAULT_OPTION = "USER_DEFAULT_OPTION";
export const RELATED_AUTHOR = "RELATED_AUTHOR";



