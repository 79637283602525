export function getClusterItem(cluster, category) {
  return {
    id: cluster.id,
    value: cluster.name,
    label: cluster.name,
    pictureLink: cluster.pictureLink,
    slug: cluster.slug,
    category,
    count: cluster.count,
    memeCount: cluster.memeCount,
    onlyImageMemes: cluster.onlyImageMemes,
    author: cluster.author,
    authorGroup: cluster.authorGroup,
    booksCollection: cluster.BooksCollectionId //for dictionary-of category
  };
}
