import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ThirdColCluster from "../Cluster/cols/thirdCol";
import ThirdCol from "../Concept/cols/thirdCol";
import ConceptSidebar from "../utills/conceptsSideBar";
import * as scroll from "../../helpers/scrolling";
import OutsideClickHandler from "react-outside-click-handler";
import * as memeActions from "../../actions/memes";
import * as posActions from "../../actions/pos";
import * as actions from "../../actions/conceptsActions";
import * as clusterActions from "../../actions/conceptClustersActions";
import * as navbarActions from "../../actions/navbaractions";

import MoveToAuthor from "../Cluster/cols/movetoauthors";
import { Col, Row, Button, Input } from "reactstrap";

import history from "../../router/history";

import {
  authorCategory,
  SORT_BY_LIKES,
  factCategory,
  conceptCategory,
  keywordCategory
} from "../../constants/global";
import { listenToDocumentSizeChangeOnPercepectiveClick } from "../../helpers/navbar";
import thirdCol from "../Concept/cols/thirdCol";
import ToolTip from "react-portal-tooltip";

const ThirdColButton = props => {
  const {
    sorting,
    selectedOption,
    subFilter,
    viralKeywords,
    keywords,
    lock,
    editConcept,
    user
  } = props;
  const [selectedButton, setSelectedButton] = useState("tags");
  const [active, setActive] = useState(false);
  const [edit, setEdit] = useState(null);
  const pageName = window.location.href.split("/")[3];

  let selectedId = subFilter ? (subFilter.type === keywordCategory ? subFilter.id : -1) : -1;

  const onClickHandler = name => {
    setSelectedButton(name);
  };

  const getKeywords = () => {
    if (pageName === "Concepts") {
      let concept = [];

      keywords &&
        keywords.forEach(e => {
          viralKeywords &&
            viralKeywords.forEach(elm => {
              if (e.slug === elm.slug) {
                concept.push({ id: e.id, slug: e.slug, name: e.name, count: e.count + elm.count });
              }
            });
        });

      keywords &&
        keywords.forEach(e => {
          if (concept.some(elm => e.id === elm.id)) {
            return;
          } else {
            concept.push({ id: e.id, slug: e.slug, name: e.name, count: e.count });
          }
        });

      viralKeywords &&
        viralKeywords.forEach(elm => {
          if (concept.some(e => e.id === elm.id)) {
            return;
          } else {
            concept.push({ id: elm.id, slug: elm.slug, name: elm.name, count: elm.count });
          }
        });
      return concept.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return keywords;
    }
  };

  const conceptTagCallBack = (index, concept, popupRoute = false) => {
    props.combineMemePerspective([]);
    if (subFilter && subFilter.type === factCategory) {
      props.setFactsKeywrodFilterId(concept.id);
    } else {
      scroll.resetSecondCol();
      const inputValue = {
        id: concept.id,
        value: concept.name,
        label: concept.name,
        slug: concept.name,
        type: keywordCategory
      };
      props.setSubFilter(inputValue, popupRoute);
      props.perspectivesByFilter(inputValue);
      props.getMemeTypeCount(concept.name, selectedOption.name);
      props.getPerspectivesCount(concept.name);
      props.setOffset(0);
      pageName === "Concepts" &&
        props.fetchMemes({
          concept: selectedOption.label,
          keyword: concept.slug,
          imageMeme: false,
          lock: true,
          toggle: false,
          thirdKey: null,
          urlChange: false,
          pageName: "All"
        });
    }
    listenToDocumentSizeChangeOnPercepectiveClick();
  };

  const aboutThinker = () => {
    history.push(`/${authorCategory}/${selectedOption.slug}`);
  };

  const handlePenClick = () => {
    props.getConcept(selectedOption.id);

    setActive(!active);
  };

  useEffect(() => {
    setEdit(editConcept);
  }, [editConcept]);

  const handlePronounChange = e => {
    setEdit({
      ...edit,
      [e.target.name]: e.target.value
    });
  };

  const handlePronounSubmit = () => {
    if (edit.concept === "") {
      // setError("Concept cannot be empty!");
    } else {
      // setError(null);
      props.PronounUpdate(edit);
      setActive(false);
    }
    // reloadPage()
  };

  return (
    <>
      {/* {selectedOption.author && (
        <div
          className="pb-2"
          style={{
            position: "fixed",
            marginLeft: "2%"
          }}
        >
          <MoveToAuthor onClick={aboutThinker} />
        </div>
      )} */}
      <div
        id={scroll.thirdColId}
        style={{
          marginLeft: "10%",
          width: "12.5%",
          backgroundColor: "white",
          boxShadow: subFilter ? "31px -2px 38px -30px rgba(0,0,0,0.75)" : "none",
          borderRight: "1px solid black"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "4px",
            position: "fixed",
            zIndex: 2
          }}
        >
          <button
            type="button"
            style={
              selectedButton === "tags"
                ? { marginTop: "6vh", border: "1px solid black" }
                : { marginTop: "6vh" }
            }
            class={`btn bg-white text-dark mr-1 ml-1`}
            onClick={() => onClickHandler("tags")}
          >
            <i class="fas fa-tag"></i>
          </button>
          <button
            type="button"
            style={
              selectedButton === "user"
                ? { marginTop: "6vh", border: "1px solid black" }
                : { marginTop: "6vh" }
            }
            class={`btn bg-white text-dark mr-1 ml-1`}
            onClick={() => onClickHandler("user")}
          >
            <i class="fas fa-user"></i>
          </button>
          {user.isAdmin && (
            <button
              id="editPen"
              type="button"
              class={`btn bg-white text-dark mr-1 ml-1`}
              onClick={() => handlePenClick()}
              style={{ marginTop: "6vh" }}
            >
              <i class="fas fa-pen"></i>
            </button>
          )}
          <ToolTip active={active} parent={`#editPen`} position="left" arrow="center">
            <div>
              {/* <Row className="pronoun-edit-row">
                <Col xs={12}>
                  <p style={{ color: "red" }}>{error}</p>
                </Col>
              </Row> */}
              <Row className="pronoun-edit-row" style={{ marginLeft: 20 }}>
                <div>
                  <Col xs={3}>
                    <Input
                      type="radio"
                      name="type"
                      value="Who"
                      checked={edit && edit.type == "Who"}
                      onChange={handlePronounChange}
                    />
                  </Col>
                  <Col style={{ marginLeft: 5 }}>Who</Col>
                </div>
                <div>
                  <Col xs={3}>
                    <Input
                      type="radio"
                      name="type"
                      value="What"
                      checked={edit && edit.type == "What"}
                      onChange={handlePronounChange}
                    />
                  </Col>
                  <Col style={{ marginLeft: 5 }}>What</Col>
                </div>
              </Row>
              <Row className="pronoun-edit-row">
                <Col xs={12}>
                  <Input
                    name="pronoun"
                    onChange={handlePronounChange}
                    value={edit && edit.pronoun}
                    style={{ borderRadius: 20 }}
                    placeholder="Pronoun"
                  />
                </Col>
              </Row>
              <Row className="pronoun-edit-row">
                <Col xs={12}>
                  <Input
                    name="name"
                    onChange={handlePronounChange}
                    value={edit && edit.name}
                    style={{ borderRadius: 20 }}
                    placeholder="Concept"
                  />
                </Col>
              </Row>
              <Row className="pronoun-edit-row">
                <Col xs={12} className="d-flex flex-row-reverse">
                  <Button
                    type="button"
                    onClick={handlePronounSubmit}
                    color="primary"
                    style={{ width: "100%" }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </ToolTip>
        </div>
        {selectedButton === "user" ? (
          lock ? (
            <ThirdColCluster />
          ) : (
            <ThirdCol />
          )
        ) : (
          <div
            style={{
              // width: "40%",
              marginTop: "50px"
              // backgroundColor: "white",
            }}
          >
            <ConceptSidebar
              concepts={getKeywords()}
              conceptCallBack={conceptTagCallBack}
              opacity={sorting === SORT_BY_LIKES}
              showCount={true}
              isKeywords={true}
              selectedId={selectedId}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchMemes: bindActionCreators(memeActions.fetchMemetagMemes, dispatch),
  perspectivesByFilter: bindActionCreators(actions.perspectivesByFilter, dispatch),
  setSubFilter: bindActionCreators(navbarActions.setSubFilter, dispatch),
  getConcept: bindActionCreators(navbarActions.getConcept, dispatch),
  setOffset: bindActionCreators(memeActions.setOffset, dispatch),
  combineMemePerspective: bindActionCreators(clusterActions.combineMemePerspective, dispatch),
  setFactsKeywrodFilterId: bindActionCreators(actions.setFactsKeywrodFilterId, dispatch),
  getMemeTypeCount: bindActionCreators(memeActions.getMemeTypeCount, dispatch),
  getPerspectivesCount: bindActionCreators(actions.getPerspectivesCount, dispatch),
  PronounUpdate: bindActionCreators(navbarActions.handlePronounUpdate, dispatch)
});

const mapStateToProps = state => ({
  selectedOption: state.navbar.selectedOption,
  keywords: state.navbar.keywords,
  user: state.auth.user,
  viralKeywords: state.navbar.viralKeywords,
  sorting: state.author.sorting,
  subFilter: state.navbar.subFilter,
  lock: state.navbar.lock,
  editConcept: state.navbar.editConcept
});

export default connect(mapStateToProps, mapDispatchToProps)(ThirdColButton);

const styles = {
  icons: {
    cursor: "pointer",
    borderRadius: "50%",
    margin: "1px"
  },
  iconSpan: {
    width: "20px"
  }
  // thirdColDiv: {
  //   marginLeft: "10%",
  //   width: "12.5%",
  //   backgroundColor: "white",
  //   marginTop: "35px",

  // }
};
