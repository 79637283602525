import React from "react";
import Related from "../../../images/imageIcons/relatedAuthors.png";

const RelatedsButton = props => {
  const { active, setActive } = props;
  return (
    <div id="clusterContainer" onMouseLeave={props.handleMouseLeave}>
      <div id="clusterOuterWrapper">
        <div
          id="clusterButton"
          onClick={() => setActive(!active)}
          onMouseEnter={props.handleMouseEnter}
          style={{ width: 135 }}
        >
          <img src={Related} style={{ width: 18 }} alt="cluster Icon" />
          related authors
        </div>
      </div>
    </div>
  );
};

export default RelatedsButton;
