import { normalize, schema } from 'normalizr';

export function normalizeAuthorCluster(data) {
    const author = new schema.Entity('authors');
    const authorCluster = new schema.Entity('authorGroups', {
        Authors: [author],
    });
    const normalizedData = normalize(data, [authorCluster]);
    return normalizedData
}
