import store from "../../../store/configureStore";
import chroma from "chroma-js";

// Search styles
//
// ....................................
//         Important! Note
//
// Search Styles which reduce distance between search and dropdonw is in  TopNavbar.css
// which looks like this ------->   .css-kj6f9i-menu, #header .css-kj6f9i-menu
//
//
// ....................................

//const grammar = getCurrentPosGrammarType();

const getColorRelatedConcept = data => {
  if (data && data.category === "Authors") {
    return "red";
  } else if (data && data.category === "Memetag") {
    if (data.value === "meme") {
      return "#c7c313";
    } else {
      return `${data.color}`;
    }
  } else {
    const currentKeyword = window.location.href.split("=")[1];
    const pageName = window.location.href.split("/")[3];
    switch (currentKeyword ? currentKeyword : pageName) {
      case "trivia":
        return "#288133";
      case "one-liner":
        return "#C78210";
      case "dad-joke":
        return "#D3665B";
      case "meme":
        return "#c7c313";
      case "memes":
        return "#6666ff";
      default:
        return "black";
    }
  }
};

const borderColorOnSearch = () => {
  const currentKeyword = window.location.href.split("=")[1];
  const pageName = window.location.href.split("/")[3];
  if (
    (currentKeyword && currentKeyword === "trivia") ||
    currentKeyword === "one-liner" ||
    currentKeyword === "dad-joke" ||
    currentKeyword === "meme"
  ) {
    return "none";
  } else if (
    (pageName && pageName === "trivia") ||
    pageName === "one-liner" ||
    pageName === "dad-joke" ||
    pageName === "meme" ||
    pageName === "memes"
  ) {
    return "none";
  } else {
    return "default";
  }
};

const borderColorOnFocus = () => {
  const currentKeyword = window.location.href.split("=")[1];
  const pageName = window.location.href.split("/")[3];
  if (
    (currentKeyword && currentKeyword === "trivia") ||
    currentKeyword === "one-liner" ||
    currentKeyword === "dad-joke" ||
    currentKeyword === "meme" ||
    currentKeyword === "memes"
  ) {
    return "none";
  } else if (
    (pageName && pageName === "trivia") ||
    pageName === "one-liner" ||
    pageName === "dad-joke" ||
    pageName === "meme" ||
    pageName === "memes"
  ) {
    return "none";
  } else {
    return "default";
  }
};

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10
  }
});

export const headerStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "20px",
    width: "100%",
    border: "1px solid black"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default"
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot(), fontSize: 11 }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
};

export const searchStyles = {
  DropdownIndicator: () => null,
  indicatorSeparator: () => {},
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "20px",
    zIndex: 5,
    boxShadow: "none",
    border: isFocused ? borderColorOnFocus() : borderColorOnSearch()
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color ? data.color : data.colorCode);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : getColorRelatedConcept(data),
      cursor: isDisabled ? "not-allowed" : "default"
    };
  },

  input: (styles, { data }) => {
    return { ...styles, ...dot(), color: getColorRelatedConcept(data) };
  },
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
};
