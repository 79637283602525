import * as autherClusterSchema from "../schema/authorClusterSchema";
import * as navbarConstants from "../constants/navbar";
import * as services from "../services/authorGroups";
import * as perspectiveServices from "../services/perspectiveServices";
import * as conceptServices from "../services/concept";
import * as constants from "../constants/authorGroup";
import * as conceptConstants from "../constants/concepts";
import {
  SORT_BY_CONCEPTS,
  SORT_BY_LIKES,
  SET_VIEWPORT,
  conceptCategory,
  authorCategory,
  groupsCategory,
  newClusterCategory
} from "../constants/global";
import { getGroupItem } from "../helpers/authorGroups";
import { sortPerspectivesByLove } from "../helpers/perspectiveHelpers";
import { sortAuthors } from "../helpers/authorHelpers";
import { RANDOMIZE_MEMES } from "../constants/memes";

export function flushAuthorClusterState() {
  return dispatch => {
    dispatch({
      type: "FLUSH_AUTHOR_CLUSTER"
    });
  };
}

export function getAuthorCluster(name) {
  return async dispatch => {
    try {
      dispatch({
        type: constants.GROUP_LOADING,
        payload: true,
        initialLoading: false
      });
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: false
      });
      const response = await services.groupDetailByName(name);
      if (response.status === 200) {
        const data = response.data.data;
        let concepts = data.concepts;
        const perspectives = data.perspectives;
        let count = data.count;
        const detail = data.detail;
        let responseAuthorGroups = data.authorGroups;
        let normalizedData = autherClusterSchema.normalizeAuthorCluster(responseAuthorGroups);
        let authors = normalizedData.entities.authors;
        let authorGroups = normalizedData.entities.authorGroups;

        dispatch({
          type: constants.GROUP_RELATED_CONCEPT,
          payload: data.relatedConceptExist
        });

        dispatch({
          type: constants.AUTHOR_GROUPS_GROUPS,
          payload: { authorGroups }
        });
        // if found perspectives first time this means concept with group name exist so move it to top

        dispatch({
          type: navbarConstants.CONCEPTS,
          payload: concepts
        });

        dispatch({
          type: navbarConstants.AUTHORS,
          payload: sortAuthors(Object.values(authors))
        });
        dispatch({
          type: constants.GROUP_CONCEPT_INDEX,
          index: 5,
          topIndex: 0
        });
        dispatch({
          type: constants.GROUP_OFFSET,
          payload: 1
        });
        dispatch({
          type: constants.SET_GROUP_SORTING,
          payload: SORT_BY_CONCEPTS
        });

        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: perspectives
        });
        dispatch({
          type: navbarConstants.SET_INSIGHTS,
          payload: count
        });

        if (data.relatedConceptExist) {
          const relatedConcept = data.relatedConcept;
          dispatch({
            type: navbarConstants.SELECTED_CONCEPT_ID,
            payload: data.relatedConcept.id
          });
          if (relatedConcept.BooksCollectionId) {
            detail.booksCollection = relatedConcept.BooksCollectionId;
            conceptServices
              .fetchBooksAndAuthors(relatedConcept.BooksCollectionId)
              .then(response => {
                dispatch({
                  type: navbarConstants.BOOKS,
                  payload: response.data.books
                });
              })
              .catch(e => {
                dispatch({
                  type: navbarConstants.BOOKS,
                  payload: []
                });
              });
          } else {
            // clearing old books
            dispatch({
              type: navbarConstants.BOOKS,
              payload: []
            });
          }
        }
        dispatch({
          type: navbarConstants.SET_SELECTED_OPTION,
          payload: getGroupItem(detail)
        });
        dispatch({
          type: constants.GROUP_LOADING,
          payload: false,
          initialLoading: true
        });
      } else {
        dispatch({
          type: navbarConstants.HIDE_PAGES,
          payload: true
        });
        dispatch({
          type: constants.GROUP_LOADING,
          payload: false,
          initialLoading: false
        });
      }
    } catch (e) {
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: true
      });
      dispatch({
        type: constants.GROUP_LOADING,
        payload: false,
        initialLoading: false
      });
    }
  };
}

export function setGroupLoading(loading) {
  return dispatch => {
    dispatch({
      type: constants.GROUP_LOADING,
      payload: loading,
      initialLoading: false
    });
  };
}

export function handleDownPagination() {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: true
      });
      const state = getState();
      const { sorting } = state.autherCluster;
      let data = [];
      const index = state.autherCluster.conceptIndex;
      const topIndex = state.autherCluster.conceptTopIndex;
      const { concepts, authors } = state.navbar;
      const authorIds = authors.map(x => x.id);

      if (sorting === SORT_BY_CONCEPTS) {
        const array = concepts.slice(index, index + 5);
        const ids = array.map(x => x.id);
        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
          authorIds,
          ids
        );
        if (response.status === 200) {
          data = response.data.data;
          dispatch({
            type: constants.GROUP_CONCEPT_INDEX,
            index: index + 5,
            topIndex: topIndex
          });
        }
      } else {
        const { offset, limit } = state.autherCluster;
        const conceptIds = concepts.map(x => x.id);
        const response = await perspectiveServices.getPerspectivesByLikes(
          authorIds,
          conceptIds,
          offset,
          limit
        );
        if (response.status === 200) {
          data = response.data.data;
          dispatch({
            type: constants.GROUP_OFFSET,
            payload: 1 + offset
          });
        }
      }
      let perspectives = state.navbar.perspectives;
      let newPerspectives = [...perspectives, ...data];
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: newPerspectives
      });

      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: false
      });
    } catch (e) {
      console.log("errro is ", e);
    }
  };
}

export function conceptCallBack(index, concept) {
  return async (dispatch, getState) => {
    dispatch({
      type: navbarConstants.LOADING_START
    });
    try {
      const state = getState();
      const {
        navbar: { concepts, authors }
      } = state;

      const authorIds = authors.map(x => x.id);
      const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(authorIds, [
        concept.id
      ]);
      if (response.status === 200) {
        const perspectives = response.data.data;
        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: perspectives
        });
        dispatch({
          type: constants.SET_GROUP_SORTING,
          payload: SORT_BY_CONCEPTS
        });
        dispatch({
          type: navbarConstants.SELECTED_CONCEPT_ID,
          payload: concept.id
        });
        dispatch({
          type: SET_VIEWPORT,
          payload: concept.id
        });
      }
      dispatch({
        type: RANDOMIZE_MEMES
      });

      const clusters = await conceptServices.getClusterConcepts({
        id: concept.id,
        type: newClusterCategory
      });
      dispatch({
        type: conceptConstants.SET_RELATEDS,
        payload: {
          concepts: clusters.data.data
        }
      });
    } catch (e) {
      console.log("error is ", e);
    }
    dispatch({
      type: navbarConstants.LOADING_END
    });
  };
}

export function handleTopPagination() {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: true
      });

      const state = getState();
      const {
        navbar: { concepts, authors, perspectives },
        autherCluster: { conceptIndex, conceptTopIndex }
      } = state;

      if (conceptTopIndex >= 0) {
        let data = [];
        if (conceptTopIndex - 5 > -1) {
          concepts.slice(conceptTopIndex - 5, conceptTopIndex).forEach(conc => data.push(conc));
        } else {
          concepts.slice(0, conceptTopIndex).map(conc => data.push(conc));
        }
        let ids = data.map(x => x.id);
        const authorIds = authors.map(x => x.id);
        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
          authorIds,
          ids
        );
        if (response.status === 200) {
          data = response.data.data;
        }
        let newPerspectives = [...data, ...perspectives];
        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: newPerspectives
        });
        dispatch({
          type: constants.GROUP_CONCEPT_INDEX,
          index: conceptIndex,
          topIndex: conceptTopIndex - 5
        });
        const prev = concepts[conceptTopIndex];
        dispatch({
          type: SET_VIEWPORT,
          payload: prev.id
        });
      }
      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: false
      });
    } catch (e) {
      console.log("errro is ", e);
    }
  };
}

export function toggleClusterPagination() {
  return async (dispatch, getState) => {
    dispatch({
      type: navbarConstants.LOADING_START
    });
    try {
      const state = getState();
      const {
        autherCluster: { sorting, limit },
        navbar: { concepts, authors }
      } = state;
      let data = [];
      if (sorting === SORT_BY_CONCEPTS) {
        dispatch({
          type: constants.SET_GROUP_SORTING,
          payload: SORT_BY_LIKES
        });
        dispatch({
          type: constants.GROUP_OFFSET,
          payload: 1
        });
        const conceptIds = concepts.map(x => x.id);
        const authorIds = authors.map(x => x.id);
        const response = await perspectiveServices.getPerspectivesByLikes(
          authorIds,
          conceptIds,
          0,
          limit
        );
        if (response.status === 200) {
          data = response.data.data;
        }
      } else {
        const array = concepts.slice(0, 5);
        const ids = array.map(x => x.id);
        const authorIds = authors.map(x => x.id);
        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
          authorIds,
          ids
        );
        if (response.status === 200) {
          data = response.data.data;
          dispatch({
            type: constants.GROUP_CONCEPT_INDEX,
            index: 5,
            topIndex: 0
          });
          dispatch({
            type: constants.SET_GROUP_SORTING,
            payload: SORT_BY_CONCEPTS
          });
        }
      }
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: data
      });
      dispatch({
        type: navbarConstants.SELECTED_CONCEPT_ID,
        payload: -1
      });
    } catch (e) {
      console.log("error is ", e);
    }
    dispatch({
      type: navbarConstants.LOADING_END
    });
  };
}

export function perspectivesByFilter(inputValue) {
  return async (dispatch, getState) => {
    dispatch({
      type: navbarConstants.LOADING_START
    });
    try {
      const state = getState();
      const {
        navbar: { concepts, authors },
        autherCluster: { authorGroups }
      } = state;
      let conceptIds = [];
      let authorIds = [];
      if (inputValue.type === conceptCategory) {
        conceptIds = [inputValue.id];
        authorIds = authors.map(x => x.id);
      }
      if (inputValue.type === authorCategory) {
        conceptIds = concepts.map(x => x.id);
        authorIds = [inputValue.id];
      }
      if (inputValue.type === groupsCategory) {
        const group = authorGroups[inputValue.id];
        conceptIds = concepts.map(x => x.id);
        authorIds = group.Authors;
      }
      const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
        authorIds,
        conceptIds
      );
      const sorted = sortPerspectivesByLove(response.data.data);
      if (response.status === 200) {
        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: sorted
        });
      }
    } catch (e) {
      console.log("error is ", e);
    }
    dispatch({
      type: navbarConstants.LOADING_END
    });
  };
}

export function setGroupSorting(type) {
  return async dispatch => {
    try {
      dispatch({
        type: constants.SET_GROUP_SORTING,
        payload: type
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function deleteAuthorFromGroup(author) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let { authors, selectedOption } = state.navbar;
      const {
        user: { token }
      } = state.auth;
      const response = await services.deleteAuthorFromGroup(selectedOption.id, author.id, token);
      if (response.status === 200) {
        authors = authors.filter(x => x.id !== author.id);
        dispatch({
          type: navbarConstants.AUTHORS,
          payload: [...authors]
        });
      }
    } catch (e) {
      console.log("error is ", e);
    }
  };
}
export function addAuthorToGroup(inputValue) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let { authors, selectedOption } = state.navbar;
      const {
        user: { token }
      } = state.auth;
      const index = authors.findIndex(x => x.id === inputValue.id);
      if (index < 0) {
        const response = await services.addAuthorToGroup(selectedOption.id, inputValue.id, token);
        if (response.status === 200) {
          inputValue.firstName = "";
          inputValue.lastName = inputValue.value;
          authors.unshift(inputValue);

          dispatch({
            type: navbarConstants.AUTHORS,
            payload: [...authors]
          });
        }
      }
    } catch (e) {
      console.log("error is ", e);
    }
  };
}
