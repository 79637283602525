import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import * as scroll from "../../../helpers/scrolling";
import FacebookComment from "./FacebookComment";
import Addsense from "../Addsense/addsense";
import MemeCard from "../../Memes/memeCard";
import TextMemeCard from "../../Memes/textMemeCard";
import AddMeme from "../../TopNavBar/navChild/AddMeme";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions/memes";
import MemeUpload from "../../Memes/memeUpload";
import $ from "jquery";
import PerspectiveLoading from "../ReactLoading/PerspectiveLoading";

// import Disclosure from './Disclosure'
// import Audible from '../Addsense/Audible'

const FourthCol = props => {
  const {
    subFilter,
    selectedOption,
    authorPage,
    memes,
    noMeme,
    memeUpload,
    isAuthenticated,
    fetchLoading,
    loading,
    uploadSuccess,
    uploadFail,
    loadingPage,
    blockPagination,
    memesMode
  } = props;
  const booksPath = window.location.pathname.split("/")[1] == "books";
  const conceptPath = window.location.pathname.split("/")[1] == "Concepts";
  const conceptPath2 = window.location.pathname.split("/")[1] == "concepts";
  const dictionaryPath = window.location.pathname.split("/")[1] == "dictionary";
  const authorPath = window.location.pathname.split("/")[1] == "Authors";
  const proverbsPath = window.location.pathname.split("/")[1] == "proverbs";

  const pageName = window.location.href.split("/")[3];
  const [memeFetchLoading, setMemeFetchLoading] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleDown);
    return () => {
      window.removeEventListener("scroll", handleDown);
    };
  }, [memeFetchLoading]);

  const handleDown = () => {
    $("#fourthCol").on("scroll", function() {
      if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
        setMemeFetchLoading(true);
      }
    });
    if (memeFetchLoading) {
      pageName !== "Concepts" && pageName !== "memes" && props.fetchAllMemes();
      setMemeFetchLoading(false);
    }
  };

  return (
    <Col
      onScroll={scroll.handleFourthWheel}
      style={styles.container(authorPage)}
      id={scroll.fourthColId}
      xs={6}
      sm={6}
      md={3}
      className="right d-none d-md-block"
      style={{ paddingBottom: 150, marginTop: 20 }}
    >
      <FacebookComment
        url={window.location.href}
        showComment={true}
        filter={subFilter}
        title={selectedOption}
      />
      <Ads />

      {/* Keeping it intentionaly. May be again used in future */}

      {/* <Disclosure
                text={`Dictionaryish is reader-supported. 
                By using our product links, 
                you're earning us affiliate commission and 
                helping us to bring you more good content.`}
            /> */}

      {selectedOption.id === 21268 && <BooksAd />}
      <br />
      {/* {props.auth.isAuthenticated && <AddMeme />} */}
      {/* <Audible/> */}
      {!isAuthenticated && (
        <span className="alert alert-info mt-5">Login To Upload your own meme</span>
      )}
      {memes &&
        !noMeme &&
        !conceptPath &&
        !conceptPath2 &&
        !dictionaryPath &&
        !booksPath &&
        !authorPath &&
        !proverbsPath &&
        memes.map((meme, index) => {
          if (index % 4 === 0) {
            return (
              <div key={meme.id}>
                <Ads />
                {!meme.isText ? (
                  <MemeCard meme={meme} key={meme.id} />
                ) : (
                  <TextMemeCard meme={meme} key={meme.id} />
                )}
              </div>
            );
          }
          return (
            <div key={meme.id}>
              {!meme.isText ? (
                <MemeCard meme={meme} key={meme.id} />
              ) : (
                <TextMemeCard meme={meme} key={meme.id} />
              )}
            </div>
          );
        })}
      {/* {memeUpload && isAuthenticated && (
        <MemeUpload
          user={props.user}
          loading={loading}
          uploadSuccess={uploadSuccess}
          uploadFail={uploadFail}
          uploadMemes={props.uploadMemes}
          selectedOption={selectedOption}
          memesPage={true}
          loadingPage={loadingPage}
        />
      )} */}
      <Ads />
      <Ads />
      <br />
      {memes && memes.length > 0 && <PerspectiveLoading perspectiveLoading={fetchLoading} />}
    </Col>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    subFilter: state.navbar.subFilter,
    selectedOption: state.navbar.selectedOption,
    fetchLoading: state.memes.fetchLoading,
    loading: state.memes.loading,
    uploadSuccess: state.memes.uploadSuccess,
    uploadFail: state.memes.uploadFail,
    memes: state.memes.memes,
    auth: state.auth,
    user: state.auth.user,
    blockPagination: state.concepts.blockPagination,
    memesMode: state.mobile.memesMode
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchMemes: bindActionCreators(actions.fetchMemes, dispatch),
    fetchAllMemes: bindActionCreators(actions.fetchAllMemePagination, dispatch),
    setOffset: bindActionCreators(actions.setOffset, dispatch),
    setMemesFilter: bindActionCreators(actions.setMemesFilter, dispatch),
    uploadMemes: bindActionCreators(actions.uploadMemes, dispatch)
  };
};
FourthCol.prototype = {
  authorPage: PropTypes.boolean
};

export default connect(mapStateToProps, mapDispatchToProps)(FourthCol);

const styles = {
  container: authorPage => {
    return authorPage
      ? {
          left: "calc(39% +  500px)"
        }
      : {};
  },
  link: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center"
  },
  btn: {
    width: 100,
    height: 34,
    marginTop: 10,
    borderRadius: 20,
    border: "1px solid #a7a7a7",
    background: "transparent"
  }
};

const Ads = () => (
  <div style={{ padding: 10 }}>
    <Addsense />
  </div>
);

const BooksAd = () => {
  return (
    <div>
      <a
        style={styles.link}
        href="https://www.amazon.com/Truth-About-Chuck-Norris-Greatest/dp/1592403441/ref=as_li_ss_il?ie=UTF8&linkCode=li3&tag=dictionaryish-20&linkId=6ff9e65726fa50c3eb200839a196563e"
        target="_blank"
      >
        <img
          border="0"
          src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=1592403441&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dictionaryish-20"
          alt="amazon"
        />
        <button style={styles.btn}>Show now</button>
      </a>
      <img
        src="https://ir-na.amazon-adsystem.com/e/ir?t=dictionaryish-20&l=li3&o=1&a=1592403441"
        width="1"
        height="1"
        border="0"
        alt="amazon"
        style={{ border: "none !important", margin: "0px !important" }}
      />
      <a
        style={styles.link}
        href="https://www.amazon.com/Christmas-Merdmaid-Pillowcase-Birthday-Reversible/dp/B07KPJD71X/ref=as_li_ss_il?dchild=1&keywords=chuck+norris+funny&qid=1597330037&sr=8-2&linkCode=li3&tag=dictionaryish-20&linkId=77b5c7a997bf14634398e01842936a8e"
        target="_blank"
      >
        <img
          border="0"
          src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07KPJD71X&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dictionaryish-20"
          alt="amazon"
        />
        <button style={styles.btn}>Show now</button>
      </a>
      <img
        src="https://ir-na.amazon-adsystem.com/e/ir?t=dictionaryish-20&l=li3&o=1&a=B07KPJD71X"
        width="1"
        height="1"
        border="0"
        alt="amazon"
        style={{ border: "none !important", margin: "0px !important" }}
      />
      <a
        style={styles.link}
        href="https://www.amazon.com/Christmas-Merdmaid-Pillowcase-Birthday-Reversible/dp/B07KPJD71X/ref=as_li_ss_il?dchild=1&keywords=chuck+norris+funny&qid=1597329751&sr=8-2&linkCode=li3&tag=dictionaryish-20&linkId=4da4b6fa07b94e4327b977835b4713cc"
        target="_blank"
      >
        <img
          border="0"
          src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07KPJD71X&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dictionaryish-20"
          alt="amazon"
        />
        <button style={styles.btn}>Show now</button>
      </a>
      <img
        src="https://ir-na.amazon-adsystem.com/e/ir?t=dictionaryish-20&l=li3&o=1&a=B07KPJD71X"
        width="1"
        height="1"
        border="0"
        alt="amazon"
        style={{ border: "none !important", margin: "0px !important" }}
      />
      <a
        style={styles.link}
        href="https://www.amazon.com/SD-toys-Missing-Action-Diorama/dp/B07FWBH3P4/ref=as_li_ss_il?dchild=1&keywords=chuck+norris+gifts&qid=1597329794&sr=8-47&linkCode=li3&tag=dictionaryish-20&linkId=d9ba2c2aabc56126f3cb26baea5e130b"
        target="_blank"
      >
        <img
          border="0"
          src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07FWBH3P4&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dictionaryish-20"
          alt="amazon"
        />
        <button style={styles.btn}>Show now</button>
      </a>
      <img
        src="https://ir-na.amazon-adsystem.com/e/ir?t=dictionaryish-20&l=li3&o=1&a=B07FWBH3P4"
        width="1"
        height="1"
        border="0"
        alt="amazon"
        style={{ border: "none !important", margin: "0px !important" }}
      />
    </div>
  );
};
