import React from 'react'

const con_img_bucket1 = 'conceptionary-memes-images.s3.amazonaws.com'
//const con_img_bucket2 = 'conceptionary-gallery-images.s3.amazonaws.com'
const con_img_bucket3 = 'conceptionary-images.s3.amazonaws.com'


export const resizedImageUrl = (imageUrl, width, height) => {
    if(!imageUrl) return;
    const bucket = imageUrl.split('/')[2];
    const img = imageUrl.split('/')[3];
    switch (bucket) {
        case con_img_bucket1:
            return `${process.env.REACT_APP_RESIZE_MEME_IMAGES}/fit-in/${width}x${height}/${img}`
        case con_img_bucket3:
            return `${process.env.REACT_APP_RESIZE_IMAGES}/fit-in/${width}x${height}/${img}`
        default:
            return imageUrl
    }

}
