import React, { lazy } from "react";
import All from "../components/All/All";
import NewConcept from "../components/Concept/Concept";
import AuthorPage from "../components/Author/author";
import archiveButton from "../../src/components/utills/PerspectiveBox/context/archiveButton";

const Message = lazy(() => import("../components/Messages/Message"));
const NewGroup = lazy(() => import("../components/newGroupPage/newGroup"));
// const  NewConcept = lazy(() => import("../components/Concept/Concept"))
// const  AuthorPage = lazy(() => import("../components/Author/author"))

const UserProfile = lazy(() => import("../components/User/User"));
const WackyTag = lazy(() => import("../components/WackyWares/WackyTag"));
const WackyWares = lazy(() => import("../components/WackyWares/WackyWares"));
const ConceptAndCluster = lazy(() => import("../components/ConceptAndCluster/ConceptAndCluster"));
const Describeish = lazy(() => import("../components/Pos/PosSubpages/Describeish"));
const Descriptions = lazy(() => import("../components/Pos/PosSubpages/Descriptions"));
const Similes = lazy(() => import("../components/Pos/PosSubpages/Similes"));
const Idioms = lazy(() => import("../components/Pos/PosSubpages/Idioms"));
const Superlatives = lazy(() => import("../components/Pos/PosSubpages/Superlatives"));
const LJS = lazy(() => import("../components/Pos/PosSubpages/LJS"));
const Exact = lazy(() => import("../components/Pos/PosSubpages/Exact"));
const Slang = lazy(() => import("../components/Pos/PosSubpages/Slang"));
const newClustersPage = lazy(() => import("../components/Cluster/myCluster.js"));
const HomePage = lazy(() => import("../components/HomePage/HomePage"));
const ArchivePage = lazy(() => import("../components/HomePage/ArchivePage"));
const ResetPassword = lazy(() => import("../components/Auth/myResetPassword"));
const ConfirmationAccount = lazy(() => import("../components/Auth/confirmationMail"));
const PerspectiveDetail = lazy(() => import("../components/perspectiveDetail/perspective"));
const About = lazy(() => import("../components/myabout/myabout"));
const Contact = lazy(() => import("../components/myContact/myContact"));
const TermsOfUse = lazy(() => import("../components/Terms/Terms"));
const PrivacyPolicy = lazy(() => import("../components/PrivacyPolicy/PrivacyPolicy"));
const MemesTag = lazy(() => import("../components/Memes/memeTag"));
const QuickLinkMemes = lazy(() => import("../components/Memes/QuickLinkMemes"));
const PosSimiles = lazy(() => import("../components/Pos/PosSimiles"));
const PosCategory = lazy(() => import("../components/Pos/PosCategory"));
const PosNoun = lazy(() => import("../components/Pos/PosNoun"));
const Memeskeyword = lazy(() => import("../components/Memes/memeKeyword"));
const SingleMemes = lazy(() => import("../components/Memes/singleMeme"));
const MemesBulk = lazy(() => import("../components/Memes/uploadBulk"));
const Books = lazy(() => import("../components/Books/Books"));
const SingleBook = lazy(() => import("../components/SingleBook/singleBook"));
const AllBooks = lazy(() => import("../components/Books/AllBooks"));
const AuthorBooks = lazy(() => import("../components/Books/AuthorBooks"));
const Login = lazy(() => import("../components/Auth/login"));
const News = lazy(() => import("../components/News/News"));
const Feed = lazy(() => import("../components/Feed/Feed"));
const Textish = lazy(() => import("../components/Textish/Textish"));
const UnverifiedConcepts = lazy(() => import("../components/Concept/unverifiedConcepts"));
const AuthorsLandingPage = lazy(() => import("../components/LandingPages/AuthorsLandingPage"));
const ConceptsLandingPage = lazy(() => import("../components/LandingPages/ConceptsLandingPage"));
const DefinitionsLandingPage = lazy(() =>
  import("../components/LandingPages/DefinitionsLandingPage")
);
const MemesLandingPage = lazy(() => import("../components/LandingPages/MemesLandingPage"));
const BooksLandingPage = lazy(() => import("../components/LandingPages/BooksLandingPage"));
const ViralsLandingPage = lazy(() => import("../components/LandingPages/ViralsLandingPage"));
const TriviaLandingPage = lazy(() => import("../components/LandingPages/TriviaLandingPage"));
const DadJokesLandingPage = lazy(() => import("../components/LandingPages/DadJokesLandingPage"));
const AdjVerbsLandingPage = lazy(() => import("../components/LandingPages/AdjVerbsLandingPage"));
const OneLinersLandingPage = lazy(() => import("../components/LandingPages/OneLinersLandingPage"));
const ProverbsLandingPage = lazy(() => import("../components/LandingPages/ProverbsLandingPage"));
const AuthorGroupsLandingPage = lazy(() =>
  import("../components/LandingPages/AuthorGroupsLandingPage")
);
const ProverbPage = lazy(() => import("../components/ProverbPage/ProverbPage"));

export const similarAuthorRoute = "/similar-authors";

const AppRoutes = [
  { path: "/", component: HomePage },
  // while making Landing Pages  { path: "/concepts", component: NewConcept },
  { path: "/unverifiedConcepts", component: UnverifiedConcepts },
  { path: `${similarAuthorRoute}/:name`, component: NewGroup },
  { path: "/dictionary/:name", component: ConceptAndCluster },
  { path: "/Concepts/:name", component: All },
  { path: "/dictionary/:name/:authorName", component: NewConcept },
  { path: "/concept-lists/:name", component: newClustersPage },
  { path: "/concept-clusters/:name", component: newClustersPage },
  { path: "/new/:name", component: newClustersPage },
  { path: "/authors/:name", component: AuthorPage },
  { path: "/author-clusters", component: NewGroup },
  // while making Landing Pages { path: "/author-groups", component: NewGroup },
  { path: "/author-groups/:name", component: NewGroup },
  { path: "/new/Concepts/:name", component: NewConcept },
  { path: "/Message", component: Message },
  { path: "/perspective/:id", component: PerspectiveDetail },
  { path: "/contact", component: Contact },
  { path: "/TermsOfUse", component: TermsOfUse },
  { path: "/PrivacyPolicy", component: PrivacyPolicy },
  { path: "/about", component: About },
  { path: "/resetPassword", component: ResetPassword },
  { path: "/confirmationAccount", component: ConfirmationAccount },
  // { path: "/memes", component: Memes },
  { path: "/memes/loading", component: MemesBulk },
  { path: "/books/all", component: AllBooks },
  { path: "/books/:id", component: Books },
  { path: "/book/:id", component: SingleBook },
  { path: "/books/author/:id", component: AuthorBooks },
  { path: "/login", component: Login },
  { path: "/news", component: News },
  { path: "/user/feed", component: Feed },
  { path: "/textish", component: Textish },
  { path: "/myarchive", component: ArchivePage },
  { path: "/single-meme/:id", component: SingleMemes },
  { path: "/memes/:name", component: MemesTag },
  //might conflict in future, but for now, to fulfil the client requirements I am fixing this way
  { path: "/memes/:meme/:keyword", component: Memeskeyword },
  { path: "/QL/:name", component: QuickLinkMemes },
  { path: "/POS/:name", component: PosCategory },
  { path: "/Noun/:name", component: PosNoun },
  { path: "/describeish", component: Describeish },
  { path: "/description", component: Descriptions },
  { path: "/similes", component: Similes },
  { path: "/idioms", component: Idioms },
  { path: "/superlatives", component: Superlatives },
  { path: "/cliches", component: LJS },
  { path: "/words-in-context", component: Exact },
  { path: "/slang", component: Slang },
  { path: "/wackywares", component: WackyWares },
  { path: "/wackywares/:concept", component: WackyTag },
  { path: "/authors", component: AuthorsLandingPage },
  { path: "/meme", component: MemesLandingPage },
  { path: "/virals", component: ViralsLandingPage },
  { path: "/books", component: BooksLandingPage },
  { path: "/one-liner", component: OneLinersLandingPage },
  { path: "/dad-joke", component: DadJokesLandingPage },
  { path: "/trivia", component: TriviaLandingPage },
  { path: "/concepts", component: ConceptsLandingPage },
  { path: "/definitions", component: DefinitionsLandingPage },
  { path: "/descriptions", component: AdjVerbsLandingPage },
  { path: "/author-groups", component: AuthorGroupsLandingPage },
  { path: "/proverbs", component: ProverbsLandingPage },
  { path: "/proverbs/:name", component: ProverbPage },
  { path: "/user", component: UserProfile }
];

export default AppRoutes;
