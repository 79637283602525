import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { Tabs, Tab } from "react-bootstrap";
import RelatedTabConcepts from "./relatedTabConcepts";
import * as memeActions from "../../../actions/memes";
import { bindActionCreators } from "redux";
import { getConceptItem } from "../../../helpers/conceptHelpers";
import ConceptSidebar from "../../utills/conceptsSideBar";
import ConceptsList from "./conceptsList";
import axios from "axios";
import * as scroll from "../../../helpers/scrolling";
import {
  SORT_BY_LIKES,
  keywordCategory,
  factCategory,
  conceptCategory
} from "../../../constants/global";
import * as actions from "../../../actions/conceptsActions";
import * as navbarActions from "../../../actions/navbaractions";
import * as conceptClusterActions from "../../../actions/conceptClustersActions";
import RelatedConcepts from "./relatedConcepts";
import { mapConceptKeywords } from "../../../selectors";
import "./firstCol.css";
import Drawer from "../../utills/drawer/drawer";
import { setDrawerOpen, setRelatedConceptDrawerOpen } from "../../../actions/mobile";
import SwitchAuthorThinker from "../../utills/Buttons/switchAuthorModes";
import SwitchConceptModes from "../../utills/Buttons/switchConceptModes";
import $ from "jquery";
import history from "../../../router/history";
import { listenToDocumentSizeChangeOnPercepectiveClick } from "../../../helpers/navbar";

import FirstColBtn from "./FirstColBtn";
import DefinitionPage from "./DefinitionPage";

function FirstCol(props) {
  const {
    concepts,
    //tagConcepts,
    sorting,
    selectedId,
    subFilter,
    conceptDrawer,
    relatedConceptDrawer,
    selectedOption,
    keywords,
    loading,
    isAdmin,
    lock
  } = props;

  const [pathname, setPathname] = useState("");
  const currentConcept = id => {
    return concepts.find(e => e.id === Number(id));
  };

  const pageName = window.location.href.split("/")[3];
  const conceptName = window.location.href.split("/")[4];
  const isAuthorPage = window.location.href.split("/")[5];

  window.onpopstate = e => {
    if (window.location && window.location.href.split("=")[1]) {
      const concept = currentConcept(window.location.href.split("=")[1]);
      if (concept) conceptCallBack(-1, concept, true);
    } else if (window.location.pathname === pathname && !window.location.href.split("=")[1]) {
      history.goBack();
    }
  };

  // useEffect(() => {
  //   const conceptName = window.location.pathname.split("/")[2];
  //   props.fetchMemes({ concept: conceptName, lock: true, toggle: true });
  // }, [window.location.pathname]);

  const conceptCallBack = (index, concept, popupRoute = false) => {
    if (subFilter && subFilter.type === factCategory) {
      props.setFactsKeywrodFilterId(concept.id);
    } else {
      scroll.resetSecondCol();
      const inputValue = {
        id: concept.id,
        value: concept.name,
        label: concept.name,
        slug: concept.name,
        type: keywordCategory
      };
      props.setSubFilter(inputValue, popupRoute);
      //props.perspectivesByFilter(inputValue);
      listenToDocumentSizeChangeOnPercepectiveClick();
    }
  };

  // const handleKeywordsCallBack = (index, concept) => {
  //   history.push(`/${conceptCategory}/${selectedOption.value}?${keywordCategory}=${concept.id}`);
  // };

  return (
    <div>
      {/* Keywords Drawer */}
      <Drawer open={conceptDrawer} handleClose={() => props.setDrawerOpen(false)} direction="left">
        <ConceptsList
          concepts={concepts}
          conceptCallBack={conceptCallBack}
          opacity={sorting === SORT_BY_LIKES}
          showCount={true}
          selectedId={selectedId}
          isKeywords={true}
        />
      </Drawer>
      {/* Related Concepts Drawer */}
      <Drawer
        open={relatedConceptDrawer}
        handleClose={() => props.setDrawerOpen(false)}
        direction="left"
      >
        {relatedConceptDrawer && (
          <RelatedConcepts
            drawerMode={
              true // Adding relatedConncepts condition because react-portal-tooltip was confusing in the DOM for two concepts with same Ids
            }
          />
        )}
      </Drawer>
      <div
        id={scroll.firstColId}
        onScroll={scroll.handleFirstWheel}
        style={{ paddingBottom: isAdmin ? 200 : 100 }}
        className="col-6 col-sm-6 col-md-2 right d-none d-md-block"
        // style={{ overflow: "inherit" }}
      >
        {/* {selectedOption.author && <SwitchAuthorThinker checked={false} />} */}
        {/* <Virals /> */}
        {/* {!relatedConceptDrawer && !selectedOption.author && <RelatedConcepts />} */}
        {/* <i
          className="fa fa-sort cursor-pointer"
          style={styles.icon}
          onClick={props.toggleKeywordsSort}
        /> */}

        {/* <button
          onClick={handleTabSelect}
          style={{ minWidth: 150, cursor: "pointer", marginBottom: "4px" }}
        >
          {activeTabe == "Related" ? <b>Related</b> : "Related"} /{" "}
          {activeTabe == "Keyword" ? <b>Keyword</b> : "Keyword"}
        </button>
        {!selectedOption.author && <SwitchConceptModes checked={false} />} */}
        {/* <div></div> */}
        {/* <div className="row concept-tabs">
          {activeTabe == "Keyword" && (
            <>
              <br />
              <ConceptSidebar
                concepts={concepts}
                conceptCallBack={conceptCallBack}
                opacity={sorting === SORT_BY_LIKES}
                showCount={true}
                selectedId={selectedId}
                isKeywords={true}
              />
            </>
          )}
          {activeTabe == "Related" &&
            (loading ? (
              <div style={{ color: "red", opacity: 0.7 }}>
                <Loader type="Oval" color="#4267B2" height="30" width="30" />
              </div>
            ) : (
              <RelatedTabConcepts concepts={keywords} />
            ))}
        </div> */}

        {/* <button
            type="button"
            className={selectedBtn === "definition" ? "selectedBtn" : "notSelectedBtn"}
            onClick={handleClickDefinition}
          >
            definition
          </button>
          <button
            type="button"
            className={selectedBtn === "virals" ? "selectedBtn" : "notSelectedBtn"}
            onClick={handleClickVirals}
          >
            virals
          </button>
          <button 
            type="button"
            className={selectedBtn === "pos" ? "selectedBtn" : "notSelectedBtn"}
            onClick={handleClickPos}
          >
            adj/
            <br />
            verbs
          </button> */}
        {/* </div> */}
        {/* <div style={{ marginLeft: "15px", cursor: "pointer" }}>
          {lock ? (
            <i className="fa fa-lock fa-3x" aria-hidden="true" onClick={setLock(false)}></i>
          ) : (
            <i className="fa fa-unlock fa-3x" aria-hidden="true" onClick={setLock(true)}></i>
          )}
        </div> */}
        {/* {!isAuthorPage && (
          <div style={{ cursor: "pointer", marginLeft: "10px" }}>
            {lock ? (
              <i
                className="fa fa-lock fa-3x"
                aria-hidden="true"
                onClick={() => props.lockIcon(false)}
              ></i>
            ) : (
              <i
                className="fa fa-unlock fa-3x"
                aria-hidden="true"
                onClick={() => props.lockIcon(true)}
              ></i>
            )}
          </div>
        )}
        <DefinitionPage lock={lock} /> */}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    //concepts: mapConceptKeywords(state),
    concepts: state.navbar.concepts,
    //tagConcepts: state.navbar.viralKeywords,
    memesID: state.navbar.selectedOption.id,
    sorting: state.author.sorting,
    subFilter: state.navbar.subFilter,
    conceptDrawer: state.mobile.conceptDrawer,
    relatedConceptDrawer: state.mobile.relatedConceptDrawer,
    selectedOption: state.navbar.selectedOption,
    keywords: state.concepts.concepts,
    loading: state.concepts.loading,
    isAdmin: state.auth.user.isAdmin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedItem: bindActionCreators(navbarActions.setSelectedItem, dispatch),
    lockIcon: bindActionCreators(navbarActions.lockIcon, dispatch),
    fetchMemes: bindActionCreators(memeActions.fetchMemetagMemes, dispatch),
    perspectivesByFilter: bindActionCreators(actions.perspectivesByFilter, dispatch),
    setFactsKeywrodFilterId: bindActionCreators(actions.setFactsKeywrodFilterId, dispatch),
    setSubFilter: bindActionCreators(navbarActions.setSubFilter, dispatch),
    toggleKeywordsSort: bindActionCreators(actions.toggleKeywordsSort, dispatch),
    setDrawerOpen: bindActionCreators(setDrawerOpen, dispatch),
    setRelatedConceptDrawerOpen: bindActionCreators(setRelatedConceptDrawerOpen, dispatch),
    relatedConcepts: bindActionCreators(actions.getRelatedConcepts, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(FirstCol));
