import * as navbarConstants from "../constants/navbar";
import * as memesConstants from "../constants/memes";
import * as constants from "../constants/authors";
import * as services from "../services/author";
import axios from "axios";
import * as perspectiveServices from "../services/perspectiveServices";
import { getAuthorItem, arrangeArray, sortAuthorByPriority } from "../helpers/authorHelpers";
import { SORT_BY_LIKES, SORT_BY_CONCEPTS, SET_VIEWPORT, factCategory } from "../constants/global";
import {
  sortPerspectivesByConcepts,
  sortPerspectivesByLove,
  sortBookPerspectivesByConcepts
} from "../helpers/perspectiveHelpers";
import { resetSecondCol } from "../helpers/scrolling";
import { toast } from "react-toastify";
import { listenToDocumentSizeChangeOnPercepectiveClick } from "../helpers/navbar";
import { dataPagination } from "../helpers/navbar";
const BASE_URL = `${process.env.REACT_APP_SERVER}/api/rest/v1`;
// New

export function getAllAuthorsByChar(character) {
  return async dispatch => {
    try {
      const response = await services.getAllAuthorsByAllCharacter(character);
      dispatch({
        type: constants.GET_ALL_AUTHORS_BY_CHAR,
        payload: response.data
      });
    } catch (error) {
      console.log(error.message);
    }
  };
}
export function getAuthorsByGroupName(id, otherGroupId) {
  console.log("console of authors group", id, otherGroupId);
  return async dispatch => {
    try {
      console.log("console of authors group", id, otherGroupId);

      const response = await services.getAuthorsByGroupNameHandler(id, otherGroupId);
      console.log("line44groupname: ", response.data);
      dispatch({
        type: constants.GET_ALL_AUTHORS_BY_CHAR,
        payload: response.data
      });
    } catch (error) {
      console.log(error.message);
    }
  };
}
export function searchAuthor(name, showPerspectives) {
  return async (dispatch, getState) => {
    try {
      resetSecondCol();
      dispatch({
        type: navbarConstants.BLOCK_PERSPECTIVE_LOADING,
        payload: false
      });
      dispatch({
        type: constants.SET_AUTHOR_SORTING,
        payload: SORT_BY_CONCEPTS
      });
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: false
      });
      dispatch({
        type: constants.AUTHOR_LOADING
      });

      dispatch({
        type: constants.AUTHOR_SELECTED_ID,
        payload: -1
      });
      dispatch({
        type: navbarConstants.SET_PRIMARY_MODE,
        payload: false
      });

      let blockPagination = false;
      const response = await services.authorDetailByName(name);

      const data = response.data.data;

      const {
        author,
        authorConcepts,
        initialPerspectives,
        factsCount,
        memesCount,
        count,
        hasAbout,
        // authorsWithReciprocal,
        authorRelateds: { AuthorGroups, AuthorInfluenceAuthors }
      } = data;
      const books = author.Books;
      const selectedOption = getAuthorItem(author, count, hasAbout);
      const perspectives = sortPerspectivesByConcepts(authorConcepts, initialPerspectives);
      const state = getState();
      const tags = state.navbar.tags;
      const index = state.author.conceptIndex;
      let showAuthor = true;

      let incrementedIndex = index + 5;
      if (authorConcepts.length < incrementedIndex) {
        dispatch({
          type: navbarConstants.BLOCK_PERSPECTIVE_LOADING,
          payload: true
        });
        dispatch({
          type: navbarConstants.PERSPECTIVE_LOADING,
          payload: false
        });
      }
      if (tags.length > 0) {
        tags.forEach(tag => {
          let groups = AuthorGroups.find(g => {
            return g.id === tag.id;
          });
          if (!groups) {
            showAuthor = false;
          }
        });
      }
      if (showAuthor) {
        dispatch({
          type: constants.AUTHOR_WIKI_LOADING,
          payload: true
        });
        services
          .scrapAuthorFromWikiPedia(author.firstName, author.lastName)
          .then(response => {
            dispatch({
              type: constants.SAVE_AUTHOR_WIKI,
              payload: response.data.data
            });
          })
          .catch(e => {
            dispatch({
              type: constants.AUTHOR_WIKI_LOADING,
              payload: false
            });
          });
        dispatch({
          type: navbarConstants.SET_SELECTED_OPTION,
          payload: selectedOption
        });
        dispatch({
          type: navbarConstants.BOOKS,
          payload: books
        });
        dispatch({
          type: navbarConstants.SET_INSIGHTS,
          payload: author.perspectiveCount
        });
        let relateds = sortAuthorByPriority(AuthorInfluenceAuthors);
        dispatch({
          type: constants.AUTHOR_RELATEDS,
          authorGroups: AuthorGroups,
          relatedAuthors: relateds
        });
        dispatch({
          type: constants.AUTHOR_DETAIL,
          payload: author
        });
        if (showPerspectives) {
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: perspectives
          });
        }
        dispatch({
          type: navbarConstants.CONCEPTS,
          payload: authorConcepts
        });
        dispatch({
          type: memesConstants.SET_MEMES_COUNT,
          payload: memesCount,
          factsCount
        });
        dispatch({
          type: constants.AUTHOR_LOADING_COMPLETE
        });
      } else {
        dispatch({
          type: navbarConstants.HIDE_PAGES,
          payload: true
        });
        dispatch({
          type: constants.AUTHOR_LOADING_COMPLETE
        });
      }
    } catch (e) {
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: true
      });
      dispatch({
        type: constants.AUTHOR_LOADING_COMPLETE
      });
    }
  };
}

export function getSingleAuthor(name) {
  return async (dispatch, getState) => {
    try {
      const response = await services.authorDetailByName(name);
      const data = response.data.data;
      const { authorConcepts } = data;
      dispatch({
        type: navbarConstants.PERSPECTIVE_CONCEPTS,
        payload: authorConcepts
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function relatedReciprocalAuthors(name) {
  return async dispatch => {
    try {
      dispatch({
        type: constants.AUTHOR_RELATEDS,
        relatedAuthors: []
      });
      dispatch({
        type: constants.AUTHOR_RELATEDS_LOADING,
        payload: true
      });
      const response = await services.authorRelatedsByName(name);
      const data = response.data.data;
      const { authorsWithReciprocal } = data;
      let relateds = sortAuthorByPriority(authorsWithReciprocal);
      dispatch({
        type: constants.AUTHOR_RELATEDS,
        relatedAuthors: relateds
      });
      dispatch({
        type: constants.AUTHOR_RELATEDS_LOADING,
        payload: false
      });
    } catch (e) {
      console.log("erros is ", e);
      dispatch({
        type: constants.AUTHOR_RELATEDS_LOADING,
        payload: false
      });
    }
  };
}

export function getSingleBook(name, id, showPerspectives) {
  return async (dispatch, getState) => {
    try {
      resetSecondCol();
      dispatch({
        type: navbarConstants.BLOCK_PERSPECTIVE_LOADING,
        payload: false
      });
      dispatch({
        type: constants.SET_AUTHOR_SORTING,
        payload: SORT_BY_CONCEPTS
      });
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: false
      });
      dispatch({
        type: constants.AUTHOR_LOADING
      });

      dispatch({
        type: constants.AUTHOR_SELECTED_ID,
        payload: -1
      });
      dispatch({
        type: navbarConstants.SET_PRIMARY_MODE,
        payload: false
      });
      const pageNumber = 0;
      const booksResponse = await services.singleBook(id, pageNumber);
      dispatch({
        type: navbarConstants.SINGLE_BOOK_PAGE_NUMBER,
        payload: 0
      });
      const {
        author,
        authorConcepts,
        initialPerspectives,
        authorRelateds,
        singleBook,
        allConcepts
      } = booksResponse.data.data;
      singleBook.category = "Book";
      singleBook.value = singleBook.title;
      const books = author.Books;
      const singleAuthor = getAuthorItem(author);
      const state = getState();
      const tags = state.navbar.tags;
      const index = state.author.conceptIndex;
      let showAuthor = true;

      const singleBookData = {
        singleBook,
        authorRelateds,
        initialPerspectives,
        authorConcepts,
        author
      };

      dispatch({
        type: navbarConstants.SINGLE_BOOK_RELATED_ALL_DATA_SUCCESS,
        payload: singleBookData
      });

      let incrementedIndex = index + 5;
      if (authorConcepts.length < incrementedIndex) {
        dispatch({
          type: navbarConstants.BLOCK_PERSPECTIVE_LOADING,
          payload: true
        });
        dispatch({
          type: navbarConstants.PERSPECTIVE_LOADING,
          payload: false
        });
      }

      if (showAuthor) {
        dispatch({
          type: constants.AUTHOR_WIKI_LOADING,
          payload: true
        });
        services
          .scrapAuthorFromWikiPedia(author.firstName, author.lastName)
          .then(response => {
            dispatch({
              type: constants.SAVE_AUTHOR_WIKI,
              payload: response.data.data
            });
          })
          .catch(e => {
            dispatch({
              type: constants.AUTHOR_WIKI_LOADING,
              payload: false
            });
          });
        dispatch({
          type: navbarConstants.SET_SELECTED_OPTION,
          payload: singleBook
        });
        dispatch({
          type: navbarConstants.SINGLE_BOOK,
          payload: singleBook
        });
        dispatch({
          type: navbarConstants.SINGLE_BOOK_AUTHOR,
          payload: singleAuthor
        });
        dispatch({
          type: navbarConstants.BOOKS,
          payload: books
        });
        dispatch({
          type: navbarConstants.SET_INSIGHTS,
          payload: author.perspectiveCount
        });
        dispatch({
          type: constants.AUTHOR_RELATEDS,
          relatedAuthors: authorRelateds
        });
        dispatch({
          type: constants.AUTHOR_DETAIL,
          payload: author
        });
        if (showPerspectives) {
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: initialPerspectives
          });
        }
        dispatch({
          type: navbarConstants.CONCEPTS,
          payload: authorConcepts
        });
        dispatch({
          type: navbarConstants.AUTHOR_CONCEPTS,
          payload: authorConcepts
        });
        dispatch({
          type: navbarConstants.ALL_CONCEPTS_SINGLE_BOOK,
          payload: allConcepts
        });
        dispatch({
          type: constants.AUTHOR_LOADING_COMPLETE
        });
      } else {
        dispatch({
          type: navbarConstants.HIDE_PAGES,
          payload: true
        });
        dispatch({
          type: constants.AUTHOR_LOADING_COMPLETE
        });
      }
    } catch (e) {
      console.log("erros is ", e);
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: true
      });
      dispatch({
        type: constants.AUTHOR_LOADING_COMPLETE
      });
    }
  };
}

export function addConceptBook(bookId, concept) {
  return async (dispatch, getState) => {
    try {
      listenToDocumentSizeChangeOnPercepectiveClick();
      const state = getState();
      const {
        navbar: { concepts }
      } = state;
      const newConcept = {
        id: concept.id,
        label: concept.label,
        name: concept.label
      };
      const response = await services.addConceptBook(bookId, concept.id);
      if (response.status === 200) {
        let newConcepts = concepts;
        if (newConcepts.length == 0) {
          const percepectiveResponse = await perspectiveServices.getPerspectivesByConceptId(
            concept.id
          );
          let perspectives = sortBookPerspectivesByConcepts(
            concepts,
            percepectiveResponse.data.data[0].Perspectives
          );
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: perspectives
          });
        }
        newConcepts.push(newConcept);
        let sortedConcepts = newConcepts.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        dispatch({
          type: navbarConstants.CONCEPTS,
          payload: sortedConcepts
        });
      }
    } catch (e) {
      console.log("erros is ", e);
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: true
      });
      dispatch({
        type: constants.AUTHOR_LOADING_COMPLETE
      });
    }
  };
}

export function addTempConceptBook(bookId, concept) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        navbar: { concepts }
      } = state;
      const newConcept = {
        id: concept.id,
        label: concept.label,
        name: concept.label,
        reciprocalExist: true
      };
      const response = await services.addConceptBook(bookId, concept.id);
      if (response.status === 200) {
        let newConcepts = concepts;
        newConcepts.push(newConcept);
        let sortedConcepts = newConcepts.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        dispatch({
          type: navbarConstants.CONCEPTS,
          payload: sortedConcepts
        });
      }
    } catch (e) {
      console.log("erros is ", e);
    }
  };
}

export function removeTempConceptBook(bookId, conceptId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        navbar: { concepts }
      } = state;

      const response = await services.removeConceptBook(bookId, conceptId);
      if (response.status === 200) {
        let newConcepts = [];
        newConcepts = concepts.filter(item => item.id !== conceptId);
        if (newConcepts.length > 0) {
          dispatch({
            type: navbarConstants.CONCEPTS,
            payload: newConcepts
          });
        } else {
          dispatch({
            type: navbarConstants.CONCEPTS,
            payload: []
          });
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: []
          });
        }
      }
    } catch (e) {
      console.log("erros is ", e);
    }
  };
}

export function removeConceptBook(bookId, conceptId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.LOADING_START
      });
      listenToDocumentSizeChangeOnPercepectiveClick();
      const state = getState();
      const {
        navbar: { concepts }
      } = state;
      const response = await services.removeConceptBook(bookId, conceptId);
      if (response.status === 200) {
        let newConcepts = [];
        newConcepts = concepts.filter(item => item.id !== conceptId);
        if (newConcepts.length > 0) {
          dispatch({
            type: navbarConstants.CONCEPTS,
            payload: newConcepts
          });
          const percepectiveResponse = await perspectiveServices.getPerspectivesByConceptId(
            newConcepts[0].id
          );
          let perspectives = sortBookPerspectivesByConcepts(
            concepts,
            percepectiveResponse.data.data[0].Perspectives
          );
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: perspectives
          });
        } else {
          dispatch({
            type: navbarConstants.CONCEPTS,
            payload: []
          });
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: []
          });
        }
      }
      dispatch({
        type: navbarConstants.LOADING_END
      });
    } catch (e) {
      console.log("erros is ", e);
      dispatch({
        type: navbarConstants.HIDE_PAGES,
        payload: true
      });
      dispatch({
        type: constants.AUTHOR_LOADING_COMPLETE
      });
    }
  };
}

export function refreshPriority() {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.REFRESH_LOADING,
        payload: true
      });
      const state = getState();
      const { selectedOption } = state.navbar;
      const {
        user: { token }
      } = state.auth;
      const { authorGroups } = state.author;

      const response1 = await services.resetAuthorsPriority(selectedOption.id, token);
      const response = await services.authorDetailByName(selectedOption.value);
      const data = response.data.data;
      if (data.authorRelateds && data.authorRelateds.AuthorInfluenceAuthors) {
        let relateds = sortAuthorByPriority(data.authorRelateds.AuthorInfluenceAuthors);
        dispatch({
          type: constants.AUTHOR_RELATEDS,
          authorGroups: authorGroups,
          relatedAuthors: relateds
        });
      }

      dispatch({
        type: constants.REFRESH_LOADING,
        payload: false
      });
    } catch (e) {
      dispatch({
        type: constants.REFRESH_LOADING,
        payload: false
      });
    }
  };
}

export function handleDownPagination(keywords = false) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: true
      });
      let blockPagination = false;
      const state = getState();
      const { sorting } = state.author;
      let data = [];
      const index = state.author.conceptIndex;
      const topIndex = state.author.conceptTopIndex;
      const {
        offset,
        limit,
        authorDetail: { id }
      } = state.author;
      if (sorting === SORT_BY_CONCEPTS) {
        const detail = state.author.authorDetail;
        const concepts = state.navbar.concepts;
        const array = concepts.slice(index, index + 5);
        const ids = array.map(x => x.id);

        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
          detail.id,
          ids,
          keywords
        );
        if (response.status === 200) {
          data = sortPerspectivesByConcepts(concepts, response.data.data);

          let incrementedIndex = index + 5;
          if (concepts.length < incrementedIndex) {
            blockPagination = true;
          }

          dispatch({
            type: constants.AUTHOR_CONCEPT_INDEX,
            index: incrementedIndex,
            topIndex: topIndex
          });
        }
      } else {
        const { concepts } = state.navbar;
        const conceptIds = concepts.map(c => c.id);
        const response = await perspectiveServices.getPerspectivesByLikes(
          id,
          conceptIds,
          offset,
          limit
        );
        if (response.status === 200) {
          data = response.data.data;
          dispatch({
            type: constants.AUTHOR_OFFSET,
            payload: 1 + offset
          });
        }
        if (data.length < limit) {
          blockPagination = true;
        }
      }
      let perspectives = state.navbar.perspectives;
      let newPerspectives = [...perspectives, ...data];

      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: newPerspectives
      });
      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: false
      });
      dispatch({
        type: navbarConstants.BLOCK_PERSPECTIVE_LOADING,
        payload: blockPagination
      });
      dataPagination();
    } catch (e) {
      console.log("errro is ", e);
      dataPagination();
    }
  };
}

export function handleTopPagination(keywords = false) {
  return async (dispatch, getState) => {
    try {
      dataPagination();
      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: true
      });
      const state = getState();
      const {
        navbar: { concepts, perspectives },
        author: { conceptTopIndex, conceptIndex, authorDetail }
      } = state;
      let data = [];
      if (conceptTopIndex - 5 > -1) {
        concepts.slice(conceptTopIndex - 5, conceptTopIndex).forEach(conc => data.push(conc));
      } else {
        concepts.slice(0, conceptTopIndex).map(conc => data.push(conc));
      }
      let ids = [];
      data.forEach(concept => {
        ids.push(concept.id);
      });

      const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
        authorDetail.id,
        ids,
        keywords
      );
      if (response.status === 200) {
        data = sortPerspectivesByConcepts(concepts, response.data.data);
      }
      let newPerspectives = [...data, ...perspectives];
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: newPerspectives
      });
      dispatch({
        type: constants.AUTHOR_CONCEPT_INDEX,
        index: conceptIndex,
        topIndex: conceptTopIndex - 5
      });
      dispatch({
        type: navbarConstants.PERSPECTIVE_LOADING,
        payload: false
      });
      const prev = concepts[conceptTopIndex];
      dispatch({
        type: SET_VIEWPORT,
        payload: prev.id
      });
      dataPagination();
    } catch (e) {
      console.log("errro is ", e);
      dataPagination();
    }
  };
}

export function toggleAuthorPagination() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        sorting,
        authorDetail: { id },
        limit
      } = state.author;
      const { concepts } = state.navbar;
      let data = [];
      dispatch({
        type: constants.AUTHOR_SELECTED_ID,
        payload: -1
      });
      if (sorting === SORT_BY_CONCEPTS) {
        dispatch({
          type: constants.SET_AUTHOR_SORTING,
          payload: SORT_BY_LIKES
        });
        const conceptIds = concepts.map(c => c.id);
        const response = await perspectiveServices.getPerspectivesByLikes(id, conceptIds, 0, limit);
        if (response.status === 200) {
          data = response.data.data;
        }
      } else {
        const array = concepts.slice(0, 5);
        const ids = array.map(x => x.id);
        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(id, ids);
        if (response.status === 200) {
          data = sortPerspectivesByConcepts(concepts, response.data.data);
        }
        dispatch({
          type: constants.SET_AUTHOR_SORTING,
          payload: SORT_BY_CONCEPTS
        });
        dispatch({
          type: constants.AUTHOR_CONCEPT_INDEX,
          index: 5,
          topIndex: 0
        });
      }
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: data
      });
      dispatch({
        type: navbarConstants.SELECTED_CONCEPT_ID,
        payload: -1
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function conceptCallBack(index, concept, keywords = false) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.LOADING_START
      });
      const state = getState();
      const {
        navbar: { concepts },
        author: { authorDetail }
      } = state;
      let preConcepts = [];
      if (index < 5) {
        preConcepts = concepts.slice(0, index);
      } else {
        preConcepts = concepts.slice(index - 5, index);
      }

      let postConcepts = concepts.slice(index, 5 + index);
      let data = preConcepts.concat(postConcepts);
      let downIndex = 5 + index;
      let topIndex = index - 5;
      let conceptIds = [];
      data.map(key => {
        conceptIds.push(key.id);
        return true;
      });

      const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
        authorDetail.id,
        conceptIds,
        keywords
      );
      if (response.status === 200) {
        let perspectives = sortPerspectivesByConcepts(concepts, response.data.data);
        resetSecondCol();
        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: perspectives
        });
        dispatch({
          type: constants.SET_AUTHOR_SORTING,
          payload: SORT_BY_CONCEPTS
        });
        dispatch({
          type: constants.AUTHOR_CONCEPT_INDEX,
          index: downIndex,
          topIndex: topIndex
        });
        dispatch({
          type: constants.AUTHOR_SELECTED_ID,
          payload: concept.id
        });
        dispatch({
          type: SET_VIEWPORT,
          payload: concept.id
        });
      }
      dispatch({
        type: navbarConstants.SELECTED_CONCEPT_ID,
        payload: concept.id
      });
      dispatch({
        type: navbarConstants.LOADING_END
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function singleBookConceptCallBack(index, concept) {
  return async (dispatch, getState) => {
    resetSecondCol();
    try {
      dispatch({
        type: navbarConstants.LOADING_START
      });
      listenToDocumentSizeChangeOnPercepectiveClick();
      const state = getState();
      const {
        navbar: { concepts },
        author: { authorDetail }
      } = state;
      var newPerspectives = concepts.Perspectives;
      let preConcepts = [];
      if (index < 5) {
        preConcepts = concepts.slice(0, index);
      } else {
        preConcepts = concepts.slice(index - 5, index);
      }
      let postConcepts = concepts.slice(index, 5 + index);
      let data = preConcepts.concat(postConcepts);
      let downIndex = 5 + index;
      let topIndex = index - 5;
      let conceptIds = [];
      data.map(key => {
        conceptIds.push(key.id);
        return true;
      });
      let response = {};
      if (state.navbar.singleBookActiveStatus == "author") {
        const authorHere = state.navbar.singleBookAuthor;
        response = await perspectiveServices.getPerspectivesByConceptIdAndAuthorId(
          concept.id,
          authorHere.id
        );
      } else {
        response = await perspectiveServices.getPerspectivesByConceptId(concept.id);
      }
      if (response.status === 200) {
        let perspectives = sortBookPerspectivesByConcepts(
          concepts,
          response.data.data[0].Perspectives
        );
        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: perspectives
        });
        dispatch({
          type: constants.SET_AUTHOR_SORTING,
          payload: SORT_BY_CONCEPTS
        });
        dispatch({
          type: constants.AUTHOR_CONCEPT_INDEX,
          index: downIndex,
          topIndex: topIndex
        });
        dispatch({
          type: constants.AUTHOR_SELECTED_ID,
          payload: concept.id
        });
        dispatch({
          type: constants.CONCEPT_SELECTED_ID,
          payload: concept.id
        });
        dispatch({
          type: SET_VIEWPORT,
          payload: concept.id
        });
      }
      dispatch({
        type: navbarConstants.SELECTED_CONCEPT_ID,
        payload: concept.id
      });
      dispatch({
        type: navbarConstants.LOADING_END
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}
export function allConceptsCallBack(index, concept) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.LOADING_START
      });
      const state = getState();
      const {
        navbar: { concepts },
        author: { authorDetail }
      } = state;
      var newPerspectives = concepts.Perspectives;
      let preConcepts = [];
      if (index < 5) {
        preConcepts = concepts.slice(0, index);
      } else {
        preConcepts = concepts.slice(index - 5, index);
      }
      let postConcepts = concepts.slice(index, 5 + index);
      let data = preConcepts.concat(postConcepts);
      let downIndex = 5 + index;
      let topIndex = index - 5;
      let conceptIds = [];
      data.map(key => {
        conceptIds.push(key.id);
        return true;
      });
      const response = await perspectiveServices.getPerspectivesByConceptId(concept.id);
      if (response.status === 200) {
        let perspectives = sortBookPerspectivesByConcepts(
          concepts,
          response.data.data[0].Perspectives
        );
        const conceptsHere = state.navbar.allConceptsOfSingleBook;
        dispatch({
          type: navbarConstants.CONCEPTS,
          payload: conceptsHere
        });
        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: perspectives
        });
        dispatch({
          type: constants.SET_AUTHOR_SORTING,
          payload: SORT_BY_CONCEPTS
        });
        dispatch({
          type: constants.AUTHOR_CONCEPT_INDEX,
          index: downIndex,
          topIndex: topIndex
        });
        dispatch({
          type: constants.AUTHOR_SELECTED_ID,
          payload: concept.id
        });
        dispatch({
          type: constants.CONCEPT_SELECTED_ID,
          payload: concept.id
        });
        dispatch({
          type: SET_VIEWPORT,
          payload: concept.id
        });
      }

      dispatch({
        type: navbarConstants.LOADING_END
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}
export function singleBookActiveStatus(state) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.SINGLE_BOOK_ACTIVE_STATE,
        payload: state
      });
      dispatch({
        type: navbarConstants.SINGLE_BOOK_PAGE_NUMBER,
        payload: 0
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}
export function handleSingleBookPagination() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const bookId = state.navbar.singleBook.id;
      const authorId = state.author.authorDetail.id;
      const pageNumber = state.navbar.singleBookPageNumber;
      const newpageNumber = pageNumber + 1;
      const oldPercepectives = state.navbar.perspectives;
      const selectedId = state.navbar.selectedConceptId;
      if (state.navbar.singleBookActiveStatus == "author" && selectedId < 1) {
        dispatch({
          type: navbarConstants.PERSPECTIVE_LOADING,
          payload: true
        });
        const token = state.auth.user.token;
        const response = await services.getPerspectiveConceptsOfBooks(
          bookId,
          authorId,
          newpageNumber
        );
        if (response.status == 200) {
          dispatch({
            type: navbarConstants.PERSPECTIVES,
            payload: [...oldPercepectives, ...response.data.perspectiveOfBooks]
          });
          dispatch({
            type: navbarConstants.SINGLE_BOOK_PAGE_NUMBER,
            payload: newpageNumber
          });
        }
        dispatch({
          type: navbarConstants.PERSPECTIVE_LOADING,
          payload: false
        });
      } else if (state.navbar.singleBookActiveStatus == "related" && selectedId < 1) {
        dispatch({
          type: navbarConstants.PERSPECTIVE_LOADING,
          payload: true
        });
        const token = state.auth.user.token;
        const response = await services.getPerspectiveConceptsOfRelatedAuthors(
          bookId,
          authorId,
          newpageNumber
        );
        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: [...oldPercepectives, ...response.data.result]
        });
        dispatch({
          type: navbarConstants.SINGLE_BOOK_PAGE_NUMBER,
          payload: newpageNumber
        });
        dispatch({
          type: navbarConstants.PERSPECTIVE_LOADING,
          payload: false
        });
      }
      dataPagination();
    } catch (e) {
      console.log("errro is ", e);
      dataPagination();
    }
  };
}

export function showAllMemes() {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.LOADING_START
      });
      const state = getState();
      const memes = state.memes.memes;

      dispatch({
        type: memesConstants.MEME_PAGE_MEMES,
        payload: {
          memePageMemes: JSON.parse(JSON.stringify(memes))
        }
      });
      dispatch({
        type: navbarConstants.SELECTED_CONCEPT_ID,
        payload: -1
      });
      dispatch({
        type: navbarConstants.LOADING_END
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function getMemerelateds(id) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "GET_MEME_CONCEPT_RELATEDS",
        payload: []
      });
      dispatch({
        type: "GET_MEME_CONCEPT_RELATED_LOADING",
        payload: true
      });

      const state = getState();
      const selectedConcept = state.navbar.selectedOption;
      const response = await axios.post(`${BASE_URL}/memes/relatedConcepts`, {
        id,
        conceptId: selectedConcept.id
      });
      dispatch({
        type: "GET_MEME_CONCEPT_RELATEDS",
        payload: response.data.data
      });
      dispatch({
        type: "GET_MEME_CONCEPT_RELATED_LOADING",
        payload: false
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function perspectivesByFilter(inputValue, keywords = false) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.LOADING_START
      });
      const state = getState();
      const {
        author: { authorDetail }
      } = state;
      const ids = [inputValue.id];
      let perspectives = [];

      if (inputValue.type === factCategory) {
        const response = await perspectiveServices.getFactsByAuthors(authorDetail.id);
        if (response.status === 200) {
          perspectives = response.data.data;
        }
      } else {
        const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(
          authorDetail.id,
          ids,
          keywords
        );
        if (response.status === 200) {
          perspectives = response.data.data;
        }
      }
      const sorted = sortPerspectivesByLove(perspectives);
      dispatch({
        type: navbarConstants.PERSPECTIVES,
        payload: sorted
      });
      dispatch({
        type: constants.AUTHOR_SELECTED_ID,
        payload: inputValue.id
      });
      dispatch({
        type: navbarConstants.LOADING_END
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}
export function cancelClick() {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: navbarConstants.LOADING_START
      });
      const state = getState();
      const {
        authorDetail: { id }
      } = state.author;
      const { concepts } = state.navbar;
      const array = concepts.slice(0, 5);
      const ids = array.map(x => x.id);
      const response = await perspectiveServices.getPerspectivesByConceptsAndAuthors(id, ids);
      if (response.status === 200) {
        let data = sortPerspectivesByConcepts(concepts, response.data.data);
        dispatch({
          type: constants.AUTHOR_SELECTED_ID,
          payload: -1
        });

        dispatch({
          type: constants.SET_AUTHOR_SORTING,
          payload: SORT_BY_CONCEPTS
        });
        dispatch({
          type: constants.AUTHOR_CONCEPT_INDEX,
          index: 5,
          topIndex: 0
        });

        dispatch({
          type: navbarConstants.PERSPECTIVES,
          payload: data
        });
      }
      dispatch({
        type: navbarConstants.LOADING_END
      });
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export function removeAuthorReciprocal(authorId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let { relatedAuthors, authorGroups } = state.author;
      const {
        user: { token }
      } = state.auth;
      const { selectedOption } = state.navbar;
      const index = relatedAuthors.findIndex(x => x.id === authorId);
      if (index > -1) {
        let author = relatedAuthors[index];
        author.reciprocalExist = false;
        relatedAuthors[index] = { ...author };
        const response = await services.removeAuthorReciprocal(author.id, selectedOption.id, token);
        if (response.status === 200) {
          dispatch({
            type: constants.AUTHOR_RELATEDS,
            authorGroups,
            relatedAuthors: [...relatedAuthors]
          });
        }
      }
    } catch (e) {
      console.log("error is ", e);
    }
  };
}
export function addAuthorReciprocal(authorId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      let { relatedAuthors, authorGroups } = state.author;
      const {
        user: { token }
      } = state.auth;
      const { selectedOption } = state.navbar;
      const index = relatedAuthors.findIndex(x => x.id === authorId);
      if (index > -1) {
        let author = relatedAuthors[index];
        author.reciprocalExist = true;
        relatedAuthors[index] = { ...author };
        const response = await services.addAuthorReciprocal(author.id, selectedOption.id, token);
        if (response.status === 200) {
          dispatch({
            type: constants.AUTHOR_RELATEDS,
            authorGroups,
            relatedAuthors: [...relatedAuthors]
          });
        }
      }
    } catch (e) {
      console.log("error is ", e);
    }
  };
}

export const fetchTempAuthors = authorId => {
  return async (dispatch, getState) => {
    dispatch({
      type: constants.TEMP_AUTHOR_LOADING,
      payload: true
    });
    const state = getState();
    const {
      user: { token }
    } = state.auth;
    const response = await services.getRelatedAuthorById(authorId, token);
    if (response.status === 200) {
      const authors = response.data.data;
      dispatch({
        type: constants.TEMP_RELATED_AUTHORS,
        payload: authors
      });
    }
    dispatch({
      type: constants.TEMP_AUTHOR_LOADING,
      payload: false
    });
  };
};

export const handleTempReciprocalClick = author => {
  return async (dispatch, getState) => {
    const state = getState();
    let { relatedAuthors, authorGroups } = state.author;
    const {
      user: { token }
    } = state.auth;
    const { selectedOption } = state.navbar;
    const reciprocal = relatedAuthors.find(x => x.id === author.id);
    if (author.reciprocalExist) {
      if (reciprocal) {
        relatedAuthors = relatedAuthors.map(x => {
          if (x.priority > reciprocal.priority) {
            x.priority -= 1;
          }
          return x;
        });
      }
      relatedAuthors = relatedAuthors.filter(x => x.id !== author.id);
      const response = await services.removeAuthorReciprocal(selectedOption.id, author.id, token);
      if (response.status === 200) {
        dispatch({
          type: constants.AUTHOR_RELATEDS,
          authorGroups,
          relatedAuthors: [...relatedAuthors]
        });
      }
    } else {
      const index = relatedAuthors.findIndex(x => x.id === author.id);

      if (index < 0) {
        const response = await services.addAuthorReciprocal(selectedOption.id, author.id, token);

        if (response.status === 200) {
          let { influencedAuthor } = response.data.data;
          influencedAuthor.reciprocalExist = true;
          if (relatedAuthors.length > 0) {
            const lastAuthor = relatedAuthors[relatedAuthors.length - 1];
            influencedAuthor.priority = lastAuthor.priority + 1;
          } else {
            influencedAuthor.priority = 0;
          }
          relatedAuthors.push(influencedAuthor);
          dispatch({
            type: constants.AUTHOR_RELATEDS,
            relatedAuthors: [...relatedAuthors]
          });
        }
      }
    }
  };
};

export const deleteRelatedAuthor = (authorId, priority) => {
  return async (dispatch, getState) => {
    const state = getState();
    let { relatedAuthors, authorGroups } = state.author;
    const {
      user: { token }
    } = state.auth;
    const { selectedOption } = state.navbar;
    const response = await services.removeAuthorReciprocal(selectedOption.id, authorId, token);
    if (response.status === 200) {
      relatedAuthors = relatedAuthors.map(x => {
        if (x.priority > priority) {
          x.priority -= 1;
        }
        return x;
      });
      relatedAuthors = relatedAuthors.filter(x => x.id !== authorId);
      dispatch({
        type: constants.AUTHOR_RELATEDS,
        relatedAuthors: [...relatedAuthors]
      });
    }
  };
};

export const handleSortEnd = (oldIndex, newIndex) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      user: { token }
    } = state.auth;
    const { selectedOption } = state.navbar;
    const { relatedAuthors, authorGroups } = state.author;
    const newData = arrangeArray(oldIndex, newIndex, relatedAuthors);
    const authors = sortAuthorByPriority(newData);
    const payload = authors.map(x => ({ id: x.id, priority: x.priority }));
    dispatch({
      type: constants.AUTHOR_RELATEDS,
      authorGroups: authorGroups,
      relatedAuthors: authors
    });
    const response = await services.updateAuthorsPriority(selectedOption.id, payload, token);
  };
};

export const allowBlockPagination = () => {
  return dispatch => {
    dispatch({
      type: navbarConstants.BLOCK_PERSPECTIVE_LOADING,
      payload: false
    });
  };
};

export function handleAuthorSubmit(firstName, lastName) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: constants.AUTHOR_SUBMIT_LOADING,
        payload: true,
        error: ""
      });
      const state = getState();
      const token = state.auth.user.token;
      const response = await services.addNewAuthor(token, firstName, lastName);
      if (response.status === 201) {
        dispatch({
          type: constants.AUTHOR_SUBMIT_LOADING,
          payload: false,
          error: ""
        });
        toast.success("Author added successfully");
      }
    } catch (e) {
      let error = "";
      if (e.response) {
        if (e.response.status == 409) {
          error = "Author with this name already Exists";
        }
      } else error = e.message;

      dispatch({
        type: constants.AUTHOR_SUBMIT_LOADING,
        payload: false,
        error: error
      });
    }
  };
}

export const getPerspectiveConceptsOfSpecificBook = (bookId, authorId) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: navbarConstants.LOADING_START
  });
  const state = getState();
  const token = state.auth.user.token;
  const conceptsHere = state.navbar.authorConceptsOfSingleBook;
  const pageNumber = 0;
  const response = await services.getPerspectiveConceptsOfBooks(bookId, authorId, pageNumber);
  dispatch({
    type: navbarConstants.PERSPECTIVES,
    payload: response.data.perspectiveOfBooks
  });
  dispatch({
    type: navbarConstants.SINGLE_BOOK_PAGE_NUMBER,
    payload: 0
  });
  dispatch({
    type: navbarConstants.CONCEPTS,
    payload: conceptsHere
  });
  dispatch({
    type: navbarConstants.SELECTED_CONCEPT_ID,
    payload: 0
  });
  dispatch({
    type: navbarConstants.LOADING_END
  });
};

export const getPerspectiveConceptsOfRelatedAuthors = (bookId, authorId) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: navbarConstants.LOADING_START
  });
  const state = getState();
  const token = state.auth.user.token;
  const conceptsHere = state.navbar.allConceptsOfSingleBook;
  const pageNumber = 0;
  const response = await services.getPerspectiveConceptsOfRelatedAuthors(
    bookId,
    authorId,
    pageNumber
  );
  dispatch({
    type: navbarConstants.PERSPECTIVES,
    payload: response.data.result
  });
  dispatch({
    type: navbarConstants.CONCEPTS,
    payload: conceptsHere
  });
  dispatch({
    type: navbarConstants.SINGLE_BOOK_PAGE_NUMBER,
    payload: 0
  });
  dispatch({
    type: navbarConstants.SELECTED_CONCEPT_ID,
    payload: 0
  });
  dispatch({
    type: navbarConstants.LOADING_END
  });
};
