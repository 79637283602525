import React from "react";
import { connect } from "react-redux";
import * as scroll from "../../../helpers/scrolling";
import AuthorDetail from "./authorDetail";
import * as actions from "../../../actions/authorActions";
import * as mobile from "../../../actions/mobile";
import { bindActionCreators } from "redux";
import { setSubFilter, removeSubFilter } from "../../../actions/navbaractions";
import Drawer from "../../utills/drawer/drawer";
import AboutThisThinker from "./aboutThisThinker";
import history from "../../../router/history";

const ThirdCol = props => {
  const { subFilter, detail, selectedOption, bioDrawer } = props;
  const relatedConcept = detail.Concept;
  const aboutThinker = () => {
    history.push(`/dictionary/${selectedOption.slug}`);
  };
  return (
    <>
      <Drawer open={bioDrawer} handleClose={() => props.setBioDrawerOpen(false)} direction="right">
        <div className="p-4">
          <AuthorDetail />
        </div>
      </Drawer>
      <div
        id={scroll.thirdColId}
        onScroll={scroll.handleThirdWheel}
        className="col-xs-10 col-sm-12 col-md-3 d-none d-md-block "
      >
        {!subFilter && (
          <div id="thirdInnerScr" style={{ maxWidth: "100%", marginTop: 30 }}>
            <AuthorDetail />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  subFilter: state.navbar.subFilter,
  factsCount: state.author.factsCount,
  detail: state.author.authorDetail,
  bioDrawer: state.mobile.bioDrawer,
  selectedOption: state.navbar.selectedOption
});

const mapDispatchToProps = dispatch => {
  return {
    perspectivesByFilter: bindActionCreators(actions.perspectivesByFilter, dispatch),
    cancelClick: bindActionCreators(actions.cancelClick, dispatch),
    setSubFilter: bindActionCreators(setSubFilter, dispatch),
    removeSubFilter: bindActionCreators(removeSubFilter, dispatch),
    setBioDrawerOpen: bindActionCreators(mobile.setBioDrawerOpen, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdCol);

const styles = {
  btnGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  innerGroup: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingBottom: 8
  },
  link: {
    padding: 5,
    fontSize: 13,
    border: "1px solid red",
    color: "black",
    fontWeight: "bold"
  }
};
