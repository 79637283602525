import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import {perspectiveLink} from '../../../../helpers/perspectiveHelpers.js'
import * as facebook from '../../../../helpers/facebook'
import { FacebookProvider, Comments } from 'react-facebook'


const Comment = props => {

    useEffect(() => {
        facebook.refreshFacebook()
        checkHeight(id);
    }, [])
    const checkHeight= (id)=>{     
        if( $('#perspectiveComments'+id).css('display')==='block')
        {
            let time=setInterval(function(){ 
                if($('#perspectiveCommentsInner'+id).height()>24)
                {
                    $('#loading'+id).css('display','none')
                    clearInterval(time)
                }
                else
                {
                    $('#loading'+id).css('display','block')
                }

            }, 500);
        }
    }
    const {id}= props
    const url = perspectiveLink(id)

    return (
        <>
            <div id={"perspectiveComments"+id}>
                <div id={"perspectiveCommentsInner"+id}>
                    <div id={"loading"+id} style={{left:'50%',position:'absolute'}}>
                        <Loader
                            type="Bars"
                            color="#007cba"
                            height="30"
                            width="30"
                        />
                    </div>
                    <FacebookProvider appId={`${process.env.REACT_APP_FB}`}>
                        <Comments href={url} width="100%" />
                    </FacebookProvider>
                </div>
            </div>
        </>
    );
}

Comment.prototype = {
    id: PropTypes.number.isRequired,                // Perspective id
}



export default React.memo(Comment);