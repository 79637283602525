import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ToolTip, { StatefulToolTip } from "react-portal-tooltip";
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./perspectiveToolTip.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  handlePerspectiveDelete,
  handlePerspectiveUpdate
} from "../../../../actions/navbaractions";
import { BLACK, LIGHT_GREEN, MAIN_WHITE } from "../../../../constants/colors";
import { findTempConcepts } from "../../../../actions/conceptsActions";
import $ from "jquery";
import { PERSPECTIVE_EDIT_TYPE } from "../../../../constants/global";
import PerspectiveForm from "../../form/perspectiveForm";

const PerspectiveToolTip = props => {
  // const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  const { perspective, user, active, setActive } = props;

  useEffect(() => {
    if (active) {
      $(document).on("click", e => {
        if (
          e.target.id == "perspective-edit-wrapper" ||
          $(e.target).parents("#perspective-edit-wrapper").length
        ) {
        } else {
          setActive(false);
        }
      });
    } else {
      $(document).off("click");
    }
  }, [active]);

  // const handleChange = (e) => {
  //     setMyPerspective({
  //         ...myPerspective,
  //         [e.target.name]: e.target.value
  //     })
  // }

  // const handleUpdate = () => {
  //     setActive(false)
  //     props.handlePerspectiveUpdate(myPerspective.id, myPerspective.pronoun, myConcept, myAuthor, myPerspective.description, myPerspective.longDescription, myPerspective.citation)
  // }

  // const handleAuthorChange = (inputValue) => {
  //     setMyAuthor(inputValue)
  // }

  // const handleConceptChange = (inputValue) => {
  //     setMyConcept(inputValue)
  // }

  const toggle = () => {
    setOpen(!open);
  };

  const handlePerspectiveDelete = () => {
    setOpen(false);
    setActive(false);
    props.handlePerspectiveDelete(perspective.id);
  };

  const handleUpdate = (...args) => {
    setActive(false);
    props.handlePerspectiveUpdate.apply(null, args);
  };
  return (
    <>
      <div className="admin-tools" style={{ display: "flex", alignItems: "center" }}>
        <i className="fa fa-trash cursor-pointer mr-2" onClick={toggle} style={styles.copy} />
        <i
          className="fa fa-pencil cursor-pointer"
          id={`perspective${perspective.id}`}
          onClick={() => setActive(!active)}
          style={styles.copy}
        />
      </div>
      <ToolTip
        active={active}
        position="right"
        arrow="center"
        parent={`#perspective${perspective.id}`}
      >
        <PerspectiveForm
          perspective={perspective}
          nature={PERSPECTIVE_EDIT_TYPE}
          handlePerspectiveUpdate={handleUpdate}
          getRelatedConcepts={props.getRelatedConcepts}
          relatedConcepts={props.relatedConcepts}
          user={user}
        />
      </ToolTip>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm</ModalHeader>
        <ModalBody>Confirm want to delete this perspective????</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handlePerspectiveDelete}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

PerspectiveToolTip.propTypes = {
  perspective: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    handlePerspectiveUpdate: bindActionCreators(handlePerspectiveUpdate, dispatch),
    handlePerspectiveDelete: bindActionCreators(handlePerspectiveDelete, dispatch),
    getRelatedConcepts: bindActionCreators(findTempConcepts, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(React.memo(PerspectiveToolTip));

const styles = {
  copy: {
    backgroundColor: MAIN_WHITE,
    border: "1px solid #e4d3d3",
    borderRadius: "100px",
    color: BLACK,
    width: "35px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};
