import * as constants from "../constants/authors";
import * as global from "../constants/global";
import { SET_MEMES_COUNT } from "../constants/memes";
const initialState = {
  authorDetail: {},
  conceptIndex: 5,
  conceptTopIndex: 0,
  sorting: global.SORT_BY_CONCEPTS,
  offset: 1,
  limit: 30,
  initialLoading: false,
  apiLoading: false,
  selectedId: -1,
  factsCount: 0,
  tempAuthors: [],
  tempLoading: true,
  refreshLoading: false,
  blockPagination: false,
  authorWikiInfo: {},
  authorWikiLoading: false,
  authorLoading: false,
  authorError: "",
  authorPerspectives: "",
  relatedAuthorsLoading: false,
  authorsByCharacter:[]
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    // New implementations
    case constants.AUTHOR_LOADING: {
      return {
        ...state,
        apiLoading: true,
        initialLoading: false
      };
    }
    case constants.GET_ALL_AUTHORS_BY_CHAR:{
      return{
        ...state,
        authorsByCharacter:action.payload
      }
    }
    case constants.AUTHOR_LOADING_COMPLETE: {
      return {
        ...state,
        apiLoading: false,
        initialLoading: true
      };
    }
    case constants.AUTHOR_DETAIL: {
      return {
        ...state,
        authorDetail: action.payload
      };
    }
    case constants.AUTHOR_RELATEDS_LOADING: {
      return {
        ...state,
        relatedAuthorsLoading: action.payload
      };
    }
    case constants.AUTHOR_RELATEDS: {
      return {
        ...state,
        authorGroups: action.authorGroups,
        relatedAuthors: action.relatedAuthors
      };
    }
    case constants.AUTHOR_CONCEPT_INDEX: {
      return {
        ...state,
        conceptIndex: action.index,
        conceptTopIndex: action.topIndex
      };
    }

    case constants.AUTHOR_SELECTED_ID: {
      return {
        ...state,
        selectedId: action.payload
      };
    }

    case constants.SET_AUTHOR_SORTING:
      return {
        ...state,
        sorting: action.payload,
        offset: 1,
        conceptIndex: 5,
        conceptTopIndex: 0
      };

    case constants.AUTHOR_OFFSET:
      return {
        ...state,
        offset: action.payload
      };

    case constants.CONCEPT_SELECTED_ID:
      return {
        ...state,
        singleBookSelectedConcept: action.payload
      };

    case SET_MEMES_COUNT:
      return {
        ...state,
        factsCount: action.factsCount
      };

    case constants.TEMP_RELATED_AUTHORS:
      return {
        ...state,
        tempAuthors: action.payload
      };

    case constants.TEMP_AUTHOR_LOADING:
      return {
        ...state,
        tempLoading: action.payload
      };
    case constants.REFRESH_LOADING:
      return {
        ...state,
        refreshLoading: action.payload
      };
    case constants.BLOCK_PERSPECTIVE_LOADING:
      return {
        ...state,
        blockPagination: action.payload
      };

    case constants.SAVE_AUTHOR_WIKI:
      return {
        ...state,
        authorWikiInfo: action.payload,
        authorWikiLoading: false
      };

    case constants.AUTHOR_WIKI_LOADING:
      return {
        ...state,
        authorWikiLoading: action.payload
      };

    case constants.AUTHOR_SUBMIT_LOADING:
      return {
        ...state,
        authorLoading: action.payload,
        authorError: action.error
      };

    case constants.AUTHOR_SUBMIT_ERROR:
      return {
        ...state,
        authorError: action.payload
      };
    case constants.SINGLE_BOOK_RELATED_ALL_PERSPECTIVE_CONCEPTS_SUCCESS:
      return {
        ...state,
        authorPerspectives: action.payload
      };
    default:
      return state;
  }
};
