import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as memeActions from "../../actions/memes";
import * as posActions from "../../actions/pos";
import * as actions from "../../actions/conceptsActions";
import * as clusterActions from "../../actions/conceptClustersActions";
import * as navbarActions from "../../actions/navbaractions";
import ReactPageLoading from "../utills/ReactLoading";
import FirstCol from "../Concept/cols/firstCol";
import DefinitionPage from "../Concept/cols/DefinitionPage";
import SecondCol from "../Concept/cols/secondCol";
import ThirdCol from "../Concept/cols/thirdCol";
import FirstColCluster from "../Cluster/cols/firstCol";
import ThirdColCluster from "../Cluster/cols/thirdCol";
import SeoTags from "../utills/SeoTag";
import ThirdColButton from "../ConceptAndCluster/ThirdColButton";

import {
  groupsServerCategory,
  authorCategory,
  keywordCategory,
  conceptCategory,
  factCategory,
  BACK_CODE
} from "../../constants/global";
import Message from "../Messages/Message";
import { groupById } from "../../services/authorGroups";
import { getAuthorGroups } from "../../helpers/authorGroups";
import { resetScroll } from "../../helpers/scrolling";
import FourthCol from "../utills/FacebookComment/facebookCol";
import { listenToDocumentSizeChangeOnPercepectiveClick } from "../../helpers/navbar";
import { withRouter } from "react-router";
import { getAuthorItem } from "../../helpers/authorHelpers";

let mounted = false;

const All = props => {
  const {
    selectedOption,
    hidePages,
    initialLoading,
    concepts,
    tagConcepts,
    authors,
    apiLoading,
    sorting,
    tags,
    viralKeywords,
    subFilter,
    location,
    isRandomActive,
    memes,
    lock
  } = props;

  // const [lock, setLock] = useState(false);
  const [concept, setConcept] = useState({});
  const [filter, setFilter] = useState(null);
  const [parentGroup, setParentGroup] = useState(null);
  const [selected, setSelected] = useState(null);
  const [pathname, setPathname] = useState("");
  const [random, setRandom] = useState(window.location.href.split("?")[1]);
  // This hook will be used to rerender component and automatically running listening top route change
  // console.log("mount")
  //const params = new URLSearchParams(location.search);
  // let keywordValue = params.get("Keyword");

  // window.onpopstate = e => {
  //   if (window.location.pathname === pathname && !window.location.href.split("=")[1]) {
  //     history.goBack()
  //   }
  // }
  const currentConcept = window.location.href.split("/")[4];

  useEffect(() => {
    lock && searchConcept();
    // props.fetchMemes(getCondition());

    mounted = true;
    // history.listen((location, action) => {
    //   if(mounted && action === BACK_CODE)
    //   {
    //     setPathname(null)    // setting null to handle edge case of not releasing old value
    //     setPathname(location.pathname)       // Update the state and when component re render and ultimately we will have updated props.match.params
    //   }

    // })
    return () => {
      mounted = false;
      props.removeSubFilter();
    };
  }, []);

  useEffect(() => {
    if (window.location.pathname) {
      !lock && searchConcept();
      //setPathname(window.location.pathname);
    }
    props.removeSubFilter();
  }, [window.location.pathname]);

  // const getAuthor = id => {
  //   return authors.find(e => e.id === Number(id));
  // };
  const searchCluster = async () => {
    const value = queryString.parse(window.location.search);
    const name = props.match.params.name;
    let showPerspectives = true;
    let filters = [];
    if (Object.keys(value).length > 0) {
      let obj = {};
      if (value.Concepts) {
        obj.id = parseInt(value.Concepts);
        obj.type = conceptCategory;
        setFilter(obj);
        showPerspectives = false;
      }
      if (value.Authors) {
        obj.id = parseInt(value.Authors);
        obj.type = authorCategory;
        setFilter(obj);
        showPerspectives = false;
      }
      if (value.filters) {
        if (Array.isArray(value.filters)) {
          filters = value.filters;
        } else {
          filters.push(value.filters);
        }
        const groups = await getAuthorGroups(filters);
        props.addTag(groups);
      }
      if (value.trivia) {
        obj.id = true;
        obj.type = obj.value = obj.label = factCategory;
        setFilter(obj);
        showPerspectives = false;
      }
      const key = Object.keys(value)[0];
      if (key && key === groupsServerCategory) {
        obj.id = parseInt(value[key]);
        obj.type = groupsServerCategory;
        const response = await groupById(obj.id);
        const group = response.data.data;
        if (group) {
          filters.push(group.name);
          props.setSubFilter({
            id: group.id,
            value: group.name,
            label: group.name,
            type: groupsServerCategory
          });
        }
      }
    }
    let type = lock ? "cluster" : "list";
    //if (window.location.href.indexOf(clusterCategory) > -1) type = "cluster";
    props.combineMemePerspective([]);
    props.getClusterDetail(name, filters, type, showPerspectives);
  };

  useEffect(() => {
    if (lock) {
      searchCluster();
    }
  }, [lock]);

  const getAuthorId = name => {
    return authors.find(
      e => (e.firstName + " " + e.lastName).trim().toLowerCase() == name.trim().toLowerCase()
    );
  };

  useEffect(() => {
    if (lock) {
      searchCluster();
    }
    // lock && searchCluster();
    props.conceptPosContent(currentConcept, "al");
    props.setOffset(0);
    props.fetchMemes({
      concept: currentConcept,
      keyword: "undefined",
      imageMeme: false,
      lock: true,
      toggle: false,
      pageName: "All"
    });
  }, [window.location.href]);

  useEffect(() => {
    if (window.location.href) {
      const route = window.location.href.split("/")[5];
      if (route) {
        const authorName = route.split("-").join(" ");
        const author = getAuthorId(authorName);
        if (author) findPerspectives(author);
      }
    }
  }, [window.location.href, authors]);

  useEffect(() => {
    if (initialLoading && lock === false) {
      searchConcept();
    }
  }, [tags]);

  useEffect(() => {
    if (
      selectedOption.category === conceptCategory &&
      concept.category === conceptCategory &&
      concept.id !== selectedOption.id &&
      Object.keys(concept).length > 0
    ) {
      resetScroll();
      props.removeSubFilter();
      searchConcept();
    }
    setConcept(selectedOption);
  }, [selectedOption, concept]);

  useEffect(() => {
    if (filter && authors.length > 0 && initialLoading) {
      let temp = {};
      if (filter.type === keywordCategory) {
        const concept = concepts.find(x => x.id === filter.id);
        if (concept) {
          temp = { ...filter, value: concept.name, label: concept.name };
        }
      }
      if (filter.type === authorCategory) {
        const author = authors.find(x => x.id === filter.id);
        const name = `${author.firstName} ${author.lastName}`;
        temp = { ...filter, value: name, label: name };
      }
      if (filter.type === factCategory) {
        temp = { ...filter, value: factCategory, label: factCategory };
      }
      props.setSubFilter(temp);
      setFilter(null);
      props.perspectivesByFilter(temp);
    }
  });

  const searchConcept = async () => {
    const value = queryString.parse(window.location.search);
    const name = props.match.params.name;
    let showPerspectives = true;
    let filters = [];
    let factsOnly = false;
    if (Object.keys(value).length > 0) {
      let obj = {};
      if (value.Keyword) {
        obj.id = parseInt(value.Keyword);
        obj.type = keywordCategory;
        setFilter(obj);
        showPerspectives = false;
      }
      if (value.Authors) {
        obj.id = parseInt(value.Authors);
        obj.type = authorCategory;
        setFilter(obj);
        showPerspectives = false;
      }
      if (value.filters) {
        if (Array.isArray(value.filters)) {
          filters = value.filters;
        } else {
          filters.push(value.filters);
        }
        const groups = await getAuthorGroups(filters);
        props.addTag(groups);
      }
      if (value.trivia) {
        obj.type = factCategory;
        obj.id = true;
        setFilter(obj);
        showPerspectives = false;
      }
      if (value.parentGroup) {
        setParentGroup(value.parentGroup);
      }
      if (value.trivia) {
        obj.type = factCategory;
        obj.id = true;
        setFilter(obj);
        showPerspectives = false;
      }
      const key = Object.keys(value)[0];
      if (key && key === groupsServerCategory) {
        obj.id = parseInt(value[key]);
        obj.type = groupsServerCategory;
        const response = await groupById(obj.id);
        const group = response.data.data;
        if (group) {
          filters.push(group.name);
          props.setSubFilter({
            id: group.id,
            value: group.name,
            label: group.name,
            type: groupsServerCategory
          });
        }
      }
    }
    props.conceptDetailsByName(name, sorting, filters, showPerspectives, factsOnly);
  };

  const authorCallBack = (author, index = -1) => {
    //  history.push(`/dictionary/${selectedOption.label}?author=${author.id}`)
  };
  const findPerspectives = author => {
    listenToDocumentSizeChangeOnPercepectiveClick();
    const inputValue = getAuthorItem(author);
    inputValue.type = inputValue.category;
    props.setSubFilter(inputValue);
    props.perspectivesByFilter(inputValue);
  };

  const page = (
    // hidePages ? (
    //   <Message />
    // ) :
    <div className="container-fluid">
      {apiLoading && <ReactPageLoading />}
      {!apiLoading && (
        <div className="row">
          <div style={{ width: "26.5%" }}>
            {/* {lock ? <FirstColCluster lock={lock} /> : <DefinitionPage lock={lock} />} */}
            <FirstColCluster lock={lock} />
          </div>
          <div style={{ width: "33.5%" }}>
            <SecondCol
              parentGroup={parentGroup}
              searchConcept={searchConcept}
              authorCallBack={authorCallBack}
            />
          </div>
          <div style={{ width: "18%" }}>
            <ThirdColButton />
            {/* {lock ? <ThirdColCluster /> : <ThirdCol authorCallBack={authorCallBack} />} */}
          </div>
          <div style={{ width: "22%" }}>
            {/* <ThirdCol authorCallBack={authorCallBack} /> */}
            <FourthCol />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className={subFilter ? "striped-page page" : "page"} style={{ paddingTop: "110px" }}>
      <SeoTags
        title={`Unique Quotes on ${currentConcept[0].toUpperCase() +
          currentConcept.slice(1)} - Dictionaryish`}
        description={`Dictionaryish is a platform where you can get unique quotes on ${currentConcept}. You can get vast collection of key concepts, quotations, and the social sciences.`}
      />
      {page}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  conceptPosContent: bindActionCreators(posActions.conceptPosContent, dispatch),
  fetchMemes: bindActionCreators(memeActions.fetchMemetagMemes, dispatch),
  conceptDetailsByName: bindActionCreators(actions.conceptDetailsByName, dispatch),
  perspectivesByFilter: bindActionCreators(actions.perspectivesByFilter, dispatch),
  setSubFilter: bindActionCreators(navbarActions.setSubFilter, dispatch),
  removeSubFilter: bindActionCreators(navbarActions.removeSubFilter, dispatch),
  addTag: bindActionCreators(navbarActions.addTag, dispatch),
  surpriseClick: bindActionCreators(navbarActions.surpriseConcept, dispatch),
  setOffset: bindActionCreators(memeActions.setOffset, dispatch),
  getClusterDetail: bindActionCreators(clusterActions.getClusterDetail, dispatch),
  combineMemePerspective: bindActionCreators(clusterActions.combineMemePerspective, dispatch),
  setFactsKeywrodFilterId: bindActionCreators(actions.setFactsKeywrodFilterId, dispatch)
});

const mapStateToProps = state => ({
  apiLoading: state.concepts.apiLoading,
  selectedOption: state.navbar.selectedOption,
  concepts: state.navbar.concepts,
  keywords: state.navbar.keywords,
  viralKeywords: state.navbar.viralKeywords,
  authors: state.navbar.authors,
  initialLoading: state.concepts.initialLoading,
  hidePages: state.navbar.hidePages,
  sorting: state.concepts.sorting,
  tags: state.navbar.tags,
  conceptDrawer: state.mobile.conceptDrawer,
  subFilter: state.navbar.subFilter,
  memes: state.memes.memes,
  lock: state.navbar.lock
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(withRouter(All)));
