import React, { Component } from 'react';
import './secondaryNavbar.css'
import { factCategory } from '../../../constants/global';
import history from '../../../router/history';
import { connect } from "react-redux";

class SecondaryNavbar extends  Component {
    render() {
      const { data, hideChildTitle } = this.props
      const isFact = data? data.type === factCategory: null
    
      return (
            <div className="secondaryNavbar"> 
                {data.parentName && !data.keywordName &&
                  <div style={{ padding: 5, }} className="cursor-pointer"><span className="fa fa-times-circle-o icon" onClick={() => this.props.showAll()}></span></div>
                }
                {data.keywordName && 
                  <span className="fa fa-arrow-circle-left icon" onClick={this.props.handleBackClick}></span>
                }
                <div className="subFilter">
                  <b>
                    <span>{data.parentName}</span>

                    {!hideChildTitle && 
                      <>
                        {isFact? (" "): (<span> and </span>)}
                        <span style={{textTransform: isFact? 'capitalize': 'inherit'}}>
                          {data.name} 
                        </span>
                        {data.keywordName && 
                          <span style={{textTransform: isFact? 'capitalize': 'inherit'}}>
                            &nbsp;| {data.keywordName}
                          </span>
                        }
                      </>
                    }
                  </b>
                </div>
            </div>
      );
    }
  }


  const mapStateToProps = state => {
    return {
      navbar: state.navbar,
    };
  };
  export default connect(mapStateToProps , null)(SecondaryNavbar)