import * as constants from "../constants/navbar";
import * as global from "../constants/global";
import { constant } from "lodash";
const initialState = {
  userDefaultOption: "feed",
  userImage: null,
  tags: [],
  selectedOption: {},
  editConcept: {},
  singleBook: {},
  singleBookAuthor: {},
  authorClusterSelectedOptions: "",
  searchCategory: "All",
  conceptClusters: [],
  authorClusters: [],
  authorGroups: [],
  modalOpen: false,
  subFilter: null,
  selectedAuthor: null,
  totalTerms: {
    concepts: 0,
    definitions: 0,
    thinkers: 0,
    virals: 0,
    memes: 0,
    trivia: 0,
    dadJokes: 0,
    oneLiners: 0,
    books: 0
  },
  perspectives: [],
  combinedData: [],
  concepts: [],
  viralKeywords: [],
  keywords: [],
  authors: [],
  searchFocus: false,
  showSearch: false,
  allowRedirect: false,
  memesFilter: false,
  isProverb: false,
  insights: 0,
  offset: 0,
  limit: 20,
  mode: constants.NEWS_MODE,
  loading: false,
  columnLoading: false,
  concpetColumnLoading: false,
  apiLoading: false,
  timeStamp: "",
  newPerspectives: [],
  hidePages: false,
  favouriteLimit: 5,
  news: [],
  selectedNewsId: -1,
  selectedPerspectiveId: -1,
  primaryMode: true,
  initialLoading: false,
  selectedConceptId: -1,
  modalOpen: false,
  modalSignupOpen: false,
  modal: false,
  thanksModal: false,
  usernameValidationMessage: "",
  usernameValidationError: false,
  // auth errors using here because don't want to persist
  memeConceptRelateds: [],
  emailNotVerified: false,
  verificationEmailSent: false,

  imageUploading: false,
  imageUploadSuccess: false,
  perspectivesLength: -1,
  singleBookActiveStatus: "author",
  isRandomActive: false,
  perspectiveConcepts: [],
  randomLoading: false,
  memeConceptRelatedLoading: false,
  perspectiveLoading: false,
  lock: true,
  thirdKey: null,
  isAuthor: false
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.USER_DEFAULT_OPTION:
      return {
        ...state,
        userDefaultOption: action.payload
      };

    case constants.UPLOAD_IMAGE:
      return {
        ...state,
        userImage: action.payload
      };

    case constants.EDIT_CONCEPT:
      return {
        ...state,
        editConcept: action.payload
      };
    case constants.COMBINE_PERSPECTIVE_MEME:
      return {
        ...state,
        combinedData: action.payload
      };
    case constants.SELECTED_AUTHOR:
      return {
        ...state,
        selectedAuthor: action.payload
      };
    case constants.THIRD_KEY:
      return {
        ...state,
        thirdKey: action.payload
      };
    case constants.IS_AUTHOR:
      return {
        ...state,
        isAuthor: action.payload
      };
    case "LOCK":
      return {
        ...state,
        lock: action.payload
      };
    case "SET_FOCUS":
      return {
        ...state,
        searchFocus: true
      };
    case "TOGGLE_SEARCH":
      return {
        ...state,
        showSearch: !state.showSearch
      };
    case "HIDE_SEARCH":
      return {
        ...state,
        showSearch: false
      };
    case "UNSET_FOCUS":
      return {
        ...state,
        searchFocus: false
      };
    case constants.TOTAL_TERMS: {
      return {
        ...state,
        totalTerms: action.payload,
        news: action.payload.news
      };
    }

    case "GET_CONCEPTS_NAMES_NAVBAR":
      return {
        ...state,
        searchSuggestions: action.payload
      };
    case "ADD_SUB_FILTER": {
      return {
        ...state,
        subFilter: action.payload
      };
    }
    case "REMOVE_SUB_FILTER": {
      return {
        ...state,
        subFilter: null
      };
    }
    case "SET_SEARCH_CATEGORY":
      return {
        ...state,
        searchCategory: action.payload
      };
    case "SET_SELECTED_OPTION":
      return {
        ...state,
        selectedOption: action.payload,
        allowRedirect: true,
        selectedConceptId: -1
      };
    case "REMOVE_SELECTED_OPTION":
      return {
        ...state,
        selectedOption: {}
      };
    case "FLUSH_NAV_BAR":
      return {
        ...state,
        selectedOption: ""
      };
    case "SET_AUTHOR_CLUSTER_SELECTED_OPTIONS":
      return {
        ...state,
        authorClusterSelectedOptions: action.payload
      };
    case "CONCEPTS_TRIMMED_FOR_NAVBAR":
      return {
        ...state,
        concepts: action.payload
      };
    case "GET_MEME_CONCEPT_RELATEDS":
      return {
        ...state,
        memeConceptRelateds: action.payload
      };
    case "GET_MEME_CONCEPT_RELATED_LOADING":
      return {
        ...state,
        memeConceptRelatedLoading: action.payload
      };

    case "PERSPECTIVE_CONCEPTS":
      return {
        ...state,
        perspectiveConcepts: action.payload
      };
    case "CONCEPT_CLUSTERS_TRIMMED_FOR_NAVBAR":
      return {
        ...state,
        conceptClusters: action.payload
      };
    case "AUTHORS_TRIMMED_FOR_NAVBAR":
      return {
        ...state,
        authors: action.payload
      };
    case "AUTHOR_CLUSTERS_TRIMMED_FOR_NAVBAR":
      return {
        ...state,
        authorClusters: action.payload
      };

    case "AUTHOR_GROUPS_TRIMMED_FOR_NAVBAR":
      return {
        ...state,
        authorGroups: action.payload
      };
    case "OPEN_LOGIN": {
      return {
        ...state,
        modalOpen: true
      };
    }
    case "IS_RANDOM": {
      return {
        ...state,
        isRandomActive: action.payload
      };
    }
    case "RANDOM_LOADING": {
      return {
        ...state,
        randomLoading: action.payload
      };
    }
    case "CLOSE_LOGIN": {
      return {
        ...state,
        modalOpen: false
      };
    }
    case "SET_TAGS":
      return {
        ...state,
        tags: action.payload
      };
    case "SET_USERNAME_MESSAGE":
      return {
        ...state,
        usernameValidationMessage: action.payload
      };
    case "SET_USERNAME_ERROR":
      return {
        ...state,
        usernameValidationError: action.payload
      };
    case constants.PERSPECTIVE_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case constants.ALL_CONCEPTS_SINGLE_BOOK: {
      return {
        ...state,
        allConceptsOfSingleBook: action.payload
      };
    }
    case constants.AUTHOR_CONCEPTS: {
      return {
        ...state,
        authorConceptsOfSingleBook: action.payload
      };
    }
    case constants.SET_MODE: {
      return {
        ...state,
        mode: action.payload,
        perspectives: [],
        concepts: [],
        RelatedAuthor:[],
        authors: [],
        offset: 0
      };
    }

    case constants.SAVE_RECENT_PERSPECTIVES: {
      return {
        ...state,
        perspectives: action.payload,
        offset: action.offset,
        loading: false,
        timeStamp: action.timeStamp
      };
    }
    case constants.SINGLE_BOOK: {
      return {
        ...state,
        singleBook: action.payload
      };
    }
    case constants.SINGLE_BOOK_ACTIVE_STATE: {
      return {
        ...state,
        singleBookActiveStatus: action.payload
      };
    }
    case constants.SINGLE_BOOK_AUTHOR: {
      return {
        ...state,
        singleBookAuthor: action.payload
      };
    }
    case constants.SET_ARCHIVE_DATA: {
      return {
        ...state,
        perspectives: action.perspectives,
        concepts: action.concepts,
        authors: action.authors
      };
    }

    case constants.SET_MEMES_FILTER:
      return {
        ...state,
        memesFilter: action.payload
      };

    case constants.SET_PROVERB:
      return {
        ...state,
        isProverb: action.payload
      };

    case constants.SET_INSIGHTS:
      return {
        ...state,
        insights: action.payload
      };

    case constants.PERSPECTIVES:
      return {
        ...state,
        perspectives: action.payload
      };
    case constants.PERSPECTIVE_LENGTH:
      return {
        ...state,
        perspectivesLength: action.payload
      };
    case constants.EMPTY_PAGE:
      return {
        ...state,
        perspectives: [],
        authors: [],
        concepts: []
      };

    case constants.AUTHORS:
      return {
        ...state,
        authors: action.payload
      };

    case constants.KEYWORDS:
      return {
        ...state,
        keywords: action.payload
      };

    case constants.CONCEPTS:
      return {
        ...state,
        concepts: action.payload
      };
      case constants.RELATED_AUTHOR:
        return {
          ...state,
        RelatedAuthor: action.payload
        };
    case constants.VIRAL_KEYWORDS:
      return {
        ...state,
        viralKeywords: action.payload
      };
    case constants.LOADING_START:
      return {
        ...state,
        columnLoading: true
      };

    case constants.CONCEPT_LOADING_START:
      return {
        ...state,
        concpetColumnLoading: true
      };

    case constants.CONCEPT_LOADING_END:
      return {
        ...state,
        concpetColumnLoading: false
      };

    case constants.LOADING_END:
      return {
        ...state,
        columnLoading: false
      };

    case constants.NEW_PERSPECTIVES:
      return {
        ...state,
        newPerspectives: action.payload
      };
    case constants.ADD_PERSPECTIVES_LOADING:
      return {
        ...state,
        perspectiveLoading: action.payload
      };

    case constants.SET_TIME_STAMP:
      return {
        ...state,
        timeStamp: action.payload
      };
    case global.SET_VIEWPORT:
      return {
        ...state,
        viewport: action.payload
      };

    case constants.HIDE_PAGES:
      return {
        ...state,
        hidePages: action.payload
      };

    case constants.ADD_FAVOURITE_CONCEPT:
      return {
        ...state,
        favouriteConcepts: action.payload
      };

    case constants.ADD_FAVOURITE_AUTHOR:
      return {
        ...state,
        favouriteAuthors: action.payload
      };
    case constants.SET_NEWS:
      return {
        ...state,
        news: action.payload
      };
    case constants.SET_SELECTED_NEWS:
      return {
        ...state,
        selectedNewsId: action.payload
      };
    case constants.SEARCH_LOADING:
      return {
        ...state,
        apiLoading: action.payload
      };

    case constants.SET_PRIMARY_MODE:
      return {
        ...state,
        primaryMode: action.payload
      };

    case constants.INITIAL_LOADING:
      return {
        ...state,
        initialLoading: action.payload
      };
    case constants.SELECTED_CONCEPT_ID:
      return {
        ...state,
        selectedConceptId: action.payload
      };
    case constants.SINGLE_BOOK_PAGE_NUMBER:
      return {
        ...state,
        singleBookPageNumber: action.payload
      };
    //                                          New Changes
    case "MODAL_OPEN":
      return {
        ...state,
        modalOpen: action.modalOpen
      };
    case "MODAL_SIGNUP_OPEN":
      return {
        ...state,
        modalSignupOpen: action.modalSignupOpen
      };
    case "MODAL":
      return {
        ...state,
        modal: action.modal
      };
    case "THANKS_MODAL":
      return {
        ...state,
        thanksModal: action.thanksModal
      };

    case constants.EMAIL_NOT_VERIFIED_ERROR:
      return {
        ...state,
        emailNotVerified: action.payload,
        verificationEmailSent: false
      };

    case constants.VERIFICATION_EMAIL_SENT:
      return {
        ...state,
        verificationEmailSent: action.payload
      };
    case constants.IMAGE_UPLOADING:
      return {
        ...state,
        imageUploading: action.payload,
        imageUploadSuccess: action.status
      };
    case "LOGIN_REQUEST":
      return {
        ...state,
        emailNotVerified: false,
        verificationEmailSent: false
      };
    case constants.ARCHIVE_MODE:
      return {
        ...state,
        mode: "ARCHIVE_MODE"
      };
    case "EMPTY_DATA":
      return {
        ...state,
        PERSPECTIVES: [],
        CONCEPTS: [],
        AUTHORS: []
        
      };

    default:
      return state;
  }
};
