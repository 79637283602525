import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Button, Input } from "reactstrap";
import Search from "../../TopNavBar/search/search";
import { MEME_EDIT_TYPE, MEME_NEW_TYPE } from "../../../constants/global";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { getAllTextMemesColors } from "../../../actions/memes";
import { useSelector } from "react-redux";
// import {useDispatch} from 'react-redux'

const MemeFrom = props => {
  const [myPerspective, setMyPerspective] = useState({});
  const [memeObject, setMemeObject] = useState({});
  console.log("🚀 ~ file: memeForm.js ~ line 14 ~ memeObject", memeObject);
  const [myConcept, setMyConcept] = useState({});
  const [memeTags, setMemeTags] = useState([]);
  const [error, setError] = useState(null);
  const [myAuthor, setMyAuthor] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const citationRef = useRef();
  const longRef = useRef();
  const definitionRef = useRef();
  const [memeColor, textMemeColors] = useState("");
  const [colorCode, setColorCode] = useState("");
  const {
    perspective,
    nature,
    conceptLoading,
    conceptError,
    authorError,
    authorLoading,
    currentConcept,
    meme,
    setAddAdvertise = () => {}
  } = props;
  useEffect(() => {
    setIsEdit(nature === MEME_EDIT_TYPE);
  }, [nature]);

  useEffect(() => {
    if (nature === MEME_EDIT_TYPE) {
      let newTags = [];
      meme.Concepts.map(item => {
        let tag = {
          category: "Concepts",
          color: "#000000",
          id: item.id,
          label: item.name,
          pictureLink: "",
          value: item.name,
          name: item.name
        };
        newTags.push(tag);
      });
      setMemeTags(newTags);
      let newMemeObject = {
        firstLine: meme.firstLine,
        secondLine: meme.secondLine
      };
      setMemeObject(newMemeObject);
    }
  }, [meme, nature]);

  useEffect(() => {
    if (nature === MEME_NEW_TYPE) {
      let newMemeObject = {
        firstLine: "",
        secondLine: "",
        citation: ""
      };
      setMemeObject(newMemeObject);
      let newTags = [];
      // let defaultMemeConcept = {
      //   category: currentConcept.category,
      //   color: "#000000",
      //   id: currentConcept.id,
      //   label: currentConcept.label,
      //   pictureLink: "",
      //   value: currentConcept.value
      // };
      //newTags.push(defaultMemeConcept);
      //setMemeTags(newTags);
    }
  }, [nature]);

  const handleChange = e => {
    setMemeObject({
      ...memeObject,
      [e.target.name]: e.target.value
    });
  };
  const keyPress = e => {
    if (e.key == "Enter") {
      setMemeObject({
        ...memeObject,
        [e.target.name]: e.target.value
      });
    }
  };
  const handleAddTag = inputValue => {
    inputValue.name = inputValue.value;
    let tags = [...memeTags];
    let checkTag = tags.find(item => item.id == inputValue.id);
    if (!checkTag) {
      tags.push(inputValue);
    }
    setMemeTags(tags);
  };
  const removeMemeTag = tag => {
    let tags = [...memeTags];
    tags = tags.filter(c => c.id !== tag.id);
    setMemeTags(tags);
  };
  const handleInsert = () => {
    if (!memeObject.firstLine) {
      setError("Please Enter a heading line");
    } else if (memeTags.length === 0) {
      setError("Please Enter Concept of meme");
    } else {
      const data = {
        firstLine: memeObject.firstLine,
        secondLine: memeObject.secondLine,
        citation: memeObject.citation,
        tags: memeTags,
        meme_tag_id: memeColor
      };
      props.uploadMeme(data);
      setError(null);
      setMemeTags([]);
      setMemeObject({});
      setAddAdvertise(false);
    }
  };

  const memeTextChangeHandler = e => {
    let selectIndex = e.target.selectedIndex;
    let optionElement = e.target.childNodes[selectIndex];
    let option = optionElement.getAttribute("data-id");

    textMemeColors(option);
    if (option) {
      let selectedColor = props.textMemeColors.find(id => id.id == option);
      setColorCode(selectedColor.colorCode);
    }
  };
  const handleUpdate = () => {
    if (!memeObject.firstLine) {
      setError("Please Enter a heading line");
    } else {
      const data = {
        firstLine: memeObject.firstLine,
        secondLine: memeObject.secondLine,
        tags: memeTags,
        memeId: meme.id
      };
      props.updateMeme(data);
      props.setActiveFalse();
      setError(null);
    }
  };

  const toggle = () => setDropdownOpen(prevState => !prevState);
  const keywordList = (
    <>
      {memeTags &&
        memeTags.map(tag => {
          return (
            <div
              style={styles.chip}
              id="chip"
              key={tag.id}
              onClick={() => {
                removeMemeTag(tag);
              }}
            >
              {`${tag.value}  x`}
            </div>
          );
        })}
    </>
  );

  return (
    <div
      id="perspective-edit-wrapper"
      className="perspective-edit-wrapper container"
      style={{ minHeight: 500 }}
    >
      {isEdit ? (
        <Row className="prospective-edit-row" style={{ marginTop: "0px" }}>
          <h4 style={{ marginLeft: 20 }}>Edit Text Meme:</h4>
        </Row>
      ) : (
        <Row className="prospective-edit-row" style={{ marginTop: "0px" }}>
          <h4 style={{ marginLeft: 20 }}>Add Text Meme:</h4>
        </Row>
      )}

      <Row className="prospective-edit-row" style={{ marginTop: "0px" }}>
        <Col xs={9}>
          <p style={{ color: "red" }}>{error}</p>
        </Col>
      </Row>
      <Row className="prospective-edit-row" style={{ marginTop: "0px" }}>
        <Col xs={9}>
          <textarea
            ref={definitionRef}
            placeholder="First Line"
            rows={5}
            name="firstLine"
            onKeyPress={keyPress}
            onChange={handleChange}
            value={memeObject.firstLine}
            style={{ borderColor: "#ced4da" }}
          ></textarea>
        </Col>
      </Row>
      <Row className="prospective-edit-row" style={{ marginTop: "0px" }}>
        <Col xs={9} style={styles.concepts}>
          <textarea
            ref={definitionRef}
            placeholder="Second Line"
            rows={5}
            name="secondLine"
            onKeyPress={keyPress}
            onChange={handleChange}
            value={memeObject.secondLine}
            style={{ borderColor: "#ced4da" }}
          ></textarea>
        </Col>
      </Row>
      <Row className="prospective-edit-row" style={{ marginTop: "0px" }}>
        <Col xs={9} style={styles.concepts}>
          <textarea
            ref={definitionRef}
            placeholder="Citation"
            rows={5}
            name="citation"
            onKeyPress={keyPress}
            onChange={handleChange}
            value={memeObject.citation}
            style={{ borderColor: "#ced4da" }}
          ></textarea>
        </Col>
      </Row>
      <Row className="prospective-edit-row" style={{ marginTop: "0px" }}>
        <Col xs={9} style={styles.concepts}>
          <Search category={"Concept"} value="" onChange={handleAddTag} placeholder="Add Tags" />
        </Col>
      </Row>
      <Row className="prospective-edit-row" style={{ marginTop: "5px" }}>
        <div style={styles.chipWrapper}>{keywordList}</div>
      </Row>
      {/* <div className="d-flex flex-md-column mt-4">

        <label style={{padding: "5px" , marginBottom: 0  }}>Select Color *</label>
        <select
          onChange={e => {
            memeTextChangeHandler(e);
          }}
          style={{padding : '6px'}}
          >
          <option defaultValue value='blue , #000'>None</option>
          {props && props.textMemeColors &&  props.textMemeColors.map((item, ind) => (
            <option data-id={item.id}>{item.name}</option>
            ))}
        </select>
            </div> */}
      <Row className="prospective-edit-row" style={{ marginTop: "5px" }}>
        <h4 style={{ marginLeft: 20 }}>Preview:</h4>
      </Row>
      <div
        className="textMemePreviewContainer"
        style={{
          backgroundImage: `linear-gradient(to bottom, ${
            colorCode ? colorCode : "blue"
          } 20%, #000000)`
        }}
      >
        <h3 className="whiteText" style={{ whiteSpace: "pre-line" }}>
          {memeObject.firstLine}
        </h3>
        <p className="whiteText" style={{ whiteSpace: "pre-line" }}>
          {memeObject.secondLine}
        </p>
      </div>
      <Row className="prospective-edit-row" style={{ marginLeft: "10px", marginTop: "5px" }}>
        {!isEdit ? (
          <Button type="button" onClick={handleInsert} color="primary">
            Add Meme
          </Button>
        ) : (
          <Button type="button" onClick={handleUpdate} color="primary">
            Update Meme
          </Button>
        )}
      </Row>
    </div>
  );
};

MemeFrom.propTypes = {
  // perspective: PropTypes.object.isRequired,
  nature: PropTypes.oneOf([MEME_EDIT_TYPE, MEME_NEW_TYPE]).isRequired,
  handlePerspectiveUpdate: PropTypes.func,
  handlePerspectiveSubmit: PropTypes.func,
  handleConceptSubmit: PropTypes.func,
  handleAuthorSubmit: PropTypes.func,
  conceptLoading: PropTypes.bool,
  // conceptError: PropTypes.bool,
  authorLoading: PropTypes.bool
  // authorError: PropTypes.bool
};

export default MemeFrom;

const styles = {
  concepts: {
    zIndex: 20,
    paddingRight: 0
  },
  addIcon: {
    width: 25
  },
  chipWrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    padding: "0px 7px",
    fontSize: 14,
    backgorund: "red",
    border: "1px solid #bab8b8",
    borderRadius: "5rem",
    marginLeft: "20px"
  }
};
