import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Switch from "react-switch";
import history from "../../../router/history";
import { authorCategory, conceptCategory, groupsServerCategory } from "../../../constants/global";
import { reloadPage } from "../../../helpers/navbar";
const SwitchAuthorModes = props => {
  const handleChange = () => {
    if (props.checked) {
      history.push(`/${groupsServerCategory}/${props.selectedOption.slug}`);
    } else {
      history.push(`/${authorCategory}/${props.selectedOption.slug}`);
    }
  };

  const handleLeftTitle = () => {
    if (props.checked) {
      history.push(`/${authorCategory}/${props.selectedOption.slug}`);
    } else {
      reloadPage();
    }
  };

  const handleRightTitle = () => {
    if (props.checked) {
      reloadPage();
    } else {
      history.push(`/${authorCategory}/${props.selectedOption.slug}`);
    }
  };

  return (
    <div className="row">
      <div className="col-3">
        <p className="cursor-pointer hover-red" onClick={handleLeftTitle}>
          About Thinker
        </p>
      </div>
      <div className="col-4">
        <Switch
          onChange={handleChange}
          checked={props.checked}
          uncheckedIcon={false}
          checkedIcon={false}
        />
      </div>
      <div className="col-2">
        <p className="cursor-pointer hover-red" onClick={handleRightTitle}>
          By Thinker
        </p>
      </div>
    </div>
  );
};

SwitchAuthorModes.propTypes = {
  leftTitle: PropTypes.string.isRequired,
  onLeftClick: PropTypes.func.isRequired,
  rightTitle: PropTypes.string.isRequired,
  onRightClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    selectedOption: state.navbar.selectedOption
  };
};

export default connect(mapStateToProps, null)(SwitchAuthorModes);
