import React from "react";
import AboutThisThinker from "./aboutThisThinker";
import ImageWrapper from "../../utills/Header/imageWrapper";
import history from "../../../router/history";
import { connect } from "react-redux";
const AuthorImageCard = props => {
  const {
    selectedOption,
    detail: { dob, dod },
    detail
  } = props;
  const relatedConcept = detail.Concept;

  const aboutThinker = () => {
    history.push(`/dictionary/${props.selectedOption.slug}`);
  };
  return (
    <>
      <div style={styles.btnWrapper}>
        <div style={styles.outerBorderBtn}>
          {/* {selectedOption.category!="Similar-Authors"&&relatedConcept && <AboutThisThinker onClick={aboutThinker} />} */}
        </div>
      </div>
      <div id="detail">
        <div id="detail-image-wrapper">
          <ImageWrapper
            src={selectedOption.pictureLink || detail.pictureLink}
            showImageButton={true}
          />
        </div>
        <div id="detail-name-wrapper" style={styles.detailNameWrapper}>
          <span>{selectedOption.value}</span>
          <h6>
            {dob}-{dod}
          </h6>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = state => ({
  selectedOption: state.navbar.selectedOption || {},
  detail: state.author.authorDetail || {}
});
const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorImageCard);
const styles = {
  outerBorderBtn: {
    margin: "2%",
    border: "4px solid white",
    borderRadius: 6
  },
  detailNameWrapper: {
    backgroundColor: "black",
    color: "white",
    height: "10%",
    paddingLeft: 2,
    fontSize: 23
  },
  btnWrapper: {
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "flex-end"
  },
  infoWrapper: {
    marginTop: 20,
    flexDirection: "column",
    alignItems: "flex-end"
  }
};
