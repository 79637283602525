import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as memesActions from "../../../actions/memes";
import * as navbarActions from "../../../actions/navbaractions";
import memesIcon from "../../../images/imageIcons/memesIcons.png";
import history from "../../../router/history";
import ToolTip from "react-portal-tooltip";
import MemeUpload from "../../Memes/memeUpload";
import * as global from "../../../constants/global";
import { Button, Badge } from "reactstrap";
import { DARK_BLUE } from "../../../constants/colors";
import memes2Icon from "../../../images/imageIcons/memes2.png";
import { reloadPage } from "../../../helpers/navbar";
const MemeButton = props => {
  const {
    memesFilter,
    count,
    selectedOption,
    loading,
    uploadSuccess,
    uploadFail,
    isAuthenticated,
    user,
    mobileView = false
  } = props;
  const [active, setActive] = useState(false);
  const [leave, setLeave] = useState(false);
  const [myLoading, setMyLoading] = useState(false);
  const [delay, setDelay] = useState("");
  const [selected, setSelected] = useState(false);
  const ref = useRef();
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleMouseClick);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleMouseClick);
    };
  }, [active, leave, loading, selected]);

  useEffect(() => {
    setMyLoading(loading);
    if (myLoading && !loading && uploadSuccess) {
      setSelected(false);
      setDelay(
        setTimeout(() => {
          setActive(false);
        }, 1000)
      );
    }
  }, [loading, uploadSuccess, selected]);

  const handleMouseClick = () => {
    if (leave) {
      setActive(false);
    }
  };

  const handleScroll = () => {
    if (active && leave) {
      setActive(false);
    }
  };
  const handleMouseEnter = () => {
    if (active) {
      setLeave(false);
      clearTimeout(delay);
    }
  };
  const handleMouseLeave = () => {
    if (active) {
      setLeave(true);
      setDelay(
        setTimeout(() => {
          if (!loading && !selected) {
            setActive(false);
          }
        }, 500)
      );
    }
  };
  const handleMemesClick = () => {
    const { category } = selectedOption;
    let clusterPage = false;
    if (category === global.clusterCategory || category === global.groupsCategory) {
      clusterPage = true;
    }
    if (count > 0 || memesFilter || clusterPage) {
      handleMeme();
    } else {
      handleZeroCount();
    }
  };
  const handleZeroCount = () => {
    setLeave(false);
    setActive(!active);
  };
  const handleMeme = () => {
    setActive(false);
    // if(memesFilter)
    // {
    //     props.setMemesFilter(false)
    //     history.push(`/${selectedOption.type}/${selectedOption.value.toLowerCase()}`)
    // }
    // else
    history.push(`/memes/${selectedOption.value}`);
    reloadPage();
    // props.setMemesFilter(true)
    // }
  };
  const handleMemesSelect = value => {
    setSelected(value);
    setActive(true);
  };
  let memePage = selectedOption.category === global.memesCategory;
  const buttonStyle = {
    backgroundColor: "white",
    color: "black",
    borderRadius: 25,
    display: "flex",
    alignItems: "center"
  };
  return (
    <Fragment>
      {!mobileView ? (
        <button
          // style={styles.getBtnStyles(memePage)}
          data-tip="memes"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleMemesClick}
          style={{ ...buttonStyle }}
          className="btn mb-2  btn-outline-primary"
          id="memes"
        >
          <img
            className="icon"
            style={styles.icon}
            id="sharePage"
            src={memesIcon}
            alt="meme icon"
          />
          Memes
          <Badge color="primary" className="ml-2" style={styles.getBadgeStyle(memePage)}>
            {count}
          </Badge>
        </button>
      ) : (
        <img
          src={memes2Icon}
          onClick={handleMemesClick}
          className="right-drawer-button right-bottom-button"
          alt="meme icon"
        />
      )}

      <ToolTip active={active} position="bottom" arrow="center" parent={"#memes"}>
        <div onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
          {isAuthenticated && (
            <div style={styles.uploadWrapper}>
              <p>{`No '${selectedOption.value}' memes. click below to submit`}</p>
              <MemeUpload
                user={user}
                loading={loading}
                uploadSuccess={uploadSuccess}
                uploadFail={uploadFail}
                uploadMemes={props.uploadMemes}
                selectedOption={selectedOption}
                setSelected={value => setSelected(value)}
              />
            </div>
          )}
          {!isAuthenticated && (
            <div style={styles.loginWrapper}>
              <p>{`No '${selectedOption.value}' memes. Login to submit memes`}</p>
              <Button color="primary" onClick={props.openModal}>
                Login
              </Button>
            </div>
          )}
        </div>
      </ToolTip>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  memesFilter: state.navbar.memesFilter,
  selectedOption: state.navbar.selectedOption,
  count: state.memes.count,
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.memes.loading,
  uploadSuccess: state.memes.uploadSuccess,
  uploadFail: state.memes.uploadFail
});

const mapDispatchToProps = dispatch => ({
  setMemesFilter: bindActionCreators(memesActions.setMemesFilter, dispatch),
  uploadMemes: bindActionCreators(memesActions.uploadMemes, dispatch),
  openModal: bindActionCreators(navbarActions.openModal, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MemeButton);

const styles = {
  icon: {
    float: "left",
    height: 29,
    width: 33,
    height: "100%",
    borderRadius: 20
  },
  uploadWrapper: {
    padding: 10
  },
  loginWrapper: {
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  btn: {
    border: "1px solid black",
    background: "transparent",
    color: "black",
    padding: 5
  },
  getBtnStyles: active => {
    return {
      border: "1px solid black",
      background: "transparent",
      color: "black",
      padding: 3,
      margin: 5
      // backgroundColor: active? DARK_BLUE: 'inherit',
      // color: active ? 'white': 'inherit',
      // fontSize: 12
    };
  },
  getBadgeStyle: active => {
    return {
      background: active ? "transparent" : DARK_BLUE
    };
  }
};
