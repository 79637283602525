import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import logo from '../../../../images/favicon.ico'
import Loader from 'react-loader-spinner'
import { Button, Form, FormGroup, Input, Alert } from "reactstrap";
import axios from 'axios'
import './login.css'

const forgetEmail = async (data) => {
    try {
        
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/api/rest/v1/forgetPassword`, data)
        return response
    }
    catch (e) {
        if (e.response && e.response.status === 400)
            return e.response
    }
}

const EmailModal = () => {
    const [email, setEmail] = useState('')
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const close = () => {
        setOpen(false);
        setMessage(false)
        setError(null)
        setLoading(false)
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const response = await forgetEmail({ email })
        if (response) {
            if (response.status === 200) {
                setMessage(true)
                setError(null)
                setLoading(false)
            }
            else {
                setError(response.data.data)
                setLoading(false)
            }
        }
        else {
            setError('Network error')
            setLoading(false)
        }

    }
    return (
        <div>
            <p onClick={() => setOpen(true)} style={styles.forgetPassword}>
                Forget Password ?
            </p>
            <Modal open={open} onClose={() => close()} className="loginModal center" center>
                <img src={logo} alt="logo" style={styles.logo} />
                {!message && <h3 style={styles.center}>Forget Password ?</h3>}
                <div id="login_body" style={styles.formBody}>
                    <Form style={styles.form} onSubmit={onSubmit} id="forget-password-form">
                        {error && <Alert color="danger">
                            {error}
                        </Alert>}
                        {!message && <FormGroup>
                            <Input type="email" name="email" id="forget-password-email" style={styles.input} onChange={e => setEmail(e.target.value)} placeholder="Enter your email" required />
                        </FormGroup>}
                        {!message && <Button type='submit' className="btn" style={styles.submitButton}>
                            {!loading ? 'Submit' : <Loader type="Puff"
                                color="#FFF"
                                height="30"
                                width="30"
                            />}
                        </Button>}
                        {message && <h4 style={styles.center}>Please Check your email</h4>}
                    </Form>
                </div>
            </Modal>
        </div>
    )
}


const styles = {
    logo: {
        width: 30,
        marginLeft: '50%'
    },
    center: {
        textAlign: 'center'
    },
    formBody: {
        padding: 10
    },
    input: {
        width: '40vw'
    },
    forgetPassword: {
        color: '#545b62',
        textDecoration: 'underline',
        cursor: 'pointer',
        textAlign: 'right'
    },
    form: {
        margin: 'auto',
        padding: '20px 30px',
        background: '#fff',
        boxShadow: '0 0 20px #ccc',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    submitButton: {
        height: "40px",
        backgroundColor: "#641E89",
        color: "#fff",
        border: "1px solid white",
        alignSelf: 'flex-end'
    },
}
export default EmailModal
