import React, { useEffect, useState } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MemeCard from "./memeCard";
import TextMemeCard from "./textMemeCard";
import * as actions from "../../actions/memes";
import PerspectiveLoading from "../utills/ReactLoading/PerspectiveLoading";
import _ from "lodash";
import history from "../../router/history";

const MemeCards = props => {
  const [concepts, setConcepts] = useState([]);
  let {
    memes,
    fetchLoading,
    memePageMemes,
    loading,
    memeTags,
    memeConcepts,
    toggle,
    handleToggle,
    thirdKey
  } = props;

  let uniqMemes = memePageMemes && _.uniqBy(memePageMemes, "id");

  useEffect(() => {
    let memeTagIds = memeTags && memeTags.map(item => item.ConceptId);
    let filteredMemeConcepts =
      memeConcepts && memeTagIds && memeConcepts.filter(item => !memeTagIds.includes(item.id));
    setConcepts(filteredMemeConcepts);
  }, [memeConcepts]);

  const memeRoute = window.location.pathname.split("/")[1] == "memes";
  !memeRoute &&
    (memes =
      memes &&
      memes.sort((a, b) => {
        return a.firstLine && b.firstLine && a.firstLine.localeCompare(b.firstLine);
      }));

  return (
    <React.Fragment>
      {!memeRoute ? (
        <>
          {memes &&
            memes.map(meme =>
              meme.isText ? (
                <TextMemeCard meme={meme} thirdKey={thirdKey} />
              ) : (
                <MemeCard meme={meme} uploadMemes={props.uploadMemes} />
              )
            )}
          <div style={styles.loading}>
            <PerspectiveLoading perspectiveLoading={fetchLoading} />
          </div>
        </>
      ) : (
        <>
          {/* {
            <p>
              <i
                style={{
                  //marginTop: "30px",
                  cursor: "pointer",
                  color: toggle ? "black" : "#adb8b0"
                }}
                onClick={handleToggle}
                class="fas fa-random"
              >
                Shuffle
              </i>
            </p>
          } */}
          {uniqMemes &&
            uniqMemes.map(meme =>
              meme.isText ? (
                <TextMemeCard meme={meme} thirdKey={thirdKey} />
              ) : (
                <MemeCard meme={meme} updateImageMeme={props.updateImageMeme} />
              )
            )}

          <div style={styles.loading}>
            <PerspectiveLoading perspectiveLoading={fetchLoading ? fetchLoading : ""} />
          </div>
        </>
      )}

      {!loading && !fetchLoading && memes && memes.length == 0 && (
        <h3 style={{ textAlign: "center" }}>Oops!!!!!! No memes found :(</h3>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    likeMeme: bindActionCreators(actions.likeMeme, dispatch),
    setOffset: bindActionCreators(actions.setOffset, dispatch),
    fetchMemes: bindActionCreators(actions.fetchMemetagMemes, dispatch),
    updateImageMeme: bindActionCreators(actions.updateImageMeme, dispatch)
  };
};

const mapStateToProps = state => {
  return {
    memes: state.memes.memes,
    memePageMemes: state.memes.memePageMemes,
    memeTags: state.memes.textMemeColors,
    memeConcepts: state.navbar.concepts,
    loading: state.memes.loading,
    fetchLoading: state.memes.fetchLoading,
    relatedConcepts: state.memes.memeConcepts
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemeCards);

const styles = {
  loading: {
    paddingTop: 30,
    paddingBottom: 30
  },
  memeWrapper: {
    border: "4px solid rgb(187, 187, 187)",
    borderRadius: 4,
    padding: 8,
    marginBottom: 20,
    marginTop: 20
  }
};
