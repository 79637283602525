import axios from 'axios'
import {BASE_URL} from '../constants/global'

export const getArchiveDataById = (id, token) => {
    return axios.get(`${BASE_URL}/archive/perspective/${id}`, {headers: { Authorization: token } })
}

export const getArchiveDataBySlug = (id) => {
    return axios.get(`${BASE_URL}/archive/slug/${id}`)
}

export const updateArchiveSecurity = (id, isPublic, token) => {
    return axios.put(`${BASE_URL}/archive/public/${id}`, {public: isPublic}, {headers: { Authorization: token } })
}

export const updateArchivePriority  = (archives, token) => {
    return axios.put(`${BASE_URL}/admin/archive/priority`, { archives }, {headers: { Authorization: token } })
}