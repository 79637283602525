import React from "react";
import PropTypes from "prop-types";
const EssentialBooks = props => {
  return (
    <div>
      <button type="button" onClick={props.onClick} className="btn btn-danger btn-lg btn-block">By This Thinker </button>
    </div>
  );
};

EssentialBooks.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default EssentialBooks;